import { Button } from "@material-ui/core";
import { DialogueFull } from "app/components";
import { CustomTimePicker } from "app/components/general/CustomDate";
// import HgDatePicker from "app/components/general/HgDatePicker";
import { getItem } from "app/utils";
import { palette } from "assets/css/main";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { withTranslation } from "react-i18next";
import Moment from "react-moment";
import "moment/locale/id";
import Select from "react-select";
import id from "date-fns/locale/id";
registerLocale("id", id);
const options = [
  { value: 30, label: "30" },
  { value: 45, label: "45" },
  { value: 60, label: "60" },
  { value: 120, label: "120" },
  { value: 180, label: "180" },
  { value: 200, label: "200" },
];

class RescheduleDialogue extends React.Component {
  mindate = new Date().setDate(new Date().getDate() + 1);
  state = {
    timeStart: "",
    timeEnd: "",
    duration: 0,
    timezone: "WIB",
    tmpDur: options[1],
  };
  validateClose() {
    const { timeStart, timeEnd, duration } = this.state;
    const { onClose } = this.props;

    if (timeStart && timeEnd && duration) {
      onClose(this.state);
    } else {
      onClose(null);
    }
  }
  handleTimeSelect(date, time = {}, dur) {
    var d = date ? new Date(date) : new Date(this.mindate);
    var tmp = new Date(d);
    var tmp2 = new Date(d);
    var h, m;
    if (time._isAMomentObject) {
      h = time._d.getHours();
      m = time._d.getMinutes();
      tmp.setHours(h);
      tmp.setMinutes(m);
      tmp2.setHours(h);
      tmp2.setMinutes(tmp.getMinutes() + dur.value);
    } else {
      h = d.getHours();
      m = d.getMinutes();
      tmp.setHours(h);
      tmp.setMinutes(m);
      tmp2.setMinutes(tmp.getMinutes() + dur.value);
    }
    this.setState({ timeStart: tmp, duration: dur.value, timeEnd: tmp2 });
  }
  render() {
    const { open, t, onClose } = this.props;
    const { timeStart, timeEnd, tmpDur } = this.state;

    return (
      <DialogueFull
        style={{ width: 650 }}
        open={open}
        onClose={() => {
          this.validateClose();
        }}>
        <div>
          <div
            style={{
              backgroundColor: "white",
              width: 650,
              boxSizing: "border-box",
              borderRadius: 4,
            }}>
            <div
              style={{
                backgroundColor: "white",
                padding: "20px 20px",
                color: palette.primary,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                fontSize: 18,
              }}>
              {t("updateResult.rescheduleTitle")}
              <p
                style={{
                  fontWeight: "lighter",
                  margin: 0,
                  borderBottom: "1px solid " + palette.popacity(0.3),
                  padding: "10px 0px",
                }}>
                {t("updateResult.subtitle")}
              </p>
            </div>
            <div
              style={{
                padding: 20,
                height: "100%",
                color: palette.primary,
              }}>
              <div
                style={{
                  backgroundColor: palette.grey,
                  minWidth: 400,
                  maxWidth: 608,
                  border: "1px solid " + palette.popacity(0.2),
                  borderRadius: 4,
                  padding: 20,
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    position: "relative",
                  }}>
                  <div
                    style={{
                      padding: "0px 10px",
                      display: "flex",
                      flexDirection: "column",
                      height: 81,
                    }}>
                    <label
                      style={{
                        fontWeight: "lighter",
                        fontSize: 14,
                        marginBottom: 3,
                      }}>
                      {t("form.label.date")}
                    </label>
                    <DatePicker
                      placeholderText={t("general:placeholder.date2")}
                      disabledKeyboardNavigation
                      locale={getItem("lang")}
                      showLabel={false}
                      dateFormat={"dd/MM/yyyy"}
                      onChange={e => {
                        this.handleTimeSelect(e, e, tmpDur);
                      }}
                      minDate={
                        new Date(new Date().setDate(new Date().getDate() + 1))
                      }
                      selected={timeStart ? new Date(timeStart) : ""}
                      format={"dd/MM/yyyy"}
                      className="Schedule popup"
                    />
                  </div>
                  <div
                    style={{
                      padding: "0px 10px",
                      height: 81,
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <label
                      style={{
                        fontWeight: "lighter",
                        fontSize: 14,
                        marginBottom: 3,
                      }}>
                      {t("form.label.duration")}
                    </label>
                    <Select
                      className="duration"
                      classNamePrefix="duration"
                      onChange={op => {
                        this.setState({ tmpDur: op });
                        this.handleTimeSelect(timeStart, {}, op);
                      }}
                      defaultValue={tmpDur}
                      options={options}
                      placeholder={t("form.placeholder.duration")}
                    />
                  </div>
                  <div style={{ padding: "0px 5px 0px 10px", height: 81 }}>
                    <label style={{ fontWeight: "lighter" }}>
                      {t("form.label.time")}
                    </label>
                    <CustomTimePicker
                      value={timeStart ? new Date(timeStart) : ""}
                      onChange={x => {
                        this.handleTimeSelect(timeStart, x, tmpDur);
                      }}
                    />
                  </div>
                  -
                  <div style={{ padding: "0px 20px 0px 5px", height: 81 }}>
                    <label style={{ fontWeight: "lighter", marginBottom: 3 }}>
                      &nbsp;
                    </label>
                    <div
                      style={{
                        padding: "9px 28px",
                        borderRadius: 4,
                        backgroundColor: "rgba(46, 105, 178, 0.1) ",
                        minHeight: 21,
                        fontWeight: "bold",
                        fontSize: 18,
                        minWidth: 40,
                        marginTop: 2,
                      }}>
                      {timeEnd ? <Moment date={timeEnd} format="HH:mm" /> : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                bottom: 0,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                backgroundColor: "white",
                boxSizing: "border-box",
                width: "100%",
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                padding: "20px 50px",
              }}>
              <Button
                onClick={() => {
                  this.setState({
                    timeStart: "",
                    timeEnd: "",
                    duration: 0,
                    timezone: "WIB",
                    tmpDur: options[1],
                  });
                  onClose(null);
                }}
                style={{
                  border: "1px solid " + palette.primary,
                  color: palette.primary,
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  padding: "8px 50px",
                  marginRight: 20,
                }}>
                {t("general:button.back")}
              </Button>
              <Button
                onClick={() => {
                  this.validateClose();
                }}
                style={{
                  backgroundColor: palette.primary,
                  textTransform: "capitalize",
                  color: "white",
                  padding: "8px 50px",
                }}>
                {t("general:button.save")}
              </Button>
            </div>
          </div>
        </div>
      </DialogueFull>
    );
  }
}
RescheduleDialogue.defaultProps = {
  onClose: () => {
    console.error("Required onClose props");
  },
};
export default withTranslation("interview-schedule")(RescheduleDialogue);
