import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { AppBar, Toolbar, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { loadImage, getItem } from "app/utils";
import { IconConfirmationCheck } from "assets/img";
import { palette } from "assets/css/main";
import { NextBtn } from "app/components";
import { isMobile } from "react-device-detect";
import { HeaderNologin } from "app/components";

function VerifySuccess({ t }) {
  const [successContent, setSuccessContent] = useState("");

  useEffect(() => {
    setSuccessContent(
      getItem("lang") === "en"
        ? t("auth:validateEmail.successContent")
        : t("auth:validateEmail.successContent_ap")
    );
  }, [t]);
  return (
    <div>
      {isMobile ? (
        <div>
          <HeaderNologin />
          <main style={{ paddingTop: 90 }} className="validate-mobile">
            <Grid style={{ textAlign: "center", padding: "0px 20px" }}>
              <img
                src={IconConfirmationCheck}
                alt="Success"
                width={90}
                height={90}
              />
              <Typography
                style={{
                  fontSize: 24,
                  marginBottom: 24,
                  paddingTop: 56,
                  fontWeight: "bold",
                  color: palette.primary,
                  textAlign: "center",
                }}
              >
                {t("auth:validateEmail.successTitle")}
              </Typography>
              <Typography
                style={{
                  fontSize: 16,
                  marginBottom: 24,
                  color: palette.primary,
                  textAlign: "center",
                }}
              >
                {successContent}
              </Typography>
              <NextBtn
                text={t("auth:validateEmail.linkLogin")}
                onClick={() => {
                  window.location.href = "/user/login/applicant";
                }}
              />
            </Grid>
          </main>
        </div>
      ) : (
        <div>
          <AppBar
            position="absolute"
            color="default"
            style={{
              backgroundColor: "white",
              top: 0,
              height: 80,
              bottom: "auto",
              position: "fixed",
            }}
          >
            <Toolbar>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ height: 80 }}
              >
                <Grid item lg={2}>
                  <Link to="/" className="centered">
                    <img
                      alt="Hiregrade-Logo"
                      src={loadImage("HiregradeLogo.png")}
                      width={150}
                      style={{ marginLeft: 87 }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <main style={{ paddingTop: 210 }} className="validate">
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              <img
                src={IconConfirmationCheck}
                alt="Success"
                width={71}
                height={71}
              />
              <Typography
                style={{
                  fontSize: 24,
                  width: 450,
                  marginBottom: 24,
                  paddingTop: 56,
                  fontWeight: "bold",
                  color: palette.primary,
                  textAlign: "center",
                }}
              >
                {t("auth:validateEmail.successTitle")}
              </Typography>
              <Typography
                style={{
                  fontSize: 18,
                  width: 400,
                  marginBottom: 180,
                  color: palette.primary,
                  textAlign: "center",
                }}
              >
                {successContent}
              </Typography>
              <NextBtn
                text={t("auth:validateEmail.linkLogin")}
                onClick={() => {
                  window.location.href = "/user/login/applicant";
                }}
              />
            </Grid>
          </main>
        </div>
      )}
    </div>
  );
}
export default withTranslation()(VerifySuccess);
