import React from "react";
import { withTranslation } from "react-i18next";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette } from "assets/css/main";
import CandidateSidePanel from "./side-panel";
import CandidatePreview from "./candidate-preview";
import { getItem, RequestGet } from "app/utils";
import TableContent from "./tab-content";
import { Tab, Tabs } from "@material-ui/core";

export default withTranslation()(
  class CandidatesTestReport extends React.Component {
    state = {
      value: 0,
      tabContent: [""],
      tabs: [],
      lang: getItem("lang"),
      data: {
        personalInfo: { firstName: "", id: "" },
      },
    };

    componentDidMount() {
      window.scrollTo(0, 0);
      this.getSoftSkill();
    }

    getSoftSkill() {
      RequestGet("candidate/result/" + this.props.match.params.id)
        .then(r => {
          const { data } = r;

          this.setState({
            lang: getItem("lang"),
            tabs: data.map(v => {
              return { eng: v.categoryNameEng, ina: v.categoryNameIna };
            }),
            tabContent: data.map((v, k) => {
              return <TableContent key={k} data={v.softSkillHeaders} />;
            }),
          });
        })
        .catch(e => {
          console.log("getSoftSkill()", e);
        });
    }

    handleChangeTab(x, n) {
      this.setState({ value: n });
    }

    render() {
      const {
        match: { params },
        //t,
      } = this.props;
      const { value, tabContent, tabs, lang } = this.state;
      if (getItem("lang") !== lang) {
        this.getSoftSkill();
      }
      return (
        <div style={{ height: "100%", boxSizing: "border-box" }}>
          <RecruiterAppBar menu="dashboard" shadow={true} />
          <div
            style={{ paddingTop: 80, height: "100%", boxSizing: "border-box" }}>
            <CandidatePreview id={params.id} />
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                minHeight: 650,
              }}>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  backgroundColor: palette.lightGrey,
                  boxSizing: "border-box",
                  justifyContent: "flex-end",
                }}>
                <CandidateSidePanel view="test" id={params.id} />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  height: "100%",
                  boxSizing: "border-box",
                  flexDirection: "column",
                  padding: "25px 40px",
                  color: palette.primary,
                }}>
                <Tabs
                  value={value}
                  onChange={(x, n) => this.handleChangeTab(x, n)}
                  indicatorColor="primary"
                  textColor="primary"
                  className="Tabs-User-Profile"
                  style={{
                    borderBottom: "1px solid " + palette.popacity(0.2),
                  }}>
                  {tabs.map((v, k) => {
                    return (
                      <Tab
                        key={k}
                        label={getItem("lang") === "en" ? v.eng : v.ina}
                        style={{
                          textTransform: "capitalize",
                          color: palette.primary,
                          opacity: value === k ? 1 : 0.5,
                        }}
                      />
                    );
                  })}
                </Tabs>
                <div style={{ padding: "30px 0px 0px" }}>
                  {tabContent[value]}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
