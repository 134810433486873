import React from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { palette } from "assets/css/main";
import MyHelmet from "app/components/header/MyHelmet";
import { Header, Item, Footer, HeaderNologin } from "app/components";
import {
  ImgAbout,
  ImgOurAction1,
  ImgOurAction2,
  ImgOurAction3,
  ImgOurAction4,
  ImgOurAction5,
} from "assets/img";
import { isMobile } from "react-device-detect";
class AboutPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    const js = t("about:jobSeekers.items", { returnObjects: true });
    const re = t("about:recruiters.items", { returnObjects: true });
    const ei = t("about:educationalInstitutions.items", {
      returnObjects: true,
    });
    const oa = t("about:ourAction.items", { returnObjects: true });
    const imgOA = [
      ImgOurAction1,
      ImgOurAction2,
      ImgOurAction3,
      ImgOurAction4,
      ImgOurAction5,
    ];
    if (isMobile) {
      return (
        <div>
          <HeaderNologin />
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{
              width: "100vw",
              paddingTop: 60,
              backgroundColor: palette.primary,
            }}>
            <p style={{ fontWeight: "bold", fontSize: 24, color: "white" }}>
              {t("about:title")}
            </p>
            <img
              src={ImgAbout}
              style={{ width: 304, height: 207, padding: "35px 0px" }}
              alt={"about"}
            />
            <div
              style={{
                width: "100%",
                padding: "0px 25px 40px",
                boxSizing: "border-box",
              }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "white",
                  marginBottom: 0,
                }}>
                {t("about:jobSeekers.title")}
              </p>
              <Item items={js} color={"white"} fontSize={16} />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "white",
                  marginBottom: 0,
                }}>
                {t("about:recruiters.title")}
              </p>
              <Item items={re} color={"white"} fontSize={16} />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  color: "white",
                  marginBottom: 0,
                }}>
                {t("about:educationalInstitutions.title")}
              </p>
              <Item items={ei} color={"white"} fontSize={16} />
            </div>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            style={{
              padding: "20px",
              backgroundColor: palette.secondary,
              paddingBottom: 80,
            }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: 24,
                color: palette.primarydark,
                textAlign: "center",
                width: "100%",
                marginBottom: 40,
              }}>
              {t("about:ourAction.title")}
            </p>
            {oa.map((v, k) => {
              return (
                <div
                  key={k}
                  style={{
                    width: "50%",
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      color: palette.primarydark,
                    }}>
                    <img src={imgOA[k]} alt={"oa-" + k} />
                    <p
                      style={{
                        fontSize: 16,
                        width: 160,
                        textAlign: "center",
                        color: palette.primarydark,
                      }}>
                      {v}
                    </p>
                  </div>
                </div>
              );
            })}
          </Grid>
          <Footer />
        </div>
      );
    } else {
      return (
        <div>
          <MyHelmet
            title="About Us"
            description="a 3-sided digital platform that facilitates the needs of fresh
                graduates, educational institutions and corporations through
                accurate people profiling for better recruitment and development
                processes"
          />
          <Header shadow={true} linkgroup={true} />

          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{
              width: "100vw",
              paddingTop: 80,
              backgroundColor: palette.primary,
            }}>
            <p style={{ fontWeight: "bold", fontSize: 32, color: "white" }}>
              {t("about:title")}
            </p>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              style={{ marginBottom: 120 }}>
              <div
                style={{
                  display: "flex",
                  padding: "20px 0px",
                  justifyContent: "center",
                  width: "100%",
                }}>
                <div style={{ marginRight: 30 }}>
                  <img
                    src={ImgAbout}
                    style={{ width: 591, height: 400 }}
                    alt={"about"}
                  />
                </div>
                <div style={{ marginLeft: 30 }}>
                  <div>
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 24,
                          color: "white",
                          marginBottom: 0,
                        }}>
                        {t("about:jobSeekers.title")}
                      </p>
                      <Item items={js} color={"white"} />
                    </div>
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 24,
                          color: "white",
                          marginBottom: 0,
                        }}>
                        {t("about:recruiters.title")}
                      </p>
                      <Item items={re} color={"white"} />
                    </div>
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: 24,
                          color: "white",
                          marginBottom: 0,
                        }}>
                        {t("about:educationalInstitutions.title")}
                      </p>
                      <Item items={ei} color={"white"} />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{
              width: "100vw",
              paddingTop: 60,
              backgroundColor: palette.secondary,
              paddingBottom: 80,
            }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: 32,
                color: palette.primarydark,
              }}>
              {t("about:ourAction.title")}
            </p>
            <Grid
              container
              justify="center"
              style={{
                width: "100vw",
              }}>
              {oa.map((v, k) => {
                var odd = k % 2;
                return (
                  <div
                    key={k}
                    style={{
                      width: "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}>
                    <div
                      style={{
                        marginTop: odd === 0 ? 0 : 80,
                        height: 90,
                        width: 90,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: palette.primarydark,
                        fontSize: 36,
                      }}>
                      <img src={imgOA[k]} alt={"oa-" + k} />
                    </div>
                    <p
                      style={{
                        fontSize: 18,
                        width: 187,
                        textAlign: "center",
                        color: palette.primarydark,
                      }}>
                      {v}
                    </p>
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <Footer />
        </div>
      );
    }
  }
}
export default withTranslation()(AboutPage);
