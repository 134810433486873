import { v4 as uuid } from "uuid";

export const getDeviceId = () => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.log("enumerateDevices() not supported.");
    return;
  }
  return navigator.mediaDevices
    .enumerateDevices()
    .then(function (devices) {
      var defaultGroup, defaultDeviceId;
      devices.forEach(device => {
        if (defaultGroup === device.groupId) {
          return device.deviceId;
        }
        if (device.deviceId === "default") {
          defaultGroup = device.groupId;
        } else {
          defaultDeviceId = device.deviceId;
        }
      });
      return defaultDeviceId;
    })
    .catch(function (err) {
      console.log(err.name + ": " + err.message);
      return uuid();
    });
};
