import React from "react";
import { Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { ArrowForward } from "@material-ui/icons";
import { observer } from "mobx-react";
import { General } from "app/utils";
export const NextBtn = observer(props => {
  const {
    color,
    text,
    onClick,
    style,
    fontWeight,
    textStyle,
    iconSize,
    type,
    underline,
    disabled,
    validate,
    id,
    fontSize,
  } = props;
  return (
    <Button
      disabled={
        validate ? (!General.visible || disabled ? true : false) : false
      }
      id={id ? id : "ButtonNex.js_" + new Date().getTime()}
      type={type ? type : "button"}
      onClick={onClick}
      style={{
        ...style,
        opacity: validate ? (!General.visible || disabled ? 0.5 : 1) : 1,
      }}>
      <span
        style={{
          fontSize: fontSize ? fontSize : 24,
          fontWeight: fontWeight ? fontWeight : "bolder",
          color: color ? color : palette.primary,
          textTransform: "capitalize",
          marginRight: 5,
          textDecoration:
            underline === undefined
              ? "underline"
              : underline
              ? "underline"
              : "none",
          ...textStyle,
        }}>
        {text}
      </span>
      <ArrowForward
        style={{ color: color ? color : palette.primary, ...iconSize }}
      />
    </Button>
  );
});
