import React from "react";
import { findObject, getItem, removeObject } from "app/utils";
import Select2 from "react-select";
import PropTypes from "prop-types";
/**
 * @extends {React.Component<{value:any, data:array}>}
 */
class OptionDropdown extends React.Component {
  static propTypes = {
    value: PropTypes.any,
    data: PropTypes.any,
    maximum: PropTypes.number,
    onChange: PropTypes.func,
  };
  static defaultProps = {
    value: "",
    data: [],
    maximum: undefined,
    onChange: () => {},
  };
  state = {
    answers: this.props.value ? this.props.value.responseAnswers : [],
    data: this.props.data,
    dataObj: [],
  };

  componentDidMount() {
    var tmp1 = [
      {
        value: "253251ca-bf29-4624-9e6b-4b7dd120dbb1",
        label:
          getItem("lang") === "en"
            ? "Any location in indonesia"
            : "Dimana saja di indonesia",
      },
    ];
    var tmp = this.state.data.workAnswers.map(v => {
      return {
        key: "xx" + v.answerId,
        value: v.answerId,
        label:
          getItem("lang") === "en"
            ? v.answerTextEng.toLowerCase()
            : v.answerTextIna.toLowerCase(),
      };
    });
    this.setState({ dataObj: [...tmp1, ...tmp] }, () => {
      this.setDisplayAnswer();
    });
  }

  setDisplayAnswer() {
    var tmp = this.state.answers.map(v => {
      return findObject(this.state.dataObj, "value", v.answerId);
    });
    this.setState({ answers: tmp });
  }

  setAnswer(val) {
    if (findObject(val, "value", "253251ca-bf29-4624-9e6b-4b7dd120dbb1")) {
      if (val.length > 1) {
        if (
          findObject(
            this.state.answers,
            "value",
            "253251ca-bf29-4624-9e6b-4b7dd120dbb1"
          )
        ) {
          var tmp = removeObject(
            val,
            "value",
            "253251ca-bf29-4624-9e6b-4b7dd120dbb1"
          );
          this.setState({ answers: tmp }, () => {
            this.returnValue();
          });
        } else {
          this.setState({ answers: [this.state.dataObj[0]] }, () => {
            this.returnValue();
          });
        }
      } else {
        this.setState({ answers: val }, () => {
          this.returnValue();
        });
      }
    } else {
      if (this.props.maximum) {
        if (val.length <= this.props.maximum) {
          this.setState({ answers: val }, () => {
            this.returnValue();
          });
        }
      } else {
        this.setState({ answers: val }, () => {
          this.returnValue();
        });
      }
    }
  }
  returnValue() {
    var tmp = this.state.answers.map(v => {
      return { answerId: v.value };
    });
    this.props.onChange(tmp);
  }
  render() {
    const { answers, data, dataObj } = this.state;

    return (
      <>
        <Select2
          closeMenuOnSelect
          key={new Date().getTime()}
          className="Select-work-location"
          classNamePrefix="select"
          placeholder={
            getItem("lang") === "en"
              ? data.questionTextEng
              : data.questionTextIna
          }
          isMulti
          menuColor="red"
          styles={{ padding: 5 }}
          onChange={e => this.setAnswer(e)}
          options={dataObj}
          value={answers ? answers : []}
        />
      </>
    );
  }
}
export default OptionDropdown;
// export default withTranslation()(OptionDropdown);
