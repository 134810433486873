import React from "react";
import { Grid, Button } from "@material-ui/core";
import "./custom.css";
import {
  IconOption,
  IconOptionClicked,
  IconQuestion,
  ImgQuestionBackgroundTop,
} from "assets/img";
import { getItem } from "app/utils";

export default class Option extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
    };
    this.translate = this.props.t;
  }

  tooltip(att) {
    
  }

  setActive(act, imgSrc) {
    this.setState({ active: act });
  }

  render() {
    const { style, options } = this.props;
    return (
      <Grid 
        container 
        style={{ ...style, marginBottom: 20 }}
      >
        {options.map((v, i) => {
          return (
            <Grid
              item lg={4} md={4} sm={4} key={i}
            >
              <div>
                <Grid container>
                  <Grid
                    item lg={12} md={12} sm={12}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={v.imageUrlEng}
                      height={"150vh"}
                      alt={v.id}
                    />
                  </Grid>

                  <Grid
                    item lg={7} md={7} sm={7} 
                    style={{
                      marginTop: "2%",
                      color: "#2F3168",
                      textTransform: "uppercase",
                      fontSize: "12px",
                      textAlign: "right",
                    }}
                  >
                    {v.softskillValueEng}
                  </Grid>
                  <Grid
                    item lg={5} md={5} sm={5}
                    style={{
                      marginTop: "1%"
                    }}
                  >
                    <img
                      className="question-tag"
                      style={{
                        marginLeft: 10, 
                        marginBottom: -2
                      }}
                      src={IconQuestion}
                      height={"15h"}
                      alt="QuestionTag"
                    />

                    <div 
                      className="question"
                      style={{
                        position: "absolute",
                        marginLeft: -182, 
                        marginTop: -176
                      }}
                    >
                      <img
                        src={ImgQuestionBackgroundTop}
                        height={"155vh"}
                        // width=
                        alt="question"
                      />
                      
                      <div
                        style={{
                          position: "absolute",
                          color: "white",
                          fontSize: "12px",
                          textTransform: "none",
                          top: "10%",
                          left: "2%",
                          right: "2%",
                          textAlign: "justify"
                        }}
                      >
                        {getItem("lang") === "en" ? (
                          v.attributeEng.split("\r\n").map(val => {
                            return(
                              <>
                                {val}<br/>
                              </>
                            )
                          })
                        ) : (
                          v.attributeIna.split("\r\n").map(val => {
                            return(
                              <>
                                {val}<br/>
                              </>
                            )
                          })
                        )}
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    item lg={12} md={12} sm={12}
                    style={{
                      margin: "2% auto",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        this.setActive(v.id, IconOption);
                        this.props.onChange(v.id);
                      }}
                    >
                      <img className="radio"
                        src={this.state.active === v.id ? IconOptionClicked : this.props.currentValue === v.id ? IconOptionClicked : IconOption}
                        // src={this.state.active === v.answerId ? IconOptionClicked : IconOption}
                        height={"15vh"}
                        alt={v.id}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
