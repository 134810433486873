import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { isMobile } from "react-device-detect";
const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: "white",
    // marginTop: theme.spacing(8),
    padding: "20px 8%",
  },
}));

function Footer(props) {
  const classes = useStyles();
  const { t } = props;
  return (
    <footer className={classes.footer}>
      <Grid
        container
        justify="space-between"
        direction={isMobile ? "column" : "row"}
        alignItems="center">
        <Grid item lg={6} md={6} sm={6}>
          <Grid
            container
            direction={isMobile ? "column" : "row"}
            alignItems="center">
            <Link
              to="/home/about"
              style={{ padding: "5px 20px", color: palette.primary }}>
              {t("link.about")}
            </Link>
            <Link
              to="/home/contact"
              style={{ padding: "5px 20px", color: palette.primary }}>
              {t("link.contact")}
            </Link>
            <Link
              to="/home/term-condition"
              style={{ padding: "5px 20px", color: palette.primary }}>
              {t("link.tnc")}
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          sm={6}
          style={{
            fontWeight: "lighter",
            textAlign: "right",
            color: palette.primary,
            padding: "5px 0px",
          }}>
          &copy;&nbsp;PT. Daya Selaras Karya - 2020
        </Grid>
      </Grid>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
export default withTranslation()(Footer);
