import React from "react";
import { withTranslation } from "react-i18next";
import { AppBar, Toolbar, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { loadImage, getItem } from "app/utils";
import { IconConfirmationCheck } from "assets/img";
import { palette } from "assets/css/main";
import { NextBtn } from "app/components";

class RecruiterSuccessCreatePassword extends React.Component {
  state = {
    link: "",
    successContent: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      link: "/user/login/applicant",
    };
  }
  componentDidMount() {
    const { t } = this.props;
    var params = new URLSearchParams(this.props.location.search);
    var type = window.atob(params.get("code"));
    var successContent = t("auth:createPassword.successContent");
    if (type === "Recruiter") {
      this.setState({
        link: "/user/login/recruiter",
        successContent: successContent,
      });
    } else {
      successContent =
        getItem("lang") === "en"
          ? t("auth:createPassword.successContent")
          : t("auth:createPassword.successContent_ap");
      this.setState({
        link: "/user/login/applicant",
        successContent: successContent,
      });
    }
  }
  render() {
    const { t } = this.props;
    const { successContent, link } = this.state;
    return (
      <div>
        <AppBar
          position="absolute"
          color="default"
          style={{
            backgroundColor: "white",
            top: 0,
            height: 80,
            bottom: "auto",
            position: "fixed",
          }}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ height: 80 }}>
              <Grid item lg={2}>
                <Link to="/" className="centered">
                  <img
                    alt="Hiregrade-Logo"
                    src={loadImage("HiregradeLogo.png")}
                    width={150}
                    style={{ marginLeft: 87 }}
                  />
                </Link>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <main style={{ paddingTop: 210 }} className="validate">
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column">
            <img
              src={IconConfirmationCheck}
              alt="Success"
              width={71}
              height={71}
            />
            <Typography
              style={{
                fontSize: 24,
                width: 450,
                marginBottom: 24,
                paddingTop: 56,
                fontWeight: "bold",
                color: palette.primary,
                textAlign: "center",
              }}>
              {t("auth:createPassword.successTitle")}
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                width: 400,
                marginBottom: 180,
                color: palette.primary,
                textAlign: "center",
              }}>
              {successContent}
            </Typography>
            <NextBtn
              text={t("auth:validateEmail.linkLogin")}
              onClick={() => {
                window.location.href = link;
              }}
            />
          </Grid>
        </main>
      </div>
    );
  }
}
export default withTranslation()(RecruiterSuccessCreatePassword);
