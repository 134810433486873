import i18next from "i18next";

export { default as UserSession } from "./session/UserSession";
export * from "./general/Storage";
export * from "./general/HttpClient";
export * from "./general/ImageLoader";
export { default as General } from "./general/general";
export { default as AppHistory } from "./general/history";
export * from "./general/device";
export const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
export const numberRegex = /(^$)|(^\d+$)/;
export const stringRegex = /(^$)|(^[A-Za-z\s]+$)/;
export const stnumRegex = /(^$)|(^[A-Za-z0-9]+$)/;
export const nameRegex = /(^$)|(^[A-Za-z\s',.]+$)/;
export const currencyRegex = /(\d)(?=(\d\d\d)+(?!\d))/g;
export const dotRegex = /\./g;

export const removeArray = (array, value, allowNull = true) => {
  var tmp = [];
  array.forEach(v => {
    if (v !== value) {
      tmp.push(v);
    }
  });
  return tmp;
};

export const removeArrayWithIndex = (array, index) => {
  var tmp = [];
  array.forEach((v, i) => {
    if (i !== index) {
      tmp.push(v);
    }
  });
  return tmp;
};

export const findWithAttr = (array, attr, value, returnKey) => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      if (returnKey) {
        return array[i][returnKey];
      } else {
        return array[i][attr];
      }
    }
  }
  return undefined;
};

export const findObject = (array, attr, value, returnNull = true) => {
  for (var i = 0; i < array.length; i += 1) {
    try {
      var item = array[i][attr].toString();
      var compare = value.toString();
      if (item.toLowerCase() === compare.toLowerCase()) {
        return array[i];
      }
    } catch (error) {
      return returnNull
        ? null
        : {
            findWithkey: attr,
            value: value,
            status: "Not Found",
          };
    }
  }
  return returnNull
    ? null
    : {
        findWithkey: attr,
        value: value,
        status: "Not Found",
      };
};

export const isEqual = (objA, objB) => {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(objA);
  var bProps = Object.getOwnPropertyNames(objB);
  // If count of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    // If values of same property are not equal,
    // objects are not equivalent
    if (objA[propName] !== objB[propName]) {
      return false;
    }
  }
  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const findDuplicate = (array = [], obj = {}) => {
  var c = 0;
  array.forEach((v, i) => {
    array.forEach((x, k) => {
      if (i !== k) {
        if (isEqual(v, x)) {
          c += 1;
        }
      }
    });
  });
  return c > 1 ? true : false;
};

export const findObjectKey = (array, attr, value) => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return null;
};

export const removeObject = (array, attr, value) => {
  var result = [];
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] !== value) {
      result.push(array[i]);
    }
  }
  return result;
};

export const currencyFormat = value => {
  var result = value.toString().replace(dotRegex, "");
  if (parseInt(result) > 999) {
    result = result.toString().replace(currencyRegex, "$1.");
  }

  return result;
};

export const removeCurrency = value => {
  var result = value.toString().replace(dotRegex, "");

  return result;
};

export const toDataUrl = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.onerror = e => {
    callback(e);
  };
  xhr.open("GET", url);
  // xhr.setRequestHeader("access-control-allow-origin", "*");
  xhr.responseType = "blob";
  xhr.send();
};

export const formatYmd = Ymd => {
  try {
    if (Ymd.split("-").length > 0) {
      var d = Ymd.split("-");
      return d[2] + "-" + d[1] + "-" + d[0];
    } else {
      return "Invalid YYYY-MM-DD";
    }
  } catch (error) {
    return "Invalid date";
  }
};

export const base64toBlob = (base64Data, contentType) => {
  contentType = contentType || "";
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const utcToGmt = (time = null) => {
  if (!time) {
    time = new Date();
  }
  if (!(time instanceof Date)) {
    if (time.indexOf("T") > 0 && time.indexOf("Z") < 0) {
      time += ".000Z";
    } else {
      time = new Date(time);
    }
  }
  var aestTime = time.toLocaleString("en-US", {
    timeZone: "Asia/Jakarta",
  });
  return new Date(aestTime).toISOString();
};

/**
 *
 * @param {String} key
 * @param {Object} options
 */
export const t = (key = "", options = {}) => {
  return i18next.t(key, options);
};
