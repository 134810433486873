import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import RecruiterFooter from "app/components/footer/RecruiterFooter";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import StepHeader from "app/components/header/StepHeader";
import { palette } from "assets/css/main";
import {
  Typography,
  Grid,
  Select as SelectDefault,
  MenuItem,
  InputLabel,
  TextField,
  Button,
} from "@material-ui/core";
import { MultipleTextField } from "app/components";
import {
  RequestGet,
  currencyFormat,
  removeCurrency,
  GetLocalItem,
  SetLocalItem,
  AppHistory,
  findObject,
  General,
} from "app/utils";
import data from "./data";
import {
  IconSuggestion,
  IconInformation,
  ImgInformationBackground,
} from "assets/img";
import Select from "react-select";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { getItem } from "app/utils";
import alertData from "app/components/alert/alert-data";
import "./custom.css";

const creatableSelectStyles = {
  borderWidth: "0px 0px 1px 0px",
  borderRadius: "0px",
};

class VacancyDetail extends React.Component {
  creatableRef = null;
  ddlRef = null;
  constructor(props) {
    super(props);
    if (GetLocalItem("vacancyPost")) {
      data.vacancy_post = GetLocalItem("vacancyPost");
    }

    this.state = {
      ...data.vacancy_post,
      listVacancy: data.listVacancy,
      loaded: false,
      other: "",
      workLocs: [],
      listWorkLocation: [],
      listjobfamily: [],
      benefits: [],
      listBenefit: [],
      otherBenefitLocal: [],
      otherBenefitID: "",
      classMenu: "",
      maxError: false,
      fromExisting: GetLocalItem("VacancySummary")
        ? GetLocalItem("VacancySummary")
        : { vacancyActives: [], vacancyArchives: [] },
      listExisting: [],
      copyFrom: GetLocalItem("CopyFrom") ? GetLocalItem("CopyFrom") : [],
      partner: null,
    };
    this.translate = this.props.t;
  }

  cekValidation() {
    var result = false;

    if (
      data.vacancy_post.vacancy.jobFamily !== "" &&
      data.vacancy_post.vacancy.jobTitle !== "" &&
      data.vacancy_post.vacancyWorkLocation.length > 0 &&
      data.vacancy_post.vacancyJobDesc[0] !== "" &&
      data.vacancy_post.vacancyBenefits.length > 0 &&
      data.vacancy_post.vacancy.salaryMin > 0 &&
      data.vacancy_post.vacancy.vacancyNumber > 0
    ) {
      result = true;
    }
    return result;
  }

  saveData() {
    if (this.cekValidation()) {
      if (this.state.maxError === false) {
        SetLocalItem("vacancyPost", data.vacancy_post);
        SetLocalItem("CopyFrom", this.state.copyFrom);
        if (data.vacancy_post.vacancy.vacancyType === "Copy Existing") {
          AppHistory.push("/recruiter/confirmation");
        } else {
          AppHistory.push("/recruiter/job-requirement");
        }
      }
    } else {
      alertData.show(this.props.t("vacancy:create.validation"), "error");
    }
  }

  setup(list) {
    General.loading = true;

    var ot = list.jobFamilies.find(p => p.jobFamilyEng === "Other").id;

    data.listVacancy = list;
    this.handleVacancy({
      companyProfileId: JSON.parse(getItem("company_info")).id,
    });

    // benefit
    var otBenefit = list.benefits.find(p => p.benefit === "Others").id;
    this.setState({ otherBenefitID: otBenefit });
    this.setState(
      {
        listVacancy: list,
        other: ot,
        listBenefit: list.benefits.map(obj => {
          return {
            value: obj.id,
            label: getItem("lang") === "en" ? obj.benefit : obj.benefitIna,
          };
        }),
        benefits: data.vacancy_post.vacancyBenefits.map(v => {
          return {
            value: v,
            label:
              getItem("lang") === "en"
                ? list.benefits.find(p => p.id === v).benefit
                : list.benefits.find(p => p.id === v).benefitIna,
          };
        }),
      },
      () => {
        this.setState({ loaded: true });
        General.loading = false;
      }
    );

    list.locationCity.forEach((obj, i) => {
      if (obj.id === 9999) {
        const listWorkLocation = this.state.listWorkLocation;
        var listWorkLocationend = {
          value: obj.id,
          label:
            getItem("lang") === "en"
              ? obj.name
              : this.props.t("vacancy:create.vacancyDetail.dropdown"),
        };

        const listWorkLocationnew = [listWorkLocationend].concat(
          listWorkLocation
        );
        this.setState({ listWorkLocation: listWorkLocationnew });
      } else {
        const listWorkLocation = this.state.listWorkLocation;
        listWorkLocation[i] = {
          value: obj.id,
          label: obj.name,
        };
        this.setState({ listWorkLocation: listWorkLocation });
      }
    });

    //listjobfamily
    this.setState({
      listjobfamily: list.jobFamilies.map(obj => {
        return {
          value: obj.id,
          label: getItem("lang") === "en" ? obj.jobFamilyEng : obj.jobFamilyIna,
        };
      }),
    });

    this.setState({
      workLocs: data.vacancy_post.vacancyWorkLocation.map(v => {
        return {
          value: v,
          label:
            v === 9999
              ? this.props.t("vacancy:create.vacancyDetail.dropdown")
              : list.locationCity.find(p => p.id === v).name,
        };
      }),
    });
  }

  getVacancyDetail() {
    if (GetLocalItem("vacancyGet")) {
      this.setup(GetLocalItem("vacancyGet"));
    } else {
      RequestGet("vacancy")
        .then(res => {
          SetLocalItem("vacancyGet", res.data);
          this.setup(res.data);
        })
        .catch(err => {
          console.log(err);
          alertData.show(String(err.status), "error");
        });
    }
  }

  getPartner() {
    RequestGet("partner")
      .then(res => {
        this.setState({ partner: res.data });
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  componentDidMount() {
    var tmp = [];
    General.loading = true;
    this.state.fromExisting.vacancyActives.map(v => {
      return tmp.push(v);
    });
    this.state.fromExisting.vacancyArchives.map(v => {
      return tmp.push(v);
    });
    this.setState({ listExisting: tmp });

    this.getVacancyDetail();
    this.getPartner();
  }

  handleSuggestion(value) {
    var currentBenefit = this.state.benefits;
    currentBenefit.push(value);
    this.setState(
      {
        benefits: currentBenefit,
        classMenu: "add_from_suggestion",
      },
      () => {
        this.creatableRef.focus();
      }
    );

    setTimeout(() => {
      this.creatableRef.blur();
      this.setState({ classMenu: "" });
    }, 100);

    var val = currentBenefit.map(v => {
      return v.value;
    });
    this.setState({ vacancyBenefits: val });
    data.setVacancyPost({ vacancyBenefits: val });
  }

  onClickTest(obj) {
    console.log(obj);
  }

  handleVacancy(obj) {
    var newVacancy = data.vacancy_post.vacancy;
    newVacancy = { ...newVacancy, ...obj };
    this.setState({ vacancy: newVacancy });
    data.setVacancyPost({ vacancy: newVacancy });

    if (
      data.vacancy_post.vacancy.salaryMin > data.vacancy_post.vacancy.salaryMax
    ) {
      this.setState({ maxError: true });
    } else {
      this.setState({ maxError: false });
    }
  }

  handleVacancyEx(obj) {
    var newVacancy = data.vacancy_post.vacancy;
    newVacancy = { ...newVacancy, ...obj };
    this.setState({ vacancy: newVacancy });
    data.setVacancyPost({ vacancy: newVacancy });
  }

  handleRequirement(obj) {
    var newReq = data.vacancy_post.vacancyRequirement;
    newReq = { ...newReq, ...obj };
    this.setState({ vacancyRequirement: newReq });
    data.setVacancyPost({ vacancyRequirement: newReq });
  }

  getVacancyExisting(vacancyId) {
    RequestGet("vacancy/" + vacancyId)
      .then(res => {
        this.setState({ listVacancy: res.data });
        data.listVacancy = res.data;
        this.handleVacancyEx({
          companyProfileId: JSON.parse(getItem("company_info")).id,
        });

        // add current vacancies
        this.handleVacancyEx({ jobFamily: res.data.vacancy.jobFamily });
        this.handleVacancyEx({ subJobFamily: res.data.vacancy.subJobFamily });
        this.handleVacancyEx({ vacancyNumber: res.data.vacancy.vacancyNumber });
        this.handleVacancyEx({ salaryMin: res.data.vacancy.salaryMin });
        this.handleVacancyEx({ salaryMax: res.data.vacancy.salaryMax });
        this.handleVacancyEx({ otherValue: res.data.vacancy.otherValue });

        this.handleRequirement({
          languageSkill: res.data.listVacancyRequirement.languageSkill,
        });
        this.handleRequirement({
          certificate: res.data.listVacancyRequirement.certificate,
        });
        this.handleRequirement({
          hasCertificate: res.data.listVacancyRequirement.hasCertificate,
        });

        var newBenefits =
          res.data.listVacancyBenefit.length > 0
            ? res.data.listVacancyBenefit.map(v => {
                return v.benefit;
              })
            : [];
        this.setState({ vacancyBenefits: newBenefits });
        data.setVacancyPost({ vacancyBenefits: newBenefits });

        var newWorkLocations =
          res.data.listVacancyWorkLocation.length > 0
            ? res.data.listVacancyWorkLocation.map(v => {
                return v.workLocation;
              })
            : [];
        this.setState({ vacancyWorkLocation: newWorkLocations });
        data.setVacancyPost({ vacancyWorkLocation: newWorkLocations });

        var newJobDesc =
          res.data.listVacancyJobDesc.length > 0
            ? res.data.listVacancyJobDesc.map(v => {
                return v.jobDescription;
              })
            : [""];
        this.setState({ vacancyJobDesc: newJobDesc });
        data.setVacancyPost({ vacancyJobDesc: newJobDesc });

        var newCertificates =
          res.data.listVacancyRequirementCertificates.length > 0
            ? res.data.listVacancyRequirementCertificates.map(v => {
                return v.specificCertificate;
              })
            : [""];
        this.setState({ vacancyRequirementCertificate: newCertificates });
        data.setVacancyPost({ vacancyRequirementCertificate: newCertificates });

        var newWorkEnvs =
          res.data.listVacancyRequirementEnvironmentWork.length > 0
            ? res.data.listVacancyRequirementEnvironmentWork.map(v => {
                return v.environmentWorkId;
              })
            : [];
        this.setState({ vacancyRequirementEnvironmentWork: newWorkEnvs });
        data.setVacancyPost({ vacancyRequirementEnvironmentWork: newWorkEnvs });

        var newJobPerforms =
          res.data.listVacancyRequirementJobPerform.length > 0
            ? res.data.listVacancyRequirementJobPerform.map(v => {
                return v.jobPerformId;
              })
            : [];
        this.setState({ vacancyRequirementJobPerform: newJobPerforms });
        data.setVacancyPost({ vacancyRequirementJobPerform: newJobPerforms });

        var newSoftSkills =
          res.data.listVacancySoftskill.length > 0
            ? res.data.listVacancySoftskill.map(v => {
                return {
                  softskillHeaderId: v.softskillHeaderId,
                  softskillDetailId: v.softskillDetailId,
                };
              })
            : [
                {
                  softskillHeaderId: "",
                  softskillDetailId: "",
                },
              ];
        this.setState({ vacancySoftskills: newSoftSkills });
        data.setVacancyPost({ vacancySoftskills: newSoftSkills });
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  handleExisting(val) {
    this.setState({ copyFrom: val });
    this.getVacancyExisting(val);
  }

  render() {
    const {
      other,
      loaded,
      listVacancy,
      vacancy,
      vacancyJobDesc,
      benefits,
      listBenefit,
      otherBenefitLocal,
      otherBenefitID,
      workLocs,
      listWorkLocation,
      listjobfamily,
      classMenu,
      maxError,
      listExisting,
      copyFrom,
      partner,
    } = this.state;
    const { t } = this.props;
    const stepData = t("vacancy:create.step", { returnObjects: true });

    return (
      <div
        className="create-vacancy font-hurme"
        style={{ position: "relative" }}>
        <MyHelmet title="Dashboard" />
        <RecruiterAppBar
          shadow={true}
          menu="dashboard"
          history={this.props.history}
        />
        <StepHeader
          data={stepData}
          stepWidth={650}
          activeStep={0}
          isAZ={true}
          style={{
            padding: "0px 100px",
            marginTop: 80,
            backgroundColor: palette.info,
          }}
        />
        <Grid container style={{ padding: "3% 12% 5% 22%" }}>
          <Grid item lg={12} md={12} sm={12}>
            <Typography
              style={{
                fontSize: "24px",
                color: palette.primary,
              }}>
              {t("vacancy:create.vacancyDetail.title")}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12}>
            <Typography
              style={{
                fontSize: "14px",
                color: palette.primary,
              }}>
              {t("vacancy:create.vacancyDetail.desc")}
            </Typography>
          </Grid>

          {partner && (
            <>
              {partner.isPartner && (
                <Grid item lg={12} md={12} sm={12} style={{ marginTop: 56 }}>
                  <div>
                    <InputLabel
                      shrink
                      id="vacancy"
                      style={{ color: palette.primary, marginBottom: 15 }}>
                      {t("vacancy:create.vacancyDetail.vacancyOpenFor.label")}
                    </InputLabel>
                    <SelectDefault
                      labelId="vacancy"
                      style={{
                        color: palette.primary,
                        fontWeight: "bold",
                        width: "75%",
                      }}
                      className="Field select-with-placeholder"
                      value={vacancy.isPrivate}
                      onChange={e => {
                        this.handleVacancy({ isPrivate: e.target.value });
                      }}>
                      <MenuItem value={true}>
                        <Grid container>
                          <Grid item lg={12} md={12} sm={12}>
                            <Typography
                              style={{
                                fontSize: "24px",
                                lineHeight: "23px",
                                fontWeight: "bold",
                                color: palette.primary,
                              }}>
                              {
                                t(
                                  "vacancy:create.vacancyDetail.vacancyOpenFor.options",
                                  {
                                    returnObjects: true,
                                  }
                                )[0]
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            className={"VacancyOpenDesc"}>
                            <Typography
                              style={{
                                marginTop: "7px",
                                fontSize: "18px",
                                lineHeight: "18px",
                                color: palette.primary,
                              }}>
                              {
                                t(
                                  "vacancy:create.vacancyDetail.vacancyOpenFor.optionDesc",
                                  {
                                    returnObjects: true,
                                  }
                                )[0]
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                      <MenuItem
                        style={{ color: palette.primary }}
                        value={false}>
                        <Grid container>
                          <Grid item lg={12} md={12} sm={12}>
                            <Typography
                              style={{
                                fontSize: "24px",
                                lineHeight: "23px",
                                fontWeight: "bold",
                                color: palette.primary,
                              }}>
                              {
                                t(
                                  "vacancy:create.vacancyDetail.vacancyOpenFor.options",
                                  {
                                    returnObjects: true,
                                  }
                                )[1]
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            className={"VacancyOpenDesc"}>
                            <Typography
                              style={{
                                marginTop: "7px",
                                fontSize: "18px",
                                lineHeight: "18px",
                                color: palette.primary,
                              }}>
                              {
                                t(
                                  "vacancy:create.vacancyDetail.vacancyOpenFor.optionDesc",
                                  {
                                    returnObjects: true,
                                  }
                                )[1]
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    </SelectDefault>
                  </div>
                </Grid>
              )}

              <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
                <div>
                  <InputLabel
                    shrink
                    id="vacancy"
                    style={{ color: palette.primary, marginBottom: 15 }}>
                    {t("vacancy:create.vacancyDetail.vacancyType.label")}
                  </InputLabel>
                  <SelectDefault
                    labelId="vacancy"
                    style={{
                      color: palette.primary,
                      fontWeight: "bold",
                      width: "75%",
                    }}
                    className="Field select-with-placeholder"
                    value={vacancy.vacancyType ? vacancy.vacancyType : " "}
                    onChange={e => {
                      data.setEmptyVacancy();
                      SetLocalItem("vacancyPost", null);
                      SetLocalItem("CopyFrom", null);
                      this.setState({ copyFrom: null });
                      this.handleVacancy({
                        companyProfileId: JSON.parse(getItem("company_info"))
                          .id,
                      });
                      this.handleVacancy({ vacancyType: e.target.value });
                    }}>
                    <MenuItem
                      style={{ color: palette.primary }}
                      value=" "
                      disabled>
                      <span>
                        {t(
                          "vacancy:create.vacancyDetail.vacancyType.placeholder"
                        )}
                      </span>
                    </MenuItem>
                    <MenuItem
                      style={{ color: palette.primary }}
                      value="Create New">
                      {
                        t("vacancy:create.vacancyDetail.vacancyType.options", {
                          returnObjects: true,
                        })[0]
                      }
                    </MenuItem>
                    <MenuItem
                      style={{ color: palette.primary }}
                      value="Copy Existing">
                      {
                        t("vacancy:create.vacancyDetail.vacancyType.options", {
                          returnObjects: true,
                        })[1]
                      }
                    </MenuItem>
                  </SelectDefault>
                </div>
              </Grid>
            </>
          )}
          {loaded && (
            <>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                hidden={vacancy.vacancyType === "Create New" ? false : true}>
                <Grid
                  item
                  lg={9}
                  md={9}
                  sm={9}
                  style={{ marginTop: 40, backgroundColor: "" }}>
                  <div>
                    <InputLabel
                      shrink
                      id="vacancy"
                      style={{ color: palette.primary, marginBottom: 15 }}>
                      {t("vacancy:create.vacancyDetail.jobFamily.label")}
                    </InputLabel>
                    <Select
                      className={classMenu}
                      ref={ref => {
                        this.ddlReff = ref;
                      }}
                      openMenuOnFocus={true}
                      classNamePrefix="changecolorselect"
                      placeholder={t(
                        "vacancy:create.vacancyDetail.jobFamily.placeholder"
                      )}
                      options={listjobfamily}
                      value={
                        vacancy.jobFamily
                          ? listjobfamily.find(
                              p => p.value === vacancy.jobFamily
                            )
                          : " "
                      }
                      isSearchable={true}
                      onChange={v => {
                        if (v === null) {
                          this.handleVacancy({ jobFamily: "" });
                        } else {
                          this.handleVacancy({ jobFamily: v.value });
                          this.handleVacancy({ subJobFamily: "" });
                          this.handleVacancy({ otherValue: "" });
                          this.setState({ classMenu: "add_from_dds" }, () => {
                            this.ddlReff.focus();
                          });

                          setTimeout(() => {
                            this.ddlReff.blur();
                            this.setState({ classMenu: "" });
                          }, 100);
                        }
                      }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  style={{
                    textAlign: "right",
                    marginTop: -62,
                    backgroundColor: "",
                  }}
                  hidden={vacancy.jobFamily === other ? false : true}>
                  <TextField
                    style={{ fontSize: 24, width: 200 }}
                    autoFocus={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t(
                      "vacancy:create.vacancyDetail.jobFamily.otherPlaceholder"
                    )}
                    className="Field"
                    onChange={v => {
                      this.handleVacancy({ otherValue: v.target.value });
                    }}
                    value={vacancy.otherValue}
                  />
                </Grid>

                <Grid
                  item
                  lg={9}
                  md={9}
                  sm={9}
                  style={{ marginTop: 40 }}
                  hidden={
                    vacancy.jobFamily === "" || vacancy.jobFamily === other
                      ? true
                      : false
                  }>
                  <div>
                    <InputLabel
                      shrink
                      id="vacancy"
                      style={{ color: palette.primary, marginBottom: 15 }}>
                      {t("vacancy:create.vacancyDetail.subJobFamily.label")}
                    </InputLabel>
                    <Select
                      className={classMenu}
                      ref={ref => {
                        this.ddlRefff = ref;
                      }}
                      openMenuOnFocus={true}
                      classNamePrefix="changecolorselect"
                      placeholder={t(
                        "vacancy:create.vacancyDetail.subJobFamily.placeholder"
                      )}
                      options={
                        !(
                          vacancy.jobFamily === "" ||
                          vacancy.jobFamily === other
                        )
                          ? listVacancy.jobFamilies
                              .find(p => p.id === vacancy.jobFamily)
                              .jobFamilyDetails.map((v, i) => {
                                return {
                                  value: v.id,
                                  label:
                                    getItem("lang") === "en"
                                      ? v.jobFamilyDetailEng
                                      : v.jobFamilyDetailIna,
                                };
                              })
                          : null
                      }
                      value={
                        !(
                          vacancy.subJobFamily === "" ||
                          vacancy.jobFamily === other
                        )
                          ? listVacancy.jobFamilies
                              .find(p => p.id === vacancy.jobFamily)
                              .jobFamilyDetails.map((v, i) => {
                                return {
                                  value: v.id,
                                  label:
                                    getItem("lang") === "en"
                                      ? v.jobFamilyDetailEng
                                      : v.jobFamilyDetailIna,
                                };
                              })
                              .find(p => p.value === vacancy.subJobFamily)
                          : ""
                      }
                      isSearchable={true}
                      onChange={v => {
                        if (v === null) {
                          this.handleVacancy({ subJobFamily: "" });
                        } else {
                          this.handleVacancy({ subJobFamily: v.value });
                          this.setState({ classMenu: "add_from_dds" }, () => {
                            this.ddlRefff.focus();
                          });

                          setTimeout(() => {
                            this.ddlRefff.blur();
                            this.setState({ classMenu: "" });
                          }, 100);
                        }
                      }}
                    />
                  </div>
                </Grid>

                <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
                  <TextField
                    style={{ fontSize: 24, width: "75%" }}
                    autoFocus={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t(
                      "vacancy:create.vacancyDetail.jobTitle.placeholder"
                    )}
                    label={t("vacancy:create.vacancyDetail.jobTitle.label")}
                    className="Field"
                    onChange={v =>
                      this.handleVacancy({ jobTitle: v.target.value })
                    }
                    value={vacancy.jobTitle}
                  />
                </Grid>

                <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
                  <TextField
                    style={{ width: "75%" }}
                    autoFocus={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t(
                      "vacancy:create.vacancyDetail.numberOfVacancy.placeholder"
                    )}
                    label={t(
                      "vacancy:create.vacancyDetail.numberOfVacancy.label"
                    )}
                    className="Field"
                    onChange={v => {
                      if (v.target.value === "") {
                        this.handleVacancy({ vacancyNumber: 0 });
                      } else {
                        var stringVacancyNum = removeCurrency(
                          v.target.value
                        );
                        this.handleVacancy({
                          vacancyNumber: parseInt(stringVacancyNum),
                        });
                      }
                    }}
                    value={
                      String(vacancy.vacancyNumber)
                        ? currencyFormat(String(vacancy.vacancyNumber))
                        : "0"
                    }
                  />
                </Grid>

                <Grid item lg={9} md={9} sm={9} style={{ marginTop: 30 }}>
                  <div>
                    <InputLabel
                      shrink
                      id="WorkLocation"
                      style={{ color: palette.primary, marginBottom: 15 }}>
                      <Grid container>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          sm={3}
                          style={{
                            fontSize: "18px",
                            fontWeight: "300",
                          }}>
                          {t("vacancy:create.vacancyDetail.workLocation.label")}
                        </Grid>
                        <Grid item lg={9} md={9} sm={9}>
                          <img
                            className="information-tag"
                            style={{
                              marginBottom: -2,
                            }}
                            src={IconInformation}
                            height={"15h"}
                            alt="InformationTag"
                          />
                          <div
                            className="information"
                            style={{
                              position: "absolute",
                              marginLeft: -88,
                              marginTop: -80,
                            }}>
                            <img
                              src={ImgInformationBackground}
                              height={"54px"}
                              width={"190px"}
                              alt="Information"
                            />

                            <div
                              style={{
                                position: "absolute",
                                color: "white",
                                fontSize: 12,
                                textTransform: "none",
                                top: "20%",
                                left: "5%",
                              }}>
                              {t(
                                "vacancy:create.vacancyDetail.workLocation.hover"
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </InputLabel>

                    <Select
                      className={classMenu}
                      ref={ref => {
                        this.ddlRef = ref;
                      }}
                      openMenuOnFocus={true}
                      isOptionDisabled={option => 
                        findObject(workLocs, "value", 9999)
                          ? option.value !== 9999
                          : (!findObject(workLocs, "value", 9999) && workLocs.length > 0
                            ? option.value === 9999
                            : option.value === null)
                      }
                      classNamePrefix={"work-location"}
                      placeholder={t(
                        "vacancy:create.vacancyDetail.workLocation.placeholder"
                      )}
                      options={listWorkLocation}
                      value={workLocs}
                      isSearchable={true}
                      isMulti={true}
                      onChange={v => {
                        console.log(v);
                        if (v === null) {
                          this.setState({ workLocs: [] });
                          this.setState({ vacancyWorkLocation: [] });
                          data.setVacancyPost({ vacancyWorkLocation: [] });
                        } else {
                          this.setState({ workLocs: v });
                          var value = v.map(v => {
                            return v.value;
                          });

                          this.setState(
                            {
                              vacancyWorkLocation: value,
                              classMenu: "add_from_ddl",
                            },
                            () => {
                              this.ddlRef.focus();
                            }
                          );

                          setTimeout(() => {
                            this.ddlRef.blur();
                            this.setState({ classMenu: "" });
                          }, 100);
                          data.setVacancyPost({ vacancyWorkLocation: value });
                        }

                        console.log(workLocs);
                      }}
                    />
                  </div>
                </Grid>

                <div style={{ marginTop: 40 }}>
                  <MultipleTextField
                    style={{ width: "82%" }}
                    fieldData={vacancyJobDesc}
                    label={t("vacancy:create.vacancyDetail.jobDesc.label")}
                    placeholder={t(
                      "vacancy:create.vacancyDetail.jobDesc.placeholder"
                    )}
                    onChange={v => {
                      this.setState({ vacancyJobDesc: v });
                      data.setVacancyPost({ vacancyJobDesc: v });
                    }}
                  />
                </div>

                <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
                  <InputLabel
                    shrink
                    id="MultipleTextField"
                    style={{ color: palette.primary }}>
                    {t("vacancy:create.vacancyDetail.salaryRange.label")}
                  </InputLabel>
                </Grid>
                <div>
                  <Grid container>
                    <Grid
                      item
                      lg={1}
                      md={1}
                      sm={1}
                      style={{ textAlign: "right" }}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "24px",
                          color: palette.primary,
                          padding: "20px 30px",
                        }}>
                        Rp
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                      <TextField
                        onChange={v => {
                          if (v.target.value === "") {
                            this.handleVacancy({ salaryMin: 0 });
                          } else {
                            var stringSalaryMin = removeCurrency(
                              v.target.value
                            );
                            this.handleVacancy({
                              salaryMin: parseInt(stringSalaryMin),
                            });
                          }
                        }}
                        style={{ width: "100%" }}
                        autoFocus={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={"Eg. 1.000.000"}
                        className="Field"
                        value={
                          String(vacancy.salaryMin)
                            ? currencyFormat(String(vacancy.salaryMin))
                            : "0"
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      lg={2}
                      md={2}
                      sm={2}
                      style={{ textAlign: "right" }}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "24px",
                          color: palette.primary,
                          padding: "20px 30px",
                        }}>
                        - Rp
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                      <TextField
                        error={maxError}
                        helperText={maxError && "Incorrect value"}
                        onChange={v => {
                          if (v.target.value === "") {
                            this.handleVacancy({ salaryMax: 0 });
                          } else {
                            var stringSalaryMax = removeCurrency(
                              v.target.value
                            );
                            this.handleVacancy({
                              salaryMax: parseInt(stringSalaryMax),
                            });
                          }
                        }}
                        style={{ width: "95%" }}
                        autoFocus={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={"Eg. 2.000.000"}
                        className="Field"
                        value={
                          String(vacancy.salaryMax)
                            ? currencyFormat(String(vacancy.salaryMax))
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </div>

                <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
                  <div style={{ width: "50vw" }}>
                    <InputLabel
                      shrink
                      id="vacancy"
                      style={{ color: palette.primary, marginBottom: 15 }}>
                      {t("vacancy:create.vacancyDetail.benefit.label")}
                    </InputLabel>
                    <ReactSelect
                      className={classMenu}
                      classNamePrefix={"benefits"}
                      ref={ref => {
                        this.creatableRef = ref;
                      }}
                      openMenuOnFocus={true}
                      placeholder={t(
                        "vacancy:create.vacancyDetail.benefit.placeholder"
                      )}
                      isMulti
                      isClearable
                      onChange={v => {
                        if (v === null) {
                          this.setState({ benefits: [] });
                          this.setState({ vacancyBenefits: [] });
                          data.setVacancyPost({ vacancyBenefits: [] });

                          this.setState({ otherBenefitLocal: [] });
                          this.setState({ otherBenefits: [] });
                          data.setVacancyPost({ otherBenefits: [] });
                        } else {
                          this.setState({ benefits: v });
                          var value = v.map(v => {
                            return v.value;
                          });

                          this.setState({ vacancyBenefits: value });
                          data.setVacancyPost({ vacancyBenefits: value });
                        }
                      }}
                      options={listBenefit}
                      styles={creatableSelectStyles}
                      value={benefits ? benefits : []}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ marginTop: 40 }}
                  hidden={
                    this.state.vacancyBenefits.some(p => p === otherBenefitID)
                      ? false
                      : true
                  }>
                  <div style={{ width: "50vw" }}>
                    <CreatableSelect
                      classNamePrefix={"benefits"}
                      placeholder={t(
                        "vacancy:create.vacancyDetail.jobFamily.otherPlaceholder"
                      )}
                      isMulti
                      onChange={obj => {
                        if (obj === null) {
                          this.setState({ otherBenefitLocal: [] });
                          this.setState({ otherBenefits: [] });
                          data.setVacancyPost({ otherBenefits: [] });
                        } else {
                          this.setState({ otherBenefitLocal: obj });
                          var value = obj.map(v => {
                            return v.value;
                          });
                          this.setState({ otherBenefits: value });
                          data.setVacancyPost({ otherBenefits: value });
                        }
                      }}
                      styles={creatableSelectStyles}
                      value={otherBenefitLocal}
                    />
                  </div>
                </Grid>

                <Grid item lg={11} md={11} sm={11} style={{ marginTop: 40 }}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "lighter",
                      color: palette.primary,
                    }}>
                    {t("vacancy:create.vacancyDetail.benefit.suggestion")}
                  </Typography>
                  {listBenefit.map((v, i) => {
                    return findObject(benefits, "value", v.value) ? null : (
                      <Button
                        key={i}
                        className="suggestion-button"
                        onClick={() => {
                          this.handleSuggestion(v);
                        }}>
                        <div className="suggestion-label">{v.label}</div>
                        <div className="suggestion-image">
                          <img
                            src={IconSuggestion}
                            height={"10vh"}
                            alt={v.label}
                          />
                        </div>
                      </Button>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                hidden={vacancy.vacancyType === "Copy Existing" ? false : true}>
                <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
                  <div style={{ width: "75%" }}>
                    <InputLabel
                      shrink
                      id="vacancy"
                      style={{ color: palette.primary, marginBottom: 15 }}>
                      {t("vacancy:create.existing.copyFrom.label")}
                    </InputLabel>
                    <Select
                      className={classMenu}
                      ref={ref => {
                        this.ddlReffff = ref;
                      }}
                      openMenuOnFocus={true}
                      classNamePrefix="changecolorselect"
                      placeholder={t(
                        "vacancy:create.existing.copyFrom.placeholder"
                      )}
                      options={listExisting.map((v, i) => {
                        return {
                          value: v.vacancyId,
                          label: v.vacancyName,
                        };
                      })}
                      value={
                        copyFrom
                          ? listExisting
                              .map((v, i) => {
                                return {
                                  value: v.vacancyId,
                                  label: v.vacancyName,
                                };
                              })
                              .find(p => p.value === copyFrom)
                          : " "
                      }
                      isSearchable={true}
                      onChange={e => {
                        if (e === null) {
                          this.handleExisting("");
                        } else {
                          this.handleExisting(e.value);
                          this.setState({ classMenu: "add_from_dds" }, () => {
                            this.ddlReffff.focus();
                          });

                          setTimeout(() => {
                            this.ddlReffff.blur();
                            this.setState({ classMenu: "" });
                          }, 100);
                        }
                      }}
                    />
                  </div>
                </Grid>

                {/* <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
                  <div>
                    <InputLabel
                      shrink
                      style={{ color: palette.primary, marginBottom: 15 }}>
                      {t("vacancy:create.existing.copyFrom.label")}
                    </InputLabel>
                    <SelectDefault
                      labelId="vacancy"
                      style={{
                        color: palette.primary,
                        fontWeight: "bold",
                        width: "75%",
                      }}
                      className="Field select-with-placeholder"
                      value={copyFrom ? copyFrom : " "}
                      onChange={e => this.handleExisting(e.target.value)}>
                      <MenuItem
                        style={{ color: palette.primary }}
                        value=" "
                        disabled>
                        <span>
                          {t("vacancy:create.existing.copyFrom.placeholder")}
                        </span>
                      </MenuItem>
                      {listExisting
                        ? listExisting.map((v, i) => {
                            return (
                              <MenuItem
                                key={i}
                                style={{ color: palette.primary }}
                                value={v.vacancyId}>
                                {v.vacancyName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </SelectDefault>
                  </div>
                </Grid> */}

                <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
                  <TextField
                    style={{ fontSize: 24, width: "75%" }}
                    autoFocus={true}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t(
                      "vacancy:create.existing.jobTitle.placeholder"
                    )}
                    label={t("vacancy:create.existing.jobTitle.label")}
                    className="Field"
                    onChange={v =>
                      this.handleVacancy({ jobTitle: v.target.value })
                    }
                    value={vacancy.jobTitle}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <RecruiterFooter
          style={{ width: "100%" }}
          history={this.props.history}
          nextTo="/recruiter/job-requirement"
          backTo="/recruiter/home"
          backLabel={t("vacancy:create.footer.cancel")}
          nextLabel={t("vacancy:create.footer.next")}
          action={true}
          onFinish={() => this.saveData()}
        />
      </div>
    );
  }
}

export default withTranslation()(VacancyDetail);
