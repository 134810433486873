import React from "react";
import { withTranslation } from "react-i18next";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette } from "assets/css/main";
import CandidateSidePanel from "./side-panel";
import CandidatePreview from "./candidate-preview";
import { getItem, RequestGet } from "app/utils";
import { IcnPlayVideo, ImgVideoThumb } from "assets/img";
import VideoPlayer from "app/pages/applicant/dashboard/home/edit-cv/video-interview/video-player";

export default withTranslation()(
  class CandidatesVideoInterview extends React.Component {
    state = {
      interview: [],
      video: null,
      current: null,
      openVideo: false,
      indexPlay: null,
      isLoading: true,
    };
    componentDidMount() {
      window.scrollTo(0, 0);
      this.getVideoInterview();
    }
    getVideoInterview() {
      const { params } = this.props.match;
      this.setState({ isLoading: true });
      RequestGet("candidate/interview/" + params.id)
        .then(r => {
          const { data } = r;
          this.setState({ video: data });
          RequestGet("InterviewQuestion")
            .then(re => {
              this.setState({ isLoading: true });

              return re.data.interviewVideoQuestions.map((v, i) => {
                return {
                  interviewQuestionEng: v.interviewQuestionEng,
                  interviewQuestionIna: v.interviewQuestionIna,
                  video: data[i] ? data[i].videoUrl : "",
                };
              });
            })
            .then(data => {
              this.setState({ interview: data });
            })
            .catch(er => {
              this.setState({ isLoading: false });

              console.log("getVideoInterview()", er);
            });
        })
        .catch(e => {
          this.setState({ isLoading: false });
          console.log("getVideoInterview()", e);
        });
    }
    render() {
      const {
        match: { params },
        t,
      } = this.props;
      const { interview, video, openVideo, indexPlay, isLoading } = this.state;
      return (
        <div style={{ height: "100%", boxSizing: "border-box" }}>
          <RecruiterAppBar menu="dashboard" shadow={false} />
          <div style={{ paddingTop: 80, boxSizing: "border-box" }}>
            <CandidatePreview id={params.id} />
            {openVideo && (
              <VideoPlayer
                open={openVideo}
                videos={video}
                questions={interview}
                index={indexPlay}
                onClose={() => {
                  this.setState({ openVideo: false });
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                minHeight: 650,
              }}>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  boxSizing: "border-box",
                  backgroundColor: palette.lightGrey,
                  justifyContent: "flex-end",
                }}>
                <CandidateSidePanel view="video" id={params.id} />
              </div>
              <div
                style={{
                  width: "80%",
                  padding: "25px 40px",
                  color: palette.primary,
                  marginBottom: 50,
                  boxSizing: "border-box",
                }}>
                <span style={{ fontWeight: "bold", fontSize: 24 }}>
                  {t("candidates:candidateInfo.vi")}
                </span>
                {interview.length === 0 && !isLoading ? (
                  <div
                    style={{
                      padding: "20px 0px 0px 0px",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    {t("candidates:candidateInfo.empty")}
                  </div>
                ) : (
                  ""
                )}
                <div style={{ width: "100%", marginTop: 20 }}>
                  {interview.map((v, k) => {
                    return (
                      <div
                        key={"quest-k" + k}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderBottom: "1.5px solid rgba(46, 105, 178,0.5)",
                          marginBottom: 20,
                        }}>
                        <div
                          style={{
                            position: "relative",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            width: 350,
                            height: 300,
                            backgroundImage: "url(" + ImgVideoThumb + ")",
                          }}>
                          <img
                            src={IcnPlayVideo}
                            alt="play"
                            onClick={() => {
                              this.setState({
                                openVideo: true,
                                indexPlay: k,
                              });
                            }}
                            style={{
                              zIndex: 3,
                              position: "absolute",
                              top: 100,
                              left: 135,
                              cursor: "pointer",
                            }}
                          />
                          <div
                            style={{
                              width: 330,
                              height: 248,
                              backgroundColor: "black",
                              opacity: 0.5,
                              marginTop: 5,
                              borderRadius: 4,
                              position: "absolute",
                              zIndex: 2,
                            }}></div>
                          <span
                            style={{
                              color: "white",
                              position: "absolute",
                              top: 30,
                              textTransform: "uppercase",
                              fontSize: 24,
                              fontWeight: "bold",
                              left: 20,
                              zIndex: 1,
                              width: 200,
                            }}>
                            {t("cv:videoInterview.title")}
                          </span>
                          <span
                            style={{
                              color: "white",
                              position: "absolute",
                              bottom: 85,
                              textTransform: "uppercase",
                              fontSize: 24,
                              fontWeight: "bold",
                              left: 20,
                              zIndex: 1,
                              width: 200,
                            }}>
                            {k + 1 < 10 ? "0" + (k + 1) : k + 1}
                          </span>
                          {/* <video width="350" height="300" controls>
                                      <source src={video[k].videoUrl} />
                                    </video> */}
                        </div>
                        <div style={{ padding: "4px 25px", width: 400 }}>
                          <span style={{ fontSize: 18 }}>
                            {t("cv:videoInterview.question") + " " + (k + 1)}
                          </span>
                          <p style={{ fontWeight: "lighter" }}>
                            {getItem("lang") === "en"
                              ? v.interviewQuestionEng
                              : v.interviewQuestionIna}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
