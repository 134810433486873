import React from "react";
import Banner from "./Banner";
import Applicant from "./About";
import Employer from "./Benefits";
import Partner from "./Approach";
import MyHelmet from "app/components/header/MyHelmet";
export default class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <MyHelmet
          title="Home"
          description="Getting hired has just got lot smarter. a 3-sided digital platform that facilitates the needs of fresh
                graduates, educational institutions and corporations through
                accurate people profiling for better recruitment and development
                processes"
        />
        <Banner />
        <Applicant />
        <Employer />
        <Partner />
      </div>
    );
  }
}
