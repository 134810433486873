import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Grid, Typography, Button } from "@material-ui/core";
import "./custom.css";
import { IconConfirmationExclamation } from "assets/img";
class ConfirmationStep extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
  }

  render() {
    const { t } = this.props;
    return (
      <Grid
        container
        style={{
          backgroundColor: "#F8F8F8",
          top: 0,
          position: "fixed",
          width: "25%",
          height: "100%",
          overflowX: "auto",
        }}
      >
        {
          this.props.edit === true ? (
            <>
              <Grid
                item lg={12} md={12} sm={12} style={{ marginTop: 113, paddingLeft: 90, paddingRight: 24 }}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    color: palette.primary,
                  }}>
                  {t("vacancy:edit.title")}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item lg={12} md={12} sm={12} style={{ marginTop: 113, paddingLeft: 90, paddingRight: 24 }}
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    color: palette.primary,
                  }}>
                  {t("vacancy:create.confirmation.title")}
                </Typography>
              </Grid>
              <Grid
                item lg={12} md={12} sm={12} style={{ marginTop: 8, paddingLeft: 112, paddingRight: 24 }}
              >
                <Typography
                  style={{
                    fontSize: "13px",
                    fontWeight: "lighter",
                    color: palette.primary,
                  }}>
                  <img
                    style={{
                      marginLeft: -26
                    }}
                    src={IconConfirmationExclamation}
                    height={"15h"}
                    alt="ExclamationTag"
                  />
                  &nbsp;
                  {t("vacancy:create.confirmation.desc")}
                </Typography>
              </Grid>
            </>
          )
        }
        
        <Grid
          item lg={12} md={12} sm={12} style={{ marginTop: 8, paddingLeft: 90, paddingRight: 16 }}
        >
          <hr
            style={{border: "1px solid #E5E5E5"}}
          />
        </Grid>
        <Grid
          item lg={12} md={12} sm={12} style={{ marginTop: 16, marginLeft: 90, marginRight: 16 }}
        >
          <div style={this.props.data === "VacancyDetail" ? { backgroundColor: "rgba(82, 200, 244, 0.2)" } : null}>
            <Button 
              style={{textTransform: "none", padding: "7px 24px 6px", width: "100%", display: "flex", justifyContent: "flex-start" }}
              onClick={() => {this.props.onChange("VacancyDetail")}}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("vacancy:create.step", { returnObjects: true })[0]}
              </Typography>
            </Button>
          </div>
        </Grid>
        <Grid
          item lg={12} md={12} sm={12} style={{ marginTop: 9, marginLeft: 90, marginRight: 16 }}
        >
          <div style={this.props.data === "JobRequirement" ? { backgroundColor: "rgba(82, 200, 244, 0.2)" } : null}>
            <Button 
              style={{textTransform: "none", padding: "7px 24px 6px", width: "100%", display: "flex", justifyContent: "flex-start" }}
              onClick={() => {this.props.onChange("JobRequirement")}}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("vacancy:create.step", { returnObjects: true })[1]}
              </Typography>
            </Button>
          </div>
        </Grid><Grid
          item lg={12} md={12} sm={12} style={{ marginTop: 9, marginLeft: 90, marginRight: 16 }}
        >
          <div style={this.props.data === "SoftSkill" ? { backgroundColor: "rgba(82, 200, 244, 0.2)" } : null}>
            <Button 
              style={{textTransform: "none", padding: "7px 24px 6px", width: "100%", display: "flex", justifyContent: "flex-start" }}
              onClick={() => {this.props.onChange("SoftSkill")}}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("vacancy:create.step", { returnObjects: true })[2]}
              </Typography>
            </Button>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "80%", marginBottom: "80%" }} />
      </Grid>
    );
  }
}

export default withTranslation()(ConfirmationStep);