import React from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { palette } from "assets/css/main";
import data from "./data";
import WizardForm from "app/components/typeform/WizardForm";
import { DialogueInfo, Options } from "app/components";
import SelectSearch from "react-select";
// import SelectSearch from "react-select/creatable";

import "./custom.css";
import {
  getItem,
  RequestPost,
  General,
  AppHistory,
  RequestGet,
  findObject,
  findDuplicate,
} from "app/utils";
import HgDatePicker from "app/components/general/HgDatePicker";
// import { logError } from "app/utils/general/rollbar";

class FormalEducation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...data.formal_education,
      user: JSON.parse(getItem("user_info")),
      endStepReq: false,
      tmpGraduate: "",
      key: new Date().getTime(),
      universities: [],
      majors: [],
      showDuplicate: false,
      showFailed: false,
    };
    General.visible = false;
    data.stepper.footer = false;
    data.formal_education.userId = getItem("user_id");
  }

  handleNext(isLast, required) {
    console.log(required);
    if (!required && isLast) {
      data.stepper.footer = isLast;
      data.stepper.nextBtn = isLast;
    }
    if (isLast) {
      this.setState({ endStepReq: true });
    }
  }

  handleBack() {
    this.setState({ endStepReq: false });
    data.stepper.footer = false;
    data.stepper.nextBtn = false;
  }
  handleChange(obj) {
    this.setState(obj);
    data.setFormalEducation(obj);
    var e = document.getElementById("error-messages");
    var el = document.getElementsByClassName("MuiInput-underline");
    var label = document.getElementsByClassName("MuiInputLabel-root");
    try {
      e.innerText = "";
      document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
      label[0].classList.remove("Mui-error");
      el[0].classList.remove("Mui-error");
    } catch (error) {}
    if (this.state.endStepReq) {
      data.stepper.footer = true;
      data.stepper.nextBtn = true;
    }
  }

  getUniversity() {
    RequestGet("university", { loading: false })
      .then(res => {
        return res.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.university,
          };
        });
      })
      .then(data => {
        this.setState({ universities: data });
      })
      .catch(e => {
        console.log("getUniversity()", e);
      });
  }

  getMajor(uID) {
    RequestGet("university/major/" + uID, { loading: false })
      .then(res => {
        return res.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.major,
          };
        });
      })
      .then(data => {
        this.setState({ majors: data });
      })
      .catch(e => {
        console.log("getUniversity()", e);
      });
  }

  getLastEducation(key) {
    var last = "SDSMPSMA";
    if (last.indexOf(key) > -1) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    this.getUniversity();
  }

  submit(init = true) {
    data.personalInfo = null;
    data.listWorks = [];
    data.listOrganization = [];
    data.listInformalEducation = [];

    data.formal_education.grade = parseFloat(data.formal_education.grade);
    data.formal_education.yearGraduate = parseInt(
      data.formal_education.yearGraduate
    );
    if (init) {
      data.listFormalEducation.push(data.formal_education);
    }
    if (findDuplicate(JSON.parse(JSON.stringify(data.listFormalEducation)))) {
      this.setState({ showDuplicate: true });
    } else {
      RequestPost("biodata", data)
        .then(() => {
          data.listFormalEducation = [];
          window.location.reload();
        })
        .catch(e => {
          // logError("formal-education :" + e);
          this.setState({ showFailed: true });
        });
    }
  }

  addFormalEducation() {
    this.setState({ key: new Date().getTime(), reset: true });
    data.formal_education.grade = parseInt(data.formal_education.grade);
    data.formal_education.yearGraduate = parseInt(
      data.formal_education.yearGraduate
    );
    data.listFormalEducation.push(data.formal_education);

    data.setFormalEducation({
      degree: "",
      institutionLocation: "",
      institution: "",
      major: "",
      nim: "",
      grade: "",
      yearGraduate: "",
    });
    AppHistory.push(
      "/initialize/biodata/addformal" + data.listFormalEducation.length
    );
  }
  render() {
    const { t } = this.props;
    const { universities, majors, showDuplicate, showFailed } = this.state;
    const universityLocations = t("biodata:options.universityLocation", {
      returnObjects: true,
    });
    return (
      <Grid container style={{ padding: "30px 0px", width: "100vw" }}>
        <DialogueInfo
          title={t("biodata:duplicate.title")}
          content={t("biodata:duplicate.content")}
          open={showDuplicate}
          onClose={() => {
            this.setState({ showDuplicate: false }, () => {
              window.location.reload();
            });
          }}
        />
        <DialogueInfo
          title={t("biodata:failed.title")}
          content={t("biodata:failed.content")}
          open={showFailed}
          onClose={() => {
            this.setState({ showFailed: false }, () => {
              this.submit(false);
            });
          }}
        />
        <Typography
          style={{
            padding: "0px 25%",
            color: palette.primary,
            fontWeight: "bold",
            width: 400,
          }}>
          {t("biodata:step.formaleducation")}
        </Typography>
        <WizardForm
          reset={this.state.reset}
          onReset={bool => {
            this.setState({ reset: bool });
          }}
          onSubmit={() => {
            this.submit();
          }}>
          <div required={true} value={this.state.degree}>
            <InputLabel
              shrink
              id="degree"
              style={{ color: palette.primary, marginBottom: 15 }}>
              {t("biodata:form.field.level")}
              <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                &thinsp;*
              </span>
            </InputLabel>
            <Select
              labelId="degree"
              style={{ color: palette.primary, fontWeight: "bold" }}
              className="Field select-with-placeholder"
              onChange={e => {
                this.handleChange({ degree: e.target.value });
              }}
              value={this.state.degree ? this.state.degree : " "}>
              <MenuItem value=" " disabled>
                <span>{t("biodata:form.placeholder.level")}</span>
              </MenuItem>
              <MenuItem value="S3">S3</MenuItem>
              <MenuItem value="S2">S2</MenuItem>
              <MenuItem value="S1">S1</MenuItem>
              <MenuItem value="D4">D4</MenuItem>
              <MenuItem value="D3">D3</MenuItem>
              <MenuItem value="D2">D2</MenuItem>
              <MenuItem value="D1">D1</MenuItem>
            </Select>
          </div>

          <Options
            label={
              this.getLastEducation(this.state.degree)
                ? t("biodata:form.field.schoolLocation")
                : t("biodata:form.field.universitylocation")
            }
            onChange={e => {
              this.handleChange({
                institutionLocation: e.value,
                institution: "",
                institutionId: "",
                major: "",
                majorId: "",
              });
            }}
            options={universityLocations}
            value={this.state.institutionLocation}
          />
          {/* institution input */}
          {this.state.institutionLocation === "indonesia" &&
          this.state.degree === "S1" ? (
            <div
              required={true}
              value={
                this.state.institution
                  ? this.state.institution
                  : this.state.institutionId
              }>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 18,
                  fontWeight: "lighter",
                  marginBottom: 10,
                }}>
                {t("biodata:form.field.university")}
                <span
                  style={{ color: palette.primary }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <SelectSearch
                closeMenuOnSelect
                key={new Date().getTime()}
                className="Select-bio-education"
                classNamePrefix="select"
                placeholder={t("biodata:form.placeholder.university")}
                menuColor={palette.primary}
                name="institution"
                options={universities}
                value={
                  this.state.institutionId
                    ? findObject(
                        universities,
                        "value",
                        this.state.institutionId
                      )
                    : ""
                }
                onChange={v => {
                  const d = findObject(universities, "value", v.value);
                  if (d) {
                    this.handleChange({
                      institutionId: v.value,
                      institution: v.label,
                      majorId: "",
                    });
                  } else {
                    v.key = new Date().getTime();
                    universities.push(v);
                    this.handleChange({
                      institutionId: v.value,
                      institution: v.label,
                      majorId: "",
                    });
                  }

                  this.getMajor(v.value);
                }}
                style={{ color: palette.primary, fontSize: 32 }}
              />
            </div>
          ) : (
            <TextField
              maxLength={50}
              autoFocus={true}
              onChange={e => {
                this.handleChange({
                  institution: e.currentTarget.value,
                  institutionId: e.currentTarget.value,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              required={true}
              placeholder={
                this.getLastEducation(this.state.degree)
                  ? t("biodata:form.placeholder.schoolName")
                  : t("biodata:form.placeholder.university")
              }
              label={
                this.getLastEducation(this.state.degree)
                  ? t("biodata:form.field.schoolName")
                  : t("biodata:form.field.university")
              }
              className="Field"
              value={this.state.institution}
            />
          )}
          {/* institution input */}

          {/* major input */}
          {this.state.institutionLocation === "indonesia" &&
          this.state.degree === "S1" ? (
            <div
              required={true}
              value={this.state.major ? this.state.major : this.state.majorId}>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 18,
                  fontWeight: "lighter",
                  marginBottom: 10,
                }}>
                {t("biodata:form.field.major")}
                <span
                  style={{ color: palette.primary }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <SelectSearch
                closeMenuOnSelect
                key={new Date().getTime()}
                className="Select-bio-education"
                classNamePrefix="select"
                placeholder={t("biodata:form.placeholder.major")}
                menuColor={palette.primary}
                name="institution"
                options={majors}
                value={
                  this.state.majorId
                    ? findObject(majors, "value", this.state.majorId)
                    : ""
                }
                onChange={v => {
                  const d = findObject(majors, "value", v.value);
                  if (d) {
                    this.handleChange({ majorId: v.value, major: v.value });
                  } else {
                    v.key = new Date().getTime();
                    majors.push(v);
                    this.handleChange({ majorId: v.value, major: v.value });
                  }
                }}
                style={{ color: palette.primary, fontSize: 32 }}
              />
            </div>
          ) : (
            <TextField
              autoFocus={true}
              onChange={e => {
                this.handleChange({
                  major: e.currentTarget.value,
                  majorId: e.currentTarget.value,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              required={true}
              maxLength={50}
              placeholder={t("biodata:form.placeholder.major")}
              label={t("biodata:form.field.major")}
              className="Field"
              value={this.state.major}
            />
          )}
          {/* major input */}

          <TextField
            autoFocus={true}
            onChange={e => {
              this.handleChange({ nim: e.currentTarget.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={
              this.getLastEducation(this.state.degree)
                ? t("biodata:form.placeholder.nis")
                : t("biodata:form.placeholder.nim")
            }
            label={
              this.getLastEducation(this.state.degree)
                ? t("biodata:form.field.nis")
                : t("biodata:form.field.nim")
            }
            className="Field"
            value={this.state.nim}
          />
          <TextField
            autoFocus={true}
            onChange={e => {
              this.handleChange({ grade: e.currentTarget.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            minvalue={0}
            maxvalue={this.getLastEducation(this.state.degree) ? 100 : 4}
            required={true}
            placeholder={
              this.getLastEducation(this.state.degree)
                ? t("biodata:form.placeholder.nem")
                : t("biodata:form.placeholder.gpa")
            }
            label={
              this.getLastEducation(this.state.degree)
                ? t("biodata:form.field.nem")
                : t("biodata:form.field.gpa")
            }
            helper={t("validation.valid.minmax")}
            className="Field"
            value={this.state.grade}
          />
          <div required={true} value={this.state.tmpGraduate}>
            <HgDatePicker
              className="one"
              autoFocus={true}
              required={true}
              minDate={"1900-01-01"}
              maxDate={new Date()}
              label={t("biodata:form.field.graduationyear")}
              format="yyyy"
              maxDetail="decade"
              value={this.state.tmpGraduate}
              onChange={e => {
                if (e) {
                  General.visible = true;
                  this.handleChange({
                    tmpGraduate: e,
                    yearGraduate: e.getFullYear(),
                  });
                }
              }}
              yearPlaceholder={t("biodata:form.placeholder.year")}
            />
            {this.state.yearGraduate && (
              <div
                onClick={() => this.addFormalEducation()}
                style={{
                  color: palette.primary,
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginTop: 150,
                  position: "absolute",
                  cursor: "pointer",
                }}>
                {t("biodata:addFormalEducation")}
              </div>
            )}
          </div>
        </WizardForm>
      </Grid>
    );
  }
}
export default withTranslation()(FormalEducation);
