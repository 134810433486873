import React from "react";
import { palette } from "assets/css/main";
import { Grid, InputLabel, Button, Typography } from "@material-ui/core";
import "./custom.css";
import {
  IconChecked,
  IconUncheck,
} from "assets/img";
import { findObject, removeObject } from "app/utils";

export default class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.defaultValue,
      label: props.label,
      options: props.options,
    };
    this.translate = this.props.t;
  }

  handleClick(value) {
    var arrActive = this.state.active.length ? this.state.active : this.props.defaultValue;

    if (findObject(arrActive, "value", value.value)) {
      arrActive = removeObject(arrActive, "value", value.value);
    } else {
      arrActive.push(value);
    }

    this.setState({active: arrActive});
    this.props.onChange(arrActive);

    console.log(this.state.active);
  }

  render() {
    const { label, options, defaultValue } = this.props;
    const { active } = this.state;
    const result = active.length ? active : defaultValue;
    return (
      <Grid container>
        <Grid
        item lg={12} md={12} sm={12}>
          <InputLabel
            shrink
            style={{ color: palette.primary }}>
            {label}
          </InputLabel>
        </Grid>
        {
          options.map((v, i) => {
            return (
              <Grid
                container
                key={i}
                style={{
                  color: palette.primary,
                  fontWeight: "bold",
                  marginTop: 10,
                }}>
                <Grid
                  item lg={1} md={1} sm={1}
                >
                  <Button
                    onClick={() => {
                      this.handleClick(v);
                    }}
                  >
                    <img
                      src={findObject(result, "value", v.value) ? IconChecked : IconUncheck}
                      alt="Plus"
                      height={"20vh"}
                    />
                  </Button>
                </Grid>
                <Grid
                  item lg={11} md={11} sm={11}
                  className="MuiInputBase-root MuiInput-root"
                >
                  <Typography
                    style={{
                      fontSize: this.props.fontSize,
                      fontWeight: "bold",
                      paddingTop: 1,
                      color: palette.primary,
                    }}>
                    {v.label}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        }
      </Grid>
    );
  }
}
