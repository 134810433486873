import React from "react";
import { Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { AnswerImages } from "app/components";
import { IcnTimer, ImgTest4, IcnPaperQuestion } from "assets/img";
import { getItem } from "app/utils";
export default class Sample extends React.Component {
  state = {
    sample: 1,
    timeout: 21 * 1000,
    time_min: "00",
    time_sec: "00",
    text: "F4",
  };
  timeInterval = null;
  componentDidMount() {
    this.timeInterval = setInterval(() => {
      this.countTime();
    }, 1000);
  }
  countTime() {
    const time = this.state.timeout - 1000;
    if (time >= 0) {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      this.setState({
        timeout: time,
        time_sec: seconds < 10 ? "0" + seconds : seconds,
        time_min: minutes < 10 ? "0" + minutes : minutes,
      });
    } else {
      clearInterval(this.timeInterval);
      this.props.onFinish();
    }
  }
  render() {
    const { title, order } = this.props;
    const { time_min, time_sec, text } = this.state;
    return (
      <div
        style={{
          display: "flex",
          width: "100vw",
          paddingTop: 50,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <div style={{ position: "absolute", right: 80, top: 180 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <img src={IcnTimer} width={50} height={50} alt="paper-grey" />
            <Typography
              style={{
                marginLeft: 20,
                color: palette.primary,
                fontWeight: "bold",
                fontSize: 18,
              }}>
              {time_min}:{time_sec}
            </Typography>
          </div>
        </div>
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: 24,
            color: palette.primary,
          }}>
          {title}
        </Typography>
        <div style={{ width: 628, marginTop: 30 }}>
          <div
            style={{
              color: palette.primary,
              fontWeight: "bold",
              backgroundColor: palette.info,
              width: 200,
              paddingTop: 7,
              paddingBottom: 7,
              paddingLeft: 10,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}>
            {getItem("lang") === "en" ? "Instruction" : "Instruksi"}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.instruction
                ? this.props.instruction.replace("EXAM_TIME", this.props.time)
                : "",
            }}
            style={{
              color: palette.primary,
              marginTop: 20,
              marginBottom: 40,
              fontSize: 18,
            }}></div>
          <div
            style={{
              color: palette.primary,
              fontWeight: "bold",
              backgroundColor: palette.info,
              width: 200,
              paddingTop: 7,
              paddingBottom: 7,
              paddingLeft: 10,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}>
            {getItem("lang") === "en" ? "Example" : "Contoh"}
          </div>
          {order === 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: 500,
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}>
              <div
                style={{
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}>
                <div
                  style={{
                    width: 300,
                    position: "relative",
                  }}>
                  <div
                    style={{
                      position: "absolute",
                      top: 15,
                      left: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#B0B7BD",
                      borderRadius: "50%",
                      fontWeight: "bold",
                      color: "white",
                      width: 32,
                      height: 32,
                    }}>
                    {1}
                  </div>
                  <img
                    id={"current.id"}
                    key={"current.id"}
                    style={{
                      position: "absolute",
                      top: "25%",
                      left: "10%",
                    }}
                    src={
                      "https://s3-ap-southeast-1.amazonaws.com/www.hiregrade.id/assets/img/cognitive/Penalaran/NAL053.jpg"
                    }
                    alt="Question Background"
                    width={220}
                    height={220}
                  />
                  <img
                    src={IcnPaperQuestion}
                    alt="Question Background"
                    width={274}
                    height={329}
                  />
                </div>
              </div>
              <AnswerImages
                sample={true}
                options={[
                  {
                    answerId: 1,
                    answer:
                      "https://s3-ap-southeast-1.amazonaws.com/www.hiregrade.id/assets/img/cognitive/Penalaran/NAL053_A1.jpg",
                  },
                  {
                    answerId: 2,
                    answer:
                      "https://s3-ap-southeast-1.amazonaws.com/www.hiregrade.id/assets/img/cognitive/Penalaran/NAL053_A2.jpg",
                  },
                  {
                    answerId: 3,
                    answer:
                      "https://s3-ap-southeast-1.amazonaws.com/www.hiregrade.id/assets/img/cognitive/Penalaran/NAL053_A3.jpg",
                  },
                  {
                    answerId: 4,
                    answer:
                      "https://s3-ap-southeast-1.amazonaws.com/www.hiregrade.id/assets/img/cognitive/Penalaran/NAL053_A4.jpg",
                  },
                  {
                    answerId: 5,
                    answer:
                      "https://s3-ap-southeast-1.amazonaws.com/www.hiregrade.id/assets/img/cognitive/Penalaran/NAL053_A5.jpg",
                  },
                ]}
                style={{ marginTop: "auto" }}
                isImage={true}
                value={this.state.sample}
                onChange={option => {
                  this.setState({ sample: option });
                }}
              />
            </div>
          )}
          {order === 2 && (
            <div>
              <Typography
                style={{
                  color: palette.primary,
                  marginTop: 20,
                  marginBottom: 40,
                  fontSize: 18,
                }}>
                Komite Olahraga Nasional Indonesia (KONI) mengakui bahwa
                singkatan resmi untuk organisasi induk cabang olahraga silat di
                Indonesia adalah ...
              </Typography>
              <AnswerImages
                sample={true}
                options={[
                  { answerId: 1, answer: "IPSI" },
                  { answerId: 2, answer: "FORKI" },
                  { answerId: 3, answer: "FKSI" },
                  { answerId: 4, answer: "PKSI" },
                  { answerId: 5, answer: "FPSI" },
                ]}
                value={this.state.sample}
                onChange={option => {
                  this.setState({ sample: option });
                }}
              />
            </div>
          )}
          {order === 3 && (
            <div>
              <Typography
                style={{
                  color: palette.primary,
                  marginTop: 20,
                  marginBottom: 40,
                  fontSize: 18,
                }}>
                Perbandingan uang jajan Deni dan uang Abbas adalah 3:2, jika
                uang Deni dan Abbas berjumlah Rp 150.000, berapakah
                masing-masing uang Deni dan Abbas?
              </Typography>
              <AnswerImages
                sample={true}
                options={[
                  { answerId: 1, answer: "Rp 80.000 dan Rp 60.000" },
                  { answerId: 2, answer: "Rp 100.000 dan Rp 80.000" },
                  { answerId: 3, answer: "Rp 200.000 dan Rp 120.000" },
                  { answerId: 4, answer: "Rp 60.000 dan Rp 40.000" },
                  { answerId: 5, answer: "Rp 120.000 dan Rp 80.000" },
                ]}
                value={this.state.sample}
                onChange={option => {
                  this.setState({ sample: option });
                }}
              />
            </div>
          )}

          {order === 4 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: 500,
                justifyContent: "flex-start",
                alignItems: "center",
              }}>
              <div
                style={{
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}>
                <div
                  style={{
                    width: 300,
                    position: "relative",
                  }}>
                  <div
                    style={{
                      position: "absolute",
                      top: 15,
                      left: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#B0B7BD",
                      borderRadius: "50%",
                      fontWeight: "bold",
                      color: "white",
                      width: 32,
                      height: 32,
                    }}>
                    {1}
                  </div>
                  <img
                    id={"current.id"}
                    key={"current.id"}
                    style={{
                      position: "absolute",
                      top: "25%",
                      left: "10%",
                    }}
                    src={ImgTest4}
                    alt="Question Background"
                    width={220}
                    height={220}
                  />
                  <img
                    src={IcnPaperQuestion}
                    alt="Question Background"
                    width={274}
                    height={329}
                  />
                </div>
              </div>
              <input
                className={"pulse"}
                key={"current.id"}
                autoFocus
                maxLength={2}
                onChange={e => {
                  this.setState({ text: e.target.value });
                }}
                value={text}
                style={{
                  textAlign: "center",
                  fontFamily: "inherit",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  border: "none",
                  fontSize: 36,
                  padding: 5,
                  paddingLeft: 20,
                  paddingRight: 20,
                  width: 60,
                  height: 120,
                  backgroundColor: "rgb(227, 229, 231)",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#ccc",
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
