import React from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { HeaderLogo } from "..";
import { palette } from "assets/css/main";
import { Link } from "react-router-dom";
import { AppHistory, getItem, RequestGet, setItem } from "app/utils";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { SwitchID, SwitchEN, IcnNotif } from "assets/img";
import i18next from "i18next";
import { HubConnectionBuilder } from "@microsoft/signalr";

const linkStyleActive = {
  color: palette.primary,
  fontSize: 16,
  borderBottom: "4px solid " + palette.primary,
  display: "flex",
  marginRight: 30,
  height: 76,
  alignItems: "center",
  cursor: "pointer",
};
const linkStyle = {
  color: palette.primary,
  marginRight: 30,
  fontSize: 16,
  display: "flex",
  borderBottom: "4px solid transparent",
  height: 76,
  alignItems: "center",
  cursor: "pointer",
};
class RecruiterAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifCount: 0,
      anchorEl: null,
      ...JSON.parse(getItem("company_info")),
      lang: getItem("lang"),
      signalRConnection: process.env.REACT_APP_SIGNALR
        ? new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_SIGNALR)
            .withAutomaticReconnect()
            .build()
        : {},
    };
  }

  handleClick = e => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  closeLang() {
    var l = getItem("lang") === "id" ? "en" : "id";
    this.setState({ lang: l });
    i18next.changeLanguage(l);
    setItem("lang", l);
    this.setState({ lang: l });
    window.location.reload();
  }

  getNotification() {
    RequestGet("notification", { loading: false })
      .then(res => {
        this.setState({
          notifCount: res.data.countUnread < 10 ? res.data.countUnread : "9+",
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getNotification();

    // signalR
    if (this.state.signalRConnection.connectionStarted) {
      // const newConnection = new HubConnectionBuilder()
      //     //.withUrl(process.env.REACT_APP_API + 'hubs/notification')
      //     .withUrl('http://localhost:5001/hubs/notification')
      //     .withAutomaticReconnect()
      //     .build();
      console.log(this.state.signalRConnection);

      this.state.signalRConnection
        .start()
        .then(() => {
          console.log("Started!");
          this.setState({ connected: true });
        })
        .catch(e => {
          console.log("Stoped!");
          console.log("Connection failed: ", e);
          this.setState({ connected: false });
        });

      this.state.signalRConnection.on("UserConnected", connectionId => {
        console.log("Connected!");
        console.log(connectionId);

        this.state.signalRConnection.invoke(
          "UpdateDB",
          getItem("user_id"),
          connectionId,
          "RECRUITER",
          "true",
          null
        );
      });
    }
  }

  render() {
    const { shadow, menu, t } = this.props;
    const { anchorEl, lang, notifCount, companyNameBrand, signalRConnection } =
      this.state;
    return (
      <div>
        <AppBar
          position="absolute"
          color="default"
          style={{
            display: "flex",
            backgroundColor: "white",
            top: 0,
            height: 80,
            bottom: "auto",
            position: "fixed",
            boxShadow: !shadow ? "none" : "0px 2px 2px rgba(0, 0, 0, 0.09)",
            justifyContent: "center",
          }}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                paddingLeft: 76,
                paddingRight: 76,
              }}>
              <Grid item lg={2} md={2}>
                <HeaderLogo />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                style={{
                  paddingLeft: 60,
                }}>
                <Grid
                  container
                  justify="flex-start"
                  alignItems="center"
                  style={{
                    height: 76,
                  }}>
                  <Link
                    to="/recruiter/home"
                    style={menu === "dashboard" ? linkStyleActive : linkStyle}>
                    {t("menuRecruiterAppbar.dashboard")}
                  </Link>
                  <Link
                    to="/recruiter/candidates"
                    style={menu === "candidates" ? linkStyleActive : linkStyle}>
                    {t("menuRecruiterAppbar.candidates")}
                  </Link>
                </Grid>
              </Grid>
              <Grid item lg={4} md={4}>
                <Grid container justify="flex-end" alignItems="center">
                  <Link
                    to="/recruiter/notification"
                    style={
                      menu === "notification" ? linkStyleActive : linkStyle
                    }>
                    <img src={IcnNotif} alt={"notif"} height={"36vh"} />
                    {notifCount !== 0 && (
                      <div
                        style={{
                          position: "absolute",
                          marginLeft: "20px",
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#52C8F4",
                          borderRadius: "20px",
                          color: "white",
                          textAlign: "center",
                        }}>
                        <span style={{ fontSize: 12 }}>
                          {String(notifCount)}
                        </span>
                      </div>
                    )}
                    <span style={{ marginLeft: 10 }}>
                      {t("link.notification")}
                    </span>
                  </Link>

                  <div style={{ marginRight: 30 }}>
                    {/* <Button
                      style={{ position: "relative", padding: 0 }}
                      className="ButtonNoPadding">
                      <NotifBadge />
                      <img
                        width={26}
                        height={"auto"}
                        src={IcnNotif}
                        alt="Notif"
                      />
                    </Button> */}
                  </div>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    style={{
                      textTransform: "capitalize",
                      color: palette.primary,
                      fontSize: 16,
                      padding: "0px 0px",
                      marginBottom: 4,
                    }}
                    onClick={this.handleClick}>
                    {companyNameBrand}
                    {Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
                  </Button>
                  <Menu
                    className="RecruiterUserMenu"
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    style={{
                      fontWeight: "lighter",
                      marginTop: 60,
                      color: palette.primary,
                    }}
                    onClose={this.handleClose}>
                    <MenuItem
                      onClick={this.handleClose}
                      style={{
                        width: 200,
                        fontWeight: "lighter",
                        color: palette.primary,
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      {t("link.lang")}
                      <img
                        src={lang === "id" ? SwitchID : SwitchEN}
                        alt={"lang"}
                        height={24}
                        width={44}
                        onClick={() => {
                          this.closeLang();
                        }}
                      />
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        AppHistory.push("/recruiter/profile-edit");
                      }}
                      style={{
                        fontWeight: "lighter",
                        color: palette.primary,
                      }}>
                      {t("link.profile")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        AppHistory.push("/recruiter/users");
                      }}
                      style={{
                        fontWeight: "lighter",
                        color: palette.primary,
                      }}>
                      User Dashboard
                    </MenuItem>
                    {/* <MenuItem
                      onClick={this.handleClose}
                      style={{
                        fontWeight: "lighter",
                        color: palette.primary,
                      }}>
                      Account Settings
                    </MenuItem> */}
                    <MenuItem
                      style={{
                        fontWeight: "lighter",
                        color: palette.danger,
                      }}
                      onClick={() => {
                        if (signalRConnection.connectionStarted) {
                          signalRConnection
                            .invoke(
                              "UpdateDB",
                              getItem("user_id"),
                              null,
                              "RECRUITER",
                              "false",
                              "LOGOUT"
                            )
                            .then(() => {
                              signalRConnection
                                .stop()
                                .then(() => {
                                  console.log("Disconnected!");

                                  window.location.href = "/user/logout";
                                })
                                .catch(e => console.log(e));
                            });
                        } else {
                          window.location.href = "/user/logout";
                        }
                      }}>
                      {t("link.logout")}
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
export default withTranslation()(RecruiterAppBar);
