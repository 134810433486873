import React from "react";
import { withTranslation } from "react-i18next";
import { ApplicantHeader, DialogueFull, DialogueInfo } from "app/components";
import { HGVideoRecorder } from "app/components";
import {
  RequestGet,
  AppHistory,
  getItem,
  RequestPost,
  setItem,
} from "app/utils";
import { palette } from "assets/css/main";
import { FooterInterview } from "./footer-interview";
import alertData from "app/components/alert/alert-data";
import { LinearProgress } from "@material-ui/core";
export default withTranslation()(
  class InterviewPage extends React.Component {
    state = {
      openDialogue: false,
      lastQuestion: false,
      interviewQuestion: [{}],
      current: 0,
      isLoaded: false,
      answered: 0,
      openConfirm: false,
      retry: {},
      progressDialog: false,
      progress: 0,
      retryDialog: false,
    };
    componentDidMount() {
      this.getQuestion();
    }

    submitVideo(v, part) {
      let format = "";
      if (v.type) {
        format = v.type.split("/") ? "." + v.type.split("/").pop() : ".webm";
      } else {
        format = ".webm";
      }
      var data = new FormData();
      data.append("file", v, "interview-part-" + part + format);
      data.append("part", part);
      data.append("loading", false);
      data.append("prevent_error", true);

      this.setState({ isLoaded: false, progressDialog: true });

      RequestPost("InterviewVideo", data, this.config)
        .then(() => {
          this.setState({
            progressDialog: false,
            progress: 0,
            retry: {
              data: null,
              part: null,
            },
          });
          this.getCurrentVideo();
        })
        .catch(e => {
          this.setState({
            retry: {
              data: v,
              part: part,
            },
            progress: 0,
            isLoaded: true,
            retryDialog: true,
            progressDialog: false,
          });
          console.log("Failed post", e);
        });
    }

    resubmit() {
      const {
        retry: { data, part },
      } = this.state;
      this.setState({ retryDialog: false });
      console.log("resubmit function:", data, part);
      this.submitVideo(data, part);
    }

    progress(pe) {
      if (this.state.progress < 99.3) {
        this.setState({ progress: (pe.loaded / pe.total) * 100 });
      }
    }

    config = {
      onUploadProgress: progressEvent => this.progress(progressEvent),
    };

    getQuestion() {
      RequestGet("InterviewQuestion", { loading: true })
        .then(r => {
          this.setState({
            interviewQuestion: r.data.interviewVideoQuestions,
          });
          this.getCurrentVideo(true);
        })
        .catch(e => {
          alertData.show("Something wen't wrong", "error");
          this.setState({ lastQuestion: false });
          console.log("getQuestion()", e);
        });
    }

    getCurrentVideo(init = false) {
      RequestGet("InterviewVideo")
        .then(r => {
          if (this.state.interviewQuestion.length === r.data.length) {
            if (init) {
              AppHistory.push("/user/video-interview");
            } else {
              this.setState({ openDialogue: true });
            }
          } else {
            this.setState({ current: r.data.length, answered: r.data.length });
          }
          this.setState({ isLoaded: true });
        })
        .catch(e => {
          this.setState({ lastQuestion: false, current: 0 });
          this.setState({ isLoaded: true });
          console.log("getCurrentVideo()", e);
        });
    }

    render() {
      const {
        isLoaded,
        interviewQuestion,
        current,
        answered,
        openDialogue,
        retryDialog,
        progressDialog,
        progress,
      } = this.state;
      const { t } = this.props;
      return (
        <div style={{ paddingBottom: 80 }}>
          <ApplicantHeader hideMenu={true} />
          <FooterInterview
            progress={true}
            valueProgress={answered}
            totalProgress={interviewQuestion.length}
            textProgress={t("cv:videoInterview.questionAnswered")}
          />
          <DialogueFull
            open={progressDialog}
            hideClose={true}
            style={{ width: 500 }}>
            <div
              style={{
                backgroundColor: "white",
                width: 500,
                minHeight: 100,
                position: "relative",
                marginTop: 200,
                borderRadius: 4,
              }}>
              <div
                style={{ width: "100%", padding: 20, color: palette.primary }}>
                <p style={{ margin: 0, fontSize: 18 }}>
                  {t("cv:videoInterview.upload.uploading")}
                </p>
                <p
                  style={{
                    margin: "8px 0px",
                    color: palette.darkGrey,
                  }}>
                  {progress.toFixed(2)}
                  {"%"}
                </p>
              </div>
              <LinearProgress
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: 0,
                }}
                className={"VideoInterview progress"}
                variant="determinate"
                value={progress}
              />
            </div>
          </DialogueFull>
          <DialogueInfo
            open={retryDialog}
            title={t("cv:videoInterview.upload.failedTitle")}
            content={t("cv:videoInterview.upload.failedContent")}
            btnText={t("cv:videoInterview.upload.buttonRetry")}
            onClose={() => {
              this.resubmit();
            }}
          />

          <DialogueInfo
            open={openDialogue}
            title={t("basictest:info")}
            content={t("cv:videoInterview.completed")}
            onClose={() => {
              this.setState({ openDialogue: false });
              AppHistory.push("/user/video-interview");
            }}
          />
          <div
            style={{
              padding: "120px 100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 100,
            }}>
            {isLoaded && (
              <div
                style={{
                  width: 730,
                  height: 400,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: palette.primary,
                }}>
                <span
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                    marginBottom: 15,
                  }}>
                  {t("cv:videoInterview.question") + " " + (current + 1)}
                </span>
                <div
                  style={{
                    marginBottom: 20,
                    width: 455,
                    backgroundColor: palette.info,
                    padding: "20px 25px",
                    borderRadius: 10,
                    textAlign: "center",
                  }}>
                  {interviewQuestion[current]
                    ? getItem("lang") === "en"
                      ? interviewQuestion[current].interviewQuestionEng
                      : interviewQuestion[current].interviewQuestionIna
                    : ""}
                </div>
                <HGVideoRecorder
                  timeLimit={3}
                  countdownTime={10}
                  onSubmit={v => {
                    this.submitVideo(
                      v,
                      interviewQuestion[current].questionOrder
                    );
                  }}
                  onStop={() => {
                    this.setState({ answered: answered + 1 });

                    var btnRetake = document.getElementById("btn-retake");

                    if (getItem("VI___" + interviewQuestion[current].id)) {
                      btnRetake.style.opacity = 0.3;
                      btnRetake.setAttribute("disabled", true);
                    } else {
                      btnRetake.style.opacity = 1;
                      btnRetake.removeAttribute("disabled");
                    }
                  }}
                  onRetake={() => {
                    setItem("VI___" + interviewQuestion[current].id, true);
                    this.setState({ answered: answered - 1 });
                  }}
                  lastQuestion={true}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  }
);
