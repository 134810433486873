import React from "react";
import { withTranslation } from "react-i18next";
import { RequestGet, getItem } from "app/utils";
import ViewOne from "./view-one";
import { Page, View, Document } from "@react-pdf/renderer";
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: "row",
//     backgroundColor: "#E4E4E4",
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1,
//   },
// });
class DownloadCV extends React.Component {
  state = {
    personalInfo: {},
    listWorks: [],
    listFormalEducation: [],
    listInformalEducation: [],
    listOrganization: [],
    ...JSON.parse(getItem("user_info")),
    cvTemplate: null,
  };
  getBiodata() {
    this.setState({ content: <div></div> });
    RequestGet("biodata", { userId: getItem("user_id") })
      .then(res => {
        res.data.listWorks.sort(function (a, b) {
          return b.stillWorking - a.stillWorking;
        });
        res.data.listFormalEducation.sort(function (a, b) {
          return b.yearGraduate - a.yearGraduate;
        });
        return res.data;
      })
      .then(data => {
        this.setState(
          prev => ({ ...prev, ...data }),
          () => {
            this.setState({ cvTemplate: <ViewOne data={this.state} /> });
          }
        );
      })
      .catch(er => {
        console.log(er);
      });
  }
  componentDidMount() {
    this.getBiodata();
  }
  render() {
    const { cvTemplate } = this.state;
    return (
      <div>
        {cvTemplate ? (
          <Document>
            <Page size="A4">
              <View>{cvTemplate}</View>
            </Page>
          </Document>
        ) : (
          "null"
        )}
      </div>
    );
  }
}
export default withTranslation()(DownloadCV);
