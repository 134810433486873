import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Paper } from "@material-ui/core";
import {
  IcnUserNew,
  IcnClockNew,
  IcnNotebookNew,
  IcnLockNew,
  IcnCameraNew,
  IcnSilentArea,
  IcnBusinessManNew,
  IcnSignalNew,
  UncheckTick,
  CheckedTick,
} from "assets/img";
import { palette } from "assets/css/main";
import { NextBtn, Header, DialogueInfo } from "app/components";
import MyHelmet from "app/components/header/MyHelmet";
import { AppHistory, RequestGet, RequestPost, setItem } from "app/utils";
import alertData from "app/components/alert/alert-data";
import DialogueConfirm from "app/components/modal/confirm";
import moment from "moment";
const icon = [
  IcnUserNew,
  IcnClockNew,
  IcnNotebookNew,
  IcnLockNew,
  IcnCameraNew,
  IcnSilentArea,
  IcnBusinessManNew,
  IcnSignalNew,
];
class PretestPage extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    const dt = t("pretest:items", { returnObjects: true });
    var d = [];
    dt.forEach(el => {
      d.push(false);
    });
    this.state = { visible: false, open: false, data: d, dialogWarn: false };
  }
  handleClose = val => {
    const { t } = this.props;
    if (val) {
      RequestPost("examattempts/takelater")
        .then(res => {
          const { isHold } = res.data;
          if (isHold) {
            this.props.history.push("/user/logout");
          } else {
            alertData.show(t("pretest:confirm.failed"), "error");
          }
        })
        .catch(e => {
          console.log(e);
          alertData.show(t("pretest:confirm.failed"), "error");
        });
    }
    this.setState({ visible: false });
  };

  async getMedia(k) {
    var tmp = this.state.data;
    await navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .catch(e => {
        console.log("getMedia()", e);
        tmp[k] = false;
        this.setState({ data: tmp, dialogWarn: true });
      });
  }

  clickHandle(key) {
    var tmp = this.state.data;
    tmp[key] = !tmp[key];
    if (key === 2 || key === 4) {
      this.getMedia(key);
    }
    this.setState({ data: tmp });
  }

  checkTimeFinish = () => {
    const { t } = this.props;
    RequestGet("examattempts/timefinish")
      .then(r => {
        const { timeFinish } = r.data;
        this.setState({
          date: timeFinish.replace("Z", ""),
          disclaimer: "",
          open: true,
        });
      })
      .catch(() => {
        this.setState({
          date: moment().add(2, "days").toDate(),
          disclaimer: t("basictest:dialogue.disclaimer"),
          open: true,
        });
      });
  };

  render() {
    const { t } = this.props;
    const { visible, open, dialogWarn, disclaimer, date } = this.state;
    const data = t("pretest:items", { returnObjects: true });
    return (
      <div style={{ paddingTop: 100 }}>
        <MyHelmet title="Points For Attention" />
        <Header shadow={true} linkgroup={false} stepper={true} activeStep={1} />
        <DialogueInfo
          title={t("pretest:info.title")}
          open={open}
          content={t("pretest:info.description")}
          width={465}
          onClose={() => {
            this.setState({ open: false });
          }}
        />
        <DialogueInfo
          title={t("pretest:warn.title")}
          open={dialogWarn}
          content={t("pretest:warn.description")}
          width={465}
          onClose={() => {
            this.setState({ dialogWarn: false });
          }}
        />
        <DialogueConfirm
          onEnter={this.getDate}
          open={visible}
          title={t("basictest:dialogue.title")}
          content={t("basictest:dialogue.content")}
          btnYes={t("basictest:dialogue.yes")}
          btnNo={t("basictest:dialogue.no")}
          disclaimerTime={disclaimer}
          time={date}
          onOK={this.handleClose}
          onClose={() => {
            this.setState({ open: false });
          }}
        />
        <div
          style={{
            textAlign: "center",
            color: palette.primary,
            fontWeight: "bold",
            fontSize: 24,
          }}>
          {t("pretest:title")}
          <p style={{ margin: 0, fontSize: 14, fontWeight: "normal" }}>
            {t("pretest:subTitle")}
          </p>
        </div>
        <Grid
          container
          direction="row"
          style={{
            marginBottom: 100,
            paddingLeft: 100,
            paddingRight: 100,
            paddingTop: 50,
          }}>
          {data.map((v, key) => {
            return (
              <Grid item lg={3} md={3} key={key}>
                <Paper
                  style={{
                    margin: 10,
                    backgroundColor: palette.grey,
                    padding: 25,
                    height: 316,
                    textAlign: "center",
                    color: palette.primary,
                    boxSizing: "border-box",
                  }}>
                  <div style={{ marginBottom: 10 }}>
                    <img src={icon[v.icon]} alt={v.title} width={40} />
                  </div>
                  <div style={{ fontWeight: "bold", marginBottom: 20 }}>
                    {v.title}
                  </div>
                  <div style={{ height: 100 }}>{v.description}</div>
                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      marginTop: 20,
                    }}>
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.clickHandle(key);
                      }}
                      src={this.state.data[key] ? CheckedTick : UncheckTick}
                      alt="ch"
                      width={24}
                      height={24}
                    />
                  </div>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{
            height: 60,
            position: "fixed",
            bottom: 0,
            backgroundColor: palette.primary,
            paddingRight: 50,
            paddingLeft: 50,
          }}>
          {/* <div
            onClick={() => {
              this.setState({ visible: true });
            }}
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: 24,
              cursor: "pointer",
              textDecoration: "underline",
            }}>
            {t("pretest:button.testLater")}
          </div> */}
          <NextBtn
            color={"white"}
            onClick={() => {
              if (this.state.data.indexOf(false) > -1) {
                this.checkTimeFinish();
              } else {
                setItem("Block_Camera", false);
                AppHistory.push("/basic-test");
              }
            }}
            text={t("pretest:button.start")}
          />
        </Grid>
      </div>
    );
  }
}
export default withTranslation()(PretestPage);
