import React from "react";
import { Avatar } from "@material-ui/core";

export default class CustomAvatar extends React.Component {
  state = { customInitial: "C" };
  componentDidMount() {
    const { children } = this.props;
    if (typeof children === "string") {
      var tmp = children.toUpperCase().split(" ")[0];
      this.setState({ customInitial: tmp.split("")[0] });
    }
  }
  render() {
    const { src, alt, style } = this.props;
    const { customInitial } = this.state;
    return (
      <Avatar src={src} alt={alt} style={style}>
        {customInitial}
      </Avatar>
    );
  }
}
