import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { IcnInfo, IcnZoom, IcnCheck, IcnX } from "assets/img";
// import { InterestFooter } from "./footer-interest";
import btd from "../data";

import PreviewPage from "./preview";
import InterestFooter from "./footer-interest";
import { RequestPut, getItem, General } from "app/utils";
import IntroInterest from "./intro";
import InterestInstructions from "./instructions";
import { withTranslation } from "react-i18next";
import Information from "../cognitive/Information";

class CompetencePage extends React.Component {
  container = [];
  value = [];
  state = {
    container: [],
    current: 0,
    progress: 0,
    total: 0,
    selected: [],
    diselected: [],
    image: null,
    data: [],
    checked: false,
    intro: this.props.intro,
    open: this.props.intro ? false : true,
    timer: 3,
    footer: false,
    dialogInfo: false,
    preview: false,
    prevImage: null,
    maxStep: 0,
    step: 0,
    itemPerStep: 6,
    button: "Continue",
  };
  componentDidMount() {
    this.reset();
  }
  reset() {
    var s = [];
    General.visible = false;
    if (this.props.data) {
      var container = this.props.data.slice(
        this.state.step * this.state.itemPerStep,
        this.state.step * this.state.itemPerStep + this.state.itemPerStep
      );
      container.forEach(
        val => {
          s.push({
            id: val.id,
            image: getItem("lang") === "en" ? val.answerEn : val.answer,
            responseAnswer: null,
            questionDesc: val.questionDesc,
            answerDescEn: val.answerDescEn,
            answerDescId: val.answerDescId,
          });
        },
        this.setState(
          {
            container: s,
            footer: false,
            total: this.props.data.length / this.state.itemPerStep,
            current: this.state.step,
            checked: false,
          },
          () => {
            this.validate();
          }
        )
      );
    }
  }
  setSelected(v, k) {
    var idx = this.findData(this.state.container, v);
    var s = this.state.container;
    s[idx].responseAnswer = k;
    this.setState({ container: s, checked: true }, () => this.validate());
  }

  findData(data, key) {
    return data.findIndex(x => x.id === key);
  }
  DataValidate(data, key) {
    return data.findIndex(x => x[key] === null);
  }

  nextButtonClick() {
    btd.setCapable(this.state.container);

    if (
      this.props.data.length / this.state.itemPerStep ===
      this.state.step + 1
    ) {
      RequestPut("interestattempts", btd.capable)
        .then(res => {
          this.props.history.push("/basic-test");
        })
        .catch(e => {
          console.log("Capability.js:nextButtonClick()->save", e);
          btd.capable.splice(
            this.state.step * this.state.itemPerStep,
            this.state.step * this.state.itemPerStep + this.state.itemPerStep
          );
        });
    } else {
      this.setState({ step: this.state.step + 1, checked: false }, () => {
        this.reset();
      });
    }
  }

  validate() {
    const { t } = this.props;
    var c, p;
    if (this.DataValidate(this.state.container, "responseAnswer") === -1) {
      if (this.props.isLast) {
        btd.stepper.footer = true;
        btd.stepper.nextBtn = true;
      } else {
        this.setState({ footer: true });
      }
      c = this.state.step + 1;
      p = (c / this.state.total) * 100;
      if (
        this.props.data.length / this.state.itemPerStep ===
        this.state.step + 1
      ) {
        this.setState({
          button: t("button.saveandcontinue"),
          current: c,
          progress: p,
        });
      } else {
        this.setState({
          button: t("button.continue"),
          current: c,
          progress: p,
        });
      }
      General.visible = true;
    } else {
      General.visible = false;
      c = this.state.step;
      p = (c / this.state.total) * 100;
      this.setState({ footer: false, current: c, progress: p });
      if (this.props.isLast) {
        btd.stepper.footer = false;
        btd.stepper.nextBtn = false;
      }
    }
  }
  showPreview(i, d) {
    this.setState({ preview: true, prevImage: i, desc: d });
  }
  render() {
    const { title, info, t } = this.props;
    const {
      intro,
      current,
      progress,
      total,
      step,
      open,
      dialogInfo,
    } = this.state;
    return (
      <div>
        {this.props.data.map((v, k) => {
          return (
            <img
              style={{ display: "none" }}
              key={"sample-" + k}
              src={getItem("lang") === "en" ? v.answerEn : v.answer}
              alt={v.answerId}
              onLoadStart={() => {
                General.loading = true;
              }}
              onLoad={() => {
                General.loading = false;
              }}
            />
          );
        })}

        <Information
          noq={t("basictest:interestTest.noq")}
          timer={3}
          open={dialogInfo}
          total={total}
          title={title}
          onFinish={() => {
            this.setState({ dialogInfo: false });
          }}
        />

        {intro && (
          <IntroInterest
            onStart={() => {
              this.setState({ open: true, intro: false });
              this.props.onStart();
            }}
          />
        )}
        {open ? (
          <InterestInstructions
            instruction={info}
            notinline={true}
            title={title}
            questionID={this.props.questionID}
            onFinish={() => {
              this.setState({ intro: false, open: false, dialogInfo: true });
            }}
          />
        ) : (
          ""
        )}
        {!intro && !open ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            style={{
              display: this.props.current === this.props.index ? "" : "none",
              width: "100vw",
              paddingTop: 40,
              paddingLeft: 40,
              paddingRight: 40,
              marginBottom: 100,
            }}>
            <PreviewPage
              key={new Date().getTime()}
              open={this.state.preview}
              image={this.state.prevImage}
              title={this.state.desc}
              onClose={v => this.setState({ preview: v })}
            />
            <Typography
              variant="h4"
              style={{ color: palette.primary, fontWeight: "bold" }}>
              {this.state.container.length > 0 && title + " " + (step + 1)}
            </Typography>
            {info !== undefined && (
              <table style={{ width: 500, marginTop: 20, marginBottom: 20 }}>
                <tbody>
                  <tr>
                    <td width={40}>
                      <img width={25} src={IcnInfo} alt="info" />
                    </td>
                    <td style={{ color: "#B0B7BD" }}>
                      <Typography>
                        {t("basictest:interestTest.infoMatching")}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width={40}></td>
                    <td
                      style={{
                        color: "#B0B7BD",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <img
                        src={IcnCheck}
                        width={25}
                        alt={"Checklist"}
                        style={{ marginRight: 10 }}
                      />
                      <Typography>
                        {t("basictest:interestTest.competent")}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width={40}></td>
                    <td
                      style={{
                        color: "#B0B7BD",
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <img
                        src={IcnX}
                        alt="X"
                        width={25}
                        style={{ marginRight: 10 }}
                      />
                      <Typography>
                        {t("basictest:interestTest.notCompetent")}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {this.state.container !== undefined && (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ width: "100%", paddingTop: 40 }}>
                {this.state.container.map((v, k) => {
                  return (
                    <Grid item lg={2} md={2} key={k}>
                      <Grid
                        container
                        justify="center"
                        direction="column"
                        alignItems="center">
                        <div
                          id={"target-" + k}
                          style={{
                            height: 168,
                            width: 177,
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: 5,
                          }}
                          className={"Item-Image capability"}>
                          {v.responseAnswer != null && v.responseAnswer && (
                            <img
                              width={32}
                              style={{
                                position: "absolute",
                                right: 5,
                                top: 5,
                                zIndex: 2,
                              }}
                              src={IcnCheck}
                              alt="No"
                            />
                          )}
                          {v.responseAnswer != null && !v.responseAnswer && (
                            <img
                              width={32}
                              style={{
                                position: "absolute",
                                right: 5,
                                top: 5,
                                zIndex: 2,
                              }}
                              src={IcnX}
                              alt="No"
                            />
                          )}
                          <span
                            key={k}
                            className="Itemimg"
                            style={{
                              position: "relative",
                              backgroundColor:
                                v.responseAnswer != null && v.responseAnswer
                                  ? palette.primary
                                  : v.responseAnswer != null &&
                                    !v.responseAnswer
                                  ? palette.primary
                                  : palette.primary,
                            }}>
                            <Button
                              onClick={() =>
                                this.showPreview(
                                  v.image,
                                  getItem("lang") === "en"
                                    ? v.answerDescEn
                                    : v.answerDescId
                                )
                              }
                              variant={"contained"}
                              size={"small"}
                              className={"Preview"}
                              style={{
                                minWidth: 10,
                                padding: 5,
                                backgroundColor: "white",
                                position: "absolute",
                                bottom: 5,
                                right: 5,
                                zIndex: 10,
                              }}>
                              <img src={IcnZoom} alt="zoom" width={20} />
                            </Button>
                            <img
                              id={"image-id-selected-" + v.id}
                              className={"Images"}
                              src={v.image}
                              key={"image-id-selected-" + v.id}
                              style={{
                                cursor: "pointer",
                                zIndex: 2,
                                height: 168,
                                width: 177,
                                // borderRadius: 5,
                                opacity: v.responseAnswer !== null ? 0.6 : 1,
                              }}
                              alt={"Data" + k}
                            />
                          </span>
                          <div
                            className={"Footer Competence"}
                            style={{
                              backgroundColor: "white",
                              color: palette.primary,
                            }}>
                            {getItem("lang") === "en"
                              ? v.answerDescEn
                              : v.answerDescId}
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: 40,
                            backgroundColor: palette.grey,
                            width: 144,
                            height: 48,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 20,
                          }}>
                          <img
                            width={32}
                            style={{
                              marginRight: 10,
                              marginLeft: 10,
                              cursor: "pointer",
                            }}
                            src={IcnCheck}
                            onClick={() => this.setSelected(v.id, true)}
                            alt="YES"
                          />
                          <img
                            width={32}
                            style={{
                              marginRight: 10,
                              marginLeft: 10,
                              cursor: "pointer",
                            }}
                            onClick={() => this.setSelected(v.id, false)}
                            src={IcnX}
                            alt="No"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <InterestFooter
              enable={!this.state.footer}
              current={current}
              total={total}
              progress={progress}
              text={this.state.button}
              onClick={() => this.nextButtonClick()}
            />
          </Grid>
        ) : (
          "-"
        )}
      </div>
    );
  }
}
export default withTranslation()(CompetencePage);
