import React from "react";
import { DialogueFull } from "app/components";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import {
  findObject,
  getItem,
  RequestGet,
  RequestPost,
  RequestPut,
} from "app/utils";
import alertData from "app/components/alert/alert-data";
import { Button, Menu, MenuItem } from "@material-ui/core";
import Moment from "react-moment";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { IcnCheckFull } from "assets/img";
import ReschedueDialogue from "./reschedue-dialogue";

class UpdateInterviewResult extends React.Component {
  state = {
    data: {},
    options: [],
    isLoading: false,
    result: "",
    anchorElAt: null,
    anchorElRe: null,
    showeschedule: false,
    showResult: false,
    isPresent: false,
    value: null,
    dataReschedule: null,
    openReschedule: false,
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ anchorElAt: null, anchorElRe: null });
  }
  selectAttendace(v) {
    this.handleClose();
    this.setState({
      atValue: v,
      result: "",
      reValue: null,
      dataReschedule: null,
    });

    //show reschedule
    if (v.value === "rs") {
      this.setState({ showReschedule: true, showResult: false, value: "rs" });
    }
    //show result
    else if (v.value === "pr") {
      this.setState({
        showReschedule: false,
        showResult: true,
        isPresent: true,
        value: "pr",
      });
    } else {
      this.setState({
        showReschedule: false,
        showResult: false,
        result: "FAI",
        isPresent: false,
        value: "np",
      });
    }
  }
  selectResult(v) {
    this.handleClose();
    this.setState({ reValue: v, result: v.value });
  }
  getDetail() {
    this.setState({
      isLoading: false,
      tmpRes: {},
      atValue: null,
      reValue: null,
    });
    const { candidateId, vacancyId, onClose, t } = this.props;
    RequestGet("interview/result", {
      vacancyId: vacancyId,
      candidateId: candidateId,
    })
      .then(r => {
        this.setState(
          {
            data: r.data,
            isLoading: true,
            result: r.data.resultAnswer,
            showResult: r.data.resultAnswer ? true : false,
            options: r.data.listResult.map(v => {
              return {
                value: v.status,
                label: getItem("lang") === "id" ? v.textIna : v.textEng,
                description: getItem("lang") === "id" ? v.descIna : v.descEng,
              };
            }),
          },
          () => {
            this.setState({
              reValue: findObject(
                this.state.options,
                "value",
                this.state.result
              ),
            });
          }
        );
      })
      .catch(e => {
        console.log("getDetail()", e);
        alertData.show(t("messages.failedViewResult"), "error");
        onClose(false);
      });
  }

  submitData() {
    const { result, data, value, isPresent, dataReschedule } = this.state;
    const { onClose, t, candidateId } = this.props;

    if (value === "rs") {
      var dr = {
        interviewId: data.interviewId,
        candidateId: candidateId,
        timeslot: dataReschedule,
      };
      RequestPost("interview/reschedulerecruiter", dr)
        .then(() => {
          alertData.show(t("messages.successUpdate"), "success");
          onClose(true);
        })
        .catch(e => {
          alertData.show(t("messages.failedUpdate"), "error");
        });
    } else {
      if (isPresent === true && !result) {
        alertData.show(t("messages.invalidParameter"), "error");
        return false;
      }
      RequestPut("interview/result", {
        detailId: data.interviewDetailId,
        result: result,
        isPresent: isPresent,
      })
        .then(() => {
          alertData.show(t("messages.successUpdate"), "success");
          onClose(true);
        })
        .catch(e => {
          alertData.show(t("messages.successUpdate"), "error");
          console.log("submitData()", e);
        });
    }
  }

  render() {
    const { open, onClose, t } = this.props;
    const {
      data,
      options,
      anchorElRe,
      isLoading,
      atValue,
      anchorElAt,
      reValue,
      showResult,
      isPresent,
      showReschedule,
      dataReschedule,
      openReschedule,
    } = this.state;
    const listAttandance = t("options.attendance", { returnObjects: true });
    return (
      <DialogueFull
        style={{ display: openReschedule ? "none" : "" }}
        open={open}
        onEnter={() => this.getDetail()}
        onClose={() => {
          onClose(false);
        }}>
        <ReschedueDialogue
          open={openReschedule}
          onClose={ts => {
            this.setState({ openReschedule: false, dataReschedule: ts });
          }}
        />
        {isLoading ? (
          <div>
            <div
              style={{
                backgroundColor: "white",
                width: 900,
                boxSizing: "border-box",
                borderRadius: 4,
              }}>
              <div
                style={{
                  height: 20,
                  backgroundColor: palette.primary,
                  padding: "20px 20px",
                  color: "white",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                  fontSize: 18,
                }}>
                {t("updateResult.title")}
              </div>
              <div
                style={{
                  padding: 20,
                  height: "100%",
                  color: palette.primary,
                }}>
                <div style={{ marginBottom: 30 }}>
                  <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                    {t("updateResult.candidateName")}
                  </p>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 0,
                      textTransform: "capitalize",
                    }}>
                    {data.candidateName}
                  </p>
                </div>
                <div style={{ marginBottom: 30 }}>
                  <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                    {t("updateResult.interviewTitle")}
                  </p>
                  <p style={{ fontSize: 18, margin: 0 }}>
                    {data.interviewTitle}
                  </p>
                </div>
                <div style={{ marginBottom: 30 }}>
                  <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                    {t("updateResult.date")}
                  </p>
                  <p style={{ fontSize: 18, margin: 0 }}>
                    <Moment
                      locale={getItem("lang") === "id" ? "id" : "en"}
                      format="dddd DD MMMM yyyy">
                      {data.timeStart}
                    </Moment>
                    {", "}
                    <Moment
                      locale={getItem("lang") === "id" ? "id" : "en"}
                      format="HH.mm">
                      {data.timeStart}
                    </Moment>
                    {" - "}
                    <Moment
                      locale={getItem("lang") === "id" ? "id" : "en"}
                      format="HH.mm">
                      {data.timeEnd}
                    </Moment>
                    {" " + data.timezone}
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: 30,
                    display: "flex",
                    flexDirection: "row",
                  }}>
                  <div>
                    <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                      {t("updateResult.attendance")}
                    </p>
                    <Button
                      onClick={e => {
                        this.setState({ anchorElAt: e.currentTarget });
                      }}
                      style={{
                        backgroundColor: palette.primary,
                        color: "white",
                        textTransform: "capitalize",
                        width: 180,
                        justifyContent: "space-between",
                      }}>
                      {atValue ? atValue.label : t("updateResult.selectAt")}
                      {Boolean(anchorElAt) ? (
                        <ArrowDropUp />
                      ) : (
                        <ArrowDropDown />
                      )}
                    </Button>
                    <Menu
                      id="select-attendance"
                      anchorEl={anchorElAt}
                      open={Boolean(anchorElAt)}
                      style={{
                        fontWeight: "lighter",
                        marginTop: 50,
                        marginLeft: 20,
                        color: palette.primary,
                      }}
                      onClose={this.handleClose}>
                      {listAttandance.map((v, k) => {
                        return (
                          <MenuItem
                            key={"att" + k}
                            onClick={() => {
                              this.selectAttendace(v);
                            }}
                            style={{
                              color: palette.primary,
                              borderTop:
                                k === 0
                                  ? ""
                                  : "1px solid " + palette.popacity(0.3),
                            }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}>
                              <span>{v.label}</span>
                              <p
                                style={{
                                  fontWeight: "lighter",
                                  color: palette.darkGrey,
                                  fontSize: 12,
                                  margin: 0,
                                }}>
                                {v.description}
                              </p>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </div>
                  {showReschedule ? (
                    <div
                      style={{
                        marginLeft: 30,
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                        {t("updateResult.reschedule")}
                      </p>
                      <Button
                        onClick={() => {
                          this.setState({ openReschedule: true });
                        }}
                        style={{
                          border: "1px solid " + palette.primary,
                          color: palette.primary,
                          textTransform: "capitalize",
                        }}>
                        {t("updateResult.rescheduleTitle")}
                        {dataReschedule ? (
                          <img
                            src={IcnCheckFull}
                            alt="icon-check"
                            style={{ width: 18, height: 18, marginLeft: 10 }}
                          />
                        ) : null}
                      </Button>
                      <span
                        style={{
                          color: palette.darkGrey,
                          fontSize: 12,
                          fontWeight: "lighter",
                          marginTop: 10,
                          width: 200,
                        }}>
                        {t("updateResult.rescheduleHint")}
                      </span>
                    </div>
                  ) : null}

                  {showResult ? (
                    <div
                      style={{
                        marginLeft: 30,
                      }}>
                      <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                        {t("updateResult.result")}
                      </p>
                      <Button
                        onClick={e => {
                          this.setState({ anchorElRe: e.currentTarget });
                        }}
                        style={{
                          backgroundColor: palette.primary,
                          color: "white",
                          textTransform: "capitalize",
                          width: 180,
                          justifyContent: "space-between",
                        }}>
                        {reValue ? reValue.label : t("updateResult.select")}
                        {Boolean(anchorElRe) ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </Button>
                      <Menu
                        id="select-result"
                        anchorEl={anchorElRe}
                        open={Boolean(anchorElRe)}
                        style={{
                          fontWeight: "lighter",
                          marginTop: 50,
                          marginLeft: 20,
                          color: palette.primary,
                        }}
                        onClose={this.handleClose}>
                        {options.map((v, k) => {
                          return (
                            <MenuItem
                              key={"att" + k}
                              onClick={() => {
                                this.selectResult(v);
                              }}
                              style={{
                                color: palette.primary,
                                borderTop:
                                  k === 0
                                    ? ""
                                    : "1px solid " + palette.popacity(0.3),
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}>
                                <span>{v.label}</span>
                                <p
                                  style={{
                                    fontWeight: "lighter",
                                    color: palette.darkGrey,
                                    fontSize: 12,
                                    margin: 0,
                                  }}>
                                  {v.description}
                                </p>
                              </div>
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  bottom: 0,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  width: "100%",
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                  padding: "20px 50px",
                }}>
                <Button
                  disabled={
                    !atValue
                      ? true
                      : (showReschedule && dataReschedule) ||
                        (isPresent && reValue) ||
                        atValue.value === "np"
                      ? false
                      : true
                  }
                  onClick={() => {
                    this.submitData();
                  }}
                  style={{
                    backgroundColor: palette.primary,
                    opacity: !atValue
                      ? 0.5
                      : (showReschedule && dataReschedule) ||
                        (isPresent && reValue) ||
                        atValue.value === "np"
                      ? 1
                      : 0.5,
                    color: "white",
                    padding: "8px 50px",
                  }}>
                  OK
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </DialogueFull>
    );
  }
}
UpdateInterviewResult.defaultProps = {
  onClose: () => {
    console.error("Required onClose props");
  },
};
export default withTranslation("interview-schedule")(UpdateInterviewResult);
