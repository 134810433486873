import React from "react";
import Moment from "react-moment";
import { palette } from "assets/css/main";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import "../user-home.css";
import { findObject, findWithAttr } from "app/utils";

export const PersonalInfoItem = props => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 30,
        padding: "0px 10px",
      }}>
      <img src={props.image} width={20} height={20} alt={props.alt} />
      <p style={{ textAlign: "center" }}>
        {props.text}
        {props.date && (
          <>
            , <Moment date={props.date} format="DD/MM/yyyy" />
          </>
        )}
      </p>

      <span
        style={{
          display: props.isLast ? "none" : "",
          width: 100,
          borderBottom: "1px solid " + palette.primary,
        }}></span>
    </div>
  );
};

export const WorkExperienceView = props => {
  return (
    <div
      className={"user"}
      style={{
        display: props.data.length > 0 ? "" : "none",
      }}>
      <div
        style={{
          fontSize: 24,
          textTransform: props.titleCase ? "capitalize" : "uppercase",
        }}>
        {props.t("biodata:step.workexperience")}
      </div>
      <Timeline>
        {props.data.map((val, i) => {
          return (
            <TimelineItem key={"we-" + i}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                  <div>
                    <p className="title">{val.name}</p>
                    <p className="title">{val.role}</p>
                    <p className="subtitle">
                      {findWithAttr(
                        props.jobStatus,
                        "value",
                        val.status,
                        "label"
                      )}
                    </p>
                  </div>
                  <div style={{ paddingTop: 2, fontWeight: "lighter" }}>
                    <Moment date={val.yearStart} format="yyyy" /> -{" "}
                    {val.stillWorking ? (
                      props.t("biodata:form.field.stillWorking")
                    ) : (
                      <Moment date={val.yearEnd} format="yyyy" />
                    )}
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
};

export const FormalEducationView = props => {
  return (
    <div
      className="user"
      style={{
        display: props.data.length > 0 ? "" : "none",
      }}>
      <div
        style={{
          fontSize: 24,
          textTransform: props.titleCase ? "capitalize" : "uppercase",
        }}>
        {props.t("biodata:step.formaleducation")}
      </div>
      <Timeline>
        {props.data.map((val, i) => {
          return (
            <TimelineItem key={"fedu-" + i}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                  <div>
                    <p className="title">{val.institution}</p>
                    <p className="title">
                      {val.degree} - {val.major}
                    </p>
                    <p className="subtitle">
                      {props.t("biodata:form.field.gpa") + " : "}
                      {val.grade}
                    </p>
                  </div>
                  <div style={{ paddingTop: 2, fontWeight: "lighter" }}>
                    {props.t("biodata:form.field.graduated") + " "}
                    {val.yearGraduate}
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
};

export const InformalEducationView = props => {
  return (
    <div
      className="user"
      style={{
        display: props.data.length > 0 ? "" : "none",
      }}>
      <div
        style={{
          fontSize: 24,
          textTransform: props.titleCase ? "capitalize" : "uppercase",
        }}>
        {props.t("biodata:step.informalEducation")}
      </div>
      <Timeline>
        {props.data.map((val, i) => {
          return (
            <TimelineItem key={"fedu-" + i}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                  <div>
                    <p className="title">{val.institution}</p>
                    <p className="title">{val.name}</p>
                    <p className="subtitle">{val.study}</p>
                  </div>
                  <div
                    style={{
                      paddingTop: 2,
                      fontWeight: "lighter",
                      textTransform: "capitalize",
                    }}>
                    {props.t("biodata:form.field.year").toLowerCase() + " "}
                    {val.year}
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
};

export const OrganizationExpView = props => {
  return (
    <div
      className="user"
      style={{
        display: props.data.length > 0 ? "" : "none",
      }}>
      <div
        style={{
          fontSize: 24,
          textTransform: props.titleCase ? "capitalize" : "uppercase",
        }}>
        {props.t("biodata:step.organizationalExperience")}
      </div>
      <Timeline>
        {props.data.map((val, i) => {
          var position = findObject(
            props.t("biodata:options.organizationPosition", {
              returnObjects: true,
            }),
            "value",
            val.position
          );
          return (
            <TimelineItem key={"fedu-" + i}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                  <div>
                    <p className="title">{val.name}</p>
                    <p className="title">{val.field}</p>
                    <p className="subtitle">
                      {position.label}{" "}
                      {val.function && <span>-{" " + val.function}</span>}
                    </p>
                  </div>
                  <div
                    style={{
                      paddingTop: 2,
                      fontWeight: "lighter",
                      textTransform: "capitalize",
                    }}>
                    {props.t("biodata:form.field.year").toLowerCase() + " "}
                    {val.year}
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
};
