import React from "react";
import { withTranslation } from "react-i18next";
import "./custom.css";
import { palette } from "assets/css/main";
import { Button } from "@material-ui/core";

const SoftSkillPopup = props => {
  const { t } = props;
  return (
    <div className="popup-box">
      <div className="box">
        <div
          style={{ height: "85%", width: "100%", padding: "30px 40px 80px" }}
        >
          {props.content}
        </div>
      </div>
      <div className="footer">
        <Button
          style={{ backgroundColor: palette.primary, fontSize: "14px", fontWeight: "bold", color: "white", padding: "11px 31px 6px", margin:"2% 75% 2%", textTransform: "none" }}
          onClick={() => {props.handleClose(false)}}
        >
          {t("vacancy:create.softSkill.popup")}
        </Button>
      </div>
    </div>
  );
};
 
export default withTranslation()(SoftSkillPopup);