import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography, TextField } from "@material-ui/core";
import { palette } from "assets/css/main";
import data from "./data";
import WizardForm from "app/components/typeform/WizardForm";
import { DialogueInfo, Options } from "app/components";
import "./custom.css";
import {
  getItem,
  RequestPost,
  General,
  AppHistory,
  findDuplicate,
} from "app/utils";
import HgDatePicker from "app/components/general/HgDatePicker";
// import { logError } from "app/utils/general/rollbar";

class InformalEducation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...data.informal_education,
      user: JSON.parse(getItem("user_info")),
      key: new Date().getTime(),
      tmpYear: undefined,
      showDuplicate: false,
      showFailed: false,
    };
    General.visible = false;
    data.stepper.footer = false;
    data.informal_education.userId = getItem("user_id");
  }
  handleBack() {
    this.setState({ endStepReq: false });
    data.stepper.footer = false;
    data.stepper.nextBtn = false;
  }
  handleChange(obj) {
    this.setState(obj);
    data.setInformalEducation(obj);
    var e = document.getElementById("error-messages");
    var el = document.getElementsByClassName("MuiInput-underline");
    var label = document.getElementsByClassName("MuiInputLabel-root");
    try {
      e.innerText = "";
      document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
      label[0].classList.remove("Mui-error");
      el[0].classList.remove("Mui-error");
    } catch (error) {}
    if (this.state.endStepReq) {
      data.stepper.footer = true;
      data.stepper.nextBtn = true;
    }
  }

  submit(isSkip = false, init = true) {
    data.personalInfo = null;
    data.listFormalEducation = [];
    data.listOrganization = [];

    data.listWorks = [];
    data.informal_education.year = parseInt(data.informal_education.year);
    data.informal_education.isSertifikat = Boolean(
      data.informal_education.isSertifikat
    );

    if (init) {
      data.listInformalEducation.push(data.informal_education);
    }

    if (findDuplicate(JSON.parse(JSON.stringify(data.listInformalEducation)))) {
      this.setState({ showDuplicate: true });
    } else {
      if (isSkip) {
        data.listInformalEducation = null;
      }
      RequestPost("biodata", data)
        .then(() => {
          data.listInformalEducation = [];
          window.location.reload();
        })
        .catch(e => {
          // logError("informal-education :" + e);
          this.setState({ showFailed: true });
        });
    }
  }
  addInformalEducation() {
    this.setState({
      key: new Date().getTime(),
      reset: true,
      tmpYear: undefined,
    });
    data.informal_education.year = parseInt(data.informal_education.year);
    data.informal_education.isSertifikat = Boolean(
      data.informal_education.isSertifikat
    );
    data.listInformalEducation.push(data.informal_education);
    data.setInformalEducation({
      name: "",
      study: "",
      institution: "",
      year: 0,
      isSertifikat: undefined,
    });

    AppHistory.push(
      "/initialize/biodata/addinformal" + data.listInformalEducation.length
    );
  }
  componentDidMount() {
    var a = document.getElementById("next-footer");
    if (data.listInformalEducation.length > 0) {
      a.click();
    }
  }
  render() {
    const { t } = this.props;
    const certificates = t("biodata:options.certificate", {
      returnObjects: true,
    });
    const isHaveInformal = t("biodata:options.yesNo", {
      returnObjects: true,
    });
    const { showDuplicate, showFailed } = this.state;

    return (
      <Grid container style={{ padding: "30px 0px", width: "100vw" }}>
        <DialogueInfo
          title={t("biodata:duplicate.title")}
          content={t("biodata:duplicate.content")}
          open={showDuplicate}
          onClose={() => {
            this.setState({ showDuplicate: false }, () => {
              window.location.reload();
            });
          }}
        />
        <DialogueInfo
          title={t("biodata:failed.title")}
          content={t("biodata:failed.content")}
          open={showFailed}
          onClose={() => {
            this.setState({ showFailed: false }, () => {
              this.submit(false, false);
            });
          }}
        />
        <Typography
          style={{
            padding: "0px 25%",
            color: palette.primary,
            fontWeight: "bold",
            width: 400,
          }}>
          {t("biodata:step.informalEducation")}
        </Typography>
        <WizardForm
          reset={this.state.reset}
          onReset={bool => {
            this.setState({ reset: bool });
          }}
          onSubmit={isSkip => {
            this.submit(isSkip);
          }}>
          <Options
            skipAble={true}
            conditionValue={"n"}
            required={true}
            label={t("biodata:form.field.informalEducation")}
            onChange={e => {
              this.handleChange({ isHaveInformal: e.value });
            }}
            options={isHaveInformal}
            value={this.state.isHaveInformal}
          />
          <TextField
            autoFocus={true}
            onChange={e => {
              this.handleChange({ name: e.currentTarget.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            required={true}
            placeholder={t("biodata:form.placeholder.trainingName")}
            label={t("biodata:form.field.trainingName")}
            className="Field"
            value={this.state.name}
            maxLength={50}
          />
          <TextField
            autoFocus={true}
            onChange={e => {
              this.handleChange({ study: e.currentTarget.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={t("biodata:form.placeholder.area")}
            label={t("biodata:form.field.area")}
            className="Field"
            value={this.state.study}
            maxLength={50}
          />
          <TextField
            autoFocus={true}
            onChange={e => {
              this.handleChange({ institution: e.currentTarget.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            maxLength={50}
            placeholder={t("biodata:form.placeholder.institution")}
            label={t("biodata:form.field.institution")}
            className="Field"
            value={this.state.institution}
          />
          <HgDatePicker
            className="one"
            autoFocus={true}
            minDate={new Date("1950-01-01")}
            maxDate={new Date()}
            label={t("biodata:form.field.yearInformal")}
            format="yyyy"
            maxDetail="decade"
            value={this.state.tmpYear}
            onChange={e => {
              if (e) {
                General.visible = true;
                this.handleChange({
                  tmpYear: e,
                  year: e.getFullYear(),
                });
              }
            }}
            yearPlaceholder={t("biodata:form.placeholder.year")}
          />
          <div
            required={true}
            value={this.state.isSertifikat === undefined ? false : true}>
            <Options
              required={true}
              label={t("biodata:form.field.certificate")}
              onChange={e => {
                this.handleChange({ isSertifikat: e.value });
              }}
              options={certificates}
              value={this.state.isSertifikat}
            />
            {this.state.isSertifikat !== undefined && (
              <div
                onClick={() => this.addInformalEducation()}
                style={{
                  color: palette.primary,
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginTop: 150,
                  position: "absolute",
                  cursor: "pointer",
                }}>
                {t("biodata:addInformalEducation")}
              </div>
            )}
          </div>
        </WizardForm>
      </Grid>
    );
  }
}
export default withTranslation()(InformalEducation);
