import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "./fullcalendar.css";
import { IcnCircleBack, IcnCircleNext } from "assets/img";
import "moment/locale/id";
import { getItem } from "app/utils";
moment.locale(getItem("lang"));
const localizer = momentLocalizer(moment);
class FullCalendar extends React.Component {
  componentDidMount() {
    try {
      var el = document.getElementsByClassName("rbc-btn-group")[0].children;
      var back = document.createElement("img");
      var next = document.createElement("img");

      back.setAttribute("src", IcnCircleBack);
      back.className = "back-button";
      back.onclick = () => {
        document.getElementById("monthBack-btn").click();
      };

      next.setAttribute("src", IcnCircleNext);
      next.className = "next-button";
      next.onclick = () => {
        document.getElementById("monthNext-btn").click();
      };

      document.getElementsByClassName("rbc-toolbar")[0].appendChild(back);
      document.getElementsByClassName("rbc-toolbar")[0].appendChild(next);

      el[0].setAttribute("id", "today-btn");
      el[1].setAttribute("id", "monthBack-btn");
      el[2].setAttribute("id", "monthNext-btn");
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const { events, style, onSelected } = this.props;
    return (
      <Calendar
        className="full-calendar"
        onSelectEvent={e => {
          onSelected(e);
        }}
        tra
        view="month"
        onView={() => {}}
        defaultDate={new Date()}
        localizer={localizer}
        events={events ? events : []}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, ...style }}
      />
    );
  }
}
FullCalendar.defaultProps = {
  events: [],
  style: {},
  onSelected: () => {},
};
export default FullCalendar;
