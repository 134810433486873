import React from "react";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import CandidateInfoExport, { saveDocument } from ".";
import { AppHistory, General, RequestGet } from "app/utils";
export default class TemporaryView extends React.Component {
  state = {
    loadingFinish: false,
    downloadOnly: true,
    data: {
      biodata: {},
      about: {},
      workpreference: {},
      diagnostic: [],
    },
  };
  async componentDidMount() {
    const id = this.props.id
      ? this.props.id
      : AppHistory.location.pathname.split("/")[3];
    if (id) {
      await this.getBiodata(id);
      await this.getAbout(id);
      await this.getWorkPreference(id);
      await this.getDiagnostic(id);
      this.setState({ loadingFinish: true });
    } else {
      AppHistory.goBack();
    }
    if (this.state.downloadOnly) {
      General.loading = true;
      this.downloadPDF();
    }
  }
  getBiodata = async id => {
    await RequestGet("candidate/" + id)
      .then(r => {
        var { data } = this.state;
        data.biodata = r.data;
        this.setState(data);
      })
      .catch(e => {
        console.log("getBiodata()", e);
      });
  };
  getAbout = async id => {
    await RequestGet("candidate/about/" + id)
      .then(r => {
        var { data } = this.state;
        data.about = r.data;
        this.setState(data);
      })
      .catch(e => {
        console.log("getAbout()", e);
      });
  };
  getWorkPreference = async id => {
    await RequestGet("candidate/workpreference/" + id)
      .then(r => {
        var { data } = this.state;
        data.workpreference = r.data;
        this.setState(data);
      })
      .catch(e => {
        console.log("getWorkPreference()", e);
      });
  };
  getDiagnostic = async id => {
    await RequestGet("candidate/result/" + id)
      .then(r => {
        var { data } = this.state;
        data.diagnostic = r.data;
        this.setState({ data: data });
      })
      .catch(e => {
        console.log("getDiagnostic()", e);
      });
  };
  downloadPDF = async () => {
    const { data } = this.state;
    const { status, filename } = this.props;
    await pdf(
      <CandidateInfoExport
        status={status === "Selected" ? "SEL" : status}
        data={data}
      />
    )
      .toBlob()
      .then(x => {
        saveDocument(
          window.URL.createObjectURL(x),
          "Candidate Information - " + filename + ".pdf"
        );
        this.props.onDownload();
      });
    General.loading = false;
  };
  render() {
    const { data, loadingFinish, downloadOnly } = this.state;
    return (
      <>
        {downloadOnly ? (
          <></>
        ) : (
          <div>
            {loadingFinish ? (
              <PDFViewer style={{ width: "100%", height: "100vw" }}>
                <CandidateInfoExport data={data} />
              </PDFViewer>
            ) : (
              "Loading"
            )}
          </div>
        )}
      </>
    );
  }
}
