import React from "react";
import { Text, View, Page } from "@react-pdf/renderer";
import { CIStyles, HeaderLogoPDF, WaterMark } from "./ci-styles";
import { palette } from "assets/css/main";

const CIPageProfileReport = props => {
  const listData = props.data ? props.data : [];
  const profile = listData[3] ? listData[3] : [{ softSkillHeaders: [{}] }];
  return (
    <Page style={CIStyles.page}>
      <WaterMark />
      <HeaderLogoPDF />
      <View style={{ position: "fixed" }} fixed>
        <Text style={{ color: palette.primary, fontWeight: "bold" }}>
          IV. Profile report
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 20,
        }}>
        <Text style={{ fontSize: 18, fontWeight: "bold" }}>
          1. Diagnostic Report
        </Text>
      </View>
      {listData.map((v, k) => {
        if (k < 3) {
          return (
            <View key={k}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 30,
                }}>
                <View style={CIStyles.subtitleLine} />
                <Text style={{ fontSize: 14 }}>
                  1.{k + 1}. {v.categoryNameEng}
                </Text>
              </View>
              <View style={{ marginTop: 15 }}>
                <TableItemPR
                  head={true}
                  num={"DIMENSION"}
                  title={"SCALE"}
                  value={"DESCRIPTION"}
                />
                {v.softSkillHeaders.map((vd, kd) => {
                  return (
                    <View key={"detail" + kd}>
                      <TableItemSinglePR value={vd.softskillNameEng} />
                      {vd.softSkillDetails.map((ssd, ssk) => {
                        const val = ssd.value.toLowerCase();
                        const item = (
                          <View
                            style={{
                              backgroundColor:
                                val === "low"
                                  ? palette.cv.low
                                  : val === "medium"
                                  ? palette.cv.medium
                                  : palette.cv.high,
                              borderRadius: 20,
                              padding: "5pt 5pt 7pt 10pt",
                              color:
                                val === "medium"
                                  ? palette.cv.textColor
                                  : "white",
                              textTransform: "capitalize",
                              width:
                                val === "low"
                                  ? 60
                                  : val === "medium"
                                  ? 100
                                  : 140,
                            }}>
                            <Text>{val}</Text>
                          </View>
                        );

                        return (
                          <TableItemPR
                            key={"sdd" + ssk}
                            num={ssd.softskillValueEng}
                            title={item}
                            value={ssd.descriptionEng}
                          />
                        );
                      })}
                    </View>
                  );
                })}
              </View>
            </View>
          );
        } else {
          return <View key={"ld" + k}></View>;
        }
      })}
      <View
        break
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 30,
        }}>
        <Text style={{ fontSize: 18, fontWeight: "bold" }}>
          2. Profile Description
        </Text>
      </View>
      <View
        style={{
          marginTop: 15,
          fontSize: 14,
          color: palette.cv.textColor,
          fontWeight: "light",
        }}>
        <Text>{profile.softSkillHeaders[0].softskillDescEng}</Text>
      </View>
    </Page>
  );
};
export default CIPageProfileReport;

const TableItemPR = props => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        fontSize: 12,
        width: "100%",
        borderTop: "1pt solid " + palette.cv.borderColor,
        borderBottom: "1pt solid " + palette.cv.borderColor,
        marginTop: -1,
      }}>
      <View
        break
        style={{
          color: palette.cv.textGrey,
          textAlign: "center",
          borderRight: props.head
            ? "none"
            : "1pt solid " + palette.cv.borderColor,
          width: "20%",
          padding: "5pt 5pt",
          fontWeight: "light",
        }}>
        <Text>{props.num}</Text>
      </View>
      <View
        break
        style={{
          color: palette.cv.textGrey,
          textAlign: props.head ? "center" : "left",
          borderRight: props.head
            ? "none"
            : "1pt solid " + palette.cv.borderColor,
          width: "30%",
          padding: "5pt 5pt",
          fontWeight: "light",
        }}>
        {typeof props.title !== "object" ? (
          <Text style={{}}>{props.title}</Text>
        ) : (
          props.title
        )}
      </View>
      <View
        break
        style={{
          color: props.head ? palette.cv.textGrey : palette.cv.textColor,
          textAlign: props.head ? "center" : "left",
          width: "50%",
          padding: "5pt 5pt",
          fontWeight: props.head ? "light" : "normal",
        }}>
        {typeof props.value !== "object" ? (
          <Text style={{}}>{props.value}</Text>
        ) : (
          props.value
        )}
      </View>
    </View>
  );
};
const TableItemSinglePR = props => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        fontSize: 12,
        width: "100%",
        borderTop: "1pt solid " + palette.cv.borderColor,
        borderBottom: "1pt solid " + palette.cv.borderColor,
        marginTop: -1,
      }}
      break>
      <View
        style={{
          width: "100%",
          textAlign: props.head ? "center" : "left",
          backgroundColor: palette.cv.borderColor,
          color: palette.cv.textColor,
          padding: "5pt 5pt",
          fontWeight: props.head ? "light" : "normal",
        }}>
        {typeof props.value !== "object" ? (
          <Text style={{}}>{props.value}</Text>
        ) : (
          props.value
        )}
      </View>
    </View>
  );
};
