import React from "react";
import { withTranslation } from "react-i18next";
import { ApplicantHeader, DialogueInfo } from "app/components";
import { FooterInterview } from "./footer-interview";
import { palette } from "assets/css/main";
import {
  CheckWebcam,
  CheckInternet,
  CheckMicrophone,
  CheckLaptop,
} from "assets/img";
import { AppHistory } from "app/utils";

export default withTranslation()(
  class EquipmentInterview extends React.Component {
    state = {
      dialogWarn: false,
    };
    async getMedia() {
      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(() => {})
        .catch(() => {
          this.setState({ dialogWarn: true });
        });
    }
    componentDidMount() {
      this.getMedia();
    }
    render() {
      const { t } = this.props;
      const devices = t("cv:videoInterview.hardware", { returnObjects: true });
      const img = [CheckWebcam, CheckInternet, CheckMicrophone, CheckLaptop];
      const { dialogWarn } = this.state;
      return (
        <div>
          <ApplicantHeader hideMenu={true} />
          <DialogueInfo
            title={t("pretest:warn.title")}
            open={dialogWarn}
            content={t("pretest:warn.description")}
            width={465}
            onClose={() => {
              this.getMedia();
              this.setState({ dialogWarn: false });
            }}
          />
          <div
            style={{
              padding: "100px 100px",
              color: palette.primary,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              boxSizing: "border-box",
              alignItems: "center",
            }}>
            <div style={{ padding: 40, fontSize: 24, fontWeight: "bold" }}>
              {t("cv:videoInterview.hardwareCheck")}
            </div>
            {devices.map((v, i) => {
              return (
                <div
                  key={i}
                  style={{
                    backgroundColor: palette.grey,
                    borderRadius: 4,
                    minWidth: 600,
                    marginBottom: 20,
                    boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}>
                  <img
                    src={img[i]}
                    style={{ width: 74, height: 77, padding: "20px 20px" }}
                    alt={"icon-" + i}
                  />
                  <div>
                    {v.name}
                    <br />
                    <span style={{ fontWeight: "lighter" }}>{v.ready}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <FooterInterview
            NextClick={() => {
              AppHistory.push("/user/interview-sample");
            }}
            text={t("cv:videoInterview.startInterview")}
          />
        </div>
      );
    }
  }
);
