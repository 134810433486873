import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import RecruiterFooter from "app/components/footer/RecruiterFooter";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette } from "assets/css/main";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Button,
  TextField,
} from "@material-ui/core";
import { MultipleTextField } from "app/components";
import data from "./data";
import StepHeader from "app/components/header/StepHeader";
import {
  IconInformation,
  IconSuggestion,
  ImgQuestionBackground,
} from "assets/img";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  RequestGet,
  getItem,
  GetLocalItem,
  SetLocalItem,
  AppHistory,
  findObject,
} from "app/utils";
import alertData from "app/components/alert/alert-data";

const creatableSelectStyles = {
  borderWidth: "0px 0px 1px 0px",
  borderRadius: "0px",
};

class JobRequirement extends React.Component {
  constructor(props) {
    super(props);
    if (GetLocalItem("vacancyPost")) {
      data.vacancy_post = GetLocalItem("vacancyPost");
    }

    this.state = {
      ...data.vacancy_post,
      listVacancy: data.listVacancy,
      performs: [],
      workEnvs: [],
      univPrefs: [],
      majorPrefs: [],
      listPerform: [],
      listWorkEnv: [],
      listUnivPref: [],
      listMajorPref: [],
      otherWorkEnvs: [],
      otherPerforms: [],
      otherWorkEnvID: "",
      otherPerformID: "",
    };
    this.translate = this.props.t;
  }

  creatableRef = null;
  creatableRefPerform = null;

  cekValidation() {
    var result = false;

    if (data.vacancy_post.vacancyRequirement.hasCertificate) {
      if (
        data.vacancy_post.vacancyRequirement.languageSkill !== "" &&
        typeof data.vacancy_post.vacancyRequirement.hasCertificate !==
          "undefined" &&
        data.vacancy_post.vacancyRequirementJobPerform.length > 0 &&
        data.vacancy_post.vacancyRequirementEnvironmentWork.length > 0 &&
        data.vacancy_post.vacancyRequirementCertificate.length > 0 &&
        data.vacancy_post.vacancyRequirementCertificate[0] !== ""
      ) {
        result = true;
      }
    } else {
      if (
        data.vacancy_post.vacancyRequirement.hasCertificate !== null &&
        data.vacancy_post.vacancyRequirement.languageSkill !== "" &&
        typeof data.vacancy_post.vacancyRequirement.hasCertificate !==
          "undefined" &&
        data.vacancy_post.vacancyRequirementJobPerform.length > 0 &&
        data.vacancy_post.vacancyRequirementEnvironmentWork.length > 0
      ) {
        result = true;
      }
    }

    return result;
  }

  saveData() {
    if (this.cekValidation()) {
      SetLocalItem("vacancyPost", data.vacancy_post);
      AppHistory.push("/recruiter/soft-skill");
    } else {
      alertData.show(this.props.t("vacancy:create.validation"), "error");
    }
  }

  setup(list) {
    this.setState({ listVacancy: list }, () => {
      this.setState({ loaded: true });
    });
    data.listVacancy = list;

    // performs
    var otPerform = list.jobPerform.find(p => p.jobPerformEng === "Others").id;
    this.setState({ otherPerformID: otPerform });
    this.setState({
      listPerform: list.jobPerform.map(obj => {
        return {
          value: obj.id,
          label:
            getItem("lang") === "en" ? obj.jobPerformEng : obj.jobPerformIna,
        };
      }),
    });
    this.setState({
      performs: data.vacancy_post.vacancyRequirementJobPerform.map(v => {
        return {
          value: v,
          label:
            getItem("lang") === "en"
              ? list.jobPerform.find(p => p.id === v).jobPerformEng
              : list.jobPerform.find(p => p.id === v).jobPerformIna,
        };
      }),
    });

    // work environtment
    var ot = list.environmentWork.find(p => p.workEnvironmentEng === "Others")
      .id;
    this.setState({ otherWorkEnvID: ot });
    this.setState({
      listWorkEnv: list.environmentWork.map(obj => {
        return {
          value: obj.id,
          label:
            getItem("lang") === "en"
              ? obj.workEnvironmentEng
              : obj.workEnvironmentIna,
        };
      }),
    });
    this.setState({
      workEnvs: data.vacancy_post.vacancyRequirementEnvironmentWork.map(v => {
        var environmentWork = list.environmentWork.find(p => p.id === v);
        return {
          value: v,
          label:
            getItem("lang") === "en"
              ? environmentWork.workEnvironmentEng
              : environmentWork.workEnvironmentIna,
        };
      }),
    });

    // university preference
    var noPrefUniv = {
      value: "noPref",
      label: this.props.t("vacancy:create.jobReq.noPref"), // => hardcoded no preference
    };
    var hcListUnivPref = [];
    hcListUnivPref.push(noPrefUniv);
    list.listUniversity.map(obj => {
      var hcUnivPref = {
        value: obj.id,
        label: obj.university,
      };
      hcListUnivPref.push(hcUnivPref);
      return null;
    });
    this.setState({ listUnivPref: hcListUnivPref });
    this.setState({
      univPrefs: data.vacancy_post.vacancyUniversitys.map(v => {
        var univPref = list.listUniversity.find(p => p.id === v);
        return {
          value: v,
          label: univPref.university,
        };
      }),
    });

    // major preference
    var noPrefMajor = {
      value: "noPref",
      label: this.props.t("vacancy:create.jobReq.noPref"), // => hardcoded no preference
    };
    var hcListMajorPref = [];
    hcListMajorPref.push(noPrefMajor);
    list.listMajorAlias.map(obj => {
      var hcMajorPref = {
        value: obj.id,
        label: getItem("lang") === "en" ? obj.aliasEng : obj.aliasIna,
      };
      hcListMajorPref.push(hcMajorPref);
      return null;
    });
    this.setState({ listMajorPref: hcListMajorPref });
    this.setState({
      majorPrefs: data.vacancy_post.vacancyMajorAliases.map(v => {
        var majorPref = list.listMajorAlias.find(p => p.id === v);
        return {
          value: v,
          label:
            getItem("lang") === "en" ? majorPref.aliasEng : majorPref.aliasIna,
        };
      }),
    });
  }

  getVacancyDetail() {
    if (GetLocalItem("vacancyGet")) {
      this.setup(GetLocalItem("vacancyGet"));
    } else {
      RequestGet("vacancy")
        .then(res => {
          SetLocalItem("vacancyGet", res.data);
          this.setup(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  componentDidMount() {
    this.getVacancyDetail();
  }

  handleSuggestion(value, param) {
    var val = null;
    if (param === "workEnv") {
      var currentEnvirontment = this.state.workEnvs;
      currentEnvirontment.push(value);

      this.setState(
        {
          workEnvs: currentEnvirontment,
          classMenu: "add_from_suggestion",
        },
        () => {
          this.creatableRef.focus();
        }
      );

      setTimeout(() => {
        this.creatableRef.blur();
        this.setState({ classMenu: "" });
      }, 100);

      val = currentEnvirontment.map(v => {
        return v.value;
      });
      this.setState({ vacancyRequirementEnvironmentWork: val });
      data.setVacancyPost({ vacancyRequirementEnvironmentWork: val });
    } else if (param === "perform") {
      var currentPerform = this.state.performs;
      currentPerform.push(value);
      this.setState(
        {
          performs: currentPerform,
          classMenuPerform: "add_from_suggestion",
        },
        () => {
          this.creatableRefPerform.focus();
        }
      );

      setTimeout(() => {
        this.creatableRefPerform.blur();
        this.setState({ classMenuPerform: "" });
      }, 100);

      val = currentPerform.map(v => {
        return v.value;
      });
      this.setState({ vacancyRequirementJobPerform: val });
      data.setVacancyPost({ vacancyRequirementJobPerform: val });
    } else {
      // nothing
    }
  }

  handleVacancy(obj) {
    var newVacancy = data.vacancy_post.vacancy;
    newVacancy = { ...newVacancy, ...obj };
    this.setState({ vacancy: newVacancy });
    data.setVacancyPost({ vacancy: newVacancy });
  }

  handleRequirement(obj) {
    var newReq = data.vacancy_post.vacancyRequirement;
    newReq = { ...newReq, ...obj };
    this.setState({ vacancyRequirement: newReq });
    data.setVacancyPost({ vacancyRequirement: newReq });
  }

  render() {
    const {
      listVacancy,
      vacancyRequirement,
      vacancyRequirementCertificate,
      vacancy,
      listPerform,
      performs,
      listWorkEnv,
      workEnvs,
      listUnivPref,
      univPrefs,
      listMajorPref,
      majorPrefs,
      otherWorkEnvs,
      otherWorkEnvID,
      otherPerforms,
      otherPerformID,
      classMenu,
      classMenuPerform,
    } = this.state;
    const { t } = this.props;
    const stepData = t("vacancy:create.step", { returnObjects: true });
    return (
      <div
        className="create-vacancy font-hurme"
        style={{
          position: "relative",
        }}>
        <MyHelmet title="Dashboard" />
        <RecruiterAppBar
          shadow={true}
          menu="dashboard"
          history={this.props.history}
        />
        <StepHeader
          data={stepData}
          stepWidth={650}
          activeStep={1}
          isAZ={true}
          style={{
            padding: "0px 100px",
            marginTop: 80,
            backgroundColor: palette.info,
          }}
        />
        <Grid container style={{ padding: "3% 22% 5% " }}>
          <Grid item lg={12} md={12} sm={12}>
            <Typography
              style={{
                fontSize: "24px",
                color: palette.primary,
              }}>
              <Grid container>
                <Grid item lg={4} md={4} sm={4}>
                  {t("vacancy:create.jobReq.title")}
                </Grid>
                <Grid item lg={8} md={8} sm={8}>
                  <img
                    className="information-tag"
                    style={{
                      marginLeft: -40,
                      marginBottom: -2,
                    }}
                    src={IconInformation}
                    height={"15h"}
                    alt="InformationTag"
                  />
                  <div
                    className="information"
                    style={{
                      position: "absolute",
                      marginLeft: -158,
                      marginTop: -5,
                      zIndex: 10,
                    }}>
                    <img
                      src={ImgQuestionBackground}
                      height={"77px"}
                      width={"250px"}
                      alt="Information"
                    />

                    <div
                      style={{
                        position: "absolute",
                        color: "white",
                        fontSize: 12,
                        fontWeight: "lighter",
                        textTransform: "none",
                        top: "15%",
                        left: "3%",
                        right: "1%",
                      }}>
                      {t("vacancy:create.jobReq.hover")}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12}>
            <Typography
              style={{
                fontSize: "14px",
                color: palette.primary,
              }}>
              {t("vacancy:create.jobReq.desc")}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: 56 }}>
            <div>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.minEdu.label")}
              </InputLabel>
              <Select
                labelId="vacancy"
                style={{ color: palette.primary, fontWeight: "bold" }}
                className="Field select-with-placeholder"
                value={
                  vacancy.minEducation === null ? " " : vacancy.minEducation
                }
                onChange={v =>
                  this.handleVacancy({ minEducation: v.target.value })
                }>
                <MenuItem style={{ color: palette.primary }} value=" " disabled>
                  <span>{t("vacancy:create.jobReq.minEdu.placeholder")}</span>
                </MenuItem>
                {t("vacancy:create.jobReq.minEdu.options", {
                  returnObjects: true,
                }).map((v, i) => {
                  return (
                    <MenuItem
                      key={i}
                      style={{ color: palette.primary }}
                      value={v}>
                      {v}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
            <div style={{ width: "50vw" }}>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.univPref.label")}
              </InputLabel>
              <ReactSelect
                classNamePrefix={"university"}
                isOptionDisabled={option =>
                  findObject(univPrefs, "value", "noPref")
                    ? option.value !== "noPref"
                    : !findObject(univPrefs, "value", "noPref") &&
                      univPrefs.length > 0
                    ? option.value === "noPref"
                    : option.value === null
                }
                openMenuOnFocus={true}
                placeholder={t("vacancy:create.jobReq.univPref.placeholder")}
                isMulti
                isClearable
                onChange={obj => {
                  if (obj === null) {
                    this.setState({ univPrefs: [] });
                    this.setState({ vacancyUniversitys: [] });
                    data.setVacancyPost({ vacancyUniversitys: [] });
                  } else if (obj[0].value === "noPref") {
                    this.setState({ univPrefs: obj });
                    this.setState({ vacancyUniversitys: [] });
                    data.setVacancyPost({ vacancyUniversitys: [] });
                  } else {
                    this.setState({ univPrefs: obj });
                    var value = obj.map(v => {
                      return v.value;
                    });
                    this.setState({ vacancyUniversitys: value });
                    data.setVacancyPost({ vacancyUniversitys: value });
                  }
                }}
                options={listUnivPref}
                styles={creatableSelectStyles}
                value={univPrefs}
              />
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
            <div style={{ width: "50vw" }}>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.majorPref.label")}
              </InputLabel>
              <ReactSelect
                classNamePrefix={"major"}
                isOptionDisabled={option =>
                  findObject(majorPrefs, "value", "noPref")
                    ? option.value !== "noPref"
                    : !findObject(majorPrefs, "value", "noPref") &&
                      majorPrefs.length > 0
                    ? option.value === "noPref"
                    : option.value === null
                }
                openMenuOnFocus={true}
                placeholder={t("vacancy:create.jobReq.majorPref.placeholder")}
                isMulti
                isClearable
                onChange={obj => {
                  if (obj === null) {
                    this.setState({ majorPrefs: [] });
                    this.setState({ vacancyMajorAliases: [] });
                    data.setVacancyPost({ vacancyMajorAliases: [] });
                  } else if (obj[0].value === "noPref") {
                    this.setState({ majorPrefs: obj });
                    this.setState({ vacancyMajorAliases: [] });
                    data.setVacancyPost({ vacancyMajorAliases: [] });
                  } else {
                    this.setState({ majorPrefs: obj });
                    var value = obj.map(v => {
                      return v.value;
                    });
                    this.setState({ vacancyMajorAliases: value });
                    data.setVacancyPost({ vacancyMajorAliases: value });
                  }
                }}
                options={listMajorPref}
                styles={creatableSelectStyles}
                value={majorPrefs}
              />
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
            <TextField
              type="number"
              style={{ fontSize: 24 }}
              autoFocus={true}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={t("vacancy:create.jobReq.minGpa.placeholder")}
              label={t("vacancy:create.jobReq.minGpa.label")}
              className="Field"
              onChange={v => {
                if (v.target.value === "" || v.target.value === null) {
                  this.handleVacancy({ minGpa: 0 });
                } else {
                  this.handleVacancy({ minGpa: parseFloat(v.target.value) });
                }
              }}
              value={vacancy.minGpa === null ? null : vacancy.minGpa.toFixed(2)}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
            <div>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.languageSkill.label")}
              </InputLabel>
              <Select
                labelId="vacancy"
                style={{ color: palette.primary, fontWeight: "bold" }}
                className="Field select-with-placeholder"
                value={
                  vacancyRequirement.languageSkill
                    ? vacancyRequirement.languageSkill
                    : " "
                }
                onChange={v =>
                  this.handleRequirement({ languageSkill: v.target.value })
                }>
                <MenuItem style={{ color: palette.primary }} value=" " disabled>
                  <span>
                    {t("vacancy:create.jobReq.languageSkill.placeholder")}
                  </span>
                </MenuItem>
                {listVacancy.languageSkills
                  ? listVacancy.languageSkills.map((v, i) => {
                      return (
                        <MenuItem
                          key={i}
                          style={{ color: palette.primary }}
                          value={v.id}>
                          {getItem("lang") === "en"
                            ? v.languageSkill
                            : v.languageSkillIna}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
            <div>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.specificCertification.label")}
              </InputLabel>
              <Select
                labelId="vacancy"
                style={{ color: palette.primary, fontWeight: "bold" }}
                className="Field select-with-placeholder"
                value={
                  vacancyRequirement.hasCertificate
                    ? "Required"
                    : vacancyRequirement.hasCertificate === null
                    ? " "
                    : "Not Required"
                }
                onChange={v => {
                  if (v.target.value === "Required") {
                    this.handleRequirement({ hasCertificate: true });
                  } else {
                    this.handleRequirement({ hasCertificate: false });
                  }
                }}>
                <MenuItem style={{ color: palette.primary }} value=" " disabled>
                  <span>
                    {t(
                      "vacancy:create.jobReq.specificCertification.placeholder"
                    )}
                  </span>
                </MenuItem>
                <MenuItem style={{ color: palette.primary }} value="Required">
                  {
                    t("vacancy:create.jobReq.specificCertification.options", {
                      returnObjects: true,
                    })[0]
                  }
                </MenuItem>
                <MenuItem
                  style={{ color: palette.primary }}
                  value="Not Required">
                  {
                    t("vacancy:create.jobReq.specificCertification.options", {
                      returnObjects: true,
                    })[1]
                  }
                </MenuItem>
              </Select>
            </div>
          </Grid>

          <div
            style={{ width: "100%", marginTop: 40 }}
            hidden={vacancyRequirement.hasCertificate ? false : true}>
            <MultipleTextField
              style={{ width: "97%" }}
              fieldData={vacancyRequirementCertificate}
              label={t("vacancy:create.jobReq.requiredCertificate.label")}
              placeholder={t(
                "vacancy:create.jobReq.requiredCertificate.placeholder"
              )}
              onChange={v => {
                this.setState({ vacancyRequirementCertificate: v });
                data.setVacancyPost({ vacancyRequirementCertificate: v });
              }}
            />
          </div>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
            <div style={{ width: "50vw" }}>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.perform.label")}
              </InputLabel>
              <ReactSelect
                className={classMenuPerform}
                classNamePrefix={"perform"}
                ref={ref => {
                  this.creatableRefPerform = ref;
                }}
                openMenuOnFocus={true}
                placeholder={t("vacancy:create.jobReq.perform.placeholder")}
                isMulti
                isClearable
                onChange={obj => {
                  if (obj === null) {
                    this.setState({ performs: [] });
                    this.setState({ vacancyRequirementJobPerform: [] });
                    data.setVacancyPost({ vacancyRequirementJobPerform: [] });

                    this.setState({ otherPerforms: [] });
                    this.setState({ otherJobPerform: [] });
                    data.setVacancyPost({ otherJobPerform: [] });
                  } else {
                    this.setState({ performs: obj });
                    var value = obj.map(v => {
                      return v.value;
                    });
                    this.setState({ vacancyRequirementJobPerform: value });
                    data.setVacancyPost({
                      vacancyRequirementJobPerform: value,
                    });
                  }
                }}
                options={listPerform}
                styles={creatableSelectStyles}
                value={performs}
              />
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            style={{ marginTop: 40 }}
            hidden={
              this.state.vacancyRequirementJobPerform.some(
                p => p === otherPerformID
              )
                ? false
                : true
            }>
            <div style={{ width: "50vw" }}>
              <CreatableSelect
                classNamePrefix={"work-env"}
                placeholder={t(
                  "vacancy:create.vacancyDetail.jobFamily.otherPlaceholder"
                )}
                isMulti
                onChange={obj => {
                  if (obj === null) {
                    this.setState({ otherPerforms: [] });
                    this.setState({ otherJobPerform: [] });
                    data.setVacancyPost({ otherJobPerform: [] });
                  } else {
                    this.setState({ otherPerforms: obj });
                    var value = obj.map(v => {
                      return v.value;
                    });
                    this.setState({ otherJobPerform: value });
                    data.setVacancyPost({ otherJobPerform: value });
                  }
                }}
                styles={creatableSelectStyles}
                value={otherPerforms}
              />
            </div>
          </Grid>

          <Grid item lg={11} md={11} sm={11} style={{ marginTop: 40 }}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("vacancy:create.jobReq.perform.suggestion")}
            </Typography>
            {listPerform.map((v, i) => {
              return findObject(performs, "value", v.value) ? null : (
                <Button
                  key={i}
                  className="suggestion-button"
                  onClick={() => {
                    this.handleSuggestion(v, "perform");
                  }}>
                  <div className="suggestion-label">{v.label}</div>
                  <div className="suggestion-image">
                    <img src={IconSuggestion} height={"10vh"} alt={v.label} />
                  </div>
                </Button>
              );
            })}
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
            <div style={{ width: "50vw" }}>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.workEnv.label")}
              </InputLabel>
              <ReactSelect
                className={classMenu}
                classNamePrefix={"work-env"}
                ref={ref => {
                  this.creatableRef = ref;
                }}
                openMenuOnFocus={true}
                placeholder={t("vacancy:create.jobReq.workEnv.placeholder")}
                isMulti
                isClearable
                onChange={obj => {
                  if (obj === null) {
                    this.setState({ workEnvs: [] });
                    this.setState({ vacancyRequirementEnvironmentWork: [] });
                    data.setVacancyPost({
                      vacancyRequirementEnvironmentWork: [],
                    });

                    this.setState({ otherWorkEnvs: [] });
                    this.setState({ otherEnvironments: [] });
                    data.setVacancyPost({ otherEnvironments: [] });
                  } else {
                    this.setState({ workEnvs: obj });
                    var value = obj.map(v => {
                      return v.value;
                    });
                    this.setState({ vacancyRequirementEnvironmentWork: value });
                    data.setVacancyPost({
                      vacancyRequirementEnvironmentWork: value,
                    });
                  }
                }}
                options={listWorkEnv}
                styles={creatableSelectStyles}
                value={workEnvs}
              />
            </div>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            style={{ marginTop: 40 }}
            hidden={
              this.state.vacancyRequirementEnvironmentWork.some(
                p => p === otherWorkEnvID
              )
                ? false
                : true
            }>
            <div style={{ width: "50vw" }}>
              <CreatableSelect
                classNamePrefix={"work-env"}
                placeholder={t(
                  "vacancy:create.vacancyDetail.jobFamily.otherPlaceholder"
                )}
                isMulti
                onChange={obj => {
                  if (obj === null) {
                    this.setState({ otherWorkEnvs: [] });
                    this.setState({ otherEnvironments: [] });
                    data.setVacancyPost({ otherEnvironments: [] });
                  } else {
                    this.setState({ otherWorkEnvs: obj });
                    var value = obj.map(v => {
                      return v.value;
                    });
                    this.setState({ otherEnvironments: value });
                    data.setVacancyPost({ otherEnvironments: value });
                  }
                }}
                styles={creatableSelectStyles}
                value={otherWorkEnvs}
              />
            </div>
          </Grid>

          <Grid item lg={11} md={11} sm={11} style={{ marginTop: 40 }}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("vacancy:create.jobReq.workEnv.suggestion")}
            </Typography>
            {listWorkEnv.map((v, i) => {
              return findObject(workEnvs, "value", v.value) ? null : (
                <Button
                  key={i}
                  className="suggestion-button"
                  onClick={() => {
                    this.handleSuggestion(v, "workEnv");
                  }}>
                  <div className="suggestion-label">{v.label}</div>
                  <div className="suggestion-image">
                    <img src={IconSuggestion} height={"10vh"} alt={v.label} />
                  </div>
                </Button>
              );
            })}
          </Grid>
        </Grid>
        <RecruiterFooter
          style={{ width: "100%" }}
          history={this.props.history}
          nextTo="/recruiter/soft-skill"
          backTo="/recruiter/vacancy-detail"
          backLabel={t("vacancy:create.footer.back")}
          nextLabel={t("vacancy:create.footer.next")}
          action={true}
          onFinish={() => this.saveData()}
        />
      </div>
    );
  }
}

export default withTranslation()(JobRequirement);
