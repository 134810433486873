import React from "react";
import { Route, Switch, Router } from "react-router-dom";
import "./App.css";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Main } from "app/layouts";
import { routes, user, home, applicant, recruiter } from "../../routes";
import { NotFound, ErrorException, NotSupport } from "app/pages";
import { Grid, Typography } from "@material-ui/core";
import {
  loadImage,
  General,
  getItem,
  AppHistory,
  setItem,
  RequestGet,
} from "app/utils";
import { withTranslation } from "react-i18next";
import { GifLoading } from "assets/img";
import { observer } from "mobx-react";
import HiregradeAlert from "app/components/alert/HiregradeAlert";
import alertData from "app/components/alert/alert-data";
import { GeneratingGame } from "./GeneratingGame";
// import { logInfo } from "app/utils/general/rollbar";
import { isMobile } from "react-device-detect";

const options = {
  position: "top center",
  timeout: 5000,
  offset: "30px",
  transition: "scale",
};

const Loader = observer(() => {
  return (
    <div
      style={{
        top: 0,
        width: "100vw",
        height: "100vh",
        display: General.loading ? "flex" : "none",
        position: "fixed",
        zIndex: 9000,
        backgroundColor: "rgba(0,0,0,0.3)",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}>
      <img
        src={GifLoading}
        width={100}
        alt="Loading"
        style={{ borderRadius: "50%" }}
      />
    </div>
  );
});

const Desktop = () => {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Loader />
      <GeneratingGame />
      <Router history={AppHistory}>
        <Switch>
          {home.map((value, i) => {
            return (
              <Route
                key={i}
                exact
                path={value.path}
                component={value.component}
              />
            );
          })}
          {user.map((value, i) => {
            return (
              <Route
                key={i}
                exact
                path={value.path}
                component={value.component}
              />
            );
          })}

          {getItem("GR") === "Recruiter" &&
            recruiter.map((value, i) => {
              return (
                <Route
                  key={i}
                  exact
                  path={value.path}
                  component={value.component}
                />
              );
            })}

          {getItem("GR") === "Applicant" &&
            applicant.map((value, i) => {
              return (
                <Route
                  key={i}
                  exact
                  path={value.path}
                  component={value.component}
                />
              );
            })}
          {routes.map((value, i) => {
            return <Route key={i} exact path={value.path} component={Main} />;
          })}
          <Route exact path="/not-supported" component={NotSupport} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </AlertProvider>
  );
};

const Mobile = props => {
  return (
    <Grid
      direction="column"
      container
      justify="center"
      alignItems="center"
      style={{ height: "100vh" }}>
      <img src={loadImage("HiregradeLogo.png")} alt="Logo" width={150} />
      <Typography style={{ textAlign: "center" }}>
        {props.t("mobileVersion")}
      </Typography>
    </Grid>
  );
};

class App extends React.Component {
  state = {
    hide: false,
  };
  constructor(props) {
    super(props);
    window.onresize = () => {
      this.reportWindowSize();
    };
    const { t } = props;
    window.addEventListener("online", () => {
      alertData.setOpen(true);
      alertData.setSeverity("success");
      alertData.setMessage(t("error.network.connected"));
    });
    window.addEventListener("offline", () => {
      alertData.show(
        t("error.network.disconnected") + ", " + t("error.network.reconnect"),
        "error"
      );
      if (getItem("TEST_ATTEMPT") === "true") {
        alertData.setReconnecting(true);
      }
    });
    if (getItem("GR") === "Applicant") {
      if (
        performance.navigation.type === 0 &&
        Boolean(getItem("FL") === "true")
      ) {
        if (process.env.NODE_ENV === "production") {
          if (AppHistory.location.pathname.indexOf("game") < 0) {
            // logInfo("Force logout", { user: getItem("user_id") });
            AppHistory.push("/user/logout");
          }
        }
      } else {
        setItem("FL", true);
      }
    }
    if (getItem("GR") === "Recruiter" && getItem("access_token")) {
      this.getBiodataRecruiterStatus();
      if (
        performance.navigation.type === 0 &&
        Boolean(getItem("FL") === "true")
      ) {
        AppHistory.push("/user/logout");
        setItem("FL", true);
      }
    }
  }

  getBiodataRecruiterStatus() {
    this.params = new URLSearchParams(AppHistory.location.search);
    const pn = AppHistory.location.pathname;
    RequestGet("BiodataRecruiter/status")
      .then(r => {
        const biodataRecruiterStatus = r.data.biodataStatus;
        if (biodataRecruiterStatus === "PI") {
          AppHistory.push("/recruiter/profile-setup");
        } else if (biodataRecruiterStatus === "CP") {
          AppHistory.push("/recruiter/profile-company");
        } else if (biodataRecruiterStatus === "AI") {
          AppHistory.push("/recruiter/profile-additional");
        } else if (biodataRecruiterStatus === "CFM") {
          AppHistory.push("/recruiter/profile-confirmation");
        } else {
          if (pn.indexOf("/user/login/recruiter") === 0) {
            RequestGet("CompanyProfile?userId=" + getItem("user_id"))
              .then(bio => {
                setItem(
                  "company_info",
                  JSON.stringify(bio.data.companyProfile)
                );
                var redir = this.params.get("redir")
                  ? this.params.get("redir")
                  : "/recruiter/home";
                AppHistory.push(redir);
              })
              .catch(err => {
                console.log("Check Biodata Rekruter", err);
              });
          }
        }
      })
      .catch(e => {
        console.log("getBiodataRecruiterStatus()", e);
      });
  }

  reportWindowSize() {
    const pn = AppHistory.location.pathname;
    if (!isMobile) {
      if (pn.indexOf("basic-test") < 0 && pn.indexOf("game") < 0) {
        this.setState({ hide: window.innerWidth < 1100 ? true : false });
      }
    }
  }

  render() {
    const { t } = this.props;
    if (false) {
      return <Mobile t={t} />;
    } else {
      return (
        <ErrorException>
          <HiregradeAlert history={AppHistory} onClose={() => {}} />
          <div style={{ display: this.state.hide ? "none" : "block" }}>
            <Desktop t={t} />
          </div>
          <div style={{ display: !this.state.hide ? "none" : "flex" }}>
            <Grid
              direction="column"
              container
              justify="center"
              alignItems="center"
              style={{ height: "100vh" }}>
              <img
                src={loadImage("HiregradeLogo.png")}
                alt="Logo"
                width={150}
              />
              <Typography>{t("unsupported")}</Typography>
            </Grid>
          </div>
        </ErrorException>
      );
    }
  }
}

export default withTranslation()(App);
