import React from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  StepConnector,
  withStyles,
} from "@material-ui/core";
import { palette } from "assets/css/main";
import { Check } from "@material-ui/icons";
import "./custom.css";
import clsx from "clsx";
import InterestTest from "./interest/interest";

import bdc from "./data";
import { InitializeFooter } from "./footer";
import MyHelmet from "app/components/header/MyHelmet";
import { Header } from "app/components";
import EnergyTest from "./energy";
import { getItem, RequestGet, setItem } from "app/utils";
import CognitivePage from "./cognitive";
import TestBreak from "./test-break";

// const steps = ["Interest Test", "Cognitive Test", "Energi Test"];

const useCustomIconStyles = makeStyles({
  root: {
    backgroundColor: "rgba(82, 200, 244, 0.2)",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    fontWeight: "bold",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: palette.secondary,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: palette.secondary,
  },
});

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: palette.secondary,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "rgba(82, 200, 244, 0.2)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#ccc",
    borderRadius: 1,
  },
})(StepConnector);

function CustomIcon(props) {
  const classes = useCustomIconStyles();
  const { active, completed } = props;

  const icons = {
    1: "a",
    2: "b",
    3: "c",
    4: "d",
    5: "e",
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {completed ? <Check /> : icons[String(props.icon)]}
    </div>
  );
}

class BasicTestPage extends React.Component {
  state = {
    ...bdc.stepper,
    steps: [],
    stepDisplay: [],
    examID: [],
    contents: <div></div>,
    intro: true,
  };
  c = 1;
  recdata = [];
  mtitle = null;
  constructor(props) {
    super(props);
    const { t } = this.props;
    const steps = t("basictest:steps", { returnObjects: true });
    bdc.stepper.length = steps.length;
    var onGame = this.props.history.location.pathname.indexOf("game");
    if (
      window.performance.navigation.type === 0 &&
      this.props.match.params.gameName &&
      getItem("TEST_ATTEMPT") === "true"
    ) {
      if (onGame >= 0) {
        if (
          this.props.history.location.pathname.indexOf(
            this.props.match.params.gameName
          ) > 0 &&
          getItem(this.props.match.params.gameName + "_attempt") === "true"
        ) {
          this.props.history.push("/user/logout");
        } else {
          setItem(this.props.match.params.gameName + "_attempt", true);
        }
      } else {
        this.props.history.push("/user/logout");
      }
    } else {
      setItem("TEST_ATTEMPT", true);
    }
  }
  getListExam() {
    const { t } = this.props;
    const steps = t("basictest:steps", { returnObjects: true });
    RequestGet("exam")
      .then(res => {
        var st = [];
        var id = [];
        res.data.map(v => {
          id.push(v.id);
          return st.push(v.name);
        });
        return { steps: st, examID: id };
      })
      .then(result => {
        this.setState({
          steps: result.steps,
          stepDisplay: steps,
          examID: result.examID,
        });
        this.getExamAttempt();
      })
      .catch(e => {
        console.log(e);
      });
  }

  pressNext(type) {
    if (type === "cog") {
      bdc.stepper.activeStep = bdc.stepper.activeStep + 1;
      setItem("cts", true);
      this.setState({
        activeStep: bdc.stepper.activeStep,
        contents: (
          <CognitivePage
            history={this.props.history}
            data={this.recdata}
            title={this.mtitle}
          />
        ),
      });
    } else if (type === "ets") {
      if (getItem("energy_")) {
        this.setState({
          activeStep: bdc.stepper.activeStep,
          contents: (
            <EnergyTest
              history={this.props.history}
              match={this.props.match}
              data={this.recdata}
              title={this.mtitle}
            />
          ),
        });
      } else {
        bdc.stepper.activeStep = bdc.stepper.activeStep + 1;
        setItem("energy_", this.recdata.sortorder);
        this.setState({
          activeStep: bdc.stepper.activeStep,
          contents: (
            <EnergyTest
              history={this.props.history}
              match={this.props.match}
              data={this.recdata}
              title={this.mtitle}
            />
          ),
        });
      }
    }
  }
  getExamAttempt() {
    const { t } = this.props;
    const steps = t("basictest:steps", { returnObjects: true });
    RequestGet("examattempts", {
      userId: getItem("user_id"),
    })
      .then(r => {
        if (r.data.examCompleted) {
          setItem("TEST_ATTEMPT", false);
          this.props.history.push("/basic-test/finish");
        } else {
          RequestGet("examcategories/" + r.data.examCategoryid)
            .then(rec => {
              bdc.stepper.activeStep = this.state.examID.indexOf(
                rec.data.examId
              );
              var content = <div></div>;
              var title = steps[bdc.stepper.activeStep];
              if (
                this.state.steps[bdc.stepper.activeStep] === "Interest Test"
              ) {
                if (this.props.history.location.pathname.indexOf("game") > 0) {
                  this.props.history.push("/basic-test");
                }
                content = (
                  <InterestTest
                    intro={bdc.interestIntro}
                    history={this.props.history}
                    data={rec.data}
                    title={title}
                  />
                );
              } else if (
                this.state.steps[bdc.stepper.activeStep] === "Cognitive Test"
              ) {
                if (this.props.history.location.pathname.indexOf("game") > 0) {
                  this.props.history.push("/basic-test");
                }
                this.recdata = rec.data;
                this.mtitle = title;
                if (getItem("cts")) {
                  content = (
                    <CognitivePage
                      history={this.props.history}
                      data={this.recdata}
                    />
                  );
                } else {
                  if (
                    r.data.examCategoryid ===
                    "def0c1ce-062b-47c8-a1cd-04c528bd26d3"
                  ) {
                    bdc.stepper.activeStep = bdc.stepper.activeStep - 1;
                    content = (
                      <TestBreak
                        history={this.props.history}
                        breakOn="interest"
                        onNext={() => {
                          this.pressNext("cog");
                        }}
                      />
                    );
                  } else {
                    content = (
                      <CognitivePage
                        history={this.props.history}
                        data={this.recdata}
                      />
                    );
                  }
                }
              } else if (
                this.state.steps[bdc.stepper.activeStep] === "Energy Test"
              ) {
                this.recdata = rec.data;
                this.mtitle = title;
                if (
                  this.props.history.location.pathname.indexOf(
                    rec.data.infoEn
                  ) < 0
                ) {
                  this.props.history.push(
                    "/game/" +
                      rec.data.infoEn +
                      "?lang=" +
                      getItem("lang").toUpperCase() +
                      "&&id=" +
                      getItem("user_id")
                  );
                }
                if (getItem("energy_")) {
                  setItem("energy_", this.recdata.sortorder);
                  var energy_ = getItem("energy_");
                  if (energy_ === "1") {
                    content = (
                      <EnergyTest
                        match={this.props.match}
                        history={this.props.history}
                        data={this.recdata}
                        title={this.mtitle}
                      />
                    );
                  } else if (energy_ === "2") {
                    content = (
                      <TestBreak
                        history={this.props.history}
                        number={this.recdata.sortorder}
                        breakOn={"energy"}
                        onNext={() => {
                          this.pressNext("ets");
                          setItem("energy_", 1);
                        }}
                      />
                    );
                  } else if (energy_ === "3") {
                    content = (
                      <TestBreak
                        history={this.props.history}
                        number={this.recdata.sortorder}
                        breakOn={"energy"}
                        onNext={() => {
                          this.pressNext("ets");
                        }}
                      />
                    );
                  }
                } else {
                  if (this.recdata.sortorder === 1) {
                    bdc.stepper.activeStep = bdc.stepper.activeStep - 1;
                    content = (
                      <TestBreak
                        history={this.props.history}
                        breakOn="cognitive"
                        onNext={() => {
                          this.pressNext("ets");
                        }}
                      />
                    );
                  } else {
                    content = (
                      <TestBreak
                        history={this.props.history}
                        number={this.recdata.sortorder}
                        breakOn={"energy"}
                        onNext={() => {
                          this.pressNext("ets");
                          setItem("energy_", 1);
                        }}
                      />
                    );
                  }
                }
              } else {
                content = "NA";
              }
              this.setState({
                activeStep: bdc.stepper.activeStep,
                contents: content,
              });
            })
            .catch(er => {
              console.log("getExamAttempt()->getCategories error", er);
            });
        }
      })
      .catch(e => {
        console.log("getExamAttempt() error", e);
      });
  }

  componentDidMount() {
    this.getListExam();
    try {
      this.setState({
        ...this.state,
        userInfo: JSON.parse(getItem("user_info")),
      });
    } catch (error) {
      this.props.history.push("/user/login/applicant");
    }
    if (process.env.NODE_ENV === "production") {
      window.onbeforeunload = function () {
        return "You have attempted to leave this page. Are you sure?";
      };
    }
  }
  UNSAFE_componentWillReceiveProps() {
    this.c = 1;
    this.setState({
      ...bdc.stepper,
      steps: [],
      examID: [],
      contents: <div></div>,
    });
    this.getListExam();
  }
  componentWillUnmount() {
    window.onbeforeunload = function () {};
  }
  update() {
    if (this.c === 1) {
      this.setState({ activeStep: bdc.stepper.activeStep });
    }
    this.c += 1;
  }
  handleNext() {
    this.getListExam();
  }
  handleBack() {
    bdc.stepper.activeStep -= 1;
    this.setState({ activeStep: bdc.stepper.activeStep });
  }

  render() {
    return (
      <div>
        <MyHelmet title="Basic Test" />
        <Header shadow={true} linkgroup={false} stepper={true} activeStep={1} />

        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            backgroundColor: palette.info,
            minHeight: 30,
            marginTop: 75,
          }}>
          <Stepper
            connector={<CustomConnector />}
            activeStep={this.state.activeStep}
            style={{
              width: "40vw",
              backgroundColor: palette.info,
              height: 0,
              padding: "30px 20px",
            }}>
            {this.state.stepDisplay.map((label, idx) => {
              return (
                <Step
                  key={label}
                  completed={this.state.activeStep > idx ? true : false}>
                  <StepLabel className="NavBar" StepIconComponent={CustomIcon}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid container style={{ marginBottom: 100 }}>
          {this.state.contents}
        </Grid>
        <InitializeFooter
          text={"Save & Continue"}
          step={this.state.activeStep}
          nextButtonClick={() => {
            this.handleNext();
          }}
        />
      </div>
    );
  }
}
export default withTranslation()(BasicTestPage);
