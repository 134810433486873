import React from "react";
import { Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { ArrowBack } from "@material-ui/icons";
export default class ButtonPrev extends React.Component {
  render() {
    const { color, text, onClick, style, iconSize, fontSize } = this.props;
    return (
      <Button onClick={onClick} style={style}>
        {/* <img src={IconArrowBack} alt="back" height={10} /> */}
        <ArrowBack
          style={{ color: color ? color : palette.primary, ...iconSize }}
        />
        <span
          style={{
            fontSize: fontSize ? fontSize : 18,
            fontWeight: "bolder",
            color: color ? color : palette.primary,
            textTransform: "capitalize",
            marginLeft: 10,
            textDecoration: "underline",
          }}>
          {text}
        </span>
      </Button>
    );
  }
}
