import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette, REC_STYLE } from "assets/css/main";
import {
  ButtonFilterStatus,
  DialogueFull,
  DialogueInfo,
  HGPagination,
  SelectionFilter,
} from "app/components";
import "app/pages/recruiter/recruiter.css";
import { ArrowDropDown, ArrowDropUp, Search } from "@material-ui/icons";
import { Button, Grow, MenuItem, Select, InputBase } from "@material-ui/core";
import {
  AppHistory,
  findObject,
  getItem,
  removeObject,
  RequestGet,
  RequestPost,
  RequestPut,
} from "app/utils";
import DialogueConfirm from "app/components/modal/confirm";
import { CandidateListFooter } from "../candidatelist-footer";
import DialogueSendOfferingFile from "app/pages/recruiter/dashboard/sendofferingfile";
import UpdateInterviewResult from "../schedule/update-result";
import DialogueCandidateJoinDate from "app/pages/recruiter/dashboard/candidate-join-date";
import DialogueCandidateJobProximity from "app/pages/recruiter/dashboard/candidate-job-proximity";
import alertData from "app/components/alert/alert-data";
import moment from "moment";
import ConfirmationReschedule from "../schedule/confirmation-reschedule";
import TemporaryView from "../../candidates/download-info/temporary-view";
import {
  ControlledMenu,
  MenuItem as MenuItemRM,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "./table-view/table.css";
import {
  SelectedTableView,
  ListedTableView,
  WishlistTableView,
  InprocessTableView,
  ProposedTableView,
  HiredTableView,
  ArchivedTableView,
} from "./table-view";
import { IconFolder, IconConfirmationCheck } from "assets/img";

class RecruiterCandidateList extends React.Component {
  // menuMore = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      openDownload: false,
      filename: "",
      status: "LIS",
      advancedFilter: false,
      selectFilter: false,
      sortBy: "-",
      sortValue: "",
      sortDirection: "",
      vacancy: [],
      candidates: [],
      candidateCount: [],
      quota: 0,
      arrwish: [],
      checkedAll: false,
      arrcheck: [],
      ischecked: false,
      arruserIdcheck: [],
      countchecked: 0,
      anchorSelectFilter: null,
      openconfirmUnassign: false,
      openUnassignSuccessConfirm: false,
      openconfirmArchived: false,
      openArchivedSuccessConfirm: false,
      anchorCandidateView: null,
      opendialogSendOffering: false,
      openUpdate: false,
      interviewResult: null,
      openConfirm: false,
      confirmReschedule: false,
      openOfferingLetterSent: false,
      openCandidateJoinDate: false,
      listCandidatesJoinDate: [],
      openButtonMovetoDialog: false,
      keyword: "",
      gender: "",
      proxmin: "",
      proxmax: "",
      workmax: "",
      workmin: "",
      selected: false,
      proposed: false,
      inprocess: false,
      gpamin: "",
      gpamax: "",
      isInterviewEnd: false,
      openDialogProximity: false,
      openJobProximitynotDisplayed: false,
      isDiscussOffer: false,
      limit: 10,
      page: 1,
      total: 0,
      menuMore: null,
      loadingCompleted: false,
    };
  }

  componentDidMount() {
    var s = AppHistory.location.state
      ? AppHistory.location.state.status
      : "LIS";
    this.getCandidate(s, true);
  }

  getLatestStatus(candidateCount) {
    var latestStatus = null;
    candidateCount.map((v, i) => {
      if (v.count > 0 && i < candidateCount.length - 1) {
        latestStatus = v.status;
      }
      return null;
    });
    this.handleFilterStatus(latestStatus);
  }

  getCandidate(status = "LIS", isBegining) {
    const match = this.props.match;
    const pathid = match.params.id;
    const {
      page,
      limit,
      sortValue,
      sortDirection,
      gpamax,
      gpamin,
      proxmax,
      proxmin,
      keyword,
      gender,
      selected,
      inprocess,
      proposed,
    } = this.state;
    this.setState({
      loadingCompleted: false,
    });

    const sm = [];

    if (selected) {
      sm.push("SEL");
    }
    if (inprocess) {
      sm.push("INP");
    }
    if (proposed) {
      sm.push("PRO");
    }

    const statmarketplace = sm.join(",");
    RequestGet("Vacancy/Candidate/" + pathid, {
      status: status,
      loading: false,
      limit: limit,
      page: page,
      sort: sortValue,
      sortValue: sortDirection,
      statmarketplace,
      gender,
      gpamax,
      gpamin,
      proxmax,
      proxmin,
      search: keyword,
    })
      .then(res => {
        this.setState({
          status: status,
          vacancy: res.data,
          candidates: res.data.candidates,
          candidateCount: res.data.candidateCount,
          arrwish: res.data.candidates.map(v => {
            return v.status === "WIS" ? true : false;
          }),
          arrcheck: res.data.candidates.map(v => {
            return false;
          }),
          ischecked: false,
          countchecked: 0,
          quota: res.data.quota,
        });
      })
      .catch(er => {
        console.log(er);
      })
      .finally(() => {
        this.setSubHeaderHeight();

        this.setState({
          loadingCompleted: true,
        });

        // get latest status
        if (isBegining) {
          this.getLatestStatus(this.state.candidateCount);
        }
      });
  }

  resetFilter = () => {
    this.setState({
      gpamin: "",
      gpamax: "",
      proxmin: "",
      proxmax: "",
      workmax: "",
      workmin: "",
      sortBy: "-",
      sortValue: "",
      sortDirection: "",
      keyword: "",
      gender: "",
      selected: false,
      inprocess: false,
      proposed: false,
    });
  };

  handleFilterStatus(status) {
    AppHistory.replace(AppHistory.location.pathname, { status: status });
    this.resetFilter();
    this.getCandidate(status, false);
  }

  onClickWishlist(idx, userId) {
    var tmp = this.state.arrwish;
    tmp[idx] = !tmp[idx];

    var match = this.props.match;
    var pathid = match.params.id;

    var vacancyId = pathid;
    var status = "WIS";
    var listUser = [{ candidateId: userId }];

    RequestPut("vacancy/candidate", {
      vacancyId,
      status,
      listUser,
      loading: true,
    })
      .then(r => {
        this.setState({
          arrwish: tmp,
        });
        this.getCandidate(this.state.status, false);
      })
      .catch(e => {
        console.log(e);
      });
  }

  onClickDeletefromWishlist(userId) {
    var match = this.props.match;
    var pathid = match.params.id;

    var vacancyId = pathid;
    var status = "LIS";
    var listUser = [{ candidateId: userId }];

    RequestPut("vacancy/candidate", {
      vacancyId,
      status,
      listUser,
      loading: true,
    })
      .then(r => {
        this.getCandidate(this.state.status, false);
      })
      .catch(e => {
        console.log(e);
      });
  }

  onClickCheckedAll = e => {
    if (!this.state.checkedAll) {
      this.setState({
        checkedAll: !this.state.checkedAll,
        ischecked: true,
        arrcheck: this.state.candidates.map(() => {
          return true;
        }),
        countchecked: this.state.candidates.length,
        arruserIdcheck: this.state.candidates.map(v => {
          return { candidateId: v.userId };
        }),
      });
    } else {
      this.setState({
        checkedAll: false,
        arruserIdcheck: [],
        ischecked: false,
        arrcheck: this.state.candidates.map(() => {
          return false;
        }),
      });
    }
  };

  onClickChecked(idx, userId, status, force = null, listcandidates) {
    var tmp = this.state.arrcheck;
    tmp[idx] = force ? force : !tmp[idx];
    var countcheck = tmp.filter(count => count).length;
    var newlistuser = this.state.arruserIdcheck;
    var newlistcandidates = [];
    if (status === "PRO") {
      newlistcandidates = this.state.listCandidatesJoinDate;
    }

    if (tmp[idx]) {
      newlistuser.push({ candidateId: userId });
      if (status === "INP") {
        this.setState({
          arrcheck: tmp,
          ischecked: countcheck > 0 ? true : false,
          countchecked: countcheck,
          arruserIdcheck: newlistuser,
        });
      } else {
        if (status === "PRO") {
          newlistcandidates.push({ ...listcandidates, joindate: "" });
        }
        this.setState({
          arrcheck: tmp,
          ischecked: countcheck > 0 ? true : false,
          countchecked: countcheck,
          arruserIdcheck: newlistuser,
          listCandidatesJoinDate: newlistcandidates,
        });
      }
    } else {
      var objlistuser = removeObject(newlistuser, "candidateId", userId);
      newlistuser = objlistuser;

      if (status === "PRO") {
        var objlistcandidates = removeObject(
          newlistcandidates,
          "userId",
          userId
        );
        newlistcandidates = objlistcandidates;
      }

      this.setState({
        arrcheck: tmp,
        ischecked: countcheck > 0 ? true : false,
        countchecked: countcheck,
        arruserIdcheck: newlistuser,
        listCandidatesJoinDate: newlistcandidates,
      });
    }

    //select all auto checked when all data checked
    if (countcheck === tmp.length) {
      this.setState({
        checkedAll: true,
      });
    } else {
      this.setState({
        checkedAll: false,
      });
    }
  }

  onClickActionSelected(uId, stat, e) {
    if (
      stat === "SEL" ||
      stat === "INP" ||
      stat === "PRO" ||
      stat === "HIR" ||
      stat === "ARC"
    ) {
      this.setState({
        anchorAction: e,
        userId: uId,
        stat: stat,
      });
    }
  }

  movetoUnassign() {
    var match = this.props.match;
    var pathid = match.params.id;
    var vacancyId = pathid;
    var status = "UNA";
    var listUser = [];
    listUser.push({ candidateId: this.state.userId });

    RequestPut("vacancy/candidate", {
      vacancyId,
      status,
      listUser,
      loading: true,
    })
      .then(r => {
        this.setState({
          openUnassignSuccessConfirm: true,
          openconfirmUnassign: false,
        });
      })
      .catch(e => {
        alertData.show(e.message, "error");
      });
  }

  clikOkSuccesUnassignedPoll() {
    this.setState({
      openUnassignSuccessConfirm: false,
    });
    this.getCandidate(this.state.status, false);
  }

  movetoArchived() {
    var match = this.props.match;
    var pathid = match.params.id;
    var vacancyId = pathid;
    var status = "ARC";
    var listUser = [];
    listUser.push({ candidateId: this.state.userId });

    RequestPut("vacancy/candidate", {
      vacancyId,
      status,
      listUser,
      loading: true,
    })
      .then(r => {
        this.setState({
          openArchivedSuccessConfirm: true,
          openconfirmArchived: false,
        });
      })
      .catch(e => {
        console.log(e);
      });
  }
  setSubHeaderHeight() {
    this.setState({
      subHeaderHeight: document.getElementById("subheader").clientHeight,
    });
  }
  clikOkSuccesArchived() {
    this.setState({
      openArchivedSuccessConfirm: false,
    });
    this.getCandidate(this.state.status, false);
  }

  handleChangeInputSearch = event => {
    this.setState({ keyword: event.target.value });
  };

  handleClickSendOfferingFile = key => {
    this.setState({
      opendialogSendOffering: true,
      k: key,
      anchorAction: null,
    });
  };

  sendofferingfile(obj) {
    var form = new FormData();
    var VacancyId = this.state.vacancy.vacancyId;
    var CandidateId = this.state.userId;
    form.append("File", obj.fileoffering.file, obj.fileoffering.file.name);
    form.append("VacancyId", VacancyId);
    form.append("CandidateId", CandidateId);
    RequestPost("vacancy/offer", form)
      .then(r => {
        try {
          document.getElementById("preview-img").blur();
          document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
        } catch (error) {
          console.log(error);
        }
        this.setState({
          opendialogSendOffering: false,
          openOfferingLetterSent: true,
        });
        obj = null;
      })
      .catch(e => {
        console.log("sendofferingfile()", e);
      });
  }

  clikOkOfferingSent() {
    this.setState({
      openOfferingLetterSent: false,
    });
    this.getCandidate(this.state.status, false);
  }

  onClickButtonMovetoDialog(stat, listcandidates) {
    if (stat === "PRO") {
      this.setState({
        listCandidatesJoinDate: listcandidates,
        openCandidateJoinDate: true,
      });
    } else {
      this.setState({
        openButtonMovetoDialog: true,
      });
    }
  }

  submitmoveto(stat, listUser) {
    var match = this.props.match;
    var pathid = match.params.id;
    var vacancyId = pathid;
    var status = "";
    if (stat === "LIS" || stat === "WIS") {
      status = "SEL";
    } else if (stat === "SEL") {
      status = "INP";
    } else if (stat === "INP") {
      status = "PRO";
    }

    if (stat !== "PRO") {
      RequestPut("vacancy/candidate", {
        vacancyId,
        status,
        listUser,
        loading: true,
      })
        .then(r => {
          this.setState({
            ischecked: false,
            checkedAll: false,
            status: stat,
            arruserIdcheck: [],
            openButtonMovetoDialog: false,
          });
          getItem("lang") === "en"
            ? alertData.show("Data Success Moved")
            : alertData.show("Data berhasil Pindah");
          this.getCandidate(stat, false);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  clickApplyFilter() {
    this.setState({ anchorSelectFilter: null });
  }

  render() {
    const {
      status,
      advancedFilter,
      sortBy,
      vacancy,
      candidates,
      candidateCount,
      quota,
      arrwish,
      arrcheck,
      openConfirm,
      ischecked,
      countchecked,
      arruserIdcheck,
      anchorAction,
      anchorSelectFilter,
      openconfirmUnassign,
      openUnassignSuccessConfirm,
      openconfirmArchived,
      openArchivedSuccessConfirm,
      userId,
      opendialogSendOffering,
      openUpdate,
      interviewResult,
      confirmReschedule,
      interviewId,
      openOfferingLetterSent,
      openCandidateJoinDate,
      listCandidatesJoinDate,
      openButtonMovetoDialog,
      isInterviewEnd,
      openDialogProximity,
      openJobProximitynotDisplayed,
      isDiscussOffer,
      openDownload,
      filename,
      hiredStatus,
      menuMore,
      loadingCompleted,
      keyword,
      subHeaderHeight,
      checkedAll,
    } = this.state;
    const {
      t,
      match: { params },
    } = this.props;

    var str = vacancy.updatedTime;
    var date = moment(str).toISOString().split("T")[0];
    var updatedate = moment(date).format("DD MMM yyyy");
    var time = moment(str).toISOString().split("T")[1];

    var textButtonMove = "";
    if (status === "LIS" || status === "WIS") {
      textButtonMove = t("candidates:ButtonMoveto.SelectedList");
    } else if (status === "SEL") {
      textButtonMove = t("candidates:ButtonMoveto.InProcess");
    } else if (status === "INP") {
      textButtonMove = t("candidates:ButtonMoveto.Proposed");
    } else if (status === "PRO") {
      textButtonMove = t("candidates:ButtonMoveto.Hired");
    }
    const candidateCountPage = findObject(
      candidateCount,
      "status",
      status,
      false
    );
    var candidateshaveoffering = [];
    if (status === "PRO") {
      for (var i = 0; i < candidates.length; i += 1) {
        if (candidates[i].offeringFile !== null) {
          candidateshaveoffering.push(candidates[i]);
        }
      }
    }

    var isCanSendOfferingFile = false;
    if (vacancy.outstandingVacancy > candidateshaveoffering.length) {
      isCanSendOfferingFile = true;
    } else {
      if (isDiscussOffer) {
        isCanSendOfferingFile = true;
      }
    }

    return (
      <div className="recruiter">
        <MyHelmet title="View Candidate List" />
        <RecruiterAppBar menu="dashboard" shadow={true} />
        <DialogueFull
          hideClose={true}
          style={{ width: 200 }}
          open={openDownload}>
          <TemporaryView
            onDownload={() => this.setState({ openDownload: false })}
            id={userId}
            status={status}
            filename={filename}
          />
        </DialogueFull>
        <SelectionFilter
          anchorSelectFilter={anchorSelectFilter}
          onChange={v => {
            this.setState(v);
          }}
          ApplyFilter={() => this.clickApplyFilter()}
          state={this.state}
        />
        <ControlledMenu
          styles={{
            zIndex: 1,
            fontWeight: "lighter",
            marginTop: 40,
            color: palette.primary,
          }}
          anchorPoint={menuMore}
          isOpen={Boolean(anchorAction)}
          onClose={() => {
            this.setState({ anchorAction: null });
          }}>
          <SubMenu label={t("candidates:menu.view")}>
            <MenuItemRM
              styles={{ color: palette.primary }}
              onClick={() => {
                AppHistory.push(
                  "/recruiter/candidates/information/cv/" + userId,
                  { status: status, vacancy: true }
                );
              }}>
              {t("candidates:candidateInfo.lcv")}
            </MenuItemRM>
            <MenuItemRM
              styles={{ color: palette.primary }}
              onClick={() => {
                this.setState({
                  openDownload: true,
                  anchorCandidateView: null,
                  anchorAction: null,
                });
              }}>
              {t("candidates:candidateInfo.dc")}
            </MenuItemRM>
          </SubMenu>
          {status === "PRO" && !hiredStatus && isCanSendOfferingFile && (
            <MenuItemRM
              onClick={e => {
                this.setState({
                  opendialogSendOffering: true,
                  anchorAction: null,
                });
              }}
              style={{
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("candidates:menu.sendofferingfile")}
            </MenuItemRM>
          )}
          {status === "INP" &&
            !hiredStatus &&
            isInterviewEnd &&
            !interviewResult && (
              <MenuItemRM
                onClick={e => {
                  this.setState({
                    anchorAction: null,
                    openUpdate: true,
                  });
                }}
                style={{
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("candidates:menu.updateResult")}
              </MenuItemRM>
            )}
          {status === "INP" && !hiredStatus && confirmReschedule && (
            <MenuItemRM
              onClick={e => {
                this.setState({
                  anchorAction: null,
                  openConfirm: true,
                });
              }}
              style={{
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("candidates:menu.confirmReschedule")}
            </MenuItemRM>
          )}
          {status !== "HIR" && !hiredStatus ? (
            <MenuItemRM
              onClick={e => {
                this.setState({
                  openconfirmUnassign: true,
                  anchorAction: null,
                });
              }}
              style={{
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("candidates:menu.moveUnasign")}
            </MenuItemRM>
          ) : null}
          {status !== "ARC" && status !== "HIR" && !hiredStatus ? (
            <MenuItemRM
              onClick={e => {
                this.setState({
                  openconfirmArchived: true,
                  anchorAction: null,
                });
              }}
              style={{
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("candidates:menu.movetoArchive")}
            </MenuItemRM>
          ) : null}
        </ControlledMenu>

        {/* Send Offering File Dialog */}
        <DialogueSendOfferingFile
          open={opendialogSendOffering}
          title={t("candidates:SendOfferingFileDialog.title")}
          content={t("candidates:SendOfferingFileDialog.message")}
          btnYes={t("candidates:SendOfferingFileDialog.btnOk")}
          btnNo={t("candidates:SendOfferingFileDialog.btnCancel")}
          onOK={res => {
            if (res) {
              this.sendofferingfile(res);
            }
          }}
          onClose={() => {
            this.setState({ opendialogSendOffering: false });
          }}
        />

        <UpdateInterviewResult
          candidateId={userId}
          vacancyId={params.id}
          open={openUpdate}
          onClose={reload => {
            if (reload) {
              this.getCandidate(status, false);
            }
            this.setState({ openUpdate: false });
          }}
        />

        <ConfirmationReschedule
          candidateId={userId}
          interviewId={interviewId}
          open={openConfirm}
          onClose={reload => {
            if (reload) {
              this.getCandidate(status, false);
            }
            this.setState({ openConfirm: false });
          }}
        />

        {/* Offering Letter sent Dialog */}
        <DialogueInfo
          title={t("candidates:OfferingLetterSent.title")}
          content={t("candidates:OfferingLetterSent.message")}
          open={openOfferingLetterSent}
          onClose={() => {
            this.clikOkOfferingSent();
          }}
        />

        {/* Candidate Join Date Dialog */}
        <DialogueCandidateJoinDate
          vacancyId={params.id}
          listcandidate={listCandidatesJoinDate}
          open={openCandidateJoinDate}
          onClose={reload => {
            if (reload) {
              this.getCandidate(status, false);
            }
            this.setState({ openCandidateJoinDate: false });
          }}
        />

        {/* Button Move to Dialog */}
        <DialogueConfirm
          open={openButtonMovetoDialog}
          title={t("candidates:ButtonMovetoDialog.title")}
          content={
            status === "LIS" || status === "WIS"
              ? t("candidates:ButtonMovetoDialog.messageSelected")
              : status === "SEL"
              ? t("candidates:ButtonMovetoDialog.messageInProcess")
              : t("candidates:ButtonMovetoDialog.messageProposed")
          }
          contentDetail={
            status === "LIS" || status === "WIS"
              ? t("candidates:ButtonMovetoDialog.messageSelectedDetail")
              : status === "SEL"
              ? t("candidates:ButtonMovetoDialog.messageInProcessDetail")
              : t("candidates:ButtonMovetoDialog.messageProposedDetail")
          }
          contentWidth={400}
          btnYes={t("candidates:ButtonMovetoDialog.btnOk")}
          btnNo={t("candidates:ButtonMovetoDialog.btnCancel")}
          onOK={() => {
            this.submitmoveto(status, arruserIdcheck);
          }}
          onClose={() => {
            this.setState({ openButtonMovetoDialog: false });
          }}
        />

        {/* Unassigned Pool Dialog */}
        <DialogueConfirm
          open={openconfirmUnassign}
          title={t("candidates:MovetoUnassignconfirmation.title")}
          content={t("candidates:MovetoUnassignconfirmation.message")}
          contentDetail={t(
            "candidates:MovetoUnassignconfirmation.messageDetail"
          )}
          contentWidth={400}
          btnYes={t("candidates:MovetoUnassignconfirmation.btnOk")}
          btnNo={t("candidates:MovetoUnassignconfirmation.btnCancel")}
          onOK={() => this.movetoUnassign(userId)}
          onClose={() => {
            this.setState({ openconfirmUnassign: false });
          }}
        />
        <DialogueInfo
          title={t("candidates:UnassignSuccessconfirmation.title")}
          content={t("candidates:UnassignSuccessconfirmation.message")}
          open={openUnassignSuccessConfirm}
          onClose={() => {
            this.clikOkSuccesUnassignedPoll();
          }}
        />

        {/* Archive Dialog */}
        <DialogueConfirm
          open={openconfirmArchived}
          title={t("candidates:MovetoArchivedfirmation.title")}
          content={t("candidates:MovetoArchivedfirmation.message")}
          contentDetail={t("candidates:MovetoArchivedfirmation.messageDetail")}
          contentWidth={409}
          btnYes={t("candidates:MovetoArchivedfirmation.btnOk")}
          btnNo={t("candidates:MovetoArchivedfirmation.btnCancel")}
          onOK={() => this.movetoArchived(userId)}
          onClose={() => {
            this.setState({ openconfirmArchived: false });
          }}
        />
        <DialogueInfo
          title={t("candidates:ArchivedSuccessconfirmation.title")}
          content={t("candidates:ArchivedSuccessconfirmation.message")}
          open={openArchivedSuccessConfirm}
          onClose={() => {
            this.clikOkSuccesArchived();
          }}
        />

        {/* Candidate Proximity Dialog */}
        <DialogueCandidateJobProximity
          candidateId={userId}
          vacancyId={params.id}
          open={openDialogProximity}
          onClose={reload => {
            if (reload) {
              //this.getCandidate(status);
            }
            this.setState({ openDialogProximity: false });
          }}
        />

        <DialogueInfo
          title={t("candidates:JobProximitynotDisplayed.title")}
          content={t("candidates:JobProximitynotDisplayed.message")}
          open={openJobProximitynotDisplayed}
          onClose={() => {
            this.setState({ openJobProximitynotDisplayed: false });
          }}
        />

        <div style={{ ...REC_STYLE.BODY_STYLE, color: palette.primary }}>
          {/* sub header start */}
          <div
            on
            id="subheader"
            style={{
              ...REC_STYLE.SUB_HEADER,
              alignItems: "flex-start",
              paddingTop: 30,
            }}>
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                boxSizing: "border-box",
              }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignContent: "center",
                  flexDirection: "row",
                }}>
                <div
                  style={{
                    width: "75%",
                    display: "flex",
                    alignContent: "center",
                    flexDirection: "row",
                  }}>
                  <div
                    style={{ marginBottom: 15, fontSize: 24, maxWidth: "55%" }}>
                    {vacancy.vacancyName}
                  </div>
                  <div style={{ width: "7%" }}></div>
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: 14,
                    }}>
                    <div
                      style={{
                        padding: "7px 15px 5px 15px",
                        backgroundColor: "rgba(46, 105, 178, 0.48)",
                        color: palette.vacanciestext,
                        borderRadius: "17% / 50%",
                        opacity: 0.8,
                        fontWeight: "lighter",
                        border: "1px solid #2E69B2",
                      }}>
                      {vacancy.outstandingVacancy} vacancies
                    </div>
                  </div>
                  <div style={{ width: "2%" }}></div>
                  <div
                    style={{
                      marginTop: 9,
                      fontSize: 12,
                      fontStyle: "italic",
                      color: "#818385",
                      fontWeight: "lighter",
                      width: "20%",
                    }}>
                    <span style={{}}>
                      {t("rec-dashboard:text.lastUpdate")} : {updatedate},{" "}
                      {time.substr(0, 8)} WIB
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    width: "25%",
                    textAlign: "right",
                  }}>
                  {status === "INP" && (
                    <div
                      style={{
                        display: "flex",
                      }}>
                      <Button
                        onClick={() => {
                          AppHistory.push("/recruiter/schedule/" + params.id);
                        }}
                        size="small"
                        variant="contained"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid #2E69B2",
                          fontWeight: "lighter",
                          color: palette.primary,
                          height: 44,
                          fontSize: 16,
                          textTransform: "none",
                        }}>
                        {t("interview-schedule:title")}
                      </Button>
                      <Button
                        onClick={() => {
                          AppHistory.push("/recruiter/calendar/" + params.id);
                        }}
                        size="small"
                        variant="contained"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid #2E69B2",
                          fontWeight: "lighter",
                          color: palette.primary,
                          height: 44,
                          fontSize: 16,
                          // width: 187,
                          textTransform: "none",
                          marginLeft: "10px",
                        }}>
                        {t("calendar-schedule:title")}
                      </Button>
                    </div>
                  )}

                  {status === "LIS" && (
                    <div
                      style={{
                        marginLeft: "45%",
                        display: "flex",
                      }}>
                      <Button
                        onClick={() => window.location.reload()}
                        size="small"
                        variant="contained"
                        style={{
                          backgroundColor: palette.primary,
                          border: "1px solid #2E69B2",
                          fontWeight: "bold",
                          color: "white",
                          height: 44,
                          fontSize: 16,
                          textTransform: "none",
                          marginLeft: "10px",
                        }}>
                        {t("candidates:actionlist.refresh")}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  margin: "20px 0px",
                  boxSizing: "border-box",
                }}>
                <div
                  style={{
                    width: "5%",
                    fontSize: 18,
                    fontWeight: "lighter",
                    marginTop: 6,
                  }}>
                  {" "}
                  Status
                </div>
                {
                  <div style={{ width: "75%" }}>
                    {candidateCount.map((v, k) => {
                      var tx = getItem("lang") === "id" ? v.textIna : v.textEng;
                      return (
                        <ButtonFilterStatus
                          onClick={idx => {
                            this.handleFilterStatus(v.status);
                          }}
                          key={k + "sh"}
                          index={k + 1}
                          active={status}
                          text={tx + " " + v.count}
                          status={v.status}
                        />
                      );
                    })}
                  </div>
                }
                {(status === "LIS" || status === "WIS" || status === "SEL") && (
                  <div style={{ width: "20%", textAlign: "right" }}>
                    <Button
                      style={{
                        padding: "0px 0px",
                        color: palette.primary,
                        textTransform: "capitalize",
                        texthecoration: "underline",
                      }}
                      onClick={() => {
                        this.setState(
                          { advancedFilter: !advancedFilter },
                          () => {
                            this.setSubHeaderHeight();
                          }
                        );
                      }}>
                      {advancedFilter
                        ? t("candidates:button.hideFilter")
                        : "Filter"}
                      {!advancedFilter ? <ArrowDropDown /> : <ArrowDropUp />}
                    </Button>
                  </div>
                )}
              </div>
              {(status === "LIS" || status === "WIS" || status === "SEL") && (
                <Grow in={advancedFilter} style={{ transformOrigin: "0 0 0" }}>
                  <div
                    className="recruiter"
                    style={{
                      padding: "20px 0px",
                      display: advancedFilter ? "flex" : "none",
                      alignItems: "center",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        width: "70%",

                        alignItems: "center",
                        flexDirection: "row",
                      }}>
                      <Button
                        onClick={e => {
                          this.setState({
                            anchorSelectFilter: e.currentTarget,
                          });
                        }}
                        style={{
                          textTransform: "none",
                          color: palette.primary,
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                          textAlign: "left",
                          marginRight: 20,
                          width: 235,
                          height: 43,
                        }}>
                        <span
                          style={{
                            color: this.state.selectFilter
                              ? palette.primary
                              : "rgba(46, 105, 178, 0.3)",
                            fontWeight: "lighter",
                            width: 250,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}>
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "50%",
                            }}>
                            {this.state.selectFilter
                              ? t("candidates:button.filterapplied")
                              : t("candidates:button.selectFilter")}
                          </span>
                          <img
                            src={IconConfirmationCheck}
                            alt={"check"}
                            height="24px"
                            align="center"
                            style={{
                              marginLeft: "10px",
                              display: this.state.selectFilter ? "" : "none",
                            }}
                          />
                          {!anchorSelectFilter ? (
                            <ArrowDropDown style={{ color: palette.primary }} />
                          ) : (
                            <ArrowDropUp style={{ color: palette.primary }} />
                          )}
                        </span>
                      </Button>
                      <Select
                        variant="outlined"
                        labelId="demo-simple-select-outlined-label"
                        className="select-with-placeholder"
                        value={sortBy}
                        style={{
                          width: 235,
                          fontSize: 14,
                          marginRight: 20,
                        }}
                        onChange={e => {
                          const sorting = e.target.value.split(" ");
                          if (sorting.length === 2) {
                            this.setState({
                              sortBy: e.target.value,
                              sortDirection: sorting[0],
                              sortValue: sorting[1],
                            });
                          } else {
                            this.setState({
                              sortBy: e.target.value,
                              sortDirection: "",
                              sortValue: "",
                            });
                          }
                        }}>
                        <MenuItem value="-" style={{ color: palette.primary }}>
                          <span>{t("candidates:button.sortBy")}</span>
                        </MenuItem>
                        <MenuItem
                          value={"desc proximity"}
                          style={{ color: palette.primary }}>
                          {t("candidates:sortbylist.Highestproximity")}
                        </MenuItem>
                        <MenuItem
                          value={"asc proximity"}
                          style={{ color: palette.primary }}>
                          {t("candidates:sortbylist.Lowestproximity")}
                        </MenuItem>
                        <MenuItem
                          value={"desc gpa"}
                          style={{ color: palette.primary }}>
                          {t("candidates:sortbylist.HighestGPA")}
                        </MenuItem>
                        <MenuItem
                          value={"asc gpa"}
                          style={{ color: palette.primary }}>
                          {t("candidates:sortbylist.LowestGPA")}
                        </MenuItem>
                        <MenuItem
                          value={"desc graduation"}
                          style={{ color: palette.primary }}>
                          {t("candidates:sortbylist.Graduation")}
                        </MenuItem>
                        <MenuItem
                          value={"desc lengthofwork"}
                          style={{ color: palette.primary }}>
                          {t("candidates:sortbylist.Lengthofwork")}
                        </MenuItem>
                      </Select>
                      <div
                        className="Search"
                        style={{
                          padding: 5,
                          paddingLeft: 10,
                          borderRadius: 4,
                          display: "flex",
                          border: "0.5px solid rgba(0,0,0,0.2)",
                          alignItems: "center",
                          width: 500,
                          marginRight: 20,
                        }}>
                        <Search
                          style={{
                            color: "rgba(0,0,0,0.3)",
                            paddingBottom: 2,
                            paddingRight: 15,
                          }}
                        />
                        <InputBase
                          value={keyword}
                          onChange={this.handleChangeInputSearch}
                          placeholder={t("candidates:placeholder.typeSearch")}
                          style={{ width: 450 }}
                        />
                      </div>
                    </div>
                    <div style={{ width: "30%", textAlign: "left" }}>
                      <Button
                        style={{
                          minHeight: 33,
                          padding: "4px 25px",
                          border: "1px solid #2E69B2",
                          backgroundColor: "#2E69B2",
                          color: "#ffffff",
                          textTransform: "capitalize",
                          texthecoration: "underline",
                          marginRight: 20,
                        }}
                        onClick={() => {
                          this.getCandidate(this.state.status, false);
                        }}>
                        {t("text.search")}
                      </Button>
                      <Button
                        style={{
                          minHeight: 33,
                          padding: "4px 25px",
                          border: "1px solid #2E69B2",
                          backgroundColor: "#FFFFFF",
                          color: "#2E69B2",
                          textTransform: "capitalize",
                          texthecoration: "underline",
                        }}
                        onClick={() => {
                          this.resetFilter();
                          this.getCandidate(this.state.status, false);
                        }}>
                        Reset
                      </Button>
                    </div>
                  </div>
                </Grow>
              )}
            </div>
          </div>
          {/* sub header end */}
          <div
            style={{
              marginTop: subHeaderHeight,
            }}>
            {status === "LIS" && (
              <ListedTableView
                candidates={candidates}
                arrcheck={arrcheck}
                status={status}
                arrwish={arrwish}
                checkedAll={checkedAll}
                onOpenProximity={v => this.setState(v)}
                onClickCheckedAll={() => this.onClickCheckedAll()}
                onClickWishlist={(idx, userId) => {
                  this.onClickWishlist(idx, userId);
                }}
                onClickSelect={(x, y, z) => {
                  this.setState({ checkedAll: false });
                  this.onClickChecked(x, y, z);
                }}
              />
            )}
            {status === "WIS" && (
              <WishlistTableView
                candidates={candidates}
                arrcheck={arrcheck}
                status={status}
                arrwish={arrwish}
                onOpenProximity={v => this.setState(v)}
                onClickCheckedAll={() => this.onClickCheckedAll()}
                onClickDelete={userId => {
                  this.onClickDeletefromWishlist(userId);
                }}
                onClickSelect={(x, y, z) => {
                  this.onClickChecked(x, y, z);
                }}
              />
            )}
            {status === "SEL" && (
              <SelectedTableView
                candidates={candidates}
                arrcheck={arrcheck}
                status={status}
                onOpenProximity={v => this.setState(v)}
                onClickMore={v => {
                  this.setState(v);
                  this.onClickActionSelected(
                    v.userId,
                    v.status,
                    v.currentTarget
                  );
                }}
                onClickSelect={(x, y, z) => {
                  this.onClickChecked(x, y, z);
                }}
              />
            )}
            {status === "INP" && (
              <InprocessTableView
                candidates={candidates}
                arrcheck={arrcheck}
                status={status}
                onOpenProximity={v => this.setState(v)}
                onClickSelect={(x, y, z) => {
                  this.onClickChecked(x, y, z);
                }}
                onClickMore={v => {
                  this.setState(v);
                  this.onClickActionSelected(
                    v.userId,
                    v.status,
                    v.currentTarget
                  );
                }}
              />
            )}
            {status === "PRO" && (
              <ProposedTableView
                candidates={candidates}
                arrcheck={arrcheck}
                status={status}
                onOpenProximity={v => this.setState(v)}
                onClickSelect={(x, y, z, c) => {
                  this.onClickChecked(x, y, z, null, c);
                }}
                onClickMore={v => {
                  this.setState(v);
                  this.onClickActionSelected(
                    v.userId,
                    v.status,
                    v.currentTarget
                  );
                }}
              />
            )}
            {status === "HIR" && (
              <HiredTableView
                candidates={candidates}
                arrcheck={arrcheck}
                status={status}
                onOpenProximity={v => this.setState(v)}
                onClickMore={v => {
                  this.setState(v);
                  this.onClickActionSelected(
                    v.userId,
                    v.status,
                    v.currentTarget
                  );
                }}
              />
            )}
            {status === "ARC" && (
              <ArchivedTableView
                candidates={candidates}
                status={status}
                onOpenProximity={v => this.setState(v)}
                onClickMore={v => {
                  this.setState(v);
                  this.onClickActionSelected(
                    v.userId,
                    v.status,
                    v.currentTarget
                  );
                }}
              />
            )}
          </div>
          {loadingCompleted && candidates.length === 0 && (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  flexDirection: "column",
                }}>
                <div
                  style={{
                    width: 500,
                    textAlign: "center",
                  }}>
                  <div>
                    <img src={IconFolder} alt="Folder" />
                  </div>
                  <div
                    style={{ marginTop: 15, fontWeight: "lighter" }}
                    dangerouslySetInnerHTML={{
                      __html: t("candidates:text.empty"),
                    }}></div>
                </div>
                {this.state.button}
              </div>
              <HGPagination
                pageLimit={10}
                totalCount={candidateCountPage.count}
                onChange={p => {
                  this.setState({ page: p }, () =>
                    this.getCandidate(status, false)
                  );
                }}
              />
            </>
          )}
        </div>

        {status !== "HIR" && status !== "ARC" && ischecked && (
          <CandidateListFooter
            countcandidatechecked={countchecked}
            countselectablecandidate={candidates.length}
            countselectablecandidatequota={quota}
            text={textButtonMove}
            isEnable={ischecked}
            stat={status}
            NextClick={() => {
              this.onClickButtonMovetoDialog(status, listCandidatesJoinDate);
            }}
          />
        )}
      </div>
    );
  }
}
export default withTranslation()(RecruiterCandidateList);
