import React from "react";
import { palette } from "assets/css/main";
import { StyleSheet, Font, Image } from "@react-pdf/renderer";

export const WaterMark = () => (
  <Image src="/assets/img/watermark.png" style={CIStyles.waterMark} fixed />
);

export const HeaderLogoPDF = () => (
  <Image
    src="/assets/img/HiregradeLogo.png"
    style={CIStyles.headerLogo}
    fixed
  />
);

Font.register({
  family: "HurmeGeometricSans3",
  src: "/assets/fonts/hurme-geometric/hurme-geometric-semibold.ttf",
  fontWeight: "normal",
});
Font.register({
  family: "HurmeGeometricSans3",
  src: "/assets/fonts/hurme-geometric/hurme-geometric-bold.ttf",
  fontWeight: "bold",
});
Font.register({
  family: "HurmeGeometricSans3",
  src: "/assets/fonts/hurme-geometric/hurme-geometric-regular.ttf",
  fontWeight: "light",
});
export const CIStyles = StyleSheet.create({
  page: {
    color: palette.cv.textColor,
    fontFamily: "HurmeGeometricSans3",
    width: "100%",
    height: "100%",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  sectionLeft: {
    height: "100%",
    width: "35%",
    padding: "10pt 10pt",
    fontWeight: "lighter",
    display: "flex",
    backgroundColor: palette.info,
    flexDirection: "column",
    alignItems: "center",
  },
  listContent: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "-2pt 10pt 10pt 10pt",
    borderLeft: "2pt solid " + palette.primary,
  },
  titleItem: { fontSize: 14, fontWeight: "bold" },
  titleContent: { fontSize: 18, textTransform: "uppercase" },
  item: { fontSize: 12, padding: "5pt 0pt 0pt 0pt" },
  timelineDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    position: "absolute",
    left: -6,
    top: 4,
    backgroundColor: palette.primary,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  waterMark: {
    position: "absolute",
    display: "block",
    height: "380pt",
    width: "300pt",
    left: "30%",
    top: "20%",
  },
  headerLogo: {
    position: "absolute",
    height: "20pt",
    right: "40pt",
    top: "22pt",
  },
  subtitleLine: {
    width: "25pt",
    height: "2pt",
    marginRight: "5pt",
    backgroundColor: palette.bgs,
  },
});
