import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Typography, Grid } from "@material-ui/core";

class CompanyProfile extends React.Component {
  render() {
    const { t, data } = this.props;
    return (
      <Grid container style={{ padding: "300px 6% 8%" }}>
        <Grid item lg={9} md={9} sm={9}>
          <Typography
            style={{
              fontSize: "24px",
              color: palette.primary,
            }}>
            {
              t("job-opening:jobOpeningDetail.sideStep.menu", {
                returnObjects: true,
              })[2]
            }
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Corporate Name (Brand)
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.companyNameBrand}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            About Company
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.aboutUs}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Company Address
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.companyAddress}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Company Type
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.companyType}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Business Type
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.businessType}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Business Sub-Type
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.businessSubType}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Website
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.website}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Established On
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.established}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Number Of Employee
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.numberEmployee}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Number Of Branches
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobCompanyProfile.numberBranch}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(CompanyProfile);
