import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { palette } from "assets/css/main";
import bdc from "./data";
import { NextBtn } from "app/components";

export const InitializeFooter = observer((props) => {
  const { text } = props;
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      style={{
        display: bdc.stepper.footer ? "" : "none",
        height: 60,
        position: "fixed",
        bottom: 0,
        backgroundColor: palette.primary,
        paddingRight: 50,
        zIndex: 100,
      }}
    >
      {bdc.stepper.nextBtn && (
        <NextBtn
          color={"white"}
          onClick={() => props.nextButtonClick()}
          text={text}
        />
      )}
    </Grid>
  );
});
