import React from "react";
import { palette } from "assets/css/main";
import { withTranslation } from "react-i18next";
import { getItem } from "app/utils";
import { OptionDropdown, OptionWork } from "app/components";

export default withTranslation()(
  class EditWorkPreferences extends React.Component {
    render() {
      const { dataQuestion, data, t } = this.props;
      return (
        <>
          {dataQuestion.map((v, i) => {
            return (
              <div
                key={i + "display"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  color: palette.primary,
                  width: 900,
                  boxSizing: "border-box",
                  padding: 20,
                }}>
                <span
                  style={{
                    backgroundColor: palette.darkGrey,
                    color: "white",
                    borderRadius: "50%",
                    padding: "7px 12.2px",
                    height: 17,
                  }}>
                  {i + 1}
                </span>
                <div
                  style={{
                    padding: "7px 15px",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <span style={{ fontWeight: "lighter" }}>
                    {getItem("lang") === "en"
                      ? v.questionTextEng
                      : v.questionTextIna}
                  </span>
                  <span
                    style={{
                      fontWeight: "lighter",
                      color: "#515D6E",
                      fontStyle: "italic",
                      fontSize: 12,
                    }}>
                    *
                    {v.categoryQuestion.indexOf("Multiple") >= 0
                      ? t("cv:workReady.form.multiple")
                      : t("cv:workReady.form.single")}
                    {v.maxAnswer ? (
                      <>
                        <br />
                        {t("cv:workReady.form.maximum").replace(
                          "MAX",
                          v.maxAnswer
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                  {v.categoryQuestion === "MultipleDropdown" && (
                    <>
                      <OptionDropdown
                        maximum={3}
                        onChange={v => {
                          this.props.setValue(v, i);
                        }}
                        data={v}
                        value={data[i] ? data[i] : {}}
                      />
                    </>
                  )}
                  {v.categoryQuestion !== "MultipleDropdown" && (
                    <>
                      <OptionWork
                        onChange={v => {
                          this.props.setValue(v, i);
                        }}
                        isEdit={true}
                        multiple={
                          dataQuestion[i].categoryQuestion.indexOf(
                            "MultipleChoice"
                          ) >= 0
                            ? true
                            : false
                        }
                        value={data[i] ? data[i] : {}}
                        options={v.workAnswers}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </>
      );
    }
  }
);
