import React from "react";
import { withTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
} from "@material-ui/core";
import { palette, WHITE_BUTTON } from "assets/css/main";
import ContentTnc from "./ContentTnc";

class Tnc extends React.Component {
  render() {
    const { t, visible, handleClose } = this.props;
    return (
      <Dialog
        disableBackdropClick
        onClose={() => handleClose(false)}
        open={visible}
        scroll={"paper"}
        style={{ color: palette.primary }}
      >
        <DialogTitle>
          <span
            style={{
              textTransform: "uppercase",
              fontWeight: "bolder",
              color: palette.primary,
            }}
          >
            {t("auth:tnc")}
          </span>
        </DialogTitle>
        <DialogContent style={{ color: palette.primary, padding: 0 }}>
          <ContentTnc />
          <Grid
            container
            justify="space-between"
            style={{
              height: 70,
              backgroundColor: palette.primary,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Button
              style={{ ...WHITE_BUTTON }}
              onClick={() => handleClose(false)}
            >
              {t("auth:disagree")}
            </Button>
            <Button
              style={{ ...WHITE_BUTTON }}
              onClick={() => handleClose(true)}
            >
              {t("auth:agree")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withTranslation()(Tnc);
