import React from "react";
import { palette } from "assets/css/main";
import { Grid, Typography, Button } from "@material-ui/core";
import "./custom.css";
import { IconDone } from "assets/img";
import { getItem } from "app/utils";

export default class SideStep extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
  }

  onClickTest(obj) {
    console.log(obj);
  }

  render() {
    return (
      <Grid
        container
        style={{
          backgroundColor: "rgba(143, 193, 255, 0.4)",
          top: 0,
          position: "fixed",
          width: "25%",
          height: "100%",
          overflowX: "auto",
        }}>
        {this.props.data.map((v, i) => {
          return (
            <div key={i}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                style={i === 0 ? { marginTop: 90, marginLeft: 100 } : { marginTop: 30, marginLeft: 100 }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: palette.primary,
                  }}>
                  { getItem("lang") === "en" ? v.categoryNameEng : v.categoryNameIna }
                </Typography>
              </Grid>
              {v.softSkillHeaders.map((val, idx) => {
                return(
                  <Grid
                    key={idx}
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    style={
                      val.id === this.props.step
                        ? {
                            marginLeft: 100,
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            // padding: "5px 5px 5px 10px",
                            width: 265,
                            maxWidth: 315,
                          }
                        : {
                            marginLeft: 100,
                            // padding: "5px 5px 5px 10px",
                            width: 265,
                            maxWidth: 315,
                          }
                    }>
                    <Typography>
                      <Button
                        textLeft
                        style={{
                          fontSize: "16px",
                          color: palette.primary,
                          textTransform: "none",
                          textAlign: "left",
                          maxWidth: "80%",
                        }}
                        onClick={() => this.props.onChange(val.id)}
                      >
                        {val.softskillNameEng}
                      </Button>
                      {this.props.defaultValue.find(p => p.softskillHeaderId === val.id) ? (
                        <img
                          style={{ marginRight: 25, marginTop: 5, paddingTop: 10 }}
                          src={IconDone}
                          alt="Plus"
                          height="10vh"
                          align="right"
                        />
                      ) : null}
                    </Typography>
                  </Grid>
                );
              })}
              {/* {i === 0 ? (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ marginTop: 90, marginLeft: 100 }}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: palette.primary,
                    }}>
                    Thinking Skill
                  </Typography>
                </Grid>
              ) : i === 3 ? (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ marginTop: 30, marginLeft: 100 }}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: palette.primary,
                    }}>
                    Working Attitude
                  </Typography>
                </Grid>
              ) : i === 10 ? (
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  style={{ marginTop: 30, marginLeft: 100 }}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: palette.primary,
                    }}>
                    Relationship Skill
                  </Typography>
                </Grid>
              ) : null}
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                style={
                  i === this.props.step
                    ? {
                        marginLeft: 100,
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        padding: "5px 5px 5px 10px",
                        width: 265,
                        maxWidth: 315,
                      }
                    : {
                        marginLeft: 100,
                        padding: "5px 5px 5px 10px",
                        width: 265,
                        maxWidth: 315,
                      }
                }>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: palette.primary,
                  }}>
                  {v.header}
                  {this.props.currentValue[v.key] ? (
                    <img
                      style={{ marginRight: 25, marginTop: 5 }}
                      src={IconDone}
                      alt="Plus"
                      height="10vh"
                      align="right"
                    />
                  ) : null}
                </Typography>
              </Grid> */}
            </div>
          );
        })}
        <Grid item lg={12} md={12} sm={12} style={{ marginBottom: 30 }} />
      </Grid>
    );
  }
}
