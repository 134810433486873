import React from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  StepConnector,
  withStyles,
} from "@material-ui/core";
import { palette } from "assets/css/main";
import { Check } from "@material-ui/icons";
import "./custom.css";
import clsx from "clsx";
import PersonalData from "./personal-data";
import FormalEducation from "./formal-education";
import WorkExperience from "./work-experience";

import bdc from "./data";
import { InitializeFooter } from "./footer";
import MyHelmet from "app/components/header/MyHelmet";
import { Header } from "app/components";
import { RequestGet, getItem } from "app/utils";
import { ConfirmationApplicant } from "app/pages";

import InformalEducation from "./informal-education";
import Organization from "./organization";

/** please note, replace locales/en or locales/id  biodata.json*/
/*
"steps": [
    "Personal Data",
    "Formal Education",
    "Informal Education",
    "Organizational Experience",
    "Work Experience"
  ],

  "steps": [
    "Data Diri",
    "Pendidikan Formal",
    "Pendidikan Informal",
    "Pengalaman Organisasi",
    "Pengalaman Kerja"
  ],
*/

const useCustomIconStyles = makeStyles({
  root: {
    backgroundColor: "rgba(82, 200, 244, 0.2)",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    fontWeight: "bold",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: palette.secondary,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "rgba(82, 200, 244, 0.2)",
  },
});

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: palette.secondary,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: palette.secondary,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#ccc",
    borderRadius: 1,
  },
})(StepConnector);

function CustomIcon(props) {
  const classes = useCustomIconStyles();
  const { active, completed } = props;

  const icons = {
    1: "a",
    2: "b",
    3: "c",
    4: "d",
    5: "e",
    6: "f",
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {completed ? <Check /> : icons[String(props.icon)]}
    </div>
  );
}

class BioData extends React.Component {
  constructor(props) {
    super(props);
    const t = props.t;
    const steps = t("biodata:steps", { returnObjects: true });
    bdc.stepper.length = steps.length;
    bdc.stepper.activeStep = 0;
    if (this.props.match.params.name) {
      this.props.history.push("/initialize/biodata");
    }
    try {
      this.state = {
        ...bdc.stepper,
        ...bdc.personal_data,
        user: JSON.parse(getItem("user_info")),
        content: <div></div>,
      };
      bdc.user = this.state.user;
    } catch (error) {}
  }
  componentDidMount() {
    this.getBiodata();
    // this.setState({ content: <PersonalData history={this.props.history} /> });
  }
  handleNext() {
    bdc.stepper.activeStep += 1;
  }
  handleBack() {
    bdc.stepper.activeStep -= 1;
    this.setState({ activeStep: bdc.stepper.activeStep });
  }
  getBiodata() {
    this.setState({ content: <div></div> });
    RequestGet("biodata/status")
      .then(res => {
        const { biodataStatus } = res.data;

        if (biodataStatus === "POA") {
          this.props.history.push("/initialize/pretest");
        } else if (biodataStatus === "CFM") {
          bdc.stepper.activeStep = 5;
          this.setState({
            activeStep: bdc.stepper.activeStep,
            content: <ConfirmationApplicant />,
          });
        } else if (biodataStatus === "WE") {
          bdc.stepper.activeStep = 4;
          this.setState({
            activeStep: bdc.stepper.activeStep,
            content: <WorkExperience />,
          });
        } else if (biodataStatus === "OE") {
          bdc.stepper.activeStep = 3;
          this.setState({
            activeStep: bdc.stepper.activeStep,
            content: <Organization />,
          });
        } else if (biodataStatus === "IE") {
          bdc.stepper.activeStep = 2;
          this.setState({
            activeStep: bdc.stepper.activeStep,
            content: <InformalEducation />,
          });
        } else if (biodataStatus === "FE") {
          bdc.stepper.activeStep = 1;
          this.setState({
            activeStep: bdc.stepper.activeStep,
            content: <FormalEducation />,
          });
        } else if (biodataStatus === "PI") {
          this.setState({
            activeStep: bdc.stepper.activeStep,
            content: <PersonalData />,
          });
        } else {
          this.setState({
            activeStep: bdc.stepper.activeStep,
            content: <div>Invalid status</div>,
          });
        }
        /** only 3 biodata -end- */
      })
      .catch(e => {
        console.log(e);
        this.setState({
          content: <div>Failed to get information, reloading...</div>,
        });
        setTimeout(() => {
          this.getBiodata();
        }, 5000);
      });
  }

  render() {
    const { t } = this.props;
    const steps = t("biodata:steps", { returnObjects: true });
    return (
      <div>
        <MyHelmet title="Bio Data" />
        <Header shadow={true} linkgroup={false} stepper={true} activeStep={0} />
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            backgroundColor: palette.info,
            minHeight: 30,
            marginTop: 75,
          }}>
          <Grid
            item
            md={steps.length < 5 ? 6 : 10}
            lg={steps.length < 5 ? 6 : 10}
            sm={steps.length < 5 ? 6 : 10}>
            <Stepper
              connector={<CustomConnector />}
              activeStep={this.state.activeStep}
              style={{ backgroundColor: palette.info, paddingRight: 70 }}>
              {steps.map((label, idx) => {
                return (
                  <Step
                    key={label}
                    completed={this.state.activeStep > idx ? true : false}>
                    <StepLabel
                      className="NavBar"
                      StepIconComponent={CustomIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 100 }}>
          {this.state.content}
        </Grid>
        <InitializeFooter
          translate={t}
          step={this.state.activeStep}
          nextButtonClick={() => {
            this.handleNext();
          }}
        />
      </div>
    );
  }
}
export default withTranslation()(BioData);
