import React from "react";
import { withTranslation } from "react-i18next";
import SidePanel from "../side-panel";
import { MyHelmet, ApplicantHeader } from "app/components";
import TellUsFirstTime from "./TellUsFirstTime";
import TellUs from "./TellUs";
import data from "./data";
import { RequestGet } from "app/utils";
// import { logError } from "app/utils/general/rollbar";

class EditCV extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
    this.state = {
      ...data.tell_us,
      profile: undefined,
      alreadyGet: false,
      firstTime: false,
      alphabet: ["A", "B", "C", "D", "E", "F"],
    };
  }

  getAboutQuestion() {
    RequestGet("aboutquestion")
      .then(res => {
        data.tell_us.tellUsApi = res.data;
        data.tell_us.tellUsApi.forEach((v, i) => {
          var options = [];
          var idOptions = [];

          if (v.categoryQuestion === "Single") {
            v.aboutAnswers.forEach((element, index) => {
              options.push({
                key: this.state.alphabet[index],
                label: element.answerTextEn,
                value: element.answerId,
              });

              idOptions.push({
                key: this.state.alphabet[index],
                label: element.answerTextId,
                value: element.answerId,
              });
            });
          }

          data.listTellUs.push({
            idOptions: idOptions,
            options: options,
          });
        });
        if (data.tell_us.tellUsApi[0].responseAnswers.length === 0) {
          this.setState({ firstTime: true });
        }
        this.setState({ alreadyGet: true, tellUsApi: res.data });
      })
      .catch(error => {
        // logError(error);
        this.setState({ firstTime: true });
      });
  }

  componentDidMount() {
    this.getAboutQuestion();
  }

  render() {
    const { profile } = this.state;
    return (
      <div style={{ paddingTop: 100 }}>
        <MyHelmet title={"Home"} />
        <ApplicantHeader menu="home" />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingBottom: 100,
          }}>
          <div
            style={{
              width: "15%",
              display: "flex",
              flexDirection: "column",
            }}></div>
          <div style={{ width: 300, display: "flex", flexDirection: "column" }}>
            <SidePanel profile={profile} />
          </div>
          <div
            style={{
              width: "1%",
              display: "flex",
              flexDirection: "column",
            }}></div>
          <div
            style={{ width: "54%", display: "flex", flexDirection: "column" }}>
            {this.state.alreadyGet === true ? (
              this.state.firstTime === true ? (
                <TellUsFirstTime
                  tellUsApi={this.state.tellUsApi}
                  getProfile={p => {
                    this.setState({ profile: p });
                  }}
                  onFinish={() => this.setState({ firstTime: false })}
                />
              ) : (
                <TellUs />
              )
            ) : null}
          </div>
          <div
            style={{
              width: "15%",
              display: "flex",
              flexDirection: "column",
            }}></div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(EditCV);
