import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import { Header, Footer, DialogueInfo, HeaderNologin } from "app/components";
import { Grid, Button } from "@material-ui/core";
import { palette, PRIMARY_BUTTON } from "assets/css/main";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ImgContact, IcnPin, IcnTelephone, IcnMail } from "assets/img";
import { AUTH_API, RequestPost } from "app/utils";
import { isMobile } from "react-device-detect";
class ContactPage extends React.Component {
  state = { name: "", email: "", message: "", subject: "", open: false };
  constructor(props) {
    super(props);
    this.submitForm = this.submitContact.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  submitContact() {
    RequestPost(AUTH_API + "connect/contactus", this.state)
      .then(res => {
        this.setState({
          name: "",
          email: "",
          message: "",
          subject: "",
          open: true,
          result: res,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    const { t } = this.props;
    if (isMobile) {
      return (
        <div style={{ boxSizing: "border-box" }}>
          <DialogueInfo
            width={300}
            title={t("contact:successMessage.title")}
            content={t("contact:successMessage.content")}
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
          />
          <HeaderNologin />
          <MyHelmet
            title="Contact Us"
            description="We take our commitment to our users seriously. If you need our help with your user account, have questions about how to use the platform or are experiencing technical difficulties, please do not hestitate to contact us."
          />
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            style={{
              width: "100vw",
              boxSizing: "border-box",
              padding: "60px 25px",
              backgroundColor: palette.primary,
            }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: 24,
                color: "white",
                marginBottom: 10,
              }}>
              {t("contact:title")}
            </p>
            <img
              src={ImgContact}
              alt="contact"
              style={{ width: 234, height: 221, padding: "35px 0px" }}
            />
            <p style={{ fontSize: 16, color: "white" }}>
              {t("contact:content")}
            </p>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            style={{
              minHeight: 500,
              padding: 20,
              paddingTop: 0,
              backgroundColor: palette.secondary,
            }}>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              style={{
                minHeight: 40,
                textAlign: "left",
              }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 24,
                  color: palette.primarydark,
                  textAlign: "center",
                  marginBottom: 10,
                  padding: "25px 0px",
                }}>
                {t("contact:question")}
              </p>
            </Grid>
            <Grid container>
              <Grid item lg={6} md={6} sm={6}>
                <ValidatorForm onSubmit={this.submitForm} className="contact">
                  <TextValidator
                    fullWidth
                    label={t("contact:form.label.name")}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    name="name"
                    type="text"
                    value={this.state.name}
                    style={{ marginTop: 20, fontSize: 16 }}
                    autoComplete="name"
                    className="Field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t("contact:form.placeholder.name")}
                    validators={["required"]}
                    errorMessages={[t("validation.required.name")]}
                  />
                  <TextValidator
                    label={t("contact:form.label.email")}
                    fullWidth
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                    name="email"
                    value={this.state.email}
                    style={{ marginTop: 20, fontSize: 16 }}
                    className="Field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t("contact:form.placeholder.email")}
                    autoComplete={"current-email"}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      t("validation.required.email"),
                      t("validation.valid.email"),
                    ]}
                  />
                  <TextValidator
                    fullWidth
                    label={t("contact:form.label.subject")}
                    onChange={e => {
                      this.setState({ subject: e.target.value });
                    }}
                    name="subject"
                    type="text"
                    value={this.state.subject}
                    style={{ marginTop: 20, fontSize: 16 }}
                    autoComplete="subject"
                    className="Field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t("contact:form.placeholder.subject")}
                    validators={["required"]}
                    errorMessages={[t("validation.required.subject")]}
                  />
                  <TextValidator
                    label={t("contact:form.label.message")}
                    fullWidth
                    onChange={e => {
                      this.setState({ message: e.target.value });
                    }}
                    name="message"
                    type="message"
                    className="Field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t("contact:form.placeholder.message")}
                    value={this.state.message}
                    style={{ marginTop: 20, fontSize: 16 }}
                    multiline={true}
                    rows={5}
                    rowsMax={10}
                    autoComplete={"current-message"}
                    validators={["required"]}
                    errorMessages={[t("validation.required.message")]}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type={"submit"}
                      variant="contained"
                      size="large"
                      style={{ ...PRIMARY_BUTTON, fontSize: 16, padding: 0 }}>
                      {t("contact:form.button.submit")}
                    </Button>
                  </div>
                </ValidatorForm>
              </Grid>
              <Grid item lg={6} md={6} sm={6} style={{ marginTop: 50 }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: palette.primarydark,
                    marginBottom: 20,
                  }}>
                  HIREGRADE
                  <br />
                  PT DAYA SELARAS KARYA
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 18,
                    color: palette.primarydark,
                  }}>
                  <img
                    src={IcnPin}
                    alt="pin"
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  <p style={{ marginTop: 0 }}>
                    EightyEight@Kasablanka Tower A, 18th Floor Jl. Kasablanka
                    Raya Kav.88 - Jakarta 12870
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 18,
                    color: palette.primarydark,
                  }}>
                  <img
                    src={IcnTelephone}
                    alt="telp"
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  <p style={{ marginTop: 0 }}>+62 2129607605</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 18,
                    color: palette.primarydark,
                  }}>
                  <img
                    src={IcnMail}
                    alt="mail"
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  <p style={{ marginTop: 0 }}>
                    info@hiregrade.id
                    {/* hg.support@ikonsultan.com */}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Footer />
        </div>
      );
    } else {
      return (
        <div>
          <DialogueInfo
            title={t("contact:successMessage.title")}
            content={t("contact:successMessage.content")}
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
          />
          <MyHelmet
            title="Contact Us"
            description="We take our commitment to our users seriously. If you need our help with your user account, have questions about how to use the platform or are experiencing technical difficulties, please do not hestitate to contact us."
          />
          <Header shadow={true} linkgroup={true} />
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{
              width: "100vw",
              padding: 100,
              backgroundColor: palette.primary,
            }}>
            <Grid item lg={6} md={6}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 32,
                  color: "white",
                  marginBottom: 10,
                }}>
                {t("contact:title")}
              </p>
              <p style={{ fontSize: 18, color: "white", width: 523 }}>
                {t("contact:content")}
              </p>
            </Grid>
            <Grid item>
              <img
                src={ImgContact}
                alt="contact"
                width={334}
                height={314}
                style={{ marginLeft: 100, marginTop: 20 }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justify="flex-start"
            style={{
              minHeight: 500,
              padding: 100,
              paddingTop: 0,
              backgroundColor: palette.secondary,
            }}>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              style={{
                minHeight: 40,
                textAlign: "left",
              }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 24,
                  color: palette.primarydark,
                  marginBottom: 10,
                }}>
                {t("contact:question")}
              </p>
            </Grid>
            <Grid container>
              <Grid item lg={6} md={6} sm={6} style={{ paddingRight: 100 }}>
                <ValidatorForm onSubmit={this.submitForm} className="contact">
                  <TextValidator
                    fullWidth
                    label={t("contact:form.label.name")}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                    name="name"
                    type="text"
                    value={this.state.name}
                    style={{ marginTop: 20 }}
                    autoComplete="name"
                    className="Field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t("contact:form.placeholder.name")}
                    validators={["required"]}
                    errorMessages={[t("validation.required.name")]}
                  />
                  <TextValidator
                    label={t("contact:form.label.email")}
                    fullWidth
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                    name="email"
                    value={this.state.email}
                    style={{ marginTop: 20 }}
                    className="Field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t("contact:form.placeholder.email")}
                    autoComplete={"current-email"}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      t("validation.required.email"),
                      t("validation.valid.email"),
                    ]}
                  />
                  <TextValidator
                    fullWidth
                    label={t("contact:form.label.subject")}
                    onChange={e => {
                      this.setState({ subject: e.target.value });
                    }}
                    name="subject"
                    type="text"
                    value={this.state.subject}
                    style={{ marginTop: 20 }}
                    autoComplete="subject"
                    className="Field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t("contact:form.placeholder.subject")}
                    validators={["required"]}
                    errorMessages={[t("validation.required.subject")]}
                  />
                  <TextValidator
                    label={t("contact:form.label.message")}
                    fullWidth
                    onChange={e => {
                      this.setState({ message: e.target.value });
                    }}
                    name="message"
                    type="message"
                    className="Field"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t("contact:form.placeholder.message")}
                    value={this.state.message}
                    style={{ marginTop: 20 }}
                    multiline={true}
                    rows={5}
                    rowsMax={10}
                    autoComplete={"current-message"}
                    validators={["required"]}
                    errorMessages={[t("validation.required.message")]}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type={"submit"}
                      variant="contained"
                      size="large"
                      style={{ ...PRIMARY_BUTTON }}>
                      {t("contact:form.button.submit")}
                    </Button>
                  </div>
                </ValidatorForm>
              </Grid>
              <Grid item lg={6} md={6} sm={6} style={{ paddingLeft: 100 }}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: palette.primarydark,
                    marginBottom: 20,
                  }}>
                  HIREGRADE
                  <br />
                  PT DAYA SELARAS KARYA
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 18,
                    color: palette.primarydark,
                  }}>
                  <img
                    src={IcnPin}
                    alt="pin"
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  <p style={{ width: 372, marginTop: 0 }}>
                    EightyEight@Kasablanka Tower A, 18th Floor Jl. Kasablanka
                    Raya Kav.88 - Jakarta 12870
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 18,
                    color: palette.primarydark,
                  }}>
                  <img
                    src={IcnTelephone}
                    alt="telp"
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  <p style={{ width: 372, marginTop: 0 }}>+62 2129607605</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 18,
                    color: palette.primarydark,
                  }}>
                  <img
                    src={IcnMail}
                    alt="mail"
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  <p style={{ width: 372, marginTop: 0 }}>
                    info@hiregrade.id
                    {/* hg.support@ikonsultan.com */}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Footer />
        </div>
      );
    }
  }
}
export default withTranslation()(ContactPage);
