import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { palette } from "assets/css/main";
import { NextBtn } from "app/components";
import DialogueConfirm from "app/components/modal/confirm";
import { General, t } from "app/utils";

export const ConfirmFooter = observer(props => {
  const { text } = props;

  const showDialogue = () => {
    General.showDialog = true;
  };
  const clickOK = () => {
    props.NextClick();
    General.showDialog = false;
  };
  const clickCancel = () => {
    General.showDialog = false;
  };
  return (
    <>
      <DialogueConfirm
        title={t("dialogue.confirm.title")}
        content={t("dialogue.confirm.content")}
        btnYes={t("dialogue.confirm.yes")}
        btnNo={t("dialogue.confirm.no")}
        open={General.showDialog}
        onOK={() => clickOK()}
        onClose={() => clickCancel()}
      />
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        style={{
          height: 60,
          position: "fixed",
          bottom: 0,
          left: 0,
          backgroundColor: palette.primary,
          padding: "0px 50px",
          zIndex: 100,
          display: "flex",
          justifyContent: "space-between",
        }}>
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: 24,
            cursor: "pointer",
            textDecoration: "underline",
          }}></div>
        <NextBtn color={"white"} onClick={() => showDialogue()} text={text} />
      </Grid>
    </>
  );
});
