import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { palette } from "assets/css/main";
import bdc from "./data";
import { NextBtn } from "app/components";

export const InitializeFooter = observer((props) => {
  const { translate } = props;
  return (
    <Grid
      id="BiodataFooter"
      container
      justify="flex-end"
      alignItems="center"
      style={{
        display: bdc.stepper.footer ? "" : "none",
        height: 60,
        position: "fixed",
        bottom: 0,
        backgroundColor: palette.primary,
        paddingRight: 50,
      }}
    >
      {bdc.stepper.nextBtn && (
        <NextBtn
          id="SaveAndContinue"
          color={"white"}
          onClick={() => props.nextButtonClick()}
          text={translate("button.saveandcontinue")}
        />
      )}
    </Grid>
  );
});
