import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import UserProfileSidePanel from "./side-panel";
import HeaderProfile from "./header";
import { RequestGet } from "app/utils";
import VideoPlayer from "app/pages/applicant/dashboard/home/edit-cv/video-interview/video-player";
import { ApplicantHeader } from "app/components";

export default withTranslation()(
  class UserFeedbackProfile extends React.Component {
    state = {
      interview: [],
      video: null,
      current: null,
      openVideo: false,
      indexPlay: null,
      isLoading: true,
    };
    componentDidMount() {
      window.scrollTo(0, 0);
    }
    getVideoInterview() {
      const { params } = this.props.match;
      this.setState({ isLoading: true });
      RequestGet("candidate/interview/" + params.id)
        .then(r => {
          const { data } = r;
          this.setState({ video: data });
          RequestGet("InterviewQuestion")
            .then(re => {
              this.setState({ isLoading: true });

              return re.data.interviewVideoQuestions.map((v, i) => {
                return {
                  interviewQuestionEng: v.interviewQuestionEng,
                  interviewQuestionIna: v.interviewQuestionIna,
                  video: data[i] ? data[i].videoUrl : "",
                };
              });
            })
            .then(data => {
              this.setState({ interview: data });
            })
            .catch(er => {
              this.setState({ isLoading: false });

              console.log("getVideoInterview()", er);
            });
        })
        .catch(e => {
          this.setState({ isLoading: false });
          console.log("getVideoInterview()", e);
        });
    }
    render() {
      const {
        match: { params },
      } = this.props;
      const { interview, video, openVideo, indexPlay } = this.state;
      return (
        <div style={{ height: "100%", boxSizing: "border-box" }}>
          <ApplicantHeader menu="profile" shadow={false} />
          <div style={{ paddingTop: 80, boxSizing: "border-box" }}>
            <HeaderProfile id={params.id} />
            <VideoPlayer
              open={openVideo}
              videos={video}
              questions={interview}
              index={indexPlay}
              onClose={() => {
                this.setState({ openVideo: false });
              }}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                minHeight: 650,
              }}>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  boxSizing: "border-box",
                  backgroundColor: palette.lightGrey,
                  justifyContent: "flex-end",
                }}>
                <UserProfileSidePanel view="feedback" id={params.id} />
              </div>
              <div
                style={{
                  width: "80%",
                  padding: "25px 40px",
                  color: palette.primary,
                  marginBottom: 50,
                  boxSizing: "border-box",
                }}></div>
            </div>
          </div>
        </div>
      );
    }
  }
);
