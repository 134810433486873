import React from "react";
import { withTranslation } from "react-i18next";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { palette } from "assets/css/main";
import data from "app/pages/recruiter/data";
import { MenuItem, Typography } from "@material-ui/core";
import { currencyFormat, removeCurrency } from "app/utils";

class EditProfileAdditional extends React.Component {
  state = {
    content: <div></div>,
  };
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.translate = this.props.t;
    this.state = {
      additionalInfo: props.data,
    };
  }

  componentDidMount() {
    var numberRegex = /(^$)|(^\d+$)/;
    ValidatorForm.addValidationRule("isNumber", value => {
      var intvalue = parseFloat(value);
      if (value === "") {
        return true;
      }
      if (isNaN(intvalue)) {
        return false;
      } else if (numberRegex.test(intvalue)) {
        return true;
      }
      return false;
    });
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isNumber");
    ValidatorForm.removeValidationRule("selectRequired");
  }

  handleChange(obj) {
    this.setState(obj);
    data.setadditionalinfo(obj);
    var additionalInfo = {
      additionalInfo: { ...this.state.additionalInfo, ...obj },
    };
    this.setState({
      ...this.state,
      ...additionalInfo,
    });
  }

  handleCompanyAsset(ca) {
    var companyAsset = currencyFormat(ca);
    var additionalInfo = {
      additionalInfo: { ...this.state.additionalInfo, companyAsset },
    };
    this.setState({
      ...this.state,
      ...additionalInfo,
    });
    companyAsset = removeCurrency(companyAsset);
    data.additionalInfo.companyAsset = parseFloat(companyAsset);
  }

  handleCompanyRevenue(cr) {
    var companyRevenue = currencyFormat(cr);
    var additionalInfo = {
      additionalInfo: { ...this.state.additionalInfo, companyRevenue },
    };
    this.setState({
      ...this.state,
      ...additionalInfo,
    });
    companyRevenue = removeCurrency(companyRevenue);
    data.additionalInfo.companyRevenue = parseFloat(companyRevenue);
  }

  handleClick(e) {
    document.getElementById("button-submit").click();
  }

  submitForm(e) {
    e.preventDefault();

    var intcompanyAsset = removeCurrency(
      this.state.additionalInfo.companyAsset
    );
    var intcompanyRevenue = removeCurrency(
      this.state.additionalInfo.companyRevenue
    );
    data.additionalInfo.numberOfEmployee = this.state.additionalInfo.numberOfEmployee;
    data.additionalInfo.numberOfBranches = this.state.additionalInfo.numberOfBranches;
    data.additionalInfo.companyAsset = parseFloat(intcompanyAsset);
    data.additionalInfo.companyRevenue = parseFloat(intcompanyRevenue);
    data.additionalInfo.urgentHiring = this.state.additionalInfo.urgentHiring;
    data.additionalInfo.projectYear = this.state.additionalInfo.projectYear;
  }

  render() {
    const { t } = this.props;
    const urgentHiring = t("profilesetup:options.urgentHiring", {
      returnObjects: true,
    });
    return (
      <div className="">
        <div
          style={{
            height: 58,
            padding: "4% 6%",
            display: "flex",
            fontWeight: "lighter",
            backgroundColor: "#2E69B2",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            color: "white",
          }}>
          <label style={{ fontSize: 24 }}>
            {t("recruiterSetup", { returnObjects: true })[2]}
          </label>
          <label style={{ fontSize: 14 }}>
            {t("profilesetup:additionalInfo")}
          </label>
          <label style={{ fontSize: 14 }}>*{t("profilesetup:require")}</label>
        </div>
        <div
          style={{
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            fontSize: 24,
          }}>
          <div
            justify="flex-start"
            style={{
              color: palette.primary,
              marginTop: "3%",
              marginLeft: "6%",
              marginBottom: "3%",
            }}>
            <ValidatorForm
              onSubmit={this.submitForm}
              style={{
                display: "flex",
                flexDirection: "column",
              }}>
              <div
                style={{
                  marginTop: "3%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.numberOfEmployee")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <SelectValidator
                  className="Field select-with-placeholder"
                  defaultValue=" "
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validators={["selectRequired"]}
                  errorMessages={[t("validation.required.numberOfEmployee")]}
                  displayempty="true"
                  onChange={e =>
                    this.handleChange({ numberOfEmployee: e.target.value })
                  }
                  value={this.state.additionalInfo.numberOfEmployee}
                  style={{ width: "46.5%" }}>
                  <MenuItem disabled value=" ">
                    <span>
                      {t("profilesetup:form.placeholder.numberOfEmployee")}
                    </span>
                  </MenuItem>
                  <MenuItem value="<100">{"< 100"}</MenuItem>
                  <MenuItem value="101 - 300">{"101 - 300"}</MenuItem>
                  <MenuItem value="301 - 800">{"301 - 800"}</MenuItem>
                  <MenuItem value="801 - 1500">{"801 - 1500"}</MenuItem>
                  <MenuItem value=">1500">{"> 1500"}</MenuItem>
                </SelectValidator>
              </div>

              <div
                style={{
                  marginTop: "3%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.numberOfBranches")}
                </label>
                <SelectValidator
                  className="Field select-with-placeholder"
                  defaultValue=" "
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validators={["selectRequired"]}
                  errorMessages={[t("validation.required.numberOfEmployee")]}
                  displayempty="true"
                  onChange={e =>
                    this.handleChange({ numberOfBranches: e.target.value })
                  }
                  value={this.state.additionalInfo.numberOfBranches}
                  style={{ width: "46.5%" }}>
                  <MenuItem disabled value=" ">
                    <span>
                      {t("profilesetup:form.placeholder.numberOfBranches")}
                    </span>
                  </MenuItem>
                  <MenuItem value="<15">{"< 15"}</MenuItem>
                  <MenuItem value="16 - 50">{"16 - 50"}</MenuItem>
                  <MenuItem value="51 - 100">{"51 - 100"}</MenuItem>
                  <MenuItem value="101 - 5004">{"101 - 500"}</MenuItem>
                  <MenuItem value=">5000">{"> 5000"}</MenuItem>
                </SelectValidator>
              </div>

              <div style={{ marginTop: "3%" }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.companyAsset")}
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "24px",
                        color: palette.primary,
                        marginTop: "60%",
                      }}>
                      Rp
                    </Typography>
                  </div>
                  <div style={{ marginLeft: "2%", width: "100%" }}>
                    <TextValidator
                      onChange={e => this.handleCompanyAsset(e.target.value)}
                      style={{ width: "60%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t(
                        "profilesetup:form.placeholder.companyAsset"
                      )}
                      name="companyAsset"
                      validators={["isNumber"]}
                      errorMessages={[t("validation.valid.companyAsset")]}
                      className="Field"
                      value={
                        this.state.additionalInfo.companyAsset
                          ? currencyFormat(
                              this.state.additionalInfo.companyAsset
                            )
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "3%" }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.companyRevenue")}
                </label>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "24px",
                        color: palette.primary,
                        marginTop: "60%",
                      }}>
                      Rp
                    </Typography>
                  </div>
                  <div style={{ marginLeft: "2%", width: "100%" }}>
                    <TextValidator
                      validators={["isNumber"]}
                      errorMessages={[t("validation.valid.companyRevenue")]}
                      onChange={e => this.handleCompanyRevenue(e.target.value)}
                      style={{ width: "60%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t(
                        "profilesetup:form.placeholder.companyRevenue"
                      )}
                      name="companyRevenue"
                      className="Field"
                      value={
                        this.state.additionalInfo.companyRevenue
                          ? currencyFormat(
                              this.state.additionalInfo.companyRevenue
                            )
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  marginTop: "3%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.urgentHiring")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <SelectValidator
                  className="Field select-with-placeholder"
                  defaultValue=" "
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validators={["selectRequired"]}
                  errorMessages={[t("validation.required.urgentHiring")]}
                  displayempty="true"
                  onChange={e =>
                    this.handleChange({ urgentHiring: e.target.value })
                  }
                  value={this.state.additionalInfo.urgentHiring}
                  style={{ width: "46.5%" }}>
                  <MenuItem disabled value=" ">
                    <span>
                      {t("profilesetup:form.placeholder.urgentHiring")}
                    </span>
                  </MenuItem>
                  <MenuItem value="Within 3 months">
                    {urgentHiring[0].label}
                  </MenuItem>
                  <MenuItem value="Within 6 months">
                    {urgentHiring[1].label}
                  </MenuItem>
                  <MenuItem value="Beyond 3 months">
                    {urgentHiring[2].label}
                  </MenuItem>
                </SelectValidator>
              </div>

              <div
                style={{
                  marginTop: "3%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.projectYear")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <SelectValidator
                  className="Field select-with-placeholder"
                  defaultValue=" "
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validators={["selectRequired"]}
                  errorMessages={[t("validation.required.projectYear")]}
                  displayempty="true"
                  onChange={e =>
                    this.handleChange({ projectYear: e.target.value })
                  }
                  value={this.state.additionalInfo.projectYear}
                  style={{ width: "46.5%" }}>
                  <MenuItem disabled value=" ">
                    <span>
                      {t("profilesetup:form.placeholder.projectYear")}
                    </span>
                  </MenuItem>
                  <MenuItem value="<10">{"< 10"}</MenuItem>
                  <MenuItem value="11 - 50">{"11 - 50"}</MenuItem>
                  <MenuItem value="51 - 100">{"51 - 100"}</MenuItem>
                  <MenuItem value="101 - 500">{"101 - 500"}</MenuItem>
                  <MenuItem value=">500">{"> 500"}</MenuItem>
                </SelectValidator>
              </div>

              <button type="submit" id="button-submit" className="Submit-Btn">
                submit
              </button>
            </ValidatorForm>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(EditProfileAdditional);
