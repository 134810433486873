import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { AppHistory } from "app/utils";
const styles = {
  padding: "10px 40px 10px 10px",
  width: 210,
  cursor: "pointer",
};
export default withTranslation()(
  class CandidateSidePanel extends React.Component {
    render() {
      const { t, view, id } = this.props;
      return (
        <div style={{ color: palette.primary, padding: "30px 0px" }}>
          <span>{t("candidates:candidateInfo.title")}</span>
          <div style={{ fontWeight: "lighter", marginTop: 20 }}>
            <div
              onClick={() => {
                AppHistory.push("/recruiter/candidates/information/cv/" + id);
              }}
              style={{
                ...styles,
                backgroundColor:
                  view === "cv" ? palette.primary_opacity : "transparent",
              }}>
              {t("candidates:candidateInfo.cv")}
            </div>
            <div
              onClick={() => {
                AppHistory.push(
                  "/recruiter/candidates/information/test-report/" + id
                );
              }}
              style={{
                ...styles,
                backgroundColor:
                  view === "test" ? palette.primary_opacity : "transparent",
              }}>
              {t("candidates:candidateInfo.tr")}
            </div>
            <div
              onClick={() => {
                AppHistory.push(
                  "/recruiter/candidates/information/video-interview/" + id
                );
              }}
              style={{
                ...styles,
                backgroundColor:
                  view === "video" ? palette.primary_opacity : "transparent",
              }}>
              {t("candidates:candidateInfo.vi")}
            </div>
            <div
              onClick={() => {
                AppHistory.push(
                  "/recruiter/candidates/information/about/" + id
                );
              }}
              style={{
                ...styles,
                backgroundColor:
                  view === "about" ? palette.primary_opacity : "transparent",
              }}>
              {t("candidates:candidateInfo.ac")}
            </div>
          </div>
        </div>
      );
    }
  }
);
