import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { ImgApproach } from "assets/img";
import { isMobile } from "react-device-detect";

export default class Approach extends React.Component {
  render() {
    if (isMobile) {
      return (
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          direction="column"
          style={{
            backgroundColor: palette.bg,
            minHeight: 100,
            padding: "40px 25px",
          }}>
          <Typography
            style={{
              marginBottom: 20,
              color: "white",
              fontWeight: "bold",
              fontSize: 24,
            }}>
            Our Approach
          </Typography>
          <img
            src={ImgApproach}
            alt="Applicant"
            style={{ width: 233, height: 166, padding: "35px 0px" }}
          />
          <div
            style={{
              width: "100%",
              color: "white",
              fontSize: 16,
              marginBottom: 50,
            }}>
            <p>
              We still believe on the passion that people have, the capabilities
              and the working attitude to perform at the best, still powerful in
              predicting talent.
            </p>
            <p>
              The tests are scientific proven and serve in gamification with
              creative way, supported by technology. It contains of Interest
              (passion), Cognitive (capabilities), and Energy (working attitude)
              that we called HG ICE.
            </p>
          </div>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          style={{
            backgroundColor: palette.bg,
            height: 620,
          }}>
          <div
            style={{
              display: "flex",
              padding: "120px 0px",
              justifyContent: "space-around",
              width: "100%",
            }}>
            <div>
              <Typography
                style={{
                  marginBottom: 20,
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 32,
                }}>
                Our Approach
              </Typography>
              <div
                style={{
                  width: "100%",
                  maxWidth: 520,
                  color: "white",
                  fontSize: 20,
                  marginBottom: 50,
                }}>
                <p style={{ paddingBottom: 20 }}>
                  We still believe on the passion that people have, the
                  capabilities and the working attitude to perform at the best,
                  still powerful in predicting talent.
                </p>
                <p style={{ paddingTop: 20 }}>
                  The tests are scientific proven and serve in gamification with
                  creative way, supported by technology. It contains of Interest
                  (passion), Cognitive (capabilities), and Energy (working
                  attitude) that we called HG ICE.
                </p>
              </div>
            </div>
            <div>
              <img
                src={ImgApproach}
                alt="Applicant"
                style={{ width: 496, height: 353 }}
              />
            </div>
          </div>
        </Grid>
      );
    }
  }
}
