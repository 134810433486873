import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "./button";
import Timer from "./timer";
import Countdown from "./countdown";
import { palette } from "assets/css/main";

const ActionsWrapper = styled.div`
  bottom: 20px;
  display: flex;
  align-items: center;
`;

const Actions = ({
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  showReplayControls,
  replayVideoAutoplayAndLoopOff,
  useVideoInput,

  onTurnOnCamera,
  onTurnOffCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onPauseRecording,
  onResumeRecording,
  onStopReplaying,
  onConfirm,
  lastQuestion,
  onSubmitVideo,
  t,
}) => {
  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }
    // isReplayingVideo = true;
    if (isReplayingVideo) {
      return (
        <div
          style={{
            minWidth: 640,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}>
          <Button
            disabled={false}
            onClick={onStopReplaying}
            id="btn-retake"
            data-qa="start-retake"
            color={palette.primary}
            backgroundColor={"white"}>
            {t("video.retake")}
          </Button>
          <Button onClick={onSubmitVideo} data-qa="next-replaying">
            {lastQuestion ? t("video.finish") : t("video.next")}
          </Button>
        </div>
      );
    }

    if (isRecording) {
      // return <StopButton onClick={onStopRecording} data-qa='stop-recording' />
      return (
        <Button onClick={onStopRecording} data-qa="start-replaying">
          {t("video.stop")}
        </Button>
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <div>
          <div
            style={{
              border: "2px dashed white",
              width: 150,
              minHeight: 200,
              zIndex: 20,
              position: "absolute",
              top: "10%",
              borderRadius: "50%",
            }}></div>
          <Button
            onClick={onStartRecording}
            data-qa="start-replaying"
            color="white"
            backgroundColor={palette.primary}>
            {t("video.record")}
          </Button>
        </div>
      );
    }

    if (useVideoInput) {
      return (
        <Button onClick={onOpenVideoInput} data-qa="open-input">
          Upload a video
        </Button>
      );
    }

    return shouldUseVideoInput ? (
      <Button onClick={onOpenVideoInput} data-qa="open-input">
        Record a video
      </Button>
    ) : (
      <Button onClick={onTurnOnCamera} data-qa="turn-on-camera">
        Turn my camera ON
      </Button>
    );
  };

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} t={t} />}
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </div>
  );
};

Actions.propTypes = {
  isVideoInputSupported: PropTypes.bool,
  isInlineRecordingSupported: PropTypes.bool,
  thereWasAnError: PropTypes.bool,
  isRecording: PropTypes.bool,
  isCameraOn: PropTypes.bool,
  streamIsReady: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isRunningCountdown: PropTypes.bool,
  countdownTime: PropTypes.number,
  timeLimit: PropTypes.number,
  showReplayControls: PropTypes.bool,
  replayVideoAutoplayAndLoopOff: PropTypes.bool,
  isReplayingVideo: PropTypes.bool,
  useVideoInput: PropTypes.bool,

  onTurnOnCamera: PropTypes.func,
  onTurnOffCamera: PropTypes.func,
  onOpenVideoInput: PropTypes.func,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
  onPauseRecording: PropTypes.func,
  onResumeRecording: PropTypes.func,
  onStopReplaying: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default Actions;
