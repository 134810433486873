import React from "react";
import { withTranslation } from "react-i18next";
import { MyHelmet, ApplicantHeader, DialogueInfo } from "app/components";
import SideStep from "./SideStep";
import {
  AppHistory,
  getItem,
  RequestGet,
  RequestPut,
  SetLocalItem,
} from "app/utils";
import { Button, Grid, Menu, MenuItem, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import alertData from "app/components/alert/alert-data";
import {
  IconJobActionActive,
  IcnDownload,
  IconFolder,
  IconCompletecv,
} from "assets/img";
import data from "./data";
import "./custom.css";
import DialogueOfferingConfirm from "app/pages/applicant/dashboard/job-opening/OfferingConfirmation";
import DialogueConfirm from "app/components/modal/confirm";

const tableStyle = {
  width: "100%",
  border: "1px solid #AFBFE5",
  borderCollapse: "inherit",
  borderSpacing: "0px",
  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)",
  boxSizing: "border-box",
};

const thStyle = {
  fontWeight: "normal",
  fontSize: "18px",
  color: "rgba(0, 0, 0, 0.4)",
  padding: "16px 24px",
  textAlign: "left",
};

const tdStyle = {
  fontWeight: "normal",
  fontSize: "18px",
  color: palette.primary,
  padding: "16px 24px",
  borderTop: "1px solid #AFBFE5",
};

const buttonStyle = {
  textTransform: "capitalize",
  border: "1px solid " + palette.primary,
  color: palette.primary,
  margin: "0px 10px",
  padding: "5px 30px",
  fontWeight: "bold",
  boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
};

class ApplicantJobOpening extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobList: data.jobList,
      anchorEl: null,
      interviewId: null,
      imageShow: null,
      offering: null,
      openOfferingConfirm: false,
      vacancyId: null,
      openOfferingConfirmRes: false,
      resOfferingConfirm: "",
      isAccept: false,
      interviewDetailId: null,
      openDecline: false,
      declineId: null,
      percentage: 0,
    };
  }

  handleMenu(idx, v) {
    var newAnchorEl = this.state.anchorEl;
    newAnchorEl[idx] = v;
    this.setState({ anchorEl: newAnchorEl });
  }

  handleDetail(id) {
    SetLocalItem("InterviewID", id);
    AppHistory.push("/user/job-opening/detail");
  }

  getJobOpen() {
    RequestGet("JobOpen")
      .then(res => {
        this.setState({ jobList: res.data });
        data.jobList = res.data;
      })
      .catch(err => {
        alertData.show(String(err.status), "error");
      });

    RequestGet("UserProfile", { loading: false })
      .then(r => {
        this.setState({ percentage: r.data.percentage });
      })
      .catch(e => {
        console.log(e);
      });
  }

  putDecline(id) {
    var data = {
      interviewId: id,
      isDecline: true,
    };
    RequestPut("Interview/Decline", data)
      .then(() => {
        alertData.show("Decline success");
        // window.location.href = "/user/job-opening";
        setTimeout(() => {
          this.setState({ anchorEl: null });
          this.setState({ imageShow: null });
          this.getJobOpen();
        }, 1000);
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  componentDidMount() {
    data.setEmptyJobDetail();
    this.getJobOpen();
  }

  OfferingConfirm(vId) {
    this.setState({
      vacancyId: vId,
      openOfferingConfirm: true,
    });
  }

  clikOkOfferingConfirmRes() {
    this.getJobOpen();
    this.setState({ openOfferingConfirmRes: false });
  }

  render() {
    const { t } = this.props;
    const {
      jobList,
      anchorEl,
      interviewId,
      offering,
      openOfferingConfirm,
      vacancyId,
      openOfferingConfirmRes,
      resOfferingConfirm,
      isAccept,
      interviewDetailId,
      openDecline,
      declineId,
      percentage,
    } = this.state;
    return (
      <div>
        <MyHelmet title={t("link.jobOpening")} />
        <ApplicantHeader menu="job-opening" />

        {/* Offering Confirmation Dialog */}
        <DialogueOfferingConfirm
          vacId={vacancyId}
          open={openOfferingConfirm}
          onClose={(reload, res) => {
            if (reload) {
              this.setState({
                openOfferingConfirm: false,
                openOfferingConfirmRes: true,
                resOfferingConfirm: res,
              });
            } else {
              this.setState({
                openOfferingConfirm: false,
              });
            }
          }}
        />

        {/* Offering Dialog */}
        <DialogueInfo
          title={
            resOfferingConfirm === "Accept"
              ? t("job-opening:OfferingConfirmationResDialog.titleAccept")
              : resOfferingConfirm === "Accept"
              ? t("job-opening:OfferingConfirmationResDialog.titleDeclined")
              : t("job-opening:OfferingConfirmationResDialog.titleDiscussion")
          }
          content={
            resOfferingConfirm === "Accept"
              ? t("job-opening:OfferingConfirmationResDialog.contentAccept")
              : resOfferingConfirm === "Accept"
              ? t("job-opening:OfferingConfirmationResDialog.contentDeclined")
              : t("job-opening:OfferingConfirmationResDialog.contentDiscussion")
          }
          open={openOfferingConfirmRes}
          onClose={() => {
            this.clikOkOfferingConfirmRes();
          }}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            paddingTop: "100px",
            flexDirection: "row",
            paddingBottom: 100,
          }}>
          <Grid container>
            <Grid item lg={3} md={3} sm={3}>
              <SideStep head="JobList" onChange={v => AppHistory.push(v)} />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              <Grid
                container
                style={{
                  padding: "24px",
                }}>
                <Grid item lg={12} md={12} sm={12}>
                  <Typography
                    style={{
                      color: palette.primary,
                      fontSize: "24px",
                    }}>
                    {t("job-opening:jobList.title")}
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
                  {percentage < 100 && (
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      style={{
                        textAlign: "center",
                        marginTop: "10%",
                      }}>
                      <div>
                        <img src={IconCompletecv} alt="Folder" />
                      </div>
                      <div
                        style={{
                          width: "34.5%",
                          textAlign: "center",
                          marginTop: "3%",
                          fontWeight: "lighter",
                        }}>
                        <label style={{ color: palette.primary }}>
                          {t("job-opening:jobList.table.completecv")}
                        </label>
                      </div>
                      <Button
                        onClick={() => {
                          AppHistory.push("/user/home");
                        }}
                        style={{
                          ...buttonStyle,
                          color: "white",
                          marginTop: 20,
                          backgroundColor: palette.primary,
                        }}>
                        {t("link.aboutYou")}
                      </Button>
                    </Grid>
                  )}
                  {!(jobList.length === 0) && percentage === 100 && (
                    <table style={tableStyle}>
                      <tbody>
                        <tr
                          style={{
                            width: "100%",
                            border: "1px solid #AFBFE5",
                          }}>
                          <th style={thStyle}>
                            {t("job-opening:jobList.table.title")}
                          </th>
                          <th style={thStyle}>
                            {t("job-opening:jobList.table.status")}
                          </th>
                          <th style={thStyle}>
                            {t("job-opening:jobList.table.company")}
                          </th>
                          <th style={thStyle}>
                            {t("job-opening:jobList.table.location")}
                          </th>
                          <th style={thStyle}>
                            {t("job-opening:jobList.table.hiring")}
                          </th>
                          <th
                            style={{ ...thStyle, textAlign: "center" }}
                            align="center"
                            valign="middle">
                            {t("job-opening:jobList.table.offering")}
                          </th>
                          <th style={thStyle}>
                            {t("job-opening:jobList.table.action")}
                          </th>
                        </tr>
                      </tbody>
                      {jobList.map((v, i) => {
                        return (
                          <tbody key={i}>
                            <tr
                              style={
                                i % 2 === 0
                                  ? {
                                      width: "100%",
                                      background:
                                        "linear-gradient(0deg, rgba(223, 229, 244, 0.2), rgba(223, 229, 244, 0.2)), linear-gradient(0deg, rgba(223, 229, 244, 0.2), rgba(223, 229, 244, 0.2)), rgba(223, 229, 244, 0.2)",
                                    }
                                  : {
                                      width: "100%",
                                    }
                              }>
                              <td style={tdStyle}>{v.jobTitle}</td>
                              <td style={tdStyle}>
                                <Typography
                                  style={
                                    v.isActive
                                      ? {
                                          background: "#CEE8F8",
                                          borderRadius: "20px",
                                          padding: "6px 1px 3px 1px",
                                          color: "#2E69B2",
                                          fontSize: "12px",
                                          textAlign: "center",
                                        }
                                      : {
                                          background: "#DBDFE8",
                                          borderRadius: "20px",
                                          padding: "6px 1px 3px 1px",
                                          color: "#515D6E",
                                          fontSize: "12px",
                                          textAlign: "center",
                                        }
                                  }>
                                  {v.isActive
                                    ? t("job-opening:jobList.active")
                                    : t("job-opening:jobList.inactive")}
                                </Typography>
                              </td>
                              <td style={tdStyle}>{v.company}</td>
                              <td style={tdStyle}>{v.location}</td>
                              <td style={tdStyle}>
                                <Typography
                                  style={{
                                    background: "rgba(255, 176, 49, 0.3)",
                                    borderRadius: "20px",
                                    padding: "6px 1px 3px 1px",
                                    color: "#CD594D",
                                    fontSize: "12px",
                                    textAlign: "center",
                                  }}>
                                  {getItem("lang") === "en"
                                    ? v.statusEng
                                    : v.statusIna}
                                </Typography>
                              </td>
                              <td
                                style={tdStyle}
                                align="center"
                                valign="middle">
                                <a
                                  style={{
                                    display:
                                      v.offeringLetter !== null ||
                                      v.offeringLetter === ""
                                        ? "unset"
                                        : "none",
                                  }}
                                  target="__blank"
                                  href={v.offeringLetter}>
                                  <img
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    src={IcnDownload}
                                    alt={"icon-download" + i}
                                    align="center"
                                  />
                                </a>
                              </td>
                              <td style={tdStyle}>
                                <Button
                                  style={{ width: "50%" }}
                                  onClick={e => {
                                    this.setState({
                                      anchorEl: e.target,
                                      interviewId: v.interviewId,
                                      imageShow: i,
                                      offering: v.offeringLetter,
                                      vacancyId: v.vacancyId,
                                      isAccept: v.isAccept,
                                      interviewDetailId: v.interviewDetailId,
                                    });
                                  }}>
                                  <img
                                    src={IconJobActionActive}
                                    alt={"action" + i}
                                    height="5vh"
                                    align="center"
                                  />
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  )}
                  {jobList.length === 0 && percentage === 100 && (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "10%",
                      }}>
                      <div>
                        <img src={IconFolder} alt="Folder" />
                      </div>
                      <div>
                        <label
                          style={{
                            color: palette.primary,
                            fontWeight: "lighter",
                          }}>
                          {t("job-opening:jobList.table.emptylist")}
                        </label>
                      </div>
                    </div>
                  )}

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() =>
                      this.setState({
                        anchorEl: null,
                        interviewId: null,
                        imageShow: null,
                      })
                    }>
                    {offering !== null && (
                      <div>
                        {isAccept === null && (
                          <MenuItem
                            style={{
                              backgroundColor: "white",
                              fontWeight: "normal",
                              color: palette.primary,
                            }}
                            onClick={() => {
                              this.setState({
                                anchorEl: null,
                                imageShow: null,
                              });
                              this.OfferingConfirm(vacancyId);
                            }}>
                            {t(
                              "job-opening:jobList.actionOfferingConfirmation"
                            )}
                          </MenuItem>
                        )}
                      </div>
                    )}
                    <MenuItem
                      style={{
                        backgroundColor: "white",
                        color: palette.primary,
                      }}
                      onClick={() => {
                        this.setState({
                          anchorEl: null,
                          imageShow: null,
                        });
                        this.handleDetail(interviewId);
                      }}>
                      {t("job-opening:jobList.action")}
                    </MenuItem>
                    {!interviewDetailId && (
                      <MenuItem
                        style={{
                          backgroundColor: "white",
                          color: "#FF4F5B",
                        }}
                        onClick={() => {
                          this.setState({ declineId: interviewId });
                          this.setState({ openDecline: true });
                        }}>
                        {t("job-opening:jobList.decline")}
                      </MenuItem>
                    )}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* Decline Confirmation */}
        <DialogueConfirm
          open={openDecline}
          title={t("job-opening:jobList.declineConfirmation.title")}
          content={t("job-opening:jobList.declineConfirmation.question")}
          btnYes={"Ok"}
          btnNo={t("job-opening:jobList.declineConfirmation.cancel")}
          onOK={() => {
            this.putDecline(declineId);
            this.setState({ openDecline: false });
          }}
          onClose={() => this.setState({ openDecline: false })}
        />
      </div>
    );
  }
}
export default withTranslation()(ApplicantJobOpening);
