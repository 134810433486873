import React from "react";
import { FullCalendar, MyHelmet, RecruiterHeader } from "app/components";

import { withTranslation } from "react-i18next";
import { palette, REC_STYLE } from "assets/css/main";
import ButtonPrev from "app/components/general/ButtonPrev";
import {
  AppHistory,
  findObject,
  getItem,
  removeObject,
  RequestGet,
} from "app/utils";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/id";
import { ImgEmpty } from "assets/img";
import { Avatar } from "@material-ui/core";
moment.locale(getItem("lang"));
export default withTranslation("calendar-schedule")(
  class SchedulCalendar extends React.Component {
    state = {
      data: { scheduleArchive: [], scheduleActive: [] },
      activeTab: 0,
      open: false,
      events: [],
      selected: {},
    };
    componentDidMount() {
      this.getCalendarEvent();
      this.getVacancy();
      window.scrollTo(0, 0);
    }
    handleChange(v) {
      this.setState({ activeTab: v });
    }
    getVacancy() {
      const {
        match: { params },
      } = this.props;
      RequestGet("interview", {
        loading: true,
        vacancyId: params.id_vacancy,
      })
        .then(r => {
          this.setState({ data: r.data });
        })
        .catch(c => {
          console.log("getVacancy()", c);
        });
    }
    getCalendarEvent() {
      const { t } = this.props;
      RequestGet("calendar/recruiter")
        .then(r => {
          var tmp = [];
          var key = "";
          var tmp2 = null;
          r.data.map(v => {
            key = moment(v.timeStart).format("DD-MM-yyyy").toString();
            tmp2 = findObject(tmp, "key", key);
            if (tmp2) {
              removeObject(tmp, "key", key);
              tmp2.detail.push({
                interviewTitle: v.interviewTitle,
                name: v.name,
                photo: v.photo,
                timeStart: v.timeStart,
                timeEnd: v.timeEnd,
                timezone: v.timezone,
              });
              tmp2.title = tmp2.detail.length + " " + t("interview");
              //sorting detail candidate time
              tmp2.detail.sort(function (a, b) {
                return new Date(a.timeStart) - new Date(b.timeStart);
              });
              tmp.push(tmp2);
            } else {
              tmp.push({
                key: key,
                interviewTitle: v.interviewTitle,
                start: moment(v.timeStart).toDate(),
                end: moment(v.timeEnd).toDate(),
                title: "1 " + t("interview"),
                detail: [
                  {
                    interviewTitle: v.interviewTitle,
                    name: v.name,
                    photo: v.photo,
                    timeStart: v.timeStart,
                    timeEnd: v.timeEnd,
                    timezone: v.timezone,
                  },
                ],
              });
            }
            return {};
          });
          return tmp;
        })
        .then(d => {
          this.setState({ events: d });
        })
        .catch(c => {
          console.log("getCalendarEvent()", c);
        });
    }

    render() {
      const {
        t,
        match: { params },
      } = this.props;
      const { data, events, selected } = this.state;
      moment().locale("en");
      return (
        <div>
          <MyHelmet title="Calendar Schedule" />
          <RecruiterHeader shadow={true} menu="dashboard" />

          <div
            style={{
              ...REC_STYLE.BODY_STYLE,
            }}>
            <div
              style={{
                ...REC_STYLE.SUB_HEADER,
                padding: "0px 100px",
                paddingTop: 10,
                zIndex: 100,
              }}>
              <div style={{ width: "100%", color: palette.primary }}>
                <ButtonPrev
                  style={{ padding: "0px 0px" }}
                  fontSize={14}
                  iconSize={{ fontSize: 14 }}
                  text={t("button.backCandidate")}
                  color={palette.primary}
                  onClick={() => {
                    AppHistory.push(
                      "/recruiter/viewcandidatelist/" + params.id_vacancy,
                      {
                        status: "INP",
                      }
                    );
                  }}
                />
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                    margin: "5px 0px",
                  }}>
                  {t("title")}
                </p>
                <p
                  style={{
                    fontSize: 18,
                    margin: "5px 0px",
                    padding: "5px 0px 20px",
                  }}>
                  {data.vacancyName}
                </p>
              </div>
            </div>
            <div style={{ padding: "140px 100px 0px", display: "flex" }}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "40px",
                  borderRadius: 4,
                  width: "65%",
                }}>
                <FullCalendar
                  onSelected={obj => {
                    this.setState({ selected: obj });
                  }}
                  events={events}
                />
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 4,
                  marginLeft: 20,
                  width: "35%",
                }}>
                <div
                  style={{
                    width: "100%",
                    padding: "40px",
                    boxSizing: "border-box",
                  }}>
                  <div style={{ opacity: 0.5 }}>{t("ondate")}</div>
                  <div
                    style={{
                      borderBottom: "1px solid #AFBFE5",
                      padding: "10px 0px 20px",
                      color: palette.primary,
                    }}>
                    {moment(selected.start).format("DD MMMM yyyy")}
                  </div>
                  {selected.start ? (
                    selected.detail.map((v, k) => {
                      return (
                        <div
                          key={k}
                          style={{
                            display: "flex",
                            margin: "20px 0px 5px",
                            color: palette.primary,
                          }}>
                          <Avatar
                            src={v.photo}
                            style={{ width: 47, height: 47 }}
                          />
                          <div style={{ marginLeft: 20 }}>
                            <p
                              style={{
                                margin: "0px 0px 5px",
                                textTransform: "capitalize",
                              }}>
                              {v.name}
                            </p>
                            <p
                              style={{
                                margin: "0px 0px 5px",
                                textTransform: "capitalize",
                                fontSize: 14,
                              }}>
                              {v.interviewTitle}
                            </p>
                            <p
                              style={{
                                margin: "0px 0px 5px",
                                fontWeight: "lighter",
                              }}>
                              {moment(v.timeStart).format("HH:mm") +
                                " - " +
                                moment(v.timeEnd).format("HH:mm") +
                                " " +
                                v.timezone}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        color: palette.primary,
                        paddingTop: 150,
                      }}>
                      <img src={ImgEmpty} width={98} alt="Empty" />
                      <p
                        style={{
                          fontWeight: "lighter",
                          width: 200,
                          textAlign: "center",
                        }}>
                        {t("nodata")}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
