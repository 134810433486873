import React from "react";
import { palette } from "assets/css/main";
import { getItem, RequestGet, RequestPost } from "app/utils";
import { withTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MenuItem } from "@material-ui/core";
import { CheckCircle, Error } from "@material-ui/icons";
import { ProfilePicture } from "app/components";
import data from "app/pages/recruiter/data";

export default withTranslation()(
  class EditSidePanel extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        companyProfile: props.data,
        ...JSON.parse(getItem("user_info")),
        anchorEl: null,
        isAdditionalComplete: false,
        isCompanyComplete: false,
        isProfileComplete: false,
        percentage: 0,
      };
      this.handleClick = this.handleClick.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
      this.getRecruiterProfile();
    }

    handleChange(obj) {
      this.setState(obj);
      data.setCompanyProfile(obj);

      var companyProfile = {
        companyProfile: { ...this.state.companyProfile, ...obj },
      };
      this.setState({
        ...this.state,
        ...companyProfile,
      });
      console.log(this.state);
    }

    handleUploadFile(file) {
      var form = new FormData();
      form.append(
        "file",
        file.file,
        getItem("user_id") + "-companyLogo." + file.extension
      );
      RequestPost("CompanyProfile/upload", form)
        .then(r => {
          const { imageUrl } = r.data;
          try {
            document.getElementById("preview-img").blur();
            document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
          } catch (error) {
            console.log(error);
          }

          this.handleChange({
            companyLogo: imageUrl + "?ts=" + new Date().getTime(),
          });
        })
        .catch(e => {
          console.log("handleUploadImage()", e);
        });
    }

    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    getRecruiterProfile() {
      RequestGet("RecruiterProfile", { loading: false })
        .then(r => {
          this.setState(r.data);
        })
        .catch(e => {
          console.log(e);
        });
    }

    render() {
      const { t } = this.props;
      const { companyProfile } = this.props.data;
      var {
        isAdditionalComplete,
        isCompanyComplete,
        isProfileComplete,
        percentage,
      } = this.state;

      if (this.props.profile) {
        isAdditionalComplete = this.props.profile.isAdditionalComplete;
        isCompanyComplete = this.props.profile.isCompanyComplete;
        isProfileComplete = this.props.profile.isProfileComplete;
        percentage = this.props.profile.percentage;
      }

      return (
        <div
          style={{
            padding: 20,
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
          }}>
          <ProfilePicture
            edit={this.props.edit}
            value={
              this.state.companyLogo
                ? this.state.companyLogo
                : companyProfile.companyLogo
            }
            onSelectFile={file => {
              this.handleUploadFile(file);
            }}
          />
          {/* <div
            style={{
              backgroundColor: "transparent",
              width: 165,
              height: 165,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              border: "1px solid " + palette.primary,
            }}>
            <div
              style={{
                backgroundColor: palette.grey,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: companyProfile.companyLogo
                  ? "url(" + companyProfile.companyLogo + ")"
                  : "",
                width: 160,
                height: 160,
                borderRadius: "50%",
              }}></div>
          </div> */}
          <div
            style={{
              display: companyProfile.companyNameBrand ? "" : "none",
              borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
              width: "100%",
            }}>
            <p
              style={{
                textAlign: "center",
                fontSize: 24,
                marginBottom: 15,
                color: palette.primary,
                fontWeight: "bolder",
              }}>
              {companyProfile.companyNameBrand}
              <br />
            </p>
          </div>
          <div style={{ marginTop: "8%", width: "100%", display: "flex" }}>
            <div style={{ width: "40%", marginLeft: "11%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  height: 70,
                }}>
                <CircularProgress
                  style={{
                    color: "#52C8F4",
                    width: 65,
                    height: 65,
                    position: "absolute",
                  }}
                  variant="static"
                  value={percentage}
                  thickness={5}
                />
                <CircularProgress
                  style={{
                    width: 65,
                    height: 65,
                    color: palette.secondary,
                    opacity: 0.5,
                    position: "absolute",
                  }}
                  variant="static"
                  value={100}
                  thickness={5}
                />
                <div
                  style={{
                    fontSize: 14,
                    color: palette.secondary,
                    position: "absolute",
                  }}>
                  {percentage + "%"}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    color: palette.secondary,
                    position: "absolute",
                    left: 100,
                  }}>
                  {t("profilesetup:dataCompleteness")}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              marginTop: "10%",
              alignItems: "left",
              padding: "0px 20px",
            }}>
            <MenuItem
              onClick={() => {
                this.props.onChange("profile");
              }}
              style={{ width: "100%", display: "flex", height: 40 }}>
              <div
                style={{
                  color: isProfileComplete ? palette.secondary : "#FF8D14",
                  display: "flex",
                  alignItems: "center",
                }}>
                {isProfileComplete ? (
                  <CheckCircle style={{ fontSize: "2.0rem" }} />
                ) : (
                  <Error style={{ fontSize: "2.0rem" }} />
                )}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: 16,
                  color: palette.primary,
                  fontWeight: "bolder",
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 10px",
                }}>
                {t("recruiterSetup", { returnObjects: true })[0]}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.props.onChange("company");
              }}
              style={{ width: "106%", display: "flex", height: 40 }}>
              <div
                style={{
                  color: isCompanyComplete ? palette.secondary : "#FF8D14",
                  display: "flex",
                  alignItems: "center",
                }}>
                {isCompanyComplete ? (
                  <CheckCircle style={{ fontSize: "2.0rem" }} />
                ) : (
                  <Error style={{ fontSize: "2.0rem" }} />
                )}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: 16,
                  color: palette.primary,
                  fontWeight: "bolder",
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 10px",
                }}>
                {t("recruiterSetup", { returnObjects: true })[1]}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.props.onChange("additional");
              }}
              style={{ width: "106%", display: "flex", height: 40 }}>
              <div
                style={{
                  color: isAdditionalComplete ? palette.secondary : "#FF8D14",
                  display: "flex",
                  alignItems: "center",
                }}>
                {isAdditionalComplete ? (
                  <CheckCircle style={{ fontSize: "2.0rem" }} />
                ) : (
                  <Error style={{ fontSize: "2.0rem" }} />
                )}
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: 16,
                  color: palette.primary,
                  fontWeight: "bolder",
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 10px",
                }}>
                {t("recruiterSetup", { returnObjects: true })[2]}
              </div>
            </MenuItem>
          </div>
        </div>
      );
    }
  }
);
