import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";

import {
  IconUserAddress,
  IconUserTelephone,
  IconUserEnvelope,
  IconUserEgg,
  IconUserStatus,
} from "assets/img";
import { getItem, findWithAttr } from "app/utils";
import {
  PersonalInfoItem,
  WorkExperienceView,
  FormalEducationView,
} from "./cv-component";
class ViewOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personalInfo: props.data.personalInfo,
      listWorks: props.data.listWorks,
      listFormalEducation: props.data.listFormalEducation,
      listInformalEducation: props.data.listInformalEducation,
      listOrganization: props.data.listOrganization,
    };
  }
  render() {
    const { personalInfo, email, listWorks, listFormalEducation } = this.state;
    const { t } = this.props;
    const maritalStatus = t("biodata:options.maritalStatus", {
      returnObjects: true,
    });
    const jobStatus = t("biodata:options.jobStatus", {
      returnObjects: true,
    });
    return (
      <div
        id="cv-doc"
        style={{
          width: "100%",
          backgroundColor: palette.grey,
          display: "flex",
          flexDirection: "row",
        }}>
        <div
          style={{
            height: "inherit",
            width: "30%",
            padding: "40px 40px",
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: palette.info,
            flexDirection: "column",
            alignItems: "center",
          }}>
          <div
            style={{
              backgroundColor: "transparent",
              width: 165,
              height: 165,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              marginBottom: 60,
              border: "1px solid " + palette.primary,
            }}>
            <div
              style={{
                backgroundColor: palette.grey,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: personalInfo.photo
                  ? "url(data:image/png;base64," + personalInfo.photo + ")"
                  : "",
                width: 155,
                height: 155,
                borderRadius: "50%",
              }}></div>
          </div>
          <PersonalInfoItem
            text={personalInfo.address}
            image={IconUserAddress}
            alt="test"
          />
          <PersonalInfoItem
            text={
              personalInfo.phoneNumber ? "0" + personalInfo.phoneNumber : ""
            }
            image={IconUserTelephone}
            alt="aa"
          />
          <PersonalInfoItem text={email} image={IconUserEnvelope} alt="email" />
          <PersonalInfoItem
            text={personalInfo.birthPlace}
            date={personalInfo.birthDate}
            image={IconUserEgg}
            alt="birthdate"
          />
          <PersonalInfoItem
            text={findWithAttr(
              maritalStatus,
              "value",
              personalInfo.maritalStatus,
              "label"
            )}
            isLast={true}
            image={IconUserStatus}
            alt="maritalStatus"
          />
        </div>

        <div
          style={{
            height: "100%",
            width: "70%",
            paddingRight: 40,
            paddingLeft: 40,
            backgroundColor: palette.grey,
          }}>
          {/* start name */}
          <div style={{ display: personalInfo.name ? "" : "none" }}>
            <p
              style={{
                fontSize: 36,
                textTransform: "uppercase",
                marginBottom: 15,
              }}>
              {personalInfo.name}
              <br />
            </p>
            <p
              style={{
                fontSize: 24,
                margin: 0,
                textTransform: "uppercase",
                marginBottom: 15,
              }}>
              ID : {getItem("user_id") ? getItem("user_id").split("-")[0] : ""}
            </p>
            <p
              style={{
                fontSize: 14,
                margin: 0,
                textTransform: "uppercase",
                marginBottom: 0,
                background: palette.info,
                width: "120px",
                textAlign: "center",
                padding: "2px 5px",
                borderRadius: 20,
              }}>
              {t("hgkode")} :{" "}
              {personalInfo.id ? getItem("user_id").split("-")[1] : ""}
            </p>
          </div>
          {/* end name */}

          {/* start work-experiences */}
          <WorkExperienceView data={listWorks} t={t} jobStatus={jobStatus} />
          {/* end work-experiences */}

          {/* start formal-educations */}
          <FormalEducationView data={listFormalEducation} t={t} />
          {/* end formal-educations */}
        </div>
      </div>
    );
  }
}
export default withTranslation()(ViewOne);
