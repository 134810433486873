import React from "react";
// import { logError } from "app/utils/general/rollbar";

export default function Catch(component, errorHandler) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: undefined,
      };
    }
    static getDerivedStateFromError(error) {
      return { error };
    }
    componentDidCatch(error, info) {
      if (errorHandler) {
        errorHandler(error, info);
        // logError(
        //   "Error: " + JSON.stringify(error) + " : " + JSON.stringify(info)
        // );
      }
    }
    render() {
      const { error } = this.state;
      return component(this.props, error);
    }
  };
}
