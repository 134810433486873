import React from "react";
import { clearAll } from "app/utils";
export default class Logout extends React.Component {
  constructor(props) {
    super(props);
    clearAll();
    this.props.history.push("");
  }
  render() {
    return <div></div>;
  }
}
