import React from "react";
import { withTranslation } from "react-i18next";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette, PRIMARY_BUTTON } from "assets/css/main";
import { Button } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { AUTH_API, RequestPost } from "app/utils";
import { DialogueInfo } from "app/components";
import { ImgUnderConstruction } from "assets/img";

export default withTranslation()(
  class UnderConstruction extends React.Component {
    state = { name: "", email: "", message: "", subject: "", open: false };
    constructor(props) {
      super(props);
      this.submitForm = this.submitContact.bind(this);
    }
    submitContact() {
      const { name, email, message, subject } = this.state;
      var data = {
        name: name,
        email: email,
        message: message,
        subject: "Recruiter Contact -" + subject,
      };
      RequestPost(AUTH_API + "connect/contactus", data)
        .then(res => {
          this.setState({
            name: "",
            email: "",
            message: "",
            subject: "",
            open: true,
            result: res,
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
    render() {
      const { t } = this.props;
      const content = t("underConstruction.content", { returnObjects: true });
      return (
        <div>
          <DialogueInfo
            title={t("contact:successMessage.title")}
            content={t("contact:successMessage.content")}
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
          />
          <RecruiterAppBar
            hideMenu={true}
            hideUserMenu={true}
            shadow={true}
            menu="dashboard"
            history={this.props.history}
          />
          <div
            style={{
              padding: "150px 100px",
              display: "flex",
              position: "relative",
              justifyContent: "space-between",
              alignItems: "flex-start",
              color: palette.primary,
            }}>
            <div style={{ maxWidth: 674 }}>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: 32,
                  width: 674,
                  margin: "0px 0px 40px",
                }}>
                {t("underConstruction.title")}
              </p>
              {content.map((v, i) => {
                return (
                  <p style={{ maxWidth: 602, textAlign: "justify" }} key={i}>
                    {v}
                  </p>
                );
              })}
            </div>
            <img
              src={ImgUnderConstruction}
              style={{
                zIndex: -1,
                position: "absolute",
                opacity: 0.1,
                width: 540,
                left: "30%",
                top: "42.11%",
              }}
              alt="Under-construction"
            />
            <div
              style={{
                width: 560,
                padding: "20px 40px",
                backgroundColor: "white",
                boxShadow: "rgb(216 216 216) 0px 0px 5px 0px",
              }}>
              <ValidatorForm
                onSubmit={this.submitForm}
                className="contact-recruiter">
                <TextValidator
                  fullWidth
                  label={t("contact:form.label.name")}
                  onChange={e => {
                    this.setState({ name: e.target.value });
                  }}
                  name="name"
                  type="text"
                  value={this.state.name}
                  style={{ marginTop: 20 }}
                  autoComplete="name"
                  className="Field"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("contact:form.placeholder.RecruiterName")}
                  validators={["required"]}
                  errorMessages={[t("validation.required.name")]}
                />
                <TextValidator
                  label={t("contact:form.label.email")}
                  fullWidth
                  onChange={e => {
                    this.setState({ email: e.target.value });
                  }}
                  name="email"
                  value={this.state.email}
                  style={{ marginTop: 20 }}
                  className="Field"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("contact:form.placeholder.RecruiterEmail")}
                  autoComplete={"current-email"}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    t("validation.required.email"),
                    t("validation.valid.email"),
                  ]}
                />
                <TextValidator
                  fullWidth
                  label={t("contact:form.label.subject")}
                  onChange={e => {
                    this.setState({ subject: e.target.value });
                  }}
                  name="subject"
                  type="text"
                  value={this.state.subject}
                  style={{ marginTop: 20 }}
                  autoComplete="subject"
                  className="Field"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("contact:form.placeholder.RecruiterSubject")}
                  validators={["required"]}
                  errorMessages={[t("validation.required.subject")]}
                />
                <TextValidator
                  label={t("contact:form.label.message")}
                  fullWidth
                  onChange={e => {
                    this.setState({ message: e.target.value });
                  }}
                  name="message"
                  type="message"
                  className="Field"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("contact:form.placeholder.RecruiterMessage")}
                  value={this.state.message}
                  style={{ marginTop: 20 }}
                  multiline={true}
                  rows={5}
                  rowsMax={10}
                  autoComplete={"current-message"}
                  validators={["required"]}
                  errorMessages={[t("validation.required.message")]}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type={"submit"}
                    variant="contained"
                    size="large"
                    style={{ ...PRIMARY_BUTTON }}>
                    {t("contact:form.button.submit")}
                  </Button>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </div>
      );
    }
  }
);
