import React from "react";
import { observer } from "mobx-react";
import { Button, Grid } from "@material-ui/core";
import { palette, PRIMARY_BUTTON } from "assets/css/main";
//import { NextBtn } from "app/components";

export const CandidateListFooter = observer(props => {
  const {
    text,
    countcandidatechecked,
    countselectablecandidate,
    countselectablecandidatequota,
    isEnable,
    stat,
  } = props;
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      style={{
        height: 84,
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: "white",
        padding: "0px 50px",
        zIndex: 10,
        display: "flex",
        justifyContent: "space-between",
        boxShadow: "rgba(0, 0, 0, 0.09) 0px -2px 2px",
        borderRadius: 4,
        width: "100vw",
        boxSizing: "border-box",
      }}>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        {stat === "WIS" && (
          <>
            <div style={{ width: "3%" }}></div>
            <div
              style={{
                width: "47%",
                textAlign: "right",
                //backgroundColor: "rgba(60, 186, 108, 0.3)",
                backgroundColor: "white",
                height: 85,
                display: "flex",
                flexDirection: "row",
              }}>
              <div
                style={{
                  width: "70%",
                  color: "#3CBA6C",
                  fontWeight: "normal",
                  fontSize: 14,
                  textAlign: "left",
                  marginTop: 30,
                  marginLeft: 80,
                }}>
                {/* You do not have candidate quota to move to the selected list,
                please upgrade
                <br />
                your account */}
              </div>
              <div
                style={{
                  width: "30%",
                  textAlign: "right",
                }}>
                {/* <Button
                  //onClick={() => props.NextClick()}
                  size="small"
                  variant="contained"
                  style={{
                    ...PRIMARY_BUTTON,
                    fontWeight: "bold",
                    right: 20,
                    color: "white",
                    height: 44,
                    fontSize: 14,
                    width: 135,
                    marginTop: 21,
                  }}>
                  {"Upgrade"}
                </Button> */}
              </div>
            </div>
          </>
        )}
        {stat !== "WIS" && (
          <div style={{ width: "50%", textAlign: "right", height: 85 }}></div>
        )}
        <div style={{ width: "25%" }}>
          <div style={{ height: 25 }}></div>
          <div
            style={{
              color: palette.primary,
              fontWeight: "normal",
              fontSize: 14,
              marginLeft: 90,
            }}>
            Total Candidates Selected : {countcandidatechecked}
          </div>
          {stat !== "WIS" && (
            <div
              style={{
                color: "rgba(0, 0, 0, 0.4)",
                fontWeight: "normal",
                fontSize: 14,
                marginLeft: 90,
              }}>
              Selectable Candidates :{" "}
              {countselectablecandidate - countcandidatechecked}
            </div>
          )}
          {stat === "WIS" && (
            <div
              style={{
                color: "rgba(0, 0, 0, 0.4)",
                fontWeight: "normal",
                fontSize: 14,
                marginLeft: 90,
              }}>
              Selectable Candidates Quota :{" "}
              {countselectablecandidatequota - countcandidatechecked}
            </div>
          )}
        </div>
        <div style={{ width: "10%", textAlign: "right" }}></div>
        <div style={{ width: "15%", textAlign: "right" }}>
          <div style={{ height: 25 }}></div>
          <Button
            disabled={!isEnable}
            onClick={() => props.NextClick()}
            size="small"
            variant="contained"
            style={{
              ...PRIMARY_BUTTON,
              fontWeight: "bold",
              right: 48,
              color: "white",
              height: 44,
              fontSize: 14,
              width: 223,
              opacity: isEnable ? 1 : 0.5,
              backgroundColor: isEnable ? palette.primary : "",
            }}>
            {text}
          </Button>
        </div>
      </div>
    </Grid>
  );
});
