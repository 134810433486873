import { observable, decorate, action } from "mobx";
// import {
//   IconPopup1,
//   IconPopup2,
//   IconPopup3,
//   IconPopup4,
//   IconPopup5,
//   IconPopup6,
//   IconPopup7,
//   IconPopup8,
//   IconPopup9,
// } from "assets/img";

class EditCvContainer {
  tell_us = {
    firstTime: true,
    tellUsApi: [],
  }

  listTellUs = [
    // {
    //   number: IconPopup1,
    //   idOptions: [],
    //   options: []
    // },
    // {
    //   number: IconPopup2,
    //   idOptions: [],
    //   options: []
    // },
    // {
    //   number: IconPopup3,
    //   idOptions: [],
    //   options: []
    // },
    // {
    //   number: IconPopup4,
    //   idOptions: [],
    //   options: []
    // },
    // {
    //   number: IconPopup5,
    //   idOptions: [],
    //   options: []
    // },
    // {
    //   number: IconPopup6,
    //   idOptions: [],
    //   options: []
    // },
    // {
    //   number: IconPopup7,
    //   idOptions: [],
    //   options: []
    // },
    // {
    //   number: IconPopup8,
    //   idOptions: [],
    //   options: []
    // },
    // {
    //   number: IconPopup9,
    //   idOptions: [],
    //   options: []
    // },
  ]

  setTellUs(new_data) {
    this.tell_us = { ...this.tell_us, ...new_data };
  }
}

const editCvContainer = decorate(EditCvContainer, {
  tell_us: observable,
  listTellUs: observable,
  setTellUs: action,
});

export default new editCvContainer();
