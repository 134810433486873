import React from "react";
import { withTranslation } from "react-i18next";
import {
  Typography,
  Checkbox,
  Grid,
  Button,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import { palette } from "assets/css/main";
import {
  CheckBoxOutlineBlank,
  CheckBox,
  ArrowForward,
} from "@material-ui/icons";
import Tnc from "app/components/general/Tnc";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { AUTH_API, RequestPost, setItem } from "app/utils";
import {
  ImgLoginRecruiter,
  IconEyeOpen,
  IconEyeClose,
  IcnInfoFull,
} from "assets/img";
import MyHelmet from "app/components/header/MyHelmet";
import { Header, HeaderNologin, Footer } from "app/components";
import { isMobile } from "react-device-detect";
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#333333",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  arrow: {
    color: "#333333",
  },
}))(Tooltip);
class RecruiterRegister extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
    this.state = {
      tnc: false,
      accept_tnc: false,
      showPassword: false,
      showConfirm: false,
      error: "",
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      password: "",
      missmatch: false,
      confirmPassword: "",
    };
    this.submitForm = this.submitForm.bind(this);
  }
  componentDidMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value !== this.state.password) {
        this.setState({ missmatch: true });
        return false;
      }
      this.setState({ missmatch: false });
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }

  submitForm = ev => {
    this.setState({ error: "" });
    ev.preventDefault();
    if (!this.state.accept_tnc) {
      this.setState({ error: this.translate("auth:error_tnc") });
    } else {
      const data = new URLSearchParams();
      data.append("email", this.state.email);
      data.append("firstName", this.state.firstName);
      data.append("midName", this.state.middleName);
      data.append("lastName", this.state.lastName);
      data.append("password", this.state.password);
      data.append("confirmPassword", this.state.confirmPassword);
      data.append("role", "Recruiter");

      RequestPost(AUTH_API + "connect/register", data)
        .then(res => {
          this.props.history.push("/user/register/verify", this.state.email);
          setItem("email-register", this.state.email);
          setItem("idRole", "r");
        })
        .catch(e => {
          console.log("UserRegister (recruiter)", e);

          this.setState({ error: e.data.messages[0] });
        });
    }
  };
  render() {
    const { t } = this.props;
    const { showPassword, showConfirm, missmatch } = this.state;
    const title = t("auth:setPassword.rulesTitle");
    const items = t("auth:setPassword.rules.items", { returnObjects: true });
    if (isMobile) {
      return (
        <div>
          <MyHelmet
            title={t("link.signin")}
            description="Hiregrade - Register Recruiter"
          />
          <HeaderNologin />
          <Grid container style={{ minHeight: "100vh" }}>
            <Grid item lg={6} md={6} sm={6}>
              <div className="auth-mobile" style={{ padding: "100px 30px" }}>
                <div style={{ marginBottom: 20, display: "flex" }}>
                  <Button
                    onClick={() => {
                      this.props.history.push("/user/login/recruiter");
                    }}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.login")}
                  </Button>
                  <Button
                    disabled={true}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.signin")}
                  </Button>
                </div>
                <div className="auth-mobile">
                  <Typography
                    style={{
                      fontWeight: "bolder",
                      color: palette.primary,
                      marginBottom: 35,
                      fontSize: 24,
                    }}>
                    {t("link.signin") + " " + t("auth:recruiter")}
                  </Typography>
                  <ValidatorForm onSubmit={this.submitForm}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "92%",
                        }}>
                        <TextValidator
                          label={t("auth:name")}
                          onChange={e => {
                            this.setState({ firstName: e.target.value });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="Field"
                          placeholder={t("auth:placeholder.firstName")}
                          name="firstname"
                          value={this.state.firstName}
                          style={{
                            width: "100%",
                            marginRight: 15,
                          }}
                          validators={["required"]}
                          errorMessages={[
                            t("auth:validation.firstNameRequired"),
                          ]}
                        />
                        <TextValidator
                          onChange={e => {
                            this.setState({ middleName: e.target.value });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="Field"
                          placeholder={t("auth:placeholder.middleName")}
                          name="middlename"
                          value={this.state.middleName}
                          style={{
                            width: "100%",
                            marginRight: 15,
                            left: 10,
                          }}
                        />
                        <TextValidator
                          onChange={e => {
                            this.setState({ lastName: e.target.value });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="Field"
                          placeholder={t("auth:placeholder.lastName")}
                          name="lastname"
                          value={this.state.lastName}
                          style={{ width: "100%", left: 20 }}
                          autoComplete="lastname"
                        />
                      </div>
                    </div>
                    <TextValidator
                      className="Field"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        this.setState({ email: e.target.value });
                      }}
                      value={this.state.email}
                      label={this.translate("auth:email")}
                      placeholder={this.translate("auth:placeholder.email")}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        t("auth:validation.emailRequired"),
                        t("auth:validation.email"),
                      ]}
                      style={{ width: "100%", marginTop: 20 }}
                    />
                    <div style={{ position: "relative" }}>
                      <TextValidator
                        className="Field passwordhint"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={showPassword ? "text" : "password"}
                        label={this.translate("auth:password")}
                        placeholder={this.translate(
                          "auth:placeholder.password"
                        )}
                        validators={["required"]}
                        onChange={e => {
                          this.setState({ password: e.target.value });
                        }}
                        value={this.state.password}
                        errorMessages={[t("auth:validation.passwordRequired")]}
                        style={{ width: "100%", marginTop: 20 }}
                      />
                      <img
                        src={showPassword ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showPassword: !showPassword });
                        }}
                        style={{
                          padding: 5,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: 22,
                          right: 0,
                        }}
                      />
                      <HtmlTooltip
                        title={
                          <div style={{ color: "white" }}>
                            {title}
                            <br />
                            {items.map((v, k) => {
                              return (
                                <span key={k}>
                                  - {v} <br />
                                </span>
                              );
                            })}
                          </div>
                        }
                        arrow>
                        <img
                          src={IcnInfoFull}
                          alt="show hide"
                          style={{
                            padding: 10,
                            cursor: "pointer",
                            width: 18,
                            position: "absolute",
                            top: 8,
                            left: 73,
                          }}
                        />
                      </HtmlTooltip>
                    </div>
                    <div style={{ position: "relative" }}>
                      <TextValidator
                        className="Field passwordhint"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={showConfirm ? "text" : "password"}
                        label={this.translate("auth:confirmpassword")}
                        placeholder={this.translate(
                          "auth:placeholder.confirmpassword"
                        )}
                        validators={["required", "isPasswordMatch"]}
                        onChange={e => {
                          this.setState({ confirmPassword: e.target.value });
                        }}
                        value={this.state.confirmPassword}
                        errorMessages={[
                          t("auth:validation.passwordRequired"),
                          t("auth:validation.matchpassword"),
                        ]}
                        style={{ width: "100%", marginTop: 20 }}
                      />
                      <img
                        src={showConfirm ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showConfirm: !showConfirm });
                        }}
                        style={{
                          padding: 5,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: missmatch ? 44 : 22,
                          right: 0,
                        }}
                      />
                    </div>
                    <Typography
                      style={{ width: "100%", marginBottom: 20, color: "red" }}>
                      {this.state.error}
                    </Typography>
                    <Grid container justify="flex-start" alignItems="center">
                      <Checkbox
                        color="primary"
                        checked={this.state.accept_tnc}
                        icon={
                          <CheckBoxOutlineBlank
                            fontSize="large"
                            color="primary"
                          />
                        }
                        checkedIcon={<CheckBox fontSize="large" />}
                        name="checkedI"
                        onClick={() => {
                          this.setState({ accept_tnc: !this.state.accept_tnc });
                        }}
                      />
                      <Typography
                        onClick={() => this.setState({ tnc: true })}
                        style={{
                          marginLeft: 10,
                          fontWeight: "600",
                          color: palette.primary,
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontSize: 16,
                        }}>
                        {this.translate("auth:tnc")}
                      </Typography>
                      <Tnc
                        visible={this.state.tnc}
                        handleClose={tnc =>
                          this.setState({ tnc: false, accept_tnc: tnc })
                        }
                      />
                    </Grid>
                    <Button
                      style={{
                        paddingLeft: 0,
                        marginTop: 20,
                        marginBottom: 24,
                      }}
                      type="submit">
                      <span
                        style={{
                          fontSize: 24,
                          fontWeight: "900",
                          color: palette.primary,
                          textTransform: "capitalize",
                          marginRight: 5,
                          textDecoration: "underline",
                        }}>
                        {this.translate("auth:register")}
                      </span>
                      <ArrowForward style={{ color: palette.primary }} />
                    </Button>
                  </ValidatorForm>
                </div>
              </div>
            </Grid>
            <Grid style={{ width: "100%" }}>
              <div style={{ position: "relative", bottom: 0 }}>
                <Footer />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <MyHelmet
            title={t("link.signin")}
            description="Hiregrade - Register Recruiter"
          />
          <Header shadow={true} linkgroup={true} />
          <Grid container style={{ minHeight: "100vh" }}>
            <Grid item lg={6} md={6} sm={6}>
              <div className={"auth"} style={{ padding: "120px 120px" }}>
                <div style={{ marginBottom: 40 }}>
                  <Button
                    onClick={() => {
                      this.props.history.push("/user/login/recruiter");
                    }}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.login")}
                  </Button>
                  <Button
                    disabled={true}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.signin")}
                  </Button>
                </div>
                <div className="auth">
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: "bolder",
                      color: palette.primary,
                      marginTop: 30,
                      marginBottom: 40,
                    }}>
                    {t("link.signin") + " " + t("auth:recruiter")}
                  </Typography>
                  <ValidatorForm onSubmit={this.submitForm}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <TextValidator
                        label={t("auth:name")}
                        onChange={e => {
                          this.setState({ firstName: e.target.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="Field"
                        placeholder={t("auth:placeholder.firstName")}
                        name="firstname"
                        value={this.state.firstName}
                        style={{
                          width: "100%",
                          marginTop: 20,
                          marginRight: 15,
                        }}
                        validators={["required"]}
                        errorMessages={[t("auth:validation.firstNameRequired")]}
                      />
                      <TextValidator
                        onChange={e => {
                          this.setState({ middleName: e.target.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="Field"
                        placeholder={t("auth:placeholder.middleName")}
                        name="middlename"
                        value={this.state.middleName}
                        style={{
                          width: "100%",
                          marginTop: 20,
                          marginRight: 15,
                        }}
                      />
                      <TextValidator
                        onChange={e => {
                          this.setState({ lastName: e.target.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="Field"
                        placeholder={t("auth:placeholder.lastName")}
                        name="lastname"
                        value={this.state.lastName}
                        style={{ width: "100%", marginTop: 20 }}
                        autoComplete="lastname"
                      />
                    </div>
                    <TextValidator
                      className="Field"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        this.setState({ email: e.target.value });
                      }}
                      value={this.state.email}
                      label={this.translate("auth:email")}
                      placeholder={this.translate("auth:placeholder.email")}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        t("auth:validation.emailRequired"),
                        t("auth:validation.email"),
                      ]}
                      style={{ width: "100%", marginTop: 20 }}
                    />
                    <div style={{ position: "relative" }}>
                      <TextValidator
                        className="Field passwordhint"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={showPassword ? "text" : "password"}
                        label={this.translate("auth:password")}
                        placeholder={this.translate(
                          "auth:placeholder.password"
                        )}
                        validators={["required"]}
                        onChange={e => {
                          this.setState({ password: e.target.value });
                        }}
                        value={this.state.password}
                        errorMessages={[t("auth:validation.passwordRequired")]}
                        style={{ width: "100%", marginTop: 20 }}
                      />
                      <img
                        src={showPassword ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showPassword: !showPassword });
                        }}
                        style={{
                          padding: 10,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: 22,
                          right: 0,
                        }}
                      />
                      <HtmlTooltip
                        title={
                          <div style={{ color: "white" }}>
                            {title}
                            <br />
                            {items.map((v, k) => {
                              return (
                                <span key={k}>
                                  - {v} <br />
                                </span>
                              );
                            })}
                          </div>
                        }
                        arrow>
                        <img
                          src={IcnInfoFull}
                          alt="show hide"
                          style={{
                            padding: 10,
                            cursor: "pointer",
                            width: 18,
                            position: "absolute",
                            top: 8,
                            left: 73,
                          }}
                        />
                      </HtmlTooltip>
                    </div>
                    <div style={{ position: "relative" }}>
                      <TextValidator
                        className="Field passwordhint"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={showConfirm ? "text" : "password"}
                        label={this.translate("auth:confirmpassword")}
                        placeholder={this.translate(
                          "auth:placeholder.confirmpassword"
                        )}
                        validators={["required", "isPasswordMatch"]}
                        onChange={e => {
                          this.setState({ confirmPassword: e.target.value });
                        }}
                        value={this.state.confirmPassword}
                        errorMessages={[
                          t("auth:validation.passwordRequired"),
                          t("auth:validation.matchpassword"),
                        ]}
                        style={{ width: "100%", marginTop: 20 }}
                      />
                      <img
                        src={showConfirm ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showConfirm: !showConfirm });
                        }}
                        style={{
                          padding: 10,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: missmatch ? 44 : 22,
                          right: 0,
                        }}
                      />
                    </div>
                    <Typography
                      style={{ width: "100%", marginBottom: 20, color: "red" }}>
                      {this.state.error}
                    </Typography>
                    <Grid container justify="flex-start" alignItems="center">
                      <Checkbox
                        color="primary"
                        checked={this.state.accept_tnc}
                        icon={
                          <CheckBoxOutlineBlank
                            fontSize="large"
                            color="primary"
                          />
                        }
                        checkedIcon={<CheckBox fontSize="large" />}
                        name="checkedI"
                        onClick={() => {
                          this.setState({ accept_tnc: !this.state.accept_tnc });
                        }}
                      />
                      <Typography
                        onClick={() => this.setState({ tnc: true })}
                        style={{
                          marginLeft: 10,
                          fontWeight: "500",
                          color: palette.primary,
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}>
                        {this.translate("auth:tnc")}
                      </Typography>
                      <Tnc
                        visible={this.state.tnc}
                        handleClose={tnc =>
                          this.setState({ tnc: false, accept_tnc: tnc })
                        }
                      />
                    </Grid>
                    <Button
                      style={{
                        paddingLeft: 0,
                        marginTop: 20,
                        marginBottom: 24,
                      }}
                      type="submit">
                      <span
                        style={{
                          fontSize: 24,
                          fontWeight: "bolder",
                          color: palette.primary,
                          textTransform: "capitalize",
                          marginRight: 5,
                          textDecoration: "underline",
                        }}>
                        {this.translate("auth:register")}
                      </span>
                      <ArrowForward style={{ color: palette.primary }} />
                    </Button>
                  </ValidatorForm>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              style={{
                backgroundColor: palette.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                src={ImgLoginRecruiter}
                alt="Recruiter"
                width={561}
                height={346}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation()(RecruiterRegister);
