import React from "react";
import { withTranslation } from "react-i18next";
import {
  MyHelmet,
  ApplicantHeader,
  FullCalendar,
  CustomAvatar,
} from "app/components";
import SideStep from "./SideStep";
import {
  AppHistory,
  findObject,
  getItem,
  removeObject,
  RequestGet,
} from "app/utils";
import { Grid } from "@material-ui/core";
import { palette } from "assets/css/main";
import moment from "moment";
import "moment/locale/id";
import { ImgEmpty } from "assets/img";
moment.locale(getItem("lang"));

class InterviewSchedule extends React.Component {
  state = {
    events: [],
    selected: {},
  };

  componentDidMount() {
    this.getCalendarEvent();
  }

  getCalendarEvent() {
    const { t } = this.props;
    RequestGet("calendar/candidate")
      .then(r => {
        var tmp = [];
        var key = "";
        var tmp2 = null;
        r.data.map(v => {
          key = v.timeStart.split("T")[0];
          tmp2 = findObject(tmp, "key", key);
          if (tmp2) {
            removeObject(tmp, "key", key);
            tmp2.detail.push({
              interviewTitle: v.interviewTitle,
              name: v.name,
              companyNameBrand: v.companyNameBrand,
              companyLogo: v.companyLogo,
              timeStart: v.timeStart,
              timeEnd: v.timeEnd,
              timezone: v.timezone,
            });
            tmp2.title =
              tmp2.detail.length + " " + t("interviewSchedule.interview");
            //sorting detail candidate time
            tmp2.detail.sort(function (a, b) {
              return new Date(a.timeStart) - new Date(b.timeStart);
            });
            tmp.push(tmp2);
          } else {
            tmp.push({
              key: key,
              interviewTitle: v.interviewTitle,
              start: moment(v.timeStart).toDate(),
              end: moment(v.timeEnd).toDate(),
              title: "1 " + t("interviewSchedule.interview"),
              detail: [
                {
                  interviewTitle: v.interviewTitle,
                  name: v.name,
                  companyLogo: v.companyLogo,
                  companyNameBrand: v.companyNameBrand,
                  timeStart: v.timeStart,
                  timeEnd: v.timeEnd,
                  timezone: v.timezone,
                },
              ],
            });
          }
          return {};
        });
        return tmp;
      })
      .then(d => {
        this.setState({ events: d });
      })
      .catch(c => {
        console.log("candidates:getCalendarEvent()", c);
      });
  }

  render() {
    const { t } = this.props;
    const { events, selected } = this.state;
    return (
      <div>
        <MyHelmet title={"Home"} />
        <ApplicantHeader menu="job-opening" />
        <div
          style={{
            width: "100%",
            display: "flex",
            paddingTop: "100px",
            flexDirection: "row",
            paddingBottom: 100,
          }}>
          <Grid container>
            <Grid item lg={3} md={3} sm={3}>
              <SideStep
                head="InterviewSchedule"
                onChange={v => AppHistory.push(v)}
              />
            </Grid>
            <Grid item lg={9} md={9} sm={9}>
              <div
                style={{
                  width: "100%",
                  padding: "10px 40px 40px",
                  color: palette.primary,
                  boxSizing: "border-box",
                }}>
                <p style={{ fontSize: 24, margin: 0 }}>
                  {t("interviewSchedule.title")}
                </p>
                <div style={{ padding: "0px", display: "flex" }}>
                  <div
                    style={{
                      padding: "40px 0px",
                      width: "65%",
                    }}>
                    <FullCalendar
                      onSelected={obj => {
                        this.setState({ selected: obj });
                      }}
                      events={events}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: 4,
                      marginLeft: 20,
                      boxSizing: "border-box",
                      width: "35%",
                    }}>
                    <div
                      style={{
                        width: "100%",
                        padding: "40px",
                        boxSizing: "border-box",
                        color: "black",
                      }}>
                      <div style={{ opacity: 0.5 }}>
                        {t("interviewSchedule.onDate")}
                      </div>
                      <div
                        style={{
                          borderBottom: "1px solid #AFBFE5",
                          padding: "10px 0px 20px",
                          color: palette.primary,
                        }}>
                        {moment(selected.start).format("DD MMMM yyyy")}
                      </div>
                      {selected.start ? (
                        selected.detail.map((v, k) => {
                          return (
                            <div
                              key={k}
                              style={{
                                display: "flex",
                                margin: "20px 0px 5px",
                                color: palette.primary,
                              }}>
                              <CustomAvatar
                                src={v.companyLogo}
                                style={{
                                  width: 47,
                                  height: 47,
                                  border: "1px solid " + palette.primary,
                                }}>
                                {v.companyNameBrand
                                  ? v.companyNameBrand
                                  : "C" + (k + 1)}
                              </CustomAvatar>
                              <div style={{ marginLeft: 20 }}>
                                <p
                                  style={{
                                    margin: "0px 0px 5px",
                                    textTransform: "capitalize",
                                  }}>
                                  {v.companyNameBrand}
                                </p>
                                <p
                                  style={{
                                    margin: "0px 0px 5px",
                                    textTransform: "capitalize",
                                    fontSize: 14,
                                  }}>
                                  {v.interviewTitle}
                                </p>
                                <p
                                  style={{
                                    margin: "0px 0px 5px",
                                    fontWeight: "lighter",
                                  }}>
                                  {moment(v.timeStart).format("HH:mm") +
                                    " - " +
                                    moment(v.timeEnd).format("HH:mm") +
                                    " " +
                                    v.timezone}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            color: palette.primary,
                            paddingTop: 150,
                          }}>
                          <img src={ImgEmpty} width={98} alt="Empty" />
                          <p
                            style={{
                              fontWeight: "lighter",
                              width: 200,
                              textAlign: "center",
                            }}>
                            {t("interviewSchedule.nodata")}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default withTranslation("job-opening")(InterviewSchedule);
