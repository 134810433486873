import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import UserProfileSidePanel from "./side-panel";
import HeaderProfile from "./header";
import { ApplicantHeader } from "app/components";
import { RequestGet } from "app/utils";

export default withTranslation()(
  class UserDescriptionProfile extends React.Component {
    state = { data: [], isLoading: true, answers: [] };

    componentDidMount() {
      this.getDescription();
    }

    getDescription() {
      RequestGet("userresult/profiledesc")
        .then(r => {
          this.setState({ data: r.data.profileDesc.split("\n\n") });
        })
        .catch(e => {
          console.log("getDescription()=>", e);
        });
    }

    render() {
      const {
        match: { params },
      } = this.props;
      const { data } = this.state;
      return (
        <div style={{ height: "100%", boxSizing: "border-box" }}>
          <ApplicantHeader menu="profile" shadow={true} />
          <div
            style={{ paddingTop: 80, height: "100%", boxSizing: "border-box" }}>
            <HeaderProfile id={params.id} />
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                minHeight: 650,
                boxSizing: "border-box",
              }}>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  backgroundColor: palette.lightGrey,
                  boxSizing: "border-box",
                  justifyContent: "flex-end",
                }}>
                <UserProfileSidePanel view="description" id={params.id} />
              </div>
              <div
                style={{
                  width: "80%",
                  padding: "25px 40px",
                  color: palette.primary,
                  marginBottom: 50,
                  boxSizing: "border-box",
                }}>
                {data.map((v, k) => {
                  return (
                    <p
                      style={{ fontWeight: "lighter", fontSize: 18 }}
                      key={"content+" + k}>
                      {v}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
