import React from "react";
export default class ReadOnlyField extends React.Component {
  render() {
    const style = this.props.style
      ? this.props.style
      : {
          width: "100%",
          marginTop: "40px",
          marginRight: "15px",
          marginBottom: "0px !important",
        };
    return (
      <div
        className="MuiFormControl-root MuiTextField-root Readonly"
        style={style}
      >
        {this.props.label && (
          <label
            className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink"
            data-shrink="true"
          >
            {this.props.label}
          </label>
        )}
        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl">
          <input
            aria-invalid="false"
            name="type"
            placeholder="..."
            type="text"
            disabled={true}
            className="MuiInputBase-input MuiInput-input"
            value={this.props.value}
          />
        </div>
      </div>
    );
  }
}
