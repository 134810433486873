import React from "react";
import { Document } from "@react-pdf/renderer";
import CIPageCV from "./page-cv";
import CIPageSelfDescription from "./page-sd";
import CIPageWorkPreference from "./page-wp";
import CIPageCover from "./page-cover";
import CIPageProfileReport from "./page-pr";

const CandidateInfoExport = props => (
  <Document
    title={props.fileName}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <CIPageCover data={props.data.biodata} status={props.status || "SEL"} />
    <CIPageCV data={props.data.biodata} status={props.status || "SEL"} />
    <CIPageSelfDescription data={props.data.about} />
    <CIPageWorkPreference data={props.data.workpreference} />
    {props.data.diagnostic.length > 0 && (
      <CIPageProfileReport data={props.data.diagnostic} />
    )}
  </Document>
);

export const onRenderDocument = ({ blob }, filename) => {
  var blobUrl = URL.createObjectURL(blob);
  saveDocument(blobUrl, filename);
};

export const saveDocument = (function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (blob, fileName) {
    a.href = blob;
    a.download = fileName;
    a.click();
    // window.URL.revokeObjectURL(blob);
    // window.close();
  };
})();
export default CandidateInfoExport;
