import React from "react";
import { withTranslation } from "react-i18next";
import { AppBar, Toolbar, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { loadImage, RequestGet, getItem, AUTH_API } from "app/utils";
import { IconConfirmationCheck, IcnFailedFilledNew } from "assets/img";
import { palette } from "assets/css/main";
import { NextBtn, DialogueInfo } from "app/components";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { isMobile } from "react-device-detect";
import { HeaderNologin } from "app/components";
class ValidatingEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      status: this.props.location.search ? true : false,
      content: <div></div>,
      link: "/user/login/applicant",
      load: false,
      successContent: "",
      failedContent: "",
      open: false,
    };
    this.submitForm = this.resendEmail.bind(this);
  }
  componentDidMount() {
    this.getKey();
  }
  getKey() {
    if (this.props.location.search) {
      const { t } = this.props;
      var params = new URLSearchParams(this.props.location.search);
      var successContent = t("auth:validateEmail.successContent");
      var failedContent = t("auth:validateEmail.successContent");
      if (params.get("role") === "Recruiter") {
        this.setState({
          link: "/user/login/recruiter",
          successContent: successContent,
          failedContent: failedContent,
        });
      } else {
        successContent =
          getItem("lang") === "en"
            ? t("auth:validateEmail.successContent")
            : t("auth:validateEmail.successContent_ap");
        failedContent =
          getItem("lang") === "en"
            ? t("auth:validateEmail.failedContent")
            : t("auth:validateEmail.failedContent_ap");
        this.setState({
          link: "/user/login/applicant",
          successContent: successContent,
          failedContent: failedContent,
        });
      }
      //check params
      if (params.get("code") && params.get("userId")) {
        //validating
        this.check(params.get("userId"), params.get("code"));
      } else {
        this.setState({ load: true, status: false });
      }
    } else {
      this.setState({ load: true, status: false });
    }
  }

  resendEmail(e) {
    const data = { email: this.state.email };
    RequestGet(AUTH_API + "connect/sendverifyemail", data)
      .then(() => {
        this.setState({ open: true });
      })
      .catch(e => {
        console.log("validate-email.js > check()", e);
      });

    e.preventDefault();
  }

  check(userId, code) {
    const data = { userId: userId, code: code };
    RequestGet(AUTH_API + "connect/verifyemail", data)
      .then(() => {
        this.setState({ load: true, status: true });
      })
      .catch(e => {
        this.setState({ load: true, status: false });

        console.log("validate-email.js > check()", e);
      });
  }
  render() {
    const { load, status, successContent, failedContent, open } = this.state;
    const { t } = this.props;
    if (isMobile) {
      return (
        <div>
          <DialogueInfo
            contentWidth={"auto"}
            open={open}
            title={t("auth:dialogueSend.title")}
            content={t("auth:dialogueSend.content")}
            contentDetail={t("auth:dialogueSend.content_detail")}
            onClose={() => {
              this.setState({ open: false });
            }}
          />
          <HeaderNologin />
          <main style={{ paddingTop: 90 }} className="validate-mobile">
            {load && status && (
              <Grid style={{ textAlign: "center", padding: "0px 20px" }}>
                <img
                  src={IconConfirmationCheck}
                  alt="Success"
                  width={90}
                  height={90}
                />
                <Typography
                  style={{
                    fontSize: 24,
                    marginBottom: 24,
                    paddingTop: 56,
                    fontWeight: "bold",
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {t("auth:validateEmail.successTitle")}
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    marginBottom: 24,
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {successContent}
                </Typography>
                <NextBtn
                  text={t("auth:validateEmail.linkLogin")}
                  onClick={() => {
                    window.location.href = this.state.link;
                  }}
                />
              </Grid>
            )}

            {load && !status && (
              <Grid style={{ textAlign: "center", padding: "0px 20px" }}>
                <img
                  src={IcnFailedFilledNew}
                  alt="Failed"
                  width={90}
                  height={90}
                />
                <Typography
                  style={{
                    fontSize: 24,
                    marginBottom: 24,
                    paddingTop: 56,
                    fontWeight: "bold",
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {t("auth:validateEmail.failedTitle")}
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    marginBottom: 20,
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {failedContent}
                </Typography>
                <ValidatorForm
                  id="form"
                  onSubmit={this.submitForm}
                  style={{ display: "flex", flexDirection: "column" }}>
                  <TextValidator
                    autoFocus={true}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="Field"
                    placeholder={t("auth:placeholder.email")}
                    name="email"
                    value={this.state.email}
                    style={{ marginTop: 20, width: "70%" }}
                    autoComplete="username"
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      t("auth:validation.required"),
                      t("auth:validation.email"),
                    ]}
                  />
                  <NextBtn
                    type="submit"
                    style={{ marginTop: 20 }}
                    text={t("auth:validateEmail.resend")}
                    onClick={() => {}}
                  />
                </ValidatorForm>
              </Grid>
            )}
          </main>
        </div>
      );
    } else {
      return (
        <div>
          <DialogueInfo
            contentWidth={"auto"}
            open={open}
            title={t("auth:dialogueSend.title")}
            content={t("auth:dialogueSend.content")}
            contentDetail={t("auth:dialogueSend.content_detail")}
            onClose={() => {
              this.setState({ open: false });
            }}
          />
          <AppBar
            position="absolute"
            color="default"
            style={{
              backgroundColor: "white",
              top: 0,
              height: 80,
              bottom: "auto",
              position: "fixed",
            }}>
            <Toolbar>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ height: 80 }}>
                <Grid item lg={2}>
                  <Link to="/" className="centered">
                    <img
                      alt="Hiregrade-Logo"
                      src={loadImage("HiregradeLogo.png")}
                      width={150}
                      style={{ marginLeft: 87 }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <main style={{ paddingTop: 210 }} className="validate">
            {load && status && (
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column">
                <img
                  src={IconConfirmationCheck}
                  alt="Success"
                  width={71}
                  height={71}
                />
                <Typography
                  style={{
                    fontSize: 24,
                    width: 450,
                    marginBottom: 24,
                    paddingTop: 56,
                    fontWeight: "bold",
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {t("auth:validateEmail.successTitle")}
                </Typography>
                <Typography
                  style={{
                    fontSize: 18,
                    width: 400,
                    marginBottom: 180,
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {successContent}
                </Typography>
                <NextBtn
                  text={t("auth:validateEmail.linkLogin")}
                  onClick={() => {
                    window.location.href = this.state.link;
                  }}
                />
              </Grid>
            )}

            {load && !status && (
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column">
                <img
                  src={IcnFailedFilledNew}
                  alt="Failed"
                  width={71}
                  height={71}
                />
                <Typography
                  style={{
                    fontSize: 24,
                    width: 450,
                    marginBottom: 24,
                    paddingTop: 56,
                    fontWeight: "bold",
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {t("auth:validateEmail.failedTitle")}
                </Typography>
                <Typography
                  style={{
                    fontSize: 18,
                    width: 605,
                    marginBottom: 50,
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {failedContent}
                </Typography>
                <ValidatorForm
                  id="form"
                  onSubmit={this.submitForm}
                  style={{ display: "flex", flexDirection: "column" }}>
                  <TextValidator
                    autoFocus={true}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="Field"
                    placeholder={t("auth:placeholder.email")}
                    name="email"
                    value={this.state.email}
                    style={{ width: 500, marginTop: 20 }}
                    autoComplete="username"
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      t("auth:validation.required"),
                      t("auth:validation.email"),
                    ]}
                  />
                  <NextBtn
                    type="submit"
                    style={{ marginTop: 70 }}
                    text={t("auth:validateEmail.resend")}
                    onClick={() => {}}
                  />
                </ValidatorForm>
              </Grid>
            )}
          </main>
        </div>
      );
    }
  }
}
export default withTranslation()(ValidatingEmail);
