import React from "react";
import { palette } from "assets/css/main";
import {
  RequestGet,
  getItem,
  AppHistory,
  RequestPost,
  RequestPut,
  SetLocalItem,
  GetLocalItem,
} from "app/utils";
import { MenuItem } from "@material-ui/core";
import data from "app/pages/applicant/initialize/bio-data/data";

import { CheckCircle, Error, Lock } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-i18next";
import { ProfilePicture } from "app/components";

export default withTranslation()(
  class SidePanel extends React.Component {
    state = {
      personalInfo: {},
      ...JSON.parse(getItem("user_info")),
      anchorEl: null,
      isAboutComplete: false,
      isCVComplete: true,
      isInterviewComplete: false,
      isReadyWorkComplete: false,
      isLoading: true,
      percentage: 0,
      active: "/user/home",
    };
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
      const {
        location: { state, pathname },
      } = AppHistory;
      this.getBiodata(state ? state.edit : false);
      this.setState({ active: pathname });
    }
    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleUploadFile(file) {
      const { personalInfo } = this.state;
      var form = new FormData();
      form.append(
        "file",
        file.file,
        getItem("user_id") + "-profile." + file.extension
      );
      RequestPost("biodata/upload", form)
        .then(r => {
          // console.log(r.data)
          const { imageUrl } = r.data;
          var personal = personalInfo;
          personal.photo = imageUrl;
          data.personal_data.photo = imageUrl;
          this.setState({ personalInfo: personal }, () => {
            RequestPut("biodata", this.state)
              .then(() => {
                this.getBiodata(true);
              })
              .catch(e => {
                console.log("update error", e);
              });
          });
        })
        .catch(e => {
          console.log("handleUploadImage()", e);
        });
    }

    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    getBiodata(force = false) {
      this.setState({ content: <div></div> });
      if (GetLocalItem("BIODATA") && !force) {
        this.setState(JSON.parse(GetLocalItem("BIODATA")));
        this.getProfile();
      } else {
        RequestGet("biodata", { userId: getItem("user_id") })
          .then(res => {
            res.data.listWorks.sort(function (a, b) {
              return b.stillWorking - a.stillWorking;
            });
            res.data.listFormalEducation.sort(function (a, b) {
              return b.yearGraduate - a.yearGraduate;
            });
            return res.data;
          })
          .then(data => {
            this.setState(
              prev => ({ ...prev, ...data }),
              () => {
                SetLocalItem("BIODATA", JSON.stringify(this.state));
              }
            );
            this.getProfile();
          })
          .catch(e => {
            console.log(e);
          });
      }
    }

    getProfile() {
      this.setState({ isLoading: true });
      RequestGet("UserProfile", { loading: false })
        .then(r => {
          this.setState(r.data);
          const {
            isCVComplete,
            isAboutComplete,
            isInterviewComplete,
            isReadyWorkComplete,
          } = r.data;

          var percentageTemp = 0;
          if (isCVComplete && isAboutComplete ) {
            percentageTemp = 100;
          }else if(isCVComplete){
            percentageTemp = 50;
          }

          var step = 0;
          if (!isAboutComplete) {
            step = 1;
          } else if (!isInterviewComplete) {
            step = 2;
          } else if (!isReadyWorkComplete) {
            step = 3;
          } else {
            step = 4;
          }
          this.setState({ isLoading: false, step: step, percentage: percentageTemp });
        })
        .catch(e => {
          this.setState({ isLoading: false });
          console.log(e);
        });
    }

    render() {
      const { personalInfo, step, active } = this.state;
      var {
        isAboutComplete,
        isCVComplete,
        // isInterviewComplete,
        // isReadyWorkComplete,
        isLoading,
        percentage,
      } = this.state;
      const { t } = this.props;

      if (this.props.profile) {
        isAboutComplete = this.props.profile.isAboutComplete;
        isCVComplete = this.props.profile.isCVComplete;
        // isInterviewComplete = this.props.profile.isInterviewComplete;
        // isReadyWorkComplete = this.props.profile.isReadyWorkComplete;
        percentage = this.props.profile.percentage;
      }
      return (
        <div
          style={{
            padding: 20,
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
          }}>
          <ProfilePicture
            edit={this.props.edit}
            value={personalInfo.photo}
            onSelectFile={file => {
              this.handleUploadFile(file);
            }}
          />

          <div
            style={{
              display: personalInfo.name ? "" : "none",
              borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
              width: "100%",
            }}>
            <p
              style={{
                textAlign: "center",
                fontSize: 24,
                marginBottom: 15,
                color: palette.primary,
                fontWeight: "bolder",
              }}>
              {personalInfo.name}
              <br />
            </p>
          </div>
          <div style={{ marginTop: "8%", width: "100%", display: "flex" }}>
            <div style={{ width: "40%", marginLeft: "11%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  height: 70,
                }}>
                <CircularProgress
                  style={{
                    color: "#52C8F4",
                    width: 65,
                    height: 65,
                    position: "absolute",
                  }}
                  variant="determinate"
                  value={percentage}
                  thickness={5}
                />
                <CircularProgress
                  style={{
                    width: 65,
                    height: 65,
                    color: palette.secondary,
                    opacity: 0.5,
                    position: "absolute",
                  }}
                  variant="determinate"
                  value={100}
                  thickness={5}
                />
                <div
                  style={{
                    fontSize: 14,
                    color: palette.secondary,
                    position: "absolute",
                  }}>
                  {percentage + "%"}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    color: palette.secondary,
                    position: "absolute",
                    left: 100,
                  }}>
                  {t("cv:completeness")}
                </div>
              </div>
            </div>
          </div>
          {!isLoading && (
            <div
              style={{
                width: "100%",
                marginTop: "15%",
                paddingBottom: 30,
                alignItems: "left",
              }}>
              <MenuItem
                onClick={() => {
                  AppHistory.push("/user/home");
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  height: 40,
                  backgroundColor:
                    active === "/user/home" ? palette.sopacity(0.2) : "",
                }}>
                <div
                  style={{
                    color: isCVComplete ? palette.secondary : "#FF8D14",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  {isCVComplete ? (
                    <CheckCircle style={{ fontSize: "2.0rem" }} />
                  ) : (
                    <Error style={{ fontSize: "2.0rem" }} />
                  )}
                </div>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: 16,
                    color: palette.primary,
                    fontWeight: "bolder",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 10,
                  }}>
                  CV
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  AppHistory.push("/user/about-you");
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  height: 40,
                  backgroundColor:
                    active === "/user/about-you" ? palette.sopacity(0.2) : "",
                }}>
                <div
                  style={{
                    color: isAboutComplete ? palette.secondary : "#FF8D14",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  {isAboutComplete ? (
                    <CheckCircle style={{ fontSize: "2.0rem" }} />
                  ) : (
                    <>
                      {step >= 1 ? (
                        <Error style={{ fontSize: "2.0rem" }} />
                      ) : (
                        <Lock style={{ fontSize: "2.0rem" }} />
                      )}
                    </>
                  )}

                  <div
                    style={{
                      textAlign: "left",
                      fontSize: 16,
                      color: palette.primary,
                      fontWeight: "bolder",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: 10,
                      marginTop: 5,
                    }}>
                    {t("about:sideStep.items", { returnObjects: true })[0]}
                  </div>
                </div>
              </MenuItem>

              {/* take out feature in 01/11/2023 */}

              {/* <MenuItem
                disabled={step >= 2 ? false : true}
                onClick={() => {
                  AppHistory.push("/user/video-interview");
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  height: 40,
                  backgroundColor:
                    active === "/user/video-interview"
                      ? palette.sopacity(0.2)
                      : "",
                }}>
                <div
                  style={{
                    color: isInterviewComplete ? palette.secondary : "#FF8D14",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  {isInterviewComplete ? (
                    <CheckCircle style={{ fontSize: "2.0rem" }} />
                  ) : (
                    <>
                      {step >= 2 ? (
                        <Error style={{ fontSize: "2.0rem" }} />
                      ) : (
                        <Lock
                          style={{
                            fontSize: "2.0rem",
                            color: palette.darkGrey,
                            marginBottom: 5,
                          }}
                        />
                      )}
                    </>
                  )}
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: 16,
                      color: palette.primary,
                      fontWeight: "bolder",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: 10,
                      marginTop: 5,
                    }}>
                    {t("cv:videoInterview.title")}
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                disabled={step >= 3 ? false : true}
                onClick={() => {
                  AppHistory.push("/user/ready-to-work");
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  height: 40,
                  backgroundColor:
                    active === "/user/ready-to-work"
                      ? palette.sopacity(0.2)
                      : "",
                }}>
                <div
                  style={{
                    color: isReadyWorkComplete ? palette.secondary : "#FF8D14",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  {isReadyWorkComplete ? (
                    <CheckCircle style={{ fontSize: "2.0rem" }} />
                  ) : (
                    <>
                      {step >= 3 ? (
                        <Error style={{ fontSize: "2.0rem" }} />
                      ) : (
                        <Lock
                          style={{
                            fontSize: "2.0rem",
                            color: palette.darkGrey,
                            marginBottom: 5,
                          }}
                        />
                      )}
                    </>
                  )}
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: 16,
                      color: palette.primary,
                      fontWeight: "bolder",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: 10,
                      marginTop: 5,
                    }}>
                    {t("cv:workReady.title")}
                  </div>
                </div>
              </MenuItem> */}
            </div>
          )}
        </div>
      );
    }
  }
);
