import { Button, Menu, MenuItem } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { DialogueFull } from "app/components";
import alertData from "app/components/alert/alert-data";
import { RequestGet, RequestPut } from "app/utils";
import { palette } from "assets/css/main";
import { IcnCheckFull } from "assets/img";
import React from "react";
import { withTranslation } from "react-i18next";
import ReschedueDialogue from "./reschedue-dialogue";
export default withTranslation("interview-schedule")(
  class ConfirmationReschedule extends React.Component {
    state = {
      data: {},
      options: false,
      result: false,
      anchorElRe: false,
      isLoading: false,
      atValue: {},
      anchorElAt: false,
      reValue: false,
      showResult: false,
      isPresent: false,
      showReschedule: false,
      dataReschedule: null,
      openReschedule: false,
      disableOK: false,
    };
    constructor(props) {
      super(props);
      this.handleClose = this.handleClose.bind(this);
    }
    handleClose() {
      this.setState({ anchorElAt: null, anchorElRe: null });
    }

    selectConfirm(v) {
      this.handleClose();
      this.setState({ atValue: v });

      if (v.value === "acc") {
        this.setState({
          isAccept: true,
          showReschedule: true,
          disableOK: false,
        });
        if (!this.state.dataReschedule) {
          this.setState({ disableOK: true });
        }
      } else {
        this.setState({
          disableOK: false,
          isAccept: false,
          showReschedule: false,
        });
      }
    }

    getDetail() {
      this.setState({
        isLoading: false,
        tmpRes: {},
        atValue: {},
        reValue: null,
      });
      const { candidateId, interviewId, onClose, t } = this.props;
      RequestGet("interview/reschedule", {
        interviewId: interviewId,
        candidateId: candidateId,
      })
        .then(r => {
          this.setState({
            data: r.data,
            isLoading: true,
          });
        })
        .catch(e => {
          console.log("getDetail()", e);
          alertData.show(t("messages.failedViewResult"), "error");
          onClose(false);
        });
    }

    submitData() {
      const { isAccept, dataReschedule } = this.state;
      const { onClose, t, candidateId, interviewId } = this.props;
      RequestPut("interview/reschedule/" + interviewId, {
        candidateId: candidateId,
        isAccept: isAccept,
        timeslot: dataReschedule ? dataReschedule : {},
      })
        .then(() => {
          alertData.show(t("messages.successConfirm"), "success");
          onClose(true);
        })
        .catch(e => {
          alertData.show(t("messages.failedConfirm"), "success");

          console.log(e);
        });
    }

    render() {
      const { open, onClose, t } = this.props;
      const {
        data,
        options,
        anchorElRe,
        isLoading,
        atValue,
        anchorElAt,
        reValue,
        showResult,
        showReschedule,
        dataReschedule,
        disableOK,
        openReschedule,
      } = this.state;
      const optionConfirm = t("confirmReschedule.options.data", {
        returnObjects: true,
      });
      return (
        <DialogueFull
          hideClose={true}
          style={{ display: openReschedule ? "none" : "" }}
          open={open}
          onEnter={() => this.getDetail()}
          onClose={() => {
            onClose(false);
          }}>
          <ReschedueDialogue
            open={openReschedule}
            onClose={ts => {
              this.setState({
                openReschedule: false,
                dataReschedule: ts,
                disableOK: ts ? false : true,
              });
            }}
          />
          {isLoading ? (
            <div>
              <div
                style={{
                  backgroundColor: "white",
                  width: 900,
                  boxSizing: "border-box",
                  borderRadius: 4,
                }}>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "20px 20px",
                    color: palette.primary,
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    fontSize: 18,
                  }}>
                  {t("confirmReschedule.title")}
                  <p
                    style={{
                      fontWeight: "lighter",
                      margin: 0,
                      borderBottom: "1px solid " + palette.popacity(0.3),
                      padding: "10px 0px",
                    }}>
                    {t("confirmReschedule.subtitle")}
                  </p>
                </div>
                <div
                  style={{
                    padding: 20,
                    height: "100%",
                    color: palette.primary,
                  }}>
                  <div style={{ marginBottom: 30 }}>
                    <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                      {t("confirmReschedule.note")}
                    </p>
                    <p
                      style={{
                        fontSize: 18,
                        margin: 0,
                        padding: 20,
                        fontWeight: "lighter",
                        backgroundColor: palette.popacity(0.1),
                        borderRadius: 5,
                      }}>
                      {data.notes}
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: 30,
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div>
                      <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                        {t("confirmReschedule.confirm")}
                      </p>
                      <Button
                        onClick={e => {
                          this.setState({ anchorElAt: e.currentTarget });
                        }}
                        style={{
                          backgroundColor: palette.primary,
                          color: "white",
                          textTransform: "capitalize",
                          width: 180,
                          justifyContent: "space-between",
                        }}>
                        {atValue.value
                          ? atValue.label
                          : t("confirmReschedule.options.placeholder")}
                        {Boolean(anchorElAt) ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </Button>
                      <Menu
                        id="select-attendance"
                        anchorEl={anchorElAt}
                        open={Boolean(anchorElAt)}
                        style={{
                          fontWeight: "lighter",
                          marginTop: 50,
                          marginLeft: 20,
                          color: palette.primary,
                        }}
                        onClose={this.handleClose}>
                        {optionConfirm.map((v, k) => {
                          return (
                            <MenuItem
                              key={"att" + k}
                              onClick={() => {
                                this.selectConfirm(v);
                              }}
                              style={{
                                color: palette.primary,
                                borderTop:
                                  k === 0
                                    ? ""
                                    : "1px solid " + palette.popacity(0.3),
                              }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}>
                                <span>{v.label}</span>
                                <p
                                  style={{
                                    fontWeight: "lighter",
                                    color: palette.darkGrey,
                                    fontSize: 12,
                                    margin: 0,
                                  }}>
                                  {v.description}
                                </p>
                              </div>
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                    {showReschedule ? (
                      <div
                        style={{
                          marginLeft: 30,
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                          {t("updateResult.reschedule")}
                        </p>
                        <Button
                          onClick={() => {
                            this.setState({ openReschedule: true });
                          }}
                          style={{
                            border: "1px solid " + palette.primary,
                            color: palette.primary,
                            textTransform: "capitalize",
                            width: 180,
                          }}>
                          {t("updateResult.rescheduleTitle")}
                          {dataReschedule ? (
                            <img
                              src={IcnCheckFull}
                              alt="icon-check"
                              style={{ width: 18, height: 18, marginLeft: 10 }}
                            />
                          ) : null}
                        </Button>
                        <span
                          style={{
                            color: palette.darkGrey,
                            fontSize: 12,
                            fontWeight: "lighter",
                            marginTop: 10,
                            width: 200,
                          }}>
                          {t("updateResult.rescheduleHint")}
                        </span>
                      </div>
                    ) : null}

                    {showResult ? (
                      <div
                        style={{
                          marginLeft: 30,
                        }}>
                        <p style={{ marginBottom: 5, fontWeight: "lighter" }}>
                          {t("updateResult.result")}
                        </p>
                        <Button
                          onClick={e => {
                            this.setState({ anchorElRe: e.currentTarget });
                          }}
                          style={{
                            backgroundColor: palette.primary,
                            color: "white",
                            textTransform: "capitalize",
                            width: 180,
                            justifyContent: "space-between",
                          }}>
                          {reValue ? reValue.label : t("updateResult.select")}
                          {Boolean(anchorElRe) ? (
                            <ArrowDropUp />
                          ) : (
                            <ArrowDropDown />
                          )}
                        </Button>
                        <Menu
                          id="select-result"
                          anchorEl={anchorElRe}
                          open={Boolean(anchorElRe)}
                          style={{
                            fontWeight: "lighter",
                            marginTop: 50,
                            marginLeft: 20,
                            color: palette.primary,
                          }}
                          onClose={this.handleClose}>
                          {options.map((v, k) => {
                            return (
                              <MenuItem
                                key={"att" + k}
                                onClick={() => {
                                  this.selectResult(v);
                                }}
                                style={{
                                  color: palette.primary,
                                  borderTop:
                                    k === 0
                                      ? ""
                                      : "1px solid " + palette.popacity(0.3),
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}>
                                  <span>{v.label}</span>
                                  <p
                                    style={{
                                      fontWeight: "lighter",
                                      color: palette.darkGrey,
                                      fontSize: 12,
                                      margin: 0,
                                    }}>
                                    {v.description}
                                  </p>
                                </div>
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    bottom: 0,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    backgroundColor: "white",
                    boxSizing: "border-box",
                    width: "100%",
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                    padding: "20px 50px",
                  }}>
                  <Button
                    onClick={() => {
                      onClose(null);
                    }}
                    style={{
                      border: "1px solid " + palette.primary,
                      color: palette.primary,
                      backgroundColor: "white",
                      textTransform: "capitalize",
                      padding: "8px 50px",
                      marginRight: 20,
                    }}>
                    {t("general:button.cancel")}
                  </Button>
                  <Button
                    disabled={disableOK}
                    onClick={() => {
                      this.submitData();
                    }}
                    style={{
                      backgroundColor: palette.primary,
                      opacity: !disableOK ? 1 : 0.5,
                      color: "white",
                      padding: "8px 50px",
                    }}>
                    OK
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </DialogueFull>
      );
    }
  }
);
