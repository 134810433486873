import React from "react";
import { Text, View, Page } from "@react-pdf/renderer";
import { CIStyles, HeaderLogoPDF, WaterMark } from "./ci-styles";
import { palette } from "assets/css/main";
import { findObject } from "app/utils";
const TableItemWP = props => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        fontSize: 12,
        width: "100%",
        borderTop: "1pt solid " + palette.cv.borderColor,
        borderBottom: "1pt solid " + palette.cv.borderColor,
        marginTop: -1,
      }}>
      <View
        style={{
          color: palette.cv.textGrey,
          textAlign: "center",
          borderRight: props.head
            ? "none"
            : "1pt solid " + palette.cv.borderColor,
          width: "10%",
          padding: "5pt 5pt",
          fontWeight: "light",
        }}>
        <Text>{props.num}</Text>
      </View>
      <View
        style={{
          color: palette.cv.textGrey,
          textAlign: props.head ? "center" : "left",
          borderRight: props.head
            ? "none"
            : "1pt solid " + palette.cv.borderColor,
          width: "30%",
          padding: "5pt 5pt",
          fontWeight: "light",
        }}>
        <Text>{props.title}</Text>
      </View>
      <View
        style={{
          color: props.head ? palette.cv.textGrey : palette.cv.textColor,
          textAlign: props.head ? "center" : "left",
          width: "55%",
          padding: "5pt 5pt",
          fontWeight: props.head ? "light" : "normal",
        }}>
        {typeof props.value !== "object" ? (
          <Text style={{}}>{props.value}</Text>
        ) : (
          props.value
        )}
      </View>
    </View>
  );
};
const CIPageWorkPreference = props => {
  const listDataWP = props.data ? props.data : [];
  return (
    <Page style={CIStyles.page}>
      <WaterMark />
      <HeaderLogoPDF />
      <View style={{ position: "fixed" }} fixed>
        <Text style={{ color: palette.primary, fontWeight: "bold" }}>
          III. Work Preference
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 20,
        }}>
        <View style={CIStyles.subtitleLine} />
        <Text style={{ fontSize: 14 }}>Preference</Text>
      </View>
      <View style={{ marginTop: 20 }}>
        <TableItemWP
          head={true}
          num={"No"}
          title={"Questions"}
          value={"Answers"}
        />
        {listDataWP.map((v, i) => {
          const number = i < 9 ? "0" + (i + 1) : i + 1;
          let collection = v.responseAnswers.map((vv, ii) => {
            const workAnswers = findObject(
              v.workAnswers,
              "answerId",
              vv.answerId,
              false
            );
            return (
              <View key={"wpa-" + ii}>
                <Text>
                  {workAnswers.answerTextEng}
                  {vv.otherValue ? " - " + vv.otherValue : ""}
                </Text>
                {vv.answerDetails && (
                  <View>
                    {vv.answerDetails.map((vd, id) => {
                      return (
                        <Text key={"wda" + id} style={{ fontWeight: "light" }}>
                          {
                            findObject(
                              workAnswers.answerDetails,
                              "answerId",
                              vd.answerId,
                              false
                            ).answerTextEng
                          }
                        </Text>
                      );
                    })}
                  </View>
                )}
              </View>
            );
          });

          // let answer = <Text></Text>;
          // let txt ="";

          // if (v.answerId === "253251ca-bf29-4624-9e6b-4b7dd120dbb1") {
          //   txt ="Any location in indonesia"
          // } else if (
          //   v.answerId === "8f0b80cb-e2ad-4bff-beaf-817b1b3a9b61" ||
          //   v.answerId === "0aafdc9d-6b1f-4a70-850e-ec40a6f06e3d"
          // ) {
          //   txt =
          //     getItem("lang") === "en"
          //       ? workAnswers.answerTextEng
          //       : workAnswers.answerTextIna;
          //   txt += ": " + vx.otherValue;
          // } else if (workAnswers.answerId.length < 36) {
          //   txt =
          //     getItem("lang") === "en"
          //       ? workAnswers.answerTextEng.toLowerCase()
          //       : workAnswers.answerTextIna.toLowerCase();
          //   capitalize = true;
          // } else {
          //   txt =
          //     getItem("lang") === "en"
          //       ? workAnswers.answerTextEng
          //       : workAnswers.answerTextIna;
          // }
          return (
            <TableItemWP
              break
              key={"wp-" + i}
              num={number}
              title={v.questionTextIna}
              value={<View>{collection}</View>}
            />
          );
        })}
      </View>
    </Page>
  );
};
export default CIPageWorkPreference;
