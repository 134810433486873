import grey from "@material-ui/core/colors/grey";
export const palette = {
  primary: "#2E69B2",
  danger: "rgb(243, 74, 56)",
  primarydark: "#2D307A",
  bg: "#396BA6",
  secondary: "rgb(81, 200, 244)",
  dsg: "#535659",
  bgs: "#52c8f4",
  info: "#eaf0f7",
  grey: "#F7F7F7",
  grey2: "#F2F2F2",
  darkGrey: "#B0B7BD",
  lightGrey: "#F8F8F8",
  inactive: {
    textColor: "#818385",
    bakcgroundColor: "#E5E5E5",
  },
  cv: {
    textCover: "#192c36",
    textColor: "#383838",
    borderColor: "#e2e7ed",
    medium: "#fdca28",
    high: "#1e9ae4",
    low: "#fa8a65",
    // #e1e6ec
    textNote: "#aaaaaa",
  },
  primary_opacity: "rgba(46,105,178,0.1)",
  popacity: (opa = 0.1) => {
    return "rgba(46,105,178," + opa + ")";
  },
  sopacity: (opa = 1) => {
    return "rgba(81, 200, 244," + opa + ")";
  },
};
export const PRIMARY_BOLD = {
  color: palette.primary,
  fontWeight: "bold",
};

export const REC_STYLE = {
  BODY_STYLE: {
    minHeight: "100vh",
    width: "100vw",
    position: "relative",
    backgroundColor: palette.grey2,
    paddingTop: 80,
  },
  SUB_HEADER: {
    zIndex: 2,
    position: "fixed",
    width: "100vw",
    backgroundColor: "white",
    alignItems: "flex-end",
    display: "flex",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)",
    padding: "0px 100px",
    boxSizing: "border-box",
    paddingTop: 125,
  },
};

export const WHITE_BUTTON = {
  fontWeight: "bold",
  textTransform: "capitalize",
  color: "white",
  textDecoration: "underline",
};

export const PRIMARY_BUTTON = {
  color: "white",
  backgroundColor: palette.primary,
  textTransform: "capitalize",
};
export const BUTTON_OVAL = {
  borderRadius: 20,
  width: 90,
  height: 40,
  border: "1px solid",
  borderColor: grey[500],
  textTransform: "capitalize",
};

export const DARK = {
  color: "white",
  backgroundColor: grey[800],
  borderColor: grey[800],
};

export const COLUMN_CENNTER = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const COLOR = {
  red: "#ff6559",
  darkGrey: "#bfbfbf",
};

export const BOLD = {
  fontWeight: "bold",
};
