import React from "react";
import { withTranslation } from "react-i18next";
import ConfirmationSidePanel from "./side-panel";
import ConfirmationPanel from "./confirmation-panel";
import { RequestGet, getItem } from "app/utils";

class ConfirmationApplicant extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;

    this.state = {
      personalInfo: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
      },
      alreadyGet: false,
      biodata: {
        personalInfo: {
          firstName: "",
          middleName: "",
          lastName: "",
          gender: "",
          birthPlace: "",
          birthDate: "",
          maritalStatus: "",
          identityNumber: "",
          identityPhoto: "",
          email: "",
          phoneNumber: "",
          address: "",
          street: "",
        },
        listFormalEducation: [],
        listInformalEducation: [],
        listOrganization: [],
        listWorks: [],
      },
    };
  }
  componentDidMount() {
    this.getBiodata();
  }

  getBiodata() {
    RequestGet("biodata", { userId: getItem("user_id") })
      .then(res => {
        res.data.listWorks.sort(function (a, b) {
          return b.stillWorking - a.stillWorking;
        });
        res.data.listFormalEducation.sort(function (a, b) {
          return b.yearGraduate - a.yearGraduate;
        });
        res.data.listInformalEducation.sort(function (a, b) {
          return b.year - a.year;
        });
        res.data.listOrganization.sort(function (a, b) {
          return b.year - a.year;
        });
        return res.data;
      })
      .then(data => {
        if(data.personalInfo.address !== null){
          let splitAddress = data.personalInfo.address.split(',');
          data.personalInfo.street = splitAddress[0];
        }

        this.setState(prev => ({ ...prev, ...data }));
        this.setState({ biodata: data, alreadyGet: true });
      })
      .then();
  }

  updateData = (newData) => {
    this.setState({ biodata: newData });
  };

  render() {
    const { biodata } = this.state;
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          paddingTop: "100px",
          flexDirection: "row",
          paddingBottom: 100,
        }}>
        <div
          style={{
            width: "15%",
            display: "flex",
            flexDirection: "column",
          }}></div>
        <div style={{ width: 300, display: "flex", flexDirection: "column" }}>
          <ConfirmationSidePanel
            onChange={v => {
              this.setState({ sideStep: v });
            }}
            data={biodata}
            updateData={(v)=>this.updateData(v)}
          />
        </div>
        <div
          style={{
            width: "1%",
            display: "flex",
            flexDirection: "column",
          }}></div>
        <div style={{ width: "54%", display: "flex", flexDirection: "column" }}>
          <ConfirmationPanel alreadyGet={this.state.alreadyGet} data={biodata} updateData={(v)=>this.updateData(v)} />
        </div>
      </div>
    );
  }
}
export default withTranslation()(ConfirmationApplicant);
