import React from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  // Menu,
  // MenuItem,
} from "@material-ui/core";
import { palette } from "assets/css/main";
import PropTypes from "prop-types";
import {
  RequestGet,
  getItem,
  AppHistory,
  RequestPost,
  GetLocalItem,
} from "app/utils";
import { withTranslation } from "react-i18next";
// import { MoreVert } from "@material-ui/icons";
import TemplateHG1 from "./select-template/template-hg1";
import TemplateHG2 from "./select-template/template-hg2";
import TemplateHG3 from "./select-template/template-hg3";
import TemplateHG4 from "./select-template/template-hg4";
import TemplateHG5 from "./select-template/template-hg5";
import TemplateHG6 from "./select-template/template-hg6";
import { pdf } from "@react-pdf/renderer";
import alertData from "app/components/alert/alert-data";
import CVPreviewPi from "./cv-preview-pi";
import CVPreviewFe from "./cv-preview-fe";
import CVPreviewIe from "./cv-preview-ie";
import CVPreviewOe from "./cv-preview-oe";
import CVPreviewWe from "./cv-preview-we";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export const onRenderDocument = ({ blob }, filename) => {
  var blobUrl = URL.createObjectURL(blob);
  saveDocument(blobUrl, filename);
};

export const saveDocument = (function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (blob, fileName) {
    a.href = blob;
    a.id = "download-cv";
    a.download = fileName;
  };
})();

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class CVPreview extends React.Component {
  state = {
    content: <div></div>,
  };
  blob = null;
  blobURL = "#";
  constructor(props) {
    super(props);
    this.state = {
      personalInfo: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
      },
      ...JSON.parse(getItem("user_info")),
      alreadyGet: false,
      anchorEl: null,
      loading: true,
      activeTab: [],
      cvTemplate: null,
      value: 0,
      biodata: {
        personalInfo: {
          firstName: "",
          middleName: "",
          lastName: "",
          gender: "",
          birthPlace: "",
          birthDate: "",
          maritalStatus: "",
          identityNumber: "",
          identityPhoto: "",
          email: "",
          phoneNumber: "",
          address: "",
        },
        listFormalEducation: [],
        listInformalEducation: [],
        listOrganization: [],
        listWorks: [],
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  componentDidMount() {
    this.getBiodata();
  }

  getBiodata() {
    const { t } = this.props;
    const maritalStatus = t("biodata:options.maritalStatus", {
      returnObjects: true,
    });
    const jobStatus = t("biodata:options.jobStatus", {
      returnObjects: true,
    });
    RequestGet("biodata", { userId: getItem("user_id") })
      .then(res => {
        res.data.listWorks.sort(function (a, b) {
          return b.stillWorking - a.stillWorking;
        });
        res.data.listFormalEducation.sort(function (a, b) {
          return b.yearGraduate - a.yearGraduate;
        });
        res.data.listInformalEducation.sort(function (a, b) {
          return b.year - a.year;
        });
        res.data.listOrganization.sort(function (a, b) {
          return b.year - a.year;
        });
        return res.data;
      })
      .then(data => {
        this.setState(prev => ({
          ...prev,
          ...data,
          ...{ maritalStatus: maritalStatus, jobStatus: jobStatus },
        }));
        this.setState({ biodata: data, alreadyGet: true }, () => {
          const t = GetLocalItem("SELECTED_TEMPLATE")
            ? GetLocalItem("SELECTED_TEMPLATE")
            : "template-blue";
          this.setTemplate(t);
        });
      })
      .then();
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  sendToEmail() {
    this.handleClose();
    const { email } = this.state;
    var d = new FormData();
    var fileName =
      "Curriculum Vitae - " + this.state.personalInfo.name + ".pdf";
    d.append("email", email);
    d.append("file", this.blob, fileName);

    // To check FormData() in console
    // for (var pair of d.entries()) {
    //   console.log(pair[0]);
    //   console.log(pair[1]);
    // }

    RequestPost("sendemail", d)
      .then(r => {
        alertData.show("Success send email");
      })
      .catch(e => {
        alertData.show("Failed send email", "error");
      });
  }

  setTemplate(key) {
    const { t } = this.props;
    this.setState({ loading: true });
    var template = null;
    if (key === "template-hg1") {
      template = <TemplateHG1 t={t} data={this.state} />;
    } else if (key === "template-hg2") {
      template = <TemplateHG2 t={t} data={this.state} />;
    } else if (key === "template-hg3") {
      template = <TemplateHG3 t={t} data={this.state} />;
    } else if (key === "template-hg4") {
      template = <TemplateHG4 t={t} data={this.state} />;
    } else if (key === "template-hg5") {
      template = <TemplateHG5 t={t} data={this.state} />;
    } else if (key === "template-hg6") {
      template = <TemplateHG6 t={t} data={this.state} />;
    } else {
      template = <TemplateHG2 t={t} data={this.state} />;
    }
    pdf(template)
      .toBlob()
      .then(x => {
        this.blob = x;
        this.blobURL = saveDocument(
          window.URL.createObjectURL(x),
          "Curriculum Vitae - " + this.state.personalInfo.name + ".pdf"
        );
        this.setState({ loading: false });
      });
    this.setState({ cvTemplate: template });
  }

  render() {
    const { value, alreadyGet, biodata } = this.state;
    const { t } = this.props;

    return (
      <div className="EditCV">
        <div
          style={{
            height: 23,
            padding: "20px 20px",
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "#2E69B2",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            color: "white",
            fontSize: 24,
          }}>
          CV
          <Button
            style={{
              textTransform: "none",
              color: "white",
              border: "1px solid white",
              padding: "2px 10px",
            }}
            onClick={() => {
              AppHistory.push("/user/cv/edit");
            }}>
            {t("button.edit")}
          </Button>
          
          {/* take out feature in 01/11/2023 */}

          {/* <div>
            <Button
              style={{
                textTransform: "none",
                color: "white",
                border: "1px solid white",
                padding: "2px 10px",
              }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={this.handleClick}>
              <MoreVert />
              {t("link.more")}
            </Button>
            <Menu
              style={{ color: palette.primary, marginTop: 50 }}
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}>
              <MenuItem
                style={{ color: palette.primary }}
                onClick={() => {
                  AppHistory.push("/user/cv/edit");
                }}>
                {t("button.edit")}
              </MenuItem>
              <MenuItem
                style={{ color: palette.primary }}
                onClick={() => {
                  AppHistory.push("/user/select-template");
                }}>
                {t("button.selectTemplate")}
              </MenuItem>
              <MenuItem
                style={{ color: palette.primary }}
                onClick={() => {
                  document.getElementById("download-cv").click();
                }}>
                {!loading && (
                  <> */}
                    {/* <a
                      style={{ color: palette.primary }}
                      href={this.blobURL}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      rel="noopener noreferer">
                      {t("button.downloadCV")}
                    </a> */}
                    {/* {t("button.downloadCV")}
                  </>
                )}
                {loading && <>Loading...</>}
              </MenuItem>
              <MenuItem
                style={{ color: palette.primary }}
                onClick={() => {
                  this.sendToEmail();
                }}>
                {t("button.sendEmail")}
              </MenuItem>
            </Menu>
          </div> */}
        </div>
        <div
          style={{
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            fontSize: 24,
          }}>
          <div
            style={{
              width: "100%",
              padding: "0px 10px",
              boxSizing: "border-box",
            }}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: palette.primary,
                },
              }}
              className="CVTab"
              value={value}
              onChange={this.handleChange}
              style={{
                color: palette.primary,
                fontWeight: "bolder",
                width: "100%",
                fontSize: 15,
                display: "flex",
                justifyContent: "center",
              }}>
              <Tab
                label={t("biodata:CVpersonalInfo")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVformalEducation")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVinformalEducation")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVorganizationalExperience")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVworkExperience")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
            </Tabs>

            {alreadyGet ? (
              <div>
                <TabPanel value={value} index={0} className="Tab-preview">
                  <CVPreviewPi data={biodata} />
                </TabPanel>
                <TabPanel value={value} index={1} className="Tab-preview">
                  <CVPreviewFe data={biodata} />
                </TabPanel>
                <TabPanel value={value} index={2} className="Tab-preview">
                  <CVPreviewIe data={biodata} />
                </TabPanel>
                <TabPanel value={value} index={3} className="Tab-preview">
                  <CVPreviewOe data={biodata} />
                </TabPanel>
                <TabPanel value={value} index={4} className="Tab-preview">
                  <CVPreviewWe data={biodata} />
                </TabPanel>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(CVPreview);
