import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { MenuItem } from "@material-ui/core";
import data from "app/pages/recruiter/data";

const recruiter_type = [
  { code: "HR", label: "Human Resources" },
  { code: "HH", label: "Headhunter" },
];

class ConfirmationProfileRecruiter extends React.Component {
  state = {
    content: <div></div>,
  };
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      recruiterPersonalInfo: props.data,
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule("selectRequired");
  }

  handleChange(obj) {
    this.setState(obj);
    data.setrecruiterPersonalInfo(obj);
    var recruiterPersonalInfo = {
      recruiterPersonalInfo: { ...this.state.recruiterPersonalInfo, ...obj },
    };
    this.setState({
      ...this.state,
      ...recruiterPersonalInfo,
    });
  }

  handleClick(e) {
    document.getElementById("button-submit").click();
  }

  submitForm(e) {
    e.preventDefault();
    data.recruiterPersonalInfo.firstName = this.state.recruiterPersonalInfo.firstName;
    data.recruiterPersonalInfo.middleName = this.state.recruiterPersonalInfo.middleName;
    data.recruiterPersonalInfo.lastName = this.state.recruiterPersonalInfo.lastName;
    data.recruiterPersonalInfo.email = this.state.recruiterPersonalInfo.email;
    data.recruiterPersonalInfo.phoneNumber = this.state.recruiterPersonalInfo.phoneNumber;
    data.recruiterPersonalInfo.recruiterType = this.state.recruiterPersonalInfo.recruiterType;
    data.recruiterPersonalInfo.positionType = this.state.recruiterPersonalInfo.positionType;
  }

  render() {
    let strmiddlename;

    if (!this.state.recruiterPersonalInfo.middleName) {
      strmiddlename = "";
    } else {
      strmiddlename = this.state.recruiterPersonalInfo.middleName;
    }
    const { t } = this.props;
    return (
      <div className="">
        <div
          style={{
            height: 58,
            padding: "4% 6%",
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "#2E69B2",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            color: "white",
          }}>
          <label style={{ fontSize: 24 }}>
            {t("recruiterSetup", { returnObjects: true })[0]}
          </label>
          <label style={{ fontSize: 14 }}>*{t("profilesetup:require")}</label>
        </div>
        <div
          style={{
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            fontSize: 24,
          }}>
          <div
            justify="flex-start"
            style={{
              color: palette.primary,
              marginTop: "3%",
              marginLeft: "6%",
              marginBottom: "3%",
            }}>
            <ValidatorForm onSubmit={this.submitForm}>
              <label
                style={{
                  color: palette.primary,
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "none",
                }}>
                {t("profilesetup:form.field.email")}
              </label>
              <br />
              <label
                style={{
                  color: palette.primary,
                  fontWeight: "bolder",
                }}>
                {this.state.recruiterPersonalInfo.email}
              </label>

              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.firstName")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <TextValidator
                  style={{
                    width: "93%",
                  }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.name")]}
                  onChange={e => {
                    this.handleChange({
                      firstName: e.currentTarget.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("profilesetup:form.placeholder.firstName")}
                  value={this.state.recruiterPersonalInfo.firstName}
                  className="Field"
                />
              </div>

              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.middleName")}
                </label>
                <TextValidator
                  style={{
                    width: "93%",
                  }}
                  onChange={e => {
                    this.handleChange({
                      middleName: e.currentTarget.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("profilesetup:form.placeholder.middleName")}
                  value={strmiddlename}
                  className="Field"
                />
              </div>

              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.lastName")}
                </label>
                <TextValidator
                  style={{
                    width: "93%",
                  }}
                  onChange={e => {
                    this.handleChange({
                      middleName: e.currentTarget.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("profilesetup:form.placeholder.lastName")}
                  value={this.state.recruiterPersonalInfo.lastName}
                  className="Field"
                />
              </div>

              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.phone")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <TextValidator
                  style={{
                    width: "93%",
                  }}
                  onChange={e => {
                    var char = e.currentTarget.value;
                    if (char.split("")[0] === "0") {
                      char = char.slice(1);
                    }
                    this.handleChange({ phoneNumber: char });
                  }}
                  validators={[
                    "required",
                    "isNumber",
                    "minStringLength:8",
                    "maxStringLength:14",
                  ]}
                  errorMessages={[
                    t("validation.required.phoneNumber"),
                    t("validation.valid.phoneNumber"),
                    t("validation.minLength.phoneNumber"),
                  ]}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("profilesetup:form.placeholder.phone")}
                  value={this.state.recruiterPersonalInfo.phoneNumber}
                  className="Field"
                />
              </div>

              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.type")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <SelectValidator
                  className="Profile"
                  style={{ width: "93%" }}
                  value={
                    this.state.recruiterPersonalInfo.recruiterType
                      ? this.state.recruiterPersonalInfo.recruiterType
                      : " "
                  }
                  onChange={e => this.handleChange({ type: e.target.value })}
                  validators={["required"]}
                  errorMessages={[t("validation.required.type")]}>
                  <MenuItem
                    style={{ fontSize: 24, fontWeight: "bold" }}
                    value={" "}
                    disabled>
                    {t("profilesetup:form.placeholder.type")}
                  </MenuItem>
                  {recruiter_type.map((v, k) => {
                    return (
                      <MenuItem
                        key={k}
                        style={{
                          fontSize: 24,
                          color: palette.primary,
                          fontWeight: "bold",
                        }}
                        value={v.code}>
                        {v.label}
                      </MenuItem>
                    );
                  })}
                </SelectValidator>
              </div>

              <div
                style={{
                  marginTop: "3%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.position")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <TextValidator
                  onChange={e => {
                    this.handleChange({ position: e.target.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t("profilesetup:form.placeholder.position")}
                  name="position"
                  value={this.state.recruiterPersonalInfo.positionType}
                  style={{ width: "93%" }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.position")]}
                />
              </div>

              <button type="submit" id="button-submit" className="Submit-Btn">
                submit
              </button>
            </ValidatorForm>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ConfirmationProfileRecruiter);
