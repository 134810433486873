import React from "react";
const content = {
  paddingLeft: 30,
  paddingRight: 30,
  textAlign: "justify",
};
export default class ContentTnc extends React.Component {
  render() {
    return (
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <p style={this.props.style ? this.props.style : content}>
          Kebijakan Privasi ini menjelaskan bagaimana Hiregrade, masing-masing
          anak perusahaan, afiliasinya, perusahaan-perusahaan yang terasosiasi
          dengannya, dan entitas-entitas yang dikendalikan bersama (secara
          kolektif disebut " Hiregrade", atau "Kami") mengumpulkan, menyimpan,
          menggunakan, mengolah, menganalisa, menguasai, mentransfer,
          mengungkapkan, menampilkan, membuka dan melindungi setiap data atau
          informasi pribadi anda (selanjutnya disebut "Pengguna", atau "Anda")
          yang diberikan kepada Hiregrade terhadap penggunaan situs (www.[*])
          dan aplikasi milik Hiregrade (selanjutnya disebut "Situs") dalam
          penggunaan layanan yang kami sediakan (selanjutnya disebut "Layanan").
        </p>
        <p style={this.props.style ? this.props.style : content}>
          "Data Pribadi" adalah segala informasi yang dapat digunakan untuk
          mengidentifikasi Anda atau dari mana Anda dapat diidentifikasi. Ini
          termasuk tetapi tidak terbatas pada nama, kebangsaan, nomor telepon,
          usia, jenis kelamin, tanggal lahir, tingkat pendidikan, pengalaman
          kerja, dan data lainnya berkaitan dengan keperluan Hiregrade untuk
          melaksanakan test pada aplikasi untuk menentukan hasil test.
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Hiregrade bertanggung jawab berdasarkan peraturan yang berlaku di
          Indonesia tentang data privasi (selanjutnya disebut
          "Peraturan").&nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <span>I. TUJUAN PENGGUNAAN DATA PRIBADI</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Untuk dapat menikmati Situs dan Layanan dari Hiregrade secara maksimal
          maka Hiregrade memerlukan pengungkapan Data Pribadi dengan tujuan
          untuk mengelola dan mendapatkan hasil test serta mengetahui informasi
          dan kebutuhan pengiklan secara maksimal serta tujuan-tujuan lainnya
          selama diizinkan oleh Peraturan, yaitu sebagai berikut:&nbsp;
        </p>
        <ul>
          <span>
            1. Untuk pelaksanaan kegiatan operasional Situs dan Aplikasi;
          </span>
        </ul>
        <ul>
          <span>
            2. Untuk membantu Pengguna mencari pekerjaan yang sesuai dengan
            Pengiklan/Pemberi Kerja dan juga membantu Pengiklan/Pemberi Kerja
            untuk menentukan serta mendapatkan kandidat yang sesuai dengan
            kebutuhan Pemberi Kerja termasuk juga Hiregrade dapat menggunakan
            kecerdasan buatan (artificial intelligence) dan data analitik
            sehubungan dengan informasi pribadi dalam rangka meningkatkan
            Layanan kami;
          </span>
        </ul>
        <ul>
          <span>
            3. Untuk menghubungi Pengguna dan/atau Pemberi Kerja terkait dengan
            keperluan administrasi dan komunikasi lebih lanjut;
          </span>
        </ul>
        <ul>
          <span>
            4. Untuk keperluan penggunaan data statistik yang dapat menghasilkan
            sumber data atau kumpulan data analisis;
          </span>
        </ul>
        <ul>
          <span>
            5. Untuk melakukan verifikasi dan/atau identifikasi terhadap
            Pengguna dan Pemberi Kerja/Pengiklan;
          </span>
        </ul>
        <ul>
          <span>
            6. Untuk keperluan lainnya termasuk namun tidak terbatas pada
            promosi, marketing dan pengenalan fitur-fitur Layanan di masa
            mendatang.
          </span>
        </ul>
        <p style={this.props.style ? this.props.style : content}>
          Ketentuan tambahan dalam hal:&nbsp;
        </p>
        <ul>
          <span>
            a. Data Pribadi anak di bawah umur Sebagai orang tua atau wali yang
            sah, mohon jangan izinkan anak di bawah umur Anda menyerahkan Data
            Pribadi kepada Hiregrade. Dalam hal Data Pribadi anak di bawah umur
            tersebut diungkapkan kepada Hiregrade, Anda dengan ini menyetujui
            pemrosesan Data Pribadi anak dibawah umur tersebut dan menerima dan
            setuju untuk terikat oleh Kebijakan ini dan bertanggung jawab atas
            tindakannya.
          </span>
        </ul>
        <ul>
          <span>
            b. Saat Anda memberikan data pribadi individu lain kepada kami Dalam
            beberapa situasi, Anda dapat memberikan Data Pribadi orang lain
            (seperti pasangan Anda, anggota keluarga atau teman) kepada kami.
            Misalnya, Anda dapat menambahkannya sebagai kontak darurat. Jika
            Anda memberikan kepada kami Data Pribadi mereka, Anda menyatakan dan
            menjamin bahwa Anda telah dan Anda telah memperoleh persetujuan
            mereka untuk Data Pribadi mereka untuk dikumpulkan, digunakan, dan
            diungkapkan sebagaimana diatur dalam Kebijakan ini.
          </span>
        </ul>
        <p style={this.props.style ? this.props.style : content}>
          <span>II. PENGGUNAAN DATA PRIBADI</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Hiregrade dapat menggunakan keseluruhan atau sebagian Data Pribadi
          yang diperoleh dan dikumpulkan dari Pengguna sebagaimana disebutkan
          dalam bagian sebelumnya untuk hal-hal sebagai berikut:&nbsp;
        </p>
        <ul>
          <span>
            1. Dalam rangka menjalankan tujuan-tujuan sebagaimana diuraikan
            tersebut di atas;
          </span>
        </ul>
        <ul>
          <span>
            2. Melakukan penilaian, analisa dan interpretasi terhadap Pengguna
            dan atau kebutuhan Pemberi Kerja;
          </span>
        </ul>
        <ul>
          <span>
            3. Membantu Pengguna pada saat berkomunikasi dengan
            Pengiklan/Pemberi Kerja;
          </span>
        </ul>
        <ul>
          <span>
            4. Menghubungi Pengguna melalui email, surat, telepon, fax, dan
            lain-lain, termasuk namun tidak terbatas, untuk membantu dan/atau
            menyelesaikan kendala (apabila ada).
          </span>
        </ul>
        <ul>
          <span>
            5. Melakukan penelitian pasar dan peninjauan kepuasan pengguna
            layanan kami, untuk meningkatkan layanan kami, pengembangan dan
            pengujian produk guna meningkatkan kenyamanan penggunaan dan
            keamanan layanan-layanan pada Situs, serta mengembangkan fitur baru.
          </span>
        </ul>
        <ul>
          <span>
            6. Menginformasikan kepada Pengguna terkait jasa, layanan, promosi
            bermanfaat dan program yang mungkin dapat gunakan/ nikmati, studi,
            survei, berita, perkembangan terbaru, acara dan lain-lain, baik
            melalui Situs maupun melalui media lainnya. Hiregrade juga dapat
            menggunakan informasi tersebut untuk mempromosikan dan memproses
            kontes dan undian, memberikan hadiah, serta menyajikan iklan dan
            konten yang relevan tentang Layanan Hiregrade dan mitra usahanya.
          </span>
        </ul>
        <ul>
          <span>
            7. Melakukan monitoring ataupun investigasi terhadap data-data atau
            tindakan-tindakan terkait perekrutan yang terindikasi mengandung
            unsur kecurangan atau pelanggaran terhadap Syarat dan Ketentuan atau
            ketentuan hukum yang berlaku, serta melakukan tindakan-tindakan yang
            diperlukan sebagai tindak lanjut dari hasil monitoring atau
            investigasi tersebut.
          </span>
        </ul>
        <ul>
          <span>
            8. Melindungi keamanan atau integritas Layanan dan setiap fasilitas
            atau peralatan yang digunakan untuk membuat Layanan tersedia;
          </span>
        </ul>
        <ul>
          <span>
            9. Dalam keadaan tertentu, Hiregrade mungkin perlu untuk menggunakan
            ataupun mengungkapkan Data Pribadi milik Pengguna untuk tujuan
            penegakan hukum atau untuk pemenuhan persyaratan hukum dan peraturan
            yang berlaku, termasuk dalam hal terjadinya sengketa atau proses
            hukum antara Pengguna dan Hiregrade.
          </span>
        </ul>
        <p style={this.props.style ? this.props.style : content}>
          <span>III. PENGUNGKAPAN DATA PRIBADI PENGGUNA</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Hiregrade tidak akan menggunakan Data Pribadi anda selain untuk tujuan
          yang telah kami beritahukan kepada anda, atau yang mana diijinkan oleh
          hukum dan peraturan setempat. Kami tidak akan menawarkan, menerbitkan,
          memberikan, menjual, mengalihkan, mendistribusikan atau meminjamkan
          Data Pribadi anda dengan pihak lainnya di luar kami untuk tujuan
          komersial, tanpa meminta persetujuan dari anda, kecuali terhadap
          hal-hal sebagai berikut:&nbsp;
        </p>
        <ul>
          <span>
            1. Dibutuhkan adanya pengungkapan Data Pribadi Pengguna kepada
            Pengiklan/Pemberi Kerja untuk kepentingan rekrutmen atau pihak
            ketiga lain yang membantu Hiregrade dalam menyajikan Layanan pada
            Situs dan memproses segala bentuk aktivitas Pengguna dalam Situs,
            termasuk memproses transaksi, verifikasi pembayaran, pengiriman
            produk, dan lain-lain.
          </span>
        </ul>
        <ul>
          <span>
            2. Hiregrade dapat menyediakan informasi yang relevan kepada vendor,
            konsultan, mitra pemasaran, firma riset, atau penyedia layanan
            sejenis untuk kepentingan pengolahan data atau hal lainnya guna
            mengoptimalkan pelayanan atau tujuan komersial Hiregrade.
          </span>
        </ul>
        <ul>
          <span>
            3. Pengguna menghubungi Hiregrade melalui media publik seperti blog,
            media sosial, dan fitur tertentu pada Situs, komunikasi antara
            Pengguna dan Hiregrade mungkin dapat dilihat secara publik.
          </span>
        </ul>
        <ul>
          <span>
            4. Hiregrade mengungkapkan data Pengguna dalam upaya mematuhi
            kewajiban hukum dan/atau adanya permintaan yang sah dari aparat
            penegak hukum.
          </span>
        </ul>
        <p style={this.props.style ? this.props.style : content}>
          <span>IV. TANGGUNG JAWAB PENGGUNA DALAM PENYEDIAAN DATA PRIBADI</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Pengguna bertanggung jawab untuk memberikan informasi yang akurat,
          tidak menyesatkan, lengkap dan terbaru kepada Hiregrade tentang diri
          Anda dan orang lain yang informasi pribadinya Anda berikan kepada kami
          dan untuk memperbarui informasi pribadi ini ketika informasi itu tidak
          akurat, menyesatkan, tidak lengkap, dan ketinggalan zaman dengan
          melakukan pembaharuan data sendiri atau menghubungi Hiregrade. Dalam
          situasi ini, Anda mungkin perlu memberikan informasi pribadi Hiregrade
          tentang seseorang selain diri Anda (misalnya, wali atau penjamin
          Anda). Jika demikian, merupakan tanggung jawab Anda untuk memberi tahu
          individu ini bahwa Anda memberikan informasi pribadi mereka kepada
          Hiregrade, untuk memastikan mereka menyetujui Anda memberi kami
          informasi mereka dan memberi tahu mereka tentang di mana mereka dapat
          menemukan salinan Pemberitahuan Privasi ini (di situs kami di [link]).
          &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <span>V. COOKIES</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Hiregrade, dan pihak ketiga yang bekerjasama dengan kami, dapat
          menggunakan cookies, web beacons, tags, scripts, local shared objects,
          yaitu HTML5 dan Flash (yang terkadang dikenal sebagai "flash
          cookies"), pengidentifikasi pemasaran (termasuk pengidentifikasi
          perangkat bergerak, yaitu IDFA milik Apple atau identitas pemasaran
          milik Google) dan teknologi sejenis lainnya ("Cookies") sehubungan
          dengan penggunaan Situs. Cookies dapat memiliki pengidentifikasi unik,
          dan tersimpan dalam, diantaranya, komputer atau perangkat lainnya yang
          anda gunakan, surat elektronik yang kami kirimkan kepada anda, dan
          pada Situs. Cookies dapat mengirimkan Data Pribadi anda dan penggunaan
          Layanan oleh anda, termasuk tipe aplikasi penjelajah internet
          (browser), preferensi pencarian anda, alamat IP, data terkait
          pemasaran yang telah ditampilkan dan dipilih oleh anda, dan tanggal
          serta waktu penggunaan anda. Cookies tersebut tidak diperuntukkan
          untuk digunakan pada saat melakukan akses data lain yang Pengguna
          miliki di perangkat komputer Pengguna, selain dari yang telah Pengguna
          setujui untuk disampaikan. &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Hiregrade memungkinkan pihak ketiga untuk menggunakan Cookies pada
          Situs untuk mengumpulkan tipe Data Pribadi yang serupa untuk tujuan
          yang sama sebagaimana Hiregrade melakukannya untuk kepentingannya
          sendiri. Pihak ketiga tersebut dapat menghubungkan Data Pribadi yang
          dihimpunnya dengan Data Pribadi anda yang mereka himpun dan miliki
          mengenai anda dari sumber lainnya. Kami tidak senantiasa memiliki
          akses kepada atau mengontrol Cookies yang mereka gunakan. &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Lebih lanjut, kami dapat memberikan Data Pribadi yang bersifat tidak
          dapat diidentifikasi secara individu kepada pihak ketiga, yaitu data
          lokasi, pengidentifikasi pemasaran, atau kompilasi kriptografis dari
          pengidentifikasi akun biasa (yaitu alamat surat elektronik), untuk
          memfasilitasi tampilan pemasaran yang memiliki target khusus. &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Walaupun secara otomatis perangkat komputer Pengguna akan menerima
          Cookies, jika Anda tidak berkenan sehubungan dengan pengumpulan Data
          Pribadi anda melalui Cookies dalam Situs, anda dapat mematikan Cookies
          dengan mengatur ulang pengaturan penjelajah (browser) internet Anda
          untuk mematikan, memblokir, atau me-nonaktif-kan Cookies, dengan
          menghapus riwayat penjelajahan dan cache dari penjelajah internet
          anda. Anda juga dapat membatasi pemberian sebagian Data Pribadi ini
          melalui pengaturan perangkat anda. &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <span>VI. PILIHAN PENGGUNA DAN TRANSPARANSI</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Setiap Pengguna berhak untuk meminta akses terhadap atau melakukan
          perubahan terhadap informasi dan data pribadinya sendiri yang disimpan
          oleh Hiregrade dengan menghubungi Layanan Pelanggan Hiregrade yang
          kontaknya akan tersedia pada Situs Kami. Dengan tunduk pada ayat di
          bawah ini, sebagai alternatif, Pengguna dapat sewaktu-waktu
          menyunting, mengubah atau menghapus informasi dan data pribadinya
          dengan masuk ke Situs kami. Apabila Pengguna ingin mengakses atau
          mengubah informasi dan data pribadinya, Hiregrade dapat meminta kepada
          Pengguna tersebut untuk memberikan rincian pribadi dalam rangka
          melakukan verifikasi terhadap identitas Pengguna tersebut. Nomor kartu
          identitas atau nomor paspor atau nomor daftar perusahaan tidak dapat
          diubah kecuali apabila data tersebut terbukti tidak akurat. Hiregrade
          wajib menanggapi permintaan Pengguna dalam jangka waktu [hari] sejak
          permintaannya dan Hiregrade akan berupaya untuk melakukan hal tersebut
          kapan pun memungkinkan, akan tetapi Hiregrade dapat membebankan biaya
          yang wajar kepada Pengguna untuk melakukan hal tersebut. &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <span>VII. PEMELIHARAAN DATA PRIBADI</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Kami akan memelihara Data Pribadi anda selama anda belum menghapus
          akun anda di dalam Situs. Ketika Data Pribadi anda tidak diperlukan
          untuk penyediaan Layanan atau tujuan penggunaan Data Pribadi, atau
          kami tidak lagi memiliki tujuan baik hukum maupun bisnis untuk
          memelihara Data Pribadi anda, kami mengambil tindakan untuk mencegah
          akses atau penggunaan Data Pribadi tersebut untuk tujuan apapun selain
          dari pemenuhan kewajiban kami terhadap Kebijakan Privasi ini, atau
          tujuan keamanan, pencegahan dan deteksi penipuan dan atau melakukan
          pemusnahan terhadap Data Pribadi Anda. &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <span>
            VIII. PENYANGKALAN MENGENAI KEAMANAN DAN SITUS PIHAK KETIGA
          </span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Hiregrade tidak menjamin keamanan Data Pribadi dan/atau informasi
          lainnya yang anda berikan pada situs pihak ketiga. Terkait akses situs
          lainnya tersebut, anda bertanggungjawab atas risiko anda sendiri.
          &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <span>IX. PENGALIHAN DATA PRIBADI KE LUAR NEGERI</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Data Pribadi anda mungkin dialihkan, disimpan atau diolah di luar
          negara anda oleh pihak yang bekerja untuk kami di negara lain, atau
          oleh penyedia layanan pihak ketiga, vendor, mitra, kontraktor, atau
          afiliasi kami. Dalam hal tersebut, kami akan memastikan bahwa Data
          Pribadi tersebut tetap menjadi subjek dari tingkat perlindungan yang
          sebanding dengan apa yang disyaratkan dalam hukum negara anda (dan,
          dalam hal apapun, sejalan dengan komitmen kami dalam Kebijakan Privasi
          ini). &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <span>X. PENYIMPANAN INFORMASI</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Data Pribadi anda yang kami terima dapat disimpan, ditransfer, atau
          diolah oleh penyedia layanan pihak ketiga. Kami akan menggunakan semua
          upaya yang wajar untuk memastikan bahwa semua penyedia layanan pihak
          ketiga tersebut diwajibkan untuk memberikan tingkat perlindungan yang
          sebanding dengan komitmen kami berdasarkan Kebijakan Privasi ini.
          &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <span>XI. PEMBARUAN KEBIJAKAN PRIVASI</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Hiregrade dapat sewaktu-waktu melakukan perubahan atau pembaruan
          terhadap Kebijakan Privasi ini. Kami menyarankan agar Pengguna membaca
          dan memahami ketentuan dalam Kebijakan Privasi ini dari waktu ke waktu
          untuk mengetahui perubahan apapun. Dengan tetap mengakses dan
          menggunakan Situs maupun Layanan Hiregrade lainnya, maka Pengguna
          dianggap menyetujui perubahan-perubahan dalam Kebijakan Privasi ini.
          &nbsp;
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <span>XII. KOMUNIKASI</span>
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Jika Anda memiliki pertanyaan mengenai Data Pribadi anda, mohon
          hubungi:
        </p>
        <p style={this.props.style ? this.props.style : content}>
          PT. Daya Selaras Karya
        </p>
        <p style={this.props.style ? this.props.style : content}>
          EightyEight @Kasablanka Office Tower, Lantai 18 unit A-H.
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Jl. Casablanca Kav.88, Jakarta Selatan. 12870
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Email : info@hiregrade.id
          {/* Email : hg.support@ikonsultan.com */}
        </p>
        <p style={this.props.style ? this.props.style : content}>
          Telepon : 021-2960 7600
        </p>
        <p style={this.props.style ? this.props.style : content}>
          <br />
        </p>
      </div>
    );
  }
}
