import React from "react";
import PropTypes from "prop-types";
import VideoRecorder from "./video-recorder";
import DialogueInfo from "../modal/info";

/**
 * @extends {React.Component<{timeLimit:number, countdownTime:number}>}
 */
class RecordVideo extends React.Component {
  static propTypes = {
    timeLimit: PropTypes.number,
    countdownTime: PropTypes.number,
  };
  static defaultProps = {
    onSubmit: () => {},
    onStop: () => {},
    onRetake: () => {},
  };
  state = {
    cameraoff: false,
    blobUrl: null,
  };

  onFinish(blobUrl) {
    this.setState({ blobUrl });
  }
  render() {
    const { cameraoff } = this.state;
    const { chunkSize, timeLimit, countdownTime, lastQuestion } = this.props;

    return (
      <>
        <DialogueInfo open={cameraoff} />
        <div
          style={{
            width: 730,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="VideoRecorder">
          <VideoRecorder
            showReplayControls={true}
            lastQuestion={lastQuestion}
            timeLimit={timeLimit ? timeLimit * 60 * 1000 : null}
            countdownTime={countdownTime ? countdownTime * 1000 : null}
            replayVideoAutoplayAndLoopOff
            chunkSize={chunkSize ? chunkSize : 10}
            isFlipped={true}
            isOnInitially
            dataAvailableTimeout={5000}
            onSubmit={vb => {
              this.props.onSubmit(vb);
            }}
            onRecordingComplete={() => {
              this.props.onStop();
            }}
            onStopReplaying={() => {
              this.props.onRetake();
            }}
          />
        </div>
      </>
    );
  }
}
export default RecordVideo;
