import { palette } from "assets/css/main";
import { IcnInfoFull } from "assets/img";
import React from "react";
import { withTranslation } from "react-i18next";
import ComponentDescription from "../create/com-description";
import ComponentTimeSlot from "../create/com-time-slot";
import ComponentCandidateSelect from "../create/com-candidate-select";
import { Button } from "@material-ui/core";
import dataSchedule from "../data-schedule";
import { DialogueInfo, FormFooter, RecruiterHeader } from "app/components";
import {
  AppHistory,
  removeItem,
  RequestGet,
  RequestPut,
  SetLocalItem,
} from "app/utils";
import alertData from "app/components/alert/alert-data";
export default withTranslation("interview-schedule")(
  class ScheduleEdit extends React.Component {
    state = {
      active: 0,
      isLoading: true,
      edit: false,
      interview: {},
      warningCandidate: false,
    };

    componentDidMount() {
      this.getInterview();
      setTimeout(() => {
        this.changeActive(0);
      }, 500);
    }

    cancelChange() {
      const {
        interview,
        listInterviewDetail,
        listInterviewCandidate,
      } = this.state;

      dataSchedule.interview = interview;
      dataSchedule.listInterviewDetail = listInterviewDetail;
      dataSchedule.listInterviewCandidate = listInterviewCandidate;
    }

    getInterview() {
      const {
        location: { state: data },
      } = AppHistory;
      if (!data) {
        return AppHistory.push("/recruiter/home");
      }
      this.setState({ isLoading: true });
      RequestGet("interview/" + data.scheduleId)
        .then(r => {
          const { interview, interviewDetail, interviewCandidate } = r.data;
          return {
            interview: interview,
            interviewDetail: interviewDetail.map(v => {
              return {
                duration: v.duration,
                tmpDur: { value: v.duration, label: v.duration },
                id: v.id,
                date: new Date(v.timeStart),
                interviewId: v.interviewId,
                timeEnd: v.timeEnd,
                isEdit: v.isEdit ? v.isEdit : false,
                timeStart: v.timeStart,
                timezone: v.timezone,
              };
            }),
            interviewCandidate: interviewCandidate,
          };
        })
        .then(d => {
          const { interview, interviewDetail, interviewCandidate } = d;

          this.setState({
            interview: interview,
            listInterviewDetail: interviewDetail,
            listInterviewCandidate: interviewCandidate,
          });

          SetLocalItem("SCH_D" + data.id_vacancy, interview);
          SetLocalItem("SCH_T" + data.id_vacancy, interviewDetail);
          SetLocalItem("SCH_L" + data.id_vacancy, interviewCandidate);

          dataSchedule.interview = interview;
          dataSchedule.listInterviewDetail = interviewDetail;
          dataSchedule.listInterviewCandidate = interviewCandidate
            ? interviewCandidate
            : [];
          setTimeout(() => {
            this.setState({ isLoading: false });
          }, 500);
        })
        .catch(e => {
          console.log("getInterview()", e);
          this.setState({ isLoading: false });
        });
    }

    submit() {
      const {
        location: { state: data },
      } = AppHistory;
      const { t } = this.props;
      const d = {
        interview: dataSchedule.interview,
        listInterviewDetail: dataSchedule.listInterviewDetail,
        listInterviewCandidate: dataSchedule.listInterviewCandidate,
      };
      if (
        dataSchedule.listInterviewDetail.length <
        dataSchedule.listInterviewCandidate.length
      ) {
        this.setState({ warningCandidate: true });
        return false;
      }

      RequestPut("interview/" + data.scheduleId, d)
        .then(() => {
          alertData.show(t("messages.successUpdate"), "success");
          AppHistory.push("/recruiter/schedule/" + data.id_vacancy);
          SetLocalItem("SCH_D" + data.id_vacancy, null);
          SetLocalItem("SCH_T" + data.id_vacancy, null);
          SetLocalItem("SCH_L" + data.id_vacancy, null);

          removeItem("SCH_D" + data.id_vacancy);
          removeItem("SCH_T" + data.id_vacancy);
          removeItem("SCH_L" + data.id_vacancy);

          dataSchedule.reset();
        })
        .catch(e => {
          alertData.show(t("messages.failedUpdate"), "error");
          console.log("postData()", e);
        });
    }

    handleBack() {
      AppHistory.goBack();
    }

    saveChange() {
      this.setState({ edit: false });
    }

    changeActive(a = 0) {
      this.setState({
        active: a,
        interview: JSON.parse(JSON.stringify(dataSchedule.interview)),
        listInterviewDetail: JSON.parse(
          JSON.stringify(dataSchedule.listInterviewDetail)
        ),
        listInterviewCandidate: JSON.parse(
          JSON.stringify(dataSchedule.listInterviewCandidate)
        ),
      });
      this.cancelChange();
    }

    render() {
      const { t } = this.props;
      const { active, edit, isLoading, warningCandidate } = this.state;
      const step = t("step", { returnObjects: true });
      return (
        <div style={{ marginTop: 80 }}>
          <RecruiterHeader shadow={true} menu="dashboard" />
          <DialogueInfo
            title={t("messages.maxCandidateTitle")}
            content={t("messages.maxCandidateContent")}
            open={warningCandidate}
            onClose={() => {
              this.setState({ warningCandidate: false });
            }}
          />
          <FormFooter
            prevText={t("general:button.back")}
            nextText={t("general:button.save")}
            backArrow={false}
            OnClickBack={() => {
              this.handleBack();
            }}
            OnClickNext={() => {
              this.submit();
            }}
          />
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              boxSizing: "border-box",
              minHeight: "50vw",
              color: palette.primary,
            }}>
            <div
              style={{
                width: "20%",
                minHeight: "100%",
                backgroundColor: palette.grey,
                display: "flex",
                justifyContent: "flex-end",
                padding: "20px 20px 0px 0px",
              }}>
              <div style={{ width: "80%" }}>
                <p style={{ width: "100%", margin: 0 }}>{t("summary.title")}</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderBottom: "1px solid " + palette.darkGrey,
                    padding: "10px 0px",
                    marginBottom: 10,
                  }}>
                  <img
                    src={IcnInfoFull}
                    alt="Info"
                    style={{ width: 18, height: 18, marginRight: 10 }}
                  />
                  <p
                    style={{ width: "100%", margin: 0, fontWeight: "lighter" }}>
                    {t("summary.description")}
                  </p>
                </div>
                <div
                  onClick={() => {
                    this.setState({ active: 0, edit: false });
                  }}
                  style={{
                    padding: "10px 25px",
                    backgroundColor: active === 0 ? palette.info : "",
                    fontWeight: "lighter",
                    cursor: "pointer",
                  }}>
                  {step[0]}
                </div>
                <div
                  onClick={() => {
                    this.setState({ active: 1, edit: false });
                  }}
                  style={{
                    padding: "10px 25px",
                    backgroundColor: active === 1 ? palette.info : "",
                    fontWeight: "lighter",
                    cursor: "pointer",
                  }}>
                  {step[1]}
                </div>
                <div
                  onClick={() => {
                    this.setState({ active: 2, edit: false });
                  }}
                  style={{
                    padding: "10px 25px",
                    backgroundColor: active === 2 ? palette.info : "",
                    fontWeight: "lighter",
                    cursor: "pointer",
                  }}>
                  {step[2]}
                </div>
              </div>
            </div>
            {!isLoading && (
              <div
                style={{
                  width: "80%",
                  minHeight: "100%",
                  padding: "20px 60px",
                  position: "relative",
                  marginTop: 20,
                  paddingBottom: 10,
                  marginBottom: 100,
                }}>
                {active === 0 ? (
                  <ComponentDescription
                    editSchedule={edit}
                    submitted={() => {
                      this.saveChange();
                    }}
                  />
                ) : (
                  ""
                )}
                {active === 1 ? (
                  <ComponentTimeSlot
                    editSchedule={edit}
                    submitted={() => {
                      this.saveChange();
                    }}
                  />
                ) : (
                  ""
                )}
                {active === 2 ? (
                  <ComponentCandidateSelect
                    editSchedule={edit}
                    submitted={() => {
                      this.saveChange();
                    }}
                  />
                ) : (
                  ""
                )}
                <div
                  style={{
                    display: !edit ? "none" : "flex",
                    justifyContent: "flex-end",
                    width: 600,
                    paddingTop: 20,
                    borderTop: "1px solid " + palette.darkGrey,
                  }}>
                  <Button
                    onClick={() => {
                      this.cancelChange();
                    }}
                    style={{
                      backgroundColor: "white",
                      textTransform: "capitalize",
                      padding: "6px 30px",
                      color: palette.primary,
                      border: "1px solid " + palette.primary,
                      marginRight: 20,
                    }}>
                    {t("general:button.cancel")}
                  </Button>
                  <Button
                    onClick={() => {
                      this.submit();
                    }}
                    style={{
                      backgroundColor: palette.primary,
                      textTransform: "capitalize",
                      padding: "6px 30px",
                      color: "white",
                      border: "1px solid " + palette.primary,
                    }}>
                    {t("general:button.save")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
);
