import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { Link } from "react-router-dom";
import { ImgApplicant } from "assets/img";
import { isMobile } from "react-device-detect";

export default class Applicant extends React.Component {
  render() {
    if (isMobile) {
      return (
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          direction="column"
          style={{
            backgroundColor: palette.bg,
            minHeight: 100,
            width: "100%",
            padding: "40px 25px",
          }}>
          <Typography
            style={{
              marginBottom: 20,
              color: "white",
              fontWeight: "bold",
              fontSize: 24,
            }}>
            About Us
          </Typography>
          <img
            src={ImgApplicant}
            alt="Applicant"
            style={{ width: 167, height: 203, padding: "35px 0px" }}
          />
          <div
            style={{
              width: "100%",
              maxWidth: 496,
              color: "white",
              fontSize: 16,
              paddingTop: 20,
            }}>
            <p style={{ marginBottom: 40 }}>
              ”a 3-sided digital platform that facilitates the needs of fresh
              graduates, educational institutions and corporations through
              accurate people profiling for better recruitment and development
              processes”
            </p>
            <Link
              to="/home/about"
              style={{
                color: "white",
                textDecoration: "underline",
                fontSize: 14,
                opacity: 0.6,
              }}>
              LEARN MORE
            </Link>
          </div>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          style={{
            backgroundColor: palette.bg,
            height: 620,
          }}>
          <div
            style={{
              display: "flex",
              padding: "120px 0px",
              justifyContent: "space-around",
              width: "100%",
            }}>
            <div>
              <Typography
                style={{
                  marginBottom: 20,
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 32,
                }}>
                About Us
              </Typography>
              <div
                style={{
                  width: "100%",
                  maxWidth: 496,
                  color: "white",
                  fontSize: 20,
                  marginBottom: 50,
                }}>
                ”a 3-sided digital platform that facilitates the needs of fresh
                graduates, educational institutions and corporations through
                accurate people profiling for better recruitment and development
                processes”
              </div>
              <Link
                to="/home/about"
                style={{
                  color: "white",
                  textDecoration: "underline",
                  fontSize: 20,
                }}>
                LEARN MORE
              </Link>
            </div>
            <div>
              <img
                src={ImgApplicant}
                alt="Applicant"
                style={{ width: 309, height: 377 }}
              />
            </div>
          </div>
        </Grid>
      );
    }
  }
}
