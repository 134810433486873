import React from "react";
import { Button, Checkbox, Tooltip } from "@material-ui/core";
import { Help, MoreHoriz } from "@material-ui/icons";
import { ButtonFilterStatus } from "app/components";
import { findObject, getItem, t } from "app/utils";
import PropTypes from "prop-types";
import { palette } from "assets/css/main";
import { IcnOfferingPdf } from "assets/img";

import { withStyles } from "@material-ui/core/styles";
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#2F3168",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default class ProposedTableView extends React.Component {
  static defaultProps = {
    status: "PRO",
    candidates: [],
    arrcheck: [],
    onOpenProximity: () => {},
    onClickSelect: () => {},
    onClickMore: () => {},
  };
  static propTypes = {
    status: PropTypes.string,
    candidates: PropTypes.array,
    arrcheck: PropTypes.array,
    onOpenProximity: PropTypes.func,
    onClickMore: PropTypes.func,
  };

  openProximity = value => {
    this.props.onOpenProximity(value);
  };

  clickSelect = (x, y, z, c) => {
    this.props.onClickSelect(x, y, z, c);
  };

  clickMore = val => {
    this.props.onClickMore(val);
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { candidates, arrcheck, status } = this.props;
    const gender = t("biodata:options.gender", {
      returnObjects: true,
    });
    const offeringStatus = t("candidates:offeringStatus", {
      returnObjects: true,
    });
    return (
      <>
        <table
          cellPadding={8}
          style={{
            backgroundColor: "white",
            position: "fixed",
            zIndex: 1,
            borderTop: "1px solid " + palette.primary,
            paddingTop: 10,
            color: "grey",
            width: "100%",
            padding: "0px 100px",
            boxShadow: "rgb(0 0 0 / 9%) 1px 2px 4px",
          }}>
          <thead>
            <tr>
              <th width={"25%"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <div style={{ width: 74, marginRight: 10 }}></div>
                  <div>{t("candidates:headerTableName.fullName")}</div>
                </div>
              </th>
              <th align="left" width={"10%"}>
                <div>
                  No. ID
                  <span>{t("candidates:headerTableName.gender")}</span>
                </div>
              </th>
              <th align="center" width={"20%"}>
                {t("candidates:headerTableName.recruitmentStatus")}
              </th>
              <th align="center" width={"10%"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}>
                  {t("candidates:headerTableName.proximity")}
                  <LightTooltip
                    title={t("candidates:tooltipmessages.proximity")}
                    arrow>
                    <Help
                      style={{
                        fontSize: 20,
                        color: "#2F3168",
                      }}
                    />
                  </LightTooltip>
                </div>
              </th>
              <th width="15%" align="center">
                {t("candidates:headerTableName.uploadOffering")}
              </th>
              <th width="10%" align="center">
                {t("candidates:headerTableName.offeringStatus")}
              </th>
              <th align="center" width={"10%"}>
                Action
              </th>
            </tr>
          </thead>
        </table>
        <div style={{ padding: "90px 100px 30px" }}>
          {candidates.map((v, k) => {
            var filenameoffering = "";
            if (v.offeringFile === null) {
              filenameoffering = "";
            } else {
              var filename = v.offeringFile.split("/");
              filenameoffering = filename[6];
            }

            return (
              <table
                cellPadding={8}
                style={{
                  backgroundColor: "white",
                  boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                  borderRadius: 4,
                  width: "100%",
                  marginTop: 10,
                }}
                key={"list-selected" + k}>
                <tbody>
                  <tr>
                    <td width="25%" valign="middle">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}>
                        <div
                          style={{
                            width: 74,
                            height: 74,
                            borderRadius: "50%",
                            backgroundColor: "grey",
                            backgroundSize: "cover",
                            marginRight: 10,
                            backgroundRepeat: "no-repeat",
                            backgroundImage: v.photo
                              ? "url(" + v.photo + ")"
                              : "",
                          }}></div>
                        <div style={{ width: 190 }}>
                          {v.scheduleStatus === 2 ? (
                            <p style={{ margin: 0 }}>{v.name}</p>
                          ) : (
                            <p
                              style={{
                                margin: 0,
                                color: "#C4C4C4",
                                fontWeight: "lighter",
                              }}>
                              {t("candidates:fullnamenotAppears")}
                            </p>
                          )}
                        </div>
                      </div>
                    </td>
                    <td width="10%">
                      <p style={{ margin: 0, paddingTop: 10 }}>
                        {v.candidateCode}
                        <span style={{ textTransform: "capitalize" }}>
                          <label>
                            {findObject(gender, "value", v.gender, false).label}
                          </label>
                        </span>
                      </p>
                    </td>

                    <td width="20%" align="left" valign="baseline">
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          gridTemplateColumns: "auto auto",
                        }}>
                        {v.marketStatus.map((r, i) => {
                          if (r.count > 0) {
                            return (
                              <div key={i}>
                                <ButtonFilterStatus
                                  className={"Status"}
                                  disabled={true}
                                  onClick={() => {}}
                                  style={{
                                    height: 27,
                                    color: "#FFB031",
                                    border: "none",
                                    backgroundColor: "rgba(255, 176, 49, 0.3)",
                                    fontWeight: "normal",
                                    textTransform: "lowercase",
                                    opacity: 3,
                                    margin: 5,
                                  }}
                                  index={1}
                                  active={1}
                                  text={
                                    getItem("lang") === "en"
                                      ? r.count + " " + r.textEng
                                      : r.count + " " + r.textIna
                                  }
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </td>
                    <td width="10%" align="center" valign="middle">
                      <div style={{ position: "relative" }}>
                        <Button
                          className={"Proximity"}
                          onClick={() => {
                            this.openProximity({
                              openDialogProximity: true,
                              userId: v.userId,
                            });
                          }}
                          style={{
                            color: "rgb(46, 105, 178)",
                            fontSize: 14,
                            height: 27,
                            padding: "0 15",
                            textTransform: "lowercase",
                            background: "rgb(46, 105, 178, 0.3)",
                            border: "none",
                            borderRadius: 20,
                            opacity: 1,
                            fontWeight: "normal",
                          }}>
                          {v.proximity} %
                        </Button>
                      </div>
                    </td>
                    <td width="15%" align="center" valign="middle">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}>
                        {v.offeringFile !== null || v.offeringFile === "" ? (
                          <a
                            style={{
                              display: "flex",
                              justifyItems: "center",
                              alignItems: "center",
                            }}
                            href={v.offeringFile}>
                            <img
                              src={IcnOfferingPdf}
                              alt="Offering Icon"
                              style={{ marginRight: 5 }}
                            />
                            <label style={{ cursor: "pointer" }}>
                              {filenameoffering}
                            </label>
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                    </td>
                    <td width="10%" align="center" valign="middle">
                      <div
                        style={{
                          position: "relative",
                        }}>
                        <Button
                          className={"interviewResult"}
                          disabled={true}
                          onClick={() => {}}
                          style={{
                            color: "#52C8F4",
                            fontSize: 14,
                            height: 47,
                            padding: "0 15",
                            textTransform: "none",
                            background: "rgba(82, 200, 244, 0.2)",
                            border: "none",
                            borderRadius: 20,
                            marginRight: 15,
                            opacity: 1,
                            fontWeight: "normal",
                          }}>
                          {v.isAcceptOffer === null
                            ? offeringStatus[2]
                            : v.isAcceptOffer
                            ? offeringStatus[0]
                            : offeringStatus[1]}
                        </Button>
                      </div>
                    </td>
                    <td width="10%" align="center" valign="middle">
                      <div
                        style={{
                          display: "flex",
                          justifyItems: "center",
                          width: 70,
                          alignItems: "center",
                        }}>
                        {findObject(v.marketStatus, "status", "HIR", false)
                          .count === 0 &&
                          v.isAcceptOffer && (
                            <Checkbox
                              className="ProposedCheckBoxList"
                              style={{ paddingBottom: 0 }}
                              checked={arrcheck[k]}
                              onClick={() => {
                                this.clickSelect(k, v.userId, status, v);
                              }}
                            />
                          )}
                        <Button
                          onClick={e => {
                            this.clickMore({
                              userId: v.userId,
                              userStatus: v.userId,
                              currentTarget: e.currentTarget,
                              anchorAction: e.currentTarget,
                              menuMore: e.currentTarget.getBoundingClientRect(),
                              hiredStatus:
                                findObject(
                                  v.marketStatus,
                                  "status",
                                  "HIR",
                                  false
                                ).count > 0
                                  ? true
                                  : false,
                              filename: v.name ? v.name : v.candidateCode,
                            });
                          }}
                          style={{
                            padding: 0,
                          }}>
                          <MoreHoriz style={{ color: "grey" }} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
        </div>
      </>
    );
  }
}
