import React from "react";
import {
  Dialog,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
} from "@material-ui/core";
import { palette, PRIMARY_BUTTON } from "assets/css/main";
import propTypes from "prop-types";
import Moment from "react-moment";
import { RequestGet } from "app/utils";

class DialogueMovetoActiveVacancy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vacancyActives: [],
      isLoading: true,
      isEnable: false,
    };
  }

  componentDidMount() {
    this.getVacancy();
  }

  getVacancy() {
    this.setState({ isLoading: true });
    RequestGet("vacancy/summary")
      .then(res => {
        // console.log(res)
        this.setState({
          isLoading: false,
          vacancyActives: res.data.vacancyActives,
        });
      })
      .catch(er => {
        this.setState({ isLoading: false });
      });
  }

  onChangeSelectVacancy(vacancyId) {
    console.log(vacancyId.selectedVacancy);
    this.setState({
      selectedVacancy: vacancyId.selectedVacancy,
      isEnable: true,
    });
  }

  render() {
    const {
      t,
      open,
      title,
      content,
      btnYes,
      btnNo,
      time,
      vacancyId,
    } = this.props;
    const { vacancyActives, isEnable } = this.state;
    return (
      <Dialog
        className={"Custom-Dialog-Move-to-act-Vacancy"}
        aria-labelledby="customized-dialog-title"
        disableBackdropClick
        open={open}
        style={{ backgroundColor: "transparent" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            paddingTop: "50px",
          }}>
          <div
            style={{
              width: 674,
              position: "relative",
            }}>
            <DialogTitle
              id="form-dialog-title"
              style={{
                color: palette.primary,
                borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                backgroundColor: "white",
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                paddingTop: 30,
              }}>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 10,
                  fontSize: 18,
                  fontWeight: "normal",
                }}>
                {title}
              </Typography>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 10,
                  fontSize: 14,
                  fontWeight: "normal",
                }}>
                {content}
                <span
                  style={{
                    color: palette.primary,
                    marginBottom: 10,
                    fontSize: 14,
                    borderBottom: "1px solid #DFE5F4",
                  }}></span>
              </Typography>
            </DialogTitle>
            <DialogContent
              style={{ backgroundColor: "white", color: palette.primary }}>
              <Select
                style={{
                  color: palette.primary,
                  fontWeight: "bold",
                  width: "97%",
                }}
                className="Field select-with-placeholder"
                value={
                  this.state.selectedVacancy ? this.state.selectedVacancy : " "
                }
                onChange={v => {
                  this.onChangeSelectVacancy({
                    selectedVacancy: v.target.value,
                  });
                }}>
                <MenuItem value=" " disabled>
                  <span>
                    {t("candidates:MovetoActiveVacancyDialog.selectVacacny")}
                  </span>
                </MenuItem>
                {vacancyActives.map(
                  (v, i) =>
                    v.vacancyId === vacancyId ? null : (
                      <MenuItem
                        key={i}
                        style={{ color: palette.primary }}
                        value={v.vacancyId}>
                        {v.vacancyName}
                      </MenuItem>
                    )

                  //   return (

                  //   );
                )}
              </Select>
              {time && (
                <div
                  style={{
                    backgroundColor: palette.info,
                    color: palette.primary,
                    padding: 10,
                    marginBottom: 30,
                    textAlign: "center",
                  }}>
                  <Moment
                    date={time}
                    add={{ days: 3, hours: 12 }}
                    format="dddd, DD MMMM yyyy hh:mm \WIB"
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                backgroundColor: "white",
                paddingLeft: 270,
                paddingRight: 40,
                paddingBottom: 25,
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
              }}>
              <Button
                style={{
                  color: palette.primary,
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  border: "1px solid #2E69B2",
                  width: 128,
                  height: 44,
                }}
                onClick={() => {
                  this.props.onClose();
                  this.setState({
                    selectedVacancy: " ",
                    isEnable: false,
                  });
                }}>
                {btnNo}
              </Button>
              <Button
                disabled={!isEnable}
                style={{
                  ...PRIMARY_BUTTON,
                  textTransform: "capitalize",
                  color: "white",
                  width: 128,
                  height: 44,
                  opacity: isEnable ? 1 : 0.5,
                }}
                onClick={() => {
                  this.props.onOK(this.state);
                  this.setState({
                    selectedVacancy: " ",
                    isEnable: false,
                  });
                }}>
                {btnYes}
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    );
  }
}

DialogueMovetoActiveVacancy.propTypes = {
  title: propTypes.string,
  content: propTypes.string,
  open: propTypes.bool,
  onClose: propTypes.func,
  onOK: propTypes.func,
  btnYes: propTypes.string,
  btnNo: propTypes.string,
};

DialogueMovetoActiveVacancy.defaultProps = {
  btnYes: "Yes",
  btnNo: "No",
  title: "Sample title",
  content: "Success",
  open: true,
  onOK: () => {
    return true;
  },
  onClose: () => {
    return false;
  },
};

export default DialogueMovetoActiveVacancy;
