import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { REC_STYLE, palette } from "assets/css/main";
import {
  Typography,
  Button,
  Grow,
  MenuItem,
  Select,
  InputBase,
} from "@material-ui/core";
import {
  ArrowDropDown,
  ArrowDropUp,
  Search,
  MoreHoriz,
} from "@material-ui/icons";
import "../recruiter.css";
import {
  getItem,
  RequestGet,
  AppHistory,
  RequestPut,
  findObject,
} from "app/utils";
import {
  LoadingContentCandidate,
  DialogueInfo,
  DialogueFull,
  HGPagination,
} from "app/components";
import {
  ControlledMenu,
  MenuItem as MenuItemRM,
  SubMenu,
} from "@szhsin/react-menu";
import DialogueConfirm from "app/components/modal/confirm";
import DialogueMovetoActiveVacancy from "app/pages/recruiter/candidates/move-to-active-vacancy";
import { IconFolder, IconConfirmationCheck } from "assets/img";
import TemporaryView from "./download-info/temporary-view";
import SelectFilterCandidate from "app/components/recruiter/select-filter-candidate";
const filterButton = {
  color: palette.primary,
  fontSize: 14,
  height: 33,
  padding: "0px 15px",
  textTransform: "capitalize",
  background: "rgba(46, 105, 178, 0.2)",
  border: "1px solid #2E69B2",
  borderRadius: 20,
  marginRight: 15,
};
const background = (state, status) => {
  return state === status ? "rgba(46, 105, 178, 0.2)" : "";
};
const opacity = (state, status) => {
  return state === status ? 1 : 0.5;
};

const statusFilter = [
  "Overall",
  "Selected",
  "In Process",
  "Proposed",
  "Hired",
  "Archived",
];

class ButtonFilterStatus extends React.Component {
  state = {
    status: "",
  };
  componentDidMount() {
    const { index } = this.props;
    this.setState({ status: index === "ALL" ? "ALL" : "" });
  }
  render() {
    const { status } = this.state;
    const {
      active,
      index,
      text,
      onClick,
      style,
      disabled,
      className,
    } = this.props;
    return (
      <Button
        className={className}
        disabled={disabled}
        onClick={() => {
          onClick(index);
          this.setState({ status: index });
        }}
        style={{
          ...filterButton,
          opacity: opacity(status, active),
          backgroundColor: background(status, active),
          ...style,
        }}>
        {text}
      </Button>
    );
  }
}
class CandidatesList extends React.Component {
  state = {
    status: "ALL",
    advancedFilter: false,
    sortBy: "-",
    sortValue: "",
    sortDirection: "",
    isLoading: true,
    candidates: [],
    candidateCount: [],
    anchorMore: null,
    openconfirmUnassign: false,
    openUnassignSuccessConfirm: false,
    openconfirmActive: false,
    opendialogMovetoActive: false,
    openMovetoActiveSuccessConfirm: false,
    statusCandidate: "",
    isAssign: false,
    anchorSelectFilter: null,
    keyword: "",
    gender: "",
    proxmin: "",
    proxmax: "",
    workmax: "",
    workmin: "",
    selected: false,
    proposed: false,
    inprocess: false,
    gpamin: "",
    gpamax: "",
  };
  componentDidMount() {
    this.getCandidate();
  }
  getCandidate(page = 1) {
    const {
      keyword,
      gender,
      proxmin,
      proxmax,
      workmax,
      workmin,
      gpamin,
      gpamax,
      sortValue,
      sortDirection,
      selected,
      inprocess,
      proposed,
    } = this.state;

    this.setState({ isLoading: true });

    const sm = [];

    if (selected) {
      sm.push("SEL");
    }
    if (inprocess) {
      sm.push("INP");
    }
    if (proposed) {
      sm.push("PRO");
    }
    const statmarketplace = sm.join(",");

    RequestGet("candidate", {
      loading: false,
      status: this.state.status,
      limit: 10,
      page: page,
      search: keyword,
      sort: sortValue,
      sortValue: sortDirection,
      statmarketplace,
      gender,
      proxmin,
      proxmax,
      workmax,
      workmin,
      gpamin,
      gpamax,
    })
      .then(res => {
        this.setState({ isLoading: false, ...res.data });
      })
      .catch(er => {
        this.setState({ isLoading: false });
        console.log(er);
      });
  }
  handleFilterStatus(idx) {
    this.setState({ status: idx, candidates: [] }, () => {
      this.getCandidate();
    });
  }
  movetoUnassign() {
    var vacancyId = this.state.vacancyId;
    var status = "UNA";
    var listUser = [];
    listUser.push({ candidateId: this.state.userId });

    RequestPut("vacancy/candidate", {
      vacancyId,
      status,
      listUser,
      loading: true,
    })
      .then(r => {
        this.setState({
          openUnassignSuccessConfirm: true,
          openconfirmUnassign: false,
        });
      })
      .catch(e => {
        console.log(e);
      });
  }
  clikOkSuccesUnassignedPoll() {
    this.setState({
      openUnassignSuccessConfirm: false,
    });
    this.getCandidate();
  }

  movetoActive(obj) {
    var vacancyId = obj.selectedVacancy;
    var status = "SEL";
    var listUser = [];
    listUser.push({ candidateId: this.state.userId });

    RequestPut("vacancy/candidate", {
      vacancyId,
      status,
      listUser,
      loading: true,
    })
      .then(r => {
        this.setState({
          opendialogMovetoActive: false,
          openMovetoActiveSuccessConfirm: true,
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  clikOkSuccesMovetoActiveVacancy() {
    this.setState({
      openMovetoActiveSuccessConfirm: false,
    });
    this.getCandidate();
  }

  resetFilter = () => {
    this.setState({
      gpamin: "",
      gpamax: "",
      proxmin: "",
      proxmax: "",
      workmax: "",
      workmin: "",
      sortBy: "-",
      sortValue: "",
      sortDirection: "",
      keyword: "",
      gender: "",
      selected: false,
      inprocess: false,
      proposed: false,
    });
  };

  render() {
    const {
      status,
      candidateCount,
      advancedFilter,
      // selectFilter,
      sortBy,
      keyword,
      candidates,
      isLoading,
      anchorMore,
      openconfirmUnassign,
      openUnassignSuccessConfirm,
      opendialogMovetoActive,
      openMovetoActiveSuccessConfirm,
      statusCandidate,
      isAssign,
      userId,
      openDownload,
      filename,
      anchorSelectFilter,
    } = this.state;
    const { t } = this.props;
    return (
      <div className="recruiter">
        <MyHelmet title="Candidate" />
        <RecruiterAppBar menu="candidates" shadow={true} />
        <DialogueFull
          hideClose={true}
          style={{ width: 200 }}
          open={openDownload}>
          <TemporaryView
            onDownload={() => this.setState({ openDownload: false })}
            id={userId}
            status={statusCandidate}
            filename={filename}
          />
        </DialogueFull>

        <SelectFilterCandidate
          anchorSelectFilter={anchorSelectFilter}
          onChange={v => {
            this.setState(v);
          }}
          ApplyFilter={() => {
            this.setState({ anchorSelectFilter: null });
          }}
          state={this.state}
        />
        {/* more action */}
        <ControlledMenu
          anchorPoint={anchorMore}
          isOpen={Boolean(anchorMore)}
          styles={{
            fontWeight: "lighter",
            marginTop: 40,
            zIndex: 2,
            color: palette.primary,
          }}
          onClose={() => {
            this.setState({
              anchorMore: null,
            });
          }}>
          <SubMenu label={t("candidates:menu.view")}>
            <MenuItemRM
              onClick={() => {
                AppHistory.push(
                  "/recruiter/candidates/information/cv/" + userId
                );
              }}
              styles={{
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("candidates:candidateInfo.lcv")}
            </MenuItemRM>
            <MenuItemRM
              onClick={() => {
                this.setState({
                  openDownload: true,
                  anchorMore: null,
                });
              }}
              styles={{
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("candidates:candidateInfo.dc")}
            </MenuItemRM>
          </SubMenu>

          {isAssign &&
            statusCandidate !== "Unassign" &&
            statusCandidate !== "Hired" && (
              <MenuItemRM
                onClick={e => {
                  this.setState({
                    openconfirmUnassign: true,
                    anchorMore: null,
                  });
                }}
                styles={{
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("candidates:menu.moveUnasign")}
              </MenuItemRM>
            )}
          {!isAssign && (
            <MenuItemRM
              onClick={e => {
                this.setState({
                  opendialogMovetoActive: true,
                  anchorMore: null,
                });
              }}
              styles={{
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("candidates:menu.movetoActive")}
            </MenuItemRM>
          )}
        </ControlledMenu>

        {/* Unassigned Pool Dialog */}
        <DialogueConfirm
          open={openconfirmUnassign}
          title={t("candidates:MovetoUnassignconfirmation.title")}
          content={t("candidates:MovetoUnassignconfirmation.message")}
          contentDetail={t(
            "candidates:MovetoUnassignconfirmation.messageDetail"
          )}
          contentWidth={400}
          btnYes={t("candidates:MovetoUnassignconfirmation.btnOk")}
          btnNo={t("candidates:MovetoUnassignconfirmation.btnCancel")}
          onOK={() => this.movetoUnassign(userId)}
          onClose={() => {
            this.setState({ openconfirmUnassign: false });
          }}
        />
        <DialogueInfo
          title={t("candidates:MovetoActiveVacancySuccessconfirmation.title")}
          content={t(
            "candidates:MovetoActiveVacancySuccessconfirmation.message"
          )}
          open={openMovetoActiveSuccessConfirm}
          onClose={() => {
            this.clikOkSuccesMovetoActiveVacancy();
          }}
        />

        {/* Send Offering File Dialog */}
        <DialogueMovetoActiveVacancy
          open={opendialogMovetoActive}
          vacancyId={this.state.vacancyId}
          title={t("candidates:MovetoActiveVacancyDialog.title")}
          content={t("candidates:MovetoActiveVacancyDialog.message")}
          btnYes={t("candidates:MovetoActiveVacancyDialog.btnOk")}
          btnNo={t("candidates:MovetoActiveVacancyDialog.btnCancel")}
          onOK={res => {
            if (res) {
              this.movetoActive(res);
            }
          }}
          onClose={() => {
            this.setState({ opendialogMovetoActive: false });
          }}
          t={this.props.t}
        />
        <DialogueInfo
          title={t("candidates:UnassignSuccessconfirmation.title")}
          content={t("candidates:UnassignSuccessconfirmation.message")}
          open={openUnassignSuccessConfirm}
          onClose={() => {
            this.clikOkSuccesUnassignedPoll();
          }}
        />

        <div style={{ ...REC_STYLE.BODY_STYLE, color: palette.primary }}>
          {/* sub header start */}
          <div
            style={{
              ...REC_STYLE.SUB_HEADER,
              alignItems: "flex-start",
              paddingTop: 30,
            }}>
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                boxSizing: "border-box",
              }}>
              <Typography style={{ fontSize: 24 }}> Your candidates</Typography>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  margin: "20px 0px",
                  boxSizing: "border-box",
                }}>
                <div style={{ width: "80%" }}>
                  {candidateCount.map((v, k) => {
                    var tx = getItem("lang") === "id" ? v.textIna : v.textEng;
                    return (
                      <ButtonFilterStatus
                        onClick={idx => {
                          this.handleFilterStatus(idx);
                        }}
                        key={k + "sh"}
                        index={v.status}
                        active={status}
                        text={tx + " " + v.count}
                      />
                    );
                  })}
                </div>
                <div style={{ width: "20%", textAlign: "right" }}>
                  <Button
                    style={{
                      padding: "0px 0px",
                      color: palette.primary,
                      textTransform: "capitalize",
                      texthecoration: "underline",
                    }}
                    onClick={() => {
                      this.setState({ advancedFilter: !advancedFilter });
                    }}>
                    {advancedFilter ? "Hide Filter" : "Filter"}
                    {!advancedFilter ? <ArrowDropDown /> : <ArrowDropUp />}
                  </Button>
                </div>
              </div>
              <Grow in={advancedFilter} style={{ transformOrigin: "0 0 0" }}>
                <div
                  className="recruiter"
                  style={{
                    padding: "20px 0px",
                    display: advancedFilter ? "flex" : "none",
                    alignItems: "center",
                    flexDirection: "row",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      width: "70%",

                      alignItems: "center",
                      flexDirection: "row",
                    }}>
                    <Button
                      onClick={e => {
                        this.setState({
                          anchorSelectFilter: e.currentTarget,
                        });
                      }}
                      style={{
                        textTransform: "none",
                        color: palette.primary,
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        textAlign: "left",
                        marginRight: 20,
                        width: 235,
                        height: 43,
                      }}>
                      <span
                        style={{
                          color: this.state.selectFilter
                            ? palette.primary
                            : "rgba(46, 105, 178, 0.3)",
                          fontWeight: "lighter",
                          width: 250,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "50%",
                          }}>
                          {this.state.selectFilter
                            ? t("candidates:button.filterapplied")
                            : t("candidates:button.selectFilter")}
                        </span>
                        <img
                          src={IconConfirmationCheck}
                          alt={"check"}
                          height="24px"
                          align="center"
                          style={{
                            marginLeft: "10px",
                            display: this.state.selectFilter ? "" : "none",
                          }}
                        />
                        {!anchorSelectFilter ? (
                          <ArrowDropDown style={{ color: palette.primary }} />
                        ) : (
                          <ArrowDropUp style={{ color: palette.primary }} />
                        )}
                      </span>
                    </Button>
                    <Select
                      variant="outlined"
                      labelId="demo-simple-select-outlined-label"
                      className="select-with-placeholder"
                      value={sortBy}
                      style={{
                        width: 235,
                        fontSize: 14,
                        marginRight: 20,
                      }}
                      onChange={e => {
                        const sorting = e.target.value.split(" ");
                        if (sorting.length === 2) {
                          this.setState({
                            sortBy: e.target.value,
                            sortDirection: sorting[0],
                            sortValue: sorting[1],
                          });
                        } else {
                          this.setState({
                            sortBy: e.target.value,
                            sortDirection: "",
                            sortValue: "",
                          });
                        }
                      }}>
                      <MenuItem value="-" style={{ color: palette.primary }}>
                        <span>{t("candidates:button.sortBy")}</span>
                      </MenuItem>
                      <MenuItem
                        value={"desc proximity"}
                        style={{ color: palette.primary }}>
                        {t("candidates:sortbylist.Highestproximity")}
                      </MenuItem>
                      <MenuItem
                        value={"asc proximity"}
                        style={{ color: palette.primary }}>
                        {t("candidates:sortbylist.Lowestproximity")}
                      </MenuItem>
                      <MenuItem
                        value={"desc gpa"}
                        style={{ color: palette.primary }}>
                        {t("candidates:sortbylist.HighestGPA")}
                      </MenuItem>
                      <MenuItem
                        value={"asc gpa"}
                        style={{ color: palette.primary }}>
                        {t("candidates:sortbylist.LowestGPA")}
                      </MenuItem>
                      <MenuItem
                        value={"desc graduation"}
                        style={{ color: palette.primary }}>
                        {t("candidates:sortbylist.Graduation")}
                      </MenuItem>
                      <MenuItem
                        value={"desc lengthofwork"}
                        style={{ color: palette.primary }}>
                        {t("candidates:sortbylist.Lengthofwork")}
                      </MenuItem>
                    </Select>
                    <div
                      className="Search"
                      style={{
                        padding: 5,
                        paddingLeft: 10,
                        borderRadius: 4,
                        display: "flex",
                        border: "0.5px solid rgba(0,0,0,0.2)",
                        alignItems: "center",
                        width: 500,
                        marginRight: 20,
                      }}>
                      <Search
                        style={{
                          color: "rgba(0,0,0,0.3)",
                          paddingBottom: 2,
                          paddingRight: 15,
                        }}
                      />
                      <InputBase
                        value={keyword}
                        onChange={e => {
                          this.setState({ keyword: e.target.value });
                        }}
                        placeholder={t("candidates:placeholder.typeSearch")}
                        style={{ width: 450, color: palette.primary }}
                      />
                    </div>
                  </div>
                  <div style={{ width: "30%", textAlign: "left" }}>
                    <Button
                      style={{
                        minHeight: 33,
                        padding: "4px 25px",
                        border: "1px solid #2E69B2",
                        backgroundColor: "#2E69B2",
                        color: "#ffffff",
                        textTransform: "capitalize",
                        texthecoration: "underline",
                        marginRight: 20,
                      }}
                      onClick={() => {
                        this.getCandidate();
                      }}>
                      {t("text.search")}
                    </Button>
                    <Button
                      style={{
                        minHeight: 33,
                        padding: "4px 25px",
                        border: "1px solid #2E69B2",
                        backgroundColor: "#FFFFFF",
                        color: "#2E69B2",
                        textTransform: "capitalize",
                        texthecoration: "underline",
                      }}
                      onClick={() => {
                        this.resetFilter();
                        this.getCandidate();
                      }}>
                      Reset
                    </Button>
                  </div>
                </div>
              </Grow>
              <div className="list-title">
                <table
                  style={{
                    width: "100%",
                    borderTop: "1px solid " + palette.primary,
                    paddingTop: 10,
                    color: "grey",
                  }}>
                  <tbody>
                    <tr>
                      <th width="420" align="center">
                        Name
                      </th>
                      <th width="350" align="center">
                        No. ID
                        <span>Gender</span>
                      </th>
                      <th width="350" align="left">
                        Level(Major)
                        <span>University Name</span>
                      </th>
                      <th width="290" align="left">
                        GPA
                        <span>Graduated</span>
                      </th>
                      <th width="300" align="left">
                        Domicile
                        <span>Length of work</span>
                      </th>
                      <th width="170" align="left">
                        Recruitment Status
                      </th>
                      <th width="150" align="center">
                        Proximity
                        <span>Job Title</span>
                      </th>
                      <th width="100" align="center">
                        Action
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* sub header end */}
          {/* body start */}
          <div
            style={{
              padding: "20px 100px",
              paddingTop: advancedFilter ? 315 : 230,
            }}>
            <div className="list-data" style={{ width: "100%" }}>
              {isLoading &&
                statusFilter.map((v, k) => {
                  return <LoadingContentCandidate key={v + k} />;
                })}

              {candidates.length === 0 && (
                <div
                  style={{
                    padding: "230px 20px",
                  }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                      flexDirection: "column",
                    }}>
                    <div
                      style={{
                        width: 500,
                        textAlign: "center",
                      }}>
                      <div>
                        <img src={IconFolder} alt="Folder" />
                      </div>
                      <div
                        style={{ marginTop: 15, fontWeight: "lighter" }}
                        dangerouslySetInnerHTML={{
                          __html: t("candidates:text.empty"),
                        }}></div>
                    </div>
                  </div>
                </div>
              )}

              {candidates.map((v, k) => {
                return (
                  <table
                    key={"list-candidate-" + k}
                    className="item"
                    style={{
                      padding: "20px 10px 30px 10px",
                      width: "100%",
                      backgroundColor: "white",
                      boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                      borderRadius: 4,
                    }}>
                    <tbody>
                      <tr>
                        <td width="300" valign="baseline">
                          <div
                            style={{ position: "relative", paddingLeft: 110 }}>
                            <div
                              style={{
                                left: 10,
                                zIndex: 1,
                                position: "absolute",
                                width: 74,
                                height: 74,
                                borderRadius: "50%",
                                backgroundColor: "grey",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundImage: "url(" + v.photo + ")",
                              }}></div>
                            <p style={{ margin: 0, paddingTop: 10 }}>
                              {v.name}
                            </p>
                          </div>
                        </td>
                        <td width="175" align="center" valign="baseline">
                          {v.userId.split("-")[0].toUpperCase()}
                          <span style={{ textTransform: "capitalize" }}>
                            {v.gender === "M" && (
                              <label>
                                {t("candidates:dataTableName.male")}
                              </label>
                            )}
                            {v.gender === "F" && (
                              <label>
                                {t("candidates:dataTableName.female")}
                              </label>
                            )}
                          </span>
                        </td>
                        <td width="320" align="left" valign="baseline">
                          {v.major}
                          <span>{v.institution}</span>
                        </td>
                        <td width="140" align="left" valign="baseline">
                          {v.grade}
                          <span>{v.yearGraduate}</span>
                        </td>
                        <td width="300" align="center" valign="baseline">
                          <p
                            style={{
                              margin: 0,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}>
                            {v.domicile}
                          </p>
                          <span>
                            {v.lengthofWork}{" "}
                            {t("candidates:dataTableName.year")}
                          </span>
                        </td>
                        <td width="120" align="center" valign="middle">
                          {/* <div>Selected</div> */}
                          <ButtonFilterStatus
                            className={"Status"}
                            disabled={true}
                            onClick={() => {}}
                            style={{
                              color: "#3A965D",
                              border: "none",
                              backgroundColor: "#D8F1E2",
                              fontWeight: "normal",
                              fontSize: "12px",
                              opacity: "1",
                            }}
                            index={1}
                            active={1}
                            text={v.status}
                          />
                        </td>
                        <td width="150" align="center" valign="middle">
                          <p
                            style={{
                              margin: 0,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}>
                            {v.proximity} %
                          </p>
                          <span>{v.jobTitle}</span>
                        </td>
                        <td width="100" align="center" valign="middle">
                          <Button
                            className="More"
                            style={{ padding: 0 }}
                            onClick={e => {
                              console.log(
                                e.currentTarget.getBoundingClientRect()
                              );
                              this.setState({
                                anchorMore: e.currentTarget.getBoundingClientRect(),
                                vacancyId: v.vacancyId,
                                userId: v.userId,
                                statusCandidate: v.status,
                                isAssign: v.isAssign,
                                filename: v.name ? v.name : v.candidateCode,
                              });
                            }}>
                            <MoreHoriz style={{ color: "grey" }} />
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              })}
              {candidates.length > 0 && (
                <HGPagination
                  pageLimit={10}
                  totalCount={
                    findObject(candidateCount, "status", status, false).count
                  }
                  onChange={p => {
                    this.getCandidate(p);
                  }}
                />
              )}
            </div>
          </div>
          {/* body end */}
        </div>
      </div>
    );
  }
}
export default withTranslation()(CandidatesList);
