import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import {IconFolder } from "assets/img";

export default withTranslation()(
  class CVPreviewIE extends React.Component {
    render() {
      const {
        t,
        data: { listInformalEducation },
      } = this.props;

      return (
        <div style={{ color: palette.primary, padding: "0px 50px" }}>
          <div style={{ fontSize: 18 }}>{t("biodata:CVinformalEducation")}</div>
          {listInformalEducation.length === 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10%",
                  }}>
                  <div>
                    <img src={IconFolder} alt="Folder" />
                  </div>
                  <div>
                    <label style={{ color: palette.primary }}>
                    {t("biodata:CVlistnull")}
                    </label>
                  </div>
                </div>
              )}
          {listInformalEducation.map((v, k) => {
            return (
              <div
                key={k}
                style={{
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                }}>
                <div>
                  <div>
                    <br />
                  </div>
                  <div
                    style={{
                      color: palette.primary,
                      fontSize: 18,
                      fontWeight: "bolder",
                    }}>
                    {v.name}
                  </div>
                  <div
                    style={{
                      color: palette.primary,
                      fontSize: 18,
                      fontWeight: "bolder",
                    }}>
                    {v.study}
                  </div>
                  <div
                    style={{
                      color: palette.primary,
                      fontSize: 18,
                      fontWeight: "lighter",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        width: "100%",
                      }}>
                      {v.institution}
                    </div>
                  </div>
                  <div style={{ fontSize: 18, fontWeight: "lighter" }}>
                    {v.year}
                  </div>
                  <div>
                    <br />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
);
