import React from "react";
import PrimaryAppBar from "app/components/header/PrimaryAppBar";
import MyHelmet from "app/components/header/MyHelmet";
import { getItem, clearAll, RequestGet, setItem } from "app/utils";
import { Grid, Typography } from "@material-ui/core";
import { ImgEmployee } from "assets/img";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { NextBtn } from "app/components";
class Welcome extends React.Component {
  state = { name: "", email: "" };
  handleEnter = undefined;
  doc = document;
  url_next = "/initialize/biodata";
  constructor(props) {
    super(props);
    this.keyup = this.keyup.bind(this);
  }
  componentDidMount() {
    this.getUserInfo();
    // this.getBiodata();
    this.doc.addEventListener("keyup", this.keyup);
  }
  keyup(e) {
    if (e.keyCode === 13) {
      this.handleClick();
    }
  }
  componentWillUnmount() {
    this.doc.removeEventListener("keyup", this.keyup);
  }

  getStepPosition() {
    RequestGet("examattempts", {
      userId: getItem("user_id"),
    }).then(r => {
      if (r.data.examCompleted) {
        setItem("EXAM_COMPLETED", true);
        window.location.href = "/user/home";
      } else {
        if (r.data.examCategoryid === "df9b82fa-50c2-46cd-8639-2ffa73125200") {
          this.getBiodata();
        } else {
          this.getBiodata(false);
        }
      }
    });
  }

  getBiodata(first = true) {
    RequestGet("biodata")
      .then(res => {
        var data = res.data;
        if (data.personalInfo) {
          setItem("gender", data.personalInfo.gender);
        }
        this.getBiodataStatus(first);
      })
      .catch(e => {
        console.log("getBiodata()", e);
      });
  }

  getBiodataStatus(first) {
    RequestGet("biodata/status")
      .then(r => {
        const { biodataStatus } = r.data;
        if (biodataStatus === "POA") {
          if (first) {
            this.url_next = "/initialize/pretest";
          } else {
            this.url_next = "/basic-test";
          }
        }
      })
      .catch(e => {
        console.log("getBiodataStatus()", e);
      });
  }

  getUserInfo() {
    if (JSON.parse(getItem("user_info"))) {
      this.setState(JSON.parse(getItem("user_info")), () => {
        this.getStepPosition();
      });
    } else {
      clearAll();
      this.props.history.push("/");
    }
  }
  handleClick() {
    this.props.history.push(this.url_next);
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <MyHelmet title="Welcome Back" />
        <PrimaryAppBar shadow={true} />
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginTop: 64, paddingTop: 40 }}>
          <Grid item lg={6} style={{ paddingLeft: 110, paddingRight: 100 }}>
            <Typography
              style={{
                fontWeight: "bolder",
                color: palette.primary,
                fontSize: 36,
              }}>
              <span style={{ textTransform: "capitalize" }}>
                {t("welcome:hi")} {this.state.first_name},
              </span>
              <br />
              {t("welcome:greeting")}
            </Typography>
            <Typography
              style={{
                color: palette.primary,
              }}>
              {t("welcome:message")}
            </Typography>
          </Grid>
          <Grid item lg={6}>
            <img src={ImgEmployee} alt="Employer" width={503} height={506.2} />
          </Grid>
        </Grid>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            height: 60,
            position: "fixed",
            bottom: 0,
            backgroundColor: palette.primary,
            paddingRight: 50,
          }}>
          <NextBtn
            color="white"
            text={t("button.continue")}
            onClick={() => this.handleClick()}
          />
        </Grid>
      </div>
    );
  }
}
export default withTranslation()(Welcome);
