import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { getItem, loadImage } from "app/utils";
import { IcnBirthday2, IcnGender2, IcnMarital2, IcnKTP2, IcnPhone2, IcnContactMail2, IcnAddress2 } from "assets/img";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  sectionLeft: {
    height: "100%",
    width: "25%",
    padding: "40pt 16pt 0pt 17pt",
    display: "flex",
    backgroundColor: "#d0d7da",
    flexDirection: "column",
  },
  sectionRight: {
    height: "100%",
    width: "75%",
    padding: "55pt 30pt 0pt 20pt",
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
  },
});

const LangaugeView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontFamily: "Georgia",
          fontSize: 14,
          fontWeight: "bold",
          color: "#3f4550",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.language")}
      </Text>
      <View
        style={{
          marginTop: 8,
          paddingTop: 9,
          width: "100%",
          borderTop: "1pt solid #3f4550",
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View
                key={i}
                style={i === (props.data.length - 1) ? {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                } : {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 7,
                }}
              >
                <View
                  style={{
                    width: "5%",
                  }}
                >
                  <View 
                    style={{
                      marginTop: 2,
                      width: 6,
                      height: 6,
                      backgroundColor: "#3f4550",
                      borderRadius: 3,
                    }}
                  />
                </View>
                <View
                  style={{
                    width: "45%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Avenir",
                      fontSize: 10,
                      color: "#3f4550",
                    }}
                  >
                    {getItem("lang") === "en" ? v.languageSkillEng : v.languageSkillIna}
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Avenir",
                      fontSize: 10,
                      color: "#3f4550",
                      fontStyle: "oblique",
                      textAlign: "right"
                    }}
                  >
                    {
                      v.proficiency === 1 ? props.t("biodata:cv_template.hg2.languageSkill.1") : (
                        v.proficiency === 2 ? props.t("biodata:cv_template.hg2.languageSkill.2") : (
                          v.proficiency === 3 ? props.t("biodata:cv_template.hg2.languageSkill.3") : null
                        )
                      )
                    }
                  </Text>
                </View>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const WorkExperienceView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontFamily: "Georgia",
          fontSize: 14,
          fontWeight: "bold",
          color: "#3f4550",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.workExp")}
      </Text>
      <View
        style={{
          marginTop: 8,
          paddingTop: 9,
          width: "100%",
          borderTop: "1pt solid #3f4550",
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View
                key={i}
                style={i === (props.data.length - 1) ? {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                } : {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 7,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#3f4550"
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    marginTop: 2,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#b19867"
                  }}
                >
                  {v.role}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    marginTop: 2,
                    fontSize: 10,
                    color: "#3f4550"
                  }}
                >
                  {(getItem("lang") === "en" ? moment(v.yearStart).lang("en").format("MMMM YYYY") : moment(v.yearStart).format("MMMM YYYY")) + " - " + (v.yearEnd ? v.yearEnd : props.t("biodata:cv_template.hg1.workYearEnd"))}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    marginTop: 2,
                    fontSize: 10,
                    color: "#3f4550"
                  }}
                >
                  {
                    v.status === "1" ? props.t("biodata:cv_template.hg1.employeeStatus.1") : (
                      v.status === "2" ? props.t("biodata:cv_template.hg1.employeeStatus.2") : (
                        v.status === "3" ? props.t("biodata:cv_template.hg1.employeeStatus.3") : null
                      )
                    )
                  }
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const FormalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontFamily: "Georgia",
          fontSize: 14,
          fontWeight: "bold",
          color: "#3f4550",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.formalEdu")}
      </Text>
      <View
        style={{
          marginTop: 8,
          paddingTop: 9,
          width: "100%",
          borderTop: "1pt solid #3f4550",
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View
                key={i}
                style={i === (props.data.length - 1) ? {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                } : {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 7,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#3f4550"
                  }}
                >
                  {v.institution}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    marginTop: 2,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#b19867"
                  }}
                >
                  {v.degree + " | " + v.major}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    marginTop: 2,
                    fontSize: 10,
                    color: "#3f4550"
                  }}
                >
                  {"GPA : " + v.grade}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    marginTop: 2,
                    fontSize: 10,
                    color: "#3f4550"
                  }}
                >
                  {"Graduated : " + v.yearGraduate}
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const InformalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontFamily: "Georgia",
          fontSize: 14,
          fontWeight: "bold",
          color: "#3f4550",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.informalEdu")}
      </Text>
      <View
        style={{
          marginTop: 8,
          paddingTop: 9,
          width: "100%",
          borderTop: "1pt solid #3f4550",
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View
                key={i}
                style={i === (props.data.length - 1) ? {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                } : {
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 7,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#3f4550"
                  }}
                >
                  {v.institution}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    marginTop: 2,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#b19867"
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    marginTop: 2,
                    fontSize: 10,
                    color: "#3f4550"
                  }}
                >
                  {v.year}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    marginTop: 2,
                    fontSize: 10,
                    color: "#3f4550"
                  }}
                >
                  {"Certificate : " + (v.isSertifikat ? props.t("biodata:cv_template.hg1.certificate.true") : props.t("biodata:cv_template.hg1.certificate.false"))}
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const TemplateHG2 = props => (
  <Document
    title={"CV - " + props.data.personalInfo.name}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <Page size="A4" style={styles.page}>
      <View style={styles.sectionLeft}>
        <View
          style={{
            width: "100%",
            alignItems: "center",
          }}
        >
          <Image 
            src={props.data.personalInfo.photo + "?t=" + new Date().getTime()}
            style={{
              width: 100,
              height: 100,
              borderTopLeftRadius: 50,
              borderTopRightRadius: 50,
              borderBottomRightRadius: 50,
              borderBottomLeftRadius: 50,
            }}
          />
        </View>
        <View
          style={{
            marginTop: 34,
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "Georgia",
              fontSize: 14,
              fontWeight: "bold",
              color: "#3f4550",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.bio")}
          </Text>
          <View
            style={{
              marginTop: 8,
              paddingTop: 9,
              width: "100%",
              borderTop: "1pt solid #3f4550",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image 
              src={IcnBirthday2}
              style={{
                width: 15,
                height: 15,
                marginBottom: 6,
              }}
            />
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#3f4550",
                  textAlign: "center",
                  marginBottom: 16,
                }}
              >
                {props.data.personalInfo.birthPlace + ", " + (getItem("lang") === "en" ? moment(props.data.personalInfo.birthDate).lang("en").format("DD MMMM YYYY") : moment(props.data.personalInfo.birthDate).format("DD MMMM YYYY"))}
              </Text>
            </View>
            <Image 
              src={IcnGender2}
              style={{
                width: 14,
                height: 14,
                marginBottom: 6,
              }}
            />
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#3f4550",
                  textAlign: "center",
                  marginBottom: 16,
                }}
              >
                {
                  props.data.personalInfo.gender === "M" ? props.t("biodata:cv_template.hg1.gender.m") : (
                    props.data.personalInfo.gender === "F" ? props.t("biodata:cv_template.hg1.gender.f") : null
                  )
                }
              </Text>
            </View>
            <Image 
              src={IcnMarital2}
              style={{
                width: 14,
                height: 14,
                marginBottom: 6,
              }}
            />
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#3f4550",
                  textAlign: "center",
                  textTransform: "capitalize",
                  marginBottom: 16,
                }}
              >
                {props.data.personalInfo.maritalStatus}
              </Text>
            </View>
            <Image 
              src={IcnKTP2}
              style={{
                width: 14,
                height: 11,
                marginBottom: 6,
              }}
            />
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#3f4550",
                  textAlign: "center",
                }}
              >
                {props.data.personalInfo.identityNumber}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            marginTop: 37,
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "Georgia",
              fontSize: 14,
              fontWeight: "bold",
              color: "#3f4550",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.contact")}
          </Text>
          <View
            style={{
              marginTop: 8,
              paddingTop: 9,
              width: "100%",
              borderTop: "1pt solid #3f4550",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image 
              src={IcnPhone2}
              style={{
                width: 14,
                height: 14,
                marginBottom: 6,
              }}
            />
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#3f4550",
                  textAlign: "center",
                  marginBottom: 16,
                }}
              >
                {"+62 " + props.data.personalInfo.phoneNumber}
              </Text>
            </View>
            <Image 
              src={IcnContactMail2}
              style={{
                width: 12,
                height: 12,
                marginBottom: 6,
              }}
            />
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#3f4550",
                  textAlign: "center",
                  textTransform: "capitalize",
                  marginBottom: 16,
                }}
              >
                {props.data.email ? props.data.email : " - "}
              </Text>
            </View>
            <Image 
              src={IcnAddress2}
              style={{
                width: 16,
                height: 13,
                marginBottom: 6,
              }}
            />
            <View
              style={{
                width: "100%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#3f4550",
                  textAlign: "center",
                }}
              >
                {props.data.personalInfo.address}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: "110%",
            height: 152,
            position: "absolute",
            bottom: 0,
            padding: "0pt 0pt 28pt 22pt",
          }}
        >
          {
            props.data.toogle &&
            <View
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Image 
                  src={loadImage("qr-code.png")}
                  style={{
                    width: 48,
                    height: 48,
                  }}
                />
              </View>
              <View
                style={{
                  marginTop: 12,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Avenir",
                    fontWeight: "bold",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrTitle")}
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrDesc")}
                </Text>
              </View>
              <View
                style={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Avenir",
                    fontWeight: "bold",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrLink")}
                </Text>
              </View>
            </View>
          }
        </View>
      </View>
      <View style={styles.sectionRight}>
        <View
          style={{
            width: "100%",
            height: "15%",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "Georgia",
              fontSize: 35,
              color: "#3f4550",
              textTransform: "uppercase",
            }}
          >
            {props.data.personalInfo.name}
          </Text>
          <Text
            style={{
              fontFamily: "Avenir",
              marginTop: 10,
              fontSize: 15,
              color: "#b19867",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {props.data.listWorks.length > 0 ? props.data.listWorks[0].role : null}
          </Text>
        </View>
        <View
          style={{
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "Georgia",
              fontSize: 14,
              fontWeight: "bold",
              color: "#3f4550",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg2.profile")}
          </Text>
          <View
            style={{
              marginTop: 8,
              paddingTop: 9,
              width: "100%",
              borderTop: "1pt solid #3f4550",
            }}
          >
            <Text
              style={{
                fontFamily: "Avenir",
                fontSize: 10,
                color: "#3f4550",
              }}
            >
              {props.data.personalInfo.description}
            </Text>
          </View>
        </View>
        {props.data.personalInfo.listUserLanguage.length > 0 ? 
          <LangaugeView 
            data={props.data.personalInfo.listUserLanguage}
            t={props.t}
          /> : null
        }
        {props.data.listWorks.length > 0 ?
          <WorkExperienceView 
            data={props.data.listWorks}
            t={props.t}
          /> : null
        }
        {props.data.listFormalEducation.length > 0 ?
          <FormalEducationView 
            data={props.data.listFormalEducation}
            t={props.t}
          /> : null
        }
        {props.data.listInformalEducation.length > 0 ?
          <InformalEducationView 
            data={props.data.listInformalEducation}
            t={props.t}
          /> : null
        }
      </View>
    </Page>
  </Document>
);
export default TemplateHG2;
