import React from "react";
import { withTranslation } from "react-i18next";
import { Header, HtmlTooltip, NextBtn, Uploader } from "app/components";
import MyHelmet from "app/components/header/MyHelmet";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { palette } from "assets/css/main";
import {
  getItem,
  AppHistory,
  RequestPost,
  General,
  RequestGet,
} from "app/utils";
import { StarRequired } from "./profile-recruiter";
import DatePickerValidator from "app/components/general/DatePickerValidator";
import data from "app/pages/recruiter/data";
import { v4 as uuid4 } from "uuid";
import SelectSearch from "react-select";
import alertData from "app/components/alert/alert-data";
import InfoIcon from "@material-ui/icons/Info";

class CompanyProfileSetup extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      userId: getItem("user_id"),
      companyCode: uuid4().split("-")[0].toUpperCase(),
      companyNameBrand: "",
      companyNameLegal: "",
      companyAddress: "",
      companyType: " ",
      businessType: [],
      businessSubType: " ",
      specifyOthers: "",
      website: "",
      established: "",
      aboutCompany: "",
      tmpEstablished: "",
      ...JSON.parse(getItem("user_info")),
      listBusinessType: [],
      selected: [],
    };
  }

  componentDidMount() {
    this.getBusinessType();
    var regex = /^((https?|http):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    ValidatorForm.addValidationRule("isUrl", value => {
      if (value === "") {
        return true;
      }
      if (regex.test(value)) {
        return true;
      }
      return false;
    });
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isUrl");
    ValidatorForm.removeValidationRule("selectRequired");
  }

  submitForm(e) {
    e.preventDefault();
    const { t } = this.props;
    if (this.state.businessType === " ") {
      alertData.show(t("validation.required.businessType"), "error");
      return false;
    }

    if (this.state.companyLogo === undefined) {
      alertData.show(t("validation.required.companyLogo"), "error");
      return false;
    }
    var strestablished = data.companyProfile.established.toString();
    data.companyProfile.companyCode = this.state.companyCode;
    data.companyProfile.established = strestablished;
    RequestPost("CompanyProfile", data)
      .then(res => {
        if (res) {
          AppHistory.push("/recruiter/profile-additional");
        }
      })
      .catch(err => {
        console.log("CompanyProfileSetup", err);
      });
  }

  handleClick(e) {
    document.getElementById("button-submit").click();
  }

  handleChange(obj) {
    this.setState(obj);
    data.setCompanyProfile(obj);
  }

  handleUploadImage(file, part) {
    var form = new FormData();
    form.append(
      "file",
      file.file,
      getItem("user_id") + "-" + part + "." + file.extension
    );
    RequestPost("CompanyProfile/upload", form)
      .then(r => {
        const { imageUrl } = r.data;
        try {
          document.getElementById("preview-img").blur();
          document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
        } catch (error) {
          console.log(error);
        }
        if (part === "companyLogo") {
          this.handleChange({ companyLogo: imageUrl });
        } else if (part === "picIdentityCard") {
          this.handleChange({ picIdentityCard: imageUrl });
        } else {
          General.visible = true;
          this.handleChange({ siup: imageUrl });
        }
      })
      .catch(e => {
        console.log("handleUploadImage()", e);
      });
  }

  getBusinessType() {
    RequestGet("Industry")
      .then(res => {
        this.setState({
          listBusinessType: res.data.map(obj => {
            return {
              value: obj.id,
              label:
                getItem("lang") === "en" ? obj.industryEng : obj.industryIna,
            };
          }),
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeBussinessType(v) {
    var { businessType, selected } = this.state;
    selected = v;
    businessType = selected.map(d => {
      return d.value;
    });
    data.setCompanyProfile(selected);
    data.companyBusinessType = businessType;
    this.setState({
      ...this.state,
      selected: selected,
      businessType: businessType,
    });
  }

  render() {
    const { t } = this.props;
    const { listBusinessType } = this.state;
    return (
      <div className="auth">
        <MyHelmet title="Update Company Profile" />
        <Header
          shadow={true}
          stepperData={t("recruiterSetup", { returnObjects: true })}
          stepper={true}
          stepWidth={730}
          activeStep={1}
        />
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          direction="column"
          style={{ marginTop: 130, marginBottom: 120 }}>
          <div style={{ width: 520, color: palette.primary }}>
            <Typography style={{ fontSize: 24 }}>
              {t("profilesetup:company")}
            </Typography>
            <Typography style={{ fontWeight: "lighter" }}>
              {t("profilesetup:companyInfo")}
            </Typography>
            <Typography
              style={{ fontSize: 12, fontWeight: "lighter", color: "#CD594D" }}>
              *{t("profilesetup:require")}
            </Typography>
            <ValidatorForm onSubmit={this.submitForm}>
              {/* <ReadOnlyField
                label={t("profilesetup:form.field.companyCode")}
                value={this.state.companyCode}
              /> */}

              <TextValidator
                label={
                  <StarRequired
                    label={t("profilesetup:form.field.companyName")}
                  />
                }
                onChange={e => {
                  this.handleChange({
                    companyNameBrand: e.currentTarget.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className="Field"
                placeholder={t("profilesetup:form.placeholder.companyName")}
                name="companyName"
                value={this.state.companyNameBrand}
                style={{ width: "100%", marginTop: 50 }}
                validators={["required"]}
                errorMessages={[t("validation.required.brand")]}
              />

              <TextValidator
                label={
                  <StarRequired
                    label={t("profilesetup:form.field.companyNameLegal")}
                  />
                }
                onChange={e => {
                  this.handleChange({
                    companyNameLegal: e.currentTarget.value,
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className="Field"
                placeholder={t(
                  "profilesetup:form.placeholder.companyNameLegal"
                )}
                name="companyNameLegal"
                value={this.state.companyNameLegal}
                style={{ width: "100%", marginTop: 20 }}
                validators={["required"]}
                errorMessages={[t("validation.required.companyNameLegal")]}
              />
              <div
                style={{
                  marginTop: "4%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontSize: 14,
                    fontWeight: "lighter",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.companyAddress")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <TextValidator
                  onChange={e => {
                    this.handleChange({
                      companyAddress: e.currentTarget.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t(
                    "profilesetup:form.placeholder.companyAddress"
                  )}
                  multiline
                  rows={4}
                  name="companyAddress"
                  value={this.state.companyAddress}
                  style={{
                    width: "100%",
                    fontWeight: "bolder",
                    color: palette.primary,
                    marginTop: -15,
                  }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.companyAddress")]}
                />
              </div>
              <SelectValidator
                label={
                  <StarRequired
                    label={t("profilesetup:form.field.companyType")}
                  />
                }
                className="select-with-placeholder Field Single"
                defaultValue=" "
                InputLabelProps={{
                  shrink: true,
                }}
                validators={["selectRequired"]}
                errorMessages={[t("validation.required.companyType")]}
                displayempty="true"
                onChange={e =>
                  this.handleChange({ companyType: e.target.value })
                }
                value={this.state.companyType}
                style={{ width: "100%", marginTop: 30 }}>
                <MenuItem disabled value=" ">
                  <span>{t("profilesetup:form.placeholder.companyType")}</span>
                </MenuItem>
                <MenuItem value="National">National</MenuItem>
                <MenuItem value="Multinational">Multinational</MenuItem>
              </SelectValidator>
              <Grid item lg={11} md={11} sm={11} style={{ marginTop: 20 }}>
                <div>
                  <label
                    style={{
                      textTransform: "uppercase",
                      fontSize: 14,
                      fontWeight: "lighter",
                      fontFamily: "Hurme Geometric Sans 3",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {t("profilesetup:form.field.businessType")}
                    <span style={{ color: "#CD594D" }}>*</span>
                    <HtmlTooltip
                      title={
                        <div style={{ color: "white" }}>
                          <p> {t("profilesetup:tooltip.businessType")}</p>
                        </div>
                      }
                      arrow>
                      <InfoIcon
                        style={{
                          color: palette.primarydark,
                          fontSize: 18,
                          margin: "0px 10px 3px",
                        }}
                      />
                    </HtmlTooltip>
                  </label>
                  <SelectSearch
                    isMulti={true}
                    validators={["selectRequired"]}
                    errorMessages={[t("validation.required.businessType")]}
                    closeMenuOnSelect
                    key={new Date().getTime()}
                    className="Select-bussiness-type"
                    classNamePrefix="select"
                    placeholder={t(
                      "profilesetup:form.placeholder.businessType"
                    )}
                    menuColor={palette.primary}
                    name="bussinestype"
                    options={listBusinessType}
                    value={this.state.selected}
                    onChange={e => {
                      this.onChangeBussinessType(e);
                    }}
                    style={{ color: palette.primary }}
                  />
                </div>
              </Grid>
              <TextValidator
                label={t("profilesetup:form.field.website")}
                onChange={e => {
                  this.handleChange({ website: e.currentTarget.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className="Field"
                placeholder={t("profilesetup:form.placeholder.website")}
                name="website"
                validators={["isUrl"]}
                errorMessages={[t("validation.valid.website")]}
                value={this.state.website}
                style={{ width: "100%", marginTop: 40 }}
              />
              <div
                style={{
                  marginTop: 20,
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontSize: 14,
                    fontWeight: "lighter",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.established")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <DatePickerValidator
                  maxDate={new Date()}
                  validators={["required"]}
                  errorMessages={[t("validation.required.established")]}
                  className="Profile"
                  label={""}
                  format="yyyy"
                  maxDetail="decade"
                  style={{ width: 520 }}
                  value={this.state.tmpEstablished}
                  onChange={e => {
                    this.handleChange({
                      established: e.getFullYear(),
                      tmpEstablished: e,
                    });
                  }}
                  yearPlaceholder={t("biodata:form.placeholder.year")}
                />
              </div>
              <div
                style={{
                  marginTop: "4%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontSize: 14,
                    fontWeight: "lighter",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.aboutCompany")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <TextValidator
                  validators={["required"]}
                  errorMessages={[t("validation.required.aboutCompany")]}
                  onChange={e => {
                    this.handleChange({ aboutUs: e.currentTarget.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t("profilesetup:form.placeholder.aboutCompany")}
                  multiline
                  rows={4}
                  name="aboutCompany"
                  value={this.state.aboutUs}
                  style={{
                    width: "100%",
                    fontWeight: "bolder",
                    color: palette.primary,
                    marginTop: -15,
                  }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ fontSize: 12 }}>
                  <div style={{ textAlign: "center" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                        fontFamily: "Hurme Geometric Sans 3",
                      }}>
                      {t("profilesetup:form.field.companyLogo")}
                      <span style={{ color: "#CD594D" }}>*</span>
                    </label>
                  </div>
                  <Uploader
                    isBlob={false}
                    key={1}
                    accept="image/png,image/jpg,image/jpeg"
                    style={{ width: 170, height: 170, fontSize: 12 }}
                    previewStyle={{ width: 170, height: 170 }}
                    labelStyle={{
                      fontSize: 12,
                      textAlign: "center",
                      fontWeight: "lighter",
                    }}
                    onDropFile={o => {
                      this.handleUploadImage(o, "companyLogo");
                    }}
                    value={this.state.companyLogo}
                  />
                </div>
                <div style={{ marginLeft: "2%", fontSize: 12 }}>
                  <div style={{ textAlign: "center" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                        fontFamily: "Hurme Geometric Sans 3",
                      }}>
                      {t("profilesetup:form.field.picIdentityCard")}
                    </label>
                  </div>
                  <Uploader
                    isBlob={false}
                    key={1}
                    accept="image/png,image/jpg,image/jpeg"
                    style={{ width: 170, height: 170, fontSize: 12 }}
                    previewStyle={{ width: 170, height: 170 }}
                    labelStyle={{
                      fontSize: 12,
                      textAlign: "center",
                      fontWeight: "lighter",
                    }}
                    onDropFile={o => {
                      this.handleUploadImage(o, "picIdentityCard");
                    }}
                    value={this.state.picIdentityCard}
                  />
                </div>
                <div style={{ marginLeft: "2%", fontSize: 12 }}>
                  <div style={{ textAlign: "center" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                        fontFamily: "Hurme Geometric Sans 3",
                      }}>
                      {t("profilesetup:form.field.siup")}
                    </label>
                  </div>
                  <Uploader
                    isBlob={false}
                    key={1}
                    accept="image/png,image/jpg,image/jpeg,application/pdf"
                    style={{ width: 170, height: 170, fontSize: 12 }}
                    previewStyle={{ width: 170, height: 170 }}
                    labelStyle={{
                      fontSize: 12,
                      textAlign: "center",
                      fontWeight: "lighter",
                    }}
                    onDropFile={o => {
                      this.handleUploadImage(o, "siup");
                    }}
                    value={this.state.siup}
                  />
                </div>
              </div>

              <button type="submit" id="button-submit" className="Submit-Btn">
                submit
              </button>
            </ValidatorForm>
          </div>
        </Grid>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            height: 60,
            position: "fixed",
            bottom: 0,
            backgroundColor: palette.primary,
            paddingRight: 50,
          }}>
          <NextBtn
            color="white"
            text={t("button.continue")}
            onClick={() => this.handleClick()}
          />
        </Grid>
      </div>
    );
  }
}
export default withTranslation()(CompanyProfileSetup);
