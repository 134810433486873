import React from "react";
import { withTranslation } from "react-i18next";
import MatchingPage from "./matching";
import CompetencePage from "./competence";
import { RequestGet, getItem, RequestPost } from "app/utils";
import btd from "../data";
import CandidTaken from "app/components/camera/HGCandid";

class InterestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      timer: 3,
      interest: 0,
      isLast: false,
      hideCamera: true,
      data: [],
      title: "",
      content: <div></div>,
      examAttemptId: null,
    };
  }

  componentDidMount() {
    this.getExamAttemptID();
    this.setState({ hideCamera: false });
  }

  getExamAttemptID() {
    const { id } = this.props.data;
    var data = {
      examCategoryId: id,
      userId: getItem("user_id"),
    };
    RequestPost("examattempts", data)
      .then(r => {
        this.getExamQuestion(r.data.id);
        this.setState({ examAttemptId: r.data.id });
      })
      .catch(e => {
        console.log("getExamAttemptID() error", e);
      });
  }

  getExamQuestion(eid) {
    var gender = "M";
    try {
      gender = getItem("gender").split("")[0].toUpperCase();
    } catch (error) {}
    const data = {
      examAttemptId: eid,
      Gender: gender,
    };
    RequestGet("interestattempts", data)
      .then(r => {
        return r.data.sort(function (a, b) {
          return a.answerOrder - b.answerOrder;
        });
      })
      .then(r => {
        return r.sort(function (a, b) {
          return a.questionOrder - b.questionOrder;
        });
      })
      .then(reorder => {
        this.renderContent(reorder);
      })
      .catch(e => {
        console.log("interest.js > getExamQuestion()", e);
      });
  }
  renderContent(data) {
    const { type, name, examId, id, infoEn, infoId } = this.props.data;
    const { t } = this.props;
    if (type === "Order") {
      var text = t("basictest:interestTest.infoResequence");
      text = text.replace(
        /NAME/g,
        getItem("lang") === "en" ? infoEn.toLowerCase() : infoId.toLowerCase()
      );
      this.setState({
        title: (getItem("lang") === "en" ? infoEn : infoId) + " - " + name,
        content: (
          <MatchingPage
            intro={btd.interestIntro}
            history={this.props.history}
            key={id}
            questionID={id}
            title={
              this.props.title +
              " - " +
              (getItem("lang") === "en" ? infoEn : infoId)
            }
            info={text}
            data={data}
            examId={examId}
            onStart={() => {
              this.onStart();
            }}
            nextButtonClick={() => {
              this.handleNext();
            }}
          />
        ),
      });
    } else if (type === "Capable") {
      this.setState({
        title: (getItem("lang") === "en" ? infoEn : infoId) + " - " + name,
        content: (
          <CompetencePage
            questionID={id}
            intro={btd.interestIntro}
            history={this.props.history}
            key={id}
            title={
              this.props.title +
              " - " +
              (getItem("lang") === "en" ? infoEn : infoId)
            }
            data={data}
            info=""
            onStart={() => {
              this.onStart();
            }}
            nextButtonClick={() => {
              this.handleNext();
            }}
          />
        ),
      });
    } else {
      this.setState({ content: <div>Unsupported type.</div> });
    }
  }

  handleNext() {
    this.props.history.push("/basic-test");
  }

  handleFinishCount() {
    this.setState({ open: false });
  }
  onStart() {
    btd.interestIntro = false;
    this.setState({ open: true });
  }

  render() {
    const { examAttemptId } = this.state;
    return (
      <div style={{ paddingBottom: 100 }}>
        <CandidTaken id={examAttemptId} />
        <div style={{ backgroundColor: "white" }}>{this.state.content}</div>
      </div>
    );
  }
}
export default withTranslation()(InterestPage);
