import React from "react";
import { withTranslation } from "react-i18next";
import { MyHelmet, ApplicantHeader, DialogueInfo } from "app/components";
import SidePanel from "../edit-cv/side-panel";
import { palette } from "assets/css/main";
import { Button } from "@material-ui/core";
import { ImgWorkReady, IconEditWhite } from "assets/img";
import {
  RequestGet,
  SetLocalItem,
  GetLocalItem,
  RequestPost,
  RequestPut,
} from "app/utils";
import ModalWork from "./modal-work";
import { CVFooter } from "../edit-cv/footer-cv";
import EditWorkPreferences from "./view-edit";
import ViewWorkPrefrences from "./view-data";
const buttonStyle = {
  textTransform: "capitalize",
  border: "1px solid white",
  color: "white",
  margin: "0px 10px",
  padding: "5px 30px",
  fontWeight: "bold",
};

export default withTranslation()(
  class WorkReady extends React.Component {
    state = {
      data: null,
      open: false,
      loaded: false,
      dataQuestion: [],
      showFooter: false,
      edited: false,
      firstTime: false,
      profile: undefined,
      dialogueFinish: false,
    };

    componentDidMount() {
      const wq = GetLocalItem("HG_WORK_QUESTION");
      if (!wq) {
        this.getData();
      } else {
        this.setState({
          dataQuestion: wq.question,
          data: wq.answer[0].responseAnswers.length > 0 ? wq.answer : null,
          loaded: true,
        });
      }
    }

    getData() {
      RequestGet("workquestion")
        .then(res => {
          var dtq = res.data;
          var dtr = res.data.map(v => {
            return {
              questionId: v.questionId,
              responseAnswers: v.responseAnswers,
            };
          });
          return { question: dtq, answer: dtr };
        })
        .then(a => {
          SetLocalItem("HG_WORK_QUESTION", a);
          this.setState({
            dataQuestion: a.question,
            data: a.answer[0].responseAnswers.length > 0 ? a.answer : null,
            loaded: true,
            showFooter: false,
            edited: false,
          });
        })
        .catch(e => {
          this.setState({ loaded: true });
          console.log(e);
        });
    }

    saveData(save = true) {
      if (save) {
        RequestPost("workquestion", this.state.data)
          .then(() => {
            this.getData();
            this.setState({ open: false });
            RequestGet("UserProfile", { loading: false })
              .then(r => {
                this.setState({ profile: r.data, dialogueFinish: true });
              })
              .catch(e => {
                console.log(e);
              });
          })
          .catch(e => {
            console.log(e);
          });
      } else {
        RequestPut("workquestion", this.state.data)
          .then(() => {
            this.getData();
          })
          .catch(e => {
            console.log(e);
          });
      }
    }

    setValue(val, index) {
      var value = this.state.data;
      var dataQuestion = this.state.dataQuestion;
      value[index] = {
        questionId: dataQuestion[index].questionId,
        responseAnswers: val,
      };
      this.setState({
        showFooter: true,
        data: value,
      });
    }

    render() {
      const { t } = this.props;
      const empty = t("cv:workReady.empty", { returnObjects: true });
      const {
        data,
        dataQuestion,
        loaded,
        showFooter,
        edited,
        firstTime,
        profile,
        dialogueFinish,
      } = this.state;
      return (
        <div>
          <MyHelmet title="Ready to work" />
          <ApplicantHeader menu="home" />
          <DialogueInfo
            open={dialogueFinish}
            title={t("cv:profileFinish.title")}
            content={t("cv:profileFinish.content")}
            onClose={() => {
              this.setState({ dialogueFinish: false });
            }}
          />
          <div style={{ padding: "100px 200px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: 300 }}>
                <SidePanel profile={profile} />
              </div>
              <div style={{ marginLeft: 20, minWidth: 900 }}>
                <div
                  style={{
                    backgroundColor: palette.primary,
                    padding: "20px",
                    color: "white",
                    borderTopRightRadius: 5,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTopLeftRadius: 5,
                  }}>
                  <span>{t("cv:workReady.title")}</span>
                  {data && (
                    <Button
                      onClick={() => {
                        this.setState({
                          edited: !edited,
                          showFooter: !showFooter,
                        });
                      }}
                      style={{
                        ...buttonStyle,
                        display: firstTime ? "none" : "",
                        backgroundColor: "transparent",
                      }}>
                      {edited ? (
                        ""
                      ) : (
                        <img
                          src={IconEditWhite}
                          style={{ width: 18, marginRight: "15px" }}
                          alt="Edit"
                        />
                      )}
                      {edited ? t("button.cancel") : t("button.edit")}
                    </Button>
                  )}
                </div>
                <div
                  style={{
                    minHeight: 520,
                    color: palette.primary,
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: !data ? "50px 20px" : 20,
                    width: "100%",
                    boxSizing: "border-box",
                    boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
                  }}>
                  {loaded && (
                    <>
                      <ModalWork
                        id="modal-parent"
                        onClose={() => {
                          this.setState({
                            open: false,
                          });
                        }}
                        onSave={responseAnswer => {
                          this.setState(
                            {
                              data: responseAnswer,
                            },
                            () => {
                              this.saveData();
                            }
                          );
                        }}
                        dataQuestion={dataQuestion}
                        open={this.state.open}
                      />
                      {!data && (
                        <>
                          <img
                            src={ImgWorkReady}
                            alt="ImgWorkReady"
                            width={143}
                            height={209}
                          />
                          {empty.map((v, k) => {
                            return (
                              <p
                                key={k}
                                style={{ width: 510, textAlign: "center" }}>
                                {v}
                              </p>
                            );
                          })}
                          <Button
                            onClick={() => {
                              this.setState({ open: true });
                            }}
                            style={{
                              backgroundColor: palette.primary,
                              color: "white",
                              padding: "8px 20px",
                              textTransform: "capitalize",
                            }}>
                            {t("cv:button.imReady")}
                          </Button>
                        </>
                      )}
                      {data && (
                        <div style={{ position: "relative" }}>
                          {edited ? (
                            <EditWorkPreferences
                              dataQuestion={dataQuestion}
                              data={data}
                              setValue={(v, i) => {
                                this.setValue(v, i);
                              }}
                            />
                          ) : (
                            <ViewWorkPrefrences
                              dataQuestion={dataQuestion}
                              data={data}
                            />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {showFooter && (
            <CVFooter
              text={t("button.save")}
              textBack={t("button.cancel")}
              BackClick={() => {
                this.setState({ edited: false, showFooter: false });
              }}
              NextClick={() => {
                this.saveData(false);
              }}
            />
          )}
        </div>
      );
    }
  }
);
