import { SetLocalItem } from "app/utils";
import { observable, decorate, action } from "mobx";

class VacancyContainer {
  vacancy_post = {
    vacancy: {
      companyProfileId: "",
      vacancyType: "",
      jobFamily: "",
      subJobFamily: "",
      jobTitle: "",
      vacancyNumber: 1,
      salaryMin: 0,
      salaryMax: 0,
      otherValue: "",
      isPrivate: false,
      minEducation: null,
      minGpa: null,
      minIq: 0,
      gender: "",
      age: 0,
      workExperience: 0,
    },
    vacancyBenefits: [],
    vacancyWorkLocation: [],
    vacancyJobDesc: [""],
    otherEnvironments: [],
    otherJobPerform: [],
    otherBenefits: [],
    vacancyRequirement: {
      languageSkill: "",
      certificate: "",
      hasCertificate: null,
    },
    vacancyRequirementCertificate: [""],
    vacancyRequirementEnvironmentWork: [],
    vacancyRequirementJobPerform: [],
    vacancySoftskills: [
      {
        softskillHeaderId: "",
        softskillDetailId: "",
      },
    ],
    vacancyUniversitys: [],
    vacancyMajorAliases: [],
  };

  soft_skill_api = [];

  listVacancy = {
    vacancy: {
      id: "",
    },
    jobFamilies: [
      {
        id: "",
        jobFamilyEng: "",
        jobFamilyDetails: [],
      },
    ],
  };

  setVacancyDetail(new_data) {
    this.vacancy_detail = { ...this.vacancy_detail, ...new_data };
  }

  setJobRequirement(new_data) {
    this.job_requirement = { ...this.job_requirement, ...new_data };
  }

  setSoftSkill(new_data) {
    this.soft_skill = { ...this.soft_skill, ...new_data };
  }

  setVacancyPost(new_data) {
    this.vacancy_post = { ...this.vacancy_post, ...new_data };
  }

  setEmptyVacancy() {
    this.vacancy_post = {
      vacancy: {
        companyProfileId: "",
        vacancyType: "",
        jobFamily: "",
        subJobFamily: "",
        jobTitle: "",
        vacancyNumber: 0,
        salaryMin: 0,
        salaryMax: 0,
        otherValue: "",
        isPrivate: this.vacancy_post.vacancy.isPrivate,
        minEducation: null,
        minGpa: null,
        minIq: 0,
        gender: "",
        age: 0,
        workExperience: 0,
      },
      vacancyBenefits: [],
      vacancyWorkLocation: [],
      vacancyJobDesc: [""],
      otherEnvironments: [],
      otherJobPerform: [],
      otherBenefits: [],
      vacancyRequirement: {
        languageSkill: "",
        certificate: "",
        hasCertificate: null,
      },
      vacancyRequirementCertificate: [""],
      vacancyRequirementEnvironmentWork: [],
      vacancyRequirementJobPerform: [],
      vacancySoftskills: [
        {
          softskillHeaderId: "",
          softskillDetailId: "",
        },
      ],
      vacancyUniversitys: [],
      vacancyMajorAliases: [],
    };

    SetLocalItem("vacancyPost", null);
    SetLocalItem("vacancyGet", null);
    SetLocalItem("softSkillGet", null);
    SetLocalItem("softSkillSum", null);
  }
}

const vacancyContainer = decorate(VacancyContainer, {
  vacancy_post: observable,
  soft_skill_api: observable,
  setVacancyDetail: action,
  setJobRequirement: action,
  setSoftSkill: action,
  setVacancyPost: action,
  setEmptyVacancy: action,
  thinkingSkill: observable,
  workingAttitude: observable,
  relationshipSkill: observable,
});

export default new vacancyContainer();
