import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { ImgUserProfileHeader } from "assets/img";
class HeaderProfile extends React.Component {
  state = {
    data: {
      personalInfo: { firstName: "", id: "" },
      listWorks: [],
      listFormalEducation: [],
    },
  };

  componentDidMount() {}
  render() {
    const { t } = this.props;
    return (
      <>
        <div
          style={{
            backgroundColor: palette.sopacity(0.2),
            display: "flex",
            color: palette.primary,
            position: "relative",
            flexDirection: "row",
            width: "100%",
            backgroundSize: "contain",
            height: 220,
            backgroundImage: "url(" + ImgUserProfileHeader + ")",
            backgroundRepeat: "no-repeat",
          }}>
          <div style={{ position: "absolute", left: 600, paddingTop: 60 }}>
            {/* <p>{t("header.type")}</p>
            <p style={{ margin: 0, fontSize: 28, fontWeight: "bold" }}>
              ARTISTIC - ENTERPRENEUR - REALISTIC
            </p> */}
            <p style={{ margin: 0, fontSize: 28, fontWeight: "bold", paddingTop: 40 }}>
              {t("header.waitingProgress")}
            </p>
          </div>
        </div>
      </>
    );
  }
}
HeaderProfile.defaultProps = {
  onRender: () => {},
};
export default withTranslation("user-profile")(HeaderProfile);
