import { Avatar, Checkbox, IconButton, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { DialogueInfo } from "app/components";
import alertData from "app/components/alert/alert-data";
import {
  AppHistory,
  findObject,
  getItem,
  GetLocalItem,
  removeObject,
  RequestGet,
  setItem,
  SetLocalItem,
} from "app/utils";
import { palette } from "assets/css/main";
import React from "react";
import { withTranslation } from "react-i18next";
import dataSchedule from "../data-schedule";
export default withTranslation("interview-schedule")(
  class ComponentCandidateSelect extends React.Component {
    mindate = new Date().setDate(new Date().getDate() + 1);

    constructor(props) {
      super(props);
      this.state = {
        listInterviewCandidate: dataSchedule.listInterviewCandidate,
        date: "",
        candidateList: [],
        warningCandidate: false,
      };
    }

    componentDidMount() {
      const {
        location: { state: data },
      } = AppHistory;
      var c = GetLocalItem("SCH_L" + data.id_vacancy);
      if (c) {
        if (!getItem("SCH_L" + data.id_vacancy)) {
          setItem("SCH_L" + data.id_vacancy, true);
          dataSchedule.listInterviewCandidate = c;
        }
      }
      this.getCandidate();
    }

    handleChange(v, idx) {
      var tmp = dataSchedule.listInterviewCandidate;
      var stateTmp = this.state.candidateList;
      stateTmp[idx].checked = !stateTmp[idx].checked;
      var s = findObject(tmp, "userId", v.userId);
      if (s) {
        tmp = removeObject(tmp, "userId", v.userId);
        dataSchedule.listInterviewCandidate = tmp;
      } else {
        dataSchedule.SetListInterviewCandidate({
          userId: v.userId,
          candidateCode: v.candidateCode,
          photo: v.photo,
          isEdit: v.isEdit,
        });
      }
      this.setState({ listInterviewCandidate: tmp, candidateList: stateTmp });
    }

    onSubmit(e) {
      const { t } = this.props;
      const {
        location: { state: data },
      } = AppHistory;
      e.preventDefault();
      if (dataSchedule.listInterviewCandidate.length) {
        this.props.submitted();
        SetLocalItem(
          "SCH_L" + data.id_vacancy,
          dataSchedule.listInterviewCandidate
        );
      } else {
        alertData.show(t("messages.selectOne"), "error");
      }
    }

    getCandidate() {
      const {
        location: { state: data },
      } = AppHistory;
      RequestGet("Interview/AvailableCandidate/" + data.id_vacancy, {
        loading: false,
      })
        .then(res => {
          var current = dataSchedule.listInterviewCandidate.map(v => {
            return {
              candidateCode: v.candidateCode,
              photo: v.photo,
              userId: v.userId,
              checked: true,
              isEdit: v.isEdit,
            };
          });
          res.data.map(v => {
            if (!findObject(current, "userId", v.userId)) {
              const x = {
                candidateCode: v.candidateCode,
                photo: v.photo,
                userId: v.userId,
                checked: false,
                isEdit: true,
              };
              current.push(x);
              return x;
            } else {
              return null;
            }
          });
          return current;
        })
        .then(data => {
          if (data.length === 0) {
            this.setState({ warningCandidate: true });
          }
          this.setState({ candidateList: data });
        })
        .catch(e => {
          console.log("getCandidate()", e);
        });
    }

    render() {
      const { t, edit } = this.props;
      const { candidateList, warningCandidate } = this.state;
      return (
        <div style={{ width: "600px" }}>
          <DialogueInfo
            title={t("messages.noCandidateTitle")}
            content={t("messages.noCandidateContent")}
            open={warningCandidate}
            onClose={() => {
              this.setState({ warningCandidate: false });
            }}
          />
          {edit === false ? (
            <>
              <p style={{ fontSize: 24, margin: 0 }}>{t("invite.title")}</p>
              <p
                style={{
                  fontSize: 18,
                  margin: 0,
                  padding: "30px 0px 20px",
                  fontWeight: "lighter",
                }}>
                {t("invite.list")}
              </p>
              {candidateList.map((v, k) => {
                if (v.checked) {
                  return (
                    <div
                      key={k}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        padding: "0px 0px 20px",
                      }}>
                      <div style={{ margin: "0px" }}>
                        <Avatar
                          alt={v.candidateCode}
                          src={v.photo}
                          style={{ width: 74, height: 74 }}
                        />
                      </div>
                      <p
                        style={{
                          padding: "0px 20px",
                          textTransform: "uppercase",
                          fontSize: 18,
                          fontWeight: "lighter",
                        }}>
                        {v.candidateCode}
                      </p>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </>
          ) : (
            <>
              <p style={{ fontSize: 24, margin: 0 }}>{t("invite.title")}</p>
              {edit === undefined ? (
                <p style={{ fontSize: 14, margin: "0px 0px 50px" }}>
                  {t("invite.description")}
                </p>
              ) : (
                ""
              )}

              <div
                style={{
                  border:
                    edit === true ? "none" : "1px solid " + palette.darkGrey,
                  minHeight: 200,
                  marginBottom: 20,
                }}>
                <p
                  style={{
                    fontSize: 18,
                    margin: 0,
                    padding: edit === true ? "30px 0px 20px" : "20px",
                    borderBottom:
                      edit === true ? "none" : "1px solid " + palette.darkGrey,
                    fontWeight: edit === true ? "lighter" : "normal",
                  }}>
                  {t("invite.list")}
                </p>
                {candidateList.map((v, k) => {
                  return (
                    <div
                      key={k}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        padding: edit === true ? "0px 0px 20px" : "20px",
                      }}>
                      <Checkbox
                        disabled={v.isEdit ? false : true}
                        checked={v.checked}
                        onChange={() => {
                          this.handleChange(v, k);
                        }}
                        color="primary"
                        style={{ opacity: v.isEdit ? 1 : 0.5 }}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                      <div style={{ margin: "0px 0px 0px 20px" }}>
                        <Avatar
                          alt={v.candidateCode}
                          src={v.photo}
                          style={{ width: 74, height: 74 }}
                        />
                      </div>
                      <p
                        style={{
                          padding: "0px 20px",
                          textTransform: "uppercase",
                          fontSize: 18,
                          fontWeight: "lighter",
                        }}>
                        {v.candidateCode}
                      </p>
                      {!v.isEdit ? (
                        <Tooltip title={t("messages.editFalseCandidate")} arrow>
                          <IconButton aria-label="delete">
                            <Info style={{ color: palette.secondary }} />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </div>
                  );
                })}
                <button
                  type="submit"
                  id="btn-submit"
                  style={{ display: "none" }}
                  onClick={e => {
                    this.onSubmit(e);
                  }}
                />
              </div>
            </>
          )}
        </div>
      );
    }
  }
);
