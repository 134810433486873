import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { AUTH_API, getItem, RequestPost } from "app/utils";
import MyHelmet from "app/components/header/MyHelmet";
import "app/pages/auth/auth.css";
import { ImgForgotPassword, IconEyeOpen, IconEyeClose } from "assets/img";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { NextBtn, Header, HeaderNologin, Footer } from "app/components";
import { isMobile } from "react-device-detect";

class RecruiterUserCreatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      error: "",
      confirmpassword: "",
      showConfirm: false,
      showPassword: false,
      missmatch: false,
    };
    if (getItem("access_token")) {
      this.props.history.push("");
    }
    this.CreatePassword = this.CreatePassword.bind(this);
  }
  componentDidMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value !== this.state.password) {
        this.setState({ missmatch: true });
        return false;
      } else {
        this.setState({ missmatch: false });
        return true;
      }
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }
  CreatePassword() {
    var params = new URLSearchParams(this.props.location.search);
    const data = {
      newPassword: this.state.password,
      confirmPassword: this.state.confirmpassword,
      code: params.get("code"),
      verifiedcode: params.get("verifiedcode"),
      userId: params.get("userId"),
    };
    var bascode = window.btoa(params.get("role"));
    RequestPost(AUTH_API + "connect/createpassword", data)
      .then(() => {
        this.props.history.push(
          "/user/success-create-password?code=" + bascode
        );
      })
      .catch(e => {
        if (e) {
          this.setState({ error: e.data.messages[0] });
        } else {
          this.setState({ error: "error" });
        }
      });
  }
  render() {
    const { t } = this.props;
    const item = t("auth:createPassword.rules.items", { returnObjects: true })
      ? t("auth:createPassword.rules.items", { returnObjects: true })
      : [];
    const { showPassword, showConfirm, missmatch } = this.state;
    if (isMobile) {
      return (
        <div>
          <MyHelmet title={t("auth:createPassword.title")} />
          <HeaderNologin />
          <Grid style={{ height: "100vh" }}>
            <Grid item lg={6} md={6} style={{ paddingTop: 15 }}>
              <div
                className={"auth-mobile"}
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  justifyItems: "flex-start",
                  padding: "50px 30px",
                  flexDirection: "column",
                }}>
                <Typography
                  style={{
                    marginTop: 50,
                    marginBottom: 20,
                    fontWeight: "bold",
                    color: palette.primary,
                    fontSize: 24,
                  }}>
                  {t("auth:createPassword.title")}
                </Typography>
                <Typography style={{ color: palette.primary, fontSize: 16 }}>
                  {t("auth:createPassword.rules.title")}
                </Typography>
                <div style={{ marginBottom: 40, fontSize: 16 }}>
                  {item.map((v, k) => {
                    return (
                      <Typography key={k} style={{ color: palette.primary }}>
                        - {v}
                      </Typography>
                    );
                  })}
                </div>
                <ValidatorForm onSubmit={this.CreatePassword}>
                  <div style={{ position: "relative" }}>
                    <TextValidator
                      autoFocus={true}
                      onChange={e => {
                        this.setState({
                          password: e.target.value,
                          error: "",
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={t("auth:createPassword.labelpassword")}
                      className="Field"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("auth:createPassword.placeholderpassword")}
                      name="password"
                      value={this.state.password}
                      style={{ marginTop: 20, width: "100%" }}
                      autoComplete="password"
                      validators={["required"]}
                      errorMessages={[t("auth:validation.reqpassword")]}
                    />
                    <img
                      src={showPassword ? IconEyeOpen : IconEyeClose}
                      alt="show hide"
                      onClick={() => {
                        this.setState({ showPassword: !showPassword });
                      }}
                      style={{
                        padding: 10,
                        cursor: "pointer",
                        width: 24.04,
                        position: "absolute",
                        bottom: 22,
                        right: 0,
                      }}
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    <TextValidator
                      onChange={e => {
                        this.setState({
                          confirmpassword: e.target.value,
                          error: "",
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label={t("auth:createPassword.labelconfirm")}
                      className="Field"
                      type={showConfirm ? "text" : "password"}
                      placeholder={t("auth:createPassword.placeholderconfirm")}
                      name="confirmpassword"
                      value={this.state.confirmpassword}
                      style={{ marginTop: 20, width: "100%" }}
                      autoComplete="confirmpassword"
                      validators={["required", "isPasswordMatch"]}
                      errorMessages={[
                        t("auth:validation.reqconfirmpassword"),
                        t("auth:validation.matchpassword"),
                      ]}
                    />
                    <img
                      src={showConfirm ? IconEyeOpen : IconEyeClose}
                      alt="show hide"
                      onClick={() => {
                        this.setState({ showConfirm: !showConfirm });
                      }}
                      style={{
                        padding: 10,
                        cursor: "pointer",
                        width: 24.04,
                        position: "absolute",
                        bottom: !missmatch ? 22 : 44,
                        right: 0,
                      }}
                    />
                  </div>
                  <p style={{ color: "red" }}>{this.state.error}</p>
                  <NextBtn
                    type="submit"
                    text={t("auth:createPassword.btn")}
                    style={{ padding: 0, marginTop: 10 }}
                  />
                </ValidatorForm>
              </div>
            </Grid>
            <Grid style={{ width: "100%" }}>
              <div style={{ position: "relative", bottom: 0 }}>
                <Footer />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <MyHelmet title={t("auth:createPassword.title")} />
          <Header shadow={true} linkgroup={true} />
          <Grid container style={{ height: "100vh", paddingTop: 80 }}>
            <Grid item lg={6} md={6} style={{ paddingTop: 15 }}>
              <Grid container>
                <div
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    justifyItems: "flex-start",
                    padding: "0px 120px",
                    flexDirection: "column",
                  }}>
                  <Typography
                    style={{
                      marginTop: 50,
                      marginBottom: 10,
                      fontWeight: "bold",
                      color: palette.primary,
                      fontSize: 32,
                    }}>
                    {t("auth:createPassword.title")}
                  </Typography>
                  <Typography style={{ width: 386, color: palette.primary }}>
                    {t("auth:createPassword.rules.title")}
                  </Typography>
                  <div style={{ marginBottom: 40 }}>
                    {item.map((v, k) => {
                      return (
                        <Typography key={k} style={{ color: palette.primary }}>
                          - {v}
                        </Typography>
                      );
                    })}
                  </div>
                  <ValidatorForm onSubmit={this.CreatePassword}>
                    <div style={{ position: "relative" }}>
                      <TextValidator
                        autoFocus={true}
                        onChange={e => {
                          this.setState({
                            password: e.target.value,
                            error: "",
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={t("auth:createPassword.labelpassword")}
                        className="Field"
                        type={showPassword ? "text" : "password"}
                        placeholder={t(
                          "auth:createPassword.placeholderpassword"
                        )}
                        name="password"
                        value={this.state.password}
                        style={{ width: 500, marginTop: 20 }}
                        autoComplete="password"
                        validators={["required"]}
                        errorMessages={[t("auth:validation.reqpassword")]}
                      />
                      <img
                        src={showPassword ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showPassword: !showPassword });
                        }}
                        style={{
                          padding: 10,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: 22,
                          right: 0,
                        }}
                      />
                    </div>

                    <div style={{ position: "relative" }}>
                      <TextValidator
                        onChange={e => {
                          this.setState({
                            confirmpassword: e.target.value,
                            error: "",
                          });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={t("auth:createPassword.labelconfirm")}
                        className="Field"
                        type={showConfirm ? "text" : "password"}
                        placeholder={t(
                          "auth:createPassword.placeholderconfirm"
                        )}
                        name="confirmpassword"
                        value={this.state.confirmpassword}
                        style={{ width: 500, marginTop: 20 }}
                        autoComplete="confirmpassword"
                        validators={["required", "isPasswordMatch"]}
                        errorMessages={[
                          t("auth:validation.reqconfirmpassword"),
                          t("auth:validation.matchpassword"),
                        ]}
                      />
                      <img
                        src={showConfirm ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showConfirm: !showConfirm });
                        }}
                        style={{
                          padding: 10,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: !missmatch ? 22 : 44,
                          right: 0,
                        }}
                      />
                    </div>
                    <p style={{ color: "red" }}>{this.state.error}</p>
                    <NextBtn
                      type="submit"
                      text={t("auth:createPassword.btn")}
                      style={{ padding: 0, marginTop: 30, marginBottom: 100 }}
                    />
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              style={{ backgroundColor: palette.secondary }}>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ paddingTop: 138 }}>
                <img
                  src={ImgForgotPassword}
                  alt="Forgot Password"
                  width={227}
                  height={443}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}
export default withTranslation()(RecruiterUserCreatePassword);
