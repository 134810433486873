import React from "react";
import { CustomAvatar, DialogueFull } from "app/components";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Button, Chip, Tab, Tabs } from "@material-ui/core";
import { getItem, RequestGet } from "app/utils";
import Moment from "react-moment";
import { IconConfirmationCheck } from "assets/img";

const label = {
  color: palette.primary,
  textTransform: "capitalize",
  fontWeight: "lighter",
  marginBottom: 5,
};
const text = {
  color: palette.primary,
  margin: "0px 0px 30px",
  fontSize: 18,
};

class InvitationStatus extends React.Component {
  state = {
    data: {},
    options: [],
    isLoading: false,
    value: 0,
    detail: {},
    interviewCandidate: [],
  };

  getDetail() {
    this.setState({ isLoading: true, data: this.props.data });
    RequestGet("interview/" + this.props.data.scheduleId)
      .then(r => {
        const {
          data: { interview, interviewCandidate },
        } = r;
        this.setState({
          detail: interview,
          interviewCandidate: interviewCandidate,
        });
      })
      .catch(e => {
        console.log("getDetail()", e);
      });
  }

  handleChange(v, n) {
    this.setState({ value: n });
  }

  render() {
    const { open, onClose, t } = this.props;
    const { data, isLoading, value, detail } = this.state;
    const scheduleStatus = t("scheduleStatus", { returnObjects: true });
    return (
      <DialogueFull
        open={open}
        style={{ width: 980 }}
        hideClose={true}
        onEnter={() => this.getDetail()}
        onClose={() => {
          onClose(false);
        }}>
        {isLoading ? (
          <div>
            <div
              style={{
                backgroundColor: "white",
                width: 900,
                boxSizing: "border-box",
                borderRadius: 4,
              }}>
              <div
                style={{
                  height: 20,
                  backgroundColor: palette.primary,
                  padding: "20px 20px",
                  color: "white",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                  fontSize: 18,
                }}>
                {t("invite.status.title")}
              </div>
              <div>
                <Tabs
                  value={value}
                  onChange={(x, n) => this.handleChange(x, n)}
                  indicatorColor="primary"
                  textColor="primary">
                  <Tab
                    label={t("invite.status.tab1")}
                    style={{
                      textTransform: "capitalize",
                      color: palette.primary,
                      opacity: value === 0 ? 1 : 0.5,
                    }}
                  />
                  <Tab
                    label={t("invite.status.tab2")}
                    style={{
                      textTransform: "capitalize",
                      color: palette.primary,
                      opacity: value === 1 ? 1 : 0.5,
                    }}
                  />
                </Tabs>
                <div
                  style={{
                    borderTop: "1px solid rgba(46, 105, 178,0.2)",
                    padding: "0px 20px 40px",
                    color: palette.primary,
                    maxHeight: 400,
                    minHeight: 400,
                    overflowX: "auto",
                  }}>
                  {value === 0 && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          width: "33.33%",
                          padding: "0px 10px 0px 0px",
                        }}>
                        <p
                          style={{
                            fontWeight: "lighter",
                            color: palette.darkGrey,
                          }}>
                          {t("slot.pending")}
                        </p>
                        {data.pendingCandidates.map((v, k) => {
                          return (
                            <div
                              key={"pending" + k}
                              style={{
                                marginBottom: 15,
                                padding: 10,
                                backgroundColor: palette.grey,
                                border: "1px solid " + palette.darkGrey,
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}>
                              <CustomAvatar
                                src={v.photo}
                                style={{
                                  width: 60,
                                  height: 60,
                                  marginRight: 10,
                                }}>
                                {v.userCode}
                              </CustomAvatar>
                              <div>
                                <p
                                  style={{
                                    textTransform: "uppercase",
                                    margin: 2,
                                  }}>
                                  {v.userCode}
                                </p>
                                <Chip
                                  label={scheduleStatus[v.scheduleStatus]}
                                  style={{
                                    color:
                                      v.scheduleStatus === 0
                                        ? palette.inactive.textColor
                                        : palette.secondary,
                                    backgroundColor:
                                      v.scheduleStatus === 0
                                        ? palette.inactive.bakcgroundColor
                                        : palette.sopacity(0.1),
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{
                          width: "33.33%",
                          padding: "0px 10px 0px 0px",
                        }}>
                        <p
                          style={{
                            fontWeight: "lighter",
                            color: palette.darkGrey,
                          }}>
                          {t("slot.remaining")}
                        </p>
                        {data.timeSlots.map((v, k) => {
                          return (
                            <div
                              key={"remaining" + k}
                              style={{
                                marginBottom: 15,
                                padding: "20px 10px",
                                backgroundColor: palette.grey,
                                border: "1px solid " + palette.darkGrey,
                                borderRadius: 4,
                              }}>
                              <Moment
                                date={v.timeStart}
                                locale={getItem("lang") === "id" ? "id" : "en"}
                                format={"dddd, DD MMMM yyyy"}
                              />
                              <div style={{ fontWeight: "lighter" }}>
                                <Moment date={v.timeStart} format={"HH:mm"} />
                                {" - "}
                                <Moment date={v.timeEnd} format={"HH:mm"} />
                                {" " + v.timezone}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div style={{ width: "33.33%" }}>
                        <p
                          style={{
                            fontWeight: "lighter",
                            color: palette.darkGrey,
                          }}>
                          {t("slot.booked")}
                        </p>
                        {data.bookedCandidates.map((v, k) => {
                          return (
                            <div
                              key={"booked" + k}
                              style={{
                                marginBottom: 15,
                                padding: "10px 0px 10px 10px",
                                backgroundColor: palette.grey,
                                border: "1px solid " + palette.darkGrey,
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}>
                              <CustomAvatar
                                src={v.photo}
                                style={{
                                  width: 60,
                                  height: 60,
                                  marginRight: 10,
                                }}>
                                {v.name}
                              </CustomAvatar>
                              <div>
                                <p style={{ margin: "0px 2px" }}>{v.name}</p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                  <img
                                    src={IconConfirmationCheck}
                                    alt="oke"
                                    width={18}
                                    height={18}
                                  />
                                  <div>
                                    <Moment
                                      date={v.timeStart}
                                      locale={
                                        getItem("lang") === "id" ? "id" : "en"
                                      }
                                      format={"dddd, DD MMMM yyyy"}
                                    />
                                    <div style={{ fontWeight: "lighter" }}>
                                      <Moment
                                        date={v.timeStart}
                                        format={"HH:mm"}
                                      />
                                      {" - "}
                                      <Moment
                                        date={v.timeEnd}
                                        format={"HH:mm"}
                                      />
                                      {" " + v.timezone}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {value === 1 && (
                    <div>
                      <div>
                        <p style={label}>
                          {t("form.label.title").toLowerCase()}
                        </p>
                        <p style={text}>{detail.interviewTitle}</p>
                      </div>
                      <div>
                        <p style={label}>
                          {t("form.label.location").toLowerCase()}
                        </p>
                        <p style={text}>{detail.location}</p>
                      </div>
                      <div>
                        <p style={label}>
                          {t("form.label.cpName").toLowerCase()}
                        </p>
                        <p style={text}>{detail.contactName}</p>
                      </div>
                      <div>
                        <p style={label}>
                          {t("form.label.cpPhone").toLowerCase()}
                        </p>
                        <p style={text}>{detail.contactNumber}</p>
                      </div>
                      <div>
                        <p style={label}>
                          {t("form.label.notes").toLowerCase()}
                        </p>
                        <p style={text}>{detail.notes}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  bottom: 0,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  width: "100%",
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                  padding: "20px 50px",
                }}>
                <Button
                  onClick={() => {
                    onClose(false);
                  }}
                  style={{
                    backgroundColor: palette.primary,
                    textTransform: "capitalize",
                    color: "white",
                    padding: "8px 50px",
                  }}>
                  {t("general:button.close")}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </DialogueFull>
    );
  }
}
InvitationStatus.defaultProps = {
  onClose: () => {
    console.error("Required onClose props");
  },
};
export default withTranslation("interview-schedule")(InvitationStatus);
