import React from "react";
import { palette } from "assets/css/main";
import { Grid } from "@material-ui/core";
import KeyHandler, { KEYPRESS } from "react-key-handler";
import "./custom.css";
import { findObject, removeObject } from "app/utils";
export default class Option extends React.Component {
  state = { active: [] };
  char = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  setActive(act) {
    const { multiple } = this.props;
    const x = [{ act }];
    var akt = this.state.active ? this.state.active : [];

    try {
      document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
    } catch (error) {}
    if (multiple) {
      if (findObject(akt, "act", act)) {
        akt = removeObject(akt, "act", act);
        this.setState({ active: [...akt] });
      } else {
        this.setState({ active: [...x, ...akt] });
      }
    } else {
      this.setState({ active: act });
    }
  }
  render() {
    const { style, options, label, value, required, multiple } = this.props;
    return (
      <div className="option" style={{ ...style, marginBottom: 20 }}>
        <div
          className="MuiInputLabel-root"
          style={{
            textTransform: "uppercase",
            marginBottom: 10,
            color: palette.primary,
            fontWeight: "lighter",
          }}>
          {label}
          {required && (
            <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
              &thinsp;*
            </span>
          )}
        </div>
        {options.map((v, i) => {
          return (
            <Grid
              title={"Press " + v.key}
              className={label}
              container
              justify="flex-start"
              alignItems="center"
              direction="row"
              onClick={() => {
                this.setActive(v.value, i);
                this.props.onChange({ ...v, index: i });
              }}
              key={i}
              style={{
                color: palette.primary,
                fontWeight: "bold",
                marginBottom: 15,
                fontSize: 32,
                cursor: "pointer",
              }}>
              <Grid
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 10,
                  paddingBottom: 8,
                  fontSize: 14,
                  textAlign: "center",
                  width: 26,
                  color: multiple
                    ? findObject(this.state.active, "act", v.value)
                      ? "white"
                      : findObject(value, "languageId", v.value)
                      ? "white"
                      : palette.primary
                    : this.state.active === v.value
                    ? "white"
                    : value === v.value
                    ? "white"
                    : palette.primary,
                  backgroundColor: multiple
                    ? findObject(this.state.active, "act", v.value)
                      ? palette.primary
                      : findObject(value, "languageId", v.value)
                      ? palette.primary
                      : "white"
                    : this.state.active === v.value
                    ? palette.primary
                    : value === v.value
                    ? palette.primary
                    : "white",
                  borderRadius: 5,
                  border: "1px solid " + palette.primary,
                  fontWeight: "normal",
                  marginRight: 10,
                }}>
                <KeyHandler
                  key={i}
                  keyEventName={KEYPRESS}
                  code={"Key" + v.key}
                  onKeyHandle={e => {
                    this.setActive(options[i].value, i);
                    this.props.onChange({ ...options[i], index: i });
                  }}
                />
                {v.key}
              </Grid>
              <Grid style={{ paddingTop: 10 }}>{v.label}</Grid>
            </Grid>
          );
        })}
      </div>
    );
  }
}
