import styled from "styled-components";
import { palette } from "assets/css/main";

const Button = styled.button`
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  border-radius: 4px;
  padding: 10px 18px;
  min-width: 165px;
  margin: 5px;
  font-size: 14px;
  font-family: "Hurme Geometric Sans 3";
  font-weight: bold;
  border: 1.2px solid ${props => props.borderColor};
  cursor: ${props => (props.disabled ? "auto" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  :hover {
    background: ${props => props.backgroundColor};
    box-shadow: ${props =>
      props.disabled
        ? "none"
        : "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);"};
  }
`;

Button.defaultProps = {
  color: "white",
  backgroundColor: palette.primary,
  borderColor: palette.primary,
};

export default Button;
