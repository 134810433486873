import React from "react";
import {
  getItem,
  RequestPut,
  General,
  removeArrayWithIndex,
  AppHistory,
} from "app/utils";
import { palette } from "assets/css/main";
import { Add } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { PRIMARY_BUTTON } from "assets/css/main";
import { CVFooter } from "./footer-cv";
import history from "app/utils/general/history";
import alertData from "app/components/alert/alert-data";
import data from "app/pages/applicant/initialize/bio-data/data";
import DialogueConfirm from "app/components/modal/confirm";
import { IconEditCV, IconDeleteCV, IconFolder } from "assets/img";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DatePickerValidator from "app/components/general/DatePickerValidator";

class CVInformalEducation extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
    this.state = {
      biodata: props.data,
      data: [],
      ...data.informal_education,
      ...JSON.parse(getItem("user_info")),
      anchorEl: null,
      open: false,
      opendelete: false,
      isedit: false,
      tmpYear: null,
    };
    General.visible = false;
    data.informal_education.userId = getItem("user_id");
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("selectRequired");
  }

  handleChange(obj) {
    this.setState(obj);
    data.setInformalEducation(obj);
    var listinformaledu = {
      listinformaledu: {
        ...this.state.biodata.listInformalEducation,
        ...data.listInformalEducation,
      },
    };
    this.setState({
      biodata: { ...this.state.biodata, ...listinformaledu },
    });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, opendelete: false });
  };

  handleClickOpen = (key = -1) => {
    if (key > -1) {
      this.setState(
        {
          open: true,
          ...this.state.biodata.listInformalEducation[key],
          isedit: true,
          k: key,
        },
        () => {
          this.setState({
            tmpYear: new Date(this.state.year + "-01-01"),
          });
        }
      );
    } else {
      this.setState({
        name: "",
        study: "",
        institution: "",
        tmpYear: "",
        isSertifikat: "",
        open: true,
        isedit: false,
      });
    }
  };

  handleClickDelete = key => {
    this.setState({ opendelete: true, k: key });
  };

  addinformaleducation() {
    const { t } = this.props;
    if (data.informal_education.isSertifikat === undefined) {
      alertData.show(t("biodata:validation.required.isSertifikat"), "error");
      return false;
    }
    data.informal_education.year = this.state.tmpYear.getFullYear();
    data.listInformalEducation.push(data.informal_education);
    data.setInformalEducation({
      name: "",
      study: "",
      institution: "",
      year: "",
      isSertifikat: "",
    });

    var objinformaledu = {
      name: "",
      study: "",
      institution: "",
      year: "",
      isSertifikat: "",
    };
    data.listInformalEducation.forEach(informaledu => {
      objinformaledu = {
        name: informaledu.name,
        study: informaledu.study,
        institution: informaledu.institution,
        year: informaledu.year,
        isSertifikat: informaledu.isSertifikat,
      };
    });
    this.state.biodata.listInformalEducation.push(objinformaledu);
    data.listInformalEducation = this.state.biodata.listInformalEducation;
    this.setState({ open: false });
  }

  editinformaleducation() {
    const { t } = this.props;
    var key = this.state.k;
    var stateListData = this.state.biodata.listInformalEducation;
    stateListData[key].name = this.state.name;
    stateListData[key].study = this.state.study;
    stateListData[key].institution = this.state.institution;
    stateListData[key].year = this.state.tmpYear.getFullYear();
    stateListData[key].isSertifikat = this.state.isSertifikat;

    if (stateListData[key].isSertifikat === undefined) {
      alertData.show(t("biodata:validation.required.isSertifikat"), "error");
      return false;
    }

    data.informal_education.name = this.state.name;
    data.informal_education.study = this.state.study;
    data.informal_education.institution = this.state.institution;
    data.informal_education.year = this.state.tmpYear.getFullYear();
    data.informal_education.isSertifikat = this.state.isSertifikat;

    data.listInformalEducation = stateListData;
    this.setState(
      { open: false, biodata: { listInformalEducation: stateListData } },
      () => {
        //alertData.show("Edit Success");
      }
    );
  }

  deleteinformaleducation() {
    var key = this.state.k;
    var listdataInformal = removeArrayWithIndex(
      this.state.biodata.listInformalEducation,
      key
    );
    data.listInformalEducation = listdataInformal;

    const tempArray = this.state.biodata;
    tempArray.listInformalEducation = listdataInformal;
    this.props.updateData(tempArray)

    this.setState(
      {
        opendelete: false,
        biodata: { listInformalEducation: listdataInformal },
      },
      () => {
        //alertData.show("Delete Success");
      }
    );
    
  }

  onSubmit(isEdit) {
    if (isEdit) {
      this.editinformaleducation();
    } else {
      this.addinformaleducation();
    }
  }

  submitdata() {
    var datasubmit = {};
    datasubmit.personalInfo = data.personalInfo;
    datasubmit.listFormalEducation = data.listFormalEducation;
    datasubmit.listOrganization = data.listOrganization;
    // datasubmit.listInformalEducation = this.state.biodata.listInformalEducation;
    datasubmit.listInformalEducation = data.listInformalEducation;
    datasubmit.listWorks = data.listWorks;

    RequestPut("biodata", datasubmit)
      .then(r => {
        alertData.show("Success");
        AppHistory.push("/user/home", { edit: true });
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    const {
      biodata: { listInformalEducation },
      open,
      opendelete,
      isedit,
    } = this.state;
    const { t } = this.props;
    const certificate = t("biodata:options.certificateInformal", {
      returnObjects: true,
    });
    var Available = certificate[0].label;
    var NotAvailable = certificate[1].label;
    return (
      <div
        style={{
          fontWeight: "lighter",
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          padding: "0px 50px",
          color: palette.primary,
        }}>
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            flexDirection: "row",
            alignItems: "center",
            color: palette.primary,
            fontSize: 20,
            fontWeight: "bolder",
            justifyContent: "space-between",
          }}>
          <div>{t("biodata:CVinformalEducation")}</div>
          <div>
            <Button
              onClick={this.handleClickOpen}
              size="small"
              variant="contained"
              style={{
                ...PRIMARY_BUTTON,
                fontWeight: "bold",
                marginLeft: 46,
                backgroundColor: "white",
                color: palette.primary,
                fontSize: 11,
              }}>
              <Add style={{ width: "0.8em", height: "0.8em" }}></Add> &nbsp;{" "}
              {t("biodata:CVbtnAdd")}
            </Button>
          </div>
        </div>
        <Dialog
          fullScreen
          className={"Custom-Dialog-cv"}
          aria-labelledby="customized-dialog-title"
          disableBackdropClick
          open={open}
          style={{ backgroundColor: "transparent" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              paddingTop: "50px",
            }}>
            <div
              style={{
                width: 800,
                minHeight: 650,
                padding: 30,
                position: "relative",
              }}>
              <button
                onClick={() => {
                  this.handleClose();
                }}
                style={{
                  width: 28,
                  height: 28,
                  color: "#C4C4C4",
                  borderRadius: "50%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  border: "none",
                  cursor: "pointer",
                }}>
                X
              </button>
              <ValidatorForm
                style={{ backgroundColor: "white", borderRadius: 10 }}
                onSubmit={() => {
                  this.onSubmit(isedit);
                }}>
                <DialogTitle
                  id="form-dialog-title"
                  style={{
                    color: palette.primary,
                    borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  }}>
                  {t("biodata:CVinformalEducation")}
                </DialogTitle>
                <DialogContent>
                  <div
                    value={this.state.name}
                    style={{
                      width: "100%",
                      marginLeft: "1%",
                      marginTop: "3%",
                      fontSize: 20,
                    }}>
                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("biodata:form.field.trainingName")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <TextValidator
                      style={{ width: "97%", fontSize: 20 }}
                      validators={["required"]}
                      errorMessages={t(
                        "biodata:validation.required.trainingName"
                      )}
                      onChange={e => {
                        this.handleChange({
                          name: e.currentTarget.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t("biodata:form.placeholder.trainingName")}
                      className="Field-cfm-cv"
                      value={this.state.name}
                    />

                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("biodata:form.field.area")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <TextValidator
                      style={{ width: "97%", fontSize: 20 }}
                      validators={["required"]}
                      errorMessages={t("biodata:validation.required.area")}
                      onChange={e => {
                        this.handleChange({
                          study: e.currentTarget.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t(
                        "biodata:form.placeholder.informalEduArea"
                      )}
                      className="Field-cfm-cv"
                      value={this.state.study}
                    />
                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("biodata:form.field.institution")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <TextValidator
                      style={{ width: "97%", fontSize: 20 }}
                      validators={["required"]}
                      errorMessages={t(
                        "biodata:validation.required.institution"
                      )}
                      onChange={e => {
                        this.handleChange({
                          institution: e.currentTarget.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t("biodata:form.placeholder.institution")}
                      className="Field-cfm-cv"
                      value={this.state.institution}
                    />
                    <div
                      value={this.state.tmpYear}
                      style={{
                        width: "97%",
                        fontSize: 20,
                      }}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {t("biodata:form.field.yearInformal")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <DatePickerValidator
                        className="Profile-cfm-cv"
                        label={""}
                        format="yyyy"
                        maxDetail="decade"
                        style={{ width: 520 }}
                        value={this.state.tmpYear}
                        onChange={e => {
                          if (e) {
                            General.visible = true;
                            this.handleChange({
                              tmpYear: e,
                              year: e,
                            });
                          }
                        }}
                        yearPlaceholder={t("biodata:form.placeholder.year")}
                      />
                    </div>
                    <div>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {t("biodata:form.field.certificate")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 8,
                        }}
                        className="radio">
                        <label
                          style={{
                            fontSize: 18,
                            color: palette.primary,
                            marginRight: 40,
                            marginBottom: 5,
                          }}>
                          <input
                            style={{
                              color: "#52C8F4",
                            }}
                            type="radio"
                            options={certificate}
                            value={this.state.isSertifikat}
                            checked={this.state.isSertifikat === false}
                            onChange={option => {
                              this.handleChange({ isSertifikat: false });
                            }}
                          />
                          {NotAvailable}
                        </label>

                        <label
                          style={{
                            fontSize: 18,
                            color: palette.primary,
                            marginRight: 40,
                          }}>
                          <input
                            style={{
                              color: "#52C8F4",
                            }}
                            type="radio"
                            options={certificate}
                            value={this.state.isSertifikat}
                            checked={this.state.isSertifikat === true}
                            onChange={option => {
                              this.handleChange({ isSertifikat: true });
                            }}
                          />
                          {Available}
                        </label>
                      </div>
                      {/* {this.state.isSertifikat && (
                        <TextField
                          style={{
                            width: "94%",
                            fontSize: 20,
                            marginTop: -25,
                            marginLeft: 20,
                          }}
                          onChange={e => {
                            this.handleChange({
                              certificateName: e.currentTarget.value,
                            });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={t(
                            "biodata:form.placeholder.certificateName"
                          )}
                          className="Field"
                          value={this.state.certificateName}
                        />
                      )} */}
                    </div>
                  </div>
                </DialogContent>
                <DialogActions
                  style={{
                    backgroundColor: palette.primary,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        onClick={this.handleClose}
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {t("biodata:CVbtnCancel")}
                      </Button>
                    </div>
                    <div style={{ width: "76%" }}></div>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        type="submit"
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {isedit
                          ? t("biodata:CVbtnEdit")
                          : t("biodata:CVbtnAdd")}
                      </Button>
                    </div>
                  </div>
                </DialogActions>
              </ValidatorForm>
            </div>
          </div>
        </Dialog>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "4%",
              width: "100%",
            }}>
            <div
              style={{
                width: "100%",
              }}>
              {listInformalEducation.length === 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10%",
                  }}>
                  <div>
                    <img src={IconFolder} alt="Folder" />
                  </div>
                  <div>
                    <label style={{ color: palette.primary }}>
                      {t("biodata:CVclickaddbutton")} <br />{" "}
                      {t("biodata:CVinformalEducation")}
                    </label>
                  </div>
                </div>
              )}
              {listInformalEducation.map((v, k) => {
                return (
                  <div
                    key={k}
                    style={{
                      borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                    }}>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div
                        style={{
                          color: palette.primary,
                          fontSize: 18,
                          fontWeight: "bolder",
                        }}>
                        {v.name}
                      </div>
                      <div
                        style={{
                          color: palette.primary,
                          fontSize: 18,
                          fontWeight: "bolder",
                        }}>
                        {v.study}
                      </div>
                      <div
                        style={{
                          color: palette.primary,
                          fontSize: 18,
                          fontWeight: "lighter",
                          display: "flex",
                          flexDirection: "row",
                        }}>
                        <div
                          style={{
                            width: "100%",
                          }}>
                          {v.institution}
                        </div>
                        <div>
                          <img
                            onClick={() => {
                              this.handleClickOpen(k);
                            }}
                            style={{ cursor: "pointer" }}
                            src={IconEditCV}
                            alt="Edit"
                          />
                        </div>
                        <div style={{ width: "4%" }}></div>
                        <div>
                          <img
                            onClick={() => {
                              this.handleClickDelete(k);
                            }}
                            style={{ cursor: "pointer" }}
                            src={IconDeleteCV}
                            alt="Edit"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "85%",
                          color: palette.primary,
                          fontSize: 18,
                          fontWeight: "lighter",
                          marginTop: "-1.5%",
                        }}>
                        {v.year}
                      </div>
                      <div>
                        <br />
                      </div>
                    </div>
                    <DialogueConfirm
                      open={opendelete}
                      title={t("auth:CVdeleteConfirm.applicant.title")}
                      content={t("auth:CVdeleteConfirm.applicant.message")}
                      btnYes={t("auth:CVdeleteConfirm.applicant.btnOk")}
                      btnNo={t("auth:CVdeleteConfirm.applicant.btnCancel")}
                      onOK={() => this.deleteinformaleducation()}
                      onClose={() => {
                        this.setState({ opendelete: false });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {this.props.edit && (
            <CVFooter
              text="save"
              BackClick={() => {
                history.goBack();
              }}
              NextClick={() => {
                this.submitdata();
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation()(CVInformalEducation);
