import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Typography, Grid, Button } from "@material-ui/core";
import { IconEdit, IconVacancyBullet } from "assets/img";
import { getItem, GetLocalItem } from "app/utils";
import data from "../data";

class JobRequirementConfirmation extends React.Component {
  constructor(props) {
    super(props);
    if (GetLocalItem("vacancyGet")) {
      data.listVacancy = GetLocalItem("vacancyGet");
    }

    this.state = {
      listVacancy: data.listVacancy,
    };
  }

  render() {
    const { listVacancy } = this.state;
    const { data, t } = this.props;
    var otherWorkEnvID = listVacancy.environmentWork.find(p => p.workEnvironmentEng === "Others").id;
    var otherPerformID = listVacancy.jobPerform.find(p => p.jobPerformEng === "Others").id;
    return (
      <Grid container style={{ padding: "3% 12% 8% 6%" }}>
        <Grid item lg={9} md={9} sm={9}>
          <Typography
            style={{
              fontSize: "24px",
              color: palette.primary,
            }}>
            {t("vacancy:create.step", { returnObjects: true })[1]}
          </Typography>
        </Grid>

        <Grid item lg={3} md={3} sm={3}>
          <Button
            onClick={() => {
              this.props.onEdit();
            }}
            style={{
              border: "1px solid #515D6E",
              borderRadius: "4px",
              marginLeft: "30%",
            }}
          >
            <img
              style={{ padding: "10px 0px 12px 22px" }}
              src={IconEdit}
              alt={"Edit"}
              height="15vh"
            />
            <Typography
              style={{
                padding: "9px 22px 6px 11px",
                fontSize: "14px",
                color: "#515D6E",
                textTransform: "none",
              }}>
              Edit
            </Typography>
          </Button>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.minEdu.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.vacancy.minEducation}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.univPref.label")}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {data.vacancyUniversitys.length > 0
            ? data.vacancyUniversitys.map((v, i) => {
              return (
                <div style={{ width: "51%" }} key={i}>
                  <Grid container>
                    <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                      <img
                        style={{ paddingTop: 5, paddingLeft: 2 }}
                        src={IconVacancyBullet}
                        alt={i}
                        height="10vh"
                        align="center"
                      />
                    </Grid>
                    <Grid
                      item
                      lg={11}
                      md={11}
                      sm={11}
                      style={{ marginTop: 4 }}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          color: palette.primary,
                        }}>
                        {listVacancy.listUniversity
                          ? listVacancy.listUniversity.find(p => p.id === v).university
                          : ""
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            })
          : <Typography
              style={{
                fontSize: "18px",
                color: palette.primary,
              }}>
              {t("vacancy:create.jobReq.noPref")}
            </Typography>
          }
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.majorPref.label")}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {data.vacancyMajorAliases.length > 0
            ? data.vacancyMajorAliases.map((v, i) => {
              return (
                <div style={{ width: "51%" }} key={i}>
                  <Grid container>
                    <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                      <img
                        style={{ paddingTop: 5, paddingLeft: 2 }}
                        src={IconVacancyBullet}
                        alt={i}
                        height="10vh"
                        align="center"
                      />
                    </Grid>
                    <Grid
                      item
                      lg={11}
                      md={11}
                      sm={11}
                      style={{ marginTop: 4 }}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          color: palette.primary,
                        }}>
                        {listVacancy.listMajorAlias
                          ? (getItem("lang") === "en"
                            ? listVacancy.listMajorAlias.find(
                                p => p.id === v
                              ).aliasEng
                            : listVacancy.listMajorAlias.find(
                                p => p.id === v
                              ).aliasIna)
                          : ""
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            })
          : <Typography
              style={{
                fontSize: "18px",
                color: palette.primary,
              }}>
              {t("vacancy:create.jobReq.noPref")}
            </Typography>
          }
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.minGpa.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.vacancy.minGpa.toFixed(2)}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.languageSkill.label")}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {listVacancy.languageSkills
              ? listVacancy.languageSkills.find(
                  p => p.id === data.vacancyRequirement.languageSkill
                )
                ? listVacancy.languageSkills.find(
                    p => p.id === data.vacancyRequirement.languageSkill
                  ).languageSkill
                : ""
              : ""}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.specificCertification.label")}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {data.vacancyRequirement.hasCertificate &&
          !(
            data.vacancyRequirementCertificate.length === 1 &&
            data.vacancyRequirementCertificate[0] === ""
          ) ? (
            data.vacancyRequirementCertificate.map((v, i) => {
              return (
                <div style={{ width: "51%" }} key={i}>
                  <Grid container>
                    <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                      <img
                        style={{ paddingTop: 5, paddingLeft: 2 }}
                        src={IconVacancyBullet}
                        alt={i}
                        height="10vh"
                        align="center"
                      />
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} style={{ marginTop: 4 }}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          color: palette.primary,
                        }}>
                        {v}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            })
          ) : (
            <Typography
              style={{
                fontSize: "18px",
                color: palette.primary,
              }}>
              {data.vacancyRequirement.hasCertificate
                ? t("vacancy:create.jobReq.specificCertification.options", {
                    returnObjects: true,
                  })[0]
                : data.vacancyRequirement.hasCertificate !== null
                ? t("vacancy:create.jobReq.specificCertification.options", {
                    returnObjects: true,
                  })[1]
                : null}
            </Typography>
          )}
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.perform.label")}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {data.vacancyRequirementJobPerform.length > 0
            ? data.vacancyRequirementJobPerform.map((v, i) => {
                if(v === otherPerformID) {
                  return (
                    <div key={i}>
                      {data.otherJobPerform.map((vOther, iOther) => {
                        return (
                          <div style={{ width: "51%" }} key={iOther}>
                            <Grid container>
                              <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                                <img
                                  style={{ paddingTop: 5, paddingLeft: 2 }}
                                  src={IconVacancyBullet}
                                  alt={iOther}
                                  height="10vh"
                                  align="center"
                                />
                              </Grid>
                              <Grid
                                item
                                lg={11}
                                md={11}
                                sm={11}
                                style={{ marginTop: 4 }}>
                                <Typography
                                  style={{
                                    fontSize: "18px",
                                    color: palette.primary,
                                  }}>
                                  {vOther}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                        )
                      })}
                    </div>
                  )
                } else {
                  return (
                    <div style={{ width: "51%" }} key={i}>
                      <Grid container>
                        <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                          <img
                            style={{ paddingTop: 5, paddingLeft: 2 }}
                            src={IconVacancyBullet}
                            alt={i}
                            height="10vh"
                            align="center"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={11}
                          md={11}
                          sm={11}
                          style={{ marginTop: 4 }}>
                          <Typography
                            style={{
                              fontSize: "18px",
                              color: palette.primary,
                            }}>
                            {listVacancy.jobPerform
                                ? getItem("lang") === "en"
                                  ? listVacancy.jobPerform.find(
                                      p => p.id === v
                                    ).jobPerformEng
                                  : listVacancy.jobPerform.find(
                                      p => p.id === v
                                    ).jobPerformIna
                                : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  );
                }
              })
            : null}
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.workEnv.label")}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {data.vacancyRequirementEnvironmentWork.length > 0
            ? data.vacancyRequirementEnvironmentWork.map((v, i) => {
                if(v === otherWorkEnvID) {
                  return (
                    <div key={i}>
                      {data.otherEnvironments.map((vOther, iOther) => {
                        return (
                          <div style={{ width: "51%" }} key={iOther}>
                            <Grid container>
                              <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                                <img
                                  style={{ paddingTop: 5, paddingLeft: 2 }}
                                  src={IconVacancyBullet}
                                  alt={iOther}
                                  height="10vh"
                                  align="center"
                                />
                              </Grid>
                              <Grid
                                item
                                lg={11}
                                md={11}
                                sm={11}
                                style={{ marginTop: 4 }}>
                                <Typography
                                  style={{
                                    fontSize: "18px",
                                    color: palette.primary,
                                  }}>
                                  {vOther}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                        )
                      })}
                    </div>
                  )
                } else {
                  return (
                    <div style={{ width: "51%" }} key={i}>
                      <Grid container>
                        <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                          <img
                            style={{ paddingTop: 5, paddingLeft: 2 }}
                            src={IconVacancyBullet}
                            alt={i}
                            height="10vh"
                            align="center"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={11}
                          md={11}
                          sm={11}
                          style={{ marginTop: 4 }}>
                          <Typography
                            style={{
                              fontSize: "18px",
                              color: palette.primary,
                            }}>
                            {listVacancy.environmentWork
                              ? getItem("lang") === "en"
                                ? listVacancy.environmentWork.find(
                                    p => p.id === v
                                  ).workEnvironmentEng
                                : listVacancy.environmentWork.find(
                                    p => p.id === v
                                  ).workEnvironmentIna
                              : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  )
                }
              })
            : null}
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(JobRequirementConfirmation);
