import { Typography } from "@material-ui/core";
import { getItem } from "app/utils";
import { palette } from "assets/css/main";
import { IcnCheck, IcnTimer, IcnX } from "assets/img";
import React from "react";
import { withTranslation } from "react-i18next";

export default withTranslation()(
  class InterestInstructions extends React.Component {
    static defaultProps = {
      onFinish: () => {},
    };
    state = {
      sample: 1,
      timeout: 20 * 1000,
      time_min: "00",
      time_sec: "00",
    };
    timeInterval = null;
    startCount() {
      this.timeInterval = setInterval(() => {
        this.countTime();
      }, 1000);
    }
    countTime() {
      const time = this.state.timeout - 1000;
      if (time >= 0) {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        this.setState({
          timeout: time,
          time_sec: seconds < 10 ? "0" + seconds : seconds,
          time_min: minutes < 10 ? "0" + minutes : minutes,
        });
      } else {
        clearInterval(this.timeInterval);
        // this.props.onFinish();
      }
    }
    render() {
      const { title, instruction, questionID, notinline, t } = this.props;
      const { time_min, time_sec } = this.state;
      return (
        <div
          style={{
            display: "flex",
            width: "100vw",
            paddingTop: 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <div style={{ position: "absolute", right: 80, top: 180 }}>
            <div
              style={{
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={IcnTimer} width={50} height={50} alt="paper-grey" />
              <Typography
                style={{
                  marginLeft: 20,
                  color: palette.primary,
                  fontWeight: "bold",
                  fontSize: 18,
                }}>
                {time_min}:{time_sec}
              </Typography>
            </div>
          </div>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: 24,
              color: palette.primary,
            }}>
            {title}
          </Typography>
          <div style={{ width: 628, marginTop: 30 }}>
            <div
              style={{
                color: palette.primary,
                fontWeight: "bold",
                backgroundColor: palette.info,
                width: 200,
                paddingTop: 7,
                paddingBottom: 7,
                paddingLeft: 10,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}>
              {getItem("lang") === "en" ? "Instruction" : "Instruksi"}
            </div>
            {!notinline ? (
              <Typography
                style={{
                  color: palette.primary,
                  marginTop: 20,
                  marginBottom: 40,
                  fontSize: 18,
                }}>
                {instruction}
              </Typography>
            ) : (
              <table
                style={{
                  width: 500,
                  marginTop: 20,
                  marginBottom: 20,
                  color: palette.primary,
                }}>
                <tbody>
                  <tr>
                    <td>
                      <Typography>
                        {t("basictest:interestTest.infoMatching")}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <img
                        src={IcnCheck}
                        width={25}
                        alt={"Checklist"}
                        style={{ marginRight: 10 }}
                      />
                      <Typography>
                        {t("basictest:interestTest.competent")}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}>
                      <img
                        src={IcnX}
                        alt="X"
                        width={25}
                        style={{ marginRight: 10 }}
                      />
                      <Typography>
                        {t("basictest:interestTest.notCompetent")}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <div
              style={{
                color: palette.primary,
                fontWeight: "bold",
                backgroundColor: palette.info,
                width: 200,
                paddingTop: 7,
                paddingBottom: 7,
                paddingLeft: 10,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}>
              {getItem("lang") === "en" ? "Example" : "Contoh"}
            </div>
            <video
              style={{
                marginTop: 50,
                borderRadius: 8,
                border: "2px solid " + palette.grey,
              }}
              width={600}
              height={288}
              autoPlay
              controls
              onEnded={() => this.props.onFinish()}
              controlsList="nodownload"
              onPlay={() => this.startCount()}>
              <source
                src={
                  questionID === "df9b82fa-50c2-46cd-8639-2ffa73125200"
                    ? "https://www.hiregrade.id/assets/img/interest/Interest_Activity.mp4"
                    : questionID === "29a285c2-fa10-4ee6-8789-01dfffe172db"
                    ? "https://www.hiregrade.id/assets/img/interest/Interest_Occupation.mp4"
                    : "https://www.hiregrade.id/assets/img/interest/Interest_Capability.mp4"
                }
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      );
    }
  }
);
