import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  IcnAddress,
  IcnPhone,
  IcnContactMail,
  IcnWork5,
  IcnFormal5,
  IcnInformal5,
  IcnOrganizational5,
  IcnBirthday5,
  IcnGender5,
  IcnMarital5,
  IcnKTP5,
  IcnNovice5,
  IcnIntermediate5,
  IcnAdvanced5,
} from "assets/img";
import moment from "moment";
import { getItem, loadImage } from "app/utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  sectionLeft: {
    height: "100%",
    width: "26%",
    backgroundColor: "#000000",
    opacity: 0.78,
    padding: "40pt 17pt 0pt 17pt",
    display: "flex",
    flexDirection: "column",
  },
  sectionRight: {
    height: "100%",
    width: "74%",
    padding: "56pt 26pt 0pt 23pt",
    display: "flex",
    flexDirection: "column",
  },
});

const WorkExperienceView = props => {
  return (
    <View
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "15%",
          }}
        >
          <Image 
            src={IcnWork5}
            style={{
              width: 15,
              height: 13,
            }}
          />
        </View>
        <View
          style={{
            width: "85%",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000000",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.workExp")}
          </Text>
        </View>
      </View>
      {
        props.data.map((v,i) => {
          return (
            <View key={i}
              style={{
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "15%",
                }}
              >
                <View 
                  style={{
                    marginLeft: 3.5,
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: "#000000",
                    opacity: 0.8,
                  }}
                />
              </View>
              <View
                style={{
                  width: "85%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.role}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {(getItem("lang") === "en" ? moment(v.yearStart).lang("en").format("MMMM YYYY") : moment(v.yearStart).format("MMMM YYYY")) + " - " + (v.yearEnd ? v.yearEnd : props.t("biodata:cv_template.hg1.workYearEnd"))}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    fontStyle: "italic",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {
                    v.status === "1" ? props.t("biodata:cv_template.hg1.employeeStatus.1") : (
                      v.status === "2" ? props.t("biodata:cv_template.hg1.employeeStatus.2") : (
                        v.status === "3" ? props.t("biodata:cv_template.hg1.employeeStatus.3") : null
                      )
                    )
                  }
                </Text>
              </View>
            </View>
          )
        })
      }
    </View>
  )
}

const FormalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "15%",
          }}
        >
          <Image 
            src={IcnFormal5}
            style={{
              width: 15,
              height: 13,
            }}
          />
        </View>
        <View
          style={{
            width: "85%",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000000",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.formalEdu")}
          </Text>
        </View>
      </View>
      {
        props.data.map((v,i) => {
          return (
            <View key={i}
              style={{
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "15%",
                }}
              >
                <View 
                  style={{
                    marginLeft: 3.5,
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: "#000000",
                    opacity: 0.8,
                  }}
                />
              </View>
              <View
                style={{
                  width: "85%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.institution}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.degree + " | " + v.major}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {"GPA : " + v.grade}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    fontStyle: "italic",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {"Graduated : " + v.yearGraduate}
                </Text>
              </View>
            </View>
          )
        })
      }
    </View>
  )
}

const InformalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "15%",
          }}
        >
          <Image 
            src={IcnInformal5}
            style={{
              width: 15,
              height: 12,
            }}
          />
        </View>
        <View
          style={{
            width: "85%",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000000",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.informalEdu")}
          </Text>
        </View>
      </View>
      {
        props.data.map((v,i) => {
          return (
            <View key={i}
              style={{
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "15%",
                }}
              >
                <View 
                  style={{
                    marginLeft: 3.5,
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: "#000000",
                    opacity: 0.8,
                  }}
                />
              </View>
              <View
                style={{
                  width: "85%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.institution}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.year}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    fontStyle: "italic",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {"Certificate : " + (v.isSertifikat ? props.t("biodata:cv_template.hg1.certificate.true") : props.t("biodata:cv_template.hg1.certificate.false"))}
                </Text>
              </View>
            </View>
          )
        })
      }
    </View>
  )
}

const OrganizationalExperienceView = props => {
  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "15%",
          }}
        >
          <Image 
            src={IcnOrganizational5}
            style={{
              width: 15,
              height: 20,
            }}
          />
        </View>
        <View
          style={{
            width: "85%",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              color: "#000000",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.organizationalExp")}
          </Text>
        </View>
      </View>
      {
        props.data.map((v,i) => {
          return (
            <View key={i}
              style={{
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "15%",
                }}
              >
                <View 
                  style={{
                    marginLeft: 3.5,
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: "#000000",
                    opacity: 0.8,
                  }}
                />
              </View>
              <View
                style={{
                  width: "85%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {
                    v.position === "1" ? props.t("biodata:cv_template.hg1.organizationalPosition.1") : (
                      v.position === "2" ? (
                        v.function ? v.function : props.t("biodata:cv_template.hg1.organizationalPosition.2")
                      ) : null
                    )
                  }
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.field}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    fontStyle: "italic",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.year}
                </Text>
              </View>
            </View>
          )
        })
      }
    </View>
  )
}

const LanguageView = props => {
  return (
    <View
      style={{
        marginTop: 36,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        style={{
          fontFamily: "AdobeClean",
          fontSize: 16,
          fontWeight: "bold",
          color: "#ffffff",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.language")}
      </Text>
      <View
        style={{
          marginTop: 10,
          paddingTop: 10,
          borderTop: "1pt solid #ffffff",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View key={i}
                style={i === 0 ? {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                } : {
                  marginTop: 8,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "40%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "AdobeClean",
                      fontSize: 8,
                      color: "#ffffff",
                    }}
                  >
                    {getItem("lang") === "en" ? v.languageSkillEng : v.languageSkillIna}
                  </Text>
                </View>
                <View
                  style={{
                    width: "60%",
                  }}
                >
                  <Image 
                    src={
                      v.proficiency === 1 ? IcnNovice5 : (
                        v.proficiency === 2 ? IcnIntermediate5 : (
                          v.proficiency === 3 ? IcnAdvanced5 : null
                        )
                      )
                    }
                    style={{
                      width: 67,
                      height: 7,
                    }}
                  />
                </View>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const TemplateHG5 = props => (
  <Document
    title={"CV - " + props.data.personalInfo.name}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <Page size="A4" style={styles.page}>
      <View style={styles.sectionLeft}>
        <View
          style={{
            width: "100%",
            alignItems: "center",
          }}
        >
          <Image 
            src={props.data.personalInfo.photo + "?t=" + new Date().getTime()}
            style={{
              width: 100,
              height: 100,
              borderRadius: 50,
            }}
          />
        </View>
        <View
          style={{
            marginTop: 45,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              color: "#ffffff",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg5.profile")}
          </Text>
          <View
            style={{
              marginTop: 10,
              paddingTop: 10,
              borderTop: "1pt solid #ffffff"
            }}
          >
            <Text
              style={{
                fontFamily: "AdobeClean",
                fontSize: 8,
                color: "#ffffff"
              }}
            >
              {props.data.personalInfo.description}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 36,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              color: "#ffffff",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.bio")}
          </Text>
          <View
            style={{
              marginTop: 10,
              paddingTop: 10,
              borderTop: "1pt solid #ffffff",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "15%",
                }}
              >
                <Image 
                  src={IcnBirthday5}
                  style={{
                    width: 10,
                    height: 10,
                  }}
                />
              </View>
              <View
                style={{
                  width: "85%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 8,
                    color: "#ffffff",
                  }}
                >
                  {props.data.personalInfo.birthPlace + ", " + (getItem("lang") === "en" ? moment(props.data.personalInfo.birthDate).lang("en").format("DD MMMM YYYY") : moment(props.data.personalInfo.birthDate).format("DD MMMM YYYY"))}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 10,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "15%",
                }}
              >
                <Image 
                  src={IcnGender5}
                  style={{
                    width: 7,
                    height: 7,
                  }}
                />
              </View>
              <View
                style={{
                  width: "85%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 8,
                    color: "#ffffff",
                  }}
                >
                  {
                    props.data.personalInfo.gender === "M" ? props.t("biodata:cv_template.hg1.gender.m") : (
                      props.data.personalInfo.gender === "F" ? props.t("biodata:cv_template.hg1.gender.f") : null
                    )
                  }
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 10,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "15%",
                }}
              >
                <Image 
                  src={IcnMarital5}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </View>
              <View
                style={{
                  width: "85%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 8,
                    color: "#ffffff",
                    textTransform: "capitalize",
                  }}
                >
                  {props.data.personalInfo.maritalStatus}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 10,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "15%",
                }}
              >
                <Image 
                  src={IcnKTP5}
                  style={{
                    width: 9,
                    height: 7,
                  }}
                />
              </View>
              <View
                style={{
                  width: "85%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 8,
                    color: "#ffffff",
                    textTransform: "capitalize",
                  }}
                >
                  {props.data.personalInfo.identityNumber}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {props.data.personalInfo.listUserLanguage.length > 0 ?
          <LanguageView 
            data={props.data.personalInfo.listUserLanguage}
            t={props.t}
          /> : null
        }
        <View
          style={{
            width: "110%",
            height: 143,
            position: "absolute",
            bottom: 0,
            padding: "0pt 0pt 36pt 17pt",
          }}
        >
          {
            props.data.toogle &&
            <View
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Image 
                src={loadImage("qr-code.png")}
                style={{
                  width: 48,
                  height: 48,
                }}
              />
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontWeight: "bold",
                  marginTop: 12,
                  fontSize: 7,
                  color: "white",
                }}
              >
                {props.t("biodata:cv_template.hg1.qrTitle")}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 7,
                  color: "white",
                }}
              >
                {props.t("biodata:cv_template.hg1.qrDesc")}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontWeight: "bold",
                  fontSize: 7,
                  color: "white",
                }}
              >
                {props.t("biodata:cv_template.hg1.qrLink")}
              </Text>
            </View>
          }
        </View>
      </View>
      <View style={styles.sectionRight}>
        <Text
          style={{
            fontFamily: "AdobeClean",
            fontSize: 30,
            fontWeight: "bold",
            color: "#000000",
            textTransform: "uppercase",
          }}
        >
          {props.data.personalInfo.name}
        </Text>
        <Text
          style={{
            fontFamily: "AdobeClean",
            marginTop: 4,
            fontSize: 16,
            fontWeight: "bold",
            color: "#000000",
            opacity: 0.78,
          }}
        >
          {props.data.listWorks.length > 0 ? props.data.listWorks[0].role : null}
        </Text>
        <View
          style={{
            marginTop: 30,
            paddingTop: 13,
            borderTop: "1pt solid #000000",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            borderBottom: "1pt solid #000000",
            paddingBottom: 13,
            marginBottom: 24,
          }}
        >
          <View
            style={{
              width: "35%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnAddress}
                style={{
                  width: 12,
                  height: 9,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 9,
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {props.data.personalInfo.address}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginLeft: "5%",
              width: "25%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnPhone}
                style={{
                  width: 9,
                  height: 9,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 9,
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {"+62 " + props.data.personalInfo.phoneNumber}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginLeft: "5%",
              width: "30%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnContactMail}
                style={{
                  width: 9,
                  height: 9,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 9,
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {props.data.email ? props.data.email : " - "}
              </Text>
            </View>
          </View>
        </View>
        {props.data.listWorks.length > 0 ?
          <WorkExperienceView 
            data={props.data.listWorks}
            t={props.t}
          /> : null
        }
        {props.data.listFormalEducation.length > 0 ?
          <FormalEducationView 
            data={props.data.listFormalEducation}
            t={props.t}
          /> : null
        }
        {props.data.listInformalEducation.length > 0 ?
          <InformalEducationView 
            data={props.data.listInformalEducation}
            t={props.t}
          /> : null
        }
        {props.data.listOrganization.length > 0 ?
          <OrganizationalExperienceView 
            data={props.data.listOrganization}
            t={props.t}
          /> : null
        }
      </View>
    </Page>
  </Document>
);
export default TemplateHG5;
