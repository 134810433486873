import React from "react";
import {
  IcnPaperGrey,
  IcnPdfFileSmall,
  IcnUpload,
  IcnTrashSmall,
} from "assets/img";
import Dropzone from "react-dropzone";
import propTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import imageCompression from "browser-image-compression";
import { withTranslation } from "react-i18next";
import alertData from "app/components/alert/alert-data";
import DialogueConfirm from "app/components/modal/confirm";

class UploaderOfferingLetter extends React.Component {
  state = { preview: null, openDeleteConfirm: false };
  options = {
    maxSizeMB: 1,
  };
  showHideButton() {
    try {
      document.getElementById("preview-img").blur();
      document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
    } catch (error) {
      console.log(error);
    }
  }
  componentDidMount() {
    if (this.props.value) {
      setTimeout(() => {
        this.showHideButton();
      }, 200);
    }
  }
  async readAsBlob(file, cb) {
    let reader = new FileReader();
    const imageFile = file[0];

    const options = {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      reader.readAsDataURL(compressedFile);
      reader.onload = function () {
        cb(reader.result);
      };
    } catch (error) {
      cb("Failed :" + error);
    }
  }

  async handleImageUpload(f) {
    const imageFile = f;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 640,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.log("handleImageUpload()", error);
      return imageFile;
    }
  }

  deletefile() {
    this.setState({
      openDeleteConfirm: true,
    });
  }

  deleteConfirm() {
    this.props.onDropFile("");
    this.setState({
      filename: null,
      filesize: "",
      openDeleteConfirm: false,
    });
  }

  render() {
    const {
      textStyle,
      style,
      previewStyle,
      iconStyle,
      isBlob,
      labelStyle,
      t,
    } = this.props;
    const { filename, filesize, openDeleteConfirm } = this.state;
    var preview = this.state.preview ? this.state.preview : null;
    //var filesize = 0;
    if (this.props.value) {
      if (this.props.value.indexOf("http") === 0) {
        var ex = this.props.value.split(".");
        var ext = ex[ex.length - 1];
        if (
          ext.toLowerCase() === "png" ||
          ext.toLowerCase() === "jpg" ||
          ext.toLowerCase() === "jpeg"
        ) {
          preview = this.props.value;
        } else if (ext.toLowerCase() === "pdf") {
          preview = IcnPdfFileSmall;
        } else {
          preview = IcnPaperGrey;
        }
      } else {
        preview = "data:image/jpeg;base64," + this.props.value;
      }
    }
    return (
      <div
        style={{
          width: 629,
          marginBottom: 20,
          boxSizing: "border-box",
          ...style,
        }}
        value={preview}>
        {/* Delete Confirm Dialog */}
        <DialogueConfirm
          open={openDeleteConfirm}
          title={t("candidates:DeleteOfferingLetterConfim.title")}
          content={t("candidates:DeleteOfferingLetterConfim.message")}
          btnYes={t("candidates:DeleteOfferingLetterConfim.btnOk")}
          btnNo={t("candidates:DeleteOfferingLetterConfim.btnCancel")}
          onOK={() => {
            this.deleteConfirm();
          }}
          onClose={() => {
            this.setState({ openDeleteConfirm: false });
          }}
        />
        <Typography
          style={{
            marginBottom: 10,
            textTransform: "uppercase",
            color: palette.primary,
            ...labelStyle,
          }}>
          {this.props.label}
          {this.props.required && (
            <span
              className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
              style={{ ...labelStyle }}>
              &thinsp;*
            </span>
          )}
        </Typography>
        <Dropzone
          accept={this.props.accept}
          onDrop={acceptedFiles => {
            if (isBlob) {
              this.readAsBlob(acceptedFiles, base => {
                this.setState({ preview: base });
                this.props.onDropFile(base.split(",")[1]);
              });
              this.showHideButton();
            } else {
              var ext;
              var split;
              try {
                split = acceptedFiles[0].name.split(".");
                ext = split[split.length - 1];
              } catch (error) {
                console.log(error);
                ext = "png";
              }
              if (
                ext.toLowerCase() === "png" ||
                ext.toLowerCase() === "jpg" ||
                ext.toLowerCase() === "jpeg"
              ) {
                this.handleImageUpload(acceptedFiles[0])
                  .then(r => {
                    this.props.onDropFile({
                      file: r,
                      extension: ext,
                    });
                  })
                  .catch(e => {
                    console.log("Failed upload", e);
                    alertData.show("Error", "error");
                  });
              } else {
                this.props.onDropFile({
                  file: acceptedFiles[0],
                  extension: ext,
                });
              }
            }
            this.setState({
              filename: acceptedFiles[0].name,
              filesize:
                Number((acceptedFiles[0].size / 1024 / 1024).toFixed(1)) +
                " MB",
            });
          }}>
          {({ getRootProps, getInputProps }) => (
            <section
              style={{
                width: "100%",
                border: "1px dashed rgba(46, 105, 178, 0.3)",
                boxSizing: "border-box",
                borderRadius: 2,
                padding: 5,
                display: "flex",
              }}>
              <div
                {...getRootProps()}
                id="preview-img"
                style={{
                  display: "flex",
                  height: 50,
                  alignItems: "center",
                  width: "95%",
                }}>
                <input {...getInputProps()} />
                {preview && (
                  <div
                    className="div-preview"
                    key={new Date().getTime()}
                    style={{
                      backgroundImage: "url(" + preview + ")",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      height: "100%",
                      width: "100%",
                      boxSizing: "border-box",
                      ...previewStyle,
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 5,
                      }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: 20,
                        }}>
                        <div
                          style={{
                            color: palette.primary,
                            fontSize: 13,
                            marginLeft: 20,
                          }}>
                          {filename}
                        </div>
                        <div
                          style={{
                            color: palette.primary,
                            fontSize: 13,
                            marginLeft: 20,
                          }}>
                          {filesize}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!preview && (
                  <div
                    style={{
                      color: "rgba(46, 105, 178, 0.2)",
                      position: "relative",
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "rgba(46, 105, 178, 0.1)",
                      alignItems: "center",
                      justifyContent: "center",
                      ...previewStyle,
                    }}>
                    <img
                      src={IcnUpload}
                      alt="Upload Icon"
                      style={{ ...iconStyle }}
                    />
                    <p style={{ textAlign: "center", ...textStyle }}>
                      <strong>
                        {t("candidates:uploaderofferingletter.choose")}
                      </strong>
                      &nbsp;
                      {t("candidates:uploaderofferingletter.drag")}
                      <br />
                      {t("candidates:uploaderofferingletter.format")}
                    </p>
                  </div>
                )}
              </div>
              {preview && (
                <div
                  onClick={e => {
                    this.deletefile();
                  }}
                  style={{
                    marginTop: 12,
                    marginLeft: 3,
                    cursor: "pointer",
                  }}>
                  <img src={IcnTrashSmall} alt="Trash Icon" />
                </div>
              )}
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
}
UploaderOfferingLetter.propTypes = {
  value: propTypes.string,
  label: propTypes.string,
  onDropFile: propTypes.func,
  style: propTypes.object,
  accept: propTypes.string,
};
UploaderOfferingLetter.defaultProps = {
  value: "",
  label: "",
  accept: "*",
  isBlob: true,
  onDropFile: function onDropFile() {
    console.log("Please define onDropFile");
  },
  style: {},
  required: false,
};
export default withTranslation()(UploaderOfferingLetter);
