import { observable, decorate, action } from "mobx";
class BioDataContainer {
  personal_data = {
    name: "",
    firstName: "",
    middleName: "",
    lastName: "",
    nik: "",
    address: "",
    birthDate: "",
    identityNumber: "",
    birthPlace: "",
    maritalStatus: "",
    street: "",
    rtrw: "",
    rt: "000",
    rw: "000",
    subDistrict: "",
    district: "",
    city: "",
    province: "",
    zipcode: "",
    phoneNumber: "",
    email: "",
    photo: "",
    identityPhoto: "",
    bdate: "",
    description: "",
    listUserLanguage: [
      {
        languageId: "",
        proficiency: "",
      },
    ],
  };
  formal_education = {
    userId: "",
    degree: "",
    institutionLocation: "",
    institution: "",
    institutionId: "",
    major: "",
    majorId: "",
    nim: "",
    grade: "",
    yearGraduate: "",
  };
  informal_education = {
    userId: "",
    name: "",
    study: "",
    institution: "",
    year: 0,
    isSertifikat: undefined,
  };
  organization = {
    userId: "",
    name: "",
    field: "",
    year: 0,
    position: " ",
    function: "",
  };
  work_experience = {
    userId: "",
    status: "",
    name: "",
    field: "",
    role: "",
    yearStart: "",
    yearEnd: null,
    stillWorking: true,
    start: "",
    end: "",
  };
  stepper = {
    nextBtn: false,
    backBtn: false,
    activeStep: 0,
    footer: false,
    length: 0,
  };
  biodata_workExp = {};
  biodata_additionalQuestion = [];
  additional_question = {
    workExp: {
      status: "",
      name: "",
      field: "",
      role: "",
      yearStart: "",
      yearEnd: "",
      stillWorking: false,
    },
    additionalQuestions: [
      // {
      //   questionId: null,
      //   responseAnswers: []
      // }
    ],
  };

  listFormalEducation = [];
  listWorks = [];
  listInformalEducation = [];
  listOrganization = [];

  showNext() {
    this.stepper.nextBtn = !this.stepper.nextBtn;
  }
  showBack() {
    this.stepper.backBtn = !this.stepper.backBtn;
  }
  setPersonalData(new_data) {
    this.personal_data = { ...this.personal_data, ...new_data };
  }
  setFormalEducation(new_data) {
    this.formal_education = { ...this.formal_education, ...new_data };
  }
  setWorkingExperience(new_data) {
    this.work_experience = { ...this.work_experience, ...new_data };
  }
  setInformalEducation(new_data) {
    this.informal_education = { ...this.informal_education, ...new_data };
  }
  setOrganization(new_data) {
    this.organization = { ...this.organization, ...new_data };
  }
  setAdditionalQuestion(new_data) {
    this.additional_question = { ...this.additional_question, ...new_data };
  }
}

const bioDataContainer = decorate(BioDataContainer, {
  personal_data: observable,
  listFormalEducation: observable,
  listWorks: observable,
  listInformalEducation: observable,
  listOrganization: observable,
  informal_education: observable,
  organization: observable,
  formal_education: observable,
  work_experience: observable,
  setPersonalData: action,
  setFormalEducation: action,
  setWorkingExperience: action,
  showNext: action,
  showBack: action,
  stepper: observable,
  biodata_workExp: observable,
  biodata_additionalQuestion: observable,
  additional_question: observable,
  setAdditionalQuestion: action,
});

export default new bioDataContainer();
