import React from "react";
import { withTranslation } from "react-i18next";
import { Typography, Checkbox, Grid, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import {
  CheckBoxOutlineBlank,
  CheckBox,
  ArrowForward,
} from "@material-ui/icons";
import "../auth.css";
import {
  RequestPost,
  setItem,
  UserSession,
  RequestGet,
  getItem,
  clearAll,
  t,
  AUTH_API,
} from "app/utils";
import Tnc from "app/components/general/Tnc";
import {
  IcnLinkedin,
  IcnFacebook,
  IcnGoogle,
  ImgLoginApplicant,
  IconEyeOpen,
  IconEyeClose,
  IcnInfoFull,
} from "assets/img";
import LinkedIn from "linkedin-login-for-react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import GoogleLogin from "react-google-login";
import { DialogueInfo, Header, HeaderNologin, Footer } from "app/components";
import MyHelmet from "app/components/header/MyHelmet";
import { HtmlTooltip } from "app/components";
import { isMobile } from "react-device-detect";
class ApplicantRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tnc: false,
      accept_tnc: false,
      showPassword: false,
      showConfirm: false,
      error: "",
      err_email: {},
      err_name: {},
      err_password: {},
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      middleName: "",
      lastName: "",
      inactiveOpen: false,
      missmatch: false,
    };
    this.submitForm = this.submitForm.bind(this);
    this.callbackLinkedIn = this.callbackLinkedIn.bind(this);
  }
  componentDidMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value !== this.state.password) {
        this.setState({ missmatch: true });
        return false;
      }
      this.setState({ missmatch: false });

      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isPasswordMatch");
  }
  getStepPosition() {
    RequestGet("examattempts", {
      userId: getItem("user_id"),
    }).then(r => {
      if (r.data.examCompleted) {
        setItem("EXAM_COMPLETED", true);
        window.location.href = "/user/home";
      } else {
        setItem("EXAM_COMPLETED", false);
        if (r.data.examCategoryid === "df9b82fa-50c2-46cd-8639-2ffa73125200") {
          this.getBiodata();
        } else {
          window.location.href = "/basic-test";
        }
      }
    });
  }
  getBiodata() {
    RequestGet("biodata", { userid: getItem("user_id") }).then(res => {
      var data = res.data;
      if (data.personalInfo) {
        setItem("gender", data.personalInfo.gender);
      }
      if (res.data.listFormalEducation.length > 0) {
        window.location.href = "/initialize/pretest";
      } else {
        window.location.href = "/welcome";
      }
    });
  }
  socialSuccess(data, clientId, grantType) {
    var d = new URLSearchParams();
    var id_token =
      clientId === "google"
        ? data.tokenId
        : clientId === "facebook"
        ? data.accessToken
        : data;
    d.append("client_id", clientId);
    d.append("client_secret", process.env.REACT_APP_SECRET);
    d.append("grant_type", grantType);
    d.append("id_token", id_token);
    if (clientId === "linkedin") {
      d.append("redirect_url", window.location.href);
    }
    if (localStorage.getItem("session_token_")) {
      d.append("session_token", localStorage.getItem("session_token_"));
    }
    RequestPost(AUTH_API + "connect/token", d)
      .then(res => {
        UserSession.setData(res.data);
        localStorage.setItem("session_token_", res.data.session_token);
        RequestGet(AUTH_API + "connect/userinfo")
          .then(res => {
            if (res.data.role === "Applicant") {
              setItem("HG_CLIENT", clientId);
              setItem("GR", res.data.role);
              setItem("user_info", JSON.stringify(res.data));
              setItem("user_id", res.data.sub);
              setItem("name", res.data.fullname);
              this.getStepPosition();
            } else {
              this.setState({ open: true });
              clearAll();
            }
          })
          .catch(e => {
            if (e.data.error_description) {
              const error = e.data.error_description;
              if (error.indexOf("Already login in another device") > -1) {
                this.setState({ showForceLogout: true });
              } else if (error.indexOf("Account is Inactive") > -1) {
                this.setState({ inactiveOpen: true });
              } else {
                this.setState({
                  error: error,
                });
              }
            } else {
              this.setState({
                error: t("error.exception.description"),
              });
            }
          });
      })
      .catch(e => {
        console.log(clientId, "Register:", e);
      });
  }
  socialFailure(data, clientId) {
    console.log("Register failed", clientId, data);
  }
  callbackLinkedIn(error, code) {
    if (!error) {
      this.socialSuccess(code, "linkedin", "LinkedInAuth");
    }
  }
  submitForm = ev => {
    const { t } = this.props;
    this.setState({ error: "" });
    if (!this.state.accept_tnc) {
      this.setState({ error: t("auth:error_tnc") });
    } else {
      const data = new URLSearchParams();
      data.append("email", this.state.email);
      data.append("firstName", this.state.firstName);
      data.append("midName", this.state.middleName);
      data.append("lastName", this.state.lastName);
      data.append("password", this.state.password);
      data.append("confirmPassword", this.state.confirmPassword);
      data.append("role", "Applicant");

      function isValidationEmail(email) {
        const domain = email.split('@')[1];
        return domain.endsWith('atmajaya.ac.id');
      }
      
      RequestPost(AUTH_API + "connect/register", data)
        .then(res => {
          setItem("email-register", this.state.email);

          if (isValidationEmail(this.state.email)) {
            this.props.history.push("/user/register/verify-email", this.state.email);
          }else{
            this.props.history.push("/user/register/verify", this.state.email);
          }
        })
        .catch(e => {
          console.log("UserRegister (applicant)", e);
          if (e.data.messages[0]) {
            const error = e.data.messages[0];
            this.setState({
              error: error,
            });
          } else {
            this.setState({
              error: t("error.exception.description"),
            });
          }
        });
    }
    ev.preventDefault();
  };
  render() {
    const { t } = this.props;
    const { showPassword, showConfirm, missmatch, inactiveOpen } = this.state;
    const title = t("auth:setPassword.rulesTitle");
    const items = t("auth:setPassword.rules.items", { returnObjects: true });
    if (isMobile) {
      return (
        <div>
          <MyHelmet
            title={t("link.login")}
            description="Hiregrade - Login Candidate"
          />
          <HeaderNologin />
          <DialogueInfo
            width={250}
            onClose={() => this.setState({ inactiveOpen: false })}
            title={t("auth:inactive.applicant.title")}
            content={
              <div
                className="BodyPopup"
                style={{
                  color: palette.primary,
                  width: 200,
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("auth:inactive.applicant.content"),
                }}></div>
            }
            open={inactiveOpen}
          />
          <Grid container style={{ minHeight: "100vh" }}>
            <Grid item lg={6} md={6} sm={6}>
              <div
                className={"auth-mobile"}
                style={{ marginTop: 20, padding: "80px 30px" }}>
                <div style={{ marginBottom: 20, display: "flex" }}>
                  <Button
                    onClick={() => {
                      window.location.href = "/user/login/applicant";
                    }}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.login")}
                  </Button>
                  <Button
                    disabled={true}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.signin")}
                  </Button>
                </div>
                <div className="auth-mobile">
                  <Typography
                    style={{
                      fontWeight: "bolder",
                      color: palette.primary,
                      marginBottom: 35,
                      fontSize: 24,
                    }}>
                    {t("link.signin") + " " + t("auth:candidate")}
                  </Typography>
                  <ValidatorForm onSubmit={this.submitForm}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "92%",
                        }}>
                        <TextValidator
                          label={t("auth:name")}
                          onChange={e => {
                            this.setState({ firstName: e.target.value });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="Field"
                          placeholder={t("auth:placeholder.firstName")}
                          name="firstname"
                          value={this.state.firstName}
                          style={{
                            width: "100%",
                            marginRight: 15,
                          }}
                          validators={["required"]}
                          errorMessages={[
                            t("auth:validation.firstNameRequired"),
                          ]}
                        />
                        <TextValidator
                          onChange={e => {
                            this.setState({ middleName: e.target.value });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="Field"
                          placeholder={t("auth:placeholder.middleName")}
                          name="middlename"
                          value={this.state.middleName}
                          style={{
                            width: "100%",
                            marginRight: 15,
                            left: 10,
                          }}
                        />
                        <TextValidator
                          onChange={e => {
                            this.setState({ lastName: e.target.value });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="Field"
                          placeholder={t("auth:placeholder.lastName")}
                          name="lastname"
                          value={this.state.lastName}
                          style={{ width: "100%", left: 20 }}
                          autoComplete="lastname"
                        />
                      </div>
                    </div>
                    <TextValidator
                      label={t("auth:email")}
                      onChange={e => {
                        this.setState({ email: e.target.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="Field"
                      placeholder={
                        getItem("lang") === "en"
                          ? t("auth:placeholder.email")
                          : t("auth:placeholder.email_ap")
                      }
                      name="email"
                      value={this.state.email}
                      style={{ width: "100%", marginTop: 20 }}
                      autoComplete="username"
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        t("auth:validation.emailRequired"),
                        t("auth:validation.email"),
                      ]}
                    />
                    <div style={{ position: "relative" }}>
                      <TextValidator
                        label={t("auth:password")}
                        type={showPassword ? "text" : "password"}
                        onChange={e => {
                          this.setState({ password: e.target.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="Field"
                        placeholder={
                          getItem("lang") === "en"
                            ? t("auth:placeholder.password")
                            : t("auth:placeholder.password_ap")
                        }
                        name="password"
                        value={this.state.password}
                        style={{ width: "100%", marginTop: 20 }}
                        autoComplete="password"
                        validators={["required"]}
                        errorMessages={[t("auth:validation.passwordRequired")]}
                      />
                      <HtmlTooltip
                        title={
                          <div style={{ color: "white" }}>
                            {title}
                            <br />
                            {items.map((v, k) => {
                              return (
                                <span key={k}>
                                  - {v} <br />
                                </span>
                              );
                            })}
                          </div>
                        }
                        arrow>
                        <img
                          src={IcnInfoFull}
                          alt="show hide"
                          style={{
                            padding: 10,
                            cursor: "pointer",
                            width: 18,
                            position: "absolute",
                            top: 8,
                            left: 73,
                          }}
                        />
                      </HtmlTooltip>
                      <img
                        src={showPassword ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showPassword: !showPassword });
                        }}
                        style={{
                          padding: 5,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: 22,
                          right: 0,
                        }}
                      />
                    </div>
                    <div style={{ position: "relative" }}>
                      <TextValidator
                        className="Field passwordhint"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={showConfirm ? "text" : "password"}
                        label={t("auth:confirmpassword")}
                        placeholder={
                          getItem("lang") === "en"
                            ? t("auth:placeholder.confirmpassword")
                            : t("auth:placeholder.confirmpassword_ap")
                        }
                        validators={["required", "isPasswordMatch"]}
                        onChange={e => {
                          this.setState({ confirmPassword: e.target.value });
                        }}
                        value={this.state.confirmPassword}
                        errorMessages={[
                          t("auth:validation.passwordRequired"),
                          t("auth:validation.matchpassword"),
                        ]}
                        style={{ width: "100%", marginTop: 20 }}
                      />
                      <img
                        src={showConfirm ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showConfirm: !showConfirm });
                        }}
                        style={{
                          padding: 5,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: missmatch ? 44 : 22,
                          right: 0,
                        }}
                      />
                    </div>
                    <Typography
                      style={{ width: "100%", marginBottom: 20, color: "red" }}>
                      {this.state.error}
                    </Typography>
                    <Grid container justify="flex-start" alignItems="center">
                      <Checkbox
                        color="primary"
                        checked={this.state.accept_tnc}
                        icon={
                          <CheckBoxOutlineBlank
                            fontSize="large"
                            color="primary"
                          />
                        }
                        checkedIcon={<CheckBox fontSize="large" />}
                        name="checkedI"
                        onClick={() => {
                          this.setState({ accept_tnc: !this.state.accept_tnc });
                        }}
                      />
                      <Typography
                        onClick={() => this.setState({ tnc: true })}
                        style={{
                          marginLeft: 10,
                          fontWeight: "600",
                          color: palette.primary,
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontSize: 16,
                        }}>
                        {t("auth:tnc")}
                      </Typography>
                      <Tnc
                        t={t}
                        visible={this.state.tnc}
                        handleClose={tnc =>
                          this.setState({ tnc: false, accept_tnc: tnc })
                        }
                      />
                    </Grid>
                    <Button
                      type="submit"
                      style={{
                        paddingLeft: 0,
                        marginTop: 20,
                        marginBottom: 24,
                      }}
                      // onClick={this.submitForm}
                    >
                      <span
                        style={{
                          fontSize: 24,
                          color: palette.primary,
                          textTransform: "capitalize",
                          marginRight: 5,
                          textDecoration: "underline",
                          fontWeight: "900",
                        }}>
                        {t("auth:register")}
                      </span>
                      <ArrowForward style={{ color: palette.primary }} />
                    </Button>
                  </ValidatorForm>
                  <div style={{ alignItems: "center", textAlign: "center" }}>
                    <span
                      style={{
                        fontSize: 14,
                        color: palette.primary,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("auth:register-with")} :
                    </span>
                    <div
                      style={{
                        alignItems: "center",
                        textAlign: "center",
                        paddingTop: 10,
                        paddingRight: 15,
                      }}>
                      <LinkedIn
                        className="ButtonLinkedin"
                        clientId={process.env.REACT_APP_LINKEDIN_ID}
                        callback={this.callbackLinkedIn}
                        scope={[
                          "r_emailaddress",
                          "r_liteprofile",
                          "w_member_social",
                        ]}
                        text={
                          <img
                            src={IcnLinkedin}
                            alt="LinkedIn"
                            height={30}
                            style={{ marginLeft: 10 }}
                          />
                        }
                      />
                      <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_ID}
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={d =>
                          this.socialSuccess(d, "facebook", "FacebookAuth")
                        }
                        render={renderProps => (
                          <img
                            onClick={renderProps.onClick}
                            src={IcnFacebook}
                            alt="Facebook"
                            height={26}
                            style={{ marginLeft: 10, cursor: "pointer" }}
                          />
                        )}
                      />
                      <GoogleLogin
                        isSignedIn={false}
                        className="ButtonGoogle"
                        buttonText={""}
                        icon={false}
                        clientId={process.env.REACT_APP_GOOGLE_KEY}
                        onSuccess={s =>
                          this.socialSuccess(s, "google", "GoogleAuth")
                        }
                        onFailure={x => this.socialFailure(x, "google")}>
                        <img src={IcnGoogle} alt="Google" width={26} />
                      </GoogleLogin>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid style={{ width: "100%" }}>
              <div style={{ position: "relative", bottom: 0 }}>
                <Footer />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <MyHelmet
            title={t("link.login")}
            description="Hiregrade - Login Candidate"
          />
          <Header shadow={true} linkgroup={true} />
          <DialogueInfo
            width={590}
            onClose={() => this.setState({ inactiveOpen: false })}
            title={t("auth:inactive.applicant.title")}
            content={
              <div
                className="BodyPopup"
                style={{
                  color: palette.primary,
                  width: 480,
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("auth:inactive.applicant.content"),
                }}></div>
            }
            open={inactiveOpen}
          />
          <Grid container style={{ minHeight: "100vh" }}>
            <Grid item lg={6} md={6} sm={6}>
              <div
                className={"auth"}
                style={{ marginTop: 40, padding: "100px 120px" }}>
                <div style={{ marginBottom: 40 }}>
                  <Button
                    onClick={() => {
                      window.location.href = "/user/login/applicant";
                    }}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.login")}
                  </Button>
                  <Button
                    disabled={true}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: "white",
                      backgroundColor: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.signin")}
                  </Button>
                </div>
                <div className="auth">
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: "bolder",
                      color: palette.primary,
                      marginBottom: 25,
                    }}>
                    {t("link.signin") + " " + t("auth:candidate")}
                  </Typography>
                  <ValidatorForm onSubmit={this.submitForm}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <TextValidator
                        label={t("auth:name")}
                        onChange={e => {
                          this.setState({ firstName: e.target.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="Field"
                        placeholder={t("auth:placeholder.firstName")}
                        name="firstname"
                        value={this.state.firstName}
                        style={{
                          width: "100%",
                          marginTop: 20,
                          marginRight: 15,
                        }}
                        validators={["required"]}
                        errorMessages={[t("auth:validation.firstNameRequired")]}
                      />
                      <TextValidator
                        onChange={e => {
                          this.setState({ middleName: e.target.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="Field"
                        placeholder={t("auth:placeholder.middleName")}
                        name="middlename"
                        value={this.state.middleName}
                        style={{
                          width: "100%",
                          marginTop: 20,
                          marginRight: 15,
                        }}
                      />
                      <TextValidator
                        onChange={e => {
                          this.setState({ lastName: e.target.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="Field"
                        placeholder={t("auth:placeholder.lastName")}
                        name="lastname"
                        value={this.state.lastName}
                        style={{ width: "100%", marginTop: 20 }}
                        autoComplete="lastname"
                      />
                    </div>
                    <TextValidator
                      label={t("auth:email")}
                      onChange={e => {
                        this.setState({ email: e.target.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="Field"
                      placeholder={
                        getItem("lang") === "en"
                          ? t("auth:placeholder.email")
                          : t("auth:placeholder.email_ap")
                      }
                      name="email"
                      value={this.state.email}
                      style={{ width: "100%", marginTop: 20 }}
                      autoComplete="username"
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        t("auth:validation.emailRequired"),
                        t("auth:validation.email"),
                      ]}
                    />
                    <div style={{ position: "relative" }}>
                      <TextValidator
                        label={t("auth:password")}
                        type={showPassword ? "text" : "password"}
                        onChange={e => {
                          this.setState({ password: e.target.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="Field"
                        placeholder={
                          getItem("lang") === "en"
                            ? t("auth:placeholder.password")
                            : t("auth:placeholder.password_ap")
                        }
                        name="password"
                        value={this.state.password}
                        style={{ width: "100%", marginTop: 20 }}
                        autoComplete="password"
                        validators={["required"]}
                        errorMessages={[t("auth:validation.passwordRequired")]}
                      />
                      <HtmlTooltip
                        title={
                          <div style={{ color: "white" }}>
                            {title}
                            <br />
                            {items.map((v, k) => {
                              return (
                                <span key={k}>
                                  - {v} <br />
                                </span>
                              );
                            })}
                          </div>
                        }
                        arrow>
                        <img
                          src={IcnInfoFull}
                          alt="show hide"
                          style={{
                            padding: 10,
                            cursor: "pointer",
                            width: 18,
                            position: "absolute",
                            top: 8,
                            left: 73,
                          }}
                        />
                      </HtmlTooltip>
                      <img
                        src={showPassword ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showPassword: !showPassword });
                        }}
                        style={{
                          padding: 10,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: 22,
                          right: 0,
                        }}
                      />
                    </div>
                    <div style={{ position: "relative" }}>
                      <TextValidator
                        className="Field passwordhint"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={showConfirm ? "text" : "password"}
                        label={t("auth:confirmpassword")}
                        placeholder={
                          getItem("lang") === "en"
                            ? t("auth:placeholder.confirmpassword")
                            : t("auth:placeholder.confirmpassword_ap")
                        }
                        validators={["required", "isPasswordMatch"]}
                        onChange={e => {
                          this.setState({ confirmPassword: e.target.value });
                        }}
                        value={this.state.confirmPassword}
                        errorMessages={[
                          t("auth:validation.passwordRequired"),
                          t("auth:validation.matchpassword"),
                        ]}
                        style={{ width: "100%", marginTop: 20 }}
                      />
                      <img
                        src={showConfirm ? IconEyeOpen : IconEyeClose}
                        alt="show hide"
                        onClick={() => {
                          this.setState({ showConfirm: !showConfirm });
                        }}
                        style={{
                          padding: 10,
                          cursor: "pointer",
                          width: 24.04,
                          position: "absolute",
                          bottom: missmatch ? 44 : 22,
                          right: 0,
                        }}
                      />
                    </div>
                    <Typography
                      style={{ width: "100%", marginBottom: 20, color: "red" }}>
                      {this.state.error}
                    </Typography>
                    <Grid container justify="flex-start" alignItems="center">
                      <Checkbox
                        color="primary"
                        checked={this.state.accept_tnc}
                        icon={
                          <CheckBoxOutlineBlank
                            fontSize="large"
                            color="primary"
                          />
                        }
                        checkedIcon={<CheckBox fontSize="large" />}
                        name="checkedI"
                        onClick={() => {
                          this.setState({ accept_tnc: !this.state.accept_tnc });
                        }}
                      />
                      <Typography
                        onClick={() => this.setState({ tnc: true })}
                        style={{
                          marginLeft: 10,
                          fontWeight: "500",
                          color: palette.primary,
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}>
                        {t("auth:tnc")}
                      </Typography>
                      <Tnc
                        t={t}
                        visible={this.state.tnc}
                        handleClose={tnc =>
                          this.setState({ tnc: false, accept_tnc: tnc })
                        }
                      />
                    </Grid>
                    <Button
                      type="submit"
                      style={{
                        paddingLeft: 0,
                        marginTop: 20,
                        marginBottom: 24,
                      }}
                      // onClick={this.submitForm}
                    >
                      <span
                        style={{
                          fontSize: 24,
                          fontWeight: "bolder",
                          color: palette.primary,
                          textTransform: "capitalize",
                          marginRight: 5,
                          textDecoration: "underline",
                        }}>
                        {t("auth:register")}
                      </span>
                      <ArrowForward style={{ color: palette.primary }} />
                    </Button>
                  </ValidatorForm>
                  <Grid container alignItems="center">
                    <span
                      style={{
                        fontSize: 14,
                        color: palette.primary,
                        textTransform: "uppercase",
                      }}>
                      {t("auth:register-with")} :
                    </span>
                    <LinkedIn
                      className="ButtonLinkedin"
                      clientId={process.env.REACT_APP_LINKEDIN_ID}
                      callback={this.callbackLinkedIn}
                      scope={[
                        "r_emailaddress",
                        "r_liteprofile",
                        "w_member_social",
                      ]}
                      text={
                        <img
                          src={IcnLinkedin}
                          alt="LinkedIn"
                          height={30}
                          style={{ marginLeft: 20 }}
                        />
                      }
                    />
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_ID}
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={d =>
                        this.socialSuccess(d, "facebook", "FacebookAuth")
                      }
                      render={renderProps => (
                        <img
                          onClick={renderProps.onClick}
                          src={IcnFacebook}
                          alt="Facebook"
                          height={26}
                          style={{ marginLeft: 20, cursor: "pointer" }}
                        />
                      )}
                    />
                    <GoogleLogin
                      isSignedIn={false}
                      className="ButtonGoogle"
                      buttonText={""}
                      icon={false}
                      clientId={process.env.REACT_APP_GOOGLE_KEY}
                      onSuccess={s =>
                        this.socialSuccess(s, "google", "GoogleAuth")
                      }
                      onFailure={x => this.socialFailure(x, "google")}>
                      <img src={IcnGoogle} alt="Google" width={26} />
                    </GoogleLogin>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              style={{
                backgroundColor: palette.bg,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                src={ImgLoginApplicant}
                alt="Candidate"
                width={572}
                height={382.78}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation()(ApplicantRegister);
