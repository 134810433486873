import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import RecruiterAppBarMobile from "app/components/header/RecruiterAppBarMobile";
import { palette, PRIMARY_BUTTON, REC_STYLE } from "assets/css/main";
import { Button, InputBase, Menu, MenuItem, Paper } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { BadgeCountInfo } from "app/components/header/BadgeCountInfo";
import { Link } from "react-router-dom";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import {
  AppHistory,
  getItem,
  RequestGet,
  RequestPut,
  SetLocalItem,
} from "app/utils";
import alertData from "app/components/alert/alert-data";
import data from "./data";
import moment from "moment";
import { HGPagination } from "app/components";
import { isMobile } from "react-device-detect";

class RecruiterDashboard extends React.Component {
  anchorEl = null;
  anchorMore = null;
  anchorMoreArc = null;

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: 0,
      button: "",
      showhide: {},
      openMore: false,
      openMoreArc: false,
      vacancy: [],
      vacancyArchive: [],
      vacancydetail: null,
      vacancyArchivedetail: null,
      isLoading: true,
      notifBedge: 0,
      notifLoaded: false,
      totalActive: 0,
      totalArchive: 0,
      page: 1,
      limit: 10,
    };
    this.translate = this.props.t;
  }

  componentDidMount() {
    const { t } = this.props;
    // copy from existing needed
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
    SetLocalItem("VacancySummary", null);
    this.setState({
      button: (
        <Button
          onClick={() => {
            data.setEmptyVacancy();
            AppHistory.push("/recruiter/vacancy-detail");
          }}
          style={{
            ...PRIMARY_BUTTON,
            marginBottom: 8,
            height: 44,
            marginLeft: 25,
            padding: "0px 13px",
            width: 196,
          }}>
          {t("rec-dashboard:button.createVacancy")}
        </Button>
      ),
    });
    this.getVacancy();
  }

  getVacancy(search = null) {
    this.setState({ isLoading: true });
    const { page, limit } = this.state;
    RequestGet("vacancy/summary", { limit: limit, page: page })
      .then(res => {
        var datafilterAct = res.data.vacancyActives;
        var datafilterArc = res.data.vacancyArchives;

        if (search !== null) {
          datafilterAct = datafilterAct.filter(item => {
            return Object.keys(item).some(
              key =>
                item[key]
                  .toString()
                  .toLowerCase()
                  .search(search.toLowerCase()) !== -1
            );
          });
          datafilterArc = datafilterArc.filter(item => {
            return Object.keys(item).some(
              key =>
                item[key]
                  .toString()
                  .toLowerCase()
                  .search(search.toLowerCase()) !== -1
            );
          });
        }

        this.setState({
          isLoading: false,
          vacancy: datafilterAct,
          vacancyArchive: datafilterArc,
          totalActive: res.data.countActives,
          totalArchive: res.data.countArchives,
          vacancydetail: res.data.vacancyActives.map(v => {
            return null;
          }),
          vacancyArchivedetail: res.data.vacancyArchives.map(v => {
            return null;
          }),
        });

        // copy from existing needed
        SetLocalItem("VacancySummary", res.data);
      })
      .catch(er => {
        this.setState({ isLoading: false });
      });
  }

  getVacancydetail(idvacancy, keyIndex, type) {
    RequestGet("vacancy/detail/" + idvacancy, { loading: true })
      .then(res => {
        var tmp = null;
        if (type === "Active") {
          tmp = this.state.vacancydetail;
          tmp[keyIndex] = res.data;
          this.setState({ vacancydetail: tmp });
        } else {
          tmp = this.state.vacancyArchivedetail;
          tmp[keyIndex] = res.data;
          this.setState({ vacancyArchivedetail: tmp });
        }
      })
      .catch(er => {
        console.log("getVacancydetail()", er);
      });
  }

  handleChange(newValue) {
    this.setState({ value: newValue, page: 0, limit: 10 });
  }
  clickMore = (e, vacancyId) => {
    this.anchorMore = e.currentTarget;
    this.setState({ openMore: true, id: vacancyId });
  };
  handleClose = () => {
    this.setState({ openMore: false, id: null });
    this.anchorMore = null;
  };

  clickMoreArc = (e, vacancyId) => {
    this.anchorMoreArc = e.currentTarget;
    this.setState({ openMoreArc: true, id: vacancyId });
  };
  handleCloseArc = () => {
    this.setState({ openMoreArc: false, id: null });
    this.anchorMoreArc = null;
  };

  movetoarchive(vacancyId) {
    RequestPut("vacancy/detail/" + vacancyId + "?status=ARC", {
      loading: true,
    })
      .then(r => {
        alertData.show("Success");
        this.setState({ openMore: false });
        this.getVacancy();
        AppHistory.push("/recruiter/home");
      })
      .catch(e => {
        console.log(e);
      });
  }

  moveToEdit(vacancyId) {
    data.setEmptyVacancy();
    data.listVacancy.vacancy.id = vacancyId;
    SetLocalItem("vacancyGet", data.listVacancy);
    AppHistory.push("/recruiter/edit-vacancy");
  }

  handleChangeInputSearch = event => {
    const search = event.target.value;

    this.getVacancy(search.toLowerCase());
  };

  render() {
    var showhide = {};
    const {
      value,
      vacancy,
      vacancyArchive,
      vacancydetail,
      vacancyArchivedetail,
      isLoading,
      totalActive,
      totalArchive,
      limit,
    } = this.state;
    const { t } = this.props;
    if (isMobile) {
      return (
        <div
          style={{
            position: "relative",
          }}>
          <MyHelmet title="Dashboard" />
          <RecruiterAppBarMobile
            shadow={true}
            menu="dashboard"
            history={this.props.history}
          />
          <Menu
            className="link-menu"
            id="more-menu"
            style={{
              marginTop: 35,
              minHeight: 500,
            }}
            anchorEl={this.anchorMore}
            open={this.state.openMore}
            onClose={this.handleClose}>
            <MenuItem
              onClick={() => {
                this.moveToEdit(this.state.id);
              }}
              style={{
                color: palette.primary,
                margin: 0,
                padding: 0,
                fontWeight: "lighter",
              }}>
              <Link
                to=""
                style={{
                  color: palette.primary,
                  padding: "5px 50px 5px 15px",
                }}>
                {t("rec-dashboard:button.editVacancy")}
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.movetoarchive(this.state.id);
              }}
              style={{
                color: palette.primary,
                margin: 0,
                fontWeight: "lighter",
                padding: "5px 50px 5px 15px",
              }}>
              {t("rec-dashboard:button.movetoArchive")}
            </MenuItem>
          </Menu>

          <Menu
            className="link-menu"
            id="more-menu-arc"
            style={{
              marginTop: 35,
              minHeight: 500,
            }}
            anchorEl={this.anchorMoreArc}
            open={this.state.openMoreArc}
            onClose={this.handleCloseArc}>
            <MenuItem
              onClick={() => {
                this.moveToEdit(this.state.id);
              }}
              style={{
                color: palette.primary,
                margin: 0,
                padding: 0,
                fontWeight: "lighter",
              }}>
              <Link
                to=""
                style={{
                  color: palette.primary,
                  padding: "5px 50px 5px 15px",
                }}>
                {t("rec-dashboard:button.editVacancy")}
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                //this.movetoarchive(this.state.id);
              }}
              style={{
                color: palette.primary,
                margin: 0,
                fontWeight: "lighter",
                padding: "5px 50px 5px 15px",
              }}>
              {t("rec-dashboard:button.movetoArchive")}
            </MenuItem>
          </Menu>
          <div style={REC_STYLE.SUB_HEADER}>
            <div
              style={{
                width: "95%",
                position: "relative",
                display: "flex",
              }}>
              <Button
                onClick={() => {
                  this.handleChange(0);
                }}
                style={{
                  borderRadius: 0,
                  paddingBottom: 18,
                  color: palette.primary,
                  opacity: value === 0 ? 1 : 0.5,
                  fontWeight: "bold",
                  borderBottom:
                    value === 0
                      ? "2px solid " + palette.primary
                      : "2px solid transparent",
                }}>
                {t("rec-dashboard:button.activeVacancies")}
                <BadgeCountInfo circle={true} count={totalActive} />
              </Button>
              <Button
                onClick={() => {
                  this.handleChange(1);
                }}
                style={{
                  borderRadius: 0,
                  paddingBottom: 18,
                  color: palette.primary,
                  fontWeight: "bold",
                  opacity: value === 1 ? 1 : 0.5,
                  borderBottom:
                    value === 1
                      ? "2px solid " + palette.primary
                      : "2px solid transparent",
                }}>
                {t("rec-dashboard:button.archiveVacancies")}
                <BadgeCountInfo circle={true} count={totalArchive} />
              </Button>
            </div>
            <div
              style={{
                width: "44%",
                display: "flex",
                alignItems: "center",
              }}>
              <div
                style={{
                  marginBottom: 8,
                  padding: 5,
                  paddingLeft: 10,
                  borderRadius: 4,
                  display: "flex",
                  border: "0.5px solid rgba(0,0,0,0.3)",
                  alignItems: "center",
                  width: 305,
                }}>
                <Search
                  style={{
                    color: "rgba(0,0,0,0.3)",
                    paddingBottom: 2,
                    paddingRight: 15,
                  }}
                />
                <InputBase
                  placeholder={t("rec-dashboard:placeholder.search")}
                  style={{
                    width: 250,
                  }}
                  onChange={this.handleChangeInputSearch}
                />
              </div>
              {this.state.button}
            </div>
          </div>
          <div style={REC_STYLE.BODY_STYLE}>
            {/* sub header */}

            {value === 0 && (
              <div
                style={{
                  padding: "100px",
                  marginTop: 20,
                }}>
                <div
                  className="list-data"
                  style={{
                    width: "100%",
                  }}>
                  {totalActive === 0 && !isLoading ? (
                    <div>
                      <Paper
                        style={{
                          display: !this.state.data ? "" : "none",
                          boxShadow: "none",
                          border: "1px solid #E5E5E5",
                          padding: "50px 20px",
                          color: palette.primary,
                        }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                            flexDirection: "column",
                          }}>
                          <div
                            style={{
                              width: 500,
                              textAlign: "center",
                              marginBottom: 20,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: t("rec-dashboard:text.empty"),
                            }}></div>
                          {this.state.button}
                        </div>
                      </Paper>
                    </div>
                  ) : null}
                  {vacancy.length === 0 && !isLoading ? (
                    <div>
                      <Paper
                        style={{
                          display: !this.state.data ? "" : "none",
                          boxShadow: "none",
                          border: "1px solid #E5E5E5",
                          padding: "50px 20px",
                          color: palette.primary,
                        }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                            flexDirection: "column",
                          }}>
                          <div
                            style={{
                              width: 500,
                              textAlign: "center",
                              marginBottom: 20,
                            }}>
                            {t("error.data.empty")}
                          </div>
                        </div>
                      </Paper>
                    </div>
                  ) : null}
                  {vacancy.map((v, k) => {
                    var str = v.updatedTime;
                    var date = moment(str).toISOString().split("T")[0];
                    var updatedate = moment(date).format("DD MMM yyyy");
                    var time = moment(str)
                      .add(7, "hours")
                      .toISOString()
                      .split("T")[1];
                    var strprivate = "";
                    if (v.isPrivate) {
                      strprivate = "Private";
                    }
                    return (
                      <table
                        key={"list-vacancies-" + k}
                        className="item"
                        style={{
                          padding: "3px 30px 3px",
                          width: "100%",
                          backgroundColor: "white",
                          boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                          borderRadius: 4,
                          marginBottom: 15,
                        }}>
                        <tbody>
                          <tr>
                            <td
                              colSpan={
                                !this.state.showhide[
                                  "vacanciedetail" + v.vacancyId
                                ]
                                  ? 0
                                  : 3
                              }
                              width={
                                !this.state.showhide[
                                  "vacanciedetail" + v.vacancyId
                                ]
                                  ? "70%"
                                  : ""
                              }
                              valign="middle">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    color: "rgb(46, 105, 178)",
                                    fontSize: 12,
                                  }}>
                                  {v.vacancyName}
                                </div>
                                {v.isPrivate && (
                                  <div
                                    style={{
                                      fontSize: 12,
                                      margin: "0px 10px",
                                    }}>
                                    <span
                                      style={{
                                        padding: "5px 19px 11px 18px",
                                        backgroundColor:
                                          "rgba(82, 200, 244, 0.2)",
                                        color: "#3B69AD",
                                        borderRadius: "15% / 50%",
                                        opacity: 0.6,
                                        fontWeight: "lighter",
                                        height: 10,
                                        border: "1px solid #52C8F4",
                                        width: 50,
                                        textAlign: "center",
                                      }}>
                                      {strprivate}
                                    </span>
                                  </div>
                                )}
                                <div
                                  style={{
                                    fontSize: 12,
                                    margin: "0px 10px",
                                  }}>
                                  <span
                                    style={{
                                      padding: "5px 19px 11px 18px",
                                      backgroundColor:
                                        "rgba(46, 105, 178, 0.48)",
                                      color: "#3B69AD",
                                      borderRadius: "15% / 50%",
                                      opacity: 0.6,
                                      fontWeight: "lighter",
                                      height: 10,
                                      border: "1px solid #2E69B2",
                                    }}>
                                    {v.outstandingVacancy} vacancies
                                  </span>
                                </div>
                                <div
                                  style={{
                                    fontSize: 12,
                                    fontStyle: "italic",
                                    color: "#818385",
                                    fontWeight: "lighter",
                                  }}>
                                  {t("rec-dashboard:text.lastUpdate")} :{" "}
                                  {updatedate}, {time.substr(0, 8)} WIB
                                </div>
                              </div>
                            </td>
                            <td width="30%" align="right" valign="middle">
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                }}>
                                <Link
                                  to={
                                    "/recruiter/viewcandidatelist/" +
                                    v.vacancyId
                                  }
                                  style={{ marginRight: 10 }}>
                                  <Button
                                    onClick={() =>
                                      SetLocalItem("VacancyId", v.vacancyId)
                                    }
                                    size="small"
                                    variant="contained"
                                    style={{
                                      ...PRIMARY_BUTTON,
                                      fontWeight: "bold",
                                      height: 27,
                                    }}>
                                    {t("rec-dashboard:button.viewCandidate")}
                                  </Button>
                                </Link>
                                <Button
                                  onClick={e => this.clickMore(e, v.vacancyId)}
                                  size="small"
                                  variant="contained"
                                  style={{
                                    ...PRIMARY_BUTTON,
                                    fontWeight: "bold",
                                    backgroundColor: "white",
                                    color: palette.primary,
                                    border: "1px solid ",
                                    height: 27,
                                  }}>
                                  {t("rec-dashboard:button.more")}
                                </Button>
                                {v.hasDetail === true ? (
                                  <div style={{}}>
                                    <Button
                                      style={{
                                        padding: "0px 0px",
                                        color: palette.primary,
                                        textTransform: "capitalize",
                                        texthecoration: "underline",
                                      }}
                                      onClick={() => {
                                        showhide = this.state.showhide;
                                        showhide[
                                          "vacanciedetail" + v.vacancyId
                                        ] =
                                          !this.state.showhide[
                                            "vacanciedetail" + v.vacancyId
                                          ];
                                        this.setState(prevState => ({
                                          ...prevState,
                                          ...showhide,
                                        }));
                                        var type = "Active";
                                        this.getVacancydetail(
                                          v.vacancyId,
                                          k,
                                          type
                                        );
                                      }}>
                                      {!this.state.showhide[
                                        "vacanciedetail" + v.vacancyId
                                      ] ? (
                                        <ArrowDropDown />
                                      ) : (
                                        <ArrowDropUp />
                                      )}
                                    </Button>
                                  </div>
                                ) : (
                                  <div style={{ minWidth: 64 }}></div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        {vacancydetail[k] !== null && (
                          <tbody
                            style={{
                              display: this.state.showhide[
                                "vacanciedetail" + v.vacancyId
                              ]
                                ? ""
                                : "none",
                            }}>
                            <tr
                              style={{
                                display: this.state.showhide[
                                  "vacanciedetail" + v.vacancyId
                                ]
                                  ? this.state.showhide[
                                      "vacanciedetail" + v.vacancyId
                                    ]
                                  : "none",
                                height: 140,
                              }}>
                              {vacancydetail[k].map((vd, kd) => {
                                return (
                                  <td
                                    key={"td-detail" + kd}
                                    align="center"
                                    width="25%"
                                    style={{
                                      color: "rgb(46, 105, 178)",
                                    }}>
                                    <div
                                      style={{
                                        borderRight: "1px solid #E5E5E5",
                                      }}>
                                      <div
                                        style={{
                                          fontSize: 40,
                                        }}>
                                        {vd.count}
                                      </div>
                                      <div
                                        style={{
                                          fontWeight: "lighter",
                                          fontSize: 20,
                                        }}>
                                        {getItem("lang") === "en"
                                          ? vd.textEng
                                          : vd.textIna}
                                      </div>
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        )}
                      </table>
                    );
                  })}
                  <HGPagination
                    pageLimit={limit}
                    totalCount={totalActive}
                    onChange={p => {
                      this.setState({ page: p }, () => {
                        this.getVacancy();
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {value === 1 && (
              <div
                style={{
                  padding: "100px",
                  paddingTop: 125,
                }}>
                {totalArchive === 0 && !isLoading ? (
                  <Paper
                    style={{
                      display: !this.state.data ? "" : "none",
                      boxShadow: "none",
                      border: "1px solid #E5E5E5",
                      padding: "50px 20px",
                      color: palette.primary,
                    }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        flexDirection: "column",
                      }}>
                      <div
                        style={{
                          width: 500,
                          textAlign: "center",
                          marginBottom: 20,
                        }}>
                        {t("error.data.empty")}
                      </div>
                    </div>
                  </Paper>
                ) : null}
                {totalArchive > 0 && (
                  <>
                    <div
                      className="list-data"
                      style={{
                        width: "100%",
                      }}>
                      {vacancyArchive.map((v, k) => {
                        var str = v.updatedTime;
                        var date = moment(str).toISOString().split("T")[0];
                        var updatedate = moment(date).format("DD MMM yyyy");
                        var time = moment(str)
                          .add(7, "hours")
                          .toISOString()
                          .split("T")[1];
                        return (
                          <table
                            key={"list-vacancies-" + k}
                            className="item"
                            style={{
                              padding: "3px 30px 3px",
                              width: "100%",
                              backgroundColor: "white",
                              boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                              borderRadius: 4,
                              marginBottom: 15,
                            }}>
                            <tbody>
                              <tr
                                style={{
                                  width: "100%",
                                }}>
                                <td colSpan={3} valign="middle">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}>
                                    <div
                                      style={{
                                        color: "rgb(46, 105, 178)",
                                        fontSize: 12,
                                      }}>
                                      {v.vacancyName}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 12,
                                        margin: "0px 10px",
                                      }}>
                                      <span
                                        style={{
                                          padding: "5px 19px 11px 18px",
                                          backgroundColor:
                                            "rgba(46, 105, 178, 0.48)",
                                          color: "#3B69AD",
                                          borderRadius: "15% / 50%",
                                          opacity: 0.6,
                                          fontWeight: "lighter",
                                          height: 10,
                                        }}>
                                        {v.outstandingVacancy} vacancies
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 12,
                                        fontStyle: "italic",
                                        color: "#818385",
                                        fontWeight: "lighter",
                                      }}>
                                      {t("rec-dashboard:text.lastUpdate")} :{" "}
                                      {updatedate}, {time.substr(0, 8)} WIB
                                    </div>
                                  </div>
                                </td>
                                <td width="45%" align="right" valign="middle">
                                  <div
                                    style={{
                                      width: 120,
                                      alignItems: "center",
                                      //display: "flex",
                                      flexDirection: "row",
                                    }}>
                                    {/* <Button
                                  onClick={e => this.clickMoreArc(e, v.vacancyId)}
                                  size="small"
                                  variant="contained"
                                  style={{
                                    ...PRIMARY_BUTTON,
                                    fontWeight: "bold",
                                    backgroundColor: "white",
                                    color: palette.primary,
                                    border: "1px solid ",
                                    height: 27,
                                  }}>
                                  {t("more")}
                                </Button> */}
                                    {v.hasDetail === true && (
                                      <div style={{}}>
                                        <Button
                                          style={{
                                            padding: "0px 0px",
                                            color: palette.primary,
                                            textTransform: "capitalize",
                                            texthecoration: "underline",
                                          }}
                                          onClick={() => {
                                            showhide = this.state.showhide;
                                            showhide[
                                              "vacanciedetail" + v.vacancyId
                                            ] =
                                              !this.state.showhide[
                                                "vacanciedetail" + v.vacancyId
                                              ];
                                            this.setState(prevState => ({
                                              ...prevState,
                                              ...showhide,
                                            }));
                                            var type = "Archive";
                                            this.getVacancydetail(
                                              v.vacancyId,
                                              k,
                                              type
                                            );
                                          }}>
                                          {!this.state.showhide[
                                            "vacanciedetail" + v.vacancyId
                                          ] ? (
                                            <ArrowDropDown />
                                          ) : (
                                            <ArrowDropUp />
                                          )}
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                            {vacancyArchivedetail[k] !== null && (
                              <tbody
                                style={{
                                  width: "100%",
                                  display: this.state.showhide[
                                    "vacanciedetail" + v.vacancyId
                                  ]
                                    ? ""
                                    : "none",
                                }}>
                                <tr
                                  style={{
                                    width: "100%",
                                    display: this.state.showhide[
                                      "vacanciedetail" + v.vacancyId
                                    ]
                                      ? this.state.showhide[
                                          "vacanciedetail" + v.vacancyId
                                        ]
                                      : "none",
                                    height: 140,
                                  }}>
                                  {vacancyArchivedetail[k].map((vd, kd) => {
                                    return (
                                      <td
                                        key={"td-detail" + kd}
                                        align="center"
                                        width="25%"
                                        style={{
                                          color: "rgb(46, 105, 178)",
                                        }}>
                                        <div
                                          style={{
                                            borderRight: "1px solid #E5E5E5",
                                          }}>
                                          <div
                                            style={{
                                              fontSize: 40,
                                            }}>
                                            {vd.count}
                                          </div>
                                          <div
                                            style={{
                                              fontWeight: "lighter",
                                              fontSize: 20,
                                            }}>
                                            {getItem("lang") === "en"
                                              ? vd.textEng
                                              : vd.textIna}
                                          </div>
                                        </div>
                                      </td>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            )}
                          </table>
                        );
                      })}
                    </div>
                    <HGPagination
                      pageLimit={limit}
                      totalCount={totalArchive}
                      onChange={p => {
                        console.log(p);
                        this.setState({ page: p }, () => {
                          this.getVacancy();
                        });
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            position: "relative",
          }}>
          <MyHelmet title="Dashboard" />
          <RecruiterAppBar
            shadow={true}
            menu="dashboard"
            history={this.props.history}
          />
          <Menu
            className="link-menu"
            id="more-menu"
            style={{
              marginTop: 35,
              minHeight: 500,
            }}
            anchorEl={this.anchorMore}
            open={this.state.openMore}
            onClose={this.handleClose}>
            <MenuItem
              onClick={() => {
                this.moveToEdit(this.state.id);
              }}
              style={{
                color: palette.primary,
                margin: 0,
                padding: 0,
                fontWeight: "lighter",
              }}>
              <Link
                to=""
                style={{
                  color: palette.primary,
                  padding: "5px 50px 5px 15px",
                }}>
                {t("rec-dashboard:button.editVacancy")}
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.movetoarchive(this.state.id);
              }}
              style={{
                color: palette.primary,
                margin: 0,
                fontWeight: "lighter",
                padding: "5px 50px 5px 15px",
              }}>
              {t("rec-dashboard:button.movetoArchive")}
            </MenuItem>
          </Menu>

          <Menu
            className="link-menu"
            id="more-menu-arc"
            style={{
              marginTop: 35,
              minHeight: 500,
            }}
            anchorEl={this.anchorMoreArc}
            open={this.state.openMoreArc}
            onClose={this.handleCloseArc}>
            <MenuItem
              onClick={() => {
                this.moveToEdit(this.state.id);
              }}
              style={{
                color: palette.primary,
                margin: 0,
                padding: 0,
                fontWeight: "lighter",
              }}>
              <Link
                to=""
                style={{
                  color: palette.primary,
                  padding: "5px 50px 5px 15px",
                }}>
                {t("rec-dashboard:button.editVacancy")}
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                //this.movetoarchive(this.state.id);
              }}
              style={{
                color: palette.primary,
                margin: 0,
                fontWeight: "lighter",
                padding: "5px 50px 5px 15px",
              }}>
              {t("rec-dashboard:button.movetoArchive")}
            </MenuItem>
          </Menu>
          <div style={REC_STYLE.SUB_HEADER}>
            <div
              style={{
                width: "95%",
                position: "relative",
                display: "flex",
              }}>
              <Button
                onClick={() => {
                  this.handleChange(0);
                }}
                style={{
                  borderRadius: 0,
                  paddingBottom: 18,
                  color: palette.primary,
                  opacity: value === 0 ? 1 : 0.5,
                  fontWeight: "bold",
                  borderBottom:
                    value === 0
                      ? "2px solid " + palette.primary
                      : "2px solid transparent",
                }}>
                {t("rec-dashboard:button.activeVacancies")}
                <BadgeCountInfo circle={true} count={totalActive} />
              </Button>
              <Button
                onClick={() => {
                  this.handleChange(1);
                }}
                style={{
                  borderRadius: 0,
                  paddingBottom: 18,
                  color: palette.primary,
                  fontWeight: "bold",
                  opacity: value === 1 ? 1 : 0.5,
                  borderBottom:
                    value === 1
                      ? "2px solid " + palette.primary
                      : "2px solid transparent",
                }}>
                {t("rec-dashboard:button.archiveVacancies")}
                <BadgeCountInfo circle={true} count={totalArchive} />
              </Button>
            </div>
            <div
              style={{
                width: "44%",
                display: "flex",
                alignItems: "center",
              }}>
              <div
                style={{
                  marginBottom: 8,
                  padding: 5,
                  paddingLeft: 10,
                  borderRadius: 4,
                  display: "flex",
                  border: "0.5px solid rgba(0,0,0,0.3)",
                  alignItems: "center",
                  width: 305,
                }}>
                <Search
                  style={{
                    color: "rgba(0,0,0,0.3)",
                    paddingBottom: 2,
                    paddingRight: 15,
                  }}
                />
                <InputBase
                  placeholder={t("rec-dashboard:placeholder.search")}
                  style={{
                    width: 250,
                  }}
                  onChange={this.handleChangeInputSearch}
                />
              </div>
              {this.state.button}
            </div>
          </div>
          <div style={REC_STYLE.BODY_STYLE}>
            {/* sub header */}

            {value === 0 && (
              <div
                style={{
                  padding: "100px",
                  marginTop: 20,
                }}>
                <div
                  className="list-data"
                  style={{
                    width: "100%",
                  }}>
                  {totalActive === 0 && !isLoading ? (
                    <div>
                      <Paper
                        style={{
                          display: !this.state.data ? "" : "none",
                          boxShadow: "none",
                          border: "1px solid #E5E5E5",
                          padding: "50px 20px",
                          color: palette.primary,
                        }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                            flexDirection: "column",
                          }}>
                          <div
                            style={{
                              width: 500,
                              textAlign: "center",
                              marginBottom: 20,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: t("rec-dashboard:text.empty"),
                            }}></div>
                          {this.state.button}
                        </div>
                      </Paper>
                    </div>
                  ) : null}
                  {vacancy.length === 0 && !isLoading ? (
                    <div>
                      <Paper
                        style={{
                          display: !this.state.data ? "" : "none",
                          boxShadow: "none",
                          border: "1px solid #E5E5E5",
                          padding: "50px 20px",
                          color: palette.primary,
                        }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                            flexDirection: "column",
                          }}>
                          <div
                            style={{
                              width: 500,
                              textAlign: "center",
                              marginBottom: 20,
                            }}>
                            {t("error.data.empty")}
                          </div>
                        </div>
                      </Paper>
                    </div>
                  ) : null}
                  {vacancy.map((v, k) => {
                    var str = v.updatedTime;
                    var date = moment(str).toISOString().split("T")[0];
                    var updatedate = moment(date).format("DD MMM yyyy");
                    var time = moment(str)
                      .add(7, "hours")
                      .toISOString()
                      .split("T")[1];
                    var strprivate = "";
                    if (v.isPrivate) {
                      strprivate = "Private";
                    }
                    return (
                      <table
                        key={"list-vacancies-" + k}
                        className="item"
                        style={{
                          padding: "3px 30px 3px",
                          width: "100%",
                          backgroundColor: "white",
                          boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                          borderRadius: 4,
                          marginBottom: 15,
                        }}>
                        <tbody>
                          <tr>
                            <td
                              colSpan={
                                !this.state.showhide[
                                  "vacanciedetail" + v.vacancyId
                                ]
                                  ? 0
                                  : 3
                              }
                              width={
                                !this.state.showhide[
                                  "vacanciedetail" + v.vacancyId
                                ]
                                  ? "70%"
                                  : ""
                              }
                              valign="middle">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    color: "rgb(46, 105, 178)",
                                    fontSize: 12,
                                  }}>
                                  {v.vacancyName}
                                </div>
                                {v.isPrivate && (
                                  <div
                                    style={{
                                      fontSize: 12,
                                      margin: "0px 10px",
                                    }}>
                                    <span
                                      style={{
                                        padding: "5px 19px 11px 18px",
                                        backgroundColor:
                                          "rgba(82, 200, 244, 0.2)",
                                        color: "#3B69AD",
                                        borderRadius: "15% / 50%",
                                        opacity: 0.6,
                                        fontWeight: "lighter",
                                        height: 10,
                                        border: "1px solid #52C8F4",
                                        width: 50,
                                        textAlign: "center",
                                      }}>
                                      {strprivate}
                                    </span>
                                  </div>
                                )}
                                <div
                                  style={{
                                    fontSize: 12,
                                    margin: "0px 10px",
                                  }}>
                                  <span
                                    style={{
                                      padding: "5px 19px 11px 18px",
                                      backgroundColor:
                                        "rgba(46, 105, 178, 0.48)",
                                      color: "#3B69AD",
                                      borderRadius: "15% / 50%",
                                      opacity: 0.6,
                                      fontWeight: "lighter",
                                      height: 10,
                                      border: "1px solid #2E69B2",
                                    }}>
                                    {v.outstandingVacancy} vacancies
                                  </span>
                                </div>
                                <div
                                  style={{
                                    fontSize: 12,
                                    fontStyle: "italic",
                                    color: "#818385",
                                    fontWeight: "lighter",
                                  }}>
                                  {t("rec-dashboard:text.lastUpdate")} :{" "}
                                  {updatedate}, {time.substr(0, 8)} WIB
                                </div>
                              </div>
                            </td>
                            <td width="30%" align="right" valign="middle">
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-end",
                                }}>
                                <Link
                                  to={
                                    "/recruiter/viewcandidatelist/" +
                                    v.vacancyId
                                  }
                                  style={{ marginRight: 10 }}>
                                  <Button
                                    onClick={() =>
                                      SetLocalItem("VacancyId", v.vacancyId)
                                    }
                                    size="small"
                                    variant="contained"
                                    style={{
                                      ...PRIMARY_BUTTON,
                                      fontWeight: "bold",
                                      height: 27,
                                    }}>
                                    {t("rec-dashboard:button.viewCandidate")}
                                  </Button>
                                </Link>
                                <Button
                                  onClick={e => this.clickMore(e, v.vacancyId)}
                                  size="small"
                                  variant="contained"
                                  style={{
                                    ...PRIMARY_BUTTON,
                                    fontWeight: "bold",
                                    backgroundColor: "white",
                                    color: palette.primary,
                                    border: "1px solid ",
                                    height: 27,
                                  }}>
                                  {t("rec-dashboard:button.more")}
                                </Button>
                                {v.hasDetail === true ? (
                                  <div style={{}}>
                                    <Button
                                      style={{
                                        padding: "0px 0px",
                                        color: palette.primary,
                                        textTransform: "capitalize",
                                        texthecoration: "underline",
                                      }}
                                      onClick={() => {
                                        showhide = this.state.showhide;
                                        showhide[
                                          "vacanciedetail" + v.vacancyId
                                        ] =
                                          !this.state.showhide[
                                            "vacanciedetail" + v.vacancyId
                                          ];
                                        this.setState(prevState => ({
                                          ...prevState,
                                          ...showhide,
                                        }));
                                        var type = "Active";
                                        this.getVacancydetail(
                                          v.vacancyId,
                                          k,
                                          type
                                        );
                                      }}>
                                      {!this.state.showhide[
                                        "vacanciedetail" + v.vacancyId
                                      ] ? (
                                        <ArrowDropDown />
                                      ) : (
                                        <ArrowDropUp />
                                      )}
                                    </Button>
                                  </div>
                                ) : (
                                  <div style={{ minWidth: 64 }}></div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        {vacancydetail[k] !== null && (
                          <tbody
                            style={{
                              display: this.state.showhide[
                                "vacanciedetail" + v.vacancyId
                              ]
                                ? ""
                                : "none",
                            }}>
                            <tr
                              style={{
                                display: this.state.showhide[
                                  "vacanciedetail" + v.vacancyId
                                ]
                                  ? this.state.showhide[
                                      "vacanciedetail" + v.vacancyId
                                    ]
                                  : "none",
                                height: 140,
                              }}>
                              {vacancydetail[k].map((vd, kd) => {
                                return (
                                  <td
                                    key={"td-detail" + kd}
                                    align="center"
                                    width="25%"
                                    style={{
                                      color: "rgb(46, 105, 178)",
                                    }}>
                                    <div
                                      style={{
                                        borderRight: "1px solid #E5E5E5",
                                      }}>
                                      <div
                                        style={{
                                          fontSize: 40,
                                        }}>
                                        {vd.count}
                                      </div>
                                      <div
                                        style={{
                                          fontWeight: "lighter",
                                          fontSize: 20,
                                        }}>
                                        {getItem("lang") === "en"
                                          ? vd.textEng
                                          : vd.textIna}
                                      </div>
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        )}
                      </table>
                    );
                  })}
                  <HGPagination
                    pageLimit={limit}
                    totalCount={totalActive}
                    onChange={p => {
                      this.setState({ page: p }, () => {
                        this.getVacancy();
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {value === 1 && (
              <div
                style={{
                  padding: "100px",
                  paddingTop: 125,
                }}>
                {totalArchive === 0 && !isLoading ? (
                  <Paper
                    style={{
                      display: !this.state.data ? "" : "none",
                      boxShadow: "none",
                      border: "1px solid #E5E5E5",
                      padding: "50px 20px",
                      color: palette.primary,
                    }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        flexDirection: "column",
                      }}>
                      <div
                        style={{
                          width: 500,
                          textAlign: "center",
                          marginBottom: 20,
                        }}>
                        {t("error.data.empty")}
                      </div>
                    </div>
                  </Paper>
                ) : null}
                {totalArchive > 0 && (
                  <>
                    <div
                      className="list-data"
                      style={{
                        width: "100%",
                      }}>
                      {vacancyArchive.map((v, k) => {
                        var str = v.updatedTime;
                        var date = moment(str).toISOString().split("T")[0];
                        var updatedate = moment(date).format("DD MMM yyyy");
                        var time = moment(str)
                          .add(7, "hours")
                          .toISOString()
                          .split("T")[1];
                        return (
                          <table
                            key={"list-vacancies-" + k}
                            className="item"
                            style={{
                              padding: "3px 30px 3px",
                              width: "100%",
                              backgroundColor: "white",
                              boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                              borderRadius: 4,
                              marginBottom: 15,
                            }}>
                            <tbody>
                              <tr
                                style={{
                                  width: "100%",
                                }}>
                                <td colSpan={3} valign="middle">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}>
                                    <div
                                      style={{
                                        color: "rgb(46, 105, 178)",
                                        fontSize: 12,
                                      }}>
                                      {v.vacancyName}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 12,
                                        margin: "0px 10px",
                                      }}>
                                      <span
                                        style={{
                                          padding: "5px 19px 11px 18px",
                                          backgroundColor:
                                            "rgba(46, 105, 178, 0.48)",
                                          color: "#3B69AD",
                                          borderRadius: "15% / 50%",
                                          opacity: 0.6,
                                          fontWeight: "lighter",
                                          height: 10,
                                        }}>
                                        {v.outstandingVacancy} vacancies
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 12,
                                        fontStyle: "italic",
                                        color: "#818385",
                                        fontWeight: "lighter",
                                      }}>
                                      {t("rec-dashboard:text.lastUpdate")} :{" "}
                                      {updatedate}, {time.substr(0, 8)} WIB
                                    </div>
                                  </div>
                                </td>
                                <td width="45%" align="right" valign="middle">
                                  <div
                                    style={{
                                      width: 120,
                                      alignItems: "center",
                                      //display: "flex",
                                      flexDirection: "row",
                                    }}>
                                    {/* <Button
                                  onClick={e => this.clickMoreArc(e, v.vacancyId)}
                                  size="small"
                                  variant="contained"
                                  style={{
                                    ...PRIMARY_BUTTON,
                                    fontWeight: "bold",
                                    backgroundColor: "white",
                                    color: palette.primary,
                                    border: "1px solid ",
                                    height: 27,
                                  }}>
                                  {t("more")}
                                </Button> */}
                                    {v.hasDetail === true && (
                                      <div style={{}}>
                                        <Button
                                          style={{
                                            padding: "0px 0px",
                                            color: palette.primary,
                                            textTransform: "capitalize",
                                            texthecoration: "underline",
                                          }}
                                          onClick={() => {
                                            showhide = this.state.showhide;
                                            showhide[
                                              "vacanciedetail" + v.vacancyId
                                            ] =
                                              !this.state.showhide[
                                                "vacanciedetail" + v.vacancyId
                                              ];
                                            this.setState(prevState => ({
                                              ...prevState,
                                              ...showhide,
                                            }));
                                            var type = "Archive";
                                            this.getVacancydetail(
                                              v.vacancyId,
                                              k,
                                              type
                                            );
                                          }}>
                                          {!this.state.showhide[
                                            "vacanciedetail" + v.vacancyId
                                          ] ? (
                                            <ArrowDropDown />
                                          ) : (
                                            <ArrowDropUp />
                                          )}
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                            {vacancyArchivedetail[k] !== null && (
                              <tbody
                                style={{
                                  width: "100%",
                                  display: this.state.showhide[
                                    "vacanciedetail" + v.vacancyId
                                  ]
                                    ? ""
                                    : "none",
                                }}>
                                <tr
                                  style={{
                                    width: "100%",
                                    display: this.state.showhide[
                                      "vacanciedetail" + v.vacancyId
                                    ]
                                      ? this.state.showhide[
                                          "vacanciedetail" + v.vacancyId
                                        ]
                                      : "none",
                                    height: 140,
                                  }}>
                                  {vacancyArchivedetail[k].map((vd, kd) => {
                                    return (
                                      <td
                                        key={"td-detail" + kd}
                                        align="center"
                                        width="25%"
                                        style={{
                                          color: "rgb(46, 105, 178)",
                                        }}>
                                        <div
                                          style={{
                                            borderRight: "1px solid #E5E5E5",
                                          }}>
                                          <div
                                            style={{
                                              fontSize: 40,
                                            }}>
                                            {vd.count}
                                          </div>
                                          <div
                                            style={{
                                              fontWeight: "lighter",
                                              fontSize: 20,
                                            }}>
                                            {getItem("lang") === "en"
                                              ? vd.textEng
                                              : vd.textIna}
                                          </div>
                                        </div>
                                      </td>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            )}
                          </table>
                        );
                      })}
                    </div>
                    <HGPagination
                      pageLimit={limit}
                      totalCount={totalArchive}
                      onChange={p => {
                        console.log(p);
                        this.setState({ page: p }, () => {
                          this.getVacancy();
                        });
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}
export default withTranslation()(RecruiterDashboard);
