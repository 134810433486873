import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import { ImgTestBreakNew } from "assets/img";
import { palette } from "assets/css/main";
import { NextBtn } from "app/components";
import DialogueConfirm from "app/components/modal/confirm";
import { RequestGet, RequestPost } from "app/utils";
import alertData from "app/components/alert/alert-data";
import moment from "moment";

class TestBreak extends React.Component {
  state = {
    open: false,
    date: "",
    disclaimer: null,
  };
  handleClose = val => {
    const { t } = this.props;
    if (val) {
      RequestPost("examattempts/takelater")
        .then(res => {
          const { isHold } = res.data;
          if (isHold) {
            this.props.history.push("/user/logout");
          } else {
            alertData.show(t("pretest:confirm.failed"), "error");
          }
        })
        .catch(e => {
          console.log(e);
          alertData.show(t("pretest:confirm.failed"), "error");
        });
      this.setState({ open: false });
    } else {
      this.setState({ open: false });
    }
  };

  checkTimeFinish = () => {
    const { t } = this.props;
    RequestGet("examattempts/timefinish")
      .then(r => {
        const { timeFinish } = r.data;
        this.setState({
          date: timeFinish.replace("Z", ""),
          disclaimer: "",
          open: true,
        });
      })
      .catch(() => {
        this.setState({
          date: moment().add(2, "days").toDate(),
          disclaimer: t("basictest:dialogue.disclaimer"),
          open: true,
        });
      });
  };

  render() {
    const { t, breakOn, number } = this.props;
    const items = t("basictest:completed." + breakOn + ".content", {
      returnObjects: true,
    });
    const { date, open, disclaimer } = this.state;
    return (
      <div style={{ paddingTop: 20 }}>
        <DialogueConfirm
          open={open}
          title={t("basictest:dialogue.title")}
          content={t("basictest:dialogue.content")}
          btnYes={t("basictest:dialogue.yes")}
          btnNo={t("basictest:dialogue.no")}
          disclaimerTime={disclaimer}
          time={date}
          onOK={this.handleClose}
          onClose={() => {
            this.setState({ open: false });
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: palette.primary,
            fontWeight: "bold",
            flexDirection: "column",
            width: "100vw",
            boxSizing: "border-box",
            fontSize: 24,
          }}>
          <img
            src={ImgTestBreakNew}
            height={129}
            width={129}
            style={{ margin: 30 }}
            alt={"Break Hiregrade Test"}
          />
          <div style={{ width: 500, textAlign: "center", marginBottom: 30 }}>
            {number
              ? t("basictest:completed." + breakOn + ".title").replace(
                  "NUMBER",
                  number - 1
                )
              : t("basictest:completed." + breakOn + ".title")}
          </div>
          {items.map((v, k) => {
            return (
              <Typography
                key={k}
                style={{
                  width: 400,
                  textAlign: "center",
                  paddingTop: k * 20,
                }}>
                {v.replace("NUMBER", number)}
              </Typography>
            );
          })}
        </div>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            height: 60,
            position: "fixed",
            bottom: 0,
            backgroundColor: palette.primary,
            padding: "0px 100px",
          }}>
          {/* <div
            onClick={this.checkTimeFinish}
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: 24,
              cursor: "pointer",
              textTransform: "capitalize",
              textDecoration: "underline",
            }}>
            {t("button.later")}
          </div> */}
          <NextBtn
            color={"white"}
            onClick={() => {
              this.props.onNext();
            }}
            text={t("button.next_")}
          />
        </Grid>
      </div>
    );
  }
}
TestBreak.defaultProps = {
  onNext: () => {
    console.log("On next");
  },
};
export default withTranslation()(TestBreak);
