import React from "react";
import { withTranslation } from "react-i18next";
import { Dialog, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { getItem } from "app/utils";
import { VideoJSPlayer } from "app/components";
const buttonStyle = {
  textTransform: "capitalize",
  border: "1px solid " + palette.primary,
  color: "white",
  backgroundColor: palette.primary,
  margin: "0px 10px",
  padding: "5px 30px",
  fontWeight: "bold",
};
export default withTranslation()(
  class VideoPlayer extends React.Component {
    state = {
      questions: [],
      videos: [],
      index: 0,
      videoJsOptions: null,
      finishLoad: false,
    };

    componentDidMount() {
      this.getData();
    }

    setVideoContent = () => {
      this.setState({ finishLoad: false });
      this.setState(
        {
          videoJsOptions: {
            liveui: false,
            controls: true,
            sources: [
              {
                src: this.state.videos[this.state.index].videoUrl,
              },
            ],
          },
        },
        () => {
          this.setState({ finishLoad: true });
        }
      );
    };

    getData() {
      this.setState(this.props, () => {
        this.setVideoContent();
      });
    }

    render() {
      const { open, t } = this.props;
      const {
        questions,
        videos,
        index,
        videoJsOptions,
        finishLoad,
      } = this.state;

      return (
        <Dialog
          onEnter={() => {
            this.getData();
          }}
          fullScreen
          className={"Custom-Dialog "}
          aria-labelledby="customized-dialog-title"
          disableBackdropClick
          open={open}
          style={{ backgroundColor: "transparent" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              paddingTop: "50px",
            }}>
            <div
              style={{
                padding: 30,
                position: "relative",
              }}>
              <div
                style={{
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "20px 20px",
                    color: palette.primary,
                    position: "relative",
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    fontSize: 18,
                  }}>
                  <button
                    onClick={() => {
                      this.props.onClose();
                    }}
                    style={{
                      width: 28,
                      height: 28,
                      color: palette.darkGrey,
                      borderRadius: "50%",
                      position: "absolute",
                      backgroundColor: "white",
                      fontWeight: "bold",
                      top: 10,
                      right: 10,
                      border: "none",
                      cursor: "pointer",
                    }}>
                    X
                  </button>
                  <p style={{ fontWeight: "lighter", margin: 0 }}>
                    {t("cv:videoInterview.question") + " " + (index + 1)}
                  </p>
                  {questions.length > 0 && (
                    <div>
                      <p
                        style={{
                          margin: 0,
                          width: 500,
                          padding: "10px 0px 0px 0px",
                        }}>
                        {getItem("lang") === "en"
                          ? questions[index].interviewQuestionEng
                          : questions[index].interviewQuestionIna}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {finishLoad && (
                <VideoJSPlayer
                  {...videoJsOptions}
                  onError={(archived, errorCode) => {
                    this.props.onClose(archived, errorCode);
                  }}
                />
              )}
              <div
                style={{
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  marginTop: -10,
                  backgroundColor: "white",
                  padding: 20,
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <div>
                  <Button
                    onClick={() => {
                      this.setState({ index: index - 1 }, () => {
                        this.setVideoContent();
                      });
                    }}
                    style={{
                      ...buttonStyle,
                      display: index === 0 ? "none" : "",
                    }}>
                    {t("button.previous")}
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      this.setState({ index: index + 1 }, () => {
                        this.setVideoContent();
                      });
                    }}
                    style={{
                      ...buttonStyle,
                      display: index === videos.length - 1 ? "none" : "",
                    }}>
                    {t("button.next")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      );
    }
  }
);
