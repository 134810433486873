import React from "react";
import Unity, { UnityContent } from "react-unity-webgl";
import "../custom.css";
import { LinearProgress, Button } from "@material-ui/core";
import { Fullscreen } from "@material-ui/icons";
import btd from "../data";
import { getItem, RequestPost, setItem } from "app/utils";
import { DialogueInfo } from "app/components";
import { withTranslation } from "react-i18next";
import IntroEnergy from "./intro";
import CandidTaken from "app/components/camera/HGCandid";

class EnergyTest extends React.Component {
  state = {
    progression: 0,
    unityContent: null,
    open: false,
    intro: getItem("energyIntro") ? false : true,
    examAttemptId: null,
  };
  unityContent;
  constructor(props) {
    super(props);
    btd.stepper.footer = false;
    this.setFullscreen = this.setFullscreen.bind(this);
  }

  componentDidMount() {
    window.onbeforeunload = function () {};
    if (!this.state.intro) {
      this.getExamAttemptID();
    }
  }

  getExamAttemptID() {
    setItem("energyIntro", true);
    this.setState({ intro: false });
    const { id } = this.props.data;
    var data = {
      examCategoryId: id,
      userId: getItem("user_id"),
    };
    RequestPost("examattempts", data)
      .then(r => {
        if (this.props.match.params.gameName !== this.props.data.infoEn) {
          var lang = getItem("lang") ? getItem("lang").toUpperCase() : "ID";
          this.props.history.push(
            "/game/" +
              this.props.data.infoEn +
              "?lang=" +
              lang +
              "&&id=" +
              getItem("user_id")
          );
        }
        this.generateGame(this.props.data.infoEn);
        this.setState({ examAttemptId: r.data.id });
      })
      .catch(e => {
        console.log("cognitive:index.js > getExamAttemptID() error", e);
      });
  }
  generateGame(type) {
    var info = type.toLowerCase();
    console.log("Load Game", info);
    try {
      var content = new UnityContent(
        "/assets/" + info + "/Meta.json",
        "/assets/" + info + "/UnityLoader.js"
      );
      this.setState({
        unityContent: content,
      });
      content.on("progress", progression => {
        this.setState({
          progression: progression * 100,
        });
        if (progression === 1) {
          setTimeout(() => {
            this.setState({ open: true });
          }, 500);
        }
      });
      content.remove();
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  }
  setFullscreen() {
    this.state.unityContent.setFullscreen(true);
  }

  render() {
    const { t } = this.props;
    const {
      open,
      unityContent,
      intro,
      progression,
      examAttemptId,
    } = this.state;
    return (
      <div
        style={{
          paddingTop: 80,
          width: "100vw",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          boxSizing: "border-box",
        }}>
        <CandidTaken id={examAttemptId} />

        <DialogueInfo
          open={open}
          title={t("basictest:info")}
          content={t("basictest:fullscreen")}
          onClose={() => {
            this.setState({ open: false }, () => {
              this.setFullscreen();
            });
          }}
        />
        {intro && (
          <IntroEnergy
            onStart={() => {
              this.getExamAttemptID();
            }}
          />
        )}
        <div
          style={{
            width: 960,
            height: 600,
            position: "relative",
            display: intro ? "none" : "",
          }}>
          {progression < 100 && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <LinearProgress
                  style={{ height: 20, width: "100%" }}
                  variant="determinate"
                  value={progression}
                />
                <p
                  style={{
                    position: "absolute",
                    margin: 0,
                    color: "white",
                    textShadow: "1px 1px black",
                  }}>
                  Loading
                </p>
              </div>
            </>
          )}

          {unityContent !== null && (
            <Unity
              className={progression === 100 ? "Unity-Container" : "none"}
              style={{ width: 960, height: 600 }}
              unityContent={unityContent}
              onProgress={this.onProgress}
            />
          )}
          <Button
            onClick={this.setFullscreen}
            style={{
              position: "absolute",
              right: 0,
              minWidth: 0,
              padding: 0,
            }}
            variant="text">
            <Fullscreen />
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EnergyTest);
