import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { palette } from "assets/css/main";
import moment from "moment";
import { findWithAttr, getItem } from "app/utils";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  leftTitle: {
    color: "#606182",
    fontSize: 12,
    marginTop: 15,
    marginBottom: 10,
    textTransform: "uppercase",
  },
  leftItem: { color: "white", fontSize: 10, padding: "5pt 0pt" },
  sectionLeft: {
    height: "100%",
    width: "35%",
    padding: "10pt 10pt",
    fontWeight: "lighter",
    display: "flex",
    backgroundColor: "#2F3168",
    flexDirection: "column",
    alignItems: "center",
  },
  listContent: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  titleItem: { fontSize: 14, fontWeight: "bold", color: "#2F3168" },
  titleContent: { fontSize: 18, textTransform: "uppercase", color: "#2F3168" },
  item: { fontSize: 12, padding: "5pt 0pt 0pt 0pt", color: "#2F3168" },
});

const WorkExperienceView = props => {
  return (
    <View
      className="user"
      style={{
        display: props.data.length > 0 ? "" : "none",
        marginTop: 20,
      }}>
      <Divider color="#2F3168" />
      <View style={styles.titleContent}>
        <Text>{props.t("biodata:step.workexperience")}</Text>
      </View>
      <View>
        {props.data.map((val, i) => {
          return (
            <View
              style={{ ...styles.listContent, marginBottom: 15 }}
              key={"we" + i}>
              <View style={{ padding: "3pt 0pt" }}>
                <Text style={{ ...styles.titleItem, paddingTop: 10 }}>
                  {val.name}
                </Text>
                <Text style={styles.item}>{val.role}</Text>
                <Text style={{ ...styles.item, fontSize: 10 }}>
                  {val.yearStart.split("-")[0]} -
                  {val.stillWorking
                    ? " " + props.t("biodata:form.field.stillWorking")
                    : " " + val.yearEnd.split("-")[0]}
                </Text>
                <Text
                  style={{
                    ...styles.item,
                    fontSize: 10,
                    fontWeight: 100,
                    color: "#606182",
                  }}>
                  {findWithAttr(props.jobStatus, "value", val.status, "label")}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const FormalEducationView = props => {
  return (
    <View
      className="user"
      style={{
        display: props.data.length > 0 ? "" : "none",
        marginTop: 20,
      }}>
      <Divider color="#2F3168" />
      <View style={styles.titleContent}>
        <Text>{props.t("biodata:step.formaleducation")}</Text>
      </View>
      <View>
        {props.data.map((val, i) => {
          return (
            <View
              style={{ ...styles.listContent, marginBottom: 15 }}
              key={"fe" + i}>
              <View style={{ padding: "3pt 0pt" }}>
                <Text style={{ ...styles.titleItem, paddingTop: 10 }}>
                  {val.institution}
                </Text>
                <Text style={styles.item}>
                  {val.degree} - {val.major}
                </Text>
                <Text style={{ ...styles.item, fontSize: 10 }}>
                  {props.t("biodata:form.field.gpa") + " : "}
                  {val.grade}
                </Text>
                <Text
                  style={{ ...styles.item, fontSize: 10, color: "#606182" }}>
                  {props.t("biodata:form.field.graduated") + ": "}
                  {val.yearGraduate}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const Divider = props => (
  <View
    style={{
      margin: "20pt 0pt 10pt 0pt",
      borderTop: "1pt solid " + (props.color ? props.color : "white"),
      width: 50,
    }}
  />
);

const TemplatePurple = props => (
  <Document
    title={"CV - " + props.data.personalInfo.name}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <Page size="A4" style={styles.page}>
      <View style={{ ...styles.sectionLeft, paddingTop: 20 }}>
        <Image
          src={{
            uri: props.data.personalInfo.photo + "?t=" + new Date().getTime(),
            method: "GET",
            headers: {
              "Access-Control-Allow-Origin": window.location.origin,
              "Access-Control-Allow-Headers": "*",
            },
          }}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: 160,
            height: 158,
            borderTopLeftRadius: 82.5,
            borderTopRightRadius: 82.5,
            borderBottomRightRadius: 82.5,
            borderBottomLeftRadius: 82.5,
          }}
          allowDangerousPaths={true}
        />
        <Text
          style={{
            color: "white",
            fontSize: 18,
            fontWeight: 700,
            paddingTop: 25,
          }}>
          {props.data.personalInfo.name}
        </Text>
        <Divider />
        <Text
          style={{
            color: "white",
            fontSize: 10,
            fontWeight: 700,
            textTransform: "uppercase",
          }}>
          ID : {getItem("user_id") ? getItem("user_id").split("-")[0] : ""}
        </Text>
        <Text
          style={{
            color: "white",
            fontSize: 10,
            fontWeight: 700,
            textTransform: "uppercase",
            margin: "5pt 0pt 30pt 0pt",
          }}>
          {props.t("hgkode")} :{" "}
          {props.data.personalInfo.id ? getItem("user_id").split("-")[1] : ""}
        </Text>
        <View style={{ width: "100%", paddingLeft: 25 }}>
          <Text style={styles.leftTitle}>{props.t("biodata:CVContact")}</Text>
          <Text style={styles.leftItem}>{props.data.email}</Text>
          <Text style={styles.leftItem}>
            {props.data.personalInfo.phoneNumber
              ? "0" + props.data.personalInfo.phoneNumber
              : ""}
          </Text>
          <Text style={styles.leftItem}>{props.data.personalInfo.address}</Text>
          <Text style={styles.leftTitle}>
            {props.t("biodata:form.field.bod")}
          </Text>
          <Text style={styles.leftItem}>
            {moment(props.data.personalInfo.birthDate).format("DD-MM-yyyy")}
          </Text>
          <Text style={styles.leftItem}>
            {props.data.personalInfo.birthPlace}
          </Text>
          <Text style={styles.leftTitle}>status</Text>
          <Text style={styles.leftItem}>
            {findWithAttr(
              props.data.maritalStatus,
              "value",
              props.data.personalInfo.maritalStatus,
              "label"
            )}
          </Text>
        </View>
      </View>
      <View
        style={{
          color: palette.primary,
          padding: "20pt 20pt 20pt 20pt",
          width: "65%",
        }}>
        <WorkExperienceView
          data={props.data.listWorks}
          t={props.t}
          jobStatus={props.data.jobStatus}
        />
        <FormalEducationView
          data={props.data.listFormalEducation}
          t={props.t}
        />
      </View>
    </Page>
  </Document>
);
export default TemplatePurple;
