import React from "react";
import { withTranslation } from "react-i18next";
import { AppBar, Toolbar, Grid, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { loadImage, getItem, RequestGet, AUTH_API } from "app/utils";
import { palette } from "assets/css/main";
import { ArrowForward } from "@material-ui/icons";
import { ImgVerifyNew } from "assets/img";
import { isMobile } from "react-device-detect";
import { HeaderNologin } from "app/components";
class VerifyEmail extends React.Component {
  state = {
    email: "",
    vt: "",
    vn: "",
    rn: "",
  };
  constructor(props) {
    super(props);

    if (!getItem("email-register") && !this.props.location.state) {
      //this.props.history.push("");
    } else {
      this.state = {
        email: this.props.location.state
          ? this.props.location.state
          : getItem("email-register"),
        vt: "",
        vn: "",
        rn: "",
      };
    }
  }
  componentDidMount() {
    const { t } = this.props;
    console.log("object");
    var vt =
      getItem("idRole") === "r"
        ? t("auth:verifytitle")
        : getItem("lang") === "en"
        ? t("auth:verifytitle")
        : t("auth:verifytitle_ap");
    var vn =
      getItem("idRole") === "r"
        ? t("auth:verifynote")
        : getItem("lang") === "en"
        ? t("auth:verifynote")
        : t("auth:verifynote_ap");
    var rn =
      getItem("idRole") === "r"
        ? t("auth:resendnote")
        : getItem("lang") === "en"
        ? t("auth:resendnote")
        : t("auth:resendnote_ap");
    console.log(vn);
    this.setState({ vt: vt, vn: vn, rn: rn });
  }
  resendEmail(e) {
    const data = { email: this.state.email };
    RequestGet(AUTH_API + "connect/sendverifyemail", data)
      .then(res => {
        console.log(res);
      })
      .catch(e => {
        console.log("verify.js > resend()", e);
      });
  }
  render() {
    const { t } = this.props;
    const { vt, vn, rn } = this.state;
    if (isMobile) {
      return (
        <div>
          <HeaderNologin />
          <main style={{ paddingTop: 90 }}>
            <Grid style={{ textAlign: "center", padding: "0px 20px" }}>
              <img src={ImgVerifyNew} alt="Verify" style={{ width: 90 }} />
              <Typography
                style={{
                  fontSize: 24,
                  marginBottom: 20,
                  fontWeight: "bold",
                  color: palette.primary,
                  textAlign: "center",
                  marginTop: 30,
                }}>
                {vt}
              </Typography>
              <Typography
                style={{
                  marginBottom: 20,
                  color: palette.primary,
                  textAlign: "center",
                }}>
                {vn.split("EMAIL_ADDRESS")[0] + " "}
                <strong>
                  {this.props.location.state
                    ? this.props.location.state
                    : getItem("email-register")}
                </strong>
                {vn.split("EMAIL_ADDRESS")[1]}
              </Typography>
              <Typography
                style={{
                  marginBottom: 20,
                  color: palette.primary,
                  textAlign: "center",
                }}>
                {rn}
              </Typography>
              <Button
                style={{ marginTop: 20 }}
                onClick={() => {
                  this.resendEmail();
                }}>
                <span
                  style={{
                    fontSize: 24,
                    fontWeight: "bolder",
                    color: palette.primary,
                    textTransform: "capitalize",
                    marginRight: 5,
                    textDecoration: "underline",
                  }}>
                  {t("auth:resendbtn")}
                </span>
                <ArrowForward style={{ color: palette.primary }} />
              </Button>
            </Grid>
          </main>
        </div>
      );
    } else {
      return (
        <div>
          <AppBar
            position="absolute"
            color="default"
            style={{
              backgroundColor: "white",
              top: 0,
              height: 80,
              bottom: "auto",
              position: "fixed",
            }}>
            <Toolbar>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ height: 80 }}>
                <Grid item lg={2}>
                  <Link to="/" className="centered">
                    <img
                      alt="Hiregrade-Logo"
                      src={loadImage("HiregradeLogo.png")}
                      width={150}
                      style={{ marginLeft: 87 }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <main style={{ paddingTop: 150 }}>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column">
              <img src={ImgVerifyNew} alt="Verify" />
              <Typography
                style={{
                  fontSize: 24,
                  width: 450,
                  marginBottom: 20,
                  fontWeight: "bold",
                  color: palette.primary,
                  textAlign: "center",
                  marginTop: 30,
                }}>
                {vt}
              </Typography>
              <Typography
                style={{
                  marginBottom: 20,
                  width: 530,
                  color: palette.primary,
                  textAlign: "center",
                }}>
                {vn.split("EMAIL_ADDRESS")[0] + " "}
                <strong>
                  {this.props.location.state
                    ? this.props.location.state
                    : getItem("email-register")}
                </strong>
                {vn.split("EMAIL_ADDRESS")[1]}
              </Typography>
              <Typography
                style={{
                  marginBottom: 20,
                  width: 400,
                  color: palette.primary,
                  textAlign: "center",
                }}>
                {rn}
              </Typography>
              <Button
                style={{ marginTop: 20, marginBottom: 24 }}
                onClick={() => {
                  this.resendEmail();
                }}>
                <span
                  style={{
                    fontSize: 24,
                    fontWeight: "bolder",
                    color: palette.primary,
                    textTransform: "capitalize",
                    marginRight: 5,
                    textDecoration: "underline",
                  }}>
                  {t("auth:resendbtn")}
                </span>
                <ArrowForward style={{ color: palette.primary }} />
              </Button>
            </Grid>
          </main>
        </div>
      );
    }
  }
}
export default withTranslation()(VerifyEmail);
