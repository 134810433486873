import React from "react";
import { withTranslation } from "react-i18next";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette } from "assets/css/main";
import CandidateSidePanel from "./side-panel";
import CandidatePreview from "./candidate-preview";
import {
  FormalEducationView,
  WorkExperienceView,
  InformalEducationView,
  OrganizationExpView,
} from "app/pages/applicant/dashboard/home/cv/cv-component";
import { MyHelmet } from "app/components";
import { AppHistory, getItem } from "app/utils";
import { Button } from "@material-ui/core";

const style = {
  container: { display: "flex", flexDirection: "column", padding: "15px 0px" },
  label: { fontWeight: "lighter", fontSize: 14 },
  value: { fontSize: 18 },
  langDot: {
    fontSize: 20,
    height: 10,
    padding: "0 15",
    background: palette.primary,
    border: "none",
    borderRadius: 20,
    opacity: 1,
    fontWeight: "normal",
    width: 10,
  },
};
export default withTranslation()(
  class CandidatesInformation extends React.Component {
    state = {
      data: {
        personalInfo: { firstName: "", id: "", listUserLanguage: [] },
        listWorks: [],
        listFormalEducation: [],
        listInformalEducation: [],
        listOrganization: [],
      },
    };
    componentDidMount() {
      window.scrollTo(0, 0);
    }
    render() {
      const {
        match: { params },
        t,
      } = this.props;

      const {
        data: {
          personalInfo,
          listWorks,
          listFormalEducation,
          listInformalEducation,
          listOrganization,
        },
      } = this.state;

      const jobStatus = t("biodata:options.jobStatus", {
        returnObjects: true,
      });
      const levelSkillLang = t("biodata:levelSkillLang", {
        returnObjects: true,
      });
      return (
        <div style={{ height: "100%", boxSizing: "border-box" }}>
          <RecruiterAppBar
            menu={AppHistory.location.state ? "dashboard" : "candidates"}
            shadow={false}
          />
          <MyHelmet title="Candidate Information" />
          <div
            style={{ paddingTop: 80, height: "100%", boxSizing: "border-box" }}>
            <CandidatePreview
              candidate={AppHistory.location.state ? false : true}
              id={params.id}
              onRender={data => {
                this.setState({ data: data });
              }}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                minHeight: 650,
                boxSizing: "border-box",
              }}>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  backgroundColor: palette.lightGrey,
                  boxSizing: "border-box",
                  justifyContent: "flex-end",
                }}>
                <CandidateSidePanel view="cv" id={params.id} />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  height: "100%",
                  boxSizing: "border-box",
                  flexDirection: "column",
                  padding: "25px 40px",
                  color: palette.primary,
                }}>
                <span style={{ fontWeight: "bold", fontSize: 24 }}>
                  {t("candidates:candidateInfo.cv")}
                </span>

                <span
                  style={{ fontWeight: "normal", fontSize: 24, marginTop: 25 }}>
                  {t("biodata:cv_template.hg2.profile")}
                </span>
                <span style={{ overflowWrap: "break-word" }}>
                  {personalInfo.description}
                </span>
                <span
                  style={{ fontWeight: "normal", fontSize: 24, marginTop: 35 }}>
                  {t("biodata:form.field.foreignLangProficiency")}
                </span>
                {personalInfo.listUserLanguage.map((v, i) => {
                  var strproficiency = levelSkillLang[v.proficiency - 1];
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 10,
                      }}>
                      <div style={style.langDot}></div>
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: "normal",
                          color: palette.primary,
                          marginLeft: 10,
                          width: 170,
                        }}>
                        {getItem("lang") === "en"
                          ? v.languageSkillEng
                          : v.languageSkillIna}
                      </div>
                      <div></div>
                      <div
                        style={{
                          position: "relative",
                          height: 30,
                          padding: "0 15",
                          background: "rgba(82, 200, 244, 0.2)",
                          border: "1px solid #52C8F4",
                          borderRadius: 20,
                          width: 300,
                        }}>
                        <Button
                          disabled
                          style={{
                            color: "white",
                            fontSize: 13,
                            height: 30,
                            padding: "0 15",
                            textTransform: "none",
                            background: "#52C8F4",
                            border: "none",
                            borderRadius: 20,
                            opacity: 2,
                            fontWeight: "normal",
                            width:
                              v.proficiency === 1
                                ? 100
                                : v.proficiency === 2
                                ? 200
                                : 300,
                          }}>
                          {strproficiency}
                        </Button>
                      </div>
                    </div>
                  );
                })}

                <div
                  style={{
                    width: 600,
                    paddingTop: 35,
                    display: listWorks.length ? "" : "none",
                  }}>
                  <WorkExperienceView
                    titleCase={true}
                    data={listWorks}
                    t={t}
                    jobStatus={jobStatus}
                  />
                </div>
                <div style={{ width: 600, paddingTop: 35 }}>
                  <FormalEducationView
                    titleCase={true}
                    data={listFormalEducation}
                    t={t}
                  />
                </div>
                {listInformalEducation.length > 0 && (
                  <div style={{ width: 600, paddingTop: 35 }}>
                    <InformalEducationView
                      titleCase={true}
                      data={listInformalEducation}
                      t={t}
                    />
                  </div>
                )}
                {listOrganization.length > 0 && (
                  <div style={{ width: 600, paddingTop: 35 }}>
                    <OrganizationExpView
                      titleCase={true}
                      data={listOrganization}
                      t={t}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
