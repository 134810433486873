import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import {
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@material-ui/core";
import { IconSuggestion } from "assets/img";
import { findObject, getItem, GetLocalItem } from "app/utils";
import { MultipleTextField } from "app/components";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import data from "../data";
import alertData from "app/components/alert/alert-data";

const creatableSelectStyles = {
  borderWidth: "0px 0px 1px 0px",
  borderRadius: "0px",
};

class JobRequirementEdit extends React.Component {
  constructor(props) {
    super(props);
    if (GetLocalItem("vacancyGet")) {
      data.listVacancy = GetLocalItem("vacancyGet");
    }

    this.data = JSON.stringify(this.props.data);
    this.state = {
      editVacancy: JSON.parse(this.data),
      listVacancy: data.listVacancy,
      performs: [],
      workEnvs: [],
      univPrefs: [],
      majorPrefs: [],
      listPerform: [],
      listWorkEnv: [],
      listUnivPref: [],
      listMajorPref: [],
      otherWorkEnvs: [],
      otherPerforms: [],
      otherWorkEnvID: "",
      otherPerformID: "",
    };
  }

  creatableRef = null;
  creatableRefPerform = null;

  componentDidMount() {
    // performs
    var otPerform = this.state.listVacancy.jobPerform.find(p => p.jobPerformEng === "Others").id;
    this.setState({ otherPerformID: otPerform });
    this.setState({
      listPerform: this.state.listVacancy.jobPerform
        ? this.state.listVacancy.jobPerform.map(obj => {
            return {
              value: obj.id,
              label:
                getItem("lang") === "en"
                  ? obj.jobPerformEng
                  : obj.jobPerformIna,
            };
          })
        : [],
    });
    this.setState({
      performs: this.state.listVacancy.jobPerform
        ? this.state.editVacancy.vacancyRequirementJobPerform.map(v => {
            const jp = this.state.listVacancy.jobPerform
              ? this.state.listVacancy.jobPerform.find(p => p.id === v)
                ? this.state.listVacancy.jobPerform.find(p => p.id === v)
                : v
              : "";
            const sjp = jp.jobPerformEng
              ? getItem("lang") === "en"
                ? jp.jobPerformEng
                : jp.jobPerformIna
              : jp;
            return {
              value: v,
              label: sjp,
            };
          })
        : [],
    });

    // work environtment
    var ot = this.state.listVacancy.environmentWork.find(p => p.workEnvironmentEng === "Others").id;
    this.setState({ otherWorkEnvID: ot });
    this.setState({
      listWorkEnv: this.state.listVacancy.environmentWork
        ? this.state.listVacancy.environmentWork.map(obj => {
            return {
              value: obj.id,
              label:
                getItem("lang") === "en"
                  ? obj.workEnvironmentEng
                  : obj.workEnvironmentIna,
            };
          })
        : [],
    });
    this.setState({
      workEnvs: this.state.listVacancy.environmentWork
        ? this.state.editVacancy.vacancyRequirementEnvironmentWork.map(v => {
            var environmentWork = this.state.listVacancy.environmentWork.find(
              p => p.id === v
            );
            return {
              value: v,
              label:
                getItem("lang") === "en"
                  ? environmentWork.workEnvironmentEng
                  : environmentWork.workEnvironmentIna,
            };
          })
        : [],
    });

    // university preference
    var noPrefUniv = {
      value: "noPref",
      label: this.props.t("vacancy:create.jobReq.noPref") // => hardcoded no preference
    };
    var hcListUnivPref = [];
    hcListUnivPref.push(noPrefUniv)
    this.state.listVacancy.listUniversity.map(obj => {
      var hcUnivPref = {
        value: obj.id,
        label: obj.university
      };
      hcListUnivPref.push(hcUnivPref)
      return null;
    });
    this.setState({ listUnivPref: hcListUnivPref });
    if (data.vacancy_post.vacancyUniversitys.length === 0) {
      this.setState({ univPrefs: noPrefUniv });
    } else {
      this.setState({
        univPrefs: data.vacancy_post.vacancyUniversitys.map(v => {
          var univPref = this.state.listVacancy.listUniversity.find(p => p.id === v);
            return {
              value: v,
              label: univPref.university
            };
        }),
      });
    }

    // major preference
    var noPrefMajor = {
      value: "noPref",
      label: this.props.t("vacancy:create.jobReq.noPref") // => hardcoded no preference
    };
    var hcListMajorPref = [];
    hcListMajorPref.push(noPrefMajor);
    this.state.listVacancy.listMajorAlias.map(obj => {
      var hcMajorPref = {
        value: obj.id,
        label:
          getItem("lang") === "en"
            ? obj.aliasEng
            : obj.aliasIna,
      }
      hcListMajorPref.push(hcMajorPref);
      return null;
    });
    this.setState({ listMajorPref: hcListMajorPref });
    if (data.vacancy_post.vacancyMajorAliases.length === 0) {
      this.setState({ majorPrefs: noPrefMajor });
    } else {
      this.setState({
        majorPrefs: data.vacancy_post.vacancyMajorAliases.map(v => {
          var majorPref = this.state.listVacancy.listMajorAlias.find(p => p.id === v);
            return {
              value: v,
              label:
                getItem("lang") === "en"
                  ? majorPref.aliasEng
                  : majorPref.aliasIna,
            };
        }),
      });
    }

    // other work environtment
    if(this.state.editVacancy.otherEnvironments.length > 0) {
      this.setState({
        otherWorkEnvs: this.state.editVacancy.otherEnvironments.map(v => {
          return {
            value: v,
            label: v
          }
        })
      })
    }

    // other perform
    if(this.state.editVacancy.otherJobPerform.length > 0) {
      this.setState({
        otherPerforms: this.state.editVacancy.otherJobPerform.map(v => {
          return {
            value: v,
            label: v
          }
        })
      })
    }
  }

  handleSuggestion(value, param) {
    var val = null;
    var newEdit = this.state.editVacancy;
    if (param === "workEnv") {
      var currentEnvirontment = this.state.workEnvs;
      currentEnvirontment.push(value);

      // manual set
      this.setState(
        {
          workEnvs: currentEnvirontment,
          classMenu: "add_from_suggestion",
        },
        () => {
          this.creatableRef.focus();
        }
      );

      setTimeout(() => {
        this.creatableRef.blur();
        this.setState({ classMenu: "" });
      }, 100);
      val = currentEnvirontment.map(v => {
        return v.value;
      });
      newEdit.vacancyRequirementEnvironmentWork = val;
      this.setState({ editVacancy: newEdit });
    } else if (param === "perform") {
      var currentPerform = this.state.performs;
      currentPerform.push(value);

      // manual set
      this.setState(
        {
          performs: currentPerform,
          classMenuPerform: "add_from_suggestion",
        },
        () => {
          this.creatableRefPerform.focus();
        }
      );

      setTimeout(() => {
        this.creatableRefPerform.blur();
        this.setState({ classMenuPerform: "" });
      }, 100);
      val = currentPerform.map(v => {
        return v.value;
      });
      newEdit.vacancyRequirementJobPerform = val;
      this.setState({ vacancyRequirementJobPerform: val });
    } else {
      // nothing
    }
  }

  cekValidation(editVacancy) {
    var result = false;

    if (editVacancy.vacancyRequirement.hasCertificate) {
      if (
        editVacancy.vacancyRequirement.languageSkill !== "" &&
        typeof editVacancy.vacancyRequirement.hasCertificate !== "undefined" &&
        editVacancy.vacancyRequirementJobPerform.length > 0 &&
        editVacancy.vacancyRequirementEnvironmentWork.length > 0 &&
        editVacancy.vacancyRequirementCertificate.length > 0 &&
        editVacancy.vacancyRequirementCertificate[0] !== ""
      ) {
        result = true;
      }
    } else {
      if (
        editVacancy.vacancyRequirement.hasCertificate !== null &&
        editVacancy.vacancyRequirement.languageSkill !== "" &&
        typeof editVacancy.vacancyRequirement.hasCertificate !== "undefined" &&
        editVacancy.vacancyRequirementJobPerform.length > 0 &&
        editVacancy.vacancyRequirementEnvironmentWork.length > 0
      ) {
        result = true;
      }
    }

    if (result === false) {
      alertData.show(this.props.t("vacancy:create.validation"), "error");
    }
    return result;
  }

  render() {
    const {
      editVacancy,
      listVacancy,
      performs,
      listPerform,
      listWorkEnv,
      workEnvs,
      listUnivPref,
      univPrefs,
      listMajorPref,
      majorPrefs,
      otherWorkEnvs,
      otherWorkEnvID,
      otherPerforms,
      otherPerformID,
      classMenu,
      classMenuPerform,
    } = this.state;
    const { t } = this.props;
    return (
      <Grid className="edit-vacancy" container style={{ padding: "4% 6% 8%" }}>
        <Grid item lg={12} md={12} sm={12}>
          <Typography
            style={{
              fontSize: "24px",
              color: palette.primary,
            }}>
            {t("vacancy:create.step", { returnObjects: true })[1]}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
            <div>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.minEdu.label")}
              </InputLabel>
              <Select
                labelId="vacancy"
                style={{ color: palette.primary, fontWeight: "bold" }}
                className="Field select-with-placeholder"
                value={
                  editVacancy.vacancy.minEducation === null
                    ? " " : editVacancy.vacancy.minEducation
                }
                onChange={v => {
                  var newEdit = editVacancy;
                  newEdit.vacancy.minEducation = v.target.value;
                  this.setState({ editVacancy: newEdit });
                }}
              >
                <MenuItem style={{ color: palette.primary }} value=" " disabled>
                  <span>
                    {t(
                      "vacancy:create.jobReq.minEdu.placeholder"
                    )}
                  </span>
                </MenuItem>
                {
                  t("vacancy:create.jobReq.minEdu.options", {
                    returnObjects: true,
                  }).map((v, i) => {
                    return(
                      <MenuItem
                        key={i}
                        style={{ color: palette.primary }}
                        value={v}
                      >
                        {v}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
            <div style={{ width:"50vw" }}>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.univPref.label")}
              </InputLabel>
              <ReactSelect
                classNamePrefix={"university"}
                isOptionDisabled={option => 
                  findObject(univPrefs, "value", "noPref")
                    ? option.value !== "noPref"
                    : (!findObject(univPrefs, "value", "noPref") && univPrefs.length > 0
                      ? option.value === "noPref"
                      : option.value === null)
                }
                openMenuOnFocus={true}
                placeholder={t("vacancy:create.jobReq.univPref.placeholder")}
                isMulti
                isClearable
                onChange={obj => {
                  var newEdit = editVacancy;
                  if (obj === null) {
                    this.setState({ univPrefs: [] });
                    newEdit.vacancyUniversitys = [];
                  } else if (obj[0].value === "noPref") {
                    this.setState({ univPrefs: obj });
                    newEdit.vacancyUniversitys = [];
                  } else {
                    this.setState({ univPrefs: obj });
                    var value = obj.map(v => {
                      return v.value;
                    });
                    newEdit.vacancyUniversitys = value;
                  }
                  this.setState({ editVacancy: newEdit });
                }}
                options={listUnivPref}
                styles={creatableSelectStyles}
                value={univPrefs}
              />
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
            <div style={{ width:"50vw" }}>
              <InputLabel
                shrink
                id="vacancy"
                style={{ color: palette.primary, marginBottom: 15 }}>
                {t("vacancy:create.jobReq.majorPref.label")}
              </InputLabel>
              <ReactSelect
                classNamePrefix={"major"}
                isOptionDisabled={option => 
                  findObject(majorPrefs, "value", "noPref")
                    ? option.value !== "noPref"
                    : (!findObject(majorPrefs, "value", "noPref") && majorPrefs.length > 0
                      ? option.value === "noPref"
                      : option.value === null)
                }
                openMenuOnFocus={true}
                placeholder={t("vacancy:create.jobReq.majorPref.placeholder")}
                isMulti
                isClearable
                onChange={obj => {
                  var newEdit = editVacancy;
                  if (obj === null) {
                    this.setState({ majorPrefs: [] });
                    newEdit.vacancyMajorAliases = [];
                  } else if (obj[0].value === "noPref") {
                    this.setState({ majorPrefs: obj });
                    newEdit.vacancyMajorAliases = [];
                  } else {
                    this.setState({ majorPrefs: obj });
                    var value = obj.map(v => {
                      return v.value;
                    });
                    newEdit.vacancyMajorAliases = value;
                  }
                  this.setState({ editVacancy: newEdit });
                }}
                options={listMajorPref}
                styles={creatableSelectStyles}
                value={majorPrefs}
              />
            </div>
          </Grid>

          <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
            <TextField
              type="number"
              style={{ fontSize: 24 }}
              autoFocus={true}
              InputLabelProps={{
                shrink: true,
              }}
              placeholder={t(
                "vacancy:create.jobReq.minGpa.placeholder"
              )}
              label={t("vacancy:create.jobReq.minGpa.label")}
              className="Field"
              onChange={v => {
                var newEdit = editVacancy;
                if (v.target.value === "" || v.target.value === null) {
                  newEdit.vacancy.minGpa = 0;
                } else {
                  newEdit.vacancy.minGpa = parseFloat(v.target.value);
                }
                this.setState({ editVacancy: newEdit });
              }}
              value={
                editVacancy.vacancy.minGpa === null
                  ? null
                  : editVacancy.vacancy.minGpa.toFixed(2)
              }
            />
          </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <div>
            <InputLabel
              shrink
              id="vacancy"
              style={{ color: palette.primary, marginBottom: 15 }}>
              {t("vacancy:create.jobReq.languageSkill.label")}
            </InputLabel>
            <Select
              labelId="vacancy"
              style={{
                color: palette.primary,
                fontWeight: "bold",
                width: "55%",
              }}
              className="Field select-with-placeholder"
              value={
                editVacancy.vacancyRequirement.languageSkill
                  ? editVacancy.vacancyRequirement.languageSkill
                  : " "
              }
              onChange={v => {
                var newEdit = editVacancy;
                newEdit.vacancyRequirement.languageSkill = v.target.value;
                this.setState({ editVacancy: newEdit });
              }}>
              <MenuItem style={{ color: palette.primary }} value=" " disabled>
                <span>
                  {t("vacancy:create.jobReq.languageSkill.placeholder")}
                </span>
              </MenuItem>
              {listVacancy.languageSkills
                ? listVacancy.languageSkills.map((v, i) => {
                    return (
                      <MenuItem
                        key={i}
                        style={{ color: palette.primary }}
                        value={v.id}>
                        {v.languageSkill}
                      </MenuItem>
                    );
                  })
                : null}
            </Select>
          </div>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <div>
            <InputLabel
              shrink
              id="vacancy"
              style={{ color: palette.primary, marginBottom: 15 }}>
              {t("vacancy:create.jobReq.specificCertification.label")}
            </InputLabel>
            <Select
              labelId="vacancy"
              style={{
                color: palette.primary,
                fontWeight: "bold",
                width: "55%",
              }}
              className="Field select-with-placeholder"
              value={
                editVacancy.vacancyRequirement.hasCertificate
                  ? "Required"
                  : editVacancy.vacancyRequirement.hasCertificate === null
                  ? " "
                  : "Not Required"
              }
              onChange={v => {
                var newEdit = editVacancy;
                if (v.target.value === "Required") {
                  newEdit.vacancyRequirement.hasCertificate = true;
                } else {
                  newEdit.vacancyRequirement.hasCertificate = false;
                }
                this.setState({ editVacancy: newEdit });
              }}>
              <MenuItem style={{ color: palette.primary }} value=" " disabled>
                <span>
                  {t("vacancy:create.jobReq.specificCertification.placeholder")}
                </span>
              </MenuItem>
              <MenuItem style={{ color: palette.primary }} value="Required">
                {
                  t("vacancy:create.jobReq.specificCertification.options", {
                    returnObjects: true,
                  })[0]
                }
              </MenuItem>
              <MenuItem style={{ color: palette.primary }} value="Not Required">
                {
                  t("vacancy:create.jobReq.specificCertification.options", {
                    returnObjects: true,
                  })[1]
                }
              </MenuItem>
            </Select>
          </div>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: "3%" }}
          hidden={editVacancy.vacancyRequirement.hasCertificate ? false : true}>
          <MultipleTextField
            style={{ width: "60%" }}
            fieldData={editVacancy.vacancyRequirementCertificate}
            label={t("vacancy:create.jobReq.requiredCertificate.label")}
            placeholder={t(
              "vacancy:create.jobReq.requiredCertificate.placeholder"
            )}
            onChange={v => {
              var newEdit = editVacancy;
              newEdit.vacancyRequirementCertificate = v;
              this.setState({ editVacancy: newEdit });
            }}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: 40 }}>
          <div style={{ width: "55%" }}>
            <InputLabel
              shrink
              id="vacancy"
              style={{ color: palette.primary, marginBottom: 15 }}>
              {t("vacancy:create.jobReq.perform.label")}
            </InputLabel>
            <ReactSelect
              className={classMenuPerform}
              classNamePrefix={"work-env"}
              ref={ref => {
                this.creatableRefPerform = ref;
              }}
              openMenuOnFocus={true}
              placeholder={t("vacancy:create.jobReq.perform.placeholder")}
              isMulti
              isClearable
              onChange={obj => {
                var newEdit = editVacancy;
                if (obj === null) {
                  this.setState({ performs: [] });
                  newEdit.vacancyRequirementJobPerform = [];
                  
                  this.setState({ otherPerforms: [] });
                  newEdit.otherJobPerform = [];
                } else {
                  this.setState({ performs: obj });
                  var value = obj.map(v => {
                    return v.value;
                  });
                  newEdit.vacancyRequirementJobPerform = value;
                }
                this.setState({ editVacancy: newEdit });
              }}
              options={listPerform}
              styles={creatableSelectStyles}
              value={performs}
            />
          </div>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: "3%" }}
          hidden={editVacancy.vacancyRequirementJobPerform.some(p => p === otherPerformID) ? false : true}
        >
          <div style={{ width: "55%" }}>
            <CreatableSelect
              classNamePrefix={"work-env"}
              placeholder={t("vacancy:create.vacancyDetail.jobFamily.otherPlaceholder")}
              isMulti
              onChange={obj => {
                var newEdit = editVacancy;
                if (obj === null) {
                  this.setState({ otherPerforms: [] });
                  newEdit.otherJobPerform = [];
                } else {
                  this.setState({ otherPerforms: obj });
                  var value = obj.map(v => {
                      return v.value;
                    });
                  newEdit.otherJobPerform = value;
                }
                this.setState({ editVacancy: newEdit });
                data.vacancy_post = newEdit;
              }}
              styles={creatableSelectStyles}
              value={otherPerforms}
            />
          </div>
        </Grid>

        <Grid item lg={9} md={9} sm={9} style={{ marginTop: 40 }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.perform.suggestion")}
          </Typography>
          {listPerform.map((v, i) => {
            return findObject(performs, "value", v.value) ? null : (
              <Button
                key={i}
                className="suggestion-button"
                onClick={() => {
                  this.handleSuggestion(v, "perform");
                }}>
                <div className="suggestion-label">{v.label}</div>
                <div className="suggestion-image">
                  <img src={IconSuggestion} height={"10vh"} alt={v.label} />
                </div>
              </Button>
            );
          })}
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <div style={{ width: "55%" }}>
            <InputLabel
              shrink
              id="vacancy"
              style={{ color: palette.primary, marginBottom: 15 }}>
              {t("vacancy:create.jobReq.workEnv.label")}
            </InputLabel>
            <ReactSelect
              className={classMenu}
              classNamePrefix={"work-env"}
              ref={ref => {
                this.creatableRef = ref;
              }}
              openMenuOnFocus={true}
              placeholder={t("vacancy:create.jobReq.workEnv.placeholder")}
              isMulti
              isClearable
              onChange={obj => {
                var newEdit = editVacancy;
                if (obj === null) {
                  this.setState({ workEnvs: [] });
                  newEdit.vacancyRequirementEnvironmentWork = [];
                  
                  this.setState({ otherWorkEnvs: [] });
                  newEdit.otherEnvironments = [];
                } else {
                  this.setState({ workEnvs: obj });
                  var value = obj.map(v => {
                    return v.value;
                  });
                  newEdit.vacancyRequirementEnvironmentWork = value;
                }
                this.setState({ editVacancy: newEdit });
              }}
              options={listWorkEnv}
              styles={creatableSelectStyles}
              value={workEnvs}
            />
          </div>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: "3%" }}
          hidden={editVacancy.vacancyRequirementEnvironmentWork.some(p => p === otherWorkEnvID) ? false : true}
        >
          <div style={{ width: "55%" }}>
            <CreatableSelect
              classNamePrefix={"work-env"}
              placeholder={t("vacancy:create.vacancyDetail.jobFamily.otherPlaceholder")}
              isMulti
              onChange={obj => {
                var newEdit = editVacancy;
                if (obj === null) {
                  this.setState({ otherWorkEnvs: [] });
                  newEdit.otherEnvironments = [];
                } else {
                  this.setState({ otherWorkEnvs: obj });
                  var value = obj.map(v => {
                      return v.value;
                    });
                  newEdit.otherEnvironments = value;
                }
                this.setState({ editVacancy: newEdit });
                data.vacancy_post = newEdit;
              }}
              styles={creatableSelectStyles}
              value={otherWorkEnvs}
            />
          </div>
        </Grid>

        <Grid item lg={9} md={9} sm={9} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.jobReq.workEnv.suggestion")}
          </Typography>
          {listWorkEnv.map((v, i) => {
            return findObject(workEnvs, "value", v.value) ? null : (
              <Button
                key={i}
                className="suggestion-button"
                onClick={() => {
                  this.handleSuggestion(v, "workEnv");
                }}>
                <div className="suggestion-label">{v.label}</div>
                <div className="suggestion-image">
                  <img src={IconSuggestion} height={"10vh"} alt={v.label} />
                </div>
              </Button>
            );
          })}
        </Grid>

        <Grid
          item
          lg={8}
          md={8}
          sm={8}
          style={{ marginTop: "4%", paddingLeft: "54%" }}>
          <Button
            onClick={() => {
              this.setState({ editVacancy: this.props.data });
              this.props.onCancel();
            }}
            style={{
              backgroundColor: "white",
              border: "1px solid #2E69B2",
              borderRadius: "4px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
              color: palette.primary,
              fontSize: "14px",
              textTransform: "none",
              padding: "1px 32px 2px",
            }}>
            {t("vacancy:create.confirmation.btnCancel")}
          </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={4} style={{ marginTop: "4%" }}>
          <Button
            onClick={() =>
              this.cekValidation(editVacancy)
                ? this.props.onSave(editVacancy)
                : null
            }
            style={{
              backgroundColor: palette.primary,
              border: "1px solid #2E69B2",
              borderRadius: "4px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
              color: "white",
              fontSize: "14px",
              textTransform: "none",
              padding: "1px 32px 2px",
            }}>
            {t("vacancy:create.confirmation.btnSave")}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(JobRequirementEdit);
