import React from "react";
import { withTranslation } from "react-i18next";
import { Dialog, LinearProgress, Typography, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { getItem } from "app/utils";
import { OptionDropdown, OptionWork } from "app/components";
const buttonStyle = {
  textTransform: "capitalize",
  border: "1px solid " + palette.primary,
  color: palette.primary,
  margin: "0px 10px",
  padding: "5px 30px",
  fontWeight: "bold",
};
export default withTranslation()(
  class ModalWork extends React.Component {
    state = {
      dataQuestion: [],
      currentAnswered: 0,
      totalLength: 0,
      answered: 0,
      finish: false,
      currentNumber: 0,
      responseAnswer: [],
    };

    getData() {
      this.setState({
        dataQuestion: this.props.dataQuestion,
        currentAnswered: this.state.responseAnswer.length,
        answered:
          (this.state.responseAnswer.length / this.props.dataQuestion.length) *
          100,
        finish: false,
        currentNumber: 0,
        totalLength: this.props.dataQuestion.length,
      });
    }

    setValue(val, index) {
      var value = this.state.responseAnswer;
      var dataQuestion = this.props.dataQuestion;
      if (val.length) {
        value[index] = {
          questionId: dataQuestion[index].questionId,
          responseAnswers: val,
        };
        this.setState({
          responseAnswer: value,
          answered: ((index + 1) / this.state.totalLength) * 100,
          currentAnswered: index + 1,
        });
      } else {
        value[index] = null;
        this.setState({
          responseAnswer: value,
          answered: (index / this.state.totalLength) * 100,
          currentAnswered: index,
        });
      }
    }

    clickNext() {
      console.log(this.state.responseAnswer);
      const { currentNumber, totalLength } = this.state;
      if (currentNumber < totalLength - 1) {
        this.setState({
          finish: currentNumber === totalLength - 2 ? true : false,
          currentNumber: this.state.currentNumber + 1,
        });
      } else {
        this.props.onSave(this.state.responseAnswer);
      }
    }
    clickBack() {
      const { currentNumber } = this.state;
      if (currentNumber > 0) {
        this.setState({
          currentNumber: this.state.currentNumber - 1,
          finish: false,
        });
      }
    }

    render() {
      const { open, t } = this.props;
      const {
        currentAnswered,
        totalLength,
        answered,
        finish,
        currentNumber,
        dataQuestion,
        responseAnswer,
      } = this.state;
      return (
        <Dialog
          id="modal-parent"
          onEnter={() => {
            this.getData();
          }}
          fullScreen
          className={"Custom-Dialog "}
          aria-labelledby="customized-dialog-title"
          disableBackdropClick
          open={open}
          style={{ backgroundColor: "transparent" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              paddingTop: "50px",
            }}>
            <div
              style={{
                width: 900,
                minHeight: 650,
                padding: 30,
                position: "relative",
              }}>
              <button
                onClick={() => {
                  this.props.onClose();
                }}
                style={{
                  width: 28,
                  height: 28,
                  color: "#C4C4C4",
                  borderRadius: "50%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  border: "none",
                  cursor: "pointer",
                }}>
                X
              </button>
              <div
                style={{
                  backgroundColor: "white",
                  width: 900,
                  minHeight: 580,
                  boxSizing: "border-box",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}>
                <div
                  style={{
                    height: 20,
                    backgroundColor: palette.primary,
                    padding: "20px 20px",
                    color: "white",
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    fontSize: 18,
                  }}>
                  {t("cv:workReady.form.title")}
                </div>
                <div
                  style={{
                    backgroundColor: "rgba(81, 200, 244,0.3)",
                    padding: "10px 20px",
                    color: palette.primary,
                    fontWeight: "lighter",
                    fontSize: 14,
                  }}>
                  {t("cv:workReady.form.subTitle")}
                </div>
                <div style={{ padding: 20 }}>
                  {dataQuestion[currentNumber] ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: palette.primary,
                        height: 450,
                        overflowY: "auto",
                      }}>
                      <span
                        style={{
                          backgroundColor: palette.darkGrey,
                          color: "white",
                          borderRadius: "50%",
                          padding: "7px 12.2px",
                          height: 17,
                        }}>
                        {currentNumber + 1}
                      </span>
                      <div
                        style={{
                          padding: "7px 15px",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <span style={{ fontWeight: "lighter" }}>
                          {getItem("lang") === "en"
                            ? dataQuestion[currentNumber].questionTextEng
                            : dataQuestion[currentNumber].questionTextIna}
                        </span>
                        <span
                          style={{
                            fontWeight: "lighter",
                            color: "#515D6E",
                            fontStyle: "italic",
                            fontSize: 12,
                          }}>
                          *
                          {dataQuestion[currentNumber].categoryQuestion.indexOf(
                            "Multiple"
                          ) >= 0
                            ? t("cv:workReady.form.multiple")
                            : t("cv:workReady.form.single")}
                          {dataQuestion[currentNumber].maxAnswer ? (
                            <>
                              <br />
                              {t("cv:workReady.form.maximum").replace(
                                "MAX",
                                dataQuestion[currentNumber].maxAnswer
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                        {dataQuestion[currentNumber].categoryQuestion ===
                          "MultipleDropdown" && (
                          <>
                            <OptionDropdown
                              maximum={3}
                              onChange={v => {
                                this.setValue(v, currentNumber);
                              }}
                              data={dataQuestion[currentNumber]}
                              value={responseAnswer[currentNumber]}
                            />
                          </>
                        )}
                        {dataQuestion[currentNumber].categoryQuestion !==
                          "MultipleDropdown" && (
                          <>
                            <OptionWork
                              onChange={v => {
                                this.setValue(v, currentNumber);
                              }}
                              multiple={
                                dataQuestion[
                                  currentNumber
                                ].categoryQuestion.indexOf("MultipleChoice") >=
                                0
                                  ? true
                                  : false
                              }
                              value={
                                responseAnswer[currentNumber]
                                  ? responseAnswer[currentNumber]
                                  : {}
                              }
                              options={dataQuestion[currentNumber].workAnswers}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  bottom: 0,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  width: "100%",
                  boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  padding: "20px 30px 20px 100px",
                }}>
                <div style={{ width: 200 }}>
                  <LinearProgress
                    className={"freetext-progress"}
                    variant="determinate"
                    value={answered}
                  />
                  <Typography
                    style={{
                      color: "#ccc",
                    }}>
                    {currentAnswered +
                      "/" +
                      totalLength +
                      " " +
                      t("basictest:answered")}
                  </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {currentNumber > 0 ? (
                    <Button
                      onClick={() => {
                        this.clickBack();
                      }}
                      style={buttonStyle}>
                      {t("button.previous")}
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => {
                      this.clickNext();
                    }}
                    disabled={responseAnswer[currentNumber] ? false : true}
                    style={{
                      ...buttonStyle,
                      backgroundColor: responseAnswer[currentNumber]
                        ? palette.primary
                        : palette.darkGrey,
                      borderColor: responseAnswer[currentNumber]
                        ? palette.primary
                        : palette.darkGrey,
                      color: "white",
                      opacity: responseAnswer[currentNumber] ? 1 : 0.5,
                    }}>
                    {finish ? t("button.save") : t("button.next")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      );
    }
  }
);
