import React from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { TextField } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import dataSchedule from "../data-schedule";
import {
  AppHistory,
  GetLocalItem,
  SetLocalItem,
  numberRegex,
  nameRegex,
  getItem,
  setItem,
} from "app/utils";
import { palette } from "assets/css/main";
const label = {
  color: palette.primary,
  textTransform: "capitalize",
  fontWeight: "lighter",
  marginBottom: 5,
};
const text = {
  color: palette.primary,
  margin: "0px 0px 30px",
  fontSize: 18,
};
export default withTranslation("interview-schedule")(
  class ComponentDescription extends React.Component {
    state = {};
    constructor(props) {
      super(props);
      this.submitForm = this.onSubmit.bind(this);
      this.state = { ...dataSchedule.interview };
    }
    componentDidMount() {
      const {
        location: { state: data },
      } = AppHistory;
      dataSchedule.setInterview({ vacancyId: data.id_vacancy });
      var c = GetLocalItem("SCH_D" + data.id_vacancy);
      if (c) {
        if (!getItem("SCH_D" + data.id_vacancy)) {
          dataSchedule.setInterview(c);
          this.setState(c);
          setItem("SCH_D" + data.id_vacancy, true);
        }
      }
      ValidatorForm.addValidationRule("minLength", value => {
        if (value.length < 5) {
          return false;
        }
        return true;
      });
      ValidatorForm.addValidationRule("maxLength", value => {
        if (value.length > 15) {
          return false;
        }
        return true;
      });
    }

    componentWillUnmount() {
      // remove rule when it is not needed
      ValidatorForm.removeValidationRule("minLength");
      ValidatorForm.removeValidationRule("maxLength");
    }
    onSubmit(e) {
      const {
        location: { state: data },
      } = AppHistory;
      e.preventDefault();
      this.props.submitted();
      SetLocalItem("SCH_D" + data.id_vacancy, dataSchedule.interview);
    }
    handleChange(obj) {
      this.setState(obj);
      dataSchedule.setInterview(obj);
    }
    render() {
      const { t, edit } = this.props;
      const step = t("step", { returnObjects: true });

      return (
        <div style={{ width: "600px" }}>
          {edit === false ? (
            <>
              <p style={{ fontSize: 24, margin: "5px 0px 30px" }}>{step[0]}</p>
              <div>
                <p style={label}>{t("form.label.title").toLowerCase()}</p>
                <p style={text}>{this.state.interviewTitle}</p>
              </div>
              <div>
                <p style={label}>{t("form.label.location").toLowerCase()}</p>
                <p style={text}>{this.state.location}</p>
              </div>
              <div>
                <p style={label}>{t("form.label.cpName").toLowerCase()}</p>
                <p style={text}>{this.state.contactName}</p>
              </div>
              <div>
                <p style={label}>{t("form.label.cpPhone").toLowerCase()}</p>
                <p style={text}>{this.state.contactNumber}</p>
              </div>
              <div>
                <p style={label}>{t("form.label.notes").toLowerCase()}</p>
                <p style={text}>{this.state.notes}</p>
              </div>
            </>
          ) : (
            <>
              {edit === undefined ? (
                <>
                  <p style={{ fontSize: 24, margin: 0 }}>{t("form.title")}</p>
                  <p style={{ fontSize: 14, margin: "5px 0px 50px" }}>
                    {t("form.description")}
                  </p>
                </>
              ) : (
                <>
                  <p style={{ fontSize: 24, margin: "5px 0px 30px" }}>
                    {step[0]}
                  </p>
                </>
              )}
              <ValidatorForm
                className="medium mb-40"
                onSubmit={this.submitForm}>
                <TextValidator
                  validators={["required"]}
                  errorMessages={[t("form.validator.required.title")]}
                  placeholder={t("form.placeholder.title")}
                  style={{
                    width: "100%",
                  }}
                  onChange={e => {
                    this.handleChange({
                      interviewTitle: e.currentTarget.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={t("form.label.title")}
                  className="Field"
                  value={this.state.interviewTitle}
                />
                <TextValidator
                  validators={["required"]}
                  errorMessages={[t("form.validator.required.location")]}
                  placeholder={t("form.placeholder.location")}
                  style={{
                    width: "100%",
                  }}
                  onChange={e => {
                    this.handleChange({ location: e.currentTarget.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={t("form.label.location")}
                  className="Field"
                  value={this.state.location}
                />
                <TextValidator
                  validators={["required"]}
                  errorMessages={[t("form.validator.required.cpName")]}
                  placeholder={t("form.placeholder.cpName")}
                  style={{
                    width: "100%",
                  }}
                  onChange={e => {
                    var char = e.currentTarget.value;
                    nameRegex.test(char)
                      ? this.handleChange({
                          contactName: e.currentTarget.value,
                        })
                      : e.preventDefault();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={t("form.label.cpName")}
                  className="Field"
                  value={this.state.contactName}
                />
                <TextValidator
                  validators={["required", "minLength", "maxLength"]}
                  errorMessages={[
                    t("form.validator.required.cpPhone"),
                    t("form.validator.length.min"),
                    t("form.validator.length.max"),
                  ]}
                  placeholder={t("form.placeholder.cpPhone")}
                  style={{
                    width: "100%",
                  }}
                  onChange={e => {
                    var char = e.currentTarget.value;
                    numberRegex.test(char)
                      ? this.handleChange({ contactNumber: char })
                      : e.preventDefault();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={t("form.label.cpPhone")}
                  className="Field"
                  value={this.state.contactNumber}
                />
                <TextField
                  style={{
                    width: "100%",
                  }}
                  value={this.state.notes}
                  className="Field"
                  label={t("form.label.notes")}
                  multiline={true}
                  rows={2}
                  rowsMax={8}
                  onChange={e => {
                    this.handleChange({ notes: e.currentTarget.value });
                  }}
                  placeholder={t("form.placeholder.notes")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <button
                  type="submit"
                  id="btn-submit"
                  style={{ display: "none" }}
                />
              </ValidatorForm>
            </>
          )}
        </div>
      );
    }
  }
);
