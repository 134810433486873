import { Numbering } from "app/components";
import { Grid } from "@material-ui/core";
import {
  AppHistory,
  getItem,
  GetLocalItem,
  removeObject,
  setItem,
  SetLocalItem,
} from "app/utils";
import { IconMin, IconPlus } from "assets/img";
import React from "react";
import { withTranslation } from "react-i18next";
import dataSchedule from "../data-schedule";
import { v4 as uuidv4 } from "uuid";
import HgDatePicker from "app/components/general/HgDatePicker";
import { CustomTimePicker } from "app/components/general/CustomDate";
import Select from "react-select";
import Moment from "react-moment";
import "moment/locale/id";
import { palette } from "assets/css/main";
import { IconButton, Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";

import {  DialogueInfo } from "app/components";
const options = [
  { value: 30, label: "30" },
  { value: 45, label: "45" },
  { value: 60, label: "60" },
  { value: 120, label: "120" },
  { value: 180, label: "180" },
  { value: 200, label: "200" },
];

export default withTranslation("interview-schedule")(
  class ComponentTimeSlot extends React.Component {
    mindate = new Date().setDate(new Date().getDate() + 1);
    constructor(props) {
      super(props);
      this.state = {
        listInterviewDetail: dataSchedule.listInterviewDetail,
        date: "",

        over: false
      };
    }
    componentDidMount() {
      const { listInterviewDetail } = this.state;
      const {
        location: { state: data },
      } = AppHistory;
      var c = GetLocalItem("SCH_T" + data.id_vacancy);
      if (c) {
        if (!getItem("SCH_T" + data.id_vacancy)) {
          setItem("SCH_T" + data.id_vacancy, true);
          dataSchedule.listInterviewDetail = c;
          this.setState({ listInterviewDetail: c });
        }
      } else {
        dataSchedule.setInterview({ vacancyId: data.id_vacancy });
        if (!listInterviewDetail.length) {
          this.handleAdd(0);
        }
      }
    }

    handleTimeSelect(date, time = {}, dur, k) {
      var d = date ? new Date(date) : new Date(this.mindate);
      var tmp = new Date(d);
      var tmp2 = new Date(d);
      var h, m;
      if (time._isAMomentObject) {
        h = time._d.getHours();
        m = time._d.getMinutes();
        tmp.setHours(h);
        tmp.setMinutes(m);
        tmp2.setHours(h);
        tmp2.setMinutes(tmp.getMinutes() + dur.value);
      } else {
        h = d.getHours();
        m = d.getMinutes();
        tmp.setHours(h);
        tmp.setMinutes(m);
        tmp2.setMinutes(tmp.getMinutes() + dur.value);
      }
      this.handleChange("date", k, tmp);
      this.handleChange("duration", k, dur.value);
      this.handleChange("timeStart", k, tmp);
      this.handleChange("timeEnd", k, tmp2);
    }

    handleAdd() {
      var obj = {
        index: uuidv4(),
        tmpDur: { value: 45, label: "45" },
        duration: "30",
        date: "",
        timeStart: "",
        timeEnd: "",
        isEdit: true,
        timezone: "WIB",
      };
      dataSchedule.setListInterview(obj);
      this.setState({
        listInterviewDetail: dataSchedule.listInterviewDetail,
      });
    }

    handleMin(k) {
      var obj = removeObject(dataSchedule.listInterviewDetail, "index", k);
      dataSchedule.listInterviewDetail = obj;
      this.setState({
        listInterviewDetail: dataSchedule.listInterviewDetail,
      });
    }

    handleChange(key, idx, val) {
      var tmp = this.state.listInterviewDetail;
      tmp[idx][key] = val;
      dataSchedule.listInterviewDetail[idx][key] = val;
      this.setState({ listInterviewDetail: tmp });
    }

    onSubmit(e) {
      this.setState({over: false})
     var boleh = 0
      const { listInterviewDetail } = this.state
      listInterviewDetail.forEach((list,i) => {
        listInterviewDetail.forEach((listcompare,j) => {
         
          if(i !== j){
            
            if(!(( new Date(list.timeEnd) < new Date(listcompare.timeStart))||(new Date(list.timeStart) > new Date(listcompare.timeEnd)))){
                  boleh = 1
                  this.setState({over: true})
                    }
          }
        })

      })

      if (boleh === 1){

        // alert("OVERLAPPING");
      } else{
             this.props.submitted()
        const {
              location: { state: data },
            } = AppHistory;
            e.preventDefault();
            SetLocalItem("SCH_T" + data.id_vacancy, dataSchedule.listInterviewDetail);
      }
   
       

     
    }

    clikOkOverlapTime() {
      this.setState({
        over : false,
      });
   
    }

    render() {
      const { t, edit } = this.props;
      const desc = t("slot.description", { returnObjects: true });
      const step = t("step", { returnObjects: true });

      const { listInterviewDetail } = this.state;

      return (
      <Grid container justify="center" >

       <DialogueInfo
          title={t("overlaptime.title")}
          content={t("overlaptime.message")}
          open={this.state.over}
          onClose={() => { this.clikOkOverlapTime();
          }}
        />
        <div style={{ width: "600px" }}>
          {edit === false ? (
            <>
              <p style={{ fontSize: 24, margin: 0 }}>{step[1]}</p>
              <p style={{ fontSize: 14, margin: "15px 0px" }}>{desc[0]}</p>
              <div>
                {listInterviewDetail.map((v, k) => {
                  return (
                    <div
                      key={"prev" + k}
                      style={{ display: "flex", alignItems: "flex-start" }}>
                      <div style={{ padding: "5px 0px" }}>
                        <div
                          style={{
                            width: 8,
                            height: 8,
                            borderRadius: "50%",
                            backgroundColor: palette.primary,
                          }}></div>
                      </div>
                      <div style={{ padding: "0px 10px" }}>
                        <div>
                          <Moment
                            date={v.timeStart}
                            format={"dddd, DD MMMM yyyy"}
                            locale={getItem("lang") === "id" ? "id" : "en"}
                          />
                        </div>
                        <div>
                          <Moment date={v.timeStart} format={"HH:mm"} /> -{" "}
                          <Moment date={v.timeEnd} format={"HH:mm"} />
                          {" " + v.timezone}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              {edit === undefined ? (
                <p style={{ fontSize: 24, margin: 0 }}>{t("slot.title")}</p>
              ) : (
                <p style={{ fontSize: 24, margin: 0 }}>{step[1]}</p>
              )}
              <div style={{ marginBottom: 50 }}>
                {desc.map((r, i) => {
                  return (
                    <p key={i} style={{ fontSize: 14, margin: "5px 0px" }}>
                      {r}
                    </p>
                  );
                })}
              </div>
              <div>
                {listInterviewDetail.map((v, k) => {
                  return (
                    <div
                      key={k}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        position: "relative",
                        backgroundColor:""
                      }}>
                      <Numbering number={k + 1} />
                      <div style={{ padding: "0px 10px" }}>
                        <HgDatePicker
                          disabled={v.isEdit ? false : true}
                          label={t("form.label.date")}
                          className="Schedule popup"
                          style={{ width: 100 }}
                          minDate={new Date(this.mindate)}
                          value={v.date ? new Date(v.date) : ""}
                          format="dd/MM/yyyy"
                          onChange={e => {
                            this.handleTimeSelect(e, e, v.tmpDur, k);
                          }}
                        />
                      </div>
                      <div style={{ padding: "0px 10px", height: 81 }}>
                        <label style={{ fontWeight: "lighter", fontSize: 14 }}>
                          {t("form.label.duration")}
                        </label>
                        <Select
                          isDisabled={v.isEdit ? false : true}
                          className="duration"
                          classNamePrefix="duration"
                          onChange={op => {
                            this.handleChange("tmpDur", k, op);
                            this.handleTimeSelect(v.date, {}, op, k);
                          }}
                          value={v.tmpDur}
                          options={options}
                          placeholder={t("form.placeholder.duration")}
                        />
                      </div>
                      <div style={{ padding: "0px 5px 0px 10px", height: 81 }}>
                        <label style={{ fontWeight: "lighter" }}>
                          {t("form.label.time")}
                        </label>
                        <CustomTimePicker
                          disabled={v.isEdit ? false : true}
                          value={v.timeStart ? new Date(v.timeStart) : ""}
                          onChange={x => {
                            this.handleTimeSelect(v.date, x, v.tmpDur, k);
                          }}
                        />
                      </div>
                      -
                      <div style={{ padding: "0px 20px 0px 5px", height: 81 }}>
                        <label style={{ fontWeight: "lighter" }}>&nbsp;</label>
                        <div
                          style={{
                            padding: "9px 28px",
                            borderRadius: 4,
                            backgroundColor: "rgba(46, 105, 178, 0.1) ",
                            minHeight: 21,
                            fontWeight: "bold",
                            fontSize: 18,
                            minWidth: 40,
                          }}>
                          {v.timeEnd ? (
                            <Moment date={v.timeEnd} format="HH:mm" />
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      {k < listInterviewDetail.length - 1 ? (
                        v.isEdit ? (
                          <img
                            onClick={() => this.handleMin(v.index)}
                            src={IconMin}
                            style={{
                              height: 46,
                              marginLeft: 7,
                              marginRight: 10,
                              opacity: 0.5,
                            }}
                            alt={"min"}
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        <img
                          onClick={() => this.handleAdd(k + 1)}
                          src={IconPlus}
                          style={{
                            height: 46,
                            opacity: 0.5,
                            marginLeft: 7,
                            marginRight: 10,
                          }}
                          alt={"add"}
                        />
                      )}
                      {!v.isEdit ? (
                        <Tooltip title={t("messages.editFalseTimeSlot")} arrow>
                          <IconButton style={{ marginBottom: 3 }}>
                            <Info
                              style={{
                                fontSize: 32,
                                color: palette.secondary,
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </div>
                  );
                })}
                <button
                  type="submit"
                  id="btn-submit"
                  style={{ display: "none" }}
                  onClick={e => {

                   
                      this.onSubmit(e);
                    
                    
                  }}
                />
              </div>
            </>
          )}
        </div>
        </Grid>
      );
    }
  }
);
