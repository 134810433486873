import React from "react";
import { palette } from "assets/css/main";
import { Grid, TextField, InputLabel, Button } from "@material-ui/core";
import {
  IconArrowRight,
  IconArrowRightFade,
  IconMin,
  IconPlus,
  IconPlusFade,
} from "assets/img";
import "./custom.css";

export default class MultipleTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fade: true,
      tempField: props.fieldData,
      label: props.label,
      placeholder: props.placeholder,
    };
    this.translate = this.props.t;
  }

  componentDidMount() {
    var last = this.state.tempField.length - 1;

    if (this.state.tempField.length > 0 && this.state.tempField[last] !== "") {
      setTimeout(() => this.filledTextField(last), 100);
    }

    if (this.state.tempField.length < 2 && this.state.tempField[0] === "") {
      this.defaultTextField(0);
    }
  }

  typinghandler(obj, idx) {
    var btn = document.getElementById("BtnPlus" + idx);
    var field = this.state.tempField;

    if (obj.length === 0) {
      this.defaultTextField(idx);
    } else {
      if (btn !== null) {
        this.filledTextField(idx);
      }
    }

    field[idx] = obj;
    this.setState({ tempField: field });
    this.props.onChange(field);
  }

  onClickPlus(idx) {
    var field = this.state.tempField;
    field.push("");
    this.setState({ tempField: field });
    this.props.onChange(field);

    // UI handling
    this.setState({ fade: true });
    setTimeout(() => this.defaultTextField(idx + 1), 100);
  }

  onClickMin(obj) {
    var field = this.state.tempField;

    field.splice(obj, 1);
    this.setState({ tempField: field });
    this.props.onChange(field);
  }

  filledTextField(idx) {
    try {
      var btn = document.getElementById("BtnPlus" + idx);
      btn.disabled = false;
      btn.classList.remove("Mui-disabled");
      this.setState({ fade: false });
    } catch (error) {}
  }

  // defaultState() {
  //   this.setState({fade: true});
  // }

  defaultTextField(idx) {
    var btn = document.getElementById("BtnPlus" + idx);
    btn.disabled = true;
    btn.classList.add("Mui-disabled");
    this.setState({ fade: true });
  }

  render() {
    const { fade, tempField, label, placeholder } = this.state;
    return (
      <Grid container style={{ ...this.props.style }}>
        <Grid item lg={12} md={12} sm={12}>
          <InputLabel
            shrink
            id="MultipleTextField"
            style={{ color: palette.primary, marginBottom: "-5vh" }}>
            {label}
          </InputLabel>
        </Grid>

        {tempField.map((v, i) => {
          return (
            <Grid container key={i}>
              {tempField.length - 1 === i ? (
                // => default textfield
                <Grid container>
                  <Grid item lg={1} md={1} sm={1}>
                    <img
                      style={{ paddingLeft: "1vw", paddingTop: "2vw" }}
                      src={fade ? IconArrowRightFade : IconArrowRight}
                      alt="Arrow"
                      height={"20vh"}
                    />
                  </Grid>
                  <Grid item lg={10} md={10} sm={10}>
                    <TextField
                      style={{ width: "100%" }}
                      // onInput={(e) => {this.typinghandler(e.target.value, i)}}
                      onChange={e => {
                        this.typinghandler(e.target.value, i);
                      }}
                      autoFocus={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={placeholder}
                      className="Field"
                      value={v}
                    />
                  </Grid>
                  <Grid item lg={1} md={1} sm={1}>
                    <Button
                      id={"BtnPlus" + i}
                      onClick={() => {
                        this.onClickPlus(i);
                      }}>
                      <img
                        style={{ paddingTop: "1vw" }}
                        src={fade ? IconPlusFade : IconPlus}
                        alt="Plus"
                        height={"50vh"}
                      />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                // => filled textfield
                <Grid container>
                  <Grid item lg={1} md={1} sm={1}>
                    <img
                      style={{ paddingLeft: "1vw", paddingTop: "2vw" }}
                      src={IconArrowRight}
                      alt="Arrow"
                      height={"20vh"}
                    />
                  </Grid>
                  <Grid item lg={10} md={10} sm={10}>
                    <TextField
                      // readOnly
                      onChange={e => {
                        this.typinghandler(e.target.value, i);
                      }}
                      style={{ width: "100%" }}
                      autoFocus={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={placeholder}
                      className="Field"
                      value={v}
                    />
                  </Grid>
                  <Grid item lg={1} md={1} sm={1}>
                    <Button
                      onClick={() => {
                        this.onClickMin(i);
                      }}>
                      <img
                        style={{ paddingTop: "1vw" }}
                        src={IconMin}
                        alt="Plus"
                        height={"50vh"}
                      />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
