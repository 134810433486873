import React from "react";
import {
  getItem,
  RequestPut,
  General,
  removeArrayWithIndex,
  RequestGet,
  findObject,
} from "app/utils";
import { palette, COLOR } from "assets/css/main";
import { Add } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
} from "@material-ui/core";
import { PRIMARY_BUTTON } from "assets/css/main";
import { ConfirmFooter } from "./confirmation-footer";
import alertData from "app/components/alert/alert-data";
import data from "app/pages/applicant/initialize/bio-data/data";
import DialogueConfirm from "app/components/modal/confirm";
import { DialogueInfo } from "app/components";
import { IconEditCV, IconDeleteCV } from "assets/img";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import DatePickerValidator from "app/components/general/DatePickerValidator";
import SelectSearch from "react-select";

class ConfirmationFormalEducation extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
    this.state = {
      biodata: props.data,
      data: [],
      ...data.formal_education,
      ...JSON.parse(getItem("user_info")),
      anchorEl: null,
      open: false,
      opendelete: false,
      opencantdelete: false,
      isedit: false,
      tmpGraduate: null,
      listUniversity: [],
      listMajor: [],
      institutionId: null,
      openValidation: false,
    };
    General.visible = false;
    data.formal_education.userId = getItem("user_id");
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("nem", value => {
      if (value < 1 || value > 100) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("gpa", value => {
      if (value < 1 || value > 4) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("selectRequired");
    ValidatorForm.removeValidationRule("nem");
    ValidatorForm.removeValidationRule("gpa");
  }

  handleChange(obj, isDegreeOrLocation) {
    if (
      isDegreeOrLocation === "isDegree" ||
      isDegreeOrLocation === "isInstitutionLocation"
    ) {
      this.setState({
        institution: "",
        major: "",
      });
    }
    this.setState(obj);
    data.setFormalEducation(obj);
    var listformaledu = {
      listformaledu: {
        ...this.state.biodata.listFormalEducation,
        ...data.listFormalEducation,
      },
    };
    this.setState({
      biodata: { ...this.state.biodata, ...listformaledu },
    });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, opendelete: false });
  };

  handleClickOpen = (key = -1) => {
    this.getUniversity(key);
    if (key > -1) {
      this.setState(
        {
          open: true,
          ...this.state.biodata.listFormalEducation[key],
          isedit: true,
          k: key,
        },
        () => {
          this.setState({
            tmpGraduate: new Date(this.state.yearGraduate + "-01-01"),
          });
        }
      );
    } else {
      this.setState({
        degree: "",
        institutionLocation: "",
        institution: "",
        major: "",
        grade: "",
        nim: "",
        tmpGraduate: "",
        open: true,
        isedit: false,
      });
    }
  };

  handleClickDelete = key => {
    this.setState({ opendelete: true, k: key });
  };

  getLastEducation(key) {
    var last = "SDSMPSMA";
    if (last.indexOf(key) > -1) {
      return true;
    } else {
      return false;
    }
  }

  addformaleducation() {
    const { t } = this.props;
    if (
      data.formal_education.degree === "S1" &&
      data.formal_education.institutionLocation === "indonesia"
    ) {
      if (data.formal_education.institution === "") {
        alertData.show(t("biodata:validation.required.university"), "error");
        return false;
      }
      if (data.formal_education.major === "") {
        alertData.show(t("biodata:validation.required.major"), "error");
        return false;
      }
    }
    data.formal_education.grade = parseFloat(this.state.grade);
    data.formal_education.yearGraduate = this.state.tmpGraduate.getFullYear();
    data.listFormalEducation.push(data.formal_education);
    data.setFormalEducation({
      degree: "",
      institutionLocation: "",
      institution: "",
      major: "",
      nim: "",
      grade: "",
      yearGraduate: "",
    });

    var objformaledu = {
      degree: "",
      institutionLocation: "",
      institution: "",
      institutionId: "",
      major: "",
      majorId: "",
      nim: "",
      grade: "",
      yearGraduate: "",
    };
    data.listFormalEducation.forEach(formaledu => {
      var listMajorbyId = findObject(
        this.state.listMajor,
        "value",
        formaledu.major
      );
      objformaledu = {
        degree: formaledu.degree,
        institutionLocation: formaledu.institutionLocation,
        institution: formaledu.institution,
        institutionId: formaledu.institutionId,
        major: listMajorbyId !== null ? listMajorbyId.label : formaledu.major,
        majorId: listMajorbyId !== null ? listMajorbyId.value : formaledu.major,
        nim: formaledu.nim,
        grade: formaledu.grade,
        yearGraduate: formaledu.yearGraduate,
      };
    });
    this.state.biodata.listFormalEducation.push(objformaledu);
    data.listFormalEducation = this.state.biodata.listFormalEducation;
    //alertData.show("Add Success");
    this.setState({ open: false });
  }

  editformaleducation() {
    const { t } = this.props;
    var key = this.state.k;
    var stateListData = this.state.biodata.listFormalEducation;
    stateListData[key].degree = this.state.degree;
    stateListData[key].institutionLocation = this.state.institutionLocation;
    if (
      this.state.degree !== "S1" ||
      this.state.institutionLocation !== "indonesia"
    ) {
      stateListData[key].institution = this.state.institution;
      stateListData[key].institutionId = "";
      stateListData[key].major = this.state.major;
      stateListData[key].majorId = "";
    } else {
      stateListData[key].institution = this.state.institution.label;
      stateListData[key].institutionId = this.state.institution.value;
      stateListData[key].major = this.state.major.label;
      stateListData[key].majorId = this.state.major.value;

      if (stateListData[key].institutionId === undefined) {
        alertData.show(t("biodata:validation.required.university"), "error");
        return false;
      }
      if (stateListData[key].majorId === undefined) {
        alertData.show(t("biodata:validation.required.major"), "error");
        return false;
      }
    }
    stateListData[key].nim = this.state.nim;
    stateListData[key].grade = parseFloat(this.state.grade);
    stateListData[key].yearGraduate = this.state.tmpGraduate.getFullYear();

    data.formal_education.degree = this.state.degree;
    data.formal_education.institutionLocation = this.state.institutionLocation;
    if (
      this.state.degree !== "S1" ||
      this.state.institutionLocation !== "indonesia"
    ) {
      data.formal_education.institutionId = this.state.institution;
      data.formal_education.majorId = this.state.major;
    } else {
      data.formal_education.institutionId = this.state.institution.value;
      data.formal_education.majorId = this.state.major.value;
    }
    data.formal_education.grade = parseFloat(this.state.grade);
    data.formal_education.yearGraduate = this.state.tmpGraduate.getFullYear();

    data.listFormalEducation = stateListData;

    this.setState(
      { open: false, biodata: {...this.state.biodata, listFormalEducation: stateListData } },
      () => {
        alertData.show("Edit Success");
      }
    );
  }

  deleteformaleducation() {
    var key = this.state.k;
    var lengthlistformal = this.state.biodata.listFormalEducation.length;
    if (lengthlistformal === 1) {
      this.setState({
        opencantdelete: true,
        opendelete: false,
      });
    } else {
      var listdataFormal = removeArrayWithIndex(
        this.state.biodata.listFormalEducation,
        key
      );
      data.listFormalEducation = listdataFormal;

      const tempArray = this.state.biodata;
      tempArray.listFormalEducation = listdataFormal;
      this.props.updateData(tempArray)

      this.setState(
        {
          opendelete: false,
          biodata: {...this.state.biodata, listFormalEducation: listdataFormal },
        },
        () => {
          alertData.show("Delete Success");
        }
      );
    }
  }

  onSubmit(isEdit) {
    if (isEdit) {
      this.editformaleducation();
    } else {
      this.addformaleducation();
    }
  }

  submitdata() {
    var datasubmit = {};
    datasubmit.personalInfo = data.personalInfo;
    datasubmit.listFormalEducation = this.state.biodata.listFormalEducation;
    datasubmit.listOrganization = this.state.biodata.listOrganization;
    datasubmit.listInformalEducation = this.state.biodata.listInformalEducation;
    datasubmit.listWorks = this.state.biodata.listWorks.length > 0 ? this.state.biodata.listWorks : data.listWorks;

    const {address,description,birthPlace,phoneNumber} = this.state.biodata.personalInfo;
    const {photo} = this.props.data.personalInfo;
    
    if (photo === null || address === null || description === null || birthPlace === null || phoneNumber === null) {
      this.setState({
        openValidation: true,
      });
    }else{
      RequestPut("biodata", datasubmit)
        .then(() => {
          window.location.reload();
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  getUniversity(key = null) {
    RequestGet("University")
      .then(resUniversity => {
        var university = resUniversity.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.university,
          };
        });
        this.setState({
          ...this.state,
          listUniversity: university,
        });
        if (key !== null) {
          if (key > -1) {
            var listInstitutionbyId = [];
            var listformaledu = this.state.biodata.listFormalEducation[key];
            if (
              listformaledu.institution !== null ||
              listformaledu.institution !== ""
            ) {
              listInstitutionbyId = findObject(
                university,
                "value",
                listformaledu.institutionId
              );
              if (listInstitutionbyId !== null) {
                this.setState({
                  institution: listInstitutionbyId,
                });
              } else {
                this.setState({
                  institution: listformaledu.institution,
                });
              }
            }
          }
        }
        this.getMajor(this.state.biodata.listFormalEducation, key);
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeUniversity(institutionId) {
    var listInstitutionbyId = findObject(
      this.state.listUniversity,
      "value",
      institutionId.institutionId
    );
    RequestGet("University/Major/" + institutionId.institutionId, {
      loading: true,
    })
      .then(resMajor => {
        var obj = {};
        obj = { 
          institution: listInstitutionbyId.label,
          institutionId: institutionId.institutionId,
         };
        this.setState(obj);
        data.setFormalEducation(obj);

        var major = resMajor.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.major,
          };
        });
        this.setState({
          ...this.state,
          major: " ",
          listMajor: major,
          institution: listInstitutionbyId,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeMajor(majorId) {
    var listMajorbyId = findObject(
      this.state.listMajor,
      "value",
      majorId.majorId
    );

    var obj = {};
    obj = { major: majorId.majorId };
    this.setState(obj);
    data.setFormalEducation(obj);
    this.setState({
      major: listMajorbyId,
    });
  }

  getMajor(listFormalEducation, key = null) {
    listFormalEducation.forEach(v => {
      var university = v.institution;
      university = findObject(
        this.state.listUniversity,
        "label",
        v.institution
      );
      if (university !== null) {
        RequestGet("University/Major/" + university.key, {
          loading: true,
        })
          .then(resMajor => {
            var major = resMajor.data.map(v => {
              return {
                key: v.id,
                value: v.id,
                label: v.major,
              };
            });
            this.setState({
              listMajor: major,
            });
            if (key !== null) {
              if (key > -1) {
                var listMajorbyId = [];
                var listformaledu = this.state.biodata.listFormalEducation[key];
                if (
                  listformaledu.institution !== null ||
                  listformaledu.institution !== ""
                ) {
                  listMajorbyId = findObject(
                    major,
                    "value",
                    listformaledu.majorId
                  );
                  if (listMajorbyId !== null) {
                    this.setState({
                      major: listMajorbyId,
                    });
                  } else {
                    this.setState({
                      major: listformaledu.major,
                    });
                  }
                }
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  }

  render() {
    const {
      biodata: { listFormalEducation },
      open,
      opendelete,
      opencantdelete,
      isedit,
      listUniversity,
      listMajor,
      openValidation,
    } = this.state;
    const { t } = this.props;
    const universityLocations = t("biodata:options.universityLocation", {
      returnObjects: true,
    });
    return (
      <div
        style={{
          fontWeight: "lighter",
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          padding: "0px 50px",
          color: palette.primary,
        }}>
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            flexDirection: "row",
            alignItems: "center",
            color: palette.primary,
            fontSize: 20,
            fontWeight: "bolder",
            justifyContent: "space-between",
          }}>
            <div>{t("biodata:CVformalEducation")}
              <span style={{
                  color: COLOR.red,
                  fontWeight: "bolder",
                  marginLeft: "5px",
                }}>*</span>
            </div>
          <div>
            <Button
              onClick={this.handleClickOpen}
              size="small"
              variant="contained"
              style={{
                ...PRIMARY_BUTTON,
                fontWeight: "bold",
                marginLeft: 46,
                backgroundColor: "white",
                color: palette.primary,
                //height: 35,
                fontSize: 11,
                // width: 100,
              }}>
              <Add style={{ width: "0.8em", height: "0.8em" }}></Add> &nbsp;{" "}
              {t("biodata:CVbtnAdd")}
            </Button>
          </div>
        </div>
        <Dialog
          fullScreen
          className={"Custom-Dialog-cv"}
          aria-labelledby="customized-dialog-title"
          disableBackdropClick
          open={open}
          style={{ backgroundColor: "transparent" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              paddingTop: "50px",
            }}>
            <div
              style={{
                width: 800,
                minHeight: 650,
                padding: 30,
                position: "relative",
              }}>
              <button
                onClick={() => {
                  this.handleClose();
                }}
                style={{
                  width: 28,
                  height: 28,
                  color: "#C4C4C4",
                  borderRadius: "50%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  border: "none",
                  cursor: "pointer",
                }}>
                X
              </button>
              <ValidatorForm
                style={{ backgroundColor: "white", borderRadius: 10 }}
                onSubmit={() => {
                  this.onSubmit(isedit);
                }}>
                <DialogTitle
                  id="form-dialog-title"
                  style={{
                    color: palette.primary,
                    borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  }}>
                  {t("biodata:CVformalEducation")}
                </DialogTitle>
                <DialogContent>
                  <div
                    value={this.state.degree}
                    style={{
                      width: "100%",
                      marginLeft: "1%",
                      marginTop: "3%",
                      fontSize: 20,
                    }}>
                    <div required={true} value={this.state.degree}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {t("biodata:form.field.level")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <SelectValidator
                        style={{
                          color: palette.primary,
                          fontWeight: "bold",
                          width: "97%",
                          fontSize: 20,
                        }}
                        className="Field-cfm-cv select-with-placeholder"
                        validators={["selectRequired"]}
                        errorMessages={[
                          t("biodata:validation.required.degree"),
                        ]}
                        onChange={e => {
                          this.handleChange(
                            { degree: e.target.value },
                            "isDegree"
                          );
                        }}
                        value={this.state.degree ? this.state.degree : " "}>
                        <MenuItem value=" " disabled>
                          <span>{t("biodata:form.placeholder.level")}</span>
                        </MenuItem>
                        <MenuItem value="S3">S3</MenuItem>
                        <MenuItem value="S2">S2</MenuItem>
                        <MenuItem value="S1">S1</MenuItem>
                        <MenuItem value="D4">D4</MenuItem>
                        <MenuItem value="D3">D3</MenuItem>
                        <MenuItem value="D2">D2</MenuItem>
                        <MenuItem value="D1">D1</MenuItem>
                      </SelectValidator>
                    </div>

                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {this.getLastEducation(this.state.degree)
                        ? t("biodata:form.field.schoolLocation")
                        : t("biodata:form.field.universitylocation")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <Select
                      style={{
                        color: palette.primary,
                        fontWeight: "bold",
                        width: "97%",
                        fontSize: 20,
                      }}
                      className="Field select-with-placeholder"
                      onChange={e => {
                        this.handleChange(
                          {
                            institutionLocation: e.target.value,
                          },
                          "isInstitutionLocation"
                        );
                      }}
                      value={
                        this.state.institutionLocation
                          ? this.state.institutionLocation
                          : " "
                      }>
                      <MenuItem value=" " disabled>
                        <span>
                          {this.getLastEducation(this.state.degree)
                            ? t("biodata:form.placeholder.schoolLocation")
                            : t("biodata:form.placeholder.universitylocation")}
                        </span>
                      </MenuItem>
                      {universityLocations.map((v, x) => {
                        return (
                          <MenuItem key={x} value={v.value}>
                            {v.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {(this.state.degree !== "S1" ||
                      this.state.institutionLocation !== "indonesia") && (
                      <div>
                        <label
                          style={{
                            color: "#2E69B2",
                            fontSize: 14,
                            textTransform: "uppercase",
                            fontWeight: "lighter",
                          }}>
                          {this.getLastEducation(this.state.degree)
                            ? t("biodata:form.field.schoolName")
                            : t("biodata:form.field.university")}
                          <span
                            style={{ color: "#CD594D" }}
                            className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                            &thinsp;*
                          </span>
                        </label>
                        <TextValidator
                          style={{ width: "97%", fontSize: 20 }}
                          validators={["required"]}
                          errorMessages={[
                            this.getLastEducation(this.state.degree)
                              ? t("biodata:validation.required.schoolName")
                              : t("biodata:validation.required.university"),
                          ]}
                          onChange={e => {
                            this.handleChange({
                              institution: e.currentTarget.value,
                            });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={
                            this.getLastEducation(this.state.degree)
                              ? t("biodata:form.placeholder.schoolName")
                              : t("biodata:form.placeholder.university")
                          }
                          className="Field-cfm-cv"
                          value={this.state.institution}
                        />

                        <label
                          style={{
                            color: "#2E69B2",
                            fontSize: 14,
                            textTransform: "uppercase",
                            fontWeight: "lighter",
                          }}>
                          {t("biodata:form.field.major")}
                          <span
                            style={{ color: "#CD594D" }}
                            className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                            &thinsp;*
                          </span>
                        </label>
                        <TextValidator
                          style={{ width: "97%", fontSize: 20 }}
                          validators={["required"]}
                          errorMessages={[
                            t("biodata:validation.required.major"),
                          ]}
                          onChange={e => {
                            this.handleChange({
                              major: e.currentTarget.value,
                            });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={t("biodata:form.placeholder.major")}
                          className="Field-cfm-cv"
                          value={this.state.major}
                        />
                      </div>
                    )}

                    {this.state.degree === "S1" && (
                      <div>
                        {this.state.institutionLocation === "indonesia" && (
                          <div>
                            <div style={{ marginBottom: 20 }}>
                              <label
                                style={{
                                  color: "#2E69B2",
                                  fontSize: 14,
                                  textTransform: "uppercase",
                                  fontWeight: "lighter",
                                }}>
                                {this.getLastEducation(this.state.degree)
                                  ? t("biodata:form.field.schoolName")
                                  : t("biodata:form.field.university")}
                                <span
                                  style={{ color: "#CD594D" }}
                                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                                  &thinsp;*
                                </span>
                              </label>
                              <SelectSearch
                                validators={["selectRequired"]}
                                errorMessages={[
                                  t("biodata:validation.required.university"),
                                ]}
                                closeMenuOnSelect
                                key={new Date().getTime()}
                                className="Select-university-major"
                                classNamePrefix="select"
                                placeholder={t(
                                  "biodata:form.placeholder.universitySelected"
                                )}
                                menuColor={palette.primary}
                                name="institution"
                                options={listUniversity}
                                value={
                                  this.state.institution
                                    ? this.state.institution
                                    : " "
                                }
                                onChange={v => {
                                  this.onChangeUniversity({
                                    institutionId: v.value,
                                  });
                                }}
                                style={{ color: palette.primary }}
                              />
                            </div>

                            <div style={{ marginBottom: 20 }}>
                              <label
                                style={{
                                  color: "#2E69B2",
                                  fontSize: 14,
                                  textTransform: "uppercase",
                                  fontWeight: "lighter",
                                }}>
                                {t("biodata:form.field.major")}
                                <span
                                  style={{ color: "#CD594D" }}
                                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                                  &thinsp;*
                                </span>
                              </label>
                              <SelectSearch
                                validators={["selectRequired"]}
                                errorMessages={[
                                  t("biodata:validation.required.major"),
                                ]}
                                closeMenuOnSelect
                                key={new Date().getTime()}
                                className="Select-university-major"
                                classNamePrefix="select"
                                placeholder={t(
                                  "biodata:form.placeholder.majorSelected"
                                )}
                                menuColor={palette.primary}
                                name="major"
                                options={listMajor}
                                value={
                                  this.state.major ? this.state.major : " "
                                }
                                onChange={v => {
                                  this.onChangeMajor({ majorId: v.value });
                                }}
                                style={{ color: palette.primary }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {this.getLastEducation(this.state.degree)
                          ? t("biodata:form.field.nis")
                          : t("biodata:form.field.nim")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          {/* &thinsp;* */}
                        </span>
                      </label>
                      <TextValidator
                        style={{ width: "97%", fontSize: 20 }}
                        onChange={e => {
                          this.handleChange({ nim: e.currentTarget.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={
                          this.getLastEducation(this.state.degree)
                            ? t("biodata:form.placeholder.nis")
                            : t("biodata:form.placeholder.nim")
                        }
                        className="Field-cfm-cv"
                        value={this.state.nim}
                      />
                    </div>

                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {this.getLastEducation(this.state.degree)
                        ? t("biodata:form.field.nem")
                        : t("biodata:form.field.gpa")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <TextValidator
                      style={{ width: "97%", fontSize: 20 }}
                      validators={[
                        "required",
                        this.getLastEducation(this.state.degree)
                          ? "nem"
                          : "gpa",
                      ]}
                      errorMessages={[
                        this.getLastEducation(this.state.degree)
                          ? t("biodata:validation.required.nem")
                          : t("biodata:validation.required.gpa"),
                        this.getLastEducation(this.state.degree)
                          ? t("biodata:validation.valid.minmaxnem")
                          : t("biodata:validation.valid.minmaxgpa"),
                      ]}
                      onChange={e => {
                        this.handleChange({ grade: e.currentTarget.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="number"
                      placeholder={
                        this.getLastEducation(this.state.degree)
                          ? t("biodata:form.placeholder.nem")
                          : t("biodata:form.placeholder.gpa")
                      }
                      className="Field-cfm-cv"
                      value={this.state.grade}
                    />
                    <div
                      value={this.state.tmpGraduate}
                      style={{
                        width: "97%",
                        fontSize: 20,
                      }}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {t("biodata:form.field.graduationyear")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          {/* &thinsp;* */}
                        </span>
                      </label>
                      <DatePickerValidator
                        className="Profile-cfm-cv"
                        label={""}
                        format="yyyy"
                        maxDetail="decade"
                        style={{ width: 520 }}
                        value={this.state.tmpGraduate}
                        onChange={e => {
                          if (e) {
                            General.visible = true;
                            this.handleChange({
                              tmpGraduate: e,
                              yearGraduate: e,
                            });
                          }
                        }}
                        yearPlaceholder={t("biodata:form.placeholder.year")}
                      />
                    </div>
                  </div>
                </DialogContent>
                <DialogActions
                  style={{
                    backgroundColor: palette.primary,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        onClick={this.handleClose}
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {t("biodata:CVbtnCancel")}
                      </Button>
                    </div>
                    <div style={{ width: "76%" }}></div>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        type="submit"
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {isedit
                          ? t("biodata:CVbtnEdit")
                          : t("biodata:CVbtnAdd")}
                      </Button>
                    </div>
                  </div>
                </DialogActions>
              </ValidatorForm>
            </div>
          </div>
        </Dialog>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "4%",
              width: "100%",
              borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
            }}>
            <div
              style={{
                width: "100%",
              }}>
              {listFormalEducation.map((v, k) => {
                var university = null;
                university = findObject(
                  listUniversity,
                  "value",
                  v.institutionId
                );
                if (university === null) {
                  university = v.institution;
                } else {
                  university = university.label;
                }

                var major = v.major;
                if (
                  v.degree !== "S1" ||
                  v.institutionLocation !== "indonesia"
                ) {
                  major = v.major;
                } else {
                  major = v.major;
                }

                return (
                  <div
                    key={k}
                    style={{
                      borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                    }}>
                    <div>
                      <div>
                        <br />
                      </div>
                      <div
                        style={{
                          color: palette.primary,
                          fontSize: 18,
                          fontWeight: "bolder",
                        }}>
                        {university}
                      </div>
                      <div
                        style={{
                          color: palette.primary,
                          fontSize: 18,
                          fontWeight: "bolder",
                        }}>
                        {v.degree} - {major}
                      </div>
                      <div
                        style={{
                          color: palette.primary,
                          fontSize: 18,
                          fontWeight: "lighter",
                          display: "flex",
                          flexDirection: "row",
                        }}>
                        <div
                          style={{
                            width: "100%",
                          }}>
                          GPA : {v.grade}
                        </div>
                        <div>
                          <img
                            onClick={() => {
                              this.handleClickOpen(k);
                            }}
                            style={{ cursor: "pointer" }}
                            src={IconEditCV}
                            alt="Edit"
                          />
                        </div>
                        <div style={{ width: "4%" }}></div>
                        <div>
                          <img
                            onClick={() => {
                              this.handleClickDelete(k);
                            }}
                            style={{ cursor: "pointer" }}
                            src={IconDeleteCV}
                            alt="Edit"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "85%",
                          color: palette.primary,
                          fontSize: 18,
                          fontWeight: "lighter",
                          marginTop: "-1.5%",
                        }}>
                        Graduated {v.yearGraduate}
                      </div>
                      <div>
                        <br />
                      </div>
                    </div>
                    <DialogueConfirm
                      open={opendelete}
                      title={t("auth:CVdeleteConfirm.applicant.title")}
                      content={t("auth:CVdeleteConfirm.applicant.message")}
                      btnYes={t("auth:CVdeleteConfirm.applicant.btnOk")}
                      btnNo={t("auth:CVdeleteConfirm.applicant.btnCancel")}
                      onOK={() => this.deleteformaleducation()}
                      onClose={() => {
                        this.setState({ opendelete: false });
                      }}
                    />
                    <DialogueInfo
                      title={t("auth:CVcantdelete.title")}
                      content={t("auth:CVcantdelete.content")}
                      open={opencantdelete}
                      onClose={() => {
                        this.setState({ opencantdelete: false });
                      }}
                    />
                    <DialogueInfo
                      title={t("biodata:validation.dialog.title")}
                      content={t("biodata:validation.dialog.content")}
                      open={openValidation}
                      onClose={() => {
                        this.setState({ openValidation: false });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <ConfirmFooter
            text={t("button.save")}
            NextClick={() => {
              this.submitdata();
            }}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation()(ConfirmationFormalEducation);
