import React from "react";
import { palette } from "assets/css/main";
import {
  Grid,
  Typography,
  Button,
  TextField,
  LinearProgress,
  Dialog,
} from "@material-ui/core";
import data from "./data";
import { IcnX, ImgAboutYou } from "assets/img";
// import { PopupAbout } from "./component";
import { RequestPost, RequestGet } from "app/utils";
import { withTranslation } from "react-i18next";
import { getItem } from "app/utils";
import alertData from "app/components/alert/alert-data";
import "./component/custom.css";
import OptionWorkAbout from "./option-work-about";

class TellUsFirstTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tellUsApi: this.props.tellUsApi,
      step: 0,
      progressStep: 0,
      popupOpen: false,
    };
  }

  togglePopup(newVal) {
    this.setState({ popupOpen: newVal });
    this.disabledBtn();
  }

  disabledBtn() {
    setTimeout(() => {
      var btn = document.getElementById("BtnNext");
      if (
        btn !== null &&
        this.state.tellUsApi[this.state.step].responseAnswers.length === 0
      ) {
        btn.disabled = true;
        btn.classList.add("Mui-disabled");
      }
    }, 100);
  }

  handleChange(obj) {
    var btn = document.getElementById("BtnNext");
    var newTellUs = this.state.tellUsApi;
    var newProgress = this.state.progressStep;
    var newAnswer = {
      userAnswer: "",
      otherValue: "",
    };

    if (this.state.tellUsApi[this.state.step].categoryQuestion === "Freetext") {
      if (obj.answer === "" || obj.answer === null) {
        if (this.state.tellUsApi[this.state.step].responseAnswers.length > 0) {
          // revert
          btn.disabled = true;
          btn.classList.add("Mui-disabled");

          this.setState({ progressStep: newProgress - 1 });
        }
        newTellUs[this.state.step].responseAnswers = [];
      } else {
        if (
          this.state.tellUsApi[this.state.step].responseAnswers.length === 0
        ) {
          // enable button
          btn.disabled = false;
          btn.classList.remove("Mui-disabled");
          this.setState({ progressStep: newProgress + 1 });

          newAnswer.userAnswer = obj.answer;
          newTellUs[this.state.step].responseAnswers.push(newAnswer);
        } else {
          newTellUs[this.state.step].responseAnswers[0].userAnswer = obj.answer;
        }
      }
    } else if (
      this.state.tellUsApi[this.state.step].categoryQuestion === "Single"
    ) {
      // enable button
      btn.disabled = false;
      btn.classList.remove("Mui-disabled");

      if (this.state.tellUsApi[this.state.step].responseAnswers.length === 0) {
        this.setState({ progressStep: newProgress + 1 });
      }

      newTellUs[this.state.step].responseAnswers = obj.answer;
    } else {
      // multiple
      btn.disabled = false;
      btn.classList.remove("Mui-disabled");
      if (this.state.tellUsApi[this.state.step].responseAnswers.length === 0) {
        this.setState({ progressStep: newProgress + 1 });
      } else {
        // remove progress
        if (obj.answer.length === 0) {
          this.setState({ progressStep: newProgress - 1 });
        }
      }

      newTellUs[this.state.step].responseAnswers = obj.answer;
    }

    data.setTellUs({ tellUsApi: newTellUs });
    this.setState({ tellUsApi: newTellUs });
  }

  postAboutQuestion(obj) {
    RequestPost("aboutquestion", obj).then(res => {
      RequestGet("UserProfile", { loading: false })
        .then(r => {
          this.props.getProfile(r.data);

          alertData.show("Success");
          setTimeout(() => {
            this.togglePopup(!this.state.popupOpen);
            this.props.onFinish();
            window.location.reload();
          }, 1000);
        })
        .catch(e => {
          console.log(e);
          alertData.show(String(e), "error");
        });
    });
  }

  handleBack() {
    var btn = document.getElementById("BtnNext");
    btn.disabled = false;
    btn.classList.remove("Mui-disabled");

    var newStep = this.state.step - 1;
    this.setState({ step: newStep });
  }

  render() {
    const { step, popupOpen, progressStep, tellUsApi } = this.state;
    const { t } = this.props;
    return (
      <Grid
        container
        className="about-you"
        style={{
          height: "100%",
          boxShadow: "rgba(0, 0, 0, 0.09) 0px 0px 13px",
        }}>
        <div
          style={{
            width: "100%",
            height: 23,
            padding: "3% 3% 3% 3%",
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "#2E69B2",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            color: "white",
            fontSize: 24,
          }}>
          {t("about:tellUsFirstTime.title")}
        </div>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <img
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={ImgAboutYou}
            alt={"Self Description"}
            height={140}
            width={215}
          />
          <Typography
            style={{
              fontSize: "18px",
              fontWeight: "lighter",
              color: palette.primary,
              marginTop: 20,
              width: 556,
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}>
            {t("about:tellUsFirstTime.wordingSelfDesc")}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "2%" }}>
          <Button
            style={{
              fontSize: 18,
              color: "white",
              backgroundColor: palette.primary,
              textTransform: "none",
              padding: "10px 36px 7px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            onClick={() => {
              this.togglePopup(!popupOpen);
            }}>
            {t("about:tellUsFirstTime.button")}
          </Button>
        </Grid>

        <Dialog
          className={"dialog-additional"}
          aria-labelledby="customized-dialog-title"
          disableBackdropClick
          scroll={"body"}
          open={popupOpen}
          style={{
            backgroundColor: "transparent",

            // overflow:"none"
          }}>
          <Grid
            container
            justify="center"
            alignItems="flex-end"
            style={{
              backgroundColor: "transparent",
              width: 550,
              paddingTop: 60,
            }}>
            <div
              style={{
                position: "absolute",
                top: 40,
                marginLeft: 490,
                width: 30,
                height: 30,
                borderRadius: "50%",
              }}>
              <Button onClick={() => this.togglePopup(!popupOpen)}>
                <img
                  src={IcnX}
                  width={24}
                  height={24}
                  style={{
                    position: "relative",
                    left: "5%",
                  }}
                  alt="Close"
                />
              </Button>
            </div>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                padding: "32px",
                backgroundColor: palette.primary,
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                width: "100%",
                color: "white",
              }}>
              <Grid item lg={12} md={12} sm={12}>
                <Typography
                  style={{
                    fontSize: "18px",
                    lineHeight: "23px",
                    textTransform: "none",
                  }}>
                  {t("about:tellUsFirstTime.popup.title")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                padding: "32px",
                backgroundColor: "white",
                width: "100%",
                color: palette.primary,
                maxHeight: "400px",
                overflowY: "scroll",
              }}>
              <Grid item lg={1} md={1} sm={1}>
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    background: "#B0B7BD",
                    borderRadius: "20px",
                    color: "white",
                    textAlign: "center",
                  }}>
                  {step + 1}
                </div>
              </Grid>
              <Grid item lg={11} md={11} sm={11}>
                {tellUsApi[step].categoryQuestion === "Single" ? (
                  <OptionWorkAbout
                    label={
                      getItem("lang") === "en"
                        ? tellUsApi[step].questionTextEn
                        : tellUsApi[step].questionTextId
                    }
                    onChange={v => {
                      this.handleChange({ answer: v });
                    }}
                    value={tellUsApi[step].responseAnswers}
                    options={tellUsApi[step].aboutAnswers}
                  />
                ) : tellUsApi[step].categoryQuestion === "Multiple" ? (
                  <OptionWorkAbout
                    label={
                      getItem("lang") === "en"
                        ? tellUsApi[step].questionTextEn
                        : tellUsApi[step].questionTextId
                    }
                    onChange={v => {
                      this.handleChange({ answer: v });
                    }}
                    multiple={true}
                    value={tellUsApi[step].responseAnswers}
                    options={tellUsApi[step].aboutAnswers}
                  />
                ) : (
                  <div style={{ width: "100%" }}>
                    <Grid container>
                      <Typography
                        style={{
                          fontSize: "18px",
                          fontWeight: "lighter",
                          color: palette.primary,
                        }}>
                        {getItem("lang") === "en"
                          ? tellUsApi[step].questionTextEn
                          : tellUsApi[step].questionTextId}
                      </Typography>
                    </Grid>
                    <Grid container>
                      <TextField
                        multiline
                        style={{
                          width: "90%",
                          maxHeight: "100px",
                          overflowY: "scroll",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={t(
                          "about:tellUsFirstTime.popup.placeholder"
                        )}
                        className="Field"
                        onChange={v =>
                          this.handleChange({ answer: v.target.value })
                        }
                        value={
                          tellUsApi[step].responseAnswers.length > 0
                            ? tellUsApi[step].responseAnswers[0].userAnswer
                            : ""
                        }
                      />
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justify="space-between"
            style={{
              height: 70,
              backgroundColor: "white",
              paddingLeft: 20,
              paddingRight: 20,
              borderTop: "1px solid #DFE5F4",
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 15,
            }}>
            <div
              style={{
                width: "40%",
              }}>
              <LinearProgress
                style={{
                  marginTop: "17px",
                  width: "100%",
                }}
                className={"freetext-progress"}
                variant="determinate"
                value={(progressStep / tellUsApi.length) * 100}
              />
              <Typography
                style={{
                  marginTop: "4px",
                  fontSize: "14px",
                  color: "#B0B7BD",
                }}>
                {progressStep + "/" + tellUsApi.length + " "}
                {t("about:tellUsFirstTime.popup.progress")}
              </Typography>
            </div>
            {step > 0 && (
              <Button
                style={{
                  backgroundColor: "white",
                  border: "1px solid #E5E5E5",
                  borderRadius: "4px",
                  fontSize: "14px",
                  color: palette.primary,
                  textTransform: "none",
                  padding: "11px 32px 6px",
                  margin: "9px 0 17px 5%",
                }}
                onClick={() => {
                  this.handleBack();
                  // this.setState({

                  //   progressStep: progressStep  1
                  // });
                }}>
                {t("about:tellUsFirstTime.popup.backButton")}
              </Button>
            )}
            <Button
              id={"BtnNext"}
              style={
                tellUsApi[step].responseAnswers.length === 0
                  ? {
                      backgroundColor: "#E5E5E5",
                      border: "1px solid #E5E5E5",
                      borderRadius: "4px",
                      fontSize: "14px",
                      color: "white",
                      textTransform: "none",
                      padding: "11px 32px 6px",
                      marginTop: "9px",
                      marginBottom: "17px",
                    }
                  : {
                      backgroundColor: palette.primary,
                      border: "1px solid #2E69B2",
                      borderRadius: "4px",
                      fontSize: "14px",
                      color: "white",
                      textTransform: "none",
                      padding: "11px 32px 6px",
                      marginTop: "9px",
                      marginBottom: "17px",
                    }
              }
              onClick={
                step === tellUsApi.length - 1
                  ? () => {
                      this.postAboutQuestion(tellUsApi);
                    }
                  : () => {
                      if (tellUsApi[step + 1].responseAnswers.length === 0) {
                        this.disabledBtn();
                      }
                      this.setState({
                        step: step + 1,
                        // progressStep: progressStep + 1,
                      });
                    }
              }>
              {step === tellUsApi.length - 1
                ? t("about:tellUsFirstTime.popup.saveButton")
                : t("about:tellUsFirstTime.popup.nextButton")}
            </Button>
          </Grid>
        </Dialog>
      </Grid>
    );
  }
}
export default withTranslation()(TellUsFirstTime);
