import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { NextBtn } from "app/components";
import { withTranslation } from "react-i18next";
class IntroInterest extends React.Component {
  render() {
    const { t } = this.props;
    const introd = t("basictest:interestTest.intro", { returnObjects: true });
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        style={{
          width: "100vw",
          paddingTop: 55,
        }}
      >
        <Typography
          variant="h4"
          style={{ color: palette.primary, fontWeight: "bold" }}
        >
          {t("basictest:interestTest.title")}
        </Typography>
        <table style={{ width: 700, marginTop: 60 }}>
          <thead>
            <tr>
              <td></td>
              <td>
                <div
                  style={{
                    color: palette.primary,
                    fontWeight: "bold",
                    backgroundColor: palette.info,
                    width: 200,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  {t("basictest:intro")}
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {introd.map((v, k) => {
              return (
                <tr key={k}>
                  <td width={40}>
                    <div
                      style={{
                        width: 28,
                        height: 23,
                        borderRadius: 14,
                        backgroundColor: "#B0B7BD",
                        color: "white",
                        textAlign: "center",
                        paddingTop: 5,
                        alignItems: "center",
                      }}
                    >
                      {k + 1}
                    </div>
                  </td>
                  <td>
                    <p style={{ color: palette.primary, fontSize: 18 }}>{v}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            height: 60,
            position: "fixed",
            bottom: 0,
            backgroundColor: palette.primary,
            paddingRight: 50,
          }}
        >
          <NextBtn
            color={"white"}
            onClick={this.props.onStart}
            text={t("button.starttest")}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(IntroInterest);
