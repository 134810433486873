import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { AppHistory } from "app/utils";
const styles = {
  padding: "10px 40px 10px 10px",
  width: 210,
  cursor: "pointer",
};
export default withTranslation("user-profile")(
  class UserProfileSidePanel extends React.Component {
    render() {
      const { t, view } = this.props;
      const menu = t("side.items", { returnObjects: true });
      return (
        <div style={{ color: palette.primary, padding: "30px 0px" }}>
          <span style={{ fontSize: 18 }}>{t("side.title")}</span>
          <div style={{ fontWeight: "lighter", marginTop: 25, fontSize: 18 }}>
            {menu.map((v, k) => {
              return (
                <div
                  key={k}
                  onClick={() => {
                    AppHistory.push(v.link);
                  }}
                  style={{
                    ...styles,
                    marginBottom: 15,
                    backgroundColor:
                      view === v.active
                        ? palette.primary_opacity
                        : "transparent",
                  }}>
                  {v.title}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
);
