import React from "react";
import red from "@material-ui/core/colors/red";
import { ValidatorComponent } from "react-material-ui-form-validator";
import DatePickers from "./DatePickers";
import { withTranslation } from "react-i18next";

const red300 = red["500"];

const style = {
  left: 0,
  bottom: 0,
  fontSize: "12px",
  color: red300,
  position: "absolute",
  marginTop: "-25px",
};

class DatePickerValidator extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      onChange,
      value,
      t,
      ...rest
    } = this.props;
    const { isValid } = this.state;

    return (
      <div style={{ position: "relative" }} className={!isValid ? "error" : ""}>
        <DatePickers
          dayPlaceholder={t("biodata:form.placeholder.date")}
          monthPlaceholder={t("biodata:form.placeholder.month")}
          yearPlaceholder={t("biodata:form.placeholder.year")}
          value={value}
          onChange={onChange}
          {...rest}
          ref={r => {
            this.input = r;
          }}
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return <div style={style}>{this.getErrorMessage()}</div>;
  }
}

export default withTranslation()(DatePickerValidator);
