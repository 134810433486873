import React from "react";
import { palette } from "assets/css/main";
import { Button } from "@material-ui/core";
import imageCompression from "browser-image-compression";
import alertData from "../alert/alert-data";
import { getItem, setItem } from "app/utils";
class ProfilePicture extends React.Component {
  options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 640,
  };
  async handleImageUpload(event) {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 640,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.log("handleImageUpload()", error);
      return imageFile;
    }
  }
  getTime() {
    if (!getItem("profile-cache")) {
      setItem("profile-cache", new Date().getTime());
    }
    return getItem("profile-cache");
  }
  render() {
    const { value } = this.props;
    return (
      <div
        className={
          this.props.edit || this.props.edit === null ? "profile-picture" : ""
        }
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          width: 165,
          height: 165,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          //border: "1px solid ",
          //padding: 3,
        }}>
        {(this.props.edit || this.props.edit === null) && (
          <Button
            onClick={() => {
              document.getElementById("profile-photo").click();
            }}
            className="button"
            style={{
              zIndex: "20",
              position: "absolute",
              backgroundColor: "transparent",
              color: "#ffffff",
              border: "1px solid #ffffff",
              textTransform: "capitalize",
              padding: "5px 10px",
              display: "none",
            }}>
            Upload
          </Button>
        )}

        <div
          className={"content"}
          style={{
            backgroundColor: palette.darkGrey,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: 165,
            height: 165,
            backgroundImage: "url(" + value + "?ts=" + this.getTime() + ")",
            borderRadius: "50%",
          }}></div>
        <input
          type="file"
          style={{ display: "none" }}
          id="profile-photo"
          accept="image/*"
          onChange={e => {
            const file = e.target.files[0];
            const extension = file.name.split(".");
            this.handleImageUpload(e)
              .then(r => {
                setItem("profile-cache", new Date().getTime());
                this.props.onSelectFile({
                  file: r,
                  extension: extension[extension.length - 1],
                });
              })
              .catch(er => {
                console.log("handleImageUpload()", er);
                alertData.show("Error", "error");
              });
          }}
        />
      </div>
    );
  }
}
ProfilePicture.defaultProps = {
  value: "",
  onSelectFile: function onSelectFile() {
    console.log("Please define onSelectFile");
  },
  style: {},
  required: false,
};

export default ProfilePicture;
