import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Typography, Grid } from "@material-ui/core";
import { currencyFormat, getItem } from "app/utils";
import { IconVacancyBullet } from "assets/img";

class JobDetails extends React.Component {
  render() {
    const { t, data } = this.props;
    return (
      <Grid container style={{ padding: "300px 6% 8%" }}>
        <Grid item lg={12} md={12} sm={12}>
          <Typography
            style={{
              fontSize: "24px",
              color: palette.primary,
            }}>
            {
              t("job-opening:jobOpeningDetail.sideStep.menu", {
                returnObjects: true,
              })[0]
            }
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Job Family
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobDetail.jobFamily.jobFamilyOther !== ""
              ? data.jobDetail.jobFamily.jobFamilyOther
              : getItem("lang") === "en"
              ? data.jobDetail.jobFamily.jobFamilyEng
              : data.jobDetail.jobFamily.jobFamilyIna}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Sub Job Family
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {getItem("lang") === "en"
              ? data.jobDetail.jobFamily.subJobFamilyEng
              : data.jobDetail.jobFamily.subJobFamilyIna}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Number of Vacancy
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobDetail.openVacancy}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Work Location
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobDetail.workLocations.length > 0
              ? data.jobDetail.workLocations.map((v, i) => {
                  var value = i === 0 ? v : ", ".concat(v);
                  return value;
                })
              : null}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Job Description
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {!(
            data.jobDetail.jobDescriptions.length === 1 &&
            data.jobDetail.jobDescriptions[0] === ""
          )
            ? data.jobDetail.jobDescriptions.map((v, i) => {
                return (
                  <div style={{ width: "51%" }} key={i}>
                    <Grid container>
                      <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                        <img
                          style={{ paddingTop: 5, paddingLeft: 2 }}
                          src={IconVacancyBullet}
                          alt={i}
                          height="10vh"
                          align="center"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={11}
                        md={11}
                        sm={11}
                        style={{ marginTop: 4 }}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            color: palette.primary,
                          }}>
                          {v}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              })
            : null}
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Salary Range
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {"Rp " +
              currencyFormat(String(data.jobDetail.salaryMin)) +
              " - Rp " +
              currencyFormat(String(data.jobDetail.salaryMax))}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Benefit
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {data.jobDetail.benefits.length > 0
            ? data.jobDetail.benefits.map((v, i) => {
                return (
                  <div style={{ width: "51%" }} key={i}>
                    <Grid container>
                      <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                        <img
                          style={{ paddingTop: 5, paddingLeft: 2 }}
                          src={IconVacancyBullet}
                          alt={i}
                          height="10vh"
                          align="center"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={11}
                        md={11}
                        sm={11}
                        style={{ marginTop: 4 }}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            color: palette.primary,
                          }}>
                          {v}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              })
            : null}
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(JobDetails);
