import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { DialogueInfo, MyHelmet, NextBtn } from "app/components";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { AppHistory, nameRegex, RequestPost } from "app/utils";
import { palette } from "assets/css/main";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import history from "app/utils/general/history";
const recruiter_type = [
  { code: "HR", label: "Human Resources" },
  { code: "HH", label: "Headhunter" },
];

class AddUserRecruiter extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      openUserSuccessfullyAdded: false,
      email: "",
      firstName: "",
      middleName: "",
      lastName: "",
      phoneNumber: "",
      recruiterType: "",
      positionType: "",
      error: "",

      hideplaceholder: false,
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("selectRequired");
  }

  submitForm(e) {
    this.setState({ error: "" });
    e.preventDefault();
    var data = {
      recruiterPersonalInfo: {
        firstName: this.state.firstName,
        middleName: this.state.middleName,
        lastName: this.state.lastName,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        recruiterType: this.state.recruiterType,
        positionType: this.state.positionType,
      },
    };
    RequestPost("UserCompany", data)
      .then(res => {
        if (res) {
          this.setState({
            openUserSuccessfullyAdded: true,
          });
        }
      })
      .catch(err => {
        console.log("RecruiterAddUser", err);
        this.setState({ error: err.data.message });
      });
  }

  handleClick(e) {
    document.getElementById("button-submit").click();
  }

  clikOkUserSuccessfullyAdded() {
    this.setState({
      openUserSuccessfullyAdded: false,
    });
    AppHistory.push("/recruiter/users");
  }

  render() {
    const { openUserSuccessfullyAdded } = this.state;
    const { t } = this.props;
    return (
      <div className="auth">
        <MyHelmet title="Add User" />
        <RecruiterAppBar shadow={true} menu="" history={this.props.history} />

        {/* User Successfully Added Dialog */}
        <DialogueInfo
          title={t("rec-add-user:UserSuccessfullyAdded.title")}
          content={t("rec-add-user:UserSuccessfullyAdded.message")}
          contentDetail={t("rec-add-user:UserSuccessfullyAdded.messageDetail")}
          contentWidth={400}
          open={openUserSuccessfullyAdded}
          onClose={() => {
            this.clikOkUserSuccessfullyAdded();
          }}
        />

        <Grid
          container
          justify="flex-start"
          alignItems="center"
          direction="column"
          style={{ marginTop: 130, marginBottom: 120 }}>
          <div style={{ width: 520, color: palette.primary }}>
            <Typography style={{ fontSize: 24 }}>
              {t("rec-add-user:addNewUser")}
            </Typography>
            <Typography>{t("rec-add-user:info")}</Typography>
            <ValidatorForm onSubmit={this.submitForm}>
              <div
                style={{
                  marginTop: "10%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontSize: 14,
                    fontWeight: "lighter",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("rec-add-user:form.field.email")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <TextValidator
                  autoFocus={true}
                  onChange={e => {
                    this.setState({ email: e.currentTarget.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t("rec-add-user:form.placeholder.email")}
                  name="email"
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    t("auth:validation.required"),
                    t("auth:validation.email"),
                  ]}
                  value={this.state.email}
                  style={{ width: "100%" }}
                />
              </div>
              <Typography
                style={{ width: "100%", marginBottom: 20, color: "red" }}>
                {this.state.error}
              </Typography>
              <div style={{ marginTop: "5%" }}>
                <label
                  style={{
                    fontSize: 14,
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("rec-add-user:form.field.firstName")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <TextValidator
                  style={{
                    width: "100%",
                  }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.name")]}
                  onChange={e => {
                    var val = e.currentTarget.value;
                    nameRegex.test(val)
                      ? this.setState({ firstName: val })
                      : e.preventDefault();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("rec-add-user:form.placeholder.firstName")}
                  value={this.state.firstName}
                  className="Field"
                />
              </div>
              <TextField
                style={{
                  width: "100%",
                  marginTop: "5%",
                }}
                onChange={e => {
                  var val = e.currentTarget.value;
                  nameRegex.test(val)
                    ? this.setState({ middleName: val })
                    : e.preventDefault();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("rec-add-user:form.placeholder.middleName")}
                label={t("rec-add-user:form.field.middleName")}
                value={this.state.middleName}
                className="Field"
              />
              <TextField
                style={{
                  width: "100%",
                  marginTop: "5%",
                }}
                //autoFocus={true}
                onChange={e => {
                  var val = e.currentTarget.value;
                  nameRegex.test(val)
                    ? this.setState({ lastName: val })
                    : e.preventDefault();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("rec-add-user:form.placeholder.lastName")}
                label={t("rec-add-user:form.field.lastName")}
                value={this.state.lastName}
                className="Field"
              />
              <Grid
                required={true}
                value={this.state.phoneNumber}
                style={{ marginTop: "5%" }}>
                <Typography
                  style={{
                    color: palette.primary,
                    fontWeight: "lighter",
                    textTransform: "uppercase",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("rec-add-user:form.field.mobilephone")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </Typography>
                <Grid container direction="row" className="group">
                  <TextField
                    value={"+62"}
                    className="Field"
                    contentEditable={false}
                    style={{ width: 62, marginTop: 0 }}
                  />
                  <TextValidator
                    placeholder={t("rec-add-user:form.placeholder.mobilephone")}
                    validators={[
                      "required",
                      "isNumber",
                      "minStringLength:8",
                      "maxStringLength:14",
                    ]}
                    errorMessages={[
                      t("validation.required.phoneNumber"),
                      t("validation.valid.phoneNumber"),
                      t("validation.minLength.phoneNumber"),
                    ]}
                    onChange={e => {
                      var char = e.currentTarget.value;
                      if (char.split("")[0] === "0") {
                        char = char.slice(1);
                      }
                      this.setState({ phoneNumber: char });
                    }}
                    value={this.state.phoneNumber}
                    className="Field"
                    style={{ width: 448, marginLeft: 10, marginTop: 0 }}
                  />
                </Grid>
              </Grid>
              <TextField
                style={{
                  width: "100%",
                  marginTop: "5%",
                  display: this.state.hideplaceholder ? "none" : "",
                }}
                placeholder={t("rec-add-user:form.placeholder.type")}
                className="Field"
                disabled
              />
              <div
                style={{
                  marginTop: this.state.hideplaceholder ? "5%" : "-16.2%",
                }}
                className="Profile">
                <label
                  style={{
                    fontSize: 15,
                    fontWeight: "lighter",
                    textTransform: "uppercase",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("rec-add-user:form.field.type")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <SelectValidator
                  className="Profile"
                  style={{ width: "100%" }}
                  value={this.state.recruiterType}
                  defaultValue={0}
                  onChange={e =>
                    this.setState({
                      recruiterType: e.target.value,
                      hideplaceholder: true,
                    })
                  }
                  validators={["selectRequired"]}
                  errorMessages={[t("validation.required.type")]}>
                  <MenuItem
                    style={{ fontSize: 24, fontWeight: "bold" }}
                    value={0}
                    disabled>
                    {t("rec-add-user:form.placeholder.type")}
                  </MenuItem>
                  {recruiter_type.map((v, k) => {
                    return (
                      <MenuItem
                        key={k}
                        style={{
                          fontSize: 24,
                          color: palette.primary,
                          fontWeight: "bold",
                        }}
                        value={v.code}>
                        {v.label}
                      </MenuItem>
                    );
                  })}
                </SelectValidator>
              </div>
              <div style={{ marginTop: "8%" }}>
                <label
                  style={{
                    fontSize: 15,
                    fontWeight: "lighter",
                    textTransform: "uppercase",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("rec-add-user:form.field.position")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <TextValidator
                  onChange={e => {
                    this.setState({ positionType: e.target.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t("rec-add-user:form.placeholder.position")}
                  name="position"
                  value={this.state.positionType}
                  style={{ width: "100%" }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.position")]}
                />
              </div>

              <button type="submit" id="button-submit" className="Submit-Btn">
                submit
              </button>
            </ValidatorForm>
          </div>
        </Grid>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            height: 60,
            position: "fixed",
            bottom: 0,
            left: 0,
            backgroundColor: palette.primary,
            padding: "0px 50px",
            zIndex: 100,
            display: "flex",
            justifyContent: "space-between",
          }}>
          <div
            onClick={() => {
              history.goBack();
            }}
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: 24,
              cursor: "pointer",
              textDecoration: "underline",
            }}>
            {t("button.cancel")}
          </div>
          <NextBtn
            color="white"
            text={t("button.addUser")}
            onClick={() => this.handleClick()}
          />
        </Grid>
      </div>
    );
  }
}
export default withTranslation()(AddUserRecruiter);
