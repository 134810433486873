import React from "react";
import {
  IcnPaperBlue,
  IcnPaperQuestion,
  IcnPaperGrey,
  IcnTimer,
  IcnInfoFill,
} from "assets/img";
import { Grid, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { AnswerImages, NextBtn } from "app/components";
import { getItem, setItem, RequestPut } from "app/utils";
import Information from "./Information";
import Sample from "./sample";
import { withTranslation } from "react-i18next";
class Choices extends React.Component {
  doc = document;
  timeInterval;
  constructor(props) {
    super(props);
    this.state = {
      alldata: { questionAttempts: [] },
      title: this.props.title,
      instruction: !getItem(this.props.id + "-ins-done") ? true : false,
      currentNumber: 0,
      filled: 0,
      not_filled: 0,
      anIsImage: this.props.data.ansImage,
      qsIsImage: this.props.data.qsImage,
      data: this.props.data,
      question: [],
      answer: [],
      total: this.props.totalQuestion,
      timer: 2,
      open: false,
      time_min: "00",
      time_sec: "00",
      time_milsec: getItem(this.props.id),
    };
    this.keyUp = this.keyUp.bind(this);
  }
  componentDidMount() {
    if (!this.state.instruction) {
      this.startCount();
    }
    this.doc.addEventListener("keyup", this.keyUp);
    this.reset();
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
    this.doc.removeEventListener("keyup", this.keyUp);
  }
  keyUp(e) {
    if (e.keyCode === 13) {
      this.nextNumber();
    } else if (e.keyCode === 8) {
      this.backNumber();
    } else if (e.keyCode === 48 && process.env.NODE_ENV === "development") {
      this.submitResult();
    }
  }
  reset() {
    if (this.props.data) {
      const question = this.props.data.questionAttempts;
      var answer = [];
      question.forEach((v, k) => {
        answer.push({ id: v.id, responseAnswer: null });
      }, this.setState({ answer: answer, question: question, not_filled: question.length, alldata: this.props.data }));
    }
  }

  nextNumber() {
    const { currentNumber, question } = this.state;
    if (currentNumber < question.length - 1) {
      this.setState({ currentNumber: this.state.currentNumber + 1 });
    }
  }

  backNumber() {
    const { currentNumber } = this.state;
    if (currentNumber > 0) {
      this.setState({ currentNumber: this.state.currentNumber - 1 });
    }
  }

  goToNumber(n) {
    this.setState({ currentNumber: n });
  }

  countFilled(data, filled = true) {
    if (filled) {
      return data.filter(x => x.responseAnswer !== null).length;
    } else {
      return data.filter(x => x.responseAnswer === null).length;
    }
  }
  startCount() {
    this.setState({ open: false }, () => {
      this.timeInterval = setInterval(() => {
        this.countTime();
      }, 1000);
    });
  }
  countTime() {
    if (!getItem(this.props.id)) {
      var timeLimit = getItem(this.props.id + "-tl");
      var end = new Date().getTime() + timeLimit * 1000 * 60;
      setItem(this.props.id, end);
    }
    const time_end = getItem(this.props.id);
    const time_now = new Date().getTime();

    const time_diff = time_end - time_now;
    const seconds = Math.floor((time_diff / 1000) % 60);
    const minutes = Math.floor((time_diff / 1000 / 60) % 60);
    if (seconds > 0 || minutes > 0) {
      this.setState({
        time_milsec: time_diff,
        time_sec: seconds < 10 ? "0" + seconds : seconds,
        time_min: minutes < 10 ? "0" + minutes : minutes,
      });
    } else {
      this.submitResult();
    }
  }

  submitResult() {
    RequestPut("questionattempts", this.state.answer)
      .then(r => {
        this.props.history.push("/basic-test");
        clearInterval(this.timeInterval);
      })
      .catch(e => {
        clearInterval(this.timeInterval);
        console.log("Time Finish->Cognitive", e);
      });
  }

  setAnswer(index, value) {
    var tmp_answer = this.state.answer;
    tmp_answer[index].responseAnswer = value;
    this.setState(
      {
        answer: tmp_answer,
        filled: this.countFilled(tmp_answer),
        not_filled: this.countFilled(tmp_answer, false),
      },
      () => {
        setTimeout(() => {
          this.nextNumber();
        }, 100);
      }
    );
  }

  finishIntscruction() {
    this.setState({ instruction: false, open: true });
    setItem(this.props.id + "-ins-done", true);
  }

  render() {
    const {
      question,
      currentNumber,
      title,
      anIsImage,
      qsIsImage,
      answer,
      not_filled,
      time_min,
      time_sec,
      filled,
      open,
      total,
      timer,
      instruction,
      alldata,
    } = this.state;
    const { t } = this.props;
    const examTime = this.props.root.examTime;
    const order = this.props.root.sortorder;
    const current = question[currentNumber] ? question[currentNumber] : {};
    const inst = t("basictest:cognitiveTest.instruction", {
      returnObjects: true,
    });
    var no = 0;
    return (
      <div>
        <Information
          name={t("basictest:cognitiveTest.title")}
          timer={timer}
          open={open}
          total={total}
          title={title}
          onFinish={() => this.startCount()}
        />
        {alldata.questionAttempts.map((v, k) => {
          var question = [];
          if (qsIsImage) {
            question.push(
              <img
                style={{ display: "none" }}
                key={v.questionId}
                src={v.questionSummary}
                alt={v.questionId}
              />
            );
          }
          if (anIsImage) {
            v.answers.map((val, key) => {
              return question.push(
                <img
                  src={val.answer}
                  style={{ display: "none" }}
                  key={key}
                  alt={val.answerId}
                />
              );
            });
          }
          return question;
        })}
        {instruction && (
          <Sample
            title={title}
            order={order}
            time={examTime}
            instruction={inst[order - 1]}
            onFinish={() => this.finishIntscruction()}
          />
        )}
        {!instruction && (
          <div>
            <Grid
              container
              justify="flex-start"
              style={{ width: "100vw", padding: 40, paddingTop: 60 }}>
              <Grid item lg={2}>
                <Grid
                  container
                  style={{
                    width: "10vw",
                    borderBottom: "2px solid" + palette.grey,
                    paddingBottom: 20,
                  }}>
                  {this.state.answer.map((v, k) => {
                    var genap = k % 2;
                    var half = this.state.answer.length / 2;
                    if (genap === 0) {
                      no += 1;
                    }
                    var nomor = genap === 0 ? no : half + no;
                    return (
                      <Grid
                        item
                        md={6}
                        key={"paper-" + v.id}
                        style={{
                          cursor: "pointer",
                          marginBottom: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => this.goToNumber(nomor - 1)}>
                        <div style={{ color: "white", position: "absolute" }}>
                          {nomor}
                        </div>
                        <img
                          style={{ width: 35, height: 38 }}
                          src={
                            this.state.answer[nomor - 1].responseAnswer
                              ? IcnPaperBlue
                              : IcnPaperGrey
                          }
                          alt={k}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid
                  container
                  direction="column"
                  style={{
                    width: "20vw",
                    paddingTop: 20,
                    paddingLeft: 15,
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                    <img
                      style={{ width: 27, height: 33 }}
                      src={IcnPaperGrey}
                      alt={"Not fill question"}
                    />
                    <p
                      style={{
                        marginLeft: 5,
                        fontWeight: "bold",
                        color: "#e3e5e7",
                      }}>
                      {t("progress.notFilled")} ({not_filled})
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}>
                    <img
                      style={{ width: 27, height: 33 }}
                      src={IcnPaperBlue}
                      alt={"fill question"}
                    />
                    <p
                      style={{
                        marginLeft: 5,
                        fontWeight: "bold",
                        color: "#e3e5e7",
                      }}>
                      {t("progress.filled")} ({filled})
                    </p>
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                lg={8}
                style={{ width: "50vw", paddingLeft: 40, paddingRight: 40 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 24,
                      marginBottom: 20,
                      color: palette.primary,
                    }}>
                    {title}
                  </Typography>
                </div>
                {qsIsImage && (
                  <div>
                    <div
                      style={{
                        marginTop: 40,
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}>
                      <div
                        style={{
                          width: 300,
                          position: "relative",
                        }}>
                        <div
                          style={{
                            position: "absolute",
                            top: 15,
                            left: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#B0B7BD",
                            borderRadius: "50%",
                            fontWeight: "bold",
                            color: "white",
                            width: 32,
                            height: 32,
                          }}>
                          {currentNumber + 1}
                        </div>
                        <img
                          id={current.id}
                          key={current.id}
                          style={{
                            position: "absolute",
                            top: "25%",
                            left: "10%",
                          }}
                          src={current.questionSummary}
                          alt="Question Background"
                          width={220}
                          height={220}
                        />
                        <img
                          src={IcnPaperQuestion}
                          alt="Question Background"
                          width={274}
                          height={329}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: 40,
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "50%",
                        }}>
                        <AnswerImages
                          options={current.answers}
                          onChange={option => {
                            this.setAnswer(currentNumber, option);
                          }}
                          isImage={anIsImage}
                          value={
                            answer[currentNumber]
                              ? answer[currentNumber].responseAnswer
                              : null
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        paddingTop: 50,
                        display:
                          currentNumber < question.length - 1 ? "flex" : "none",
                        justifyContent: "flex-start",
                        paddingLeft: 200,
                        width: "100%",
                      }}>
                      <NextBtn
                        fontWeight={"normal"}
                        fontSize={18}
                        text={
                          answer[currentNumber]
                            ? answer[currentNumber].responseAnswer
                              ? t("progress.next")
                              : t("progress.skip")
                            : t("progress.skip")
                        }
                        onClick={() => {
                          this.nextNumber();
                        }}
                      />
                    </div>
                  </div>
                )}
                {!qsIsImage && (
                  <div>
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <div
                        style={{
                          position: "absolute",
                          left: -40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#B0B7BD",
                          borderRadius: "50%",
                          fontWeight: "bold",
                          color: "white",
                          width: 32,
                          height: 32,
                        }}>
                        {currentNumber + 1}
                      </div>
                      <Typography
                        style={{
                          top: 0,
                          left: 30,
                          color: palette.primary,
                          fontSize: 18,
                          paddingTop: 4,
                          marginLeft: 10,
                        }}>
                        {current.questionSummary}
                      </Typography>
                      <div>
                        <AnswerImages
                          options={current.answers}
                          onChange={option => {
                            this.setAnswer(currentNumber, option);
                          }}
                          style={{
                            marginLeft: 10,
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                          isImage={anIsImage}
                          value={
                            answer[currentNumber]
                              ? answer[currentNumber].responseAnswer
                              : null
                          }
                        />
                        {currentNumber < question.length - 1 && (
                          <NextBtn
                            fontWeight={"normal"}
                            fontSize={18}
                            text={
                              answer[currentNumber]
                                ? answer[currentNumber].responseAnswer
                                  ? t("progress.next")
                                  : t("progress.skip")
                                : t("progress.skip")
                            }
                            onClick={() => {
                              this.nextNumber();
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item lg={2} style={{ width: "20vw" }}>
                <div style={{ paddingLeft: 50 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}>
                    <img
                      src={IcnTimer}
                      width={50}
                      height={50}
                      alt="paper-grey"
                    />
                    <Typography
                      style={{
                        marginLeft: 20,
                        color: palette.primary,
                        fontWeight: "bold",
                        fontSize: 18,
                      }}>
                      {time_min}:{time_sec}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: filled === question.length ? "flex" : "none",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginTop: 10,
                      paddingTop: 10,
                      borderTop: "1px solid #E5E5E5",
                    }}>
                    <img
                      src={IcnInfoFill}
                      width={24}
                      height={24}
                      alt="info"
                      style={{ paddingRight: 5 }}
                    />
                    <Typography style={{ color: "#B0B7BD", paddingLeft: 5 }}>
                      {t("basictest:review")}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}
export default withTranslation()(Choices);
