import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Typography, Grid, Button } from "@material-ui/core";
import {
  IconQuestion,
  IconOption,
  IconOptionClicked,
  IconChecked,
  IconUncheck,
  ImgQuestionBackgroundTop,
} from "assets/img";
import { findObject, getItem, GetLocalItem, removeObject } from "app/utils";
import data from "../data";

const tableStyle = {
  width: "100%",
  border: "1px solid #B0B7BD",
  borderCollapse: "collapse",
  textAlign: "left",
};

const linkStyleActive = {
  color: palette.primary,
  fontSize: 18,
  borderBottom: "2px solid " + palette.primary,
  display: "flex",
  marginRight: 40,
  height: 40,
  alignItems: "center",
  cursor: "pointer",
};
const linkStyle = {
  color: palette.primary,
  marginRight: 40,
  fontSize: 18,
  display: "flex",
  borderBottom: "2px solid transparent",
  height: 40,
  alignItems: "center",
  cursor: "pointer",
};

const thStyle = {
  width: "100%",
  border: "1px solid #B0B7BD",
  fontWeight: "normal",
  fontSize: "18px",
  color: palette.primary,
  padding: "26px",
};

const tdStyle = {
  width: "100%",
  border: "1px solid #B0B7BD",
  fontWeight: "lighter",
  fontSize: "14px",
  color: palette.primary,
  padding: "18px 16px",
};

const btnStyle = {
  width: "100%",
  border: "1px solid #B0B7BD",
  fontWeight: "lighter",
  fontSize: "14px",
  color: palette.primary,
  padding: 0,
};

class SoftSkillEdit extends React.Component {
  constructor(props) {
    super(props);
    if (GetLocalItem("softSkillGet")) {
      data.soft_skill_api = GetLocalItem("softSkillGet");
    }

    this.data = JSON.stringify(this.props.data);
    this.state = {
      editVacancy: JSON.parse(this.data),
      menu: "ts",
      thinkingSkill: [],
      workingAttitude: [],
      relationshipSkill: [],
      softSkillHeaders: [],
      soft_skill_api: data.soft_skill_api,
    };
  }

  reRender() {
    this.setState({
      thinkingSkill: this.renderThinkingSkill(),
      workingAttitude: this.renderWorkingAttitude(),
      relationshipSkill: this.renderRelationshipSkill(),
    });
  }

  componentDidMount() {
    this.reRender();
  }

  renderThinkingSkill() {
    return this.state.soft_skill_api[0]
      ? this.state.soft_skill_api[0].softSkillHeaders.map((val, index) => {
          return (
            <tbody style={{ width: "100%" }} key={"thinkingSkill-" + index}>
              {val.softSkillDetails
                ? val.softSkillDetails.map((v, i) => {
                    return (
                      <tr style={{ width: "100%" }} key={i}>
                        {i === 0 ? (
                          <th
                            style={{ ...thStyle, width: "33%" }}
                            rowSpan={val.softSkillDetails.length}>
                            <Grid container>
                              <Grid item lg={8} md={8} sm={8}>
                                {val.softskillNameEng}
                              </Grid>
                              <Grid item lg={4} md={4} sm={4}>
                                <img
                                  className={"question-tag"}
                                  style={{ paddingTop: "12px" }}
                                  src={IconQuestion}
                                  alt={"question" + i}
                                  height="15vh"
                                  align="right"
                                />
                                <div
                                  className="question"
                                  style={{
                                    position: "absolute",
                                    marginLeft: -65,
                                    marginTop: -85,
                                  }}>
                                  <img
                                    src={ImgQuestionBackgroundTop}
                                    height={"95vh"}
                                    alt="question"
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      color: "white",
                                      fontSize: "12px",
                                      textTransform: "none",
                                      top: "10%",
                                      left: "2%",
                                      right: "2%",
                                      textAlign: "justify",
                                    }}>
                                    {getItem("lang") === "en"
                                      ? val.softskillDescEng
                                      : val.softskillDescIna}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </th>
                        ) : null}
                        <td
                          style={{
                            ...thStyle,
                            width: "57%",
                          }}>
                          <Grid container>
                            <Grid item lg={2} md={2} sm={2}>
                              <img
                                src={v.imageUrlEng}
                                alt={"answer" + i}
                                height="30vh"
                                align="left"
                              />
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              style={{ paddingTop: "6px" }}>
                              {v.softskillValueEng}
                            </Grid>
                            <Grid item lg={2} md={2} sm={2}>
                              <img
                                className={"question-tag"}
                                style={{ paddingTop: "8px" }}
                                src={IconQuestion}
                                alt={"question" + i}
                                height="15vh"
                                align="right"
                              />
                              <div
                                className="question"
                                style={{
                                  position: "absolute",
                                  marginLeft: -133,
                                  marginTop: -145,
                                }}>
                                <img
                                  src={ImgQuestionBackgroundTop}
                                  height={"152vh"}
                                  alt="question"
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    color: "white",
                                    fontSize: "12px",
                                    textTransform: "none",
                                    top: "5%",
                                    left: "2%",
                                    right: "2%",
                                    textAlign: "justify",
                                  }}>
                                  {getItem("lang") === "en"
                                    ? v.attributeEng.split("\r\n").map(val => {
                                        return (
                                          <div key={val}>
                                            {val}
                                            <br />
                                          </div>
                                        );
                                      })
                                    : v.attributeIna.split("\r\n").map(val => {
                                        return (
                                          <div key={val}>
                                            {val}
                                            <br />
                                          </div>
                                        );
                                      })}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </td>
                        <td style={{ ...btnStyle, width: "10%" }}>
                          {val.answerType === "Single" ? (
                            <Button
                              onClick={() => {
                                var newEdit = this.state.editVacancy;
                                var idx = newEdit.vacancySoftskills.find(
                                  p => p.softskillHeaderId === val.id
                                )
                                  ? newEdit.vacancySoftskills.findIndex(
                                      p => p.softskillHeaderId === val.id
                                    )
                                  : -1;

                                if (idx > -1) {
                                  newEdit.vacancySoftskills[
                                    idx
                                  ].softskillDetailId = v.id;
                                } else {
                                  var obj = {
                                    softskillHeaderId: val.id,
                                    softskillDetailId: v.id,
                                  };
                                  newEdit.vacancySoftskills.push(obj);
                                }

                                this.setState({ editVacancy: newEdit });
                                this.reRender();
                              }}
                              style={{
                                width: "30%",
                              }}>
                              <img
                                src={
                                  this.state.editVacancy.vacancySoftskills.find(
                                    p => p.softskillHeaderId === val.id
                                  )
                                    ? this.state.editVacancy.vacancySoftskills.find(
                                        p => p.softskillHeaderId === val.id
                                      ).softskillDetailId === v.id
                                      ? IconOptionClicked
                                      : IconOption
                                    : IconOption
                                }
                                alt={"check" + i}
                                height="20vh"
                                align="center"
                              />
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                var newEdit = this.state.editVacancy;

                                if (
                                  findObject(
                                    newEdit.vacancySoftskills,
                                    "softskillDetailId",
                                    v.id
                                  )
                                ) {
                                  newEdit.vacancySoftskills = removeObject(
                                    newEdit.vacancySoftskills,
                                    "softskillDetailId",
                                    v.id
                                  );
                                } else {
                                  newEdit.vacancySoftskills.push({
                                    softskillHeaderId: val.id,
                                    softskillDetailId: v.id,
                                  });
                                }

                                this.setState({ editVacancy: newEdit });
                                this.reRender();
                              }}
                              style={{
                                width: "30%",
                              }}>
                              <img
                                src={
                                  this.state.editVacancy.vacancySoftskills.find(
                                    p => p.softskillHeaderId === val.id
                                  )
                                    ? this.state.editVacancy.vacancySoftskills.find(
                                        p => p.softskillDetailId === v.id
                                      )
                                      ? IconChecked
                                      : IconUncheck
                                    : IconUncheck
                                }
                                alt={"check" + i}
                                height="20vh"
                                align="center"
                              />
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          );
        })
      : null;
  }

  renderWorkingAttitude() {
    return this.state.soft_skill_api[1]
      ? this.state.soft_skill_api[1].softSkillHeaders.map((val, index) => {
          return (
            <tbody style={{ width: "100%" }} key={"workingAttitude-" + index}>
              {val.softSkillDetails.map((v, i) => {
                return (
                  <tr style={{ width: "100%" }} key={i}>
                    {i === 0 ? (
                      <th
                        style={{ ...thStyle, width: "33%" }}
                        rowSpan={val.softSkillDetails.length}>
                        <Grid container>
                          <Grid item lg={8} md={8} sm={8}>
                            {val.softskillNameEng}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4}>
                            <img
                              className={"question-tag"}
                              style={{ paddingTop: "12px" }}
                              src={IconQuestion}
                              alt={"question" + i}
                              height="15vh"
                              align="right"
                            />
                            <div
                              className="question"
                              style={{
                                position: "absolute",
                                marginLeft: -73,
                                marginTop: -96,
                              }}>
                              <img
                                src={ImgQuestionBackgroundTop}
                                height={"107vh"}
                                alt="question"
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  color: "white",
                                  fontSize: "12px",
                                  textTransform: "none",
                                  top: "10%",
                                  left: "2%",
                                  right: "2%",
                                  textAlign: "justify",
                                }}>
                                {getItem("lang") === "en"
                                  ? val.softskillDescEng
                                  : val.softskillDescIna}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </th>
                    ) : null}
                    <td
                      style={{
                        ...thStyle,
                        width: "57%",
                      }}>
                      <Grid container>
                        <Grid item lg={2} md={2} sm={2}>
                          <img
                            src={v.imageUrlEng}
                            alt={"answer" + i}
                            height="30vh"
                            align="left"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          md={8}
                          sm={8}
                          style={{ paddingTop: "6px" }}>
                          {v.softskillValueEng}
                        </Grid>
                        <Grid item lg={2} md={2} sm={2}>
                          <img
                            className={"question-tag"}
                            style={{ paddingTop: "8px" }}
                            src={IconQuestion}
                            alt={"question" + i}
                            height="15vh"
                            align="right"
                          />
                          <div
                            className="question"
                            style={{
                              position: "absolute",
                              marginLeft: -106,
                              marginTop: -124,
                            }}>
                            <img
                              src={ImgQuestionBackgroundTop}
                              height={"130vh"}
                              alt="question"
                            />
                            <div
                              style={{
                                position: "absolute",
                                color: "white",
                                fontSize: "12px",
                                textTransform: "none",
                                top: "5%",
                                left: "2%",
                                right: "2%",
                                textAlign: "justify",
                              }}>
                              {getItem("lang") === "en"
                                ? v.attributeEng.split("\r\n").map(val => {
                                    return (
                                      <div key={val}>
                                        {val}
                                        <br />
                                      </div>
                                    );
                                  })
                                : v.attributeIna.split("\r\n").map(val => {
                                    return (
                                      <div key={val}>
                                        {val}
                                        <br />
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </td>
                    <td style={{ ...tdStyle, width: "10%" }}>
                      {val.answerType === "Single" ? (
                        <Button
                          onClick={() => {
                            var newEdit = this.state.editVacancy;
                            var idx = newEdit.vacancySoftskills.find(
                              p => p.softskillHeaderId === val.id
                            )
                              ? newEdit.vacancySoftskills.findIndex(
                                  p => p.softskillHeaderId === val.id
                                )
                              : -1;

                            if (idx > -1) {
                              newEdit.vacancySoftskills[idx].softskillDetailId =
                                v.id;
                            } else {
                              var obj = {
                                softskillHeaderId: val.id,
                                softskillDetailId: v.id,
                              };
                              newEdit.vacancySoftskills.push(obj);
                            }

                            this.setState({ editVacancy: newEdit });
                            this.reRender();
                          }}
                          style={{
                            width: "30%",
                          }}>
                          <img
                            src={
                              this.state.editVacancy.vacancySoftskills.find(
                                p => p.softskillHeaderId === val.id
                              )
                                ? this.state.editVacancy.vacancySoftskills.find(
                                    p => p.softskillHeaderId === val.id
                                  ).softskillDetailId === v.id
                                  ? IconOptionClicked
                                  : IconOption
                                : IconOption
                            }
                            alt={"check" + i}
                            height="20vh"
                            align="center"
                          />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            var newEdit = this.state.editVacancy;

                            if (
                              findObject(
                                newEdit.vacancySoftskills,
                                "softskillDetailId",
                                v.id
                              )
                            ) {
                              newEdit.vacancySoftskills = removeObject(
                                newEdit.vacancySoftskills,
                                "softskillDetailId",
                                v.id
                              );
                            } else {
                              newEdit.vacancySoftskills.push({
                                softskillHeaderId: val.id,
                                softskillDetailId: v.id,
                              });
                            }

                            this.setState({ editVacancy: newEdit });
                            this.reRender();
                          }}
                          style={{
                            width: "30%",
                          }}>
                          <img
                            src={
                              this.state.editVacancy.vacancySoftskills.find(
                                p => p.softskillHeaderId === val.id
                              )
                                ? this.state.editVacancy.vacancySoftskills.find(
                                    p => p.softskillDetailId === v.id
                                  )
                                  ? IconChecked
                                  : IconUncheck
                                : IconUncheck
                            }
                            alt={"check" + i}
                            height="20vh"
                            align="center"
                          />
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          );
        })
      : null;
  }

  renderRelationshipSkill() {
    return this.state.soft_skill_api[2]
      ? this.state.soft_skill_api[2].softSkillHeaders.map((val, index) => {
          return (
            <tbody style={{ width: "100%" }} key={"relationshipSkill-" + index}>
              {val.softSkillDetails.map((v, i) => {
                return (
                  <tr style={{ width: "100%" }} key={i}>
                    {i === 0 ? (
                      <th
                        style={{ ...thStyle, width: "33%" }}
                        rowSpan={val.softSkillDetails.length}>
                        <Grid container>
                          <Grid item lg={8} md={8} sm={8}>
                            {val.softskillNameEng}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4}>
                            <img
                              className={"question-tag"}
                              style={{ paddingTop: "12px" }}
                              src={IconQuestion}
                              alt={"question" + i}
                              height="15vh"
                              align="right"
                            />
                            <div
                              className="question"
                              style={{
                                position: "absolute",
                                marginLeft: -45,
                                marginTop: -75,
                              }}>
                              <img
                                src={ImgQuestionBackgroundTop}
                                height={"85vh"}
                                alt="question"
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  color: "white",
                                  fontSize: "12px",
                                  textTransform: "none",
                                  top: "10%",
                                  left: "2%",
                                  right: "2%",
                                  textAlign: "justify",
                                }}>
                                {getItem("lang") === "en"
                                  ? val.softskillDescEng
                                  : val.softskillDescIna}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </th>
                    ) : null}
                    <td
                      style={{
                        ...thStyle,
                        width: "57%",
                      }}>
                      <Grid container>
                        <Grid item lg={2} md={2} sm={2}>
                          <img
                            src={v.imageUrlEng}
                            alt={"answer" + i}
                            height="30vh"
                            align="left"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          md={8}
                          sm={8}
                          style={{ paddingTop: "6px" }}>
                          {v.softskillValueEng}
                        </Grid>
                        <Grid item lg={2} md={2} sm={2}>
                          <img
                            className={"question-tag"}
                            style={{ paddingTop: "8px" }}
                            src={IconQuestion}
                            alt={"question" + i}
                            height="15vh"
                            align="right"
                          />
                          <div
                            className="question"
                            style={{
                              position: "absolute",
                              marginLeft: -100,
                              marginTop: -120,
                            }}>
                            <img
                              src={ImgQuestionBackgroundTop}
                              height={"125vh"}
                              alt="question"
                            />
                            <div
                              style={{
                                position: "absolute",
                                color: "white",
                                fontSize: "12px",
                                textTransform: "none",
                                top: "5%",
                                left: "2%",
                                right: "2%",
                                textAlign: "justify",
                              }}>
                              {getItem("lang") === "en"
                                ? v.attributeEng.split("\r\n").map(val => {
                                    return (
                                      <div key={val}>
                                        {val}
                                        <br />
                                      </div>
                                    );
                                  })
                                : v.attributeIna.split("\r\n").map(val => {
                                    return (
                                      <div key={val}>
                                        {val}
                                        <br />
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </td>
                    <td style={{ ...tdStyle, width: "10%" }}>
                      {val.answerType === "Single" ? (
                        <Button
                          onClick={() => {
                            var newEdit = this.state.editVacancy;
                            var idx = newEdit.vacancySoftskills.find(
                              p => p.softskillHeaderId === val.id
                            )
                              ? newEdit.vacancySoftskills.findIndex(
                                  p => p.softskillHeaderId === val.id
                                )
                              : -1;

                            if (idx > -1) {
                              newEdit.vacancySoftskills[idx].softskillDetailId =
                                v.id;
                            } else {
                              var obj = {
                                softskillHeaderId: val.id,
                                softskillDetailId: v.id,
                              };
                              newEdit.vacancySoftskills.push(obj);
                            }

                            this.setState({ editVacancy: newEdit });
                            this.reRender();
                          }}
                          style={{
                            width: "30%",
                          }}>
                          <img
                            src={
                              this.state.editVacancy.vacancySoftskills.find(
                                p => p.softskillHeaderId === val.id
                              )
                                ? this.state.editVacancy.vacancySoftskills.find(
                                    p => p.softskillHeaderId === val.id
                                  ).softskillDetailId === v.id
                                  ? IconOptionClicked
                                  : IconOption
                                : IconOption
                            }
                            alt={"check" + i}
                            height="20vh"
                            align="center"
                          />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            var newEdit = this.state.editVacancy;

                            if (
                              findObject(
                                newEdit.vacancySoftskills,
                                "softskillDetailId",
                                v.id
                              )
                            ) {
                              newEdit.vacancySoftskills = removeObject(
                                newEdit.vacancySoftskills,
                                "softskillDetailId",
                                v.id
                              );
                            } else {
                              newEdit.vacancySoftskills.push({
                                softskillHeaderId: val.id,
                                softskillDetailId: v.id,
                              });
                            }

                            this.setState({ editVacancy: newEdit });
                            this.reRender();
                          }}
                          style={{
                            width: "30%",
                          }}>
                          <img
                            src={
                              this.state.editVacancy.vacancySoftskills.find(
                                p => p.softskillHeaderId === val.id
                              )
                                ? this.state.editVacancy.vacancySoftskills.find(
                                    p => p.softskillDetailId === v.id
                                  )
                                  ? IconChecked
                                  : IconUncheck
                                : IconUncheck
                            }
                            alt={"check" + i}
                            height="20vh"
                            align="center"
                          />
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          );
        })
      : null;
  }

  render() {
    const {
      menu,
      thinkingSkill,
      workingAttitude,
      relationshipSkill,
      editVacancy,
    } = this.state;
    const { t } = this.props;
    return (
      <Grid container style={{ padding: "4% 6% 8%" }} className={"soft-skill"}>
        <Grid item lg={12} md={12} sm={12}>
          <Typography
            style={{
              fontSize: "24px",
              color: palette.primary,
            }}>
            Soft Skill
          </Typography>
        </Grid>

        <Grid item lg={8} md={8} sm={8} style={{ marginTop: "4%" }}>
          <Grid
            container
            style={{
              borderBottom: "1px solid #DFE5F4",
            }}>
            <Grid item lg={4} md={4} sm={4}>
              <div
                style={menu === "ts" ? linkStyleActive : linkStyle}
                onClick={() => {
                  this.setState({ menu: "ts" });
                }}>
                Thinking Skill
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={4}>
              <div
                style={menu === "wa" ? linkStyleActive : linkStyle}
                onClick={() => {
                  this.setState({ menu: "wa" });
                }}>
                Working Attitude
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={4}>
              <div
                style={menu === "rs" ? linkStyleActive : linkStyle}
                onClick={() => {
                  this.setState({ menu: "rs" });
                }}>
                Relationship Skill
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={8} md={8} sm={8} style={{ marginTop: "3%" }}>
          <table style={tableStyle}>
            {menu === "ts"
              ? thinkingSkill
              : menu === "wa"
              ? workingAttitude
              : relationshipSkill}
          </table>
        </Grid>

        <Grid
          item
          lg={8}
          md={8}
          sm={8}
          style={{ marginTop: "4%", paddingLeft: "54%" }}>
          <Button
            onClick={() => {
              this.setState({ editVacancy: this.props.data });
              this.props.onCancel();
            }}
            style={{
              backgroundColor: "white",
              border: "1px solid #2E69B2",
              borderRadius: "4px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
              color: palette.primary,
              fontSize: "14px",
              textTransform: "none",
              padding: "1px 32px 2px",
            }}>
            {t("vacancy:create.confirmation.btnCancel")}
          </Button>
        </Grid>
        <Grid item lg={4} md={4} sm={4} style={{ marginTop: "4%" }}>
          <Button
            onClick={() => this.props.onSave(editVacancy)}
            style={{
              backgroundColor: palette.primary,
              border: "1px solid #2E69B2",
              borderRadius: "4px",
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
              color: "white",
              fontSize: "14px",
              textTransform: "none",
              padding: "1px 32px 2px",
            }}>
            {t("vacancy:create.confirmation.btnSave")}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(SoftSkillEdit);
