import React from "react";
import { IcnPaperQuestion, IcnTimer } from "assets/img";
import { Grid, Typography, LinearProgress, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { getItem, setItem, RequestPut, stnumRegex } from "app/utils";
import "./cognitive.css";
import Information from "./Information";
import Sample from "./sample";
import { withTranslation } from "react-i18next";
class Freetext extends React.Component {
  doc = document;
  timeInterval;
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error2: false,
      alldata: { questionAttempts: [] },
      title: this.props.title,
      currentNumber: 0,
      filled: 0,
      last_number: false,
      not_filled: 0,
      anIsImage: this.props.data.ansImage,
      qsIsImage: this.props.data.qsImage,
      data: this.props.data,
      total: this.props.totalQuestion,
      timer: 3,
      open: false,
      question: [],
      answer: [],
      progress: 0,
      error: false,
      time_min: "00",
      time_sec: "00",
      inst: !getItem(this.props.id + "-ins-done") ? true : false,
      showButton: true,
      time_milsec: getItem(this.props.id),
    };
    this.keyUp = this.keyUp.bind(this);
  }
  componentDidMount() {
    if (!this.state.inst) {
      this.startCount();
      this.doc.addEventListener("keyup", this.keyUp);
    }
    this.reset();
  }
  componentWillUnmount() {
    this.doc.removeEventListener("keyup", this.keyUp);
  }
  keyUp(e) {
    if (e.keyCode === 13) {
      this.nextNumber();
    }
  }
  reset() {
    if (this.props.data) {
      const question = this.props.data.questionAttempts;
      var answer = [];
      const progress = (1 / question.length) * 100;
      question.forEach((v, k) => {
        answer.push({ id: v.id, responseAnswer: null });
      }, this.setState({ answer: answer, question: question, not_filled: question.length, progress: progress, alldata: this.props.data }));
    }
  }

  nextNumber() {
    const { currentNumber, answer } = this.state;
    if (currentNumber < answer.length - 1) {
      this.setState({ value: "" });
      if (!answer[currentNumber].responseAnswer) {
        this.setState({ error: true });
      } else if (!answer[currentNumber].responseAnswer.trim()) {
        this.setState({ error: true });
      } else {
        const newNumber = this.state.currentNumber + 1;
        const progress = ((newNumber + 1) / answer.length) * 100;
        this.setState({
          currentNumber: newNumber,
          progress: progress,
          showButton: currentNumber === answer.length - 1 ? false : true,
          error: false,
        });
      }
    } else {
      this.setState({ last_number: true });
    }
  }

  countFilled(data, filled = true) {
    if (filled) {
      return data.filter(x => x.responseAnswer !== null).length;
    } else {
      return data.filter(x => x.responseAnswer === null).length;
    }
  }

  countTime() {
    if (!getItem(this.props.id)) {
      var timeLimit = getItem(this.props.id + "-tl");
      var end = new Date().getTime() + timeLimit * 1000 * 60;
      setItem(this.props.id, end);
    }
    const time_end = getItem(this.props.id);
    const time_now = new Date().getTime();
    const time_diff = time_end - time_now;
    const seconds = Math.floor((time_diff / 1000) % 60);
    const minutes = Math.floor((time_diff / 1000 / 60) % 60);

    if (seconds > 0 || minutes > 0) {
      this.setState({
        time_milsec: time_diff,
        time_sec: seconds < 10 ? "0" + seconds : seconds,
        time_min: minutes < 10 ? "0" + minutes : minutes,
      });
    } else {
      this.submitResult();
    }
  }
  submitResult() {
    RequestPut("questionattempts", this.state.answer)
      .then(r => {
        this.props.history.push("/basic-test");
        clearInterval(this.timeInterval);
      })
      .catch(e => {
        clearInterval(this.timeInterval);
        console.log("Time Finish->Cognitive", e);
      });
  }
  startCount() {
    this.setState({ open: false }, () => {
      this.timeInterval = setInterval(() => {
        this.countTime();
      }, 1000);
    });
  }

  finishIntscruction() {
    this.setState({ inst: false, open: true });
    setItem(this.props.id + "-ins-done", true);
    this.doc.addEventListener("keyup", this.keyUp);
  }

  setAnswer(index, value) {
    var tmp_answer = this.state.answer;
    tmp_answer[index].responseAnswer = value;
    this.setState({
      answer: tmp_answer,
      value: value,
    });
  }

  render() {
    const {
      question,
      currentNumber,
      title,
      qsIsImage,
      error,
      time_min,
      timer,
      open,
      total,
      time_sec,
      progress,
      inst,
      alldata,
      error2,
      value,
      last_number,
    } = this.state;
    const current = question[currentNumber] ? question[currentNumber] : {};
    const { order, sortorder, examTime } = this.props.root;
    const od = order ? order : sortorder;

    const { t } = this.props;
    const ins = t("basictest:cognitiveTest.instruction", {
      returnObjects: true,
    });
    return (
      <div>
        {alldata.questionAttempts.map((v, k) => {
          var question = [];
          if (qsIsImage) {
            question.push(
              <img
                style={{ display: "none" }}
                key={v.questionId}
                src={v.questionSummary}
                alt={v.questionId}
              />
            );
          }
          return question;
        })}
        <Information
          name={t("basictest:cognitiveTest.title")}
          timer={timer}
          open={open}
          total={total}
          title={title}
          onFinish={() => this.startCount()}
        />
        {inst && (
          <Sample
            title={title}
            order={od}
            instruction={ins[od - 1]}
            time={examTime}
            onFinish={() => this.finishIntscruction()}
          />
        )}
        {!inst && (
          <div>
            <Grid
              container
              justify="flex-start"
              style={{ width: "100vw", padding: 40, paddingTop: 60 }}>
              <Grid item lg={2}>
                <LinearProgress
                  className={"freetext-progress"}
                  variant="determinate"
                  value={progress}
                />
                <Typography
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    fontWeight: "bold",
                    color: "#ccc",
                  }}>
                  {currentNumber + 1}/{question.length}{" "}
                  {t("basictest:answered")}
                </Typography>
              </Grid>
              <Grid
                item
                lg={8}
                style={{ width: "50vw", paddingLeft: 40, paddingRight: 40 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: 24,
                      color: palette.primary,
                    }}>
                    {title}
                  </Typography>
                </div>
                {qsIsImage && (
                  <div>
                    <div
                      style={{
                        marginTop: 40,
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}>
                      <div
                        style={{
                          width: 300,
                          display: "flex",
                          justifyContent: "flex-end",
                          position: "relative",
                        }}>
                        <div
                          style={{
                            position: "absolute",
                            top: 15,
                            left: 35,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#B0B7BD",
                            borderRadius: "50%",
                            fontWeight: "bold",
                            color: "white",
                            width: 32,
                            height: 32,
                          }}>
                          {currentNumber + 1}
                        </div>
                        <img
                          id={current.id}
                          key={current.id}
                          style={{
                            position: "absolute",
                            bottom: 25,
                            right: 30,
                          }}
                          src={current.questionSummary}
                          alt="Question Background"
                          width={220}
                          height={220}
                        />
                        <img
                          src={IcnPaperQuestion}
                          alt="Question Background"
                          width={274}
                          height={329}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: 40,
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "50%",
                          alignItems: "center",
                        }}>
                        <input
                          id={"input" + current.id}
                          key={current.id}
                          autoFocus
                          // onBlur={e => {
                          //   try {
                          //     document
                          //       .getElementById("input" + current.id)
                          //       .focus();
                          //   } catch (error) {}
                          // }}
                          maxLength={2}
                          onChange={e => {
                            if (stnumRegex.test(e.target.value)) {
                              if (!last_number) {
                                this.setAnswer(currentNumber, e.target.value);
                              }
                            } else {
                              return false;
                            }
                          }}
                          value={value}
                          style={{
                            fontFamily: "inherit",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            border: "none",
                            fontSize: 36,
                            padding: 5,
                            paddingLeft: 20,
                            paddingRight: 20,
                            width: 60,
                            height: 120,
                            backgroundColor: "rgb(227, 229, 231)",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "#ccc",
                            position: "absolute",
                            textAlign: "center",
                          }}
                        />

                        <div
                          style={{
                            marginTop: 220,
                          }}>
                          {error && (
                            <p style={{ color: "red" }}>
                              {t("basictest:required")}
                            </p>
                          )}
                          {error2 && (
                            <p style={{ color: "red" }}>
                              {t("basictest:required")}
                            </p>
                          )}
                          {!last_number && (
                            <div
                              style={{
                                display: this.state.showButton
                                  ? "flex"
                                  : "none",
                                flexDirection: "row",
                                alignItems: "center",
                              }}>
                              <Button
                                style={{
                                  backgroundColor: palette.primary,
                                  fontWeight: "bold",
                                  marginRight: 20,
                                }}
                                color="primary"
                                variant="contained"
                                onClick={() => this.nextNumber()}>
                                OK
                              </Button>
                              <p>
                                press
                                <strong> ENTER</strong>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item lg={2} style={{ width: "20vw" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <img src={IcnTimer} width={50} height={50} alt="paper-grey" />
                  <Typography
                    style={{
                      marginLeft: 20,
                      color: palette.primary,
                      fontWeight: "bold",
                      fontSize: 18,
                    }}>
                    {time_min}:{time_sec}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}
export default withTranslation()(Freetext);
