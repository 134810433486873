import { decorate, observable, action } from "mobx";
class General {
  loading = false;
  pageTitle = null;
  visible = false;
  showDialog = false;
  setVisible() {
    this.visible = !this.visible;
  }
}
const general = decorate(General, {
  loading: observable,
  pageTitle: observable,
  visible: observable,
  setVisible: action,
  showDialog: observable,
});
export default new general();
