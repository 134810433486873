import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

/**
 * @extends {React.Component<{description:string, title:string}>}
 */
class MyHelmet extends React.Component {
  static propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
  };
  static defaultProps = {
    description: null,
    title: process.env.REACT_APP_NAME,
  };
  componentDidMount() {
    const desc = document.getElementsByName("description")[0];
    const { description } = this.props;
    if (description) {
      desc.content = description;
    }
  }
  render() {
    const { title } = this.props;
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {process.env.REACT_APP_NAME}
          {title ? " - " + title : ""}
        </title>
      </Helmet>
    );
  }
}
export default MyHelmet;
