import { observable, decorate, action } from "mobx";
class BasicTestData {
  ordering = [];
  capable = [];
  cognitive = [];
  cognitiveIntro = true;
  interestIntro = true;
  energyIntro = true;
  stepper = {
    nextBtn: false,
    backBtn: false,
    activeStep: 0,
    footer: false,
    length: 0,
  };
  setOrdering(data) {
    data.map(v => {
      return this.ordering.push({
        id: v.id,
        responseAnswer: v.responseAnswer.toString(),
      });
    });
  }
  setCapable(data) {
    data.map(v => {
      return this.capable.push({
        id: v.id,
        responseAnswer: v.responseAnswer.toString(),
      });
    });
  }
  setCognitive(data) {
    data.map(v => {
      return this.cognitive.push({
        id: v.id,
        responseAnswer: v.responseAnswer.toString(),
      });
    });
  }
  showNext() {
    this.stepper.nextBtn = !this.stepper.nextBtn;
  }
  showBack() {
    this.stepper.backBtn = !this.stepper.backBtn;
  }
}

const basicTestData = decorate(BasicTestData, {
  cognitive: observable,
  interestIntro: observable,
  ordering: observable,
  setOrdering: action,
  setCapable: action,
  setCognitive: action,
  showNext: action,
  showBack: action,
  stepper: observable,
});

export default new basicTestData();
