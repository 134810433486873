import React from "react";
import { withTranslation } from "react-i18next";
import { MyHelmet, ApplicantHeader, DialogueInfo } from "app/components";
import SidePanel from "../side-panel";
import { palette } from "assets/css/main";
import { ImgInterview, IcnPlayVideo } from "assets/img";
import { RequestGet, AppHistory, getItem, RequestPut } from "app/utils";
import { Button } from "@material-ui/core";
import { ImgVideoThumb } from "assets/img";
import VideoPlayer from "./video-player";
import alertData from "app/components/alert/alert-data";
import DialogueConfirm from "app/components/modal/confirm";
const buttonStyle = {
  textTransform: "capitalize",
  border: "1px solid " + palette.primary,
  color: palette.primary,
  margin: "0px 10px",
  padding: "5px 30px",
  fontWeight: "bold",
  boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
};

export default withTranslation()(
  class VideoInterview extends React.Component {
    state = {
      profile: undefined,
      alreadyGet: false,
      dataQuestion: [],
      video: [],
      current: 0,
      videoUrl: null,
      openVideo: false,
      indexPlay: 0,
      openRequest: false,
      openInfo: false,
    };
    componentDidMount() {
      this.getDataVideo();
    }

    checkRequest = () => {
      const { t } = this.props;
      RequestGet("InterviewVideo/Status")
        .then(res => {
          const { status } = res.data;
          if (status === 0) {
            this.setState({
              openRequest: true,
            });
          } else if (status === 1) {
            this.setState({
              openInfo: true,
              infoTitle: t("cv:videoInterview.archived.requestWait.title"),
              infoContent: t("cv:videoInterview.archived.requestWait.content"),
            });
          } else if (status === 2) {
            alertData(t("cv:videoInterview.archived.failed"), "error");
          }
        })
        .catch(e => {
          console.log(e);
          this.setState({
            openRequest: true,
          });
        });
    };

    submitRequest = () => {
      const { t } = this.props;

      RequestPut("InterviewVideo/Restore")
        .then(() => {
          this.setState({
            openRequest: false,
            openInfo: true,
            infoTitle: t("cv:videoInterview.archived.requestSent.title"),
            infoContent: t("cv:videoInterview.archived.requestSent.content"),
          });
        })
        .catch(() => {
          this.setState({ openRequest: false });
        });
    };
    getQuestion() {
      RequestGet("interviewquestion")
        .then(r => {
          this.setState({
            dataQuestion: r.data.interviewVideoQuestions,
            alreadyGet: true,
            isCompleted:
              r.data.interviewVideoQuestions.length === this.state.video.length
                ? true
                : false,
          });
        })
        .catch(e => {
          this.setState({ alreadyGet: true });
        });
    }
    getDataVideo() {
      RequestGet("InterviewVideo")
        .then(r => {
          this.setState(
            {
              video: r.data,
              current: r.data.length,
            },
            () => {
              this.getQuestion();
            }
          );
        })
        .catch(e => {
          this.setState({ lastQuestion: false, current: 0, alreadyGet: true });
        });
    }
    render() {
      const {
        profile,
        alreadyGet,
        isCompleted,
        dataQuestion,
        video,
        current,
        openVideo,
        indexPlay,
        infoTitle,
        infoContent,
        openRequest,
        openInfo,
      } = this.state;
      const { t } = this.props;
      const description = t("cv:videoInterview.description", {
        returnObjects: true,
      });
      return (
        <div>
          <MyHelmet title="Video Interview" />
          <ApplicantHeader menu="home" />
          <DialogueConfirm
            onClose={() => this.setState({ openRequest: false })}
            btnYes={"Request"}
            btnNo={t("button.cancel")}
            onOK={this.submitRequest}
            open={openRequest}
            title={t("cv:videoInterview.archived.request.title")}
            content={t("cv:videoInterview.archived.request.content")}
            contentDetail={t(
              "cv:videoInterview.archived.request.contentDetail"
            )}
          />
          <DialogueInfo
            title={infoTitle}
            open={openInfo}
            content={infoContent}
            onClose={() => this.setState({ openInfo: false })}
          />
          {video.length > 0 && (
            <VideoPlayer
              open={openVideo}
              videos={video}
              questions={dataQuestion}
              index={indexPlay}
              onClose={(archived, errorCode) => {
                if (archived) {
                  this.setState({ openVideo: false }, this.checkRequest());
                } else {
                  if (errorCode === 4) {
                    this.setState({
                      openInfo: true,
                      infoTitle: t("cv:videoInterview.unsupported.title"),
                      infoContent: t("cv:videoInterview.unsupported.content"),
                    });
                  }
                  this.setState({ openVideo: false });
                }
              }}
            />
          )}
          <div style={{ padding: "100px 200px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: 300 }}>
                <SidePanel profile={profile} />
              </div>
              <div style={{ marginLeft: 20, minWidth: 900 }}>
                <div
                  style={{
                    backgroundColor: palette.primary,
                    padding: "20px",
                    color: "white",
                    borderTopRightRadius: 5,
                    borderTopLeftRadius: 5,
                  }}>
                  {t("cv:videoInterview.title")}
                </div>
                <div
                  style={{
                    minHeight: 520,
                    color: palette.primary,
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    padding: 20,
                    width: "100%",
                    boxSizing: "border-box",
                    boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
                  }}>
                  {/* diplay when ready */}
                  {alreadyGet && (
                    <>
                      {!isCompleted && (
                        <>
                          <img
                            src={ImgInterview}
                            alt={"interview"}
                            width={92}
                            height={165}
                          />
                          <div
                            style={{
                              width: 525,
                              textAlign: "center",
                              fontWeight: "lighter",
                            }}>
                            {description.map((v, k) => {
                              return (
                                <p
                                  key={k}
                                  style={{
                                    margin: k === 0 ? "20px 0px" : "5px 0px",
                                  }}>
                                  {v}
                                </p>
                              );
                            })}
                          </div>
                          {current > 0 && (
                            <>
                              <span style={{ marginTop: 20 }}>
                                {current +
                                  "/" +
                                  dataQuestion.length +
                                  " " +
                                  t("cv:videoInterview.questionAnswered")}
                              </span>
                            </>
                          )}
                          <Button
                            onClick={() => {
                              AppHistory.push(
                                "/user/video-interview-equipment"
                              );
                            }}
                            style={{
                              ...buttonStyle,
                              color: "white",
                              marginTop: 20,
                              backgroundColor: palette.primary,
                            }}>
                            {current === 0
                              ? t("button.start")
                              : t("button.continue")}
                          </Button>
                        </>
                      )}
                      {isCompleted && (
                        <>
                          <div style={{ width: "100%" }}>
                            {dataQuestion.map((v, k) => {
                              return (
                                <div
                                  key={"quest-k" + k}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    borderBottom:
                                      "1.5px solid rgba(46, 105, 178,0.5)",
                                    marginBottom: 20,
                                  }}>
                                  <div
                                    style={{
                                      position: "relative",
                                      backgroundSize: "contain",
                                      backgroundRepeat: "no-repeat",
                                      width: 350,
                                      height: 300,
                                      backgroundImage:
                                        "url(" + ImgVideoThumb + ")",
                                    }}>
                                    <img
                                      src={IcnPlayVideo}
                                      alt="play"
                                      onClick={() => {
                                        this.setState({
                                          openVideo: true,
                                          indexPlay: k,
                                        });
                                      }}
                                      style={{
                                        zIndex: 3,
                                        position: "absolute",
                                        top: 100,
                                        left: 135,
                                        cursor: "pointer",
                                      }}
                                    />
                                    <div
                                      style={{
                                        width: 330,
                                        height: 248,
                                        backgroundColor: "black",
                                        opacity: 0.5,
                                        marginTop: 5,
                                        borderRadius: 4,
                                        position: "absolute",
                                        zIndex: 2,
                                      }}></div>
                                    <span
                                      style={{
                                        color: "white",
                                        position: "absolute",
                                        top: 30,
                                        textTransform: "uppercase",
                                        fontSize: 24,
                                        fontWeight: "bold",
                                        left: 20,
                                        zIndex: 1,
                                        width: 200,
                                      }}>
                                      {t("cv:videoInterview.title")}
                                    </span>
                                    <span
                                      style={{
                                        color: "white",
                                        position: "absolute",
                                        bottom: 85,
                                        textTransform: "uppercase",
                                        fontSize: 24,
                                        fontWeight: "bold",
                                        left: 20,
                                        zIndex: 1,
                                        width: 200,
                                      }}>
                                      {k + 1 < 10 ? "0" + (k + 1) : k + 1}
                                    </span>
                                    {/* <video width="350" height="300" controls>
                                      <source src={video[k].videoUrl} />
                                    </video> */}
                                  </div>
                                  <div
                                    style={{ padding: "4px 25px", width: 400 }}>
                                    <span style={{ fontSize: 18 }}>
                                      {t("cv:videoInterview.question") +
                                        " " +
                                        (k + 1)}
                                    </span>
                                    <p style={{ fontWeight: "lighter" }}>
                                      {getItem("lang") === "en"
                                        ? v.interviewQuestionEng
                                        : v.interviewQuestionIna}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
