import React from "react";
import { withTranslation } from "react-i18next";
import { Dialog, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { getItem, findObject, removeObject } from "app/utils";
import OptionWork from "./option-work";
const buttonStyle = {
  textTransform: "capitalize",
  border: "1px solid " + palette.primary,
  color: palette.primary,
  margin: "0px 10px",
  padding: "5px 30px",
  fontWeight: "bold",
};
export default withTranslation()(
  class ModalWorkDetail extends React.Component {
    state = {
      answerDetails: [],
      answerId: "",
      answerTextEng: "",
      answerTextIna: "",
      answered: [],
    };

    getData() {
      this.setState(this.props.data, () => {
        if (!this.props.isEdit) {
          this.setState({ answered: [] });
        }
      });
    }

    setValue(v) {
      const { answered } = this.state;
      var tmp = answered;
      var val = v[0];
      if (val.answerTextEng.indexOf("No Preference") === 0) {
        tmp = [{ answerId: val.answerId, answerTextEng: val.answerTextEng }];
      } else if (findObject(answered, "answerId", val.answerId)) {
        if (tmp.length > 1) {
          tmp = removeObject(tmp, "answerId", val.answerId);
        }
      } else {
        tmp = removeObject(tmp, "answerTextEng", "No Preferences");
        tmp = removeObject(tmp, "answerTextEng", "No Preference");
        tmp.push({ answerId: val.answerId });
      }
      this.setState({ answered: tmp });
    }

    confirm() {
      const { answered, answerId, answerTextEng } = this.state;
      if (answered.length) {
        this.props.onClose({
          answerId: answerId,
          otherValue: "",
          otherId: "",
          answerTextEng: answerTextEng,
          answerDetail: answered,
        });
      } else {
        this.props.onClose(null);
      }
    }

    render() {
      const { open, t, number } = this.props;
      const {
        answerDetails,
        answerTextEng,
        answerTextIna,
        answered,
      } = this.state;
      return (
        <Dialog
          fullScreen
          onEnter={() => this.getData()}
          className={"Custom-Dialog "}
          aria-labelledby="customized-dialog-title"
          disableBackdropClick
          open={open}
          style={{ backgroundColor: "transparent" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              paddingTop: "50px",
            }}>
            <div
              id="content-detail"
              style={{
                width: 900,
                minHeight: 650,
                padding: 30,
                position: "relative",
              }}>
              <div
                style={{
                  backgroundColor: "white",
                  width: 900,
                  minHeight: 580,
                  boxSizing: "border-box",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                }}>
                <div
                  style={{
                    height: 20,
                    backgroundColor: palette.primary,
                    padding: "20px 20px",
                    color: "white",
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    fontSize: 18,
                  }}>
                  {t("cv:workReady.form.specific")}
                </div>
                <div style={{ padding: 20 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      color: palette.primary,
                      height: 450,
                      overflowY: "auto",
                    }}>
                    <span
                      style={{
                        backgroundColor: palette.darkGrey,
                        color: "white",
                        borderRadius: "50%",
                        padding: "7px 12.2px",
                        height: 17,
                      }}>
                      {number}
                    </span>
                    <div
                      style={{
                        padding: "7px 15px",
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <span style={{ fontWeight: "lighter", fontSize: 18 }}>
                        {getItem("lang") === "en"
                          ? answerTextEng
                          : answerTextIna}
                      </span>
                      <span
                        style={{
                          fontWeight: "lighter",
                          color: "#515D6E",
                          fontStyle: "italic",
                          fontSize: 12,
                        }}>
                        {t("cv:workReady.form.multiple")}
                      </span>
                      <OptionWork
                        isNumber={true}
                        onChange={v => {
                          this.setValue(v);
                        }}
                        reset={this.props.isReset}
                        multiple={true}
                        value={answered}
                        options={answerDetails}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      onClick={() => {
                        this.confirm();
                      }}
                      style={{
                        ...buttonStyle,
                        backgroundColor:
                          answered.length > 0 ? palette.primary : "white",
                        color: answered.length > 0 ? "white" : palette.primary,
                      }}>
                      {answered.length > 0
                        ? t("button.back")
                        : t("button.cancel")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      );
    }
  }
);
