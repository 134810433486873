import React from "react";
import {
  getItem,
  RequestPut,
  General,
  removeArrayWithIndex,
  AppHistory,
  RequestGet,
  RequestPost,
  findObject,
} from "app/utils";
import { palette } from "assets/css/main";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
  Checkbox,
  Typography,
  Grid,
} from "@material-ui/core";
import { PRIMARY_BUTTON } from "assets/css/main";
import { CVFooter } from "./footer-cv";
import history from "app/utils/general/history";
import alertData from "app/components/alert/alert-data";
import data from "app/pages/applicant/initialize/bio-data/data";
import { Add, CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";
import DialogueConfirm from "app/components/modal/confirm";
import { IconEditCV, IconDeleteCV, IconFolder } from "assets/img";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import DatePickerValidator from "app/components/general/DatePickerValidator";
import DialogueWorkExp from "./popup-workExp";
import DialogueAdditionalQuestion from "./popup-additionalQuestion";
import SelectSearch from "react-select";

class CVWorkExperience extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
    this.state = {
      biodata_workExp: data.biodata_workExp,
      biodata: props.data,
      ...data.work_experience,
      ...JSON.parse(getItem("user_info")),
      anchorEl: null,
      open: false,
      opendelete: false,
      openConfirmation: false,
      openWorkExp: false,
      openAdditionalQuestion: false,
      role: "",
      isedit: false,
      listBusinessArea: [],
    };
    General.visible = false;
    data.work_experience.userId = getItem("user_id");
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("selectRequired");
  }

  handleChange(obj) {
    this.setState(obj);
    data.setWorkingExperience(obj);
    var listWorksexp = {
      listWorksexp: { ...this.state.biodata.listWorks, ...data.listWorks },
    };
    this.setState({
      biodata: { ...this.state.biodata, ...listWorksexp },
    });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, opendelete: false });
  };

  getBiodataWorkExp(key = null) {
    RequestGet("biodata/workexp")
      .then(res => {
        this.setState({ biodata_workExp: res.data });
        data.biodata_workExp = res.data;
        this.getBusinessArea(key);
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  handleClickOpen = (key = -1) => {
    this.getBiodataWorkExp(key);

    setTimeout(() => {
      if (
        this.state.biodata_workExp.newSelfDesc &&
        this.state.biodata_workExp.nonExp
      ) {
        this.setState({ openConfirmation: true });
      } else {
        if (key > -1) {
          this.setState(
            {
              open: true,
              ...this.state.biodata.listWorks[key],
              isedit: true,
              k: key,
            },
            () => {}
          );
        } else {
          this.setState({
            open: true,
            isedit: false,
            status: " ",
            name: "",
            field: " ",
            role: "",
            yearStart: "",
            yearEnd: "",
            stillWorking: false,
          });
        }
      }
    }, 2000);
  };

  handleClickDelete = key => {
    this.setState({ opendelete: true, k: key });
  };

  addworksexperience() {
    const { t } = this.props;
    if (data.work_experience.field === "") {
      alertData.show(t("biodata:validation.required.businessarea"), "error");
      return false;
    }
    data.listWorks.push(data.work_experience);
    data.setWorkingExperience({
      status: "",
      name: "",
      field: "",
      role: "",
      yearStart: "",
      yearEnd: null,
      stillWorking: false,
      start: "",
      end: "",
    });

    var objworksexp = {
      status: "",
      name: "",
      field: "",
      role: "",
      yearStart: "",
      yearEnd: null,
      stillWorking: false,
      start: "",
      end: "",
    };
    data.listWorks.forEach(worksexp => {
      objworksexp = {
        status: worksexp.status,
        name: worksexp.name,
        field: worksexp.field,
        role: worksexp.role,
        yearStart: worksexp.yearStart,
        yearEnd: worksexp.yearEnd,
        stillWorking: worksexp.stillWorking,
        start: worksexp.start,
        end: worksexp.end,
      };
    });
    this.state.biodata.listWorks.push(objworksexp);
    data.listWorks = this.state.biodata.listWorks;
    //alertData.show("Add Success");
    this.setState({ open: false });
  }

  editworksexperience() {
    const { t } = this.props;
    var key = this.state.k;
    var stateListData = this.state.biodata.listWorks;
    stateListData[key].status = this.state.status;
    stateListData[key].name = this.state.name;
    stateListData[key].field = this.state.field.value;
    stateListData[key].role = this.state.role;
    stateListData[key].yearStart = this.state.yearStart;
    stateListData[key].yearEnd = this.state.yearEnd;
    stateListData[key].stillWorking = this.state.stillWorking;
    stateListData[key].start = this.state.start;
    stateListData[key].end = this.state.end;

    if (stateListData[key].field === "") {
      alertData.show(t("biodata:validation.required.businessarea"), "error");
      return false;
    }

    data.work_experience.status = this.state.status;
    data.work_experience.name = this.state.name;
    data.work_experience.field = this.state.field.value;
    data.work_experience.role = this.state.role;
    data.work_experience.yearStart = this.state.yearStart;
    data.work_experience.yearEnd = this.state.yearEnd;
    data.work_experience.stillWorking = this.state.stillWorking;
    data.work_experience.start = this.state.start;
    data.work_experience.end = this.state.end;

    data.listWorks = stateListData;

    this.setState(
      { open: false, biodata: { listWorks: stateListData } },
      () => {
        //alertData.show("Edit Success");
      }
    );
  }

  deleteworksexperience() {
    var key = this.state.k;
    //var lengthlistwork = this.state.biodata.listWorks.length;
    var listdataWork = removeArrayWithIndex(this.state.biodata.listWorks, key);
    data.listWorks = listdataWork;
    const tempArray = this.state.biodata;
    tempArray.listWorks = listdataWork;
    this.props.updateData(tempArray)
    this.setState(
      { opendelete: false, biodata: { listWorks: listdataWork } },
      () => {
        //alertData.show("Delete Success");
      }
    );
  }

  onSubmit(isEdit) {
    if (isEdit) {
      this.editworksexperience();
    } else {
      this.addworksexperience();
    }
  }

  submitdata() {
    var datasubmit = {};
    datasubmit.personalInfo = data.personalInfo;
    datasubmit.listFormalEducation = data.listFormalEducation;
    datasubmit.listOrganization = data.listOrganization;
    datasubmit.listInformalEducation = data.listInformalEducation;
    datasubmit.listWorks = this.state.biodata.listWorks;
    RequestPut("biodata", datasubmit)
      .then(r => {
        alertData.show("Save Success");
        AppHistory.push("/user/home", { edit: true });
      })
      .catch(e => {
        console.log(e);
      });
  }

  postAdditionalQuestion() {
    if (data.additional_question.workExp.stillWorking) {
      data.additional_question.workExp.yearEnd = null;
    }
    if (data.additional_question.workExp.field !== "") {
      data.additional_question.workExp.field =
        data.additional_question.workExp.field.value;
    }
    RequestPost("aboutquestion/additional", data.additional_question)
      .then(() => {
        alertData.show("Save Success");
        setTimeout(() => {
          AppHistory.push("/user/home", { edit: true });
        }, 1000);
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  getBusinessArea(key = null) {
    RequestGet("Industry")
      .then(res => {
        var bussinessArea = res.data.map(obj => {
          return {
            value: obj.id,
            label: getItem("lang") === "en" ? obj.industryEng : obj.industryIna,
          };
        });
        this.setState({
          listBusinessArea: bussinessArea,
        });

        if (key !== null) {
          if (key > -1) {
            var listBusinessAreabyId = [];
            var listworks = this.state.biodata.listWorks[key];
            if (listworks.field !== null || listworks.field !== "") {
              listBusinessAreabyId = findObject(
                bussinessArea,
                "value",
                listworks.field
              );
              if (listBusinessAreabyId !== null) {
                this.setState({
                  field: listBusinessAreabyId,
                  bisnisArea: listBusinessAreabyId,
                });
              } else {
                this.setState({
                  field: listworks.field,
                  bisnisArea: listworks.field,
                });
              }
            }
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeBussinessArea(businessAreaId) {
    var listbusinessAreabyId = findObject(
      this.state.listBusinessArea,
      "value",
      businessAreaId.businessAreaId
    );
    var obj = { field: businessAreaId.businessAreaId };
    data.setWorkingExperience(obj);

    this.setState({
      ...this.state,
      bisnisArea: listbusinessAreabyId,
    });
  }

  render() {
    const {
      biodata: { listWorks },
      open,
      isedit,
      opendelete,
      openConfirmation,
      openWorkExp,
      openAdditionalQuestion,
      listBusinessArea,
    } = this.state;
    const { t } = this.props;
    return (
      <div
        style={{
          fontWeight: "lighter",
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          padding: "0px 50px",
          color: palette.primary,
        }}>
        {/* Dialog Confirmation if (newSelfDesc && nonExp) */}
        <DialogueConfirm
          open={openConfirmation}
          title={t("biodata:biodata_workExp.confirmation.title")}
          content={t("biodata:biodata_workExp.confirmation.content")}
          btnYes={"Ok"}
          btnNo={t("biodata:biodata_workExp.confirmation.btnNo")}
          onOK={() => {
            this.setState({ openConfirmation: false });
            this.setState({ openWorkExp: true });
          }}
          onClose={() => this.setState({ openConfirmation: false })}
        />

        {/* Dialog for Work Exp */}
        <DialogueWorkExp
          open={openWorkExp}
          onOK={() => {
            this.setState({ openWorkExp: false });
            this.setState({ openAdditionalQuestion: true });
          }}
          onClose={() => this.setState({ openWorkExp: false })}
        />

        {/* Dialog for Additional Question */}
        <DialogueAdditionalQuestion
          open={openAdditionalQuestion}
          onOK={() => {
            this.setState({ openAdditionalQuestion: false });
            this.postAdditionalQuestion();
          }}
          onClose={() => this.setState({ openAdditionalQuestion: false })}
        />
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            flexDirection: "row",
            alignItems: "center",
            color: palette.primary,
            fontSize: 20,
            fontWeight: "bolder",
            justifyContent: "space-between",
          }}>
          <div>{t("biodata:CVworkExperience")}</div>
          <div>
            <Button
              onClick={this.handleClickOpen}
              size="small"
              variant="contained"
              style={{
                ...PRIMARY_BUTTON,
                fontWeight: "bold",
                marginLeft: 46,
                backgroundColor: "white",
                color: palette.primary,
                fontSize: 11,
              }}>
              <Add style={{ width: "0.8em", height: "0.8em" }}></Add> &nbsp;{" "}
              {t("biodata:CVbtnAdd")}
            </Button>
          </div>
        </div>
        <Dialog
          fullScreen
          className={"Custom-Dialog-cv"}
          aria-labelledby="customized-dialog-title"
          disableBackdropClick
          open={open}
          style={{ backgroundColor: "transparent" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              paddingTop: "50px",
            }}>
            <div
              style={{
                width: 800,
                minHeight: 650,
                padding: 30,
                position: "relative",
              }}>
              <button
                onClick={() => {
                  this.handleClose();
                }}
                style={{
                  width: 28,
                  height: 28,
                  color: "#C4C4C4",
                  borderRadius: "50%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  border: "none",
                  cursor: "pointer",
                }}>
                X
              </button>
              <ValidatorForm
                style={{ backgroundColor: "white", borderRadius: 10 }}
                onSubmit={() => {
                  this.onSubmit(isedit);
                }}>
                <DialogTitle
                  id="form-dialog-title"
                  style={{
                    color: palette.primary,
                    borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  }}>
                  {t("biodata:CVworkExperience")}
                </DialogTitle>
                <DialogContent>
                  <div value={this.state.status}>
                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("biodata:form.field.employmentstatus")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        {/* &thinsp;* */}
                      </span>
                    </label>
                    <SelectValidator
                      style={{ width: "97%" }}
                      className="select-with-placeholder Field Single"
                      defaultValue=" "
                      InputLabelProps={{
                        shrink: true,
                      }}
                      validators={["selectRequired"]}
                      errorMessages={[
                        t("biodata:validation.required.employmentstatus"),
                      ]}
                      displayempty="true"
                      onChange={e => {
                        this.handleChange({ status: e.target.value });
                      }}
                      value={this.state.status ? this.state.status : " "}>
                      <MenuItem value=" " disabled>
                        <span>{t("biodata:form.placeholder.CVjobststus")}</span>
                      </MenuItem>
                      <MenuItem value="1">
                        {
                          t("cv:jobStatus.options", {
                            returnObjects: true,
                          })[0]
                        }
                      </MenuItem>
                      <MenuItem value="2">
                        {
                          t("cv:jobStatus.options", {
                            returnObjects: true,
                          })[1]
                        }
                      </MenuItem>
                      <MenuItem value="3">
                        {
                          t("cv:jobStatus.options", {
                            returnObjects: true,
                          })[2]
                        }
                      </MenuItem>
                    </SelectValidator>

                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("biodata:form.field.companyname")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <TextValidator
                      onChange={e => {
                        this.handleChange({
                          name: e.currentTarget.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="Field-cfm-cv"
                      placeholder={t("biodata:form.placeholder.companyname")}
                      name="companyname"
                      value={this.state.name}
                      style={{
                        width: "97%",
                        color: palette.primary,
                      }}
                      validators={["required", "maxStringLength:50"]}
                      errorMessages={[
                        t("biodata:validation.required.companyname"),
                        t("biodata:validation.valid.maxcompanyName"),
                      ]}
                    />

                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      required={true}
                      value={this.state.bisnisArea}
                      style={{
                        width: "97%",
                        color: palette.primary,
                        marginBottom: 15,
                      }}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {t("biodata:form.field.businessarea")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <SelectSearch
                        validators={["selectRequired"]}
                        errorMessages={[
                          t("biodata:validation.required.businessarea"),
                        ]}
                        closeMenuOnSelect
                        key={new Date().getTime()}
                        className="Select-domicile-location"
                        classNamePrefix="select"
                        placeholder={t("biodata:form.placeholder.businessarea")}
                        menuColor={palette.primary}
                        name="businessArea"
                        options={listBusinessArea}
                        value={
                          this.state.bisnisArea ? this.state.bisnisArea : " "
                        }
                        onChange={v => {
                          this.onChangeBussinessArea({
                            businessAreaId: v.value,
                          });
                        }}
                        style={{ color: palette.primary }}
                      />
                    </Grid>

                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("biodata:form.field.position")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <TextValidator
                      onChange={e => {
                        this.handleChange({
                          role: e.currentTarget.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="Field-cfm-cv"
                      placeholder={t("biodata:form.placeholder.position")}
                      name="position"
                      value={this.state.role}
                      style={{
                        width: "97%",
                        color: palette.primary,
                      }}
                      validators={["required", "maxStringLength:50"]}
                      errorMessages={[
                        t("biodata:validation.required.position"),
                        t("biodata:validation.valid.maxPosition"),
                      ]}
                    />

                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("biodata:form.field.startdate")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <DatePickerValidator
                      validators={["required"]}
                      errorMessages={[
                        t("biodata:validation.required.startdate"),
                      ]}
                      className="Profile-cfm-cv"
                      label={""}
                      format="dd/MM/yyyy"
                      style={{ width: 520 }}
                      value={
                        this.state.yearStart
                          ? new Date(this.state.yearStart)
                          : ""
                      }
                      maxDate={new Date()}
                      onChange={e => {
                        this.handleChange({ yearStart: e });
                      }}
                    />
                    <div>
                      {!this.state.stillWorking && (
                        <div>
                          <label
                            style={{
                              color: "#2E69B2",
                              fontSize: 14,
                              textTransform: "uppercase",
                              fontWeight: "lighter",
                            }}>
                            {t("biodata:form.field.enddate")}
                            <span
                              style={{ color: "#CD594D" }}
                              className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                              {/* &thinsp;* */}
                            </span>
                          </label>
                          <DatePickerValidator
                            className="Profile-cfm-cv"
                            label={""}
                            format="dd/MM/yyyy"
                            style={{ width: 520 }}
                            value={
                              this.state.yearEnd
                                ? new Date(this.state.yearEnd)
                                : ""
                            }
                            maxDate={new Date()}
                            onChange={e => {
                              this.handleChange({ yearEnd: e });
                            }}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}>
                        <Checkbox
                          className="show-button"
                          color="primary"
                          checked={this.state.stillWorking}
                          icon={
                            <CheckBoxOutlineBlank
                              fontSize="large"
                              color="primary"
                            />
                          }
                          checkedIcon={<CheckBox fontSize="large" />}
                          name="checkedI"
                          onClick={() => {
                            this.handleChange({
                              stillWorking: !this.state.stillWorking,
                            });
                          }}
                        />
                        <Typography
                          style={{
                            color: palette.primary,
                            textTransform: "uppercase",
                            marginLeft: 10,
                          }}>
                          Current
                        </Typography>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions
                  style={{
                    backgroundColor: palette.primary,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        onClick={this.handleClose}
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {t("biodata:CVbtnCancel")}
                      </Button>
                    </div>
                    <div style={{ width: "76%" }}></div>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        type="submit"
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {isedit
                          ? t("biodata:CVbtnEdit")
                          : t("biodata:CVbtnAdd")}
                      </Button>
                    </div>
                  </div>
                </DialogActions>
              </ValidatorForm>
            </div>
          </div>
        </Dialog>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "47%",
              marginTop: "4%",
              width: "100%",
            }}>
            <div
              style={{
                width: "100%",
              }}>
              {listWorks.length === 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10%",
                  }}>
                  <div>
                    <img src={IconFolder} alt="Folder" />
                  </div>
                  <div>
                    <label style={{ color: palette.primary }}>
                      {t("biodata:CVclickaddbutton")} <br />{" "}
                      {t("biodata:CVworkExperience")}
                    </label>
                  </div>
                </div>
              )}
              {listWorks.map((v, k) => {
                var datestart = new Date(v.yearStart);
                var yearstart = datestart.getFullYear();

                var dateend = new Date(v.yearEnd);
                var yearend = dateend.getFullYear();
                var stillw = "";
                if (v.stillWorking === true) {
                  stillw = "Current";
                } else {
                  stillw = yearend.toString();
                }

                var employeestatus = "";
                if (v.status === "1") {
                  employeestatus = "Permanent Employee";
                } else if (v.status === "2") {
                  employeestatus = "Contract Employee";
                } else if (v.status === "3") {
                  employeestatus = "Internship Employee";
                } else {
                  employeestatus = "";
                }
                return (
                  <div
                    key={k}
                    style={{
                      borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                    }}>
                    <div>
                      <br />
                    </div>
                    <div
                      style={{
                        color: palette.primary,
                        fontSize: 18,
                        fontWeight: "bolder",
                      }}>
                      {v.name}
                    </div>
                    <div
                      style={{
                        color: palette.primary,
                        fontSize: 18,
                        fontWeight: "bolder",
                      }}>
                      {v.role}
                    </div>
                    <div
                      style={{
                        color: palette.primary,
                        fontSize: 18,
                        fontWeight: "lighter",
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div
                        style={{
                          width: "100%",
                        }}>
                        {employeestatus}
                      </div>
                      <div>
                        <img
                          onClick={() => {
                            this.handleClickOpen(k);
                          }}
                          style={{ cursor: "pointer" }}
                          src={IconEditCV}
                          alt="Edit"
                        />
                      </div>
                      <div style={{ width: "4%" }}></div>
                      <div>
                        <img
                          onClick={() => {
                            this.handleClickDelete(k);
                          }}
                          style={{ cursor: "pointer" }}
                          src={IconDeleteCV}
                          alt="Edit"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "85%",
                        color: palette.primary,
                        fontSize: 18,
                        fontWeight: "lighter",
                        marginTop: "-1.5%",
                      }}>
                      {yearstart} - {stillw}
                    </div>
                    <div>
                      <br />
                    </div>
                    <DialogueConfirm
                      open={opendelete}
                      title={t("auth:CVdeleteConfirm.applicant.title")}
                      content={t("auth:CVdeleteConfirm.applicant.message")}
                      btnYes={t("auth:CVdeleteConfirm.applicant.btnOk")}
                      btnNo={t("auth:CVdeleteConfirm.applicant.btnCancel")}
                      onOK={() => this.deleteworksexperience()}
                      onClose={() => {
                        this.setState({ opendelete: false });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {this.props.edit && (
            <CVFooter
              text="save"
              BackClick={() => {
                history.goBack();
              }}
              NextClick={() => {
                this.submitdata();
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation()(CVWorkExperience);
