import React from "react";
import DatePicker from "react-date-picker";
import "./custom.css";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";

/**
 * @extends {React.Component<{value:propTypes.any, label:propTypes.any, format:propTypes.string, minDetail:propTypes.string, maxDetail:propTypes.string, dayPlaceholder:propTypes.string, yearPlaceholder:propTypes.string, monthPlaceholder:propTypes.string, onChange:propTypes.func, disabled:propTypes.bool}>}
 */
class HgDatePicker extends React.Component {
  render() {
    const {
      label,
      value,
      format,
      dayPlaceholder,
      monthPlaceholder,
      yearPlaceholder,
      maxDate,
      minDate,
      maxDetail,
      minDetail,
      autoFocus,
      style,
      className,
      required,
      showLabel,
      disabled,
      t,
    } = this.props;
    return (
      <div className={className + " custom-date"} style={style}>
        {showLabel && (
          <label style={{}}>
            {label}
            {required && (
              <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                &thinsp;*
              </span>
            )}
          </label>
        )}
        <DatePicker
          disabled={disabled}
          CalendarType="ISO 8601"
          required={required}
          autoFocus={autoFocus}
          maxDate={maxDate}
          minDate={minDate}
          maxDetail={maxDetail}
          minDetail={minDetail}
          dayPlaceholder={
            dayPlaceholder ? dayPlaceholder : t("biodata:form.placeholder.date")
          }
          monthPlaceholder={
            monthPlaceholder
              ? monthPlaceholder
              : t("biodata:form.placeholder.month")
          }
          yearPlaceholder={
            yearPlaceholder
              ? yearPlaceholder
              : t("biodata:form.placeholder.year")
          }
          format={format}
          onChange={val => {
            this.props.onChange(val);
          }}
          value={
            value instanceof Date
              ? value
              : value
              ? value.indexOf("T") > 0 && value.indexOf("Z") < 0
                ? new Date(value + ".000Z")
                : null
              : null
          }
          className={className + " Field"}
          label="s"
          clearIcon={null}
          calendarIcon={null}
        />
      </div>
    );
  }
}
HgDatePicker.propTypes = {
  value: propTypes.any,
  label: propTypes.any,
  format: propTypes.string,
  minDetail: propTypes.string,
  maxDetail: propTypes.string,
  dayPlaceholder: propTypes.string,
  yearPlaceholder: propTypes.string,
  monthPlaceholder: propTypes.string,
  onChange: propTypes.func,
  disabled: propTypes.bool,
};
HgDatePicker.defaultProps = {
  showLabel: true,
  required: false,
  autoFocus: false,
  maxDate: null,
  minDate: null,
  minDetail: "century",
  maxDetail: "month",
  label: "Date",
  format: "yyyy/MM/dd",
  dayPlaceholder: null,
  yearPlaceholder: null,
  monthPlaceholder: null,
  className: "",
  disabled: false,
  onChange: () => {},
};
export default withTranslation()(HgDatePicker);
