import React from "react";
import { Text, Page, View, Image } from "@react-pdf/renderer";
import { WaterMark, HeaderLogoPDF, CIStyles } from "./ci-styles";
import { palette } from "assets/css/main";
import moment from "moment";
import { findObject, t } from "app/utils";

const TableItem = props => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        fontSize: 12,
        width: "100%",
        borderTop: "1pt solid " + palette.cv.borderColor,
        borderBottom: "1pt solid " + palette.cv.borderColor,
        marginTop: -1,
      }}>
      <View
        style={{
          color: palette.cv.textGrey,
          textAlign: props.head ? "center" : "left",
          borderRight: props.head
            ? "none"
            : "1pt solid " + palette.cv.borderColor,
          width: "25%",
          padding: "5pt 5pt",
          fontWeight: "light",
        }}>
        <Text>{props.title}</Text>
      </View>
      <View
        style={{
          color: props.head ? palette.cv.textGrey : palette.cv.textColor,
          textAlign: props.head ? "center" : "left",
          width: "75%",
          padding: "5pt 5pt",
          fontWeight: props.head ? "light" : "normal",
        }}>
        {typeof props.value !== "object" ? (
          <Text style={{}}>{props.value}</Text>
        ) : (
          props.value
        )}
      </View>
    </View>
  );
};
const TableItemSingle = props => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        fontSize: 12,
        width: "100%",
        borderTop: "1pt solid " + palette.cv.borderColor,
        borderBottom: "1pt solid " + palette.cv.borderColor,
        marginTop: -1,
      }}>
      <View
        style={{
          width: "100%",
          textAlign: props.head ? "center" : "left",
          backgroundColor: palette.cv.borderColor,
          color: palette.cv.textColor,
          padding: "5pt 5pt",
          fontWeight: props.head ? "light" : "normal",
        }}>
        {typeof props.value !== "object" ? (
          <Text style={{}}>{props.value}</Text>
        ) : (
          props.value
        )}
      </View>
    </View>
  );
};
const CIPageCV = props => {
  const {
    personalInfo,
    listFormalEducation,
    listInformalEducation,
    listOrganization,
    listWorks,
  } = props.data.personalInfo
    ? props.data
    : {
        personalInfo: { userId: "", listUserLanguage: [] },
        listFormalEducation: [],
        listInformalEducation: [],
        listOrganization: [],
        listWorks: [],
      };
  const gender = t("biodata:options.gender", { returnObjects: true });
  const maritalStatus = t("biodata:options.maritalStatus", {
    returnObjects: true,
  });
  const employeeStatus = t("biodata:options.jobStatus", {
    returnObjects: true,
  });
  return (
    <Page style={CIStyles.page}>
      <WaterMark />
      <HeaderLogoPDF />
      <View style={{ position: "fixed" }} fixed>
        <Text style={{ color: palette.primary, fontWeight: "bold" }}>
          I. Curriculum vitae
        </Text>
      </View>
      <View style={{ marginTop: 30 }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 20,
          }}>
          <Image
            src={personalInfo.photo + "?t=" + new Date().getTime()}
            style={{
              backgroundColor: palette.darkGrey,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: 80,
              height: 80,
            }}
            allowDangerousPaths
          />
          <View style={{ marginLeft: 20 }}>
            <Text style={{ fontWeight: "bold" }}>
              {props.status === "SEL"
                ? personalInfo.userId.substr(0, 6).toUpperCase()
                : personalInfo.name}
            </Text>
          </View>
        </View>

        {/* professional-profile */}
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}>
            <View style={CIStyles.subtitleLine} />
            <Text style={{ fontSize: 14 }}>1. Professional profile</Text>
          </View>
          <View>
            <Text style={{ fontSize: 12, textAlign: "justify" }}>
              {personalInfo.description}
            </Text>
          </View>
        </View>
        {/*  professional-profile  */}

        {/* personal information  */}
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}>
            <View style={CIStyles.subtitleLine} />
            <Text style={{ fontSize: 14 }}>2. Personal information</Text>
          </View>
          <View style={{ marginTop: 20 }}>
            <TableItem
              title={"Name"}
              value={props.status === "SEL" ? "" : personalInfo.name}
            />
            <TableItem
              title={"Hiregrade ID"}
              value={
                personalInfo.userId
                  ? personalInfo.userId.substr(0, 6).toUpperCase()
                  : ""
              }
            />
            <TableItem
              title={"Birth place/ date"}
              value={
                personalInfo.birthPlace +
                ", " +
                moment(props.birthDate).format("DD MMMM yyyy")
              }
            />
            <TableItem
              title={"Gender"}
              value={
                findObject(gender, "value", personalInfo.gender, false).label
              }
            />
            <TableItem
              title={"NIK"}
              value={props.status === "SEL" ? "" : personalInfo.identityNumber}
            />
            <TableItem
              title={"Contact Number"}
              value={
                props.status === "SEL" ? "" : "+62" + personalInfo.phoneNumber
              }
            />
            <TableItem
              title={"Email"}
              value={props.status === "SEL" ? "" : personalInfo.email}
            />
            <TableItem
              title={"Marital status"}
              value={
                findObject(
                  maritalStatus,
                  "value",
                  personalInfo.maritalStatus,
                  false
                ).label
              }
            />
            <TableItem
              title={"Domicile address"}
              value={personalInfo.address + " " + personalInfo.address + " "}
            />
          </View>
        </View>
        {/* personal information  */}

        {/* Language */}
        <View break>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}>
            <View style={CIStyles.subtitleLine} />
            <Text style={{ fontSize: 14 }}>3. Language Skill</Text>
          </View>
          <View style={{ marginTop: 20 }}>
            <TableItem head={true} title="Language" value={"Level"} />
            {personalInfo.listUserLanguage.map((v, i) => {
              const color =
                v.proficiency === 1
                  ? palette.cv.low
                  : v.proficiency === 2
                  ? palette.cv.medium
                  : palette.cv.high;
              const text =
                v.proficiency === 1
                  ? "Novice"
                  : v.proficiency === 2
                  ? "Intermediate"
                  : "advanced";
              const width =
                v.proficiency === 1 ? 80 : v.proficiency === 2 ? 120 : 200;
              return (
                <TableItem
                  key={i}
                  title={v.languageSkillEng}
                  value={
                    <View
                      style={{
                        backgroundColor: color,
                        width: width,
                        color:
                          v.proficiency === 2 ? palette.cv.textColor : "white",
                        padding: "5pt 5pt 7pt 10pt",
                        borderRadius: 20,
                        textTransform: "uppercase",
                      }}>
                      <Text>{text}</Text>
                    </View>
                  }
                />
              );
            })}
          </View>
        </View>
        {/* Language */}

        {/* Formal Education */}
        <View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}>
            <View style={CIStyles.subtitleLine} />
            <Text style={{ fontSize: 14 }}>4. Formal education</Text>
          </View>
          <View style={{ marginTop: 20 }}>
            <TableItem head={true} title="University" value={"Major"} />
            {listFormalEducation.map((v, i) => {
              return (
                <TableItem
                  title={v.institution}
                  key={"fe-" + i}
                  value={
                    <View>
                      <Text>
                        {v.degree} - {v.major}
                      </Text>
                      <Text>{v.yearGraduate}</Text>
                      <Text>{v.grade}</Text>
                    </View>
                  }
                />
              );
            })}
          </View>
        </View>
        {/* Formal Education */}

        {/* Informal Education */}
        <View break>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}>
            <View style={CIStyles.subtitleLine} />
            <Text style={{ fontSize: 14 }}>5. Informal education</Text>
          </View>
          <View style={{ marginTop: 20 }}>
            {listInformalEducation.map((v, i) => {
              return (
                <View key={"ie" + i}>
                  <TableItemSingle value={v.institution} />
                  <TableItem title={"Training name"} value={v.name} />
                  <TableItem title={"Area"} value={v.study} />
                  <TableItem title={"Provider"} value={v.institution} />
                  <TableItem title={"Year attended"} value={v.year} />
                  <TableItem
                    title={"Certificate"}
                    value={v.isSertifikat ? "Available" : "Not Available"}
                  />
                </View>
              );
            })}
          </View>
        </View>
        {/* Informal Education */}

        {/* Organization Experience */}
        <View break>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}>
            <View style={CIStyles.subtitleLine} />
            <Text style={{ fontSize: 14 }}>6. Organization experience</Text>
          </View>
          <View style={{ marginTop: 20 }}>
            {listOrganization.map((v, i) => {
              return (
                <View key={"ie" + i}>
                  <TableItemSingle value={v.name} />
                  <TableItem title={"Organizationn name"} value={v.name} />
                  <TableItem title={"Area"} value={v.field} />
                  <TableItem title={"Year"} value={v.year} />
                  <TableItem
                    title={"Position"}
                    value={
                      <View>
                        <Text>
                          {v.position}
                          {v.function ? " - " + v.function : ""}
                        </Text>
                      </View>
                    }
                  />
                </View>
              );
            })}
          </View>
        </View>
        {/* Organization Experience */}

        {/* Work Experience */}
        <View break>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}>
            <View style={CIStyles.subtitleLine} />
            <Text style={{ fontSize: 14 }}>7. Work experience</Text>
          </View>
          <View style={{ marginTop: 20 }}>
            <TableItem head={true} title="Company" value={"Role"} />
            {listWorks.map((v, i) => {
              return (
                <TableItem
                  title={v.name}
                  key={"we-" + i}
                  value={
                    <View>
                      <Text>
                        {v.role}(
                        {
                          findObject(employeeStatus, "value", v.status, false)
                            .label
                        }
                        )
                      </Text>
                      <Text>
                        {moment(v.yearStart).format("yyyy")} -{" "}
                        {v.stillWorking
                          ? "Present"
                          : moment(v.yearEnd).format("yyyy")}
                      </Text>
                    </View>
                  }
                />
              );
            })}
          </View>
        </View>
        {/* Work Experience */}
      </View>
    </Page>
  );
};
export default CIPageCV;
