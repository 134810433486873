import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { AUTH_API, getItem, RequestGet } from "app/utils";
import MyHelmet from "app/components/header/MyHelmet";
import "../auth.css";
import { ImgForgotPassword } from "assets/img";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  NextBtn,
  DialogueInfo,
  Header,
  HeaderNologin,
  Footer,
} from "app/components";
import { isMobile } from "react-device-detect";

class ApplicantForgotPassword extends React.Component {
  state = {
    title: "",
    emailPlaceholder: "",
  };
  constructor(props) {
    super(props);
    this.state = { email: "", open: false, error: "" };
    if (getItem("access_token")) {
      this.props.history.push("");
    }
    this.resetPassword = this.resetPassword.bind(this);
  }
  resetPassword() {
    RequestGet(AUTH_API + "connect/sendresetpassword?email=" + this.state.email)
      .then(res => {
        this.setState({ open: true });
      })
      .catch(e => {
        if (e) {
          this.setState({ error: e.data.messages[0] });
        } else {
          this.setState({ error: "error" });
        }
      });
  }
  render() {
    const { t } = this.props;
    var title =
      getItem("idRole") === "r"
        ? t("auth:resetPassword.title")
        : getItem("lang") === "en"
        ? t("auth:resetPassword.title")
        : t("auth:resetPassword.title_ap");
    var emailPlaceholder =
      getItem("idRole") === "r"
        ? t("auth:placeholder.email")
        : getItem("lang") === "en"
        ? t("auth:placeholder.email")
        : t("auth:placeholder.email_ap");
    if (isMobile) {
      return (
        <div>
          <MyHelmet title={title} />
          <DialogueInfo
            title={t("auth:dialogue.title")}
            content={t("auth:dialogue.content")}
            open={this.state.open}
            onClose={() => {
              this.setState({ open: false });
            }}
          />
          <HeaderNologin />
          <Grid style={{ minHeight: "100vh" }}>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              style={{
                boxSizing: "border-box",
              }}>
              <Grid>
                <div
                  className="auth-mobile"
                  style={{
                    display: "flex",
                    justifyItems: "flex-start",
                    padding: "0px 30px",
                    flexDirection: "column",
                  }}>
                  <Typography
                    style={{
                      marginTop: 125,
                      marginBottom: 50,
                      fontWeight: "bold",
                      color: palette.primary,
                      fontSize: 24,
                    }}>
                    {title}
                  </Typography>
                  <ValidatorForm onSubmit={this.resetPassword}>
                    <TextValidator
                      autoFocus={true}
                      onChange={e => {
                        this.setState({ email: e.target.value, error: "" });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Email"
                      className="Field"
                      placeholder={emailPlaceholder}
                      name="email"
                      value={this.state.email}
                      style={{ marginTop: 20, width: "90%" }}
                      autoComplete="username"
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        t("auth:validation.required"),
                        t("auth:validation.email"),
                      ]}
                    />
                    <p style={{ color: "red" }}>{this.state.error}</p>
                    <NextBtn
                      type="submit"
                      text={t("auth:resetPassword.btn")}
                      style={{ padding: 0, marginTop: 10 }}
                    />
                  </ValidatorForm>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 15,
                    }}>
                    <Typography
                      style={{
                        fontSize: 16,
                        color: palette.primary,
                        fontWeight: "lighter",
                      }}>
                      {t("auth:resetPassword.back")}
                    </Typography>
                    <Button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        padding: 0,
                        fontSize: 16,
                        color: palette.primary,
                      }}>
                      {t("auth:login")}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid style={{ width: "100%" }}>
              <div style={{ position: "relative", bottom: 0 }}>
                <Footer />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <MyHelmet title={title} />
          <DialogueInfo
            title={t("auth:dialogue.title")}
            content={t("auth:dialogue.content")}
            open={this.state.open}
            onClose={() => {
              this.setState({ open: false });
            }}
          />
          <Header shadow={true} linkgroup={true} />
          <Grid container style={{ height: "100vh", paddingTop: 80 }}>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              style={{
                boxSizing: "border-box",
              }}>
              <Grid container>
                <div
                  style={{
                    display: "flex",
                    justifyItems: "flex-start",
                    padding: "0px 120px",
                    flexDirection: "column",
                  }}>
                  <Typography
                    style={{
                      marginTop: 125,
                      marginBottom: 50,
                      width: 300,
                      fontWeight: "bold",
                      color: palette.primary,
                      fontSize: 32,
                    }}>
                    {title}
                  </Typography>
                  <ValidatorForm onSubmit={this.resetPassword}>
                    <TextValidator
                      autoFocus={true}
                      onChange={e => {
                        this.setState({ email: e.target.value, error: "" });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Email"
                      className="Field"
                      placeholder={emailPlaceholder}
                      name="email"
                      value={this.state.email}
                      style={{ width: 500, marginTop: 20 }}
                      autoComplete="username"
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        t("auth:validation.required"),
                        t("auth:validation.email"),
                      ]}
                    />
                    <p style={{ color: "red" }}>{this.state.error}</p>
                    <NextBtn
                      type="submit"
                      text={t("auth:resetPassword.btn")}
                      style={{ padding: 0, marginTop: 30 }}
                    />
                  </ValidatorForm>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 170,
                    }}>
                    <Typography
                      style={{
                        fontSize: 18,
                        color: palette.primary,
                        fontWeight: "lighter",
                      }}>
                      {t("auth:resetPassword.back")}
                    </Typography>
                    <Button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        padding: 0,
                        fontSize: 18,
                        color: palette.primary,
                      }}>
                      {t("auth:login")}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              style={{ backgroundColor: palette.secondary }}>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ paddingTop: 138 }}>
                <img
                  src={ImgForgotPassword}
                  alt="Forgot Password"
                  width={227}
                  height={443}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}
export default withTranslation()(ApplicantForgotPassword);
