import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import Moment from "react-moment";
import { getItem, findWithAttr, utcToGmt } from "app/utils";
import { Button } from "@material-ui/core";
const style = {
  container: { display: "flex", flexDirection: "column", padding: "15px 0px" },
  label: { fontWeight: "lighter", fontSize: 14 },
  value: { fontSize: 18 },
  langDot: {
    fontSize: 20,
    height: 10,
    padding: "0 15",
    background: palette.primary,
    border: "none",
    borderRadius: 20,
    opacity: 1,
    fontWeight: "normal",
    width: 10,
  },
};
export default withTranslation()(
  class CVPreviewPI extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        listUserLanguage: [],
      };
    }

    componentDidMount() {
      const {
        data: { personalInfo },
      } = this.props;
      if (personalInfo.listUserLanguage.length > 0) {
        this.setState({
          listUserLanguage: personalInfo.listUserLanguage,
        });
      }
    }

    render() {
      const {
        t,
        data: { personalInfo },
      } = this.props;
      const { listUserLanguage } = this.state;
      const user_info = JSON.parse(getItem("user_info"));
      const gender = t("biodata:options.gender", { returnObjects: true });
      const maritalStatus = t("biodata:options.CVmaritalStatus", {
        returnObjects: true,
      });
      const levelSkillLang = t("biodata:levelSkillLang", {
        returnObjects: true,
      });

      return (
        <div style={{ color: palette.primary, padding: "0px 50px" }}>
          <span style={{ fontSize: 18 }}>{t("biodata:CVpersonalInfo")}</span>
          <div style={style.container}>
            <span style={style.label}>{t("biodata:form.field.firstName")}</span>
            <span style={style.value}>{personalInfo.firstName}</span>
          </div>
          <div style={style.container}>
            <span style={style.label}>
              {t("biodata:form.field.middleName")}
            </span>
            <span style={style.value}>{personalInfo.middleName}</span>
          </div>
          <div style={style.container}>
            <span style={style.label}>{t("biodata:form.field.lastName")}</span>
            <span style={style.value}>{personalInfo.lastName}</span>
          </div>
          <div style={style.container}>
            <span style={style.label}>
              {t("biodata:form.field.description")}
            </span>
            <span style={{ ...style.value, overflowWrap: "break-word" }}>
              {personalInfo.description}
            </span>
          </div>
          <div style={style.container}>
            <span style={{ ...style.label, marginBottom: 10 }}>
              {t("biodata:form.field.foreignLangProficiency")}
            </span>
            {listUserLanguage.map((v, i) => {
              var strproficiency = "";
              if (v.proficiency === 1) {
                strproficiency = levelSkillLang[0];
              } else if (v.proficiency === 2) {
                strproficiency = levelSkillLang[1];
              } else if (v.proficiency === 3) {
                strproficiency = levelSkillLang[2];
              }
              return (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 10,
                  }}>
                  <div style={style.langDot}></div>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "normal",
                      color: palette.primary,
                      marginLeft: 10,
                      width: 170,
                    }}>
                    {getItem("lang") === "en"
                      ? v.languageSkillEng
                      : v.languageSkillIna}
                  </div>
                  <div></div>
                  <div
                    style={{
                      position: "relative",
                      height: 30,
                      padding: "0 15",
                      background: "rgba(82, 200, 244, 0.2)",
                      border: "1px solid #52C8F4",
                      borderRadius: 20,
                      width: 300,
                    }}>
                    <Button
                      disabled
                      style={{
                        color: "white",
                        fontSize: 13,
                        height: 30,
                        padding: "0 15",
                        textTransform: "none",
                        background: "#52C8F4",
                        border: "none",
                        borderRadius: 20,
                        opacity: 2,
                        fontWeight: "normal",
                        width:
                          v.proficiency === 1
                            ? 100
                            : v.proficiency === 2
                            ? 200
                            : 300,
                      }}>
                      {strproficiency}
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={style.container}>
            <span style={style.label}>{t("biodata:form.field.gender")}</span>
            <span style={style.value}>
              {findWithAttr(gender, "value", personalInfo.gender, "label")}
            </span>
          </div>
          <div style={style.container}>
            <span style={style.label}>{t("biodata:form.field.marital")}</span>
            <span style={style.value}>
              {findWithAttr(
                maritalStatus,
                "value",
                personalInfo.maritalStatus,
                "label"
              )}
            </span>
          </div>
          <div style={style.container}>
            <span style={style.label}>
              {t("biodata:form.field.birthplace")}
            </span>
            <span style={style.value}>{personalInfo.birthPlace}</span>
          </div>
          <div style={style.container}>
            <span style={style.label}>{t("biodata:form.field.birthdate")}</span>
            <span style={style.value}>
              <Moment
                date={utcToGmt(personalInfo.birthDate)}
                format="DD/MM/yyyy"
              />
            </span>
          </div>
          <div style={style.container}>
            <span style={style.label}>
              {t("biodata:form.field.idcardNumber")}
            </span>
            <span style={style.value}>{personalInfo.identityNumber}</span>
          </div>
          <div style={style.container}>
            <span style={style.label}>{t("biodata:form.field.idcard")}</span>
            <span style={style.value}>
              <div
                style={{
                  width: 170,
                  height: 170,
                  backgroundColor: palette.grey,
                  backgroundImage:
                    "url(" +
                    (personalInfo.identityPhoto.indexOf("http") >= 0
                      ? personalInfo.identityPhoto
                      : "data:image/jpeg;base64," +
                        personalInfo.identityPhoto) +
                    ")",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}></div>
            </span>
          </div>
          <div style={{ fontSize: 18 }}>{t("biodata:CVContact")}</div>
          <div style={style.container}>
            <span style={style.label}>{t("biodata:form.field.email")}</span>
            <span style={style.value}>{user_info.email}</span>
          </div>
          <div style={style.container}>
            <span style={style.label}>{t("biodata:form.field.contact")}</span>
            <span style={style.value}>
              {"+62 "}
              {personalInfo.phoneNumber}
            </span>
          </div>
          <div style={style.container}>
            <span style={style.label}>{t("biodata:form.field.address")}</span>
            <span style={style.value}>{personalInfo.address}</span>
          </div>
        </div>
      );
    }
  }
);
