import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { getItem, loadImage } from "app/utils";
import { IcnAddress4, IcnAdvanced4, IcnBirthday4, IcnContactMail4, IcnGender4, IcnIntermediate4, IcnKTP4, IcnMarital4, IcnNovice4, IcnPhone4 } from "assets/img";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  sectionLeft: {
    height: "100%",
    width: "38%",
    padding: "44pt 15pt 0pt 50pt",
    display: "flex",
    flexDirection: "column",
  },
  sectionRight: {
    height: "100%",
    width: "62%",
    padding: "49pt 51pt 0pt 15pt",
    display: "flex",
    flexDirection: "column",
  },
});

const FormalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontFamily: "HurmeGeometricSans3",
          fontSize: 14,
          fontWeight: "bold",
          color: "#cea69a",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.formalEdu")}
      </Text>
      <View
        style={{
          marginTop: 9,
          paddingTop: 9,
          width: "100%",
          borderTop: "1pt solid #000000",
          opacity: 0.78,
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.institution}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.degree + " | " + v.major}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {"GPA : " + v.grade}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {"Graduated : " + v.yearGraduate}
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const InformalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontFamily: "HurmeGeometricSans3",
          fontSize: 14,
          fontWeight: "bold",
          color: "#cea69a",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.informalEdu")}
      </Text>
      <View
        style={{
          marginTop: 9,
          paddingTop: 9,
          width: "100%",
          borderTop: "1pt solid #000000",
          opacity: 0.78,
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.institution}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.year}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {"Certificate : " + (v.isSertifikat ? props.t("biodata:cv_template.hg1.certificate.true") : props.t("biodata:cv_template.hg1.certificate.false"))}
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const WorkExperienceView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontFamily: "HurmeGeometricSans3",
          fontSize: 14,
          fontWeight: "bold",
          color: "#cea69a",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.workExp")}
      </Text>
      <View
        style={{
          marginTop: 9,
          paddingTop: 9,
          width: "100%",
          borderTop: "1pt solid #000000",
          opacity: 0.78,
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.role}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {(getItem("lang") === "en" ? moment(v.yearStart).lang("en").format("MMMM YYYY") : moment(v.yearStart).format("MMMM YYYY")) + " - " + (v.yearEnd ? v.yearEnd : props.t("biodata:cv_template.hg1.workYearEnd"))}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {
                    v.status === "1" ? props.t("biodata:cv_template.hg1.employeeStatus.1") : (
                      v.status === "2" ? props.t("biodata:cv_template.hg1.employeeStatus.2") : (
                        v.status === "3" ? props.t("biodata:cv_template.hg1.employeeStatus.3") : null
                      )
                    )
                  }
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const OrganizationalExperienceView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontFamily: "HurmeGeometricSans3",
          fontSize: 14,
          fontWeight: "bold",
          color: "#cea69a",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.organizationalExp")}
      </Text>
      <View
        style={{
          marginTop: 9,
          paddingTop: 9,
          width: "100%",
          borderTop: "1pt solid #000000",
          opacity: 0.78,
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {
                    v.position === "1" ? props.t("biodata:cv_template.hg1.organizationalPosition.1") : (
                      v.position === "2" ? (
                        v.function ? v.function : props.t("biodata:cv_template.hg1.organizationalPosition.2")
                      ) : null
                    )
                  }
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.field}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 4,
                    fontSize: 10,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {v.year}
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const LanguageView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
      }}
    >
      <Text
        style={{
          fontFamily: "HurmeGeometricSans3",
          fontSize: 14,
          fontWeight: "bold",
          color: "#cea69a",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.language")}
      </Text>
      <View
        style={{
          marginTop: 9,
          paddingTop: 9,
          width: "100%",
          borderTop: "1pt solid #000000",
          opacity: 0.78,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {
          props.data.map((v,i) => {
            return (
              <View key={i}
                style={i === 0 ? {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                } : {
                  marginTop: 8,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "AdobeClean",
                      fontSize: 9,
                      color: "#000000",
                      opacity: 0.78,
                    }}
                  >
                    {getItem("lang") === "en" ? v.languageSkillEng : v.languageSkillIna}
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Image 
                    src={
                      v.proficiency === 1 ? IcnNovice4 : (
                        v.proficiency === 2 ? IcnIntermediate4 : (
                          v.proficiency === 3 ? IcnAdvanced4 : null
                        )
                      )
                    }
                    style={{
                      width: 67,
                      height: 7,
                    }}
                  />
                </View>
              </View>
            )
          })
        }
      </View>
    </View>
  )
}

const TemplateHG4 = props => (
  <Document
    title={"CV - " + props.data.personalInfo.name}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <Page size="A4" style={styles.page}>
      <View style={styles.sectionLeft}>
        <View
          style={{
            width: 124,
          }}
        >
          <Text
            style={{
              fontFamily: "HurmeGeometricSans3",
              fontSize: 25,
              fontWeight: "bold",
              color: "#cea69a",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            {props.data.personalInfo.name}
          </Text>
        </View>
        <View
          style={{
            marginTop: 11,
            width: 124,
          }}
        >
          <Text
            style={{
              fontFamily: "LucidaCalligraphy",
              fontSize: 14,
              color: "#000000",
              opacity: 0.78,
              textAlign: "center",
            }}
          >
            {props.data.listWorks.length > 0 ? props.data.listWorks[0].role : null}
          </Text>
        </View>
        <Image 
          src={props.data.personalInfo.photo + "?t=" + new Date().getTime()}
          style={{
            marginTop: 20,
            width: 124,
            height: 124,
            borderRadius: 62,
          }}
        />
        <View
          style={{
            marginTop: 34,
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "HurmeGeometricSans3",
              fontSize: 14,
              fontWeight: "bold",
              color: "#cea69a",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.bio")}
          </Text>
          <View
            style={{
              marginTop: 9,
              paddingTop: 9,
              width: "100%",
              borderTop: "1pt solid #000000",
              opacity: 0.78,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnBirthday4}
                  style={{
                    width: 10,
                    height: 10,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 1,
                    fontSize: 9,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {props.data.personalInfo.birthPlace + ", " + (getItem("lang") === "en" ? moment(props.data.personalInfo.birthDate).lang("en").format("DD MMMM YYYY") : moment(props.data.personalInfo.birthDate).format("DD MMMM YYYY"))}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnGender4}
                  style={{
                    width: 7,
                    height: 7,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 9,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {
                    props.data.personalInfo.gender === "M" ? props.t("biodata:cv_template.hg1.gender.m") : (
                      props.data.personalInfo.gender === "F" ? props.t("biodata:cv_template.hg1.gender.f") : null
                    )
                  }
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnMarital4}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 9,
                    color: "#000000",
                    opacity: 0.78,
                    textTransform: "capitalize",
                  }}
                >
                  {props.data.personalInfo.maritalStatus}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnKTP4}
                  style={{
                    width: 9,
                    height: 7,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 9,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {props.data.personalInfo.identityNumber}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            marginTop: 24,
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "HurmeGeometricSans3",
              fontSize: 14,
              fontWeight: "bold",
              color: "#cea69a",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.contact")}
          </Text>
          <View
            style={{
              marginTop: 9,
              paddingTop: 9,
              width: "100%",
              borderTop: "1pt solid #000000",
              opacity: 0.78,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnAddress4}
                  style={{
                    width: 12,
                    height: 9,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 1,
                    fontSize: 9,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {props.data.personalInfo.address}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnPhone4}
                  style={{
                    width: 9,
                    height: 9,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 9,
                    color: "#000000",
                    opacity: 0.78,
                  }}
                >
                  {"+62 " + props.data.personalInfo.phoneNumber}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnContactMail4}
                  style={{
                    width: 9,
                    height: 9,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 9,
                    color: "#000000",
                    opacity: 0.78,
                    textTransform: "capitalize",
                  }}
                >
                  {props.data.email ? props.data.email : " - "}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {props.data.personalInfo.listUserLanguage.length > 0 ?
          <LanguageView 
            data={props.data.personalInfo.listUserLanguage}
            t={props.t}
          /> : null
        }
        <View
          style={{
            width: "200%",
            height: 67,
            position: "absolute",
            bottom: 0,
            padding: "0pt 0pt 27pt 50pt",
          }}
        >
          {
            props.data.toogle &&
            <View
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Image 
                src={loadImage("qr-code.png")}
                style={{
                  width: 40,
                  height: 40,
                }}
              />
              <View
                style={{
                  marginLeft: 8,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontWeight: "bold",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrTitle")}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrDesc")}
                </Text>
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontWeight: "bold",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrLink")}
                </Text>
              </View>
            </View>
          }
        </View>
      </View>
      <View style={styles.sectionRight}>
        <View
          style={{
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "HurmeGeometricSans3",
              fontSize: 14,
              fontWeight: "bold",
              color: "#cea69a",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.profile")}
          </Text>
          <View
            style={{
              marginTop: 9,
              paddingTop: 9,
              width: "100%",
              borderTop: "1pt solid #000000",
              opacity: 0.78,
            }}
          >
            <Text
              style={{
                fontFamily: "AdobeClean",
                fontSize: 10,
                color: "#000000",
                opacity: 0.78,
              }}
            >
              {props.data.personalInfo.description}
            </Text>
          </View>
        </View>
        {props.data.listFormalEducation.length > 0 ?
          <FormalEducationView 
            data={props.data.listFormalEducation}
            t={props.t}
          /> : null
        }
        {props.data.listInformalEducation.length > 0 ?
          <InformalEducationView 
            data={props.data.listInformalEducation}
            t={props.t}
          /> : null
        }
        {props.data.listWorks.length > 0 ? 
          <WorkExperienceView 
            data={props.data.listWorks}
            t={props.t}
          /> : null
        }
        {props.data.listOrganization.length > 0 ?
          <OrganizationalExperienceView 
            data={props.data.listOrganization}
            t={props.t}
          /> : null
        }
      </View>
    </Page>
  </Document>
);
export default TemplateHG4;
