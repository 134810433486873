import React from "react";
import { withTranslation } from "react-i18next";
import { ImageCircle, MyHelmet } from "app/components";
import {
  Grid,
  Button,
  InputBase,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import "./custom.css";
import { palette } from "assets/css/main";
import { Search } from "@material-ui/icons";
import { IconFolder } from "assets/img";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import data from "./data";
import {
  findObject,
  findObjectKey,
  getItem,
  RequestGet,
  RequestPut,
  AppHistory,
} from "app/utils";
import alertData from "app/components/alert/alert-data";
import moment from "moment";

class RecruiterNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationList: [],
      loaded: false,
      loadPage: false,
      showReadAll: false,
      showLoadMore: true,
      limit: 3,
      page: 1,
    };
  }

  timeFromNow(time) {
    const text =
      getItem("lang") === "en"
        ? moment(time).locale("en").fromNow()
        : moment(time).fromNow();
    return text;
  }

  componentDidMount() {
    this.getNotification();
  }

  getNotification() {
    const { limit, page, notificationList } = this.state;
    this.setState({ loadPage: true });
    RequestGet("notification", { page: page, limit: limit, loading: false })
      .then(res => {
        const {
          data: { countUnread, notification },
        } = res;
        this.setState({
          notificationList: [...notificationList, ...notification],
          showLoadMore: notification.length >= limit ? true : false,
          loaded: true,
          showReadAll: countUnread > 0 ? true : false,
          loadPage: false,
        });
      })
      .catch(err => {
        this.setState({
          showLoadMore: false,
          loaded: true,
          loadPage: false,
        });
        alertData.show(String(err.status), "error");
      });
  }

  search(part) {
    if (part === "" || part === null) {
      this.setState({ notificationList: data.notificationList });
    } else {
      var newObj = this.filterValuePart(data.notificationList, part);
      this.setState({ notificationList: newObj });
    }
  }

  filterValuePart(arr, part) {
    return getItem("lang") === "en"
      ? arr.filter(
          k =>
            k.subjectEng.toLowerCase().includes(part.toLowerCase()) ||
            k.messageEng.toLowerCase().includes(part.toLowerCase())
        )
      : arr.filter(
          k =>
            k.subjectIna.toLowerCase().includes(part.toLowerCase()) ||
            k.messageIna.toLowerCase().includes(part.toLowerCase())
        );
  }

  readSpecific(id) {
    const sn = findObject(this.state.notificationList, "id", id, false);
    if (sn.screenCode) {
      if (sn.screenCode === "002") {
        AppHistory.push(
          "/recruiter/calendar/" +
            findObject(sn.screenParam, "key", "VacancyId", false).value,
          {
            interviewId: findObject(sn.screenParam, "key", "InterviewId", false)
              .value,
          }
        );
      } else if (
        sn.screenCode === "003" ||
        sn.screenCode === "004" ||
        sn.screenCode === "007" ||
        sn.screenCode === "012" ||
        sn.screenCode === "013" ||
        sn.screenCode === "014" ||
        sn.screenCode === "015"
      ) {
        AppHistory.push(
          "/recruiter/viewcandidatelist/" +
            findObject(sn.screenParam, "key", "VacancyId", false).value,
          {
            status:
              sn.screenCode === "012" ||
              sn.screenCode === "013" ||
              sn.screenCode === "014" ||
              sn.screenCode === "015"
                ? "PRO"
                : "INP",
          }
        );
      }
    }
    RequestPut("notification?notifId=" + id)
      .then(() => {
        var newNotif = this.state.notificationList;
        var key = findObjectKey(newNotif, "id", id);
        newNotif[key].isRead = true;
        this.setState({ notificationList: newNotif });
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  readAll() {
    RequestPut("notification")
      .then(() => {
        this.setState({
          showReadAll: false,
          notificationList: this.state.notificationList.map(v => {
            v.isRead = true;
            return v;
          }),
        });
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  render() {
    const {
      notificationList,
      loaded,
      loadPage,
      showLoadMore,
      showReadAll,
    } = this.state;
    const { t } = this.props;
    return (
      <div
        style={{
          background: "#F2F2F2",
          content: "",
          display: "block",
          paddingBottom: "53%",
          height: "100%",
        }}>
        <MyHelmet title="Notification" />

        <RecruiterAppBar
          shadow={true}
          menu="notification"
          history={this.props.history}
        />

        <Grid
          container
          style={{
            padding: "7% 22%",
          }}>
          {loaded && (
            <>
              {showReadAll && (
                <Grid item lg={12} md={12} sm={12}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Button
                      onClick={() => this.readAll()}
                      style={{
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: palette.primary,
                        textTransform: "none",
                        textDecoration: "underline",
                      }}>
                      {t("notification:list.markAll")}
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Grid item lg={12} md={12} sm={12}>
                <Grid
                  container
                  style={{
                    background: "white",
                    border: "1px solid #E5E5E5",
                    boxSizing: "border-box",
                    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)",
                    borderRadius: "4px",
                  }}>
                  <Grid item lg={6} md={6} sm={6}>
                    <Grid container justify="flex-start" alignItems="center">
                      <Typography
                        style={{
                          fontSize: "24px",
                          lineHeight: "31px",
                          padding: 30,
                          color: palette.primary,
                        }}>
                        {t("notification:list.title")}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6}>
                    <Grid
                      container
                      justify="flex-end"
                      alignItems="center"
                      style={{ padding: "20px 30px" }}>
                      <div
                        style={{
                          padding: 5,
                          paddingLeft: 10,
                          borderRadius: 4,
                          display: "flex",
                          border: "1px solid #2E69B2",
                          alignItems: "center",
                          width: 320,
                        }}>
                        <Search
                          style={{
                            color: palette.primary,
                            paddingBottom: 2,
                            paddingRight: 15,
                          }}
                        />
                        <InputBase
                          placeholder={t("notification:list.searchLabel")}
                          style={{
                            width: 250,
                            color: palette.primary,
                            fontWeight: "lighter",
                          }}
                          onChange={e => this.search(e.target.value)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <span
                    style={{
                      width: "100%",
                      borderBottom: "1.5px solid " + palette.popacity(0.1),
                    }}
                  />
                  {notificationList.length === 0 ? (
                    <div style={{ minHeight: 300, width: "100%" }}>
                      <Grid item lg={12} md={12} sm={12}>
                        <Grid container justify="center" alignItems="center">
                          <img
                            style={{
                              height: "4vw",
                              marginTop: "8%",
                            }}
                            src={IconFolder}
                            alt={"empty"}
                          />
                        </Grid>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12}>
                        <Grid container justify="center" alignItems="center">
                          <Typography
                            style={{
                              fontSize: "16px",
                              fontWeight: "lighter",
                              lineHeight: "24px",
                              marginTop: "30",
                              color: palette.primary,
                              width: "200px",
                              textAlign: "center",
                            }}>
                            {t("notification:list.emptyNotif")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <div>
                      {notificationList.map((v, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              borderBottom:
                                "1.5px solid " + palette.popacity(0.1),
                            }}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              style={
                                v.isRead
                                  ? {
                                      background: "white",
                                    }
                                  : {
                                      background: "rgba(82, 200, 244, 0.2)",
                                    }
                              }>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => this.readSpecific(v.id)}
                                to="x">
                                <Grid
                                  container
                                  style={{
                                    width: "100%",
                                    padding: "20px 30px",
                                  }}>
                                  <Grid item lg={2} md={2} sm={2}>
                                    <ImageCircle size={100} url={v.photo} />
                                  </Grid>

                                  <Grid item lg={10} md={10} sm={10}>
                                    <Grid
                                      container
                                      justify="flex-start"
                                      alignItems="center">
                                      <Grid item lg={12} md={12} sm={12}>
                                        <Typography
                                          className="FromNow"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "lighter",
                                            lineHeight: "18px",
                                            marginBottom: "0.5%",
                                            color: palette.primary,
                                          }}>
                                          {this.timeFromNow(v.createdTime)}
                                        </Typography>
                                      </Grid>

                                      <Grid item lg={12} md={12} sm={12}>
                                        <Typography
                                          style={{
                                            fontSize: "16px",
                                            lineHeight: "21px",
                                            marginBottom: "1%",
                                            color: palette.primary,
                                          }}>
                                          {getItem("lang") === "en"
                                            ? v.subjectEng
                                            : v.subjectIna}
                                        </Typography>
                                      </Grid>

                                      <Grid item lg={12} md={12} sm={12}>
                                        <Typography
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "lighter",
                                            lineHeight: "18px",
                                            color: palette.primary,
                                          }}>
                                          {getItem("lang") === "en"
                                            ? v.messageEng
                                            : v.messageIna}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          boxSizing: "border-box",
                          padding: 20,
                        }}>
                        {!loadPage && showLoadMore && (
                          <div
                            onClick={() => {
                              this.setState(
                                { page: this.state.page + 1 },
                                () => {
                                  this.getNotification();
                                }
                              );
                            }}
                            style={{
                              color: palette.primary,
                              cursor: "pointer",
                            }}>
                            {t("general:button.loadMore")}
                          </div>
                        )}
                        {loadPage && (
                          <CircularProgress
                            size={24}
                            style={{ color: palette.primary }}
                            disableShrink
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }
}
export default withTranslation()(RecruiterNotification);
