import TemporaryView from "app/pages/recruiter/candidates/download-info/temporary-view";
import {
  Home,
  VerifyEmail,
  VerifySuccess,
  Logout,
  Welcome,
  BioData,
  BasicTest,
  PretestPage,
  AboutPage,
  ContactPage,
  FinishTestPage,
  ValidatingEmail,
  ApplicantForgotPassword,
  ApplicantSetNewPassword,
  ApplicantSuccessReset,
  TermCondition,
  ProfileRecruiterSetup,
  ProfileCompanySetup,
  AdditionalProfileSetup,
  ConfirmationProfileSetup,
  EditProfileSetup,
  ConfirmationProfileCorporate,
  RecruiterDashboard,
  RecruiterCandidates,
  ApplicantLogin,
  ApplicantRegister,
  RecruiterLogin,
  RecruiterRegister,
  VacancyDetail,
  JobRequirement,
  SoftSkill,
  Confirmation,
  EditVacancy,
  ApplicantHome,
  ApplicantJobOpening,
  JobOpeningDetail,
  InterviewSchedule,
  Notification,
  RecruiterUnderConstruction,
  ApplicantRequestLogut,
  ForceLogout,
  DownloadCV,
  WorkReady,
  AboutYou,
  VideoInterview,
  EquipmentInterview,
  InterviewPage,
  InterviewSamplePage,
  SelectTemplate,
  EditCV,
  CandidatesInformation,
  CandidatesTestReport,
  CandidatesVideoInterview,
  CandidatesAbout,
  ScheduleList,
  ScheduleCreate,
  ScheduleEdit,
  CalendarSchedule,
  UserDashboard,
  AddUserRecruiter,
  RecruiterUserCreatePassword,
  RecruiterSuccessCreatePassword,
  UserProfile,
  UserFeedbackProfile,
  UserJobSpectrumProfile,
  UserDescriptionProfile,
  RecruiterNotification,
  RecruiterViewCandidateList,
} from "../pages";

const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/home",
    exact: true,
    component: Home,
  },
];
const home = [
  {
    path: "/home/about",
    exact: true,
    component: AboutPage,
  },
  {
    path: "/home/contact",
    exact: true,
    component: ContactPage,
  },
  {
    path: "/home/term-condition",
    exact: true,
    component: TermCondition,
  },
];

const user = [
  {
    path: "/user/register/applicant",
    exact: true,
    component: ApplicantRegister,
  },
  {
    path: "/user/register/recruiter",
    exact: true,
    component: RecruiterRegister,
  },
  {
    path: "/user/register/verify",
    exact: true,
    component: VerifyEmail,
  },
  {
    path: "/user/register/validate",
    exact: true,
    component: ValidatingEmail,
  },
  {
    path: "/user/register/verify-email",
    exact: true,
    component: VerifySuccess,
  },
  {
    path: "/user/login/applicant",
    exact: true,
    component: ApplicantLogin,
  },
  {
    path: "/user/login/recruiter",
    exact: true,
    component: RecruiterLogin,
  },
  {
    path: "/user/forgot",
    exact: true,
    component: ApplicantForgotPassword,
  },
  {
    path: "/user/forgot-password",
    exact: true,
    component: ApplicantSetNewPassword,
  },
  {
    path: "/user/success-reset",
    exact: true,
    component: ApplicantSuccessReset,
  },
  {
    path: "/user/logout",
    exact: true,
    component: Logout,
  },
  {
    path: "/user/request-logout",
    exact: true,
    component: ApplicantRequestLogut,
  },
  {
    path: "/user/force-logout",
    exact: true,
    component: ForceLogout,
  },
  {
    path: "/user/create-password",
    exact: true,
    component: RecruiterUserCreatePassword,
  },
  {
    path: "/user/success-create-password",
    exact: true,
    component: RecruiterSuccessCreatePassword,
  },
];
const applicant = [
  //applicant-init-start
  {
    path: "/welcome",
    exact: true,
    component: Welcome,
  },
  {
    path: "/initialize/biodata",
    exact: true,
    component: BioData,
  },
  {
    path: "/initialize/biodata/:name",
    exact: true,
    component: BioData,
  },
  {
    path: "/initialize/pretest",
    exact: true,
    component: PretestPage,
  },
  {
    path: "/basic-test",
    exact: true,
    component: BasicTest,
  },
  {
    path: "/game",
    exact: true,
    component: BasicTest,
  },
  {
    path: "/game/:gameName",
    exact: true,
    component: BasicTest,
  },
  {
    path: "/basic-test/finish",
    exact: true,
    component: FinishTestPage,
  },
  //applicant-init-end

  //applicant-core-start
  {
    path: "/user/home",
    exact: true,
    component: ApplicantHome,
  },
  {
    path: "/user/profile",
    exact: true,
    component: UserProfile,
  },
  {
    path: "/user/profile/description",
    exact: true,
    component: UserDescriptionProfile,
  },
  {
    path: "/user/profile/job-spectrum",
    exact: true,
    component: UserJobSpectrumProfile,
  },
  {
    path: "/user/profile/feedback",
    exact: true,
    component: UserFeedbackProfile,
  },
  {
    path: "/user/job-opening",
    exact: true,
    component: ApplicantJobOpening,
  },
  {
    path: "/user/job-opening/detail",
    exact: true,
    component: JobOpeningDetail,
  },
  {
    path: "/user/job-opening/interview-schedule",
    exact: true,
    component: InterviewSchedule,
  },
  {
    path: "/user/notification",
    exact: true,
    component: Notification,
  },
  {
    path: "/user/cv/edit",
    exact: true,
    component: EditCV,
  },
  {
    path: "/user/download",
    exact: true,
    component: DownloadCV,
  },
  {
    path: "/user/ready-to-work",
    exact: true,
    component: WorkReady,
  },
  {
    path: "/user/video-interview",
    exact: true,
    component: VideoInterview,
  },
  {
    path: "/user/video-interview-equipment",
    exact: true,
    component: EquipmentInterview,
  },
  {
    path: "/user/interview-sample",
    exact: true,
    component: InterviewSamplePage,
  },
  {
    path: "/user/interview",
    exact: true,
    component: InterviewPage,
  },
  {
    path: "/user/about-you",
    exact: true,
    component: AboutYou,
  },
  {
    path: "/user/aboutyou",
    exact: true,
    component: AboutYou,
  },
  {
    path: "/user/select-template",
    exact: true,
    component: SelectTemplate,
  },
  //applicant-core-end
];
const recruiter = [
  {
    path: "/recruiter/home",
    exact: true,
    component: RecruiterDashboard,
  },
  {
    path: "/recruiter/candidates",
    exact: true,
    component: RecruiterCandidates,
  },
  {
    path: "/recruiter/profile-setup",
    exact: true,
    component: ProfileRecruiterSetup,
  },
  {
    path: "/recruiter/profile-company",
    exact: true,
    component: ProfileCompanySetup,
  },
  {
    path: "/recruiter/profile-additional",
    exact: true,
    component: AdditionalProfileSetup,
  },
  {
    path: "/recruiter/profile-confirmation",
    exact: true,
    component: ConfirmationProfileSetup,
  },
  {
    path: "/recruiter/profile-edit",
    exact: true,
    component: EditProfileSetup,
  },
  {
    path: "/recruiter/profile-corporate-confirmation",
    exact: true,
    component: ConfirmationProfileCorporate,
  },
  {
    path: "/recruiter/viewcandidatelist/:id",
    exact: true,
    component: RecruiterViewCandidateList,
  },
  {
    path: "/recruiter/vacancy-detail",
    exact: true,
    component: VacancyDetail,
  },
  {
    path: "/recruiter/job-requirement",
    exact: true,
    component: JobRequirement,
  },
  {
    path: "/recruiter/soft-skill",
    exact: true,
    component: SoftSkill,
  },
  {
    path: "/recruiter/confirmation",
    exact: true,
    component: Confirmation,
  },
  {
    path: "/recruiter/edit-vacancy",
    exact: true,
    component: EditVacancy,
  },
  {
    path: "/recruiter/candidate-export/:id",
    exact: true,
    component: TemporaryView,
  },
  {
    path: "/recruiter/notification",
    exact: true,
    component: RecruiterNotification,
  },
  //start -> candidates information
  {
    path: "/recruiter/candidates/information/cv/:id",
    exact: true,
    component: CandidatesInformation,
  },
  {
    path: "/recruiter/candidates/information/test-report/:id",
    exact: true,
    component: CandidatesTestReport,
  },
  {
    path: "/recruiter/candidates/information/video-interview/:id",
    exact: true,
    component: CandidatesVideoInterview,
  },
  {
    path: "/recruiter/candidates/information/about/:id",
    exact: true,
    component: CandidatesAbout,
  },
  //end -> candidates information
  //start -> shcedule interview
  {
    path: "/recruiter/schedule/:id_vacancy",
    exact: true,
    component: ScheduleList,
  },
  {
    path: "/recruiter/schedule-create",
    exact: true,
    component: ScheduleCreate,
  },
  {
    path: "/recruiter/schedule-edit",
    exact: true,
    component: ScheduleEdit,
  },
  //start -> shcedule interview

  //start -> calendarshcedule
  {
    path: "/recruiter/calendar/:id_vacancy",
    exact: true,
    component: CalendarSchedule,
  },
  //start -> calendar shcedule

  {
    path: "/recruiter/users",
    exact: true,
    component: UserDashboard,
  },
  {
    path: "/recruiter/add-user",
    exact: true,
    component: AddUserRecruiter,
  },
];

const recruiterMaintenance = [
  {
    path: "/recruiter/under-construction",
    exact: true,
    component: RecruiterUnderConstruction,
  },
];
export { routes, user, home, applicant, recruiter, recruiterMaintenance };
