import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/pages/main/App";
import * as serviceWorker from "./serviceWorker";
import "./assets/css/fonts/hurme-geometric-sans-3/font-hurme.css";
import "./assets/css/form.css";

import i18n from "i18n";
import { I18nextProvider } from "react-i18next";
import { Grid } from "@material-ui/core";
import { loadImage, AppHistory } from "app/utils";
import { Router } from "react-router-dom";
const Loader = () => (
  <Grid
    direction="column"
    container
    justify="center"
    alignItems="center"
    style={{ height: "100vh" }}>
    <img src={loadImage("logo.png")} alt="Logo" width={100} />
  </Grid>
);
ReactDOM.render(
  <Router history={AppHistory}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </I18nextProvider>
  </Router>,
  document.getElementById("root")
);
console.warn = () => {};
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if (process.env.NODE_ENV === "development") {
serviceWorker.unregister();
// } else {
//   serviceWorker.register();
// }
