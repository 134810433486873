import React from "react";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import { palette } from "assets/css/main";
import PropTypes from "prop-types";
import ConfirmPersonalInfo from "./confirmation-personal-info";
import { withTranslation } from "react-i18next";
import ConfirmationFormalEducation from "./confirmation-formal-education";
import ConfirmationInformalEducation from "./confirmation-informal-education";
import ConfirmationOrganizationExperience from "./confirmation-organization-experience";
import ConfirmationWorkExperience from "./confirmation-work-experience";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class ConfirmationPanel extends React.Component {
  state = {
    content: <div></div>,
  };
  constructor(props) {
    super(props);
    this.state = {
      activeTab: [],
      value: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event, newValue) => {
    //this.setState({ newValue });
    this.setState({ value: newValue });
  };
  
  render() {
    const { value } = this.state;
    const { t,alreadyGet, data } = this.props;
    return (
      <div className="EditCV">
        <div
          style={{
            height: 23,
            padding: "3% 3%",
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "#2E69B2",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            color: "white",
            fontSize: 24,
          }}>
          Biodata
        </div>
        <div
          style={{
            //height: 1290,
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            fontSize: 24,
          }}>
          <div
            style={{
              width: "100%",
              padding: "0px 10px",
              boxSizing: "border-box",
            }}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: palette.primary,
                },
              }}
              className="CVTab"
              value={value}
              onChange={this.handleChange}
              style={{
                color: palette.primary,
                fontWeight: "bolder",
                width: "100%",
                fontSize: 15,
                display: "flex",
                justifyContent: "center",
              }}>
              <Tab
                label={t("biodata:CVpersonalInfo")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVformalEducation")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVinformalEducation")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVorganizationalExperience")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVworkExperience")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
            </Tabs>

            {alreadyGet ? (
              <div>
                <TabPanel value={value} index={0} className="Tab-preview">
                  <ConfirmPersonalInfo data={data} updateData={this.props.updateData} />
                </TabPanel>
                <TabPanel value={value} index={1} className="Tab-preview">
                  <ConfirmationFormalEducation data={data} updateData={this.props.updateData} />
                </TabPanel>
                <TabPanel value={value} index={2} className="Tab-preview">
                  <ConfirmationInformalEducation data={data} updateData={this.props.updateData} />
                </TabPanel>
                <TabPanel value={value} index={3} className="Tab-preview">
                  <ConfirmationOrganizationExperience data={data} updateData={this.props.updateData} />
                </TabPanel>
                <TabPanel value={value} index={4} className="Tab-preview">
                  <ConfirmationWorkExperience data={data} updateData={this.props.updateData} />
                </TabPanel>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ConfirmationPanel);
