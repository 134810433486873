import React from "react";
import { withTranslation } from "react-i18next";
import { Typography, Grid, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { ArrowForward } from "@material-ui/icons";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  RequestPost,
  UserSession,
  RequestGet,
  setItem,
  t,
  clearAll,
  AUTH_API,
  AppHistory,
} from "app/utils";
import { DialogueInfo, Header, HeaderNologin, Footer } from "app/components";
import { ImgLoginRecruiter, IconEyeOpen, IconEyeClose } from "assets/img";
import MyHelmet from "app/components/header/MyHelmet";
import history from "app/utils/general/history";
import { isMobile } from "react-device-detect";

class RecruiterLogin extends React.Component {
  params = null;
  constructor(props) {
    super(props);
    this.translate = this.props.t;
    this.state = {
      showPassword: false,
      email: process.env.REACT_APP_USER_RECRUITER
        ? process.env.REACT_APP_USER_RECRUITER
        : "",
      password: process.env.REACT_APP_PASS_RECRUITER
        ? process.env.REACT_APP_PASS_RECRUITER
        : "",
      tnc: false,
      accept_tnc: false,
      error: "",
      open: false,
    };
    this.submitForm = this.submitForm.bind(this);
    this.params = new URLSearchParams(this.props.location.search);
  }
  submitForm = ev => {
    ev.preventDefault();
    var form = this.state;
    var data = new URLSearchParams();
    data.append("client_id", process.env.REACT_APP_CLIENT);
    data.append("client_secret", process.env.REACT_APP_SECRET);
    data.append("grant_type", "password");
    data.append("username", form.email);
    data.append("password", form.password);
    if (localStorage.getItem("session_token__")) {
      data.append("session_token", localStorage.getItem("session_token__"));
    }
    RequestPost(AUTH_API + "connect/token", data)
      .then(resLogin => {
        UserSession.setData(resLogin.data);
        localStorage.setItem("session_token__", resLogin.data.session_token);
        RequestGet(AUTH_API + "connect/userinfo")
          .then(res => {
            setItem("GR", res.data.role);
            setItem("HG_CLIENT", process.env.REACT_APP_CLIENT);
            setItem("user_info", JSON.stringify(res.data));
            setItem("user_id", res.data.sub);
            setItem("name", res.data.fullname);
            if (res.data.role === "Recruiter") {
              if (isMobile) {
                AppHistory.push("/not-supported");
              } else {
                window.location.reload();
              }
            } else {
              this.setState({ open: true });
              clearAll();
            }
          })
          .catch(err => {
            this.setState({ error: err.data.error_description });
          });
      })
      .catch(e => {
        if (e.data.error_description) {
          if (
            e.data.error_description.indexOf(
              "Already login in another device"
            ) > -1
          ) {
            this.setState({
              showForceLogout: true,
              error: e.data.error_description,
            });
          }
        } else {
          this.setState({
            error: t("error.exception.description"),
          });
        }
      });
  };
  render() {
    const { t } = this.props;
    const { open, showPassword, showForceLogout } = this.state;
    if (isMobile) {
      return (
        <div>
          <MyHelmet
            title={t("link.login")}
            description="Hiregrade - Login Recruiter"
          />
          <HeaderNologin />
          <Grid style={{ minHeight: "100vh" }}>
            <Grid item lg={6} md={6} sm={6}>
              <div className="auth-mobile" style={{ padding: "100px 30px" }}>
                <div style={{ marginBottom: 20, display: "flex" }}>
                  <Button
                    disabled={true}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: "white",
                      marginRight: 10,
                      backgroundColor: palette.primary,
                    }}>
                    {t("link.login")}
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.history.push("/user/register/recruiter");
                    }}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.signin")}
                  </Button>
                </div>
                <DialogueInfo
                  contentWidth={378}
                  title={t("auth:errorLogin.applicant.title")}
                  content={t("auth:errorLogin.applicant.message")}
                  open={open}
                  onClose={() => this.setState({ open: false })}
                />
                <Typography
                  style={{
                    fontWeight: "bolder",
                    color: palette.primary,
                    marginBottom: 20,
                    fontSize: 24,
                  }}>
                  {t("link.login") + " " + t("auth:recruiter")}
                </Typography>
                <ValidatorForm onSubmit={this.submitForm}>
                  <TextValidator
                    label={this.translate("auth:email")}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                    name="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="Field"
                    value={this.state.email}
                    placeholder={t("auth:placeholder.email")}
                    style={{ width: "100%", marginTop: 20 }}
                    validators={["required", "isEmail"]}
                    autoComplete="username"
                    errorMessages={[
                      this.translate("auth:validation.required"),
                      this.translate("auth:validation.email"),
                    ]}
                  />
                  <div style={{ position: "relative" }}>
                    <TextValidator
                      label={this.translate("auth:password")}
                      onChange={e => {
                        this.setState({ password: e.target.value });
                      }}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={this.state.password}
                      style={{ width: "100%", marginTop: 20 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="Field passwordhint"
                      placeholder={t("auth:placeholder.password")}
                      validators={["required"]}
                      autoComplete={"current-password"}
                      errorMessages={[
                        this.translate("auth:validation.required"),
                      ]}
                    />
                    <img
                      src={showPassword ? IconEyeOpen : IconEyeClose}
                      alt="show hide"
                      onClick={() => {
                        this.setState({ showPassword: !showPassword });
                      }}
                      style={{
                        padding: 5,
                        cursor: "pointer",
                        width: 24.04,
                        position: "absolute",
                        bottom: 22,
                        right: 0,
                      }}
                    />
                  </div>

                  <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    style={{ marginTop: 20 }}>
                    <Button
                      variant="text"
                      style={{ padding: 0 }}
                      onClick={() => {
                        setItem("idRole", "r");
                        this.props.history.push("/user/forgot");
                      }}>
                      <span
                        style={{
                          fontSize: 16,
                          color: palette.primary,
                          textTransform: "capitalize",
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontWeight: "600",
                        }}>
                        {t("auth:forgotPassword")}
                      </span>
                    </Button>
                  </Grid>
                  <Typography
                    style={{ width: "100%", marginBottom: 20, color: "red" }}>
                    {this.state.error}
                  </Typography>
                  {showForceLogout && (
                    <>
                      <Button
                        onClick={() => {
                          history.push("/user/request-logout");
                        }}
                        style={{
                          paddingLeft: 0,
                          marginTop: 20,
                          marginBottom: 24,
                        }}>
                        <span
                          style={{
                            fontSize: 18,
                            fontWeight: "bolder",
                            color: palette.primary,
                            textTransform: "capitalize",
                            marginRight: 5,
                            textDecoration: "underline",
                          }}>
                          {t("auth:forceLogout.link")}
                        </span>
                      </Button>
                      <br />
                    </>
                  )}
                  <Button
                    type="submit"
                    style={{ paddingLeft: 0, marginBottom: 24 }}>
                    <span
                      style={{
                        fontSize: 24,
                        fontWeight: "900",
                        color: palette.primary,
                        textTransform: "capitalize",
                        marginRight: 5,
                        textDecoration: "underline",
                      }}>
                      {this.translate("auth:login")}
                    </span>
                    <ArrowForward style={{ color: palette.primary }} />
                  </Button>
                </ValidatorForm>
              </div>
            </Grid>
            <Grid style={{ width: "100%" }}>
              <div style={{ position: "relative", bottom: 0 }}>
                <Footer />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <MyHelmet
            title={t("link.login")}
            description="Hiregrade - Login Recruiter"
          />
          <Header shadow={true} linkgroup={true} />
          <Grid container style={{ minHeight: "100vh" }}>
            <Grid item lg={6} md={6} sm={6}>
              <div className="auth" style={{ padding: "120px 120px" }}>
                <div style={{ marginBottom: 40 }}>
                  <Button
                    disabled={true}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: "white",
                      marginRight: 10,
                      backgroundColor: palette.primary,
                    }}>
                    {t("link.login")}
                  </Button>
                  <Button
                    onClick={() => {
                      this.props.history.push("/user/register/recruiter");
                    }}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.signin")}
                  </Button>
                </div>
                <DialogueInfo
                  contentWidth={378}
                  title={t("auth:errorLogin.applicant.title")}
                  content={t("auth:errorLogin.applicant.message")}
                  open={open}
                  onClose={() => this.setState({ open: false })}
                />
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: "bolder",
                    color: palette.primary,
                    marginBottom: 40,
                  }}>
                  {t("link.login") + " " + t("auth:recruiter")}
                </Typography>
                <ValidatorForm onSubmit={this.submitForm}>
                  <TextValidator
                    label={this.translate("auth:email")}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                    name="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="Field"
                    value={this.state.email}
                    placeholder={t("auth:placeholder.email")}
                    style={{ width: "100%", marginTop: 20 }}
                    validators={["required", "isEmail"]}
                    autoComplete="username"
                    errorMessages={[
                      this.translate("auth:validation.required"),
                      this.translate("auth:validation.email"),
                    ]}
                  />
                  <div style={{ position: "relative" }}>
                    <TextValidator
                      label={this.translate("auth:password")}
                      onChange={e => {
                        this.setState({ password: e.target.value });
                      }}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={this.state.password}
                      style={{ width: "100%", marginTop: 20 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="Field passwordhint"
                      placeholder={t("auth:placeholder.password")}
                      validators={["required"]}
                      autoComplete={"current-password"}
                      errorMessages={[
                        this.translate("auth:validation.required"),
                      ]}
                    />
                    <img
                      src={showPassword ? IconEyeOpen : IconEyeClose}
                      alt="show hide"
                      onClick={() => {
                        this.setState({ showPassword: !showPassword });
                      }}
                      style={{
                        padding: 10,
                        cursor: "pointer",
                        width: 24.04,
                        position: "absolute",
                        bottom: 22,
                        right: 0,
                      }}
                    />
                  </div>

                  <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    style={{ marginTop: 20 }}>
                    <Button
                      variant="text"
                      style={{ padding: 0 }}
                      onClick={() => {
                        setItem("idRole", "r");
                        this.props.history.push("/user/forgot");
                      }}>
                      <span
                        style={{
                          fontSize: 18,
                          color: palette.primary,
                          textTransform: "capitalize",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}>
                        {t("auth:forgotPassword")}
                      </span>
                    </Button>
                  </Grid>
                  <Typography
                    style={{ width: "100%", marginBottom: 20, color: "red" }}>
                    {this.state.error}
                  </Typography>
                  {showForceLogout && (
                    <>
                      <Button
                        onClick={() => {
                          history.push("/user/request-logout");
                        }}
                        style={{
                          paddingLeft: 0,
                          marginTop: 20,
                          marginBottom: 24,
                        }}>
                        <span
                          style={{
                            fontSize: 18,
                            fontWeight: "bolder",
                            color: palette.primary,
                            textTransform: "capitalize",
                            marginRight: 5,
                            textDecoration: "underline",
                          }}>
                          {t("auth:forceLogout.link")}
                        </span>
                      </Button>
                      <br />
                    </>
                  )}
                  <Button
                    type="submit"
                    style={{ paddingLeft: 0, marginTop: 20, marginBottom: 24 }}>
                    <span
                      style={{
                        fontSize: 24,
                        fontWeight: "bolder",
                        color: palette.primary,
                        textTransform: "capitalize",
                        marginRight: 5,
                        textDecoration: "underline",
                      }}>
                      {this.translate("auth:login")}
                    </span>
                    <ArrowForward style={{ color: palette.primary }} />
                  </Button>
                </ValidatorForm>
              </div>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              style={{
                backgroundColor: palette.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                src={ImgLoginRecruiter}
                alt="Recruiter"
                width={561}
                height={346}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation()(RecruiterLogin);
