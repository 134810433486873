import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import {
  Typography,
  Grid,
  Button,
  TextField,
  InputLabel,
  // Select as SelectDefault,
  // MenuItem,
} from "@material-ui/core";
import { getItem, currencyFormat, removeCurrency, findObject } from "app/utils";
import {
  IconSuggestion,
  IconInformation,
  ImgInformationBackground,
} from "assets/img";
import { MultipleTextField } from "app/components";
import Select from "react-select";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import data from "../data";

const creatableSelectStyles = {
  borderWidth: "0px 0px 1px 0px",
  borderRadius: "0px",
  width: "55%",
};

class VacancyDetailEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editVacancy: data.vacancy_post,
      listVacancy: data.listVacancy,
      other: "",
      workLocs: [],
      listWorkLocation: [],
      listjobfamily: [],
      benefits: [],
      listBenefit: [],
      otherBenefitLocal: [],
      otherBenefitID: "",
      hiredCandidate: data.listVacancy.vacancy.hiredCandidate,
      offeredCandidate: data.listVacancy.vacancy.offeredCandidate,
    };
  }

  handleSuggestion(value) {
    var currentBenefit = this.state.benefits;
    currentBenefit.push(value);

    // manual set
    var newEdit = this.state.editVacancy;
    this.setState(
      {
        benefits: currentBenefit,
        classMenu: "add_from_suggestion",
      },
      () => {
        this.creatableRef.focus();
      }
    );

    setTimeout(() => {
      this.creatableRef.blur();
      this.setState({ classMenu: "" });
    }, 100);
    var val = currentBenefit.map(v => {
      return v.value;
    });
    newEdit.vacancyBenefits = val;
    this.setState({ editVacancy: newEdit });
    data.vacancy_post = newEdit;
  }

  componentDidMount() {
    var ot = this.state.listVacancy.jobFamilies.find(
      p => p.jobFamilyEng === "Other"
    )
      ? this.state.listVacancy.jobFamilies.find(p => p.jobFamilyEng === "Other")
          .id
      : "";
    this.setState({ other: ot });

    // benefit
    var otBenefit = this.state.listVacancy.benefits.find(p => p.benefit === "Others").id;
    this.setState({ otherBenefitID: otBenefit });
    this.setState({
      listBenefit: this.state.listVacancy.benefits
        ? this.state.listVacancy.benefits.map(obj => {
            return {
              value: obj.id,
              label: getItem("lang") === "en"
                ? obj.benefit
                : obj.benefitIna,
            };
          })
        : [],
    });
    this.setState({
      benefits: this.state.listVacancy.benefits
        ? this.state.editVacancy.vacancyBenefits.map(v => {
            return {
              value: v,
              label: getItem("lang") === "en"
                ? this.state.listVacancy.benefits.find(p => p.id === v).benefit
                : this.state.listVacancy.benefits.find(p => p.id === v).benefitIna,
            };
          })
        : [],
    });

    // work location
    this.state.listVacancy.locationCity.forEach((obj ,i)=>
    {

          if(obj.id === 9999){
            const listWorkLocation = this.state.listWorkLocation
          var listWorkLocationend =
            { 
            value: obj.id,
            label: getItem("lang") === "en" ? obj.name : this.props.t("vacancy:create.vacancyDetail.dropdown"), 
            }

          const listWorkLocationnew = [listWorkLocationend].concat(listWorkLocation)
          this.setState({listWorkLocation : listWorkLocationnew })
            

          }else{
            const listWorkLocation = this.state.listWorkLocation
            listWorkLocation[i] =
            { 
            value: obj.id,
            label:obj.name
            }
            this.setState({listWorkLocation : listWorkLocation})
          }
    })

    this.setState({
      listjobfamily: this.state.listVacancy.jobFamilies.map(obj => {
        return {
          value: obj.id,
          label: getItem("lang") === "en" ? obj.jobFamilyEng : obj.jobFamilyIna,
        };
      }),
    });
    
    this.setState({
      workLocs: this.state.listVacancy.locationCity
        ? this.state.editVacancy.vacancyWorkLocation.map(v => {
            return {
              value: v,
              label: v === 9999? getItem("lang") === "en" ? this.state.listVacancy.locationCity.find(p => p.id === v).name : this.props.t("vacancy:create.vacancyDetail.dropdown") : this.state.listVacancy.locationCity.find(p => p.id === v).name,
            };
          })
        : [],
    });

    // other performs
    if(this.state.editVacancy.otherBenefits.length > 0) {
      this.setState({
        otherBenefitLocal: this.state.editVacancy.otherBenefits.map(v => {
          return {
            value: v,
            label: v
          }
        })
      })
    }
  }

  render() {
    const {
      listVacancy,
      other,
      editVacancy,
      listBenefit,
      benefits,
      otherBenefitLocal,
      otherBenefitID,
      workLocs,
      listWorkLocation,
      listjobfamily,
      classMenu,
      hiredCandidate,
      offeredCandidate,
    } = this.state;
    const { t } = this.props;
    return (
      <Grid className="edit-vacancy" container style={{ padding: "4% 6% 8%" }}>
        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "8%" }}>
          <Typography
            style={{
              fontSize: "24px",
              color: palette.primary,
            }}>
            {t("vacancy:create.step", { returnObjects: true })[0]}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <TextField
            style={{ width: "55%" }}
            autoFocus={true}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={t("vacancy:create.vacancyDetail.jobTitle.placeholder")}
            label={t("vacancy:create.vacancyDetail.jobTitle.label")}
            className="Field"
            onChange={v => {
              var newEdit = editVacancy;
              newEdit.vacancy.jobTitle = v.target.value;
              this.setState({ editVacancy: newEdit });
              data.vacancy_post = newEdit;
            }}
            value={editVacancy.vacancy.jobTitle}
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: 40, backgroundColor: ""}}>
          <div style={{width:"55%"}}>
            <InputLabel
              shrink
              id="vacancy"
              style={{ color: palette.primary, marginBottom: 15 }}>
              {t("vacancy:create.vacancyDetail.jobFamily.label")}
            </InputLabel>
            <Select
              className={classMenu}
              ref={ref => {
                this.ddlReff = ref;
              }}
              openMenuOnFocus={true}
              classNamePrefix="changecolorselectedit"
              placeholder={t(
                "vacancy:create.vacancyDetail.jobFamily.placeholder"
              )}


              options={listjobfamily}
              value={listjobfamily.find(
                p => p.value === editVacancy.vacancy.jobFamily
              )}
              isSearchable={true}
              onChange={v => {
                if (v === null) {
                    const newEdit = editVacancy;
                    editVacancy.vacancy.jobFamily = "";
                    this.setState({ editVacancy: newEdit });
                    data.vacancy_post = newEdit;
                } else {
                    const newEdit = editVacancy;
                    newEdit.vacancy.jobFamily = v.value;
                    newEdit.vacancy.subJobFamily = "";
                    newEdit.vacancy.otherValue = "";

                    this.setState({ editVacancy: newEdit });
                    data.vacancy_post = newEdit;

                  this.setState({ classMenu: "add_from_dds" }, () => {
                    this.ddlReff.focus();
                  });

                  setTimeout(() => {
                    this.ddlReff.blur();
                    this.setState({ classMenu: "" });
                  }, 100);
                }
              }}
            />
          </div>
        </Grid>

        <Grid
              item
              lg={12}
              md={12}
              sm={12}
              style={{ textAlign: "right", marginTop: "-54px", marginRight: "10%"}}
              hidden={editVacancy.vacancy.jobFamily === other ? false : true}>
              <TextField
                style={{ width: "35%" }}
                autoFocus={true}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("vacancy:create.vacancyDetail.jobFamily.otherPlaceholder")}
                className="Field"
                onChange={v => {
                  var newEdit = editVacancy;
                  newEdit.vacancy.otherValue = v.target.value;
                  this.setState({ editVacancy: newEdit });
                  data.vacancy_post = newEdit;
                }}
                value={editVacancy.vacancy.otherValue}
          />
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: "3%" }}
          hidden={
            editVacancy.vacancy.jobFamily === "" ||
            editVacancy.vacancy.jobFamily === other
              ? true
              : false
          }
          >
          <div style={{width:"55%"}}>
            <InputLabel
              shrink
              id="vacancy"
              style={{ color: palette.primary, marginBottom: 15 }}>
              {t("vacancy:create.vacancyDetail.subJobFamily.label")}
            </InputLabel>
            <Select
              className={classMenu}
              ref={ref => {
                this.ddlRefff = ref;
              }}
              openMenuOnFocus={true}
              classNamePrefix="changecolorselectedit"
              placeholder={t(
                "vacancy:create.vacancyDetail.subJobFamily.placeholder"
              )}
              options={
                !(
                    editVacancy.vacancy.jobFamily === "" ||
                    editVacancy.vacancy.jobFamily === other
                )
                  ? listVacancy.jobFamilies
                      .find(p => p.id === editVacancy.vacancy.jobFamily)
                      .jobFamilyDetails.map((v, i) => {
                        return {
                          value: v.id,
                          label:
                            getItem("lang") === "en"
                              ? v.jobFamilyDetailEng
                              : v.jobFamilyDetailIna,
                        };
                      })
                  : null
              }
              value={
                !(
                    editVacancy.vacancy.subJobFamily === "" ||
                    editVacancy.vacancy.jobFamily === other
                )
                  ? listVacancy.jobFamilies
                      .find(p => p.id === editVacancy.vacancy.jobFamily )
                      .jobFamilyDetails.map((v, i) => {
                        return {
                          value: v.id,
                          label:
                            getItem("lang") === "en"
                              ? v.jobFamilyDetailEng
                              : v.jobFamilyDetailIna,
                        };
                      })
                      .find(p => p.value === editVacancy.vacancy.subJobFamily)
                  : {}
              }
              isSearchable={true}
              onChange={v => {
                if (v === null) {
                    const newEdit = editVacancy;
                    newEdit.vacancy.subJobFamily = "";
                    this.setState({ editVacancy: newEdit });
                    data.vacancy_post = newEdit;
                } else {
                    const newEdit = editVacancy;
                    newEdit.vacancy.subJobFamily = v.value;
                    this.setState({ editVacancy: newEdit });
                    data.vacancy_post = newEdit;

                  this.setState({ classMenu: "add_from_dds" }, () => {
                    this.ddlRefff.focus();
                  });

                  setTimeout(() => {
                    this.ddlRefff.blur();
                    this.setState({ classMenu: "" });
                  }, 100);
                }
              }}
            />
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <TextField
            error={this.props.validation}
            helperText={
              this.props.validation &&
              "Number of Vacancy can't less than Hired Candidate"
            }
            style={{ width: "55%" }}
            autoFocus={true}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={t(
              "vacancy:create.vacancyDetail.numberOfVacancy.placeholder"
            )}
            label={t("vacancy:create.vacancyDetail.numberOfVacancy.label")}
            className="Field"
            onChange={v => {
              var newEdit = editVacancy;
              if (v.target.value === "") {
                newEdit.vacancy.vacancyNumber = 0;
              } else {
                var stringVacancyNum = removeCurrency(v.target.value);
                newEdit.vacancy.vacancyNumber = parseInt(stringVacancyNum);
              }

              // validasi Number of Vacancy tidak boleh kurang dari Hired Vacancy
              if (
                newEdit.vacancy.vacancyNumber <
                hiredCandidate + offeredCandidate
              ) {
                this.props.onValidation(true);
              } else {
                this.props.onValidation(false);
              }

              this.setState({ editVacancy: newEdit });
              data.vacancy_post = newEdit;
            }}
            value={
              String(editVacancy.vacancy.vacancyNumber)
                ? currencyFormat(String(editVacancy.vacancy.vacancyNumber))
                : "0"
            }
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <div style={{ width: "55%" }}>
            <InputLabel
              shrink
              id="WorkLocation"
              style={{ color: palette.primary, marginBottom: 15 }}>
              {t("vacancy:create.vacancyDetail.workLocation.label")}
              <img
                className="information-tag"
                style={{
                  marginLeft: 10,
                  marginBottom: -2,
                }}
                src={IconInformation}
                height={"15h"}
                alt="InformationTag"
              />
              <div
                className="information"
                style={{
                  position: "absolute",
                  marginLeft: 30,
                  marginTop: -110,
                }}>
                <img
                  src={ImgInformationBackground}
                  height={"70h"}
                  alt="Information"
                />

                <div
                  style={{
                    position: "absolute",
                    color: "white",
                    fontSize: 12,
                    textTransform: "none",
                    top: "30%",
                    left: "15%",
                  }}>
                  {t("vacancy:create.vacancyDetail.workLocation.hover")}
                </div>
              </div>
            </InputLabel>

            <Select
              classNamePrefix={"work-location"}
              placeholder={t(
                "vacancy:create.vacancyDetail.workLocation.placeholder"
              )}
              isOptionDisabled={option =>
                findObject(workLocs, "value", 9999)
                  ? option.value !== 9999
                  : !findObject(workLocs, "value", 9999) &&
                    workLocs.length > 0
                  ? option.value === 9999
                  : option.value === null
              }
              options={listWorkLocation}
              value={workLocs}
              isSearchable={true}
              isMulti={true}
              onChange={v => {
                var newEdit = editVacancy;

                if (v === null) {
                  this.setState({ workLocs: [] });
                  newEdit.vacancyWorkLocation = [];
                } else {
                  this.setState({ workLocs: v });
                  var value = v.map(v => {
                    return v.value;
                  });
                  newEdit.vacancyWorkLocation = value;
                }

                this.setState({ editVacancy: newEdit });
                data.vacancy_post = newEdit;
              }}
            />
          </div>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <MultipleTextField
            style={{ width: "60%" }}
            fieldData={editVacancy.vacancyJobDesc}
            label={t("vacancy:create.vacancyDetail.jobDesc.label")}
            placeholder={t("vacancy:create.vacancyDetail.jobDesc.placeholder")}
            onChange={v => {
              var newEdit = editVacancy;
              newEdit.vacancyJobDesc = v;
              this.setState({ editVacancy: newEdit });
              data.vacancy_post = newEdit;
            }}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.vacancyDetail.salaryRange.label")}
          </Typography>
        </Grid>
        <div style={{ width: "75%" }}>
          <Grid container>
            <Grid item lg={1} md={1} sm={1} style={{ textAlign: "center" }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  color: palette.primary,
                  padding: "20px 10px",
                }}>
                Rp
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <TextField
                onChange={v => {
                  var newEdit = editVacancy;
                  if (v.target.value === "") {
                    newEdit.vacancy.salaryMin = 0;
                  } else {
                    var stringSalaryMin = removeCurrency(v.target.value);
                    newEdit.vacancy.salaryMin = parseInt(stringSalaryMin);
                  }
                  this.setState({ editVacancy: newEdit });
                  data.vacancy_post = newEdit;
                }}
                style={{ width: "100%" }}
                autoFocus={true}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={"Eg. 1.000.000"}
                className="Field"
                value={
                  String(editVacancy.vacancy.salaryMin)
                    ? currencyFormat(String(editVacancy.vacancy.salaryMin))
                    : "0"
                }
              />
            </Grid>
            <Grid item lg={2} md={2} sm={2} style={{ textAlign: "right" }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  color: palette.primary,
                  padding: "20px 30px",
                }}>
                - Rp
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <TextField
                error={this.props.validation}
                helperText={this.props.validation && "Incorrect value"}
                onChange={v => {
                  var newEdit = editVacancy;
                  if (v.target.value === "") {
                    newEdit.vacancy.salaryMax = 0;
                  } else {
                    var stringSalaryMin = removeCurrency(v.target.value);
                    newEdit.vacancy.salaryMax = parseInt(stringSalaryMin);
                  }
                  this.setState({ editVacancy: newEdit });
                  data.vacancy_post = newEdit;

                  if (
                    data.vacancy_post.vacancy.salaryMin >
                    data.vacancy_post.vacancy.salaryMax
                  ) {
                    this.props.onValidation(true);
                  } else {
                    this.props.onValidation(false);
                  }
                }}
                style={{ width: "95%" }}
                autoFocus={true}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={"Eg. 2.000.000"}
                className="Field"
                value={
                  String(editVacancy.vacancy.salaryMax)
                    ? currencyFormat(String(editVacancy.vacancy.salaryMax))
                    : ""
                }
              />
            </Grid>
          </Grid>
        </div>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <div style={{ width: "55%" }}>
            <InputLabel
              shrink
              id="vacancy"
              style={{ color: palette.primary, marginBottom: 15 }}>
              {t("vacancy:create.vacancyDetail.benefit.label")}
            </InputLabel>
            <ReactSelect
              className={classMenu}
              classNamePrefix={"benefits"}
              ref={ref => {
                this.creatableRef = ref;
              }}
              openMenuOnFocus={true}
              placeholder={t(
                "vacancy:create.vacancyDetail.benefit.placeholder"
              )}
              isMulti
              isClearable
              onChange={v => {
                var newEdit = editVacancy;
                if (v === null) {
                  this.setState({ benefits: [] });
                  newEdit.vacancyBenefits = [];

                  this.setState({ otherBenefitLocal: [] });
                  newEdit.otherBenefits = [];
                } else {
                  this.setState({ benefits: v });
                  var value = v.map(v => {
                    return v.value;
                  });
                  newEdit.vacancyBenefits = value;
                }
                this.setState({ editVacancy: newEdit });
                data.vacancy_post = newEdit;
              }}
              options={listBenefit}
              styles={creatableSelectStyles}
              value={benefits ? benefits : []}
            />
          </div>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: "3%" }}
          hidden={editVacancy.vacancyBenefits.some(p => p === otherBenefitID) ? false : true}
        >
          <div style={{ width: "55%" }}>
            <CreatableSelect
              classNamePrefix={"benefits"}
              placeholder={t("vacancy:create.vacancyDetail.jobFamily.otherPlaceholder")}
              isMulti
              onChange={obj => {
                var newEdit = editVacancy;
                if (obj === null) {
                  this.setState({ otherBenefitLocal: [] });
                  newEdit.otherBenefits = [];
                } else {
                  this.setState({ otherBenefitLocal: obj });
                  var value = obj.map(v => {
                      return v.value;
                    });
                  newEdit.otherBenefits = value;
                }
                this.setState({ editVacancy: newEdit });
                data.vacancy_post = newEdit;
              }}
              styles={creatableSelectStyles}
              value={otherBenefitLocal}
            />
          </div>
        </Grid>

        <Grid item lg={9} md={9} sm={9} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Suggested benefit that will be obtained
          </Typography>
          {listBenefit.map((v, i) => {
            return findObject(benefits, "value", v.value) ? null : (
              <Button
                key={i}
                className="suggestion-button"
                onClick={() => {
                  this.handleSuggestion(v);
                }}
                // onClick={() => {this.handleChange({benefit: v})}}
              >
                <div className="suggestion-label">{v.label}</div>
                <div className="suggestion-image">
                  <img src={IconSuggestion} height={"10vh"} alt={v.label} />
                </div>
              </Button>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(VacancyDetailEdit);
