import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import RecruiterFooter from "app/components/footer/RecruiterFooter";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette } from "assets/css/main";
import { Typography, Grid, Button, Link } from "@material-ui/core";
import { SideStep, OptionsImage, CheckboxImage } from "app/components";
import data from "./data";
import { IconSummary, IconConfirmationCheck } from "assets/img";
import StepHeader from "app/components/header/StepHeader";
import SoftSkillPopup from "./soft-skill-popup";
import {
  AppHistory,
  findObject,
  GetLocalItem,
  removeObject,
  RequestGet,
  SetLocalItem,
} from "app/utils";
import { getItem } from "app/utils";
import alertData from "app/components/alert/alert-data";

const tableStyle = {
  width: "100%",
  border: "1px solid rgb(175, 191, 230, 0.5)",
  borderCollapse: "inherit",
  borderSpacing: "0px",
  textAlign: "left",
  borderRadius: "4px",
};

const linkStyleActive = {
  color: palette.primary,
  fontSize: 18,
  borderBottom: "2px solid " + palette.primary,
  display: "flex",
  marginRight: 40,
  height: 40,
  alignItems: "center",
  cursor: "pointer",
};
const linkStyle = {
  color: palette.primary,
  marginRight: 40,
  fontSize: 18,
  display: "flex",
  borderBottom: "2px solid transparent",
  height: 40,
  alignItems: "center",
  cursor: "pointer",
};

const thStyle = {
  width: "100%",
  border: "1px solid rgb(175, 191, 230, 0.2)",
  fontWeight: "normal",
  fontSize: "16px",
  color: palette.primary,
  padding: "20px 26px",
};

const tdStyle = {
  width: "100%",
  border: "1px solid rgb(175, 191, 230, 0.2)",
  fontWeight: "lighter",
  fontSize: "14px",
  color: palette.primary,
  padding: "8px",
};

class SoftSkill extends React.Component {
  constructor(props) {
    super(props);
    if (GetLocalItem("vacancyPost")) {
      data.vacancy_post = GetLocalItem("vacancyPost");
    }

    this.state = {
      ...data.vacancy_post,
      step: 0,
      softSkillHeaders: [],
      sum: GetLocalItem("softSkillSum") ? GetLocalItem("softSkillSum") : 0,
      soft_skill_api: data.soft_skill_api,
      listVacancySoftskill: data.listVacancySoftskill,
      popupOpen: false,
      menu: null,
      thinkingSkill: [],
      workingAttitude: [],
      relationshipSkill: [],
      loaded: false,
    };
    this.translate = this.props.t;
  }

  togglePopup(newVal) {
    this.setState({ popupOpen: newVal });

    this.setState({
      thinkingSkill: this.renderThinkingSkill(),
      workingAttitude: this.renderWorkingAttitude(),
      relationshipSkill: this.renderRelationshipSkill(),
    });
  }

  cekValidation() {
    var result = false;

    if (
      data.vacancy_post.vacancySoftskills.length > 0 &&
      data.vacancy_post.vacancySoftskills[0].softskillHeaderId !== ""
    ) {
      result = true;
    }

    return result;
  }

  saveData() {
    if (this.cekValidation()) {
      SetLocalItem("vacancyPost", data.vacancy_post);
      SetLocalItem("softSkillSum", this.state.sum);
      AppHistory.push("/recruiter/confirmation");
    } else {
      alertData.show(this.props.t("vacancy:create.validation"), "error");
    }
  }

  setup(list) {
    this.setState({ soft_skill_api: list });
    data.soft_skill_api = list;

    this.setState({ menu: list[0].id });
    var objHeaders = this.state.softSkillHeaders;
    list.forEach(v => {
      v.softSkillHeaders.forEach(val => {
        objHeaders.push(val);
      });
    });
    this.setState({ softSkillHeaders: objHeaders }, () => {
      this.setState({ loaded: true });
    });
  }

  componentDidMount() {
    this.getSoftSkill();
  }

  getSoftSkill() {
    if (GetLocalItem("softSkillGet")) {
      this.setup(GetLocalItem("softSkillGet"));
    } else {
      RequestGet("softskill")
        .then(res => {
          SetLocalItem("softSkillGet", res.data);
          this.setup(res.data);
        })
        .catch(er => {
          console.log(er);
        });
    }
  }

  renderThinkingSkill() {
    return this.state.soft_skill_api[0].softSkillHeaders.map((val, index) => {
      return (
        <tbody style={{ width: "100%" }} key={"thinkingSkill-" + index}>
          {val.softSkillDetails.map((v, i) => {
            return (
              <tr style={{ width: "100%" }} key={i}>
                {i === 0 ? (
                  <th
                    style={{ ...thStyle, width: "35%" }}
                    rowSpan={val.softSkillDetails.length}>
                    {getItem("lang") === "en"
                      ? val.softskillNameEng
                      : val.softskillNameIna}
                  </th>
                ) : null}
                <td
                  style={{
                    ...tdStyle,
                    width: "57%",
                    paddingLeft: "24px",
                  }}>
                  {v.softskillValueEng}
                </td>
                <td style={{ ...tdStyle, width: "8%", paddingLeft: "12px" }}>
                  {data.vacancy_post.vacancySoftskills.find(
                    p => p.softskillHeaderId === val.id
                  ) ? (
                    data.vacancy_post.vacancySoftskills.find(
                      p => p.softskillDetailId === v.id
                    ) ? (
                      <img
                        src={IconConfirmationCheck}
                        alt={"check" + i}
                        height="24px"
                        align="center"
                      />
                    ) : null
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    });
  }

  renderWorkingAttitude() {
    return this.state.soft_skill_api[1].softSkillHeaders.map((val, index) => {
      return (
        <tbody style={{ width: "100%" }} key={"workingAttitude-" + index}>
          {val.softSkillDetails.map((v, i) => {
            return (
              <tr style={{ width: "100%" }} key={i}>
                {i === 0 ? (
                  <th
                    style={{ ...thStyle, width: "35%" }}
                    rowSpan={val.softSkillDetails.length}>
                    {getItem("lang") === "en"
                      ? val.softskillNameEng
                      : val.softskillNameIna}
                  </th>
                ) : null}
                <td
                  style={{
                    ...tdStyle,
                    width: "57%",
                    paddingLeft: "24px",
                  }}>
                  {v.softskillValueEng}
                </td>
                <td style={{ ...tdStyle, width: "8%" }}>
                  {data.vacancy_post.vacancySoftskills.find(
                    p => p.softskillHeaderId === val.id
                  ) ? (
                    data.vacancy_post.vacancySoftskills.find(
                      p => p.softskillDetailId === v.id
                    ) ? (
                      <img
                        src={IconConfirmationCheck}
                        alt={"check" + i}
                        height="24px"
                        align="center"
                      />
                    ) : null
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    });
  }

  renderRelationshipSkill() {
    return this.state.soft_skill_api[2].softSkillHeaders.map((val, index) => {
      return (
        <tbody style={{ width: "100%" }} key={"relationshipSkill-" + index}>
          {val.softSkillDetails.map((v, i) => {
            return (
              <tr style={{ width: "100%" }} key={i}>
                {i === 0 ? (
                  <th
                    style={{ ...thStyle, width: "35%" }}
                    rowSpan={val.softSkillDetails.length}>
                    {getItem("lang") === "en"
                      ? val.softskillNameEng
                      : val.softskillNameIna}
                  </th>
                ) : null}
                <td
                  style={{
                    ...tdStyle,
                    width: "57%",
                    paddingLeft: "24px",
                  }}>
                  {v.softskillValueEng}
                </td>
                <td style={{ ...tdStyle, width: "8%" }}>
                  {data.vacancy_post.vacancySoftskills.find(
                    p => p.softskillHeaderId === val.id
                  ) ? (
                    data.vacancy_post.vacancySoftskills.find(
                      p => p.softskillDetailId === v.id
                    ) ? (
                      <img
                        src={IconConfirmationCheck}
                        alt={"check" + i}
                        height="24px"
                        align="center"
                      />
                    ) : null
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    });
  }

  render() {
    const {
      loaded,
      step,
      softSkillHeaders,
      sum,
      vacancySoftskills,
      soft_skill_api,
      popupOpen,
      thinkingSkill,
      workingAttitude,
      relationshipSkill,
      menu,
    } = this.state;
    const { t } = this.props;
    const stepData = t("vacancy:create.step", { returnObjects: true });
    return (
      <div
        className="soft-skill font-hurme"
        style={{
          position: "relative",
        }}>
        <MyHelmet title="Dashboard" />
        <RecruiterAppBar shadow={true} history={this.props.history} />
        <Grid container style={{ minHeight: "100vh" }}>
          <Grid item lg={3} md={3} sm={3}>
            <div>
              {loaded && (
                <SideStep
                  data={soft_skill_api}
                  step={softSkillHeaders[step].id}
                  onChange={v => {
                    var newStep = 0;
                    for (let i = 0; i < softSkillHeaders.length; i++) {
                      if (softSkillHeaders[i].id === v) {
                        newStep = i;
                      }
                    }
                    this.setState({ step: newStep });
                  }}
                  defaultValue={vacancySoftskills}
                />
              )}
            </div>
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            <div>
              <StepHeader
                data={stepData}
                stepWidth={650}
                activeStep={2}
                isAZ={true}
                style={{
                  padding: "0px 100px",
                  marginTop: 80,
                  backgroundColor: palette.info,
                }}
              />
              <Grid container style={{ padding: "3% 4% 5%" }}>
                <Grid item lg={9} md={9} sm={9}>
                  <Grid container style={{ paddingRight: "4%" }}>
                    <Grid item lg={12} md={12} sm={12}>
                      <Typography
                        style={{
                          fontSize: "24px",
                          color: palette.primary,
                        }}>
                        {t("vacancy:create.softSkill.title")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      style={{ marginBottom: 58 }}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: palette.primary,
                        }}>
                        {loaded &&
                          (softSkillHeaders[step].answerType === "Single"
                            ? t("vacancy:create.softSkill.desc")
                            : t("vacancy:create.softSkill.descMulti"))}
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3}>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: palette.primary,
                        }}>
                        {loaded &&
                          (getItem("lang") === "en"
                            ? softSkillHeaders[step].softskillNameEng.concat(
                                " :"
                              )
                            : softSkillHeaders[step].softskillNameIna.concat(
                                " :"
                              ))}
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9}>
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: palette.primary,
                        }}>
                        {loaded &&
                          (getItem("lang") === "en"
                            ? softSkillHeaders[step].softskillDescEng
                            : softSkillHeaders[step].softskillDescIna)}
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9}>
                      {loaded &&
                        (softSkillHeaders[step].answerType === "Single" ? (
                          <OptionsImage
                            style={{ marginTop: 50 }}
                            options={softSkillHeaders[step].softSkillDetails}
                            onChange={v => {
                              var newVacancySoftskills = vacancySoftskills;
                              if (
                                typeof vacancySoftskills.find(
                                  p =>
                                    p.softskillHeaderId ===
                                    softSkillHeaders[step].id
                                ) === "undefined"
                              ) {
                                var newSum = sum + 1;
                                if (
                                  newVacancySoftskills[0].softskillHeaderId ===
                                  ""
                                ) {
                                  newVacancySoftskills[0].softskillHeaderId =
                                    softSkillHeaders[step].id;
                                  newVacancySoftskills[0].softskillDetailId = v;
                                } else {
                                  var ob = {
                                    softskillHeaderId:
                                      softSkillHeaders[step].id,
                                    softskillDetailId: v,
                                  };
                                  newVacancySoftskills.push(ob);
                                }

                                this.setState({ sum: newSum });
                              } else {
                                var idx = vacancySoftskills.findIndex(
                                  p =>
                                    p.softskillHeaderId ===
                                    softSkillHeaders[step].id
                                );
                                newVacancySoftskills[idx].softskillHeaderId =
                                  softSkillHeaders[step].id;
                                newVacancySoftskills[idx].softskillDetailId = v;
                              }

                              this.setState({
                                vacancySoftskills: newVacancySoftskills,
                              });
                              data.setVacancyPost({
                                vacancySoftskills: newVacancySoftskills,
                              });
                            }}
                            currentValue={
                              vacancySoftskills.find(
                                p =>
                                  p.softskillHeaderId ===
                                  softSkillHeaders[step].id
                              )
                                ? vacancySoftskills.find(
                                    p =>
                                      p.softskillHeaderId ===
                                      softSkillHeaders[step].id
                                  ).softskillDetailId
                                : ""
                            }
                          />
                        ) : (
                          <CheckboxImage
                            style={{ marginTop: 50 }}
                            options={softSkillHeaders[step].softSkillDetails}
                            defaultValue={
                              vacancySoftskills.find(
                                p =>
                                  p.softskillHeaderId ===
                                  softSkillHeaders[step].id
                              )
                                ? vacancySoftskills.filter(
                                    p =>
                                      p.softskillHeaderId ===
                                      softSkillHeaders[step].id
                                  )
                                : []
                            }
                            onChange={obj => {
                              var newSum = sum;
                              var newVacancySoftskills = vacancySoftskills;
                              if (
                                findObject(
                                  newVacancySoftskills,
                                  "softskillHeaderId",
                                  softSkillHeaders[step].id
                                )
                              ) {
                                if (obj.length === 0) {
                                  newSum = sum - 1;
                                }
                                newVacancySoftskills = removeObject(
                                  newVacancySoftskills,
                                  "softskillHeaderId",
                                  softSkillHeaders[step].id
                                );
                              } else {
                                newSum = sum + 1;
                              }

                              this.setState({ sum: newSum });
                              obj.forEach(v => {
                                if (
                                  newVacancySoftskills.length > 0 &&
                                  newVacancySoftskills[0].softskillHeaderId ===
                                    ""
                                ) {
                                  newVacancySoftskills[0].softskillHeaderId =
                                    v.softskillHeaderId;
                                  newVacancySoftskills[0].softskillDetailId =
                                    v.softskillDetailId;
                                } else {
                                  newVacancySoftskills.push(v);
                                }
                              });

                              if (newVacancySoftskills.length === 0) {
                                newVacancySoftskills.push({
                                  softskillHeaderId: "",
                                  softskillDetailId: "",
                                });
                              }

                              this.setState({
                                vacancySoftskills: newVacancySoftskills,
                              });
                              data.setVacancyPost({
                                vacancySoftskills: newVacancySoftskills,
                              });
                            }}
                          />
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={3} md={3} sm={3}>
                  <Button
                    style={{
                      border: "1px solid #EAF0F7",
                      boxShadow: "8px 4px 9px rgba(0, 0, 0, 0.1)",
                      borderRadius: "80px",
                      width: "100%",
                    }}
                    onClick={() => {
                      this.togglePopup(!popupOpen);
                    }}>
                    <Grid container style={{ padding: "6px 13px" }}>
                      <Grid item lg={3} md={3} sm={3}>
                        <img
                          style={{ marginRight: 10, marginTop: 5 }}
                          src={IconSummary}
                          alt="Sum"
                          height="20vh"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={7}
                        md={7}
                        sm={7}
                        style={{
                          fontSize: "16px",
                          color: palette.primary,
                          textTransform: "none",
                          paddingTop: "5px",
                          textAlign: "left",
                        }}>
                        {t("vacancy:create.softSkill.sum")}
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        sm={2}
                        style={{ paddingTop: "7px" }}>
                        <div
                          style={{
                            backgroundColor: palette.primary,
                            width: "20px",
                            height: "20px",
                            borderRadius: "10px",
                            fontSize: "12px",
                            color: "white",
                            paddingTop: "2px",
                            paddingLeft: "1px",
                          }}>
                          {sum}
                        </div>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
              <RecruiterFooter
                style={{ width: "75%" }}
                history={this.props.history}
                nextTo="/recruiter/confirmation"
                backTo="/recruiter/job-requirement"
                backLabel={t("vacancy:create.footer.back")}
                nextLabel={t("vacancy:create.footer.next")}
                stepperData={v => {
                  this.setState({ step: v });
                }}
                stepperValue={step}
                maxStep={softSkillHeaders.length}
                onFinish={() => this.saveData()}
              />
            </div>
          </Grid>
        </Grid>

        {/* Popup */}
        {popupOpen && (
          <SoftSkillPopup
            content={
              <>
                <Grid container style={{ width: "90%" }}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    style={{ marginTop: "4%" }}>
                    <Grid
                      container
                      style={{
                        borderBottom: "1px solid #DFE5F4",
                      }}>
                      {soft_skill_api.map((v, i) => {
                        return (
                          <Grid key={i} item lg={4} md={4} sm={4}>
                            <Link
                              style={
                                menu === v.id ? linkStyleActive : linkStyle
                              }
                              onClick={() => {
                                this.setState({ menu: v.id });
                              }}>
                              {getItem("lang") === "en"
                                ? v.categoryNameEng
                                : v.categoryNameIna}
                            </Link>
                          </Grid>
                        );
                      })}
                      {/* <Grid
                        item lg={4} md={4} sm={4}
                      >
                        <Link
                          style={menu === "ts" ? linkStyleActive : linkStyle}
                          onClick={() => {this.setState({menu: "ts"})}}
                        >
                          Thinking Skill
                        </Link>
                      </Grid>
                      <Grid
                        item lg={4} md={4} sm={4}
                      >
                        <Link
                          style={menu === "wa" ? linkStyleActive : linkStyle}
                          onClick={() => {this.setState({menu: "wa"})}}
                        >
                          Working Attitude
                        </Link>
                      </Grid>
                      <Grid
                        item lg={4} md={4} sm={4}
                      >
                        <Link
                          style={menu === "rs" ? linkStyleActive : linkStyle}
                          onClick={() => {this.setState({menu: "rs"})}}
                        >
                          Relationship Skill
                        </Link>
                      </Grid> */}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    style={{ marginTop: "16px" }}>
                    <table style={tableStyle}>
                      {menu === soft_skill_api[0].id
                        ? thinkingSkill
                        : menu === soft_skill_api[1].id
                        ? workingAttitude
                        : relationshipSkill}
                    </table>
                  </Grid>
                </Grid>
              </>
            }
            handleClose={() => {
              this.togglePopup(!popupOpen);
            }}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(SoftSkill);
