import { palette } from "assets/css/main";
import { IcnInfoFull, IconEdit } from "assets/img";
import React from "react";
import { withTranslation } from "react-i18next";
import ComponentDescription from "./com-description";
import ComponentTimeSlot from "./com-time-slot";
import ComponentCandidateSelect from "./com-candidate-select";
import { Button } from "@material-ui/core";
import dataSchedule from "../data-schedule";
export default withTranslation("interview-schedule")(
  class ScheduleConfirmation extends React.Component {
    state = {
      active: 0,
      edit: false,
      interview: {},
    };

    componentDidMount() {
      setTimeout(() => {
        this.changeActive(0);
      }, 500);
    }

    cancelChange() {
      const {
        interview,
        listInterviewDetail,
        listInterviewCandidate,
        active,
      } = this.state;
      this.setState(
        {
          edit: false,
          active: 3,
        },
        () => {
          this.setState({ active: active });
        }
      );
      dataSchedule.interview = interview;
      dataSchedule.listInterviewDetail = listInterviewDetail;
      dataSchedule.listInterviewCandidate = listInterviewCandidate;
    }

    submit() {
      try {
        document.getElementById("btn-submit").click();
      } catch (error) {
        console.log("saveChange()", error);
      }
    }
    saveChange() {
      this.setState({ edit: false });
    }

    changeActive(a = 0) {
      this.setState({
        active: a,
        edit: false,
        interview: JSON.parse(JSON.stringify(dataSchedule.interview)),
        listInterviewDetail: JSON.parse(
          JSON.stringify(dataSchedule.listInterviewDetail)
        ),
        listInterviewCandidate: JSON.parse(
          JSON.stringify(dataSchedule.listInterviewCandidate)
        ),
      });
      this.cancelChange();
    }

    render() {
      const { t } = this.props;
      const { active, edit } = this.state;
      const step = t("step", { returnObjects: true });
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            boxSizing: "border-box",
            minHeight: "50vw",
          }}>
          <div
            style={{
              width: "20%",
              minHeight: "100%",
              backgroundColor: palette.grey,
              display: "flex",
              justifyContent: "flex-end",
              padding: "20px 20px 0px 0px",
            }}>
            <div style={{ width: "80%" }}>
              <p style={{ width: "100%", margin: 0 }}>{t("summary.title")}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid " + palette.darkGrey,
                  padding: "10px 0px",
                  marginBottom: 10,
                }}>
                <img
                  src={IcnInfoFull}
                  alt="Info"
                  style={{ width: 18, height: 18, marginRight: 10 }}
                />
                <p style={{ width: "100%", margin: 0, fontWeight: "lighter" }}>
                  {t("summary.description")}
                </p>
              </div>
              <div
                onClick={() => {
                  this.setState({ active: 0, edit: false });
                }}
                style={{
                  padding: "10px 25px",
                  backgroundColor: active === 0 ? palette.info : "",
                  fontWeight: "lighter",
                  cursor: "pointer",
                }}>
                {step[0]}
              </div>
              <div
                onClick={() => {
                  this.setState({ active: 1, edit: false });
                }}
                style={{
                  padding: "10px 25px",
                  backgroundColor: active === 1 ? palette.info : "",
                  fontWeight: "lighter",
                  cursor: "pointer",
                }}>
                {step[1]}
              </div>
              <div
                onClick={() => {
                  this.setState({ active: 2, edit: false });
                }}
                style={{
                  padding: "10px 25px",
                  backgroundColor: active === 2 ? palette.info : "",
                  fontWeight: "lighter",
                  cursor: "pointer",
                }}>
                {step[2]}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "80%",
              minHeight: "100%",
              padding: "20px 60px",
              position: "relative",
              marginTop: 20,
              marginBottom: 100,
            }}>
            <Button
              onClick={() => {
                this.setState({
                  edit: true,
                });
              }}
              style={{
                position: "absolute",
                right: 100,
                top: 20,
                display: edit ? "none" : "",
                backgroundColor: "white",
                textTransform: "capitalize",
                border: "1px solid " + palette.darkGrey,
              }}>
              <img
                src={IconEdit}
                style={{ width: 18, marginRight: "15px" }}
                alt="Edit"
              />
              {t("general:button.edit")}
            </Button>
            {active === 0 ? (
              <ComponentDescription
                edit={edit}
                submitted={() => {
                  this.saveChange();
                }}
              />
            ) : (
              ""
            )}
            {active === 1 ? (
              <ComponentTimeSlot
                edit={edit}
                submitted={() => {
                  this.saveChange();
                }}
              />
            ) : (
              ""
            )}
            {active === 2 ? (
              <ComponentCandidateSelect
                edit={edit}
                submitted={() => {
                  this.saveChange();
                }}
              />
            ) : (
              ""
            )}
            <div
              style={{
                display: !edit ? "none" : "flex",
                justifyContent: "flex-end",
                width: 600,
                paddingTop: 20,
                borderTop: "1px solid " + palette.darkGrey,
              }}>
              <Button
                onClick={() => {
                  this.cancelChange();
                }}
                style={{
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  padding: "6px 30px",
                  color: palette.primary,
                  border: "1px solid " + palette.primary,
                  marginRight: 20,
                }}>
                {t("general:button.cancel")}
              </Button>
              <Button
                onClick={() => {
                  this.submit();
                }}
                style={{
                  backgroundColor: palette.primary,
                  textTransform: "capitalize",
                  padding: "6px 30px",
                  color: "white",
                  border: "1px solid " + palette.primary,
                }}>
                {t("general:button.save")}
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }
);
