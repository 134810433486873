import React from "react";
import {
  DialogueInfo,
  FormFooter,
  MyHelmet,
  RecruiterHeader,
} from "app/components";

import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { AppHistory, GetLocalItem, RequestPost, SetLocalItem } from "app/utils";
import ComponentDescription from "./com-description";
import ComponentTimeSlot from "./com-time-slot";
import ComponentCandidateSelect from "./com-candidate-select";
import ConfirmationSchedule from "./confirmation";

import dataSchedule from "../data-schedule";
import {
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { Check } from "@material-ui/icons";
import alertData from "app/components/alert/alert-data";

const useCustomIconStyles = makeStyles({
  root: {
    backgroundColor: "rgba(82, 200, 244, 0.2)",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    fontWeight: "bold",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: palette.secondary,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: palette.secondary,
  },
});

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: palette.secondary,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "rgba(82, 200, 244, 0.2)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#ccc",
    borderRadius: 1,
  },
})(StepConnector);

function CustomIcon(props) {
  const classes = useCustomIconStyles();
  const { active, completed } = props;

  const icons = {
    1: "a",
    2: "b",
    3: "c",
    4: "d",
    5: "e",
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {completed ? <Check /> : icons[String(props.icon)]}
    </div>
  );
}
export default withTranslation("interview-schedule")(
  class ScheduleCreate extends React.Component {
    state = {
      currentStep: 0,
      show: false,
      warningCandidate: false,
      component: [
        <ComponentDescription
          submitted={() => {
            this.submitComponent();
          }}
        />,
        <ComponentTimeSlot
          submitted={() => {
            this.submitComponent();
          }}
        />,
        <ComponentCandidateSelect
          submitted={() => {
            this.submitComponent();
          }}
        />,
        <ConfirmationSchedule />,
      ],
    };

    componentDidMount() {
      const {
        location: { state: data },
      } = AppHistory;
      if (!data) {
        AppHistory.goBack();
      } else {
        var d = GetLocalItem("SCH_D" + data.id_vacancy);
        var t = GetLocalItem("SCH_T" + data.id_vacancy);
        var l = GetLocalItem("SCH_L" + data.id_vacancy);

        if (d) {
          dataSchedule.setInterview(d);
        }

        if (t) {
          dataSchedule.listInterviewDetail = t;
        }

        if (l) {
          dataSchedule.listInterviewCandidate = l;
        }

        setTimeout(() => {
          const s = l ? 3 : t ? 2 : d ? 1 : 0;
          this.setState({ currentStep: s, show: true });
        }, 500);
      }
    }

    handleBackClick() {
      const { currentStep } = this.state;
      if (currentStep === 0) {
        AppHistory.goBack();
      } else {
        this.setState({ currentStep: currentStep - 1 });
      }
    }

    handleNextClick() {
      const { t } = this.props;
      const { currentStep } = this.state;

      const step = t("step", { returnObjects: true });

      if (currentStep < step.length - 1) {
        document.getElementById("btn-submit").click();
      } else {
        //submit here
        this.postData();
      }
    }

    postData() {
      const {
        location: { state: data },
      } = AppHistory;
      const { t } = this.props;
      const d = {
        interview: dataSchedule.interview,
        listInterviewDetail: dataSchedule.listInterviewDetail,
        listInterviewCandidate: dataSchedule.listInterviewCandidate,
      };
      if (
        dataSchedule.listInterviewDetail.length <
        dataSchedule.listInterviewCandidate.length
      ) {
        this.setState({ warningCandidate: true });
        return false;
      }
      RequestPost("interview", d)
        .then(r => {
          alertData.show(t("messages.saveSuccess"), "success");
          AppHistory.push("/recruiter/schedule/" + data.id_vacancy);
          SetLocalItem("SCH_D" + data.id_vacancy, null);
          SetLocalItem("SCH_T" + data.id_vacancy, null);
          SetLocalItem("SCH_L" + data.id_vacancy, null);
          dataSchedule.reset();
        })
        .catch(e => {
          alertData.show(t("messages.saveError"), "success");
          console.log("postData()", e);
        });
    }

    submitComponent() {
      const { currentStep } = this.state;
      this.setState({ currentStep: currentStep + 1 });
    }

    render() {
      const { t } = this.props;
      const step = t("step", { returnObjects: true });
      const { currentStep, component, show, warningCandidate } = this.state;
      return (
        <div style={{ height: "100%", boxSizing: "border-box" }}>
          <MyHelmet title="Create Interview Schedule" />
          <RecruiterHeader shadow={true} menu="dashboard" />
          <DialogueInfo
            title={t("messages.maxCandidateTitle")}
            content={t("messages.maxCandidateContent")}
            open={warningCandidate}
            onClose={() => {
              this.setState({ warningCandidate: false });
            }}
          />
          <FormFooter
            prevText={t("general:button.back")}
            nextText={
              currentStep < step.length - 1
                ? t("general:button.next")
                : t("general:button.save")
            }
            backArrow={false}
            OnClickBack={() => {
              this.handleBackClick();
            }}
            OnClickNext={() => {
              this.handleNextClick();
            }}
          />
          <div
            style={{
              marginTop: 80,
              width: "100%",
              backgroundColor: palette.info,
              display: "flex",
              justifyContent: "flex-end",
              position: "fixed",
              zIndex: 20,
            }}>
            <Stepper
              connector={<CustomConnector />}
              activeStep={currentStep}
              style={{
                width: "40vw",
                backgroundColor: palette.info,
                height: 0,
                padding: "30px 20px",
              }}>
              {step.map((label, idx) => {
                return (
                  <Step
                    key={label}
                    completed={currentStep > idx ? true : false}>
                    <StepLabel
                      className="NavBar"
                      StepIconComponent={CustomIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
          <div
            style={{
              padding: currentStep === 3 ? "140px 0px 0px 0px" : "140px 100px",
              color: palette.primary,
              height: "100%",
              boxSizing: "border-box",
            }}>
            <div
              style={{
                padding: currentStep === 3 ? "0px" : "40px 0px 0px",
                width: "100%",
                display: "flex",
                height: "100%",
                boxSizing: "border-box",
                justifyContent: currentStep === 3 ? "flex-start" : "center",
              }}>
              {show ? component[currentStep] : ""}
            </div>
          </div>
        </div>
      );
    }
  }
);
