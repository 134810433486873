import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Typography, Grid, Button, TextField } from "@material-ui/core";
import "./custom.css";
import Select from "react-select";
import moment from "moment";
import { DialogueFull, DialogueInfo } from "app/components";
import alertData from "app/components/alert/alert-data";
import {
  findObject,
  getItem,
  GetLocalItem,
  RequestPost,
  RequestPut,
} from "app/utils";

class InterviewInvitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      listSchedule: [],
      schedule: null,
      dialogue: false,
      title: "",
      content: "",
      loaded: false,
      showNotes: false,
      error: "",
      notes: "",
    };
  }

  renderPopup() {
    const { t } = this.props;
    const { showNotes } = this.state;
    return (
      <div style={{ backgroundColor: "white", padding: 20 }}>
        <div>
          <Grid container>
            <Grid item lg={12} md={12} sm={12}>
              <Typography
                style={{
                  fontSize: "18",
                  color: palette.primary,
                }}>
                {t("job-opening:interviewInvitation.popup.dropdown")}
              </Typography>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              style={{
                marginTop: "8px",
              }}>
              <Typography
                style={{
                  width: "90%",
                  fontWeight: "lighter",
                  fontSize: "14",
                  color: palette.primary,
                }}>
                {t("job-opening:interviewInvitation.popup.notes")}
              </Typography>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              style={{
                marginTop: "16px",
              }}>
              <hr
                style={{
                  border: "1px solid #DFE5F4",
                }}
              />
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              style={{
                marginTop: "16px",
                overflowY: "visible",
                minHeight: 170,
              }}>
              <Select
                classNamePrefix={"interview-schedule"}
                placeholder={t(
                  "job-opening:interviewInvitation.popup.placeholder"
                )}
                options={this.state.listSchedule}
                onChange={obj => {
                  this.setState({
                    schedule: obj.value,
                    showNotes: obj.value === "res" ? true : false,
                  });
                  this.enableBtn();
                }}
                isOptionDisabled={option => !option.param}
              />
              {showNotes && (
                <div style={{ paddingTop: 30 }}>
                  <label
                    style={{ fontWeight: "lighter", color: palette.primary }}>
                    {t("job-opening:labelNotes")}:
                  </label>
                  <TextField
                    rows={5}
                    placeholder={t("job-opening:notes")}
                    multiline
                    className="NotesInvitation Field"
                    onChange={e => {
                      this.setState({ notes: e.target.value });
                    }}
                  />
                  <span style={{ fontSize: 12, color: palette.danger }}>
                    {this.state.error}
                  </span>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: 20 }}>
          <Grid container>
            <Grid item lg={9} md={9} sm={9}>
              <Button
                style={{
                  backgroundColor: "white",
                  border: "1px solid #2E69B2",
                  boxSizing: "border-box",
                  borderRadius: "4px",
                  fontSize: "14px",
                  color: palette.primary,
                  textTransform: "none",
                  width: "128px",
                  float: "right",
                }}
                onClick={() => {
                  this.setState({ schedule: null });
                  this.togglePopup(false);
                }}>
                {t("job-opening:interviewInvitation.popup.btnCancel")}
              </Button>
            </Grid>
            <Grid item lg={3} md={3} sm={3}>
              <Button
                id={"BtnOk"}
                style={
                  this.state.schedule === null
                    ? {
                        backgroundColor: "#E5E5E5",
                        borderRadius: "4px",
                        fontSize: "14px",
                        color: "white",
                        textTransform: "none",
                        width: "128px",
                        float: "right",
                      }
                    : {
                        backgroundColor: palette.primary,
                        borderRadius: "4px",
                        fontSize: "14px",
                        color: "white",
                        textTransform: "none",
                        width: "128px",
                        float: "right",
                      }
                }
                onClick={
                  this.state.schedule !== null
                    ? () => {
                        this.putInterviewSchedule(GetLocalItem("InterviewID"));
                      }
                    : null
                }>
                OK
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  enableBtn() {
    var btn = document.getElementById("BtnOk");
    if (btn !== null && this.state.schedule === null) {
      btn.disabled = false;
      btn.classList.remove("Mui-disabled");
    }
  }

  putInterviewSchedule(id) {
    const { t } = this.props;
    if (this.state.schedule === "res") {
      if (this.state.notes) {
        RequestPost("interview/reschedule", {
          interviewId: id,
          notes: this.state.notes,
          candidateId: getItem("user_id"),
        })
          .then(() => {
            this.setState({
              popupOpen: false,
              dialogue: true,
              title: t("interview-schedule:dialogue.successReschedule.title"),
              content: t(
                "interview-schedule:dialogue.successReschedule.content"
              ),
            });
          })
          .catch(err => {
            console.log(err);
            alertData.show(String(err.status), "error");
          });
      } else {
        this.setState({
          error:
            getItem("lang") === "en"
              ? "Notes is requred"
              : "Catatan diperlukan",
        });
      }
    } else {
      RequestPut("Interview/Invitation/" + id, {
        responseAnswer: this.state.schedule,
      })
        .then(() => {
          this.setState({
            popupOpen: false,
            dialogue: true,
            title: t("interview-schedule:dialogue.successSelect.title"),
            content: t("interview-schedule:dialogue.successSelect.content"),
          });
        })
        .catch(err => {
          console.log(err);
          alertData.show(String(err.status), "error");
        });
    }
  }

  togglePopup(newVal) {
    this.setState({ popupOpen: newVal });

    // disabled button next
    setTimeout(() => {
      var btn = document.getElementById("BtnOk");
      if (btn !== null && this.state.schedule === null) {
        btn.disabled = true;
        btn.classList.add("Mui-disabled");
      }
    }, 100);
  }

  dateFormat(obj) {
    var result =
      moment(obj.timeStart).format("dddd, DD MMMM yyyy, HH.mm") +
      " - " +
      moment(obj.timeEnd).format("HH.mm") +
      " " +
      obj.timezone.toUpperCase();
    return result;
  }

  componentDidMount() {
    // interview schedule
    const { t } = this.props;
    var list = this.props.data.jobInterview
      ? this.state.schedule === null &&
        this.props.data.jobInterview.responseAnswer === null
        ? this.props.data.jobInterview.listSchedules.filter(k => !k.isBooked)
        : this.props.data.jobInterview.listSchedules
      : [];
    if (!findObject(list, "interviewDetailId", "res")) {
      list.unshift({
        interviewDetailId: "res",
        isBooked: false,
        timeEnd: null,
        timeStart: null,
        timezone: null,
      });
    }

    this.setState(
      {
        listSchedule: list.map(obj => {
          if (obj.interviewDetailId === "res") {
            return {
              value: obj.interviewDetailId,
              label: t("job-opening:reschedule"),
              param: true,
            };
          } else {
            var timeStart = obj.timeStart;
            var StartInterviewDate = moment(timeStart)
              .add(7, "hours")
              .toISOString();
            var datenow = new Date();
            var DN = moment(datenow).add(7, "hours").toISOString();
            return {
              value: obj.interviewDetailId,
              label: this.dateFormat(obj),
              param: StartInterviewDate > DN ? true : false,
            };
          }
        }),
      },
      () => {
        this.setState({ loaded: true });
      }
    );
  }

  render() {
    const { t, data } = this.props;
    const {
      popupOpen,
      dialogue,
      listSchedule,
      schedule,
      loaded,
      title,
      content,
    } = this.state;
    const { jobInterview } = data;
    return (
      <div>
        {loaded && (
          <Grid container style={{ padding: "300px 6% 8%" }}>
            <Grid item lg={9} md={9} sm={9}>
              <Typography
                style={{
                  fontSize: "24px",
                  color: palette.primary,
                }}>
                {
                  t("job-opening:jobOpeningDetail.sideStep.menu", {
                    returnObjects: true,
                  })[3]
                }
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("job-opening:interviewInvitation.title")}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
              <Typography
                style={{
                  fontSize: "18px",
                  color: palette.primary,
                }}>
                {jobInterview.interviewTitle}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("job-opening:interviewInvitation.time")}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
              {jobInterview.reqReschedule ? (
                <div style={{ paddingTop: 11 }}>
                  <span
                    style={{
                      padding: "10px 30px",
                      backgroundColor: palette.primarydark,
                      color: "white",
                      borderRadius: 4,
                    }}>
                    {t("interview-schedule:invite.status.waitingConfirm")}
                  </span>
                </div>
              ) : (
                <>
                  {schedule === null && jobInterview.responseAnswer === null ? (
                    <Button
                      onClick={() => this.togglePopup(true)}
                      style={{
                        padding: "6px 16px 4px",
                        fontSize: "14px",
                        color: "white",
                        backgroundColor: palette.primary,
                        boxSizing: "border-box",
                        borderRadius: "4px",
                        textTransform: "none",
                      }}>
                      {t("job-opening:interviewInvitation.btnPopup")}
                    </Button>
                  ) : (
                    <Typography
                      style={{
                        fontSize: "18px",
                        color: palette.primary,
                      }}>
                      {schedule
                        ? findObject(listSchedule, "value", schedule).label
                        : findObject(
                            listSchedule,
                            "value",
                            jobInterview.responseAnswer
                          ).label}
                    </Typography>
                  )}
                </>
              )}
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("job-opening:interviewInvitation.location")}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
              <Typography
                style={{
                  fontSize: "18px",
                  color: palette.primary,
                }}>
                {jobInterview.location}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("job-opening:interviewInvitation.contact")}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
              <Typography
                style={{
                  fontSize: "18px",
                  color: palette.primary,
                }}>
                {jobInterview.contactName +
                  " (" +
                  jobInterview.contactNumber +
                  ")"}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {t("job-opening:interviewInvitation.notes")}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
              <Typography
                style={{
                  fontSize: "18px",
                  color: palette.primary,
                }}>
                {jobInterview.notes}
              </Typography>
            </Grid>

            {/* Popup */}
            {/* {popupOpen && this.renderPopup()} */}
            <DialogueFull
              style={{ width: 600 }}
              hideClose={true}
              children={this.renderPopup()}
              open={popupOpen}
              onClose={() => {
                this.setState({ popupOpen: false });
              }}
            />
            <DialogueInfo
              title={title}
              content={content}
              open={dialogue}
              onClose={() => {
                this.setState({ dialogue: false });
              }}
            />
          </Grid>
        )}
      </div>
    );
  }
}

export default withTranslation()(InterviewInvitation);
