import { observable, decorate, action } from "mobx";
class DataSchedule {
  interview = {
    vacancyId: "",
    interviewTitle: "",
    location: "",
    contactName: "",
    contactNumber: "",
    notes: "",
  };
  listInterviewDetail = [];
  listInterviewCandidate = [];
  setInterview(obj) {
    this.interview = { ...this.interview, ...obj };
  }

  setListInterview(obj) {
    this.listInterviewDetail.push(obj);
  }

  SetListInterviewCandidate(obj) {
    this.listInterviewCandidate.push(obj);
  }
  reset() {
    this.interview = {
      vacancyId: "",
      interviewTitle: "",
      location: "",
      contactName: "",
      contactNumber: "",
      notes: "",
    };
    this.listInterviewDetail = [];
    this.listInterviewCandidate = [];
  }
}
const ds = decorate(DataSchedule, {
  interview: observable,
  listInterviewDetail: observable,
  listInterviewCandidate: observable,
  setInterview: action,
  setListInterview: action,
  SetListInterviewCandidate: action,
});
export default new ds();
