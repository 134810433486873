import React from "react";
import { palette } from "assets/css/main";
import { div } from "@material-ui/core";
import KeyHandler, { KEYPRESS } from "react-key-handler";

export default class AnswerImages extends React.Component {
  state = { active: "" };
  char = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  setActive(act) {
    this.setState({ active: act });
  }
  render() {
    const { style, options, label, value, isImage, sample } = this.props;
    var opt = [];
    if (options) {
      opt = options;
    }
    return (
      <div
        className="option"
        style={{
          ...style,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}>
        <div
          style={{
            textTransform: "uppercase",
            color: palette.primary,
          }}>
          {label}
        </div>
        {opt.map((v, i) => {
          return (
            <div
              title={"Press " + this.char.split("")[i]}
              className={label}
              onClick={() => {
                this.setActive(v.answerId);
                this.props.onChange(v.answerId);
              }}
              key={i}
              style={{
                marginBottom: "auto",
                display: "flex",
                color: palette.primary,
                // fontWeight: "bold",
                alignItems: "center",
                flexDirection: "row",
                cursor: "pointer",
              }}>
              <div
                className={
                  sample
                    ? this.state.active === v.answerId
                      ? "pulse"
                      : value === v.answerId
                      ? "pulse"
                      : ""
                    : "not-sample"
                }
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 12,
                  paddingBottom: 8,
                  fontSize: 14,
                  textAlign: "center",
                  width: 26,
                  height: 23,
                  color:
                    this.state.active === v.answerId
                      ? "white"
                      : value === v.answerId
                      ? "white"
                      : palette.primary,
                  backgroundColor:
                    this.state.active === v.answerId
                      ? palette.primary
                      : value === v.answerId
                      ? palette.primary
                      : "white",
                  borderRadius: 5,
                  border: "1px solid " + palette.primary,
                  marginRight: 10,
                }}>
                <KeyHandler
                  key={i}
                  keyEventName={KEYPRESS}
                  code={"Key" + this.char.split("")[i]}
                  onKeyHandle={e => {
                    this.setActive(options[i].answerId);
                    this.props.onChange(options[i].answerId);
                  }}
                />
                {this.char.split("")[i]}
              </div>
              <div style={{ maxWidth: 400 }}>
                {isImage && (
                  <img
                    src={v.answer}
                    key={v.answerId}
                    width={60}
                    height={60}
                    alt={v.answerId}
                  />
                )}
                {!isImage && <p>{v.answer}</p>}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
