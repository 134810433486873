import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Typography, Grid } from "@material-ui/core";
import { IconVacancyBullet } from "assets/img";
import { getItem } from "app/utils";

class JobRequirement extends React.Component {
  render() {
    const { t, data } = this.props;
    return (
      <Grid container style={{ padding: "300px 6% 8%" }}>
        <Grid item lg={9} md={9} sm={9}>
          <Typography
            style={{
              fontSize: "24px",
              color: palette.primary,
            }}>
            {
              t("job-opening:jobOpeningDetail.sideStep.menu", {
                returnObjects: true,
              })[1]
            }
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Language Skill
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.jobRequirement.languageSkill}
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Specific Certification
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {!(
            data.jobRequirement.specificCertification.length === 1 &&
            data.jobRequirement.specificCertification[0] === ""
          ) ? (
            data.jobRequirement.specificCertification.map((v, i) => {
              return (
                <div style={{ width: "51%" }} key={i}>
                  <Grid container>
                    <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                      <img
                        style={{ paddingTop: 5, paddingLeft: 2 }}
                        src={IconVacancyBullet}
                        alt={i}
                        height="10vh"
                        align="center"
                      />
                    </Grid>
                    <Grid item lg={11} md={11} sm={11} style={{ marginTop: 4 }}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          color: palette.primary,
                        }}>
                        {v}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            })
          ) : (
            <Typography
              style={{
                fontSize: "18px",
                color: palette.primary,
              }}>
              {t("job-opening:jobOpeningDetail.jobReq.nr")}
            </Typography>
          )}
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            To Perform The Job, Candidate Will Be Required To :
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {data.jobRequirement.jobPerform.length > 0
            ? data.jobRequirement.jobPerform.map((v, i) => {
                return (
                  <div style={{ width: "51%" }} key={i}>
                    <Grid container>
                      <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                        <img
                          style={{ paddingTop: 5, paddingLeft: 2 }}
                          src={IconVacancyBullet}
                          alt={i}
                          height="10vh"
                          align="center"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={11}
                        md={11}
                        sm={11}
                        style={{ marginTop: 4 }}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            color: palette.primary,
                          }}>
                          {getItem("lang") === "en" ? v.textEng : v.textIna}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              })
            : null}
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "3%" }}>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            Work Environtment
          </Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} style={{ marginTop: "4px" }}>
          {data.jobRequirement.workEnvironment.length > 0
            ? data.jobRequirement.workEnvironment.map((v, i) => {
                return (
                  <div style={{ width: "51%" }} key={i}>
                    <Grid container>
                      <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                        <img
                          style={{ paddingTop: 5, paddingLeft: 2 }}
                          src={IconVacancyBullet}
                          alt={i}
                          height="10vh"
                          align="center"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={11}
                        md={11}
                        sm={11}
                        style={{ marginTop: 4 }}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            color: palette.primary,
                          }}>
                          {getItem("lang") === "en" ? v.textEng : v.textIna}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              })
            : null}
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(JobRequirement);
