import React from "react";
import { MyHelmet, RecruiterHeader } from "app/components";

import { withTranslation } from "react-i18next";
import { palette, REC_STYLE } from "assets/css/main";
import ButtonPrev from "app/components/general/ButtonPrev";
import { AppHistory, RequestGet, SetLocalItem } from "app/utils";
import { Button, Menu, MenuItem, Paper } from "@material-ui/core";
import { BadgeCountInfo } from "app/components/header/BadgeCountInfo";
import { IcnCalendar } from "assets/img";
import { MoreHoriz } from "@material-ui/icons";
import dataSchedule from "./data-schedule";
import InvitationStatus from "./invitation-status";
export default withTranslation("interview-schedule")(
  class ScheduleList extends React.Component {
    state = {
      data: { scheduleArchive: [], scheduleActive: [] },
      activeTab: 0,
      open: false,
      openStatus: false,
      anchorMenu: null,
      selectedData: false,
      loading: true,
    };

    componentDidMount() {
      this.getVacancy();
      const {
        match: { params },
      } = this.props;

      SetLocalItem("SCH_D" + params.id_vacancy, null);
      SetLocalItem("SCH_T" + params.id_vacancy, null);
      SetLocalItem("SCH_L" + params.id_vacancy, null);
      dataSchedule.reset();
      window.scrollTo(0, 0);
    }

    handleChange(v) {
      this.setState({ activeTab: v });
    }

    getVacancy() {
      const {
        match: { params },
      } = this.props;
      this.setState({ loading: true });
      RequestGet("interview", {
        loading: true,
        vacancyId: params.id_vacancy,
      })
        .then(r => {
          this.setState({ data: r.data, loading: false });
        })
        .catch(c => {
          this.setState({ loading: false });
          console.log("getVacancy()", c);
        });
    }

    render() {
      const {
        t,
        match: { params },
      } = this.props;
      const {
        data,
        activeTab,
        anchorMenu,
        data: { scheduleActive },
        scheduleId,
        selectedData,
        openStatus,
      } = this.state;
      return (
        <div>
          <MyHelmet title="Interview Schedule" />
          <RecruiterHeader shadow={true} menu="dashboard" />
          <InvitationStatus
            open={openStatus}
            onClose={() => this.setState({ openStatus: false })}
            data={selectedData}
          />
          <Menu
            className="more-action"
            id="simple-menu-schedule"
            anchorEl={anchorMenu}
            keepMounted
            open={Boolean(anchorMenu)}
            style={{
              fontWeight: "lighter",
              marginTop: 40,
              marginRight: 40,
              color: palette.primary,
            }}
            onClose={() => {
              this.setState({
                anchorMenu: null,
              });
            }}>
            <MenuItem
              onClick={() => {
                this.setState({
                  anchorMenu: null,
                  openStatus: true,
                });
              }}
              style={{
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("button.viewStatus")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                AppHistory.push("/recruiter/schedule-edit", {
                  id_vacancy: params.id_vacancy,
                  scheduleId: scheduleId,
                });
                this.setState({
                  anchorMenu: null,
                });
              }}
              style={{
                fontWeight: "lighter",
                color: palette.primary,
              }}>
              {t("button.edit")}
            </MenuItem>
          </Menu>
          <div style={REC_STYLE.BODY_STYLE}>
            <div
              style={{
                ...REC_STYLE.SUB_HEADER,
                padding: "0px 100px",
                paddingTop: 10,
              }}>
              <div style={{ width: "100%", color: palette.primary }}>
                <ButtonPrev
                  style={{ padding: "0px 0px" }}
                  fontSize={14}
                  iconSize={{ fontSize: 14 }}
                  text={t("button.backCandidate")}
                  color={palette.primary}
                  onClick={() => {
                    AppHistory.push(
                      "/recruiter/viewcandidatelist/" + params.id_vacancy,
                      {
                        status: "INP",
                      }
                    );
                  }}
                />
                <p
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                    margin: "5px 0px",
                  }}>
                  {t("title")}
                </p>
                <p
                  style={{
                    fontSize: 18,
                    margin: "5px 0px",
                    padding: "5px 0px 20px",
                    borderBottom: "1px solid " + palette.primary,
                  }}>
                  {data.vacancyName}
                </p>
                <div style={{ marginTop: 20, position: "relative" }}>
                  <Button
                    onClick={() => {
                      this.handleChange(0);
                    }}
                    style={{
                      borderRadius: 0,
                      padding: 10,
                      color: palette.primary,
                      opacity: activeTab === 0 ? 1 : 0.5,
                      fontWeight: "bold",
                      borderBottom:
                        activeTab === 0
                          ? "2px solid " + palette.primary
                          : "2px solid transparent",
                    }}>
                    {t("button.active")}
                    <BadgeCountInfo
                      circle={true}
                      count={data.scheduleActive.length}
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      AppHistory.push("/recruiter/schedule-create", {
                        id_vacancy: params.id_vacancy,
                      });
                    }}
                    style={{
                      textTransform: "capitalize",
                      padding: "10px 25px",
                      color: "white",
                      position: "absolute",
                      right: "0",
                      bottom: 5,
                      backgroundColor: palette.primary,
                    }}>
                    {t("button.create")}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ padding: "220px 100px 50px" }}>
              {scheduleActive.length === 0 && (
                <div>
                  <Paper
                    style={{
                      display: !this.state.loading ? "" : "none",
                      boxShadow: "none",
                      border: "1px solid #E5E5E5",
                      padding: "50px 20px",
                      color: palette.primary,
                    }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        flexDirection: "column",
                      }}>
                      <div
                        style={{
                          width: 500,
                          textAlign: "center",
                          marginBottom: 20,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: t("messages.emptyListData"),
                        }}></div>
                      <Button
                        onClick={() => {
                          AppHistory.push("/recruiter/schedule-create", {
                            id_vacancy: params.id_vacancy,
                          });
                        }}
                        style={{
                          textTransform: "capitalize",
                          padding: "10px 25px",
                          color: "white",
                          backgroundColor: palette.primary,
                        }}>
                        {t("button.create")}
                      </Button>
                    </div>
                  </Paper>
                </div>
              )}
              {scheduleActive.map((v, k) => {
                return (
                  <div
                    key={"list-" + k}
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: 4,
                      padding: "20px 40px",
                      marginBottom: 20,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color: palette.primary,
                      }}>
                      <img
                        src={IcnCalendar}
                        alt="calendar"
                        style={{ marginRight: 30, width: 39.5, height: 39 }}
                      />
                      <div>
                        <p
                          style={{
                            textTransform: "capitalize",
                            fontSize: 16,
                            margin: "10px 0px",
                          }}>
                          {v.scheduleName}
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            color: palette.secondary,
                            margin: 0,
                          }}>
                          {v.pendingCandidates.length +
                            " " +
                            t("list.pendingCount") +
                            ", " +
                            v.bookedCandidates.length +
                            " " +
                            t("list.bookedCount")}
                        </p>
                      </div>
                    </div>
                    <Button
                      onClick={e => {
                        this.setState({
                          anchorMenu: e.target,
                          scheduleId: v.scheduleId,
                          selectedData: v,
                        });
                      }}
                      style={{
                        padding: 0,
                        borderRadius: 4,
                        border: "1px solid " + palette.primary,
                      }}>
                      <MoreHoriz style={{ color: "grey" }} />
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }
);
