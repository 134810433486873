import React from "react";
import { withTranslation } from "react-i18next";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { palette } from "assets/css/main";
import data from "app/pages/recruiter/data";
import { Grid, MenuItem } from "@material-ui/core";
import DatePickerValidator from "app/components/general/DatePickerValidator";
import { HtmlTooltip, Uploader } from "app/components";
import { RequestPost, getItem, General, findObject } from "app/utils";
import SelectSearch from "react-select";
import alertData from "app/components/alert/alert-data";
import InfoIcon from "@material-ui/icons/Info";

class ConfirmationProfileCorporate extends React.Component {
  state = {
    content: <div></div>,
  };
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.translate = this.props.t;
    this.state = {
      companyProfile: props.data,
      listBusinessType: props.listbisnistype,
      BussinessType: [],
      selected: [],
    };
  }

  componentDidMount() {
    var d = JSON.parse(JSON.stringify(data.companyBusinessType));
    var sl = d.map(v => {
      return findObject(this.state.listBusinessType, "value", v);
    });
    this.setState({
      businessType: d,
      selected: sl,
    });
    var regex = /^((https?|http):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    ValidatorForm.addValidationRule("isUrl", value => {
      if (value === "") {
        return true;
      }
      if (regex.test(value)) {
        return true;
      }
      return false;
    });
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule("isUrl");
    ValidatorForm.removeValidationRule("selectRequired");
  }

  handleChange(obj) {
    this.setState(obj);
    data.setCompanyProfile(obj);

    var companyProfile = {
      companyProfile: { ...this.state.companyProfile, ...obj },
    };
    this.setState({
      ...this.state,
      ...companyProfile,
    });
  }

  handleClick(e) {
    document.getElementById("button-submit").click();
  }

  handleUploadImage(file, part) {
    var form = new FormData();
    form.append(
      "file",
      file.file,
      getItem("user_id") + "-" + part + "." + file.extension
    );
    RequestPost("CompanyProfile/upload", form)
      .then(r => {
        const { imageUrl } = r.data;
        try {
          document.getElementById("preview-img").blur();
          document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
        } catch (error) {
          console.log(error);
        }
        if (part === "companyLogo") {
          this.handleChange({ companyLogo: imageUrl });
        } else if (part === "picIdentityCard") {
          this.handleChange({ picIdentityCard: imageUrl });
        } else {
          General.visible = true;
          this.handleChange({ siup: imageUrl });
        }
      })
      .catch(e => {
        console.log("handleUploadImage()", e);
      });
  }

  submitForm(e) {
    e.preventDefault();
    const { t } = this.props;
    if (this.state.businessType === " ") {
      alertData.show(t("validation.required.businessType"), "error");
      return false;
    }
    if (this.state.companyLogo === undefined) {
      alertData.show(t("validation.required.companyLogo"), "error");
      return false;
    }
    data.companyProfile.companyCode = this.state.companyProfile.companyCode;
    data.companyProfile.companyNameBrand = this.state.companyProfile.companyNameBrand;
    data.companyProfile.companyNameLegal = this.state.companyProfile.companyNameLegal;
    data.companyProfile.companyAddress = this.state.companyProfile.companyAddress;
    data.companyProfile.website = this.state.companyProfile.website;
    data.companyProfile.established = this.state.companyProfile.established;
    data.companyProfile.companyType = this.state.companyProfile.companyType;
    data.companyProfile.businessType = this.state.businessType.value;
    data.companyProfile.specifyOthers = this.state.companyProfile.specifyOthers;
    data.companyProfile.aboutUs = this.state.companyProfile.aboutUs;
    data.companyProfile.companyLogo = this.state.companyProfile.companyLogo;
    data.companyProfile.picIdentityCard = this.state.companyProfile.picIdentityCard;
    data.companyProfile.siup = this.state.companyProfile.siup;
  }

  onChangeBussinessType(v) {
    var { businessType, selected } = this.state;
    selected = v;
    businessType = selected.map(d => {
      return d.value;
    });

    data.companyBusinessType = businessType;
    this.setState({
      ...this.state,
      selected: selected,
      businessType: businessType,
    });
  }

  render() {
    const { t } = this.props;
    const { listBusinessType } = this.state;
    var year = new Date(this.state.companyProfile.established + "-01-01");
    return (
      <div className="">
        <div
          style={{
            height: 58,
            padding: "4% 6%",
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "#2E69B2",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            color: "white",
            fontSize: 24,
          }}>
          <label style={{ fontSize: 24 }}>
            {t("recruiterSetup", { returnObjects: true })[1]}
          </label>
          <label style={{ fontSize: 14 }}>
            {t("profilesetup:companyInfo")}
          </label>
          <label style={{ fontSize: 14 }}>*{t("profilesetup:require")}</label>
        </div>
        <div
          style={{
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            fontSize: 24,
          }}>
          <div
            justify="flex-start"
            style={{
              color: palette.primary,
              marginTop: "3%",
              marginLeft: "6%",
              marginBottom: "3%",
            }}>
            <ValidatorForm onSubmit={this.submitForm}>
              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.companyName")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <TextValidator
                  onChange={e => {
                    this.handleChange({
                      companyNameBrand: e.currentTarget.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t("profilesetup:form.placeholder.companyName")}
                  name="companyName"
                  value={this.state.companyProfile.companyNameBrand}
                  style={{ width: "93%" }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.brand")]}
                />
              </div>

              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.companyNameLegal")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <TextValidator
                  onChange={e => {
                    this.handleChange({
                      companyNameLegal: e.currentTarget.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t(
                    "profilesetup:form.placeholder.companyNameLegal"
                  )}
                  name="companyNameLegal"
                  value={this.state.companyProfile.companyNameLegal}
                  style={{ width: "93%" }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.companyNameLegal")]}
                />
              </div>

              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontSize: 14,
                    fontWeight: "lighter",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.companyAddress")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <TextValidator
                  onChange={e => {
                    this.handleChange({
                      companyAddress: e.currentTarget.value,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t(
                    "profilesetup:form.placeholder.companyAddress"
                  )}
                  multiline
                  rows={4}
                  name="companyAddress"
                  value={this.state.companyProfile.companyAddress}
                  style={{
                    width: "93%",
                    color: palette.primary,
                  }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.companyAddress")]}
                />
              </div>

              <div
                style={{
                  marginTop: "3%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                    fontSize: 14,
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.companyType")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <SelectValidator
                  className="select-with-placeholder Field Single"
                  defaultValue=" "
                  InputLabelProps={{
                    shrink: true,
                  }}
                  validators={["selectRequired"]}
                  errorMessages={[t("validation.required.companyType")]}
                  displayempty="true"
                  onChange={e =>
                    this.handleChange({ companyType: e.target.value })
                  }
                  value={this.state.companyProfile.companyType}
                  style={{ width: "46.5%" }}>
                  <MenuItem disabled value=" ">
                    <span>
                      {t("profilesetup:form.placeholder.companyType")}
                    </span>
                  </MenuItem>
                  <MenuItem value="National">National</MenuItem>
                  <MenuItem value="Multinational">Multinational</MenuItem>
                </SelectValidator>
              </div>

              <Grid item lg={11} md={11} sm={11} style={{ marginTop: 20 }}>
                <div>
                  <label
                    style={{
                      textTransform: "uppercase",
                      fontSize: 14,
                      fontWeight: "lighter",
                      fontFamily: "Hurme Geometric Sans 3",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    {t("profilesetup:form.field.businessType")}
                    <span style={{ color: "#CD594D" }}>*</span>
                    <HtmlTooltip
                      title={
                        <div style={{ color: "white" }}>
                          <p> {t("profilesetup:tooltip.businessType")}</p>
                        </div>
                      }
                      arrow>
                      <InfoIcon
                        style={{
                          color: palette.primarydark,
                          fontSize: 18,
                          margin: "0px 10px 3px",
                        }}
                      />
                    </HtmlTooltip>
                  </label>
                  <SelectSearch
                    validators={["selectRequired"]}
                    errorMessages={[t("validation.required.businessType")]}
                    closeMenuOnSelect
                    key={new Date().getTime()}
                    className="Select-bussiness-type-confirm"
                    classNamePrefix="select"
                    placeholder={t(
                      "profilesetup:form.placeholder.businessType"
                    )}
                    menuColor={palette.primary}
                    name="bussinestype"
                    options={listBusinessType}
                    value={this.state.selected}
                    onChange={e => this.onChangeBussinessType(e)}
                    style={{ color: palette.primary }}
                    isMulti={true}
                  />
                </div>
              </Grid>

              <div
                style={{
                  marginTop: "5%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontSize: 14,
                    fontWeight: "lighter",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.website")}
                </label>
                <TextValidator
                  onChange={e => {
                    this.handleChange({ website: e.currentTarget.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t("profilesetup:form.placeholder.website")}
                  name="website"
                  validators={["isUrl"]}
                  errorMessages={[t("validation.valid.website")]}
                  value={this.state.companyProfile.website}
                  style={{ width: "93%" }}
                />
              </div>

              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontSize: 14,
                    fontWeight: "lighter",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.established")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <DatePickerValidator
                  validators={["required"]}
                  errorMessages={[t("validation.required.established")]}
                  className="Profile"
                  label={""}
                  maxDate={new Date()}
                  format="yyyy"
                  maxDetail="decade"
                  style={{ width: 520, fontSize: 14 }}
                  value={year}
                  onChange={e => {
                    this.handleChange({
                      established: e.getFullYear().toString(),
                    });
                  }}
                  yearPlaceholder={t("biodata:form.placeholder.year")}
                />
              </div>
              <div
                style={{
                  marginTop: "3%",
                }}>
                <label
                  style={{
                    textTransform: "uppercase",
                    fontSize: 14,
                    fontWeight: "lighter",
                    fontFamily: "Hurme Geometric Sans 3",
                  }}>
                  {t("profilesetup:form.field.aboutCompany")}
                  <span style={{ color: "#CD594D" }}>*</span>
                </label>
                <TextValidator
                  validators={["required"]}
                  errorMessages={[t("validation.required.aboutCompany")]}
                  onChange={e => {
                    this.handleChange({ aboutUs: e.currentTarget.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t("profilesetup:form.placeholder.aboutCompany")}
                  multiline
                  rows={4}
                  name="aboutCompany"
                  value={this.state.companyProfile.aboutUs}
                  style={{
                    width: "93%",
                    fontWeight: "bolder",
                    color: palette.primary,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "70%",
                  marginLeft: "17%",
                  marginTop: "3%",
                }}>
                <div style={{ fontSize: 12 }}>
                  <div style={{ textAlign: "center" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 14,
                        fontWeight: "lighter",
                        fontFamily: "Hurme Geometric Sans 3",
                      }}>
                      {t("profilesetup:form.field.companyLogo")}
                      <span style={{ color: "#CD594D" }}>*</span>
                    </label>
                  </div>
                  <Uploader
                    isBlob={false}
                    key={1}
                    accept="image/png,image/jpg,image/jpeg"
                    //label={t("profilesetup:form.field.companyLogo")}
                    style={{ width: 170, height: 170, fontSize: 12 }}
                    previewStyle={{ width: 170, height: 170 }}
                    labelStyle={{
                      fontSize: 12,
                      textAlign: "center",
                      fontWeight: "lighter",
                    }}
                    onDropFile={o => {
                      this.handleUploadImage(o, "companyLogo");
                    }}
                    value={this.state.companyProfile.companyLogo}
                  />
                </div>
                <div style={{ marginLeft: "5%", fontSize: 12 }}>
                  <div style={{ textAlign: "center" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 14,
                        fontWeight: "lighter",
                        fontFamily: "Hurme Geometric Sans 3",
                      }}>
                      {t("profilesetup:form.field.picIdentityCard")}
                    </label>
                  </div>
                  <Uploader
                    isBlob={false}
                    key={1}
                    accept="image/png,image/jpg,image/jpeg"
                    //label={t("profilesetup:form.field.picIdentityCard")}
                    style={{ width: 170, height: 170, fontSize: 12 }}
                    previewStyle={{ width: 170, height: 170 }}
                    labelStyle={{
                      fontSize: 12,
                      textAlign: "center",
                      fontWeight: "lighter",
                    }}
                    onDropFile={o => {
                      this.handleUploadImage(o, "picIdentityCard");
                    }}
                    value={this.state.companyProfile.picIdentityCard}
                  />
                </div>
                <div style={{ marginLeft: "5%", fontSize: 12 }}>
                  <div style={{ textAlign: "center" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontSize: 14,
                        fontWeight: "lighter",
                        fontFamily: "Hurme Geometric Sans 3",
                      }}>
                      {t("profilesetup:form.field.siup")}
                    </label>
                  </div>
                  <Uploader
                    isBlob={false}
                    key={1}
                    accept="image/png,image/jpg,image/jpeg,application/pdf"
                    //label={t("profilesetup:form.field.siup")}
                    style={{ width: 170, height: 170, fontSize: 12 }}
                    previewStyle={{ width: 170, height: 170 }}
                    labelStyle={{
                      fontSize: 12,
                      textAlign: "center",
                      fontWeight: "lighter",
                    }}
                    onDropFile={o => {
                      this.handleUploadImage(o, "siup");
                    }}
                    value={this.state.companyProfile.siup}
                  />
                </div>
              </div>

              <button type="submit" id="button-submit" className="Submit-Btn">
                submit
              </button>
            </ValidatorForm>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ConfirmationProfileCorporate);
