import React from "react";
import { Text, Page, View, Image } from "@react-pdf/renderer";
import { CIStyles, WaterMark } from "./ci-styles";
import { palette } from "assets/css/main";
import moment from "moment";

const CIPageCover = props => {
  const { personalInfo, listFormalEducation } = props.data.personalInfo
    ? props.data
    : { personalInfo: {}, listFormalEducation: [] };
  return (
    <Page style={CIStyles.page}>
      <WaterMark />
      <View style={{ marginTop: "350pt", marginBottom: "25pt" }}>
        <Image
          src="/assets/img/HiregradeLogo.png"
          style={{ width: "250px", height: "50px" }}
        />
      </View>
      <View>
        <Text style={{ fontWeight: "bold", fontSize: "50pt", width: "300pt" }}>
          Candidate Information
        </Text>
      </View>

      <View
        style={{
          width: "100%",
          backgroundColor: palette.bgs,
          padding: "20pt 20pt",
          borderRadius: "8pt",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}>
        <Image
          src={personalInfo.photo + "?t=" + new Date().getTime()}
          style={{
            backgroundColor: palette.darkGrey,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: 80,
            height: 80,
            borderTopLeftRadius: 40,
            borderTopRightRadius: 40,
            borderBottomRightRadius: 40,
            borderBottomLeftRadius: 40,
          }}
          allowDangerousPaths
        />
        <View style={{ marginLeft: 30 }}>
          {props.status !== "SEL" && (
            <Text style={{ fontWeight: "bold" }}>{personalInfo.name}</Text>
          )}
          {props.status === "SEL" && (
            <Text style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              {personalInfo.userId ? personalInfo.userId.substr(0, 6) : ""}
            </Text>
          )}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 11,
              color: palette.cv.textCover,
              paddingTop: 10,
            }}>
            <View style={{ marginRight: 50 }}>
              <Text>
                {listFormalEducation[0]
                  ? listFormalEducation[0].institution
                  : ""}
              </Text>
              <Text>
                {listFormalEducation[0] ? listFormalEducation[0].major : ""}
              </Text>
            </View>
            {props.status !== "SEL" && (
              <View>
                <Text>{personalInfo.email}</Text>
                <Text>
                  {personalInfo.phoneNumber ? "+62" : ""}
                  {personalInfo.phoneNumber ? personalInfo.phoneNumber : ""}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
      <View>
        <Text
          style={{ fontSize: 8, color: palette.cv.textNote, marginTop: 20 }}>
          Document downloaded: {moment(new Date()).format("DD-MMMM-yyyy")}
        </Text>
      </View>
    </Page>
  );
};
export default CIPageCover;
