import React from "react";
import {
  Dialog,
  Grid,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { palette, WHITE_BUTTON } from "assets/css/main";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import data from "app/pages/applicant/initialize/bio-data/data";
import "../user-home.css";
import DatePicker from "app/components/general/DatePickers.js";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import alertData from "app/components/alert/alert-data";
import SelectSearch from "react-select";
import { findObject, getItem, RequestGet } from "app/utils";

class DialogueWorkExp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...data.additional_question,
      listBusinessArea: [],
    };
  }

  componentDidMount() {
    this.getBusinessArea();
  }

  handleWorkExp(obj) {
    var newWorkExp = data.additional_question.workExp;
    newWorkExp = { ...newWorkExp, ...obj };
    this.setState({ workExp: newWorkExp });
    data.setAdditionalQuestion({ workExp: newWorkExp });
  }

  getBusinessArea() {
    RequestGet("Industry")
      .then(res => {
        this.setState({
          listBusinessArea: res.data.map(obj => {
            return {
              value: obj.id,
              label:
                getItem("lang") === "en" ? obj.industryEng : obj.industryIna,
            };
          }),
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeBussinessArea(businessAreaId) {
    var listbusinessAreabyId = findObject(
      this.state.listBusinessArea,
      "value",
      businessAreaId.businessAreaId
    );
    var obj = { field: listbusinessAreabyId };
    var newWorkExp = data.additional_question.workExp;
    newWorkExp = { ...newWorkExp, ...obj };
    this.setState({ workExp: newWorkExp });
    data.setAdditionalQuestion({ workExp: newWorkExp });
  }

  render() {
    const { workExp, listBusinessArea } = this.state;
    const { open, t } = this.props;
    return (
      <Dialog
        className={"dialog-additional"}
        aria-labelledby="customized-dialog-title"
        disableBackdropClick
        open={open}
        scroll={"body"}
        style={{ backgroundColor: "transparent" }}>
        <Grid
          container
          justify="center"
          alignItems="flex-end"
          style={{
            backgroundColor: "transparent",
            width: 550,
            paddingTop: 60,
          }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              padding: "32px",
              backgroundColor: "white",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              width: "100%",
              color: palette.primary,
            }}>
            <Grid item lg={12} md={12} sm={12}>
              <Typography
                style={{
                  fontSize: "18px",
                  lineHeight: "23px",
                  marginBottom: "16px",
                }}>
                {t("biodata:popup_workExp.title")}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <hr
                style={{
                  margin: "0 -32px",
                  border: "1px solid #DFE5F4",
                  marginBottom: "16px",
                }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <InputLabel
                shrink
                id="employmentstatus"
                style={{
                  color: palette.primary,
                  fontSize: "14px",
                  textTransform: "none",
                }}>
                {t("biodata:form.field.employmentstatus")}
              </InputLabel>
              <Select
                labelId="employmentstatus"
                style={{
                  color: palette.primary,
                  width: "100%",
                  marginBottom: "30px",
                }}
                className="Field select-with-placeholder"
                onChange={e => {
                  this.handleWorkExp({ status: e.target.value });
                }}
                value={workExp.status ? workExp.status : " "}>
                <MenuItem value=" " disabled>
                  <span>{t("biodata:form.placeholder.CVjobststus")}</span>
                </MenuItem>
                <MenuItem value="1">
                  {
                    t("cv:jobStatus.options", {
                      returnObjects: true,
                    })[0]
                  }
                </MenuItem>
                <MenuItem value="2">
                  {
                    t("cv:jobStatus.options", {
                      returnObjects: true,
                    })[1]
                  }
                </MenuItem>
                <MenuItem value="3">
                  {
                    t("cv:jobStatus.options", {
                      returnObjects: true,
                    })[2]
                  }
                </MenuItem>
              </Select>
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <TextField
                style={{
                  width: "100%",
                  marginBottom: "30px",
                }}
                onChange={e => {
                  this.handleWorkExp({ name: e.currentTarget.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={workExp.name}
                placeholder={t("biodata:form.placeholder.companyname")}
                label={t("biodata:form.field.companyname")}
                className="Field"
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <InputLabel
                shrink
                id="businessarea"
                style={{
                  color: palette.primary,
                  fontSize: "14px",
                  textTransform: "none",
                }}>
                {t("biodata:form.field.businessarea")}
              </InputLabel>
              <SelectSearch
                closeMenuOnSelect
                key={new Date().getTime()}
                className="Select-domicile-location"
                classNamePrefix="select"
                placeholder={t("biodata:form.placeholder.businessarea")}
                menuColor={palette.primary}
                name="businessArea"
                options={listBusinessArea}
                value={workExp.field ? workExp.field : " "}
                onChange={v => {
                  this.onChangeBussinessArea({
                    businessAreaId: v.value,
                  });
                }}
                style={{ color: palette.primary }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} style={{ marginTop: "30px" }}>
              <TextField
                style={{
                  width: "100%",
                  marginBottom: "30px",
                }}
                onChange={e => {
                  this.handleWorkExp({ role: e.currentTarget.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={workExp.role}
                placeholder={t("biodata:form.placeholder.position")}
                label={t("biodata:form.field.position")}
                className="Field"
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <DatePicker
                className="DateWorkExp"
                label={t("biodata:form.field.startdate")}
                format="dd/MM/yyyy"
                // style={{ width: 20 }}
                value={workExp.yearStart}
                maxDate={new Date()}
                onChange={e => {
                  this.handleWorkExp({ yearStart: e });
                }}
              />
            </Grid>

            {!workExp.stillWorking && (
              <Grid item lg={12} md={12} sm={12}>
                <DatePicker
                  className="DateWorkExp"
                  label={t("biodata:form.field.enddate")}
                  format="dd/MM/yyyy"
                  // style={{ width: 20 }}
                  value={workExp.yearEnd}
                  maxDate={new Date()}
                  onChange={e => {
                    this.handleWorkExp({ yearEnd: e });
                  }}
                />
              </Grid>
            )}

            <Grid item lg={1} md={1} sm={1}>
              <Checkbox
                className="show-button"
                color="primary"
                checked={workExp.stillWorking}
                icon={
                  <CheckBoxOutlineBlank
                    // fontSize="large"
                    color="primary"
                  />
                }
                checkedIcon={
                  <CheckBox
                  // fontSize="large"
                  />
                }
                name="checkedI"
                onClick={() => {
                  this.handleWorkExp({ stillWorking: !workExp.stillWorking });
                }}
              />
            </Grid>
            <Grid item lg={11} md={11} sm={11}>
              <Typography
                style={{
                  color: palette.primary,
                  fontSize: "14px",
                  paddingTop: "5px",
                }}>
                Current
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="space-between"
          style={{
            height: 70,
            backgroundColor: palette.primary,
            paddingLeft: 20,
            paddingRight: 20,
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
          }}>
          <Button style={{ ...WHITE_BUTTON }} onClick={this.props.onClose}>
            {t("biodata:popup_workExp.btnCancel")}
          </Button>
          <Button
            style={{ ...WHITE_BUTTON }}
            onClick={() => {
              if (
                (data.additional_question.workExp.status !== "" &&
                  data.additional_question.workExp.name !== "" &&
                  data.additional_question.workExp.field !== "" &&
                  data.additional_question.workExp.role !== "" &&
                  data.additional_question.workExp.yearStart !== "" &&
                  data.additional_question.workExp.yearEnd !== "" &&
                  !data.additional_question.workExp.stillWorking) ||
                (data.additional_question.workExp.status !== "" &&
                  data.additional_question.workExp.name !== "" &&
                  data.additional_question.workExp.field !== "" &&
                  data.additional_question.workExp.role !== "" &&
                  data.additional_question.workExp.yearStart !== "" &&
                  data.additional_question.workExp.stillWorking)
              ) {
                this.props.onOK();
              } else {
                alertData.show(t("biodata:popup_workExp.warning"), "error");
              }
            }}>
            {t("biodata:popup_workExp.btnNext")}
          </Button>
        </Grid>
      </Dialog>
    );
  }
}

DialogueWorkExp.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  onOK: propTypes.func,
};

DialogueWorkExp.defaultProps = {
  open: true,
  onOK: () => {
    return true;
  },
  onClose: () => {
    return false;
  },
};

export default withTranslation()(DialogueWorkExp);
