import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Grid, Typography, Button } from "@material-ui/core";
class SideStepDetail extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
  }

  render() {
    const { t } = this.props;
    return (
      <Grid
        container
        style={{
          backgroundColor: "#F8F8F8",
          top: 260,
          position: "fixed",
          width: "25%",
          height: "100%",
          overflowX: "auto",
        }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: 32, paddingLeft: 90, paddingRight: 24 }}>
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {t("job-opening:jobOpeningDetail.sideStep.title")}
          </Typography>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: 16, marginLeft: 90 }}>
          <div
            style={
              this.props.head === "JobDetails"
                ? { backgroundColor: "rgba(46, 105, 178, 0.1)" }
                : null
            }>
            <Button
              style={{
                textTransform: "none",
                padding: "7px 24px 6px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                this.props.onChange("JobDetails");
              }}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {
                  t("job-opening:jobOpeningDetail.sideStep.menu", {
                    returnObjects: true,
                  })[0]
                }
              </Typography>
            </Button>
          </div>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: 9, marginLeft: 90 }}>
          <div
            style={
              this.props.head === "JobRequirement"
                ? { backgroundColor: "rgba(46, 105, 178, 0.1)" }
                : null
            }>
            <Button
              style={{
                textTransform: "none",
                padding: "7px 24px 6px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                this.props.onChange("JobRequirement");
              }}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {
                  t("job-opening:jobOpeningDetail.sideStep.menu", {
                    returnObjects: true,
                  })[1]
                }
              </Typography>
            </Button>
          </div>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: 9, marginLeft: 90 }}>
          <div
            style={
              this.props.head === "CompanyProfile"
                ? { backgroundColor: "rgba(46, 105, 178, 0.1)" }
                : null
            }>
            <Button
              style={{
                textTransform: "none",
                padding: "7px 24px 6px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                this.props.onChange("CompanyProfile");
              }}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {
                  t("job-opening:jobOpeningDetail.sideStep.menu", {
                    returnObjects: true,
                  })[2]
                }
              </Typography>
            </Button>
          </div>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: 9, marginLeft: 90 }}>
          <div
            style={
              this.props.head === "InterviewInvitation"
                ? { backgroundColor: "rgba(46, 105, 178, 0.1)" }
                : null
            }>
            <Button
              style={{
                textTransform: "none",
                padding: "7px 24px 6px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => {
                this.props.onChange("InterviewInvitation");
              }}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "lighter",
                  color: palette.primary,
                }}>
                {
                  t("job-opening:jobOpeningDetail.sideStep.menu", {
                    returnObjects: true,
                  })[3]
                }
              </Typography>
            </Button>
          </div>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          style={{ marginTop: "80%", marginBottom: "80%" }}
        />
      </Grid>
    );
  }
}
export default withTranslation()(SideStepDetail);
