import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { ImgBanner, ImgBannerMobile } from "assets/img";
import { withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

class Banner extends React.Component {
  render() {
    return (
      <Grid
        style={{
          backgroundColor: "black",
          height: isMobile ? "60vh" : "100vh",
          position: "relative",
        }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            backgroundImage: isMobile
              ? "url(" + ImgBannerMobile + ")"
              : "url(" + ImgBanner + ")",
            opacity: 0.7,
            backgroundSize: "cover",
          }}></div>

        <div
          style={{
            position: "absolute",
            top: "20%",
            maxWidth: isMobile ? 150 : 500,
            left: "4.5%",
          }}>
          <Typography
            variant={isMobile ? "body1" : "h3"}
            style={{
              lineHeight: 1.2,
              color: "white",
              fontSize: isMobile ? 21 : "3rem",
              fontWeight: "bold",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}>
            Getting hired has just got lot smarter
          </Typography>
        </div>
      </Grid>
    );
  }
}
export default withTranslation()(Banner);
