import React from "react";
import { Grid, Button } from "@material-ui/core";
import "./custom.css";
import {
  IconChecked,
  IconUncheck,
  IconQuestion,
  ImgQuestionBackgroundTop,
} from "assets/img";
import { getItem, findObject, removeObject } from "app/utils";

export default class CheckboxImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.defaultValue,
    };
    this.translate = this.props.t;
  }

  handleClick(value) {
    var arrActive = this.state.active.length ? this.state.active : this.props.defaultValue;

    if (findObject(arrActive, "softskillDetailId", value.id)) {
      arrActive = removeObject(arrActive, "softskillDetailId", value.id);
    } else {
      arrActive.push({
        softskillHeaderId: value.softskillHdrId,
        softskillDetailId: value.id
      });
    }

    this.setState({active: arrActive});
    this.props.onChange(arrActive);
  }

  render() {
    const { style, options, defaultValue } = this.props;
    const { active } = this.state;
    const result = active.length ? active : defaultValue;
    return (
      <Grid 
        container 
        style={{ ...style, marginBottom: 20 }}
      >
        {options.map((v, i) => {
          return (
            <Grid
              item lg={4} md={4} sm={4} key={i}
            >
              <div>
                <Grid container>
                  <Grid
                    item lg={12} md={12} sm={12}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={v.imageUrlEng}
                      height={"150vh"}
                      alt={v.id}
                    />
                  </Grid>

                  <Grid
                    item lg={7} md={7} sm={7} 
                    style={{
                      marginTop: "2%",
                      color: "#2F3168",
                      textTransform: "uppercase",
                      fontSize: "12px",
                      textAlign: "right",
                    }}
                  >
                    {v.softskillValueEng}
                  </Grid>
                  <Grid
                    item lg={5} md={5} sm={5}
                    style={{
                      marginTop: "1%"
                    }}
                  >
                    <img
                      className="question-tag"
                      style={{
                        marginLeft: 10, 
                        marginBottom: -2
                      }}
                      src={IconQuestion}
                      height={"15h"}
                      alt="QuestionTag"
                    />

                    <div 
                      className="question"
                      style={{
                        position: "absolute",
                        marginLeft: -137, 
                        marginTop: -141
                      }}
                    >
                      <img
                        src={ImgQuestionBackgroundTop}
                        height={"120vh"}
                        alt="question"
                      />

                      <div
                        style={{
                          position: "absolute",
                          color: "white",
                          fontSize: "12px",
                          textTransform: "none",
                          top: "10%",
                          left: "2%",
                          right: "2%",
                          textAlign: "justify"
                        }}
                      >
                        {getItem("lang") === "en" ? (
                          v.attributeEng.split("\r\n").map(val => {
                            return(
                              <>
                                {val}<br/>
                              </>
                            )
                          })
                        ) : (
                          v.attributeIna.split("\r\n").map(val => {
                            return(
                              <>
                                {val}<br/>
                              </>
                            )
                          })
                        )}
                      </div>
                    </div>
                  </Grid>

                  <Grid
                    item lg={12} md={12} sm={12}
                    style={{
                      margin: "2% auto",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      onClick={() => {
                        this.handleClick(v)
                      }}
                    >
                      <img className="radio"
                        src={findObject(result, "softskillDetailId", v.id) ? IconChecked : IconUncheck}
                        height={"15vh"}
                        alt={v.id}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
