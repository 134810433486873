import React from "react";
import { Grid } from "@material-ui/core";
import { palette } from "assets/css/main";
import MyHelmet from "app/components/header/MyHelmet";
import { Header, Footer, HeaderNologin } from "app/components";
import ContentTnc from "app/components/general/ContentTnc";
import { withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

class TermCondition extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    if (isMobile) {
      return (
        <div>
          <MyHelmet title="Term And Condition" />
          <HeaderNologin />
          <Grid
            container
            style={{
              backgroundColor: palette.primary,
              color: "white",
              padding: "60px 5px 40px",
            }}>
            <p
              style={{
                margin: "45px 20px 25px",
                fontSize: 24,
                textAlign: "center",
                width: "100%",
                fontWeight: "bold",
              }}>
              {t("auth:tnc")}
            </p>
            <ContentTnc style={{}} />
          </Grid>
          <Footer />
        </div>
      );
    }
    return (
      <div>
        <MyHelmet title="Term And Condition" />
        <Header shadow={true} linkgroup={true} />
        <Grid
          container
          style={{
            backgroundColor: palette.primary,
            color: "white",
            padding: "100px 100px 100px 100px",
          }}>
          <p style={{ marginLeft: 40, fontSize: 24, fontWeight: "bold" }}>
            {t("auth:tnc")}
          </p>
          <ContentTnc />
        </Grid>
        <Footer />
      </div>
    );
  }
}
export default withTranslation()(TermCondition);
