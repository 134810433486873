import { SetLocalItem } from "app/utils";
import { observable, decorate, action /*, action*/ } from "mobx";

class JobOpening {
  jobList = [];

  jobDetail = { jobDetail: { workLocations: [] } };

  setJobDetail(new_data) {
    this.jobDetail = { ...this.jobDetail, ...new_data };
  }

  setEmptyJobDetail() {
    this.jobDetail = {};
    SetLocalItem("InterviewID", null);
  }
}

const jobOpening = decorate(JobOpening, {
  jobList: observable,
  jobDetail: observable,
  setJobDetail: action,
  setEmptyJobDetail: action,
});

export default new jobOpening();
