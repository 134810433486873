import React from "react";
import {
  getItem,
  removeObject,
  RequestGet,
  RequestPost,
  RequestPut,
  findObject,
} from "app/utils";
import { palette } from "assets/css/main";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Slider,
  Checkbox,
} from "@material-ui/core";
import { nameRegex, numberRegex } from "app/utils";
import { withTranslation } from "react-i18next";
import { ConfirmFooter } from "./confirmation-footer";
import data from "app/pages/applicant/initialize/bio-data/data";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DatePickerValidator from "app/components/general/DatePickerValidator";
import { ReadOnlyField, Uploader } from "app/components";
import SelectSearch from "react-select";
import alertData from "app/components/alert/alert-data";
import { DialogueInfo } from "app/components";

const addComma = val => {
  if (val) {
    return ", " + val;
  } else {
    return "";
  }
};

class ConfirmPersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
    this.submitForm = this.submitForm.bind(this);
    this.submitFormAddress = this.submitFormAddress.bind(this);
    this.state = {
      biodata: props.data,
      data: [],
      ...JSON.parse(getItem("user_info")),
      anchorEl: null,
      open: false,
      listProvince: [],
      listCity: [],
      listDistrict: [],
      listSubDistrict: [],
      provinceId: null,
      cityId: null,
      districtId: null,
      descriptionCount: props.data.personalInfo.description
      ? props.data.personalInfo.description.length
      : 0,
      listforeignLangProficiency: [],
      listCheckedForeignLang: [],
      openValidation: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClickAddress = this.handleClickAddress.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {
    this.getLanguageSkill();
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule("selectRequired");
  }

  handleChange(obj, length) {
    this.setState(obj);
    data.setPersonalData(obj);
    if (length > 0) {
      this.setState({
        descriptionCount: length,
      });
    } else {
      this.setState({
        descriptionCount: 0,
      });
    }
    var personalInfo = {
      personalInfo: { ...this.state.biodata.personalInfo, ...obj },
    };
    this.setState({
      biodata: { ...this.state.biodata, ...personalInfo },
    });

    //update props state
    const tempArray = this.props.data;
    tempArray.personalInfo = { ...this.state.biodata.personalInfo, ...obj };
    this.props.updateData(tempArray)

    var e = document.getElementById("error-messages");
    var el = document.getElementsByClassName("MuiInput-underline");
    var label = document.getElementsByClassName("MuiInputLabel-root");

    try {
      e.innerText = "";
      document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
      label[0].classList.remove("Mui-error");
      el[0].classList.remove("Mui-error");
    } catch (error) {}
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  handleClickOpen = () => {
    this.getProvince();
  };

  date(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toDateString([], options);
  }

  submitFormAddress() {
    this.editaddress();
  }

  editaddress() {
    const { t } = this.props;
    if (this.state.city === " ") {
      alertData.show(t("biodata:validation.required.city"), "error");
      return false;
    }
    if (this.state.district === " ") {
      alertData.show(t("biodata:validation.required.district"), "error");
      return false;
    }
    if (this.state.subDistrict === " ") {
      alertData.show(t("biodata:validation.required.subDistrict"), "error");
      return false;
    }
    // for state
    var dataAddress = {};
    dataAddress.personalInfo = this.state.biodata.personalInfo;
    dataAddress.personalInfo.address =
    this.state.street +
    addComma(this.state.rt + "/" + this.state.rw) +
    addComma(this.state.subDistrict.label) +
    addComma(this.state.district.label) +
    addComma(this.state.city.label) +
    addComma(this.state.province.label) +
    addComma(this.state.zipcode);
   dataAddress.personalInfo.rtrw = this.state.rtrw;
   dataAddress.personalInfo.subDistrict = this.state.subDistrict.value.toString();
   dataAddress.personalInfo.district = this.state.district.value.toString();
   dataAddress.personalInfo.city = this.state.city.value.toString();
   dataAddress.personalInfo.province = this.state.province.value.toString();
   dataAddress.personalInfo.zipcode = parseInt(this.state.zipcode);

    //for mobx
    data.personal_data = this.state.biodata.personalInfo;
    data.personal_data.address =
      this.state.street +
      addComma(this.state.rt + "/" + this.state.rw) +
      addComma(this.state.subDistrict.label) +
      addComma(this.state.district.label) +
      addComma(this.state.city.label) +
      addComma(this.state.province.label) +
      addComma(this.state.zipcode);
    data.personal_data.rtrw = this.state.rtrw;
    data.personal_data.subDistrict = this.state.subDistrict.value.toString();
    data.personal_data.district = this.state.district.value.toString();
    data.personal_data.city = this.state.city.value.toString();
    data.personal_data.province = this.state.province.value.toString();
    data.personal_data.zipcode = parseInt(this.state.zipcode);

    this.setState(
      { open: false, biodata: {...this.state.biodata, personalInfo: dataAddress.personalInfo } }
    );
   
    this.props.updateData({...this.state.biodata, personalInfo: dataAddress.personalInfo })
  }

  handleRTRW(e) {
    var val = e.currentTarget.value;
    if (numberRegex.test(val)) {
      val = parseInt(e.currentTarget.value);
      if (val < 10) {
        return "00" + val;
      } else if (val < 100) {
        return "0" + val;
      } else if (val < 1000) {
        return val.toString();
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getProvince() {
    RequestGet("location/province")
      .then(resProv => {
        var province = resProv.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var listprovincebyId = findObject(
          province,
          "value",
          parseInt(this.state.biodata.personalInfo.province)
        );

        if(this.state.biodata.personalInfo.address !== null) {
          this.getCity(province, listprovincebyId);
        }else{
          this.setState({
            open: true,
            ...this.state.biodata.personalInfo,
          listProvince: province,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  getCity(province, listprovincebyId) {
    RequestGet(
      "location/city/" +
        parseInt(this.state.biodata.personalInfo.province, {
          loading: true,
        })
    )
      .then(resCity => {
        var city = resCity.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var listcitybyId = findObject(
          city,
          "value",
          parseInt(this.state.biodata.personalInfo.city)
        );
        this.getDistrict(province, listprovincebyId, city, listcitybyId);
      })
      .catch(err => {
        console.log(err);
      });
  }

  getDistrict(province, listprovincebyId, city, listcitybyId) {
    RequestGet(
      "location/district/" +
        parseInt(this.state.biodata.personalInfo.city, {
          loading: true,
        })
    )
      .then(resDistrict => {
        var district = resDistrict.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var listdistrictbyId = findObject(
          district,
          "value",
          parseInt(this.state.biodata.personalInfo.district)
        );
        this.getSubDistrict(
          province,
          listprovincebyId,
          city,
          listcitybyId,
          district,
          listdistrictbyId
        );
      })
      .catch(err => {
        console.log(err);
      });
  }

  getSubDistrict(
    province,
    listprovincebyId,
    city,
    listcitybyId,
    district,
    listdistrictbyId
  ) {
    RequestGet(
      "location/subdistrict/" +
        parseInt(this.state.biodata.personalInfo.district, {
          loading: true,
        })
    )
      .then(resSubDistrict => {
        var subdistrict = resSubDistrict.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var listsubdistrictbyId = findObject(
          subdistrict,
          "value",
          parseInt(this.state.biodata.personalInfo.subDistrict)
        );
        this.setState({
          open: true,
          ...this.state.biodata.personalInfo,
          listProvince: province,
          listCity: city,
          listDistrict: district,
          listSubDistrict: subdistrict,
          province: listprovincebyId,
          city: listcitybyId,
          district: listdistrictbyId,
          subDistrict: listsubdistrictbyId,
          address: this.state.biodata.personalInfo.address,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  submitForm() {
    this.submitdata();
  }

  submitdata() {
    var datasubmit = {};
    datasubmit.personalInfo = this.state.biodata.personalInfo;
    var fn = this.state.biodata.personalInfo.firstName,
      mn = this.state.biodata.personalInfo.middleName,
      ln = this.state.biodata.personalInfo.lastName;
    var full = fn + " " + mn + " " + ln;
    datasubmit.personalInfo.name = full.replace(/ +(?= )/g, "").trim();

    datasubmit.listFormalEducation = this.state.biodata.listFormalEducation.length > 0 ? this.state.biodata.listFormalEducation : data.listFormalEducation;
    datasubmit.listOrganization = this.state.biodata.listOrganization;
    datasubmit.listInformalEducation = this.state.biodata.listInformalEducation;
    datasubmit.listWorks = this.state.biodata.listWorks.length > 0 ? this.state.biodata.listWorks : data.listWorks;

    const {address,description,birthPlace,phoneNumber} = this.state.biodata.personalInfo;
    const {photo} = this.props.data.personalInfo;
    
    if (photo === null || address === null || description === null || birthPlace === null || phoneNumber === null) {
      this.setState({
        openValidation: true,
      });
    }else{
      RequestPut("biodata", datasubmit)
        .then(() => {
          window.location.reload();
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  handleUploadImage(file, part) {
    var form = new FormData();
    form.append(
      "file",
      file.file,
      getItem("user_id") + "-" + part + "." + file.extension
    );
    RequestPost("biodata/upload", form)
      .then(r => {
        const { imageUrl } = r.data;
        try {
          document.getElementById("preview-img").blur();
          document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
        } catch (error) {
          console.log(error);
        }
        if (part === "identityPhoto") {
          this.handleChange({ identityPhoto: imageUrl });
        }
      })
      .catch(e => {
        console.log("handleUploadImage()", e);
      });
  }

  handleClickAddress(e) {
    document.getElementById("button-submit-address").click();
  }

  onChangeProvince(provinceId) {
    var listprovincebyId = findObject(
      this.state.listProvince,
      "value",
      provinceId.provinceId
    );
    RequestGet("location/city/" + provinceId.provinceId, {
      loading: true,
    })
      .then(resCity => {
        var city = resCity.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var obj = { province: provinceId.provinceId };
        data.setPersonalData(obj);
        this.setState({
          open: true,
          ...this.state.biodata.personalInfo,
          listCity: city,
          listDistrict: [],
          listSubDistrict: [],
          province: listprovincebyId,
          city: " ",
          district: " ",
          subDistrict: " ",
          zipcode: "",
          address: "",
          rt: "000",
          rw: "000",
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeCity(cityId) {
    var listcitybyId = findObject(this.state.listCity, "value", cityId.cityId);
    RequestGet("location/district/" + cityId.cityId, {
      loading: true,
    })
      .then(resDistrict => {
        var district = resDistrict.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var obj = { city: cityId.cityId };
        data.setPersonalData(obj);
        this.setState({
          open: true,
          ...this.state.biodata.personalInfo,
          listDistrict: district,
          listSubDistrict: [],
          province: this.state.province,
          city: listcitybyId,
          district: " ",
          subDistrict: " ",
          zipcode: "",
          address: "",
          rt: "000",
          rw: "000",
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeDistrict(districtId) {
    var listdistrictbyId = findObject(
      this.state.listDistrict,
      "value",
      districtId.districtId
    );
    RequestGet("location/subdistrict/" + districtId.districtId, {
      loading: true,
    })
      .then(resSubDistrict => {
        var subdistrict = resSubDistrict.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var obj = { district: districtId.districtId };
        data.setPersonalData(obj);
        this.setState({
          open: true,
          ...this.state.biodata.personalInfo,
          listSubDistrict: subdistrict,
          province: this.state.province,
          city: this.state.city,
          district: listdistrictbyId,
          subDistrict: " ",
          zipcode: "",
          address: "",
          rt: "000",
          rw: "000",
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeSubDistrict(subdistrictId) {
    var listsubdistrictbyId = findObject(
      this.state.listSubDistrict,
      "value",
      subdistrictId.subDistrict
    );
    var obj = { subDistrict: subdistrictId.subdistrictId };
    data.setPersonalData(obj);
    this.setState({
      open: true,
      ...this.state.biodata.personalInfo,
      province: this.state.province,
      city: this.state.city,
      district: this.state.district,
      subDistrict: listsubdistrictbyId,
      zipcode: "",
      address: "",
      rt: "000",
      rw: "000",
    });
  }

  getLanguageSkill() {
    var char = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var list = [];
    var checkedLang = this.state.biodata.personalInfo.listUserLanguage;
    RequestGet("Biodata/Language")
      .then(res => {
        var sliderlang = [];
        var checkedval = [];
        var proficiency = [];
        var listchek = this.state.listCheckedForeignLang;
        res.data.map((v, i) => {
          sliderlang.push(false);
          checkedval.push(false);
          proficiency.push(1);
          checkedLang.forEach(e => {
            if (v.id === e.languageId) {
              checkedval[i] = true;
              sliderlang[i] = true;
              proficiency[i] = e.proficiency;

              listchek.push({
                languageId: e.languageId,
                proficiency: e.proficiency,
              });
            }
          });
          return list.push({
            key: char[i],
            label:
              getItem("lang") === "en" ? v.languageSkill : v.languageSkillIna,
            value: v.id,
            valchecked: checkedval[i],
            slide: sliderlang[i],
            proficiencybyId: proficiency[i],
          });
        });
        this.setState({
          ...this.state,
          listforeignLangProficiency: list,
          levellang: sliderlang,
          strLevelLang: proficiency,
          listCheckedForeignLang: listchek,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  onClickCheckedLang(e, langId, idx) {
    var listlang = this.state.listforeignLangProficiency;
    var tmp = this.state.levellang;

    var listchek = this.state.listCheckedForeignLang;
    var obj = {};
    if (e.target.checked) {
      listchek.push({ languageId: langId, proficiency: 1 });

      obj = { listUserLanguage: listchek };
      this.setState({ listCheckedForeignLang: listchek });
      this.setState(obj);
      data.setPersonalData(obj);

      listlang[idx].valchecked = true;
      listlang[idx].slide = true;
      this.setState({
        listforeignLangProficiency: listlang,
      });

      tmp[idx] = !tmp[idx];
      this.setState({
        levellang: tmp,
      });
    } else {
      if (listchek.length === 0) {
        listchek = removeObject(listchek, "languageId", langId.toString());
        listchek.forEach(e => {
          listchek.push({
            languageId: e.languageId,
            proficiency: e.proficiency,
          });
        });
      } else {
        listchek = removeObject(listchek, "languageId", langId.toString());
      }

      obj = { listUserLanguage: listchek };
      this.setState({ listCheckedForeignLang: listchek });
      this.setState(obj);
      data.setPersonalData(obj);

      listlang[idx].valchecked = false;
      listlang[idx].slide = false;
      this.setState({
        listforeignLangProficiency: listlang,
      });

      tmp[idx] = false;
      this.setState({
        levellang: tmp,
      });
    }
    var personalInfo = {
      personalInfo: { ...this.state.biodata.personalInfo, ...obj },
    };
    this.setState({
      biodata: { ...this.state.biodata, ...personalInfo },
    });
  }

  slideChange = (val, langid, idx) => {
    var liststrlevel = this.state.strLevelLang;
    if (val === 1) {
      liststrlevel[idx] = 1;
    } else if (val === 2) {
      liststrlevel[idx] = 2;
    } else {
      liststrlevel[idx] = 3;
    }
    this.setState({ strLevelLang: liststrlevel });

    var listchecklang = this.state.listCheckedForeignLang;

    listchecklang.forEach(e => {
      if (e.languageId === langid) {
        e.proficiency = val;
      }
    });

    var obj = {};
    obj = { listUserLanguage: listchecklang };
    this.setState({ listCheckedForeignLang: listchecklang });
    this.setState(obj);
    data.setPersonalData(obj);

    var personalInfo = {
      personalInfo: { ...this.state.biodata.personalInfo, ...obj },
    };
    this.setState({
      biodata: { ...this.state.biodata, ...personalInfo },
    });
  };

  render() {
    const {
      open,
      listProvince,
      listCity,
      listDistrict,
      listSubDistrict,
      strLevelLang,
      listforeignLangProficiency,
      levellang,
      openValidation,
    } = this.state;
    const { t } = this.props;
    const gender = t("biodata:options.gender", {
      returnObjects: true,
    });
    const maritalStatus = t("biodata:options.CVmaritalStatus", {
      returnObjects: true,
    });
    let stremail = this.state.biodata.personalInfo.email
      ? this.state.biodata.personalInfo.email
      : this.state.email;
    const levelSkillLang = t("biodata:levelSkillLang", { returnObjects: true });

    return (
      <div
        style={{
          fontWeight: "lighter",
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          padding: "0px 50px",
          color: palette.primary,
        }}>
        <span style={{ fontSize: 18, fontWeight: "bolder" }}>
          {t("biodata:CVpersonalInfo")}
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4%",
            width: "87%",
          }}>
          <ValidatorForm onSubmit={this.submitForm}>
            <div>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.firstName")}
                <span
                  style={{ color: "#CD594D" }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <TextValidator
                style={{
                  width: "93%",
                  marginTop: -15,
                }}
                validators={["required"]}
                errorMessages={[t("biodata:validation.required.firstName")]}
                onChange={e => {
                  var val = e.currentTarget.value;
                  nameRegex.test(val)
                    ? this.handleChange({ firstName: val })
                    : e.preventDefault();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("biodata:form.placeholder.firstName")}
                //label={t("biodata:form.field.firstName")}
                value={this.state.biodata.personalInfo.firstName}
                className="Field"
              />
            </div>
            <div>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.middleName")}
              </label>
              <TextField
                style={{
                  width: "93%",
                  marginTop: -15,
                }}
                onChange={e => {
                  var val = e.currentTarget.value;
                  nameRegex.test(val)
                    ? this.handleChange({ middleName: val })
                    : e.preventDefault();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("biodata:form.placeholder.middleName")}
                value={this.state.biodata.personalInfo.middleName}
                className="Field"
              />
            </div>
            <div>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.lastName")}
              </label>
              <TextField
                style={{
                  width: "93%",
                  marginTop: -15,
                }}
                onChange={e => {
                  var val = e.currentTarget.value;
                  nameRegex.test(val)
                    ? this.handleChange({ lastName: val })
                    : e.preventDefault();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("biodata:form.placeholder.lastName")}
                value={this.state.biodata.personalInfo.lastName}
                className="Field"
              />
            </div>
            <Grid
              container
              direction="column"
              justify="flex-start"
              required={true}
              value={this.state.biodata.personalInfo.description}
              maxLength={250}>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.description")}
                <span
                  style={{ color: "#CD594D" }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <TextValidator
                style={{
                  width: "93%",
                }}
                onChange={e => {
                  var val = e.currentTarget.value;
                  this.handleChange(
                    {
                      description: e.currentTarget.value,
                    },
                    val.length
                  );
                }}
                multiline
                rows={5}
                InputLabelProps={{
                  shrink: true,
                }}
                validators={["required", "maxStringLength:250"]}
                errorMessages={[
                  t("biodata:validation.required.required"),
                  t("biodata:validation.valid.minmaxdesc"),
                ]}
                required={true}
                value={this.state.biodata.personalInfo.description}
                placeholder={t("biodata:form.placeholder.description")}
                className="Field"
              />
              <Typography
                style={{
                  color: palette.primary,
                  fontSize: 14,
                  fontWeight: "lighter",
                  textAlign: "right",
                  width: "93%",
                }}>
                {this.state.descriptionCount}/250
              </Typography>
            </Grid>
            <div>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.foreignLangProficiency")}
              </label>
              <Grid
                container
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}>
                <Grid
                  container
                  style={{
                    width: "30%",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 15,
                  }}>
                  {listforeignLangProficiency.map((v, i) => {
                    return (
                      <label
                        key={i}
                        style={{
                          fontSize: 18,
                          color: palette.primary,
                          marginRight: 40,
                          minHeight: 45,
                          marginTop: 15,
                        }}>
                        <Checkbox
                          checked={v.valchecked}
                          onClick={e => {
                            this.onClickCheckedLang(e, v.value, i);
                          }}
                          value={v.value}></Checkbox>
                        {v.label}
                      </label>
                    );
                  })}
                </Grid>
                <Grid
                  container
                  style={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  {listforeignLangProficiency.map((v, i) => {
                    return (
                      <Grid
                        key={i}
                        id="GridSlide"
                        container
                        style={{
                          flexDirection: "column",
                          marginBottom: 3,
                          minHeight: 60,
                        }}>
                        <div
                          style={{
                            display: levellang[i] ? "flex" : "none",
                            flexDirection: "column",
                          }}>
                          <Typography
                            id="level-lang"
                            gutterBottom
                            style={{
                              color: palette.primary,
                              fontWeight: "lighter",
                            }}>
                            Level -{" "}
                            {strLevelLang[i] === 1
                              ? levelSkillLang[0]
                              : strLevelLang[i] === 2
                              ? levelSkillLang[1]
                              : levelSkillLang[2]}
                          </Typography>
                          <Slider
                            onChange={(event, val) =>
                              this.slideChange(val, v.value, i)
                            }
                            defaultValue={v.proficiencybyId}
                            aria-labelledby="discrete-slider"
                            step={1}
                            marks
                            min={1}
                            max={3}
                            style={{ width: "50%", color: "#52C8F4" }}
                          />
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: 15 }}>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.gender")}
                <span
                  style={{ color: "#CD594D" }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <div className="radio">
                <label
                  style={{
                    fontSize: 18,
                    color: palette.primary,
                    marginRight: 40,
                  }}>
                  <input
                    style={{
                      color: "#52C8F4",
                    }}
                    type="radio"
                    options={gender}
                    value={this.state.biodata.personalInfo.gender}
                    checked={this.state.biodata.personalInfo.gender === "M"}
                    onChange={option => {
                      this.handleChange({ gender: "M" });
                    }}
                  />
                  {t("biodata:form.field.male")}
                </label>

                <label
                  style={{
                    fontSize: 18,
                    color: palette.primary,
                    marginRight: 40,
                  }}>
                  <input
                    style={{
                      color: "#52C8F4",
                    }}
                    type="radio"
                    options={gender}
                    value={this.state.biodata.personalInfo.gender}
                    checked={this.state.biodata.personalInfo.gender === "F"}
                    onChange={option => {
                      this.handleChange({ gender: "F" });
                    }}
                  />
                  {t("biodata:form.field.female")}
                </label>
              </div>
            </div>
            <div style={{ marginTop: 30 }}>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.marital")}
                <span
                  style={{ color: "#CD594D" }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <div className="radio">
                <label
                  style={{
                    fontSize: 18,
                    color: palette.primary,
                    marginRight: 40,
                  }}>
                  <input
                    style={{
                      color: "#52C8F4",
                    }}
                    type="radio"
                    options={maritalStatus}
                    value={this.state.biodata.personalInfo.maritalStatus}
                    checked={
                      this.state.biodata.personalInfo.maritalStatus === "single"
                    }
                    onChange={option => {
                      this.handleChange({ maritalStatus: "single" });
                    }}
                  />
                  {t("biodata:form.field.single")}
                </label>

                <label
                  style={{
                    fontSize: 18,
                    color: palette.primary,
                    marginRight: 40,
                  }}>
                  <input
                    style={{
                      color: "#52C8F4",
                    }}
                    type="radio"
                    options={maritalStatus}
                    value={this.state.biodata.personalInfo.maritalStatus}
                    checked={
                      this.state.biodata.personalInfo.maritalStatus ===
                      "married"
                    }
                    onChange={option => {
                      this.handleChange({ maritalStatus: "married" });
                    }}
                  />
                  {t("biodata:form.field.married")}
                </label>

                <label
                  style={{
                    fontSize: 18,
                    color: palette.primary,
                    marginRight: 40,
                  }}>
                  <input
                    style={{
                      color: "#52C8F4",
                    }}
                    type="radio"
                    options={maritalStatus}
                    value={this.state.biodata.personalInfo.maritalStatus}
                    checked={
                      this.state.biodata.personalInfo.maritalStatus ===
                      "widower"
                    }
                    onChange={option => {
                      this.handleChange({ maritalStatus: "widower" });
                    }}
                  />
                  {t("biodata:form.field.widower")}
                </label>
              </div>
            </div>

            <label>
              <br />
            </label>
            <label>
              <br />
            </label>
            <div>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.birthplace")}
                <span
                  style={{ color: "#CD594D" }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <TextValidator
                style={{
                  width: "93%",
                  marginTop: -15,
                }}
                validators={["required"]}
                errorMessages={[t("biodata:validation.required.birthplace")]}
                onChange={e => {
                  var val = e.currentTarget.value;
                  nameRegex.test(val)
                    ? this.handleChange({ birthPlace: val })
                    : e.preventDefault();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={t("biodata:form.placeholder.birthplace")}
                className="Field"
                value={this.state.biodata.personalInfo.birthPlace}
              />
            </div>
            <div>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.birthdate")}
                <span
                  style={{ color: "#CD594D" }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <DatePickerValidator
                validators={["required"]}
                errorMessages={[t("biodata:validation.required.birthDate")]}
                className="Field-date"
                label={""}
                format="dd/MM/yyyy"
                style={{
                  width: "93%",
                  marginTop: -15,
                }}
                value={
                  this.state.biodata.personalInfo.birthDate
                    ? new Date(this.state.biodata.personalInfo.birthDate)
                    : ""
                }
                maxDate={new Date()}
                onChange={e => {
                  this.handleChange({ birthDate: e });
                }}
              />
            </div>
            <div>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.idcardNumber")}
                {/* <span
                  style={{ color: "#CD594D" }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span> */}
              </label>
              <TextValidator
                style={{
                  width: "93%",
                  marginTop: -15,
                }}
                // validators={["required"]}
                // errorMessages={[t("biodata:validation.required.idcardNumber")]}
                onChange={e => {
                  var val = e.currentTarget.value;
                  numberRegex.test(val)
                    ? this.handleChange({ identityNumber: val })
                    : e.preventDefault();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                minLength={10}
                maxLength={16}
                helper={t("biodata:validation.idcardNumber")}
                placeholder={t("biodata:form.placeholder.idcardNumber")}
                value={this.state.biodata.personalInfo.identityNumber}
                className="Field"
              />
            </div>
            <div>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.placeholder.idcard")}
                {/* <span
                  style={{ color: "#CD594D" }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span> */}
              </label>
              <Uploader
                isBlob={false}
                key={1}
                accept="image/png,image/jpg,image/jpeg"
                style={{
                  width: 170,
                  height: 170,
                  fontSize: 12,
                  cursor: "pointer",
                }}
                previewStyle={{ width: 170, height: 170 }}
                labelStyle={{
                  fontSize: 12,
                  textAlign: "center",
                  fontWeight: "lighter",
                }}
                onDropFile={o => {
                  this.handleUploadImage(o, "identityPhoto");
                }}
                value={this.state.biodata.personalInfo.identityPhoto}
              />
            </div>
            <label>
              <br />
            </label>
            <label
              style={{
                color: palette.primary,
                fontSize: 20,
              }}>
              <br /> <br />
              {t("biodata:CVContact")}
              <br /> <br />
            </label>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 14,
                  textTransform: "uppercase",
                  fontWeight: "lighter",
                }}>
                {t("biodata:form.field.email")}
              </label>
              <ReadOnlyField
                style={{ fontSize: 18, width: "93%" }}
                value={stremail}
              />
            </div>
            <Grid
              required={true}
              value={this.state.biodata.personalInfo.phoneNumber}
              style={{ marginTop: 10, width: "91%" }}>
              <Typography
                style={{
                  color: palette.primary,
                  fontWeight: "lighter",
                  textTransform: "uppercase",
                  fontSize: 14,
                }}>
                {t("biodata:form.field.contact")}
                <span
                  style={{ color: "#CD594D" }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </Typography>
              <Grid style={{ display: "flex", flexDirection: "row" }}>
                <label
                  style={{
                    color: "#2E69B2",
                    fontSize: 18,
                    width: 40,
                    marginTop: 7,
                    fontFamily: "Hurme Geometric Sans 3 !important",
                    fontWeight: "bold",
                    padding: "0px 6px 3px",
                    borderBottom: "2px solid " + palette.primary,
                    marginBottom: 20,
                    marginRight: 7,
                  }}>
                  +62
                </label>
                <TextValidator
                  style={{
                    width: "32vw",
                    marginTop: -18,
                  }}
                  validators={["required"]}
                  errorMessages={[t("biodata:validation.required.phoneNumber")]}
                  maxLength="13"
                  onChange={e => {
                    var char = e.currentTarget.value;
                    if (char.split("")[0] === "0") {
                      char = char.slice(1);
                    }
                    numberRegex.test(char)
                      ? this.handleChange({ phoneNumber: char })
                      : e.preventDefault();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("biodata:form.placeholder.contact")}
                  //label={t("biodata:form.field.contact")}
                  value={this.state.biodata.personalInfo.phoneNumber}
                  className="Field"
                />
              </Grid>
            </Grid>
            <div
              style={{
                color: palette.primary,
                cursor: "pointer",
              }}
              onClick={this.handleClickOpen}>
              <div>
                <label
                  style={{
                    color: "#2E69B2",
                    fontSize: 14,
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                  }}>
                  {t("biodata:CVaddress")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 17,
                  marginTop: "1%",
                  borderBottom: "2px solid",
                  width: "91%",
                }}>
                {this.state.biodata.personalInfo.address}
              </div>
            </div>
            <button type="submit" id="button-submit" className="Submit-Btn">
              submit
            </button>
          </ValidatorForm>
          <ConfirmFooter
            text={t("button.save")}
            NextClick={() => {
              this.submitdata();
            }}
          />
        </div>
        <Dialog
          fullScreen
          className={"Custom-Dialog "}
          aria-labelledby="customized-dialog-title"
          disableBackdropClick
          open={open}
          style={{ backgroundColor: "transparent" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              paddingTop: "50px",
            }}>
            <div
              style={{
                width: 700,
                minHeight: 650,
                padding: 30,
                position: "relative",
              }}>
              <button
                onClick={() => {
                  this.handleClose();
                }}
                style={{
                  width: 28,
                  height: 28,
                  color: "#C4C4C4",
                  borderRadius: "50%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  border: "none",
                  cursor: "pointer",
                }}>
                X
              </button>
              <ValidatorForm
                style={{ backgroundColor: "white", borderRadius: 10 }}
                onSubmit={this.submitFormAddress}>
                <DialogTitle
                  id="form-dialog-title"
                  style={{
                    color: palette.primary,
                    borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  }}>
                  {t("biodata:CVaddress")}
                </DialogTitle>
                <DialogContent>
                  <div
                    value={this.state.degree}
                    style={{
                      width: "100%",
                      marginLeft: "1%",
                      marginTop: "3%",
                      fontSize: 20,
                    }}>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      required={true}
                      value={this.state.province}
                      style={{
                        width: "97%",
                        color: palette.primary,
                        marginBottom: 15,
                      }}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                          marginBottom: 15,
                        }}>
                        {t("biodata:form.field.province")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <SelectSearch
                        validators={["selectRequired"]}
                        errorMessages={[
                          t("biodata:validation.required.province"),
                        ]}
                        closeMenuOnSelect
                        key={new Date().getTime()}
                        className="Select-domicile-location"
                        classNamePrefix="select"
                        placeholder={t("biodata:form.placeholder.province")}
                        menuColor={palette.primary}
                        name="province"
                        options={listProvince}
                        value={this.state.province ? this.state.province : " "}
                        onChange={v => {
                          this.onChangeProvince({ provinceId: v.value });
                        }}
                        style={{ color: palette.primary }}
                      />
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      required={true}
                      value={this.state.city}
                      style={{
                        width: "97%",
                        color: palette.primary,
                        marginBottom: 15,
                      }}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                          marginBottom: 15,
                        }}>
                        {t("biodata:form.field.city")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <SelectSearch
                        validators={["selectRequired"]}
                        errorMessages={[t("biodata:validation.required.city")]}
                        closeMenuOnSelect
                        key={new Date().getTime()}
                        className="Select-domicile-location"
                        classNamePrefix="select"
                        placeholder={t("biodata:form.placeholder.city")}
                        menuColor={palette.primary}
                        name="city"
                        options={listCity}
                        value={this.state.city ? this.state.city : " "}
                        onChange={v => {
                          this.onChangeCity({ cityId: v.value });
                        }}
                        style={{ color: palette.primary }}
                      />
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      required={true}
                      value={this.state.district}
                      style={{
                        width: "97%",
                        color: palette.primary,
                        marginBottom: 15,
                      }}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                          marginBottom: 15,
                        }}>
                        {t("biodata:form.field.district")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <SelectSearch
                        validators={["selectRequired"]}
                        errorMessages={[
                          t("biodata:validation.required.district"),
                        ]}
                        closeMenuOnSelect
                        key={new Date().getTime()}
                        className="Select-domicile-location"
                        classNamePrefix="select"
                        placeholder={t("biodata:form.placeholder.district")}
                        menuColor={palette.primary}
                        name="district"
                        options={listDistrict}
                        value={this.state.district ? this.state.district : " "}
                        onChange={v => {
                          this.onChangeDistrict({ districtId: v.value });
                        }}
                        style={{ color: palette.primary }}
                      />
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      required={true}
                      value={this.state.subDistrict}
                      style={{
                        width: "97%",
                        color: palette.primary,
                        marginBottom: 15,
                      }}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                          marginBottom: 15,
                        }}>
                        {t("biodata:form.field.subDistrict")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <SelectSearch
                        validators={["selectRequired"]}
                        errorMessages={[
                          t("biodata:validation.required.subDistrict"),
                        ]}
                        closeMenuOnSelect
                        key={new Date().getTime()}
                        className="Select-domicile-location"
                        classNamePrefix="select"
                        placeholder={t("biodata:form.placeholder.subDistrict")}
                        menuColor={palette.primary}
                        name="subdistrict"
                        options={listSubDistrict}
                        value={
                          this.state.subDistrict ? this.state.subDistrict : " "
                        }
                        onChange={v => {
                          this.onChangeSubDistrict({ subDistrict: v.value });
                        }}
                        style={{ color: palette.primary }}
                      />
                    </Grid>
                    <div>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {t("biodata:form.field.zip")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <TextValidator
                        style={{ width: "97%", fontSize: 18 }}
                        validators={["required"]}
                        errorMessages={[t("biodata:validation.required.zip")]}
                        onChange={e => {
                          var val = e.currentTarget.value;
                          numberRegex.test(val)
                            ? this.handleChange({ zipcode: val })
                            : e.preventDefault();
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.zipcode}
                        placeholder={t("biodata:form.placeholder.zip")}
                        //label={t("biodata:form.field.zip")}
                        className="Field-cfm-cv"
                      />
                    </div>
                    <div>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {t("biodata:form.field.street")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <TextValidator
                        style={{
                          width: "97%",
                          fontSize: 18,
                          color: palette.primary,
                        }}
                        validators={["required"]}
                        errorMessages={[
                          t("biodata:validation.required.street"),
                        ]}
                        onChange={e => {
                          this.handleChange({ street: e.currentTarget.value });
                        }}
                        multiline
                        rows={3}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.street}
                        placeholder={t("biodata:form.placeholder.street")}
                        //label={t("biodata:form.field.street")}
                        className="Field"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: 180,
                      }}>
                      <TextField
                        autoFocus={true}
                        onChange={e => {
                          this.handleRTRW(e)
                            ? this.handleChange({ rt: this.handleRTRW(e) })
                            : e.preventDefault();
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.rt ? this.state.rt : "000"}
                        placeholder={"000"}
                        label={"RT/RW"}
                        style={{ width: 50 }}
                        className="Field-cfm-cv-rtrw rtrw"
                      />
                      <span
                        style={{
                          fontSize: 24,
                          borderBottom: "2px solid " + palette.primary,
                          color: palette.primary,
                          padding: "0px 10px 1px 3px",
                        }}>
                        /
                      </span>
                      <TextField
                        onChange={e => {
                          this.handleRTRW(e)
                            ? this.handleChange({ rw: this.handleRTRW(e) })
                            : e.preventDefault();
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.rw ? this.state.rw : "000"}
                        placeholder={"000"}
                        label={""}
                        style={{ width: 50, marginTop: 15 }}
                        className="Field-cfm-cv-rtrw rtrw"
                      />
                    </div>
                  </div>
                </DialogContent>
                <DialogActions
                  style={{
                    backgroundColor: palette.primary,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        onClick={this.handleClose}
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {t("biodata:CVbtnCancel")}
                      </Button>
                    </div>
                    <div style={{ width: "76%" }}></div>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        onClick={() => this.handleClickAddress()}
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {t("biodata:CVbtnEdit")}
                      </Button>
                    </div>
                  </div>
                </DialogActions>
                <button
                  type="submit"
                  id="button-submit-address"
                  className="Submit-Btn">
                  submit
                </button>
              </ValidatorForm>
            </div>
          </div>
        </Dialog>
        <DialogueInfo
          title={t("biodata:validation.dialog.title")}
          content={t("biodata:validation.dialog.content")}
          open={openValidation}
          onClose={() => {
            this.setState({ openValidation: false });
          }}
        />
      </div>
    );
  }
}
export default withTranslation()(ConfirmPersonalInfo);
