import React from "react";
import PropTypes from "prop-types";
const { default: ReactPaginate } = require("react-paginate");

/**
 * @extends {React.Component<{totalCount:number,pageLimit:number, pageDisplay:number, onChange:Function}>}
 */
export default class HgPagination extends React.Component {
  state = { alreadyMount: false };
  static defaultProps = {
    totalCount: 0,
    pageDisplay: 3,
    pageLimit: 10,
    onChange: e => {
      console.log("Selected number", e);
    },
  };
  static propTypes = {
    totalCount: PropTypes.number,
    pageDisplay: PropTypes.number,
    pageLimit: PropTypes.number,
    onChange: PropTypes.func,
  };

  changePage = () => {};

  render() {
    const { totalCount, pageDisplay, pageLimit } = this.props;
    const { alreadyMount } = this.state;
    return (
      <>
        {totalCount ? (
          <div className={"Pagination"}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              initialPage={0}
              pageRangeDisplayed={pageDisplay}
              pageCount={Math.ceil(totalCount / pageLimit)}
              onPageChange={e => {
                if (alreadyMount) {
                  this.props.onChange(e.selected + 1);
                } else {
                  this.setState({ alreadyMount: true });
                }
              }}
            />
          </div>
        ) : null}
      </>
    );
  }
}
