import React from "react";
import { palette } from "assets/css/main";
import { DialogueFull } from "app/components";
import { withTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import { RequestPost } from "app/utils";
import { IcnSpeakerConfirmation } from "assets/img";

class DialogueOfferingConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      stateopen: this.props.open,
      isDisabled: true,
      VOfferingConfirm: "",
      error: "",
    };
  }

  submitOfferingConfirm(vId) {
    this.setState({ error: "" });
    const { onClose } = this.props;
    var vacancyId = vId;
    var offeringCon = this.state.VOfferingConfirm;
    var isAccept = null;
    var isDiscussOffer = null;

    if (offeringCon === "Accept") {
      isAccept = true;
      isDiscussOffer = false;
    } else if (offeringCon === "Declined") {
      isAccept = false;
      isDiscussOffer = false;
    } else {
      isAccept = null;
      isDiscussOffer = true;
    }

    RequestPost("Vacancy/AcceptOffer", {
      vacancyId,
      isAccept,
      isDiscussOffer,
      loading: true,
    })
      .then(() => {
        onClose(true, offeringCon);
      })
      .catch(e => {
        console.log("submitOfferingConfirm()", e);
        this.setState({ error: "Error : " + e.status });
      });
  }

  render() {
    const { open, onClose, vacId, t } = this.props;
    const { isLoading, isDisabled } = this.state;
    const OfferingConfirm = t("job-opening:options.offeringConfirm", {
      returnObjects: true,
    });
    return (
      <DialogueFull
        open={open}
        onClose={() => {
          onClose(false);
          this.setState({ VOfferingConfirm: "", isDisabled: true });
        }}
        style={{
          width: 590,
        }}>
        {isLoading ? (
          <Grid
            container
            justify="center"
            alignItems="flex-end"
            style={{
              backgroundColor: "transparent",
            }}>
            <div
              style={{
                backgroundColor: "white",
                boxSizing: "border-box",
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
                borderBottomLeftRadius: 25,
                borderBottomRightRadius: 25,
              }}>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  width: 140,
                  height: 140,
                }}>
                <img
                  src={IcnSpeakerConfirmation}
                  width={129}
                  height={129}
                  style={{ position: "relative", left: 230, bottom: 35 }}
                  alt="Info"
                />
              </div>
              <div
                style={{
                  height: 20,
                  backgroundColor: "white",
                  padding: "67px 40px 40px",
                  width: 508,
                  color: palette.primary,
                  fontSize: 28,
                  border: "1px solid #E2E5E7",
                  textAlign: "center",
                  borderTopLeftRadius: 25,
                  borderTopRightRadius: 25,
                }}>
                {t("job-opening:OfferingConfirmationDialog.title")}
              </div>
              <div
                style={{
                  padding: "20px 60px",
                  height: "100%",
                }}>
                <div style={{}}>
                  <p
                    style={{
                      marginBottom: 5,
                      fontSize: 24,
                      color: palette.primary,
                    }}>
                    {t("job-opening:OfferingConfirmationDialog.content")}
                  </p>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    className="radio"
                    style={{
                      padding: "15px 0px 10px",
                      display: "flex",
                      flexDirection: "column",
                    }}>
                    <label
                      style={{
                        fontSize: 18,
                        color: palette.primary,
                        marginBottom: 20,
                      }}>
                      <input
                        style={{
                          color: palette.primary,
                          marginRight: 10,
                        }}
                        type="radio"
                        options={OfferingConfirm}
                        value={this.state.VOfferingConfirm}
                        checked={this.state.VOfferingConfirm === "Accept"}
                        onChange={option => {
                          this.setState({
                            VOfferingConfirm: "Accept",
                            isDisabled: false,
                          });
                        }}
                      />
                      {t("job-opening:OfferingConfirmationDialog.accept")}
                    </label>

                    <label
                      style={{
                        fontSize: 18,
                        color: palette.primary,
                        marginBottom: 20,
                      }}>
                      <input
                        style={{
                          color: palette.primary,
                          marginRight: 10,
                        }}
                        type="radio"
                        options={OfferingConfirm}
                        value={this.state.VOfferingConfirm}
                        checked={this.state.VOfferingConfirm === "Declined"}
                        onChange={option => {
                          this.setState({
                            VOfferingConfirm: "Declined",
                            isDisabled: false,
                          });
                        }}
                      />
                      {t("job-opening:OfferingConfirmationDialog.declined")}
                    </label>

                    <label
                      style={{
                        fontSize: 18,
                        color: palette.primary,
                        marginBottom: 20,
                      }}>
                      <input
                        style={{
                          color: palette.primary,
                          marginRight: 10,
                        }}
                        type="radio"
                        options={OfferingConfirm}
                        value={this.state.VOfferingConfirm}
                        checked={this.state.VOfferingConfirm === "Discussion"}
                        onChange={option => {
                          this.setState({
                            VOfferingConfirm: "Discussion",
                            isDisabled: false,
                          });
                        }}
                      />
                      {t("job-opening:OfferingConfirmationDialog.discussion")}
                    </label>
                  </div>
                </div>
                <label
                  style={{ width: "100%", marginBottom: 20, color: "red" }}>
                  {this.state.error}
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  bottom: 0,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: palette.primary,
                  boxSizing: "border-box",
                  width: "100%",
                  borderBottomLeftRadius: 25,
                  borderBottomRightRadius: 25,
                  boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                  padding: "20px 50px",
                  opacity: !isDisabled ? 1 : 0.5,
                }}>
                <Button
                  onClick={() => {
                    this.submitOfferingConfirm(vacId);
                  }}
                  disabled={isDisabled}
                  style={{
                    backgroundColor: palette.primary,
                    opacity: !isDisabled ? 1 : 0.5,
                    color: "white",
                    padding: "8px 50px",
                    textTransform: "none",
                    textDecorationLine: "underline",
                    fontSize: 24,
                  }}>
                  Submit
                </Button>
              </div>
            </div>
          </Grid>
        ) : (
          <div></div>
        )}
      </DialogueFull>
    );
  }
}
export default withTranslation("job-opening")(DialogueOfferingConfirm);
