import React from "react";
import { palette } from "assets/css/main";
import { getItem, findObject } from "app/utils";
import moment from "moment";
const Dot = () => {
  return (
    <span
      style={{
        height: 12,
        width: 12,
        marginRight: 10,
        backgroundColor: palette.primary,
        borderRadius: "50%",
        display: "inline-block",
      }}></span>
  );
};
export default class ViewWorkPrefrences extends React.Component {
  render() {
    const { dataQuestion, data } = this.props;
    return (
      <>
        {dataQuestion.map((v, i) => {
          return (
            <div
              key={i + "display"}
              style={{
                display: "flex",
                flexDirection: "row",
                color: palette.primary,
                width: 900,
                boxSizing: "border-box",
                padding: 20,
              }}>
              <span
                style={{
                  backgroundColor: palette.darkGrey,
                  color: "white",
                  borderRadius: "50%",
                  padding: "7px 12.2px",
                  height: 17,
                }}>
                {i + 1}
              </span>
              <div
                style={{
                  padding: "7px 15px",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <span style={{ fontWeight: "lighter" }}>
                  {getItem("lang") === "en"
                    ? v.questionTextEng
                    : v.questionTextIna}
                </span>
                {data[i].responseAnswers.map((vx, ix) => {
                  var workAnswers = findObject(
                    v.workAnswers,
                    "answerId",
                    vx.answerId
                  )
                    ? findObject(v.workAnswers, "answerId", vx.answerId)
                    : { answerTextEng: "" };
                  var txt = "";
                  var capitalize = false;

                  if (vx.answerId === "253251ca-bf29-4624-9e6b-4b7dd120dbb1") {
                    txt =
                      getItem("lang") === "en"
                        ? "Any location in indonesia"
                        : "Dimana saja di indonesia";
                  } else if (
                    vx.answerId === "8f0b80cb-e2ad-4bff-beaf-817b1b3a9b61" ||
                    vx.answerId === "0aafdc9d-6b1f-4a70-850e-ec40a6f06e3d"
                  ) {
                    console.log();
                    txt =
                      getItem("lang") === "en"
                        ? workAnswers.answerTextEng
                        : workAnswers.answerTextIna;
                    txt += ": " + moment(vx.otherValue).format("DD/MM/yyyy");
                  } else if (workAnswers.answerId.length < 36) {
                    txt =
                      getItem("lang") === "en"
                        ? workAnswers.answerTextEng.toLowerCase()
                        : workAnswers.answerTextIna.toLowerCase();
                    capitalize = true;
                  } else {
                    txt =
                      getItem("lang") === "en"
                        ? workAnswers.answerTextEng
                        : workAnswers.answerTextIna;
                  }
                  return (
                    <div key={"details" + ix}>
                      <div
                        key={"answer-" + ix}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          padding: "5px 0px",
                        }}>
                        <Dot />
                        <span
                          style={{
                            paddingTop: 2,
                            textTransform: capitalize ? "capitalize" : "",
                          }}>
                          {txt}
                        </span>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {vx.answerDetails
                          ? vx.answerDetails.map((vd, id) => {
                              var answerDetail = findObject(
                                workAnswers.answerDetails,
                                "answerId",
                                vd.answerId
                              )
                                ? findObject(
                                    workAnswers.answerDetails,
                                    "answerId",
                                    vd.answerId
                                  )
                                : {};
                              return (
                                <span
                                  key={"detail-" + id}
                                  style={{
                                    padding: "5px 22px",
                                    fontWeight: "lighter",
                                  }}>
                                  {"- "}
                                  {getItem("lang") === "en"
                                    ? answerDetail.answerTextEng
                                    : answerDetail.answerTextIna}
                                </span>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  }
}
