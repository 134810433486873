import { Button, Checkbox, Tooltip } from "@material-ui/core";
import { Help, MoreHoriz } from "@material-ui/icons";
import { ButtonFilterStatus } from "app/components";
import { findObject, getItem, t } from "app/utils";
import React from "react";
import PropTypes from "prop-types";
import { palette } from "assets/css/main";
import { IcnTrashSmall } from "assets/img";

import { withStyles } from "@material-ui/core/styles";
const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#2F3168",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
/**
 * @extends {React.Component<{status:string, candidates:array, arrcheck:array, onOpenProximity:Function, onClickSelect:Function, arrwish:array,onClickCheckedAll:Function,onClickDelete:Function}>}
 */
export default class WishlistTableView extends React.Component {
  static defaultProps = {
    status: "WIS",
    candidates: [],
    arrcheck: [],
    arrwish: [],
    onOpenProximity: () => {},
    onClickSelect: () => {},
    onClickCheckedAll: () => {},
    onClickDelete: () => {},
    onPageChange: () => {},
    totalData: 0,
  };
  static propTypes = {
    status: PropTypes.string,
    candidates: PropTypes.array,
    arrcheck: PropTypes.array,
    onOpenProximity: PropTypes.func,
    onClickSelect: PropTypes.func,
    arrwish: PropTypes.array,
    onClickCheckedAll: PropTypes.func,
    onClickDelete: PropTypes.func,
  };

  openProximity = value => {
    this.props.onOpenProximity(value);
  };
  clickSelect = (x, y, z) => {
    this.props.onClickSelect(x, y, z);
  };
  clickCheckedAll = () => {
    this.props.onClickCheckedAll();
  };
  clickDelete = userId => {
    this.props.onClickDelete(userId);
  };

  render() {
    const { candidates, arrcheck, status, checkedAll } = this.props;
    const gender = t("biodata:options.gender", {
      returnObjects: true,
    });
    return (
      <>
        <table
          cellPadding={8}
          style={{
            backgroundColor: "white",
            position: "fixed",
            zIndex: 1,
            borderTop: "1px solid " + palette.primary,
            paddingTop: 10,
            color: "grey",
            width: "100%",
            padding: "0px 100px",
            boxShadow: "rgb(0 0 0 / 9%) 1px 2px 4px",
          }}>
          <thead>
            <tr>
              <th width={"10%"}></th>
              <th align="left" width={"10%"}>
                <div>
                  No. ID
                  <span>{t("candidates:headerTableName.gender")}</span>
                </div>
              </th>
              <th align="left" width={"10%"}>
                {t("candidates:headerTableName.levelMajor")}
                <span>{t("candidates:headerTableName.universityName")}</span>
              </th>
              <th align="left" width={"10%"}>
                {t("candidates:headerTableName.GPA")}
                <span>{t("candidates:headerTableName.graduated")}</span>
              </th>
              <th align="left" width={"15%"}>
                {t("candidates:headerTableName.domicile")}
                <span>{t("candidates:headerTableName.lengthofWork")}</span>
              </th>
              <th align="center" width={"25%"}>
                {t("candidates:headerTableName.recruitmentStatus")}
              </th>
              <th align="center" width={"10%"}>
                {t("candidates:headerTableName.proximity")}
                <LightTooltip
                  title={t("candidates:tooltipmessages.proximity")}
                  arrow>
                  <Help
                    style={{
                      fontSize: 20,
                      color: "#2F3168",
                    }}
                  />
                </LightTooltip>
              </th>
              <th align="center">
                {t("candidates:headerTableName.selectAll")}
                <div>
                  <Checkbox
                    checked={checkedAll}
                    onClick={() => {
                      this.clickCheckedAll();
                    }}></Checkbox>
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <div style={{ padding: "110px 100px 30px" }}>
          {candidates.map((v, k) => {
            return (
              <table
                cellPadding={8}
                style={{
                  backgroundColor: "white",
                  boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                  borderRadius: 4,
                  marginTop: 10,
                  width: "100%",
                }}
                // className="Candidates"
                key={"list-selected" + k}>
                <tbody>
                  <tr>
                    <td width="10%" valign="middle">
                      <div
                        style={{
                          width: 74,
                          height: 74,
                          borderRadius: "50%",
                          backgroundColor: "grey",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundImage: v.photo
                            ? "url(" + v.photo + ")"
                            : "",
                        }}></div>
                    </td>
                    <td width="10%">
                      <p style={{ margin: 0, paddingTop: 10 }}>
                        {v.candidateCode}
                        <span style={{ textTransform: "capitalize" }}>
                          <label>
                            {findObject(gender, "value", v.gender, false).label}
                          </label>
                        </span>
                      </p>
                    </td>
                    <td width="10%" align="left" valign="baseline">
                      {v.degree} ({v.major})<span>{v.institution}</span>
                    </td>
                    <td width="10%" align="left" valign="baseline">
                      <div style={{ position: "relative" }}>
                        {v.grade}
                        <span>{v.yearGraduate}</span>
                      </div>
                    </td>
                    <td width="15%" align="left" valign="baseline">
                      <p
                        style={{
                          margin: 0,
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}>
                        {v.domicile}
                      </p>
                      <span>
                        {v.lengthofWork} {t("candidates:dataTableName.year")}
                      </span>
                    </td>
                    <td width="25%" align="left" valign="baseline">
                      <div
                        style={{
                          display: "grid",
                          justifyContent: "center",
                          gridTemplateColumns: "auto auto",
                        }}>
                        {v.marketStatus.map((r, i) => {
                          if (r.count > 0) {
                            return (
                              <div key={i}>
                                <ButtonFilterStatus
                                  className={"Status"}
                                  disabled={true}
                                  onClick={() => {}}
                                  style={{
                                    height: 27,
                                    color: "#FFB031",
                                    border: "none",
                                    backgroundColor: "rgba(255, 176, 49, 0.3)",
                                    fontWeight: "normal",
                                    textTransform: "lowercase",
                                    opacity: 3,
                                    margin: 5,
                                  }}
                                  index={1}
                                  active={1}
                                  text={
                                    getItem("lang") === "en"
                                      ? r.count + " " + r.textEng
                                      : r.count + " " + r.textIna
                                  }
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                    </td>
                    <td width="10%" align="center" valign="middle">
                      <div style={{ position: "relative" }}>
                        <Button
                          className={"Proximity"}
                          onClick={() => {
                            this.openProximity({
                              openDialogProximity: true,
                              userId: v.userId,
                            });
                          }}
                          style={{
                            color: "rgb(46, 105, 178)",
                            fontSize: 14,
                            height: 27,
                            padding: "0 15",
                            textTransform: "lowercase",
                            background: "rgb(46, 105, 178, 0.3)",
                            border: "none",
                            borderRadius: 20,
                            opacity: 1,
                            fontWeight: "normal",
                          }}>
                          {v.proximity} %
                        </Button>
                      </div>
                    </td>

                    <td align="center" valign="middle">
                      <div
                        style={{
                          width: 70,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}>
                        {findObject(v.marketStatus, "status", "HIR", false)
                          .count === 0 && (
                          <Checkbox
                            checked={arrcheck[k]}
                            onClick={() => {
                              this.clickSelect(k, v.userId, status);
                            }}>
                            <MoreHoriz style={{ color: "grey" }} />
                          </Checkbox>
                        )}
                        <img
                          onClick={e => {
                            this.clickDelete(v.userId);
                          }}
                          style={{
                            marginRight: 5,
                            marginBottom: -3,
                            display: "unset",
                            cursor: "pointer",
                          }}
                          src={IcnTrashSmall}
                          alt="Trash Icon"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
        </div>
      </>
    );
  }
}
