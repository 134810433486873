import React from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  // Menu,
  // MenuItem,
} from "@material-ui/core";
import { palette } from "assets/css/main";
import PropTypes from "prop-types";
import PersonalInfo from "./personal-info";
import { RequestGet, getItem, AppHistory, RequestPost } from "app/utils";
import { withTranslation } from "react-i18next";
import CVFormalEducation from "./cv-formal-education";
import CVInformalEducation from "./cv-informal-education";
import CVOrganizationExperience from "./cv-organization-experience";
import CVWorkExperience from "./cv-work-experience";
// import { MoreVert } from "@material-ui/icons";
import TemplateBlue from "../select-template/template-blue";
import TemplatePurple from "../select-template/template-purple";
import { pdf } from "@react-pdf/renderer";
import alertData from "app/components/alert/alert-data";
import data from "app/pages/applicant/initialize/bio-data/data";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class CVPanel extends React.Component {
  state = {
    content: <div></div>,
  };
  blob = null;
  blobURL = "#";

  constructor(props) {
    super(props);
    this.state = {
      personalInfo: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
      },
      ...JSON.parse(getItem("user_info")),
      alreadyGet: false,
      anchorEl: null,
      loading: true,
      activeTab: [],
      cvTemplate: null,
      value: 0,
      biodata: {
        personalInfo: {
          firstName: "",
          middleName: "",
          lastName: "",
          gender: "",
          birthPlace: "",
          birthDate: "",
          maritalStatus: "",
          identityNumber: "",
          identityPhoto: "",
          email: "",
          phoneNumber: "",
          address: "",
          street: "",
        },
        listFormalEducation: [],
        listInformalEducation: [],
        listOrganization: [],
        listWorks: [],
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  componentDidMount() {
    this.getBiodata();
  }

  getBiodata() {
    const { t } = this.props;
    const maritalStatus = t("biodata:options.maritalStatus", {
      returnObjects: true,
    });
    const jobStatus = t("biodata:options.jobStatus", {
      returnObjects: true,
    });
    RequestGet("biodata", { userId: getItem("user_id") })
      .then(res => {
        res.data.listWorks.sort(function (a, b) {
          return b.stillWorking - a.stillWorking;
        });
        res.data.listFormalEducation.sort(function (a, b) {
          return b.yearGraduate - a.yearGraduate;
        });
        res.data.listInformalEducation.sort(function (a, b) {
          return b.year - a.year;
        });
        res.data.listOrganization.sort(function (a, b) {
          return b.year - a.year;
        });
        return res.data;
      })
      .then(dt => {
        let splitAddress = dt.personalInfo.address.split(',');
        this.setState(prev => ({
          ...prev,
          ...dt,
          ...{ maritalStatus: maritalStatus, jobStatus: jobStatus },
        }));
        data.personalInfo = dt.personalInfo;
        data.personalInfo.street = splitAddress[0];
        data.listFormalEducation = dt.listFormalEducation;
        data.listInformalEducation = dt.listInformalEducation;
        data.listOrganization = dt.listOrganization;
        data.listWorks = dt.listWorks;
        this.setState({ biodata: dt, alreadyGet: true }, () => {
          this.setTemplate(/** mustbe key */);
        });
      })
      .then();
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  sendToEmail() {
    this.handleClose();
    const { email } = this.state;
    var d = new FormData();
    d.append("email", email);
    d.append("file", this.blob, "cv.pdf");
    RequestPost("sendemail", d)
      .then(r => {
        alertData.show("Success send email");
      })
      .catch(e => {
        alertData.show("Failed send email", "error");
      });
  }

  setTemplate(key) {
    const { t } = this.props;
    this.setState({ loading: true });
    var template = null;
    if (key === "template-blue") {
      template = <TemplateBlue t={t} data={this.state} />;
    } else if (key === "template-purple") {
      template = <TemplatePurple t={t} data={this.state} />;
    } else {
      template = <TemplateBlue t={t} data={this.state} />;
    }
    pdf(template)
      .toBlob()
      .then(x => {
        this.blob = x;
        this.blobURL = window.URL.createObjectURL(x);
        this.setState({ cvTemplate: template }, () => {
          this.setState({ loading: false });
        });
      });
  }
  updateData = (newData) => {
    this.setState({ biodata: newData });
  };

  render() {
    const { value, biodata, alreadyGet } = this.state;
    const { t } = this.props;
    console.log(biodata,"data")
    return (
      <div className="EditCV">
        <div
          style={{
            height: 23,
            padding: "20px 20px",
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "#2E69B2",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            color: "white",
            fontSize: 24,
          }}>
          CV

          {this.props.edit !== undefined && (
            <Button
            style={{
              textTransform: "none",
              color: "white",
              border: "1px solid white",
              padding: "2px 10px",
            }}
              onClick={() => {
                AppHistory.push("/user/cv/edit");
              }}>
              {t("button.edit")}
            </Button>
          )}
          {this.props.edit === undefined && (
            <Button
            style={{
              textTransform: "none",
              color: "white",
              border: "1px solid white",
              padding: "2px 10px",
            }}
              onClick={() => {
                AppHistory.push("/user/home");
              }}>
              {t("button.cancel")}
            </Button>
          )}
          {/* take out feature in 01/11/2023 */}

          {/* <div>
            <Button
              style={{
                textTransform: "none",
                color: "white",
                border: "1px solid white",
                padding: "2px 10px",
              }}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={this.handleClick}>
              <MoreVert />
              {t("link.more")}
            </Button>
            <Menu
              style={{ color: palette.primary, marginTop: 50 }}
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}>
              {this.props.edit !== undefined && (
                <MenuItem
                  style={{ color: palette.primary }}
                  onClick={() => {
                    AppHistory.push("/user/cv/edit");
                  }}>
                  {t("button.edit")}
                </MenuItem>
              )}
              {this.props.edit === undefined && (
                <MenuItem
                  style={{ color: palette.primary }}
                  onClick={() => {
                    AppHistory.push("/user/home");
                  }}>
                  {t("button.cancel")}
                </MenuItem>
              )}
              <MenuItem
                style={{ color: palette.primary }}
                onClick={() => {
                  AppHistory.push("/user/select-template");
                }}>
                {t("button.selectTemplate")}
              </MenuItem>
              <MenuItem style={{ color: palette.primary }} onClick={() => {}}>
                {!loading && (
                  <>
                    <a
                      style={{ color: palette.primary }}
                      href={this.blobURL}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      rel="noopener noreferer">
                      {t("button.downloadCV")}
                    </a>
                  </>
                )}
                {loading && <>Loading...</>}
              </MenuItem>
              <MenuItem
                style={{ color: palette.primary }}
                onClick={() => {
                  this.sendToEmail();
                }}>
                {t("button.sendEmail")}
              </MenuItem>
            </Menu>
          </div> */}
        </div>
        <div
          style={{
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
            fontSize: 24,
          }}>
          <div
            style={{
              width: "100%",
              padding: "0px 10px",
              boxSizing: "border-box",
            }}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: palette.primary,
                },
              }}
              className="CVTab"
              value={value}
              onChange={this.handleChange}
              style={{
                color: palette.primary,
                fontWeight: "bolder",
                width: "100%",
                fontSize: 15,
                display: "flex",
                justifyContent: "center",
              }}>
              <Tab
                label={t("biodata:CVpersonalInfo")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVformalEducation")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVinformalEducation")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVorganizationalExperience")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
              <Tab
                label={t("biodata:CVworkExperience")}
                style={{
                  width: "20%",
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  textTransform: "none",
                  fontSize: 15,
                }}
              />
            </Tabs>

            {alreadyGet ? (
              <div>
                <TabPanel value={value} index={0} className="Tab-preview">
                  <PersonalInfo
                    data={biodata}
                    edit={
                      this.props.edit !== undefined ? this.props.edit : true
                    }
                  />
                </TabPanel>
                <TabPanel value={value} index={1} className="Tab-preview">
                  <CVFormalEducation
                    data={biodata}
                    edit={
                      this.props.edit !== undefined ? this.props.edit : true
                    }
                    updateData={this.updateData}
                  />
                </TabPanel>
                <TabPanel value={value} index={2} className="Tab-preview">
                  <CVInformalEducation
                    data={biodata}
                    edit={
                      this.props.edit !== undefined ? this.props.edit : true
                    }
                    updateData={this.updateData}
                  />
                </TabPanel>
                <TabPanel value={value} index={3} className="Tab-preview">
                  <CVOrganizationExperience
                    data={biodata}
                    edit={
                      this.props.edit !== undefined ? this.props.edit : true
                    }
                    updateData={this.updateData}
                  />
                </TabPanel>
                <TabPanel value={value} index={4} className="Tab-preview">
                  <CVWorkExperience
                    data={biodata}
                    edit={
                      this.props.edit !== undefined ? this.props.edit : true
                    }
                    updateData={this.updateData}
                  />
                </TabPanel>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(CVPanel);
