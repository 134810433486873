import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography, TextField } from "@material-ui/core";
import { palette } from "assets/css/main";
import data from "./data";
import WizardForm from "app/components/typeform/WizardForm";
import { DialogueInfo, Options } from "app/components";
import "./custom.css";
import {
  getItem,
  RequestPost,
  General,
  AppHistory,
  findDuplicate,
} from "app/utils";
import HgDatePicker from "app/components/general/HgDatePicker";
// import { logError } from "app/utils/general/rollbar";

class Organization extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...data.organization,
      user: JSON.parse(getItem("user_info")),
      key: new Date().getTime(),
      tmpYear: undefined,
      showDuplicate: false,
      showFailed: false,
    };
    General.visible = false;
    data.stepper.footer = false;
    data.organization.userId = getItem("user_id");
  }
  handleNext(isLast, required) {
    console.log(required);
    if (!required && isLast) {
      data.stepper.footer = isLast;
      data.stepper.nextBtn = isLast;
    }
    if (isLast) {
      this.setState({ endStepReq: true });
    }
  }
  handleBack() {
    this.setState({ endStepReq: false });
    data.stepper.footer = false;
    data.stepper.nextBtn = false;
  }
  handleChange(obj) {
    this.setState(obj);
    data.setOrganization(obj);
    var e = document.getElementById("error-messages");
    var el = document.getElementsByClassName("MuiInput-underline");
    var label = document.getElementsByClassName("MuiInputLabel-root");
    try {
      e.innerText = "";
      document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
      label[0].classList.remove("Mui-error");
      el[0].classList.remove("Mui-error");
    } catch (error) {}
    if (this.state.endStepReq) {
      data.stepper.footer = true;
      data.stepper.nextBtn = true;
    }
  }

  submit(isSkip = false, init = true) {
    data.personalInfo = null;
    data.listFormalEducation = [];
    data.listWorks = [];
    data.organization.year = parseInt(data.organization.year);
    data.organization.position = data.organization.position.trim();

    if (init) {
      data.listOrganization.push(data.organization);
    }

    if (findDuplicate(JSON.parse(JSON.stringify(data.listOrganization)))) {
      this.setState({ showDuplicate: true });
    } else {
      if (isSkip) {
        data.listOrganization = null;
      }
      RequestPost("biodata", data)
        .then(() => {
          data.listOrganization = [];
          window.location.reload();
        })
        .catch(e => {
          // logError("organization :" + e);
          this.setState({ showFailed: true });
        });
    }
  }

  componentDidMount() {
    var a = document.getElementById("next-footer");
    if (data.listOrganization.length > 0) {
      a.click();
    }
  }

  addOrganization() {
    this.setState({
      key: new Date().getTime(),
      reset: true,
      tmpYear: undefined,
    });
    data.organization.year = parseInt(data.organization.year);
    data.organization.position = data.organization.position.trim();
    data.listOrganization.push(data.organization);
    console.log(JSON.stringify(data.listOrganization));
    data.setOrganization({
      name: "",
      field: "",
      year: 0,
      position: " ",
      function: "",
    });
    AppHistory.push(
      "/initialize/biodata/addorganization" + data.listOrganization.length
    );
  }

  render() {
    const { t } = this.props;
    const positions = t("biodata:options.organizationPosition", {
      returnObjects: true,
    });
    const isHaveOrgExp = t("biodata:options.yesNo", {
      returnObjects: true,
    });

    const { showDuplicate, showFailed } = this.state;

    return (
      <Grid container style={{ padding: "30px 0px", width: "100vw" }}>
        <DialogueInfo
          title={t("biodata:duplicate.title")}
          content={t("biodata:duplicate.content")}
          open={showDuplicate}
          onClose={() => {
            this.setState({ showDuplicate: false }, () => {
              window.location.reload();
            });
          }}
        />
        <DialogueInfo
          title={t("biodata:failed.title")}
          content={t("biodata:failed.content")}
          open={showFailed}
          onClose={() => {
            this.setState({ showFailed: false }, () => {
              this.submit(false, false);
            });
          }}
        />
        <Typography
          style={{
            padding: "0px 25%",
            color: palette.primary,
            fontWeight: "bold",
            width: 400,
          }}>
          {t("biodata:step.organizationalExperience")}
        </Typography>
        <WizardForm
          reset={this.state.reset}
          onReset={bool => {
            this.setState({ reset: bool });
          }}
          onSubmit={isSkip => {
            this.submit(isSkip);
          }}>
          <Options
            skipAble={true}
            conditionValue={"n"}
            required={true}
            label={t("biodata:form.field.organizationExperience")}
            onChange={e => {
              this.handleChange({ isHaveOrgExp: e.value });
            }}
            options={isHaveOrgExp}
            value={this.state.isHaveOrgExp}
          />
          <TextField
            autoFocus={true}
            required={true}
            onChange={e => {
              this.handleChange({ name: e.currentTarget.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            maxLength={50}
            placeholder={t("biodata:form.placeholder.organizationName")}
            label={t("biodata:form.field.organizationName")}
            className="Field"
            value={this.state.name}
          />
          <TextField
            autoFocus={true}
            required={true}
            maxLength={50}
            onChange={e => {
              this.handleChange({ field: e.currentTarget.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={t("biodata:form.placeholder.organizationArea")}
            label={t("biodata:form.field.organizationArea")}
            className="Field"
            value={this.state.field}
          />
          <HgDatePicker
            className="one"
            autoFocus={true}
            minDate={new Date("1950-01-01")}
            maxDate={new Date()}
            label={t("biodata:form.field.year")}
            format="yyyy"
            maxDetail="decade"
            value={this.state.tmpYear}
            onChange={e => {
              if (e) {
                General.visible = true;
                this.handleChange({
                  tmpYear: e,
                  year: e.getFullYear(),
                });
              }
            }}
            yearPlaceholder={t("biodata:form.placeholder.year")}
          />
          <div
            required={true}
            value={
              this.state.position === "Committee"
                ? this.state.function
                : this.state.position
            }>
            <Options
              required={true}
              label={t("biodata:form.field.organizationPosition")}
              onChange={e => {
                this.handleChange({ position: e.value });
                this.handleChange({ function: "" });
              }}
              options={positions}
              value={this.state.position}
            />
            <div
              value={this.state.function}
              maxLength={50}
              label={t("biodata:form.field.organizationPosition")}
              style={{
                marginLeft: 60,
                display: this.state.position === "Committee" ? "" : "none",
              }}>
              <TextField
                autoFocus={true}
                onChange={e => {
                  this.handleChange({ function: e.currentTarget.value });
                }}
                placeholder={t("biodata:form.placeholder.function")}
                className="Field"
                value={this.state.function}
              />

              <div
                id="custom-error"
                style={{ color: palette.danger, paddingBottom: 20 }}></div>
            </div>
            {this.state.position === "Committee" && this.state.function ? (
              <div
                onClick={() => this.addOrganization()}
                style={{
                  color: palette.primary,
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginTop: 95,
                  position: "absolute",
                  cursor: "pointer",
                }}>
                {t("biodata:addOrganization")}
              </div>
            ) : (
              ""
            )}
            {this.state.position === "Member" ? (
              <div
                onClick={() => this.addOrganization()}
                style={{
                  color: palette.primary,
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  marginTop: 150,
                  position: "absolute",
                  cursor: "pointer",
                }}>
                {t("biodata:addOrganization")}
              </div>
            ) : (
              ""
            )}
          </div>
        </WizardForm>
      </Grid>
    );
  }
}
export default withTranslation()(Organization);
