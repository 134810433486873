import React from "react";
import { getItem, setItem } from "app/utils";
import Unity, { UnityContent } from "react-unity-webgl";

export class GeneratingGame extends React.Component {
  state = { unityContent: null };
  idx = 0;
  energy = ["asosisapi", "gamebala", "menara"];

  componentDidMount() {
    if (
      Boolean(getItem("EXAM_COMPLETED") === "false") &&
      !getItem("FINISH_LOAD_GAME")
    ) {
      this.generateGame(0);
    }
  }

  generateGame(i) {
    var content = new UnityContent(
      "/assets/" + this.energy[i] + "/Meta.json",
      "/assets/" + this.energy[i] + "/UnityLoader.js"
    );
    try {
      this.setState({
        unityContent: content,
      });
      content.on("progress", progression => {
        this.setState({
          progression: progression * 100,
        });
        if (progression === 1) {
          if (this.idx < 2) {
            this.idx += 1;
            this.setState({ unityContent: null });
            this.generateGame(this.idx);
          } else {
            this.setState({ unityContent: null });
            setItem("FINISH_LOAD_GAME", null);
          }
        }
      });
      content.remove();
    } catch (error) {
      console.log("Error render", error);
    }
  }
  render() {
    const { unityContent } = this.state;
    return (
      <>
        {unityContent !== null && (
          <div style={{ height: 1, width: 1 }}>
            <Unity
              className={"Hide-unity"}
              unityContent={unityContent}
              style={{ height: 20, width: 20 }}
              onProgress={this.onProgress}
            />
          </div>
        )}
      </>
    );
  }
}
