import { Dialog } from "@material-ui/core";
import { palette } from "assets/css/main";
import React from "react";
import propTypes from "prop-types";

/**
 * @extends {React.Component<{onEnter:propTypes.func, onClose:propTypes.func, open:propTypes.bool, hideClose:propTypes.bool,style:propTypes.object}>}
 */
class DialogueFull extends React.Component {
  render() {
    const { open, children, onEnter, onClose, hideClose, style } = this.props;
    return (
      <Dialog
        id="modal-parent"
        onEnter={() => {
          onEnter();
        }}
        fullScreen
        className={"Custom-Dialog "}
        aria-labelledby="customized-dialog-title"
        disableBackdropClick
        open={open}
        style={{ backgroundColor: "transparent" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            paddingTop: "50px",
          }}>
          <div
            style={{
              width: 900,
              minHeight: 650,
              padding: 30,
              position: "relative",
              ...style,
            }}>
            {hideClose ? (
              ""
            ) : (
              <button
                onClick={() => {
                  onClose();
                }}
                style={{
                  width: 28,
                  height: 28,
                  color: "#C4C4C4",
                  borderRadius: "50%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  border: "none",
                  cursor: "pointer",
                }}>
                X
              </button>
            )}
            {children ? (
              children
            ) : (
              <>
                {/* sample view children*/}
                <div
                  style={{
                    backgroundColor: "white",
                    width: 900,
                    boxSizing: "border-box",
                    borderRadius: 4,
                  }}>
                  <div
                    style={{
                      height: 20,
                      backgroundColor: palette.primary,
                      padding: "20px 20px",
                      color: "white",
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      fontSize: 18,
                    }}>
                    Title Here
                  </div>
                  <div style={{ padding: 20, height: "100%", minHeight: 580 }}>
                    Your Content
                  </div>
                  <div
                    style={{
                      display: "flex",
                      bottom: 0,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      backgroundColor: "white",
                      boxSizing: "border-box",
                      width: "100%",
                      borderBottomLeftRadius: 4,
                      borderBottomRightRadius: 4,
                      boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                      padding: "20px 100px",
                    }}>
                    Footer
                  </div>
                </div>
                {/* end sample view children */}
              </>
            )}
          </div>
        </div>
      </Dialog>
    );
  }
}
DialogueFull.propTypes = {
  onEnter: propTypes.func,
  onClose: propTypes.func,
  open: propTypes.bool,
  hideClose: propTypes.bool,
  style: propTypes.object,
};
DialogueFull.defaultProps = {
  onEnter: () => {},
  onClose: () => {
    console.error("You should use onClose={} props");
  },
  open: false,
  hideClose: false,
  style: {},
};
export default DialogueFull;
