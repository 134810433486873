import React from "react";
import { withTranslation } from "react-i18next";
//import TemplateBlue from "./template-blue";
import { PDFViewer, Font } from "@react-pdf/renderer";
import {
  RequestGet,
  getItem,
  AppHistory,
  GetLocalItem,
  SetLocalItem,
} from "app/utils";
import { ApplicantHeader } from "app/components";
import { palette } from "assets/css/main";
import {
  // IcnTemplateBlue,
  IcnTemplateHG1,
  IcnTemplateHG2,
  IcnTemplateHG3,
  IcnTemplateHG4,
  IcnTemplateHG5,
  IcnTemplateHG6,
  // IcnTemplatePulrple,
  IconConfirmationCheck,
  ImgToogleOff,
  ImgToogleOn,
} from "assets/img";
import ButtonPrev from "app/components/general/ButtonPrev";
import { Button, Grid, Typography } from "@material-ui/core";
// import TemplatePurple from "./template-purple";
import TemplateHG1 from "./template-hg1";
import TemplateHG2 from "./template-hg2";
import TemplateHG3 from "./template-hg3";
import TemplateHG4 from "./template-hg4";
import TemplateHG5 from "./template-hg5";
import TemplateHG6 from "./template-hg6";
import { loadFont } from "app/utils/general/FontLoader";

// Register font
Font.register({
  family: "BebasNeue",
  src: loadFont("bebas-neue/BebasNeue-Regular.ttf"),
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Roboto",
  src: loadFont("roboto/Roboto-Regular.ttf"),
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Roboto",
  src: loadFont("roboto/Roboto-Bold.ttf"),
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Roboto",
  src: loadFont("roboto/Roboto-Italic.ttf"),
  fontStyle: "italic",
  fontWeight: "normal",
});

Font.register({
  family: "Georgia",
  src: loadFont("georgia/Georgia_Regular_font.ttf"),
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Georgia",
  src: loadFont("georgia/georgia_bold.ttf"),
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Avenir",
  src: loadFont("avenir/avenir-roman.ttf"),
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "Avenir",
  src: loadFont("avenir/FontsFree-Net-AvenirLTStd-Black.ttf"),
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Avenir",
  src: loadFont("avenir/Avenir-LT-Std-55-Oblique_5172.ttf"),
  fontStyle: "oblique",
  fontWeight: "normal",
});

Font.register({
  family: "AdobeClean",
  src: loadFont("adobe-clean/AdobeClean-Regular.ttf"),
  fontStyle: "normal",
  fontWeight: "normal",
});
Font.register({
  family: "AdobeClean",
  src: loadFont("adobe-clean/AdobeClean-Bold.ttf"),
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "AdobeClean",
  src: loadFont("adobe-clean/AdobeClean-It.ttf"),
  fontStyle: "italic",
  fontWeight: "normal",
});

Font.register({
  family: "HurmeGeometricSans3",
  src: loadFont("hurme-geometric-sans-3/Hurme Geometric Sans 3 W03 Bold.ttf"),
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "LucidaCalligraphy",
  src: loadFont("lucida-calligraphy/LCALLIG.ttf"),
  fontStyle: "normal",
  fontWeight: "normal",
});

const templates = [
  // { key: "template-blue", image: IcnTemplateBlue },
  // { key: "template-purple", image: IcnTemplatePulrple },
  { key: "template-hg1", image: IcnTemplateHG1 },
  { key: "template-hg2", image: IcnTemplateHG2 },
  { key: "template-hg3", image: IcnTemplateHG3 },
  { key: "template-hg4", image: IcnTemplateHG4 },
  { key: "template-hg5", image: IcnTemplateHG5 },
  { key: "template-hg6", image: IcnTemplateHG6 },
];
export default withTranslation()(
  class SelectTemplate extends React.Component {
    state = {
      cvTemplate: null,
      loading: true,
      selected: GetLocalItem("SELECTED_TEMPLATE")
        ? GetLocalItem("SELECTED_TEMPLATE")
        : "template-hg1",
      toogle: false,
    };
    componentDidMount() {
      this.getBiodata();
    }

    getBiodata() {
      this.setState({ content: <div></div> });
      const { t } = this.props;
      const maritalStatus = t("biodata:options.maritalStatus", {
        returnObjects: true,
      });
      const jobStatus = t("biodata:options.jobStatus", {
        returnObjects: true,
      });
      RequestGet("biodata")
        .then(res => {
          console.log(res.data);
          res.data.listWorks.sort(function (a, b) {
            return b.stillWorking - a.stillWorking;
          });
          res.data.listFormalEducation.sort(function (a, b) {
            return b.yearGraduate - a.yearGraduate;
          });
          return res.data;
        })
        .then(data => {
          this.setState(
            prev => ({
              ...prev,
              ...data,
              ...JSON.parse(getItem("user_info")),
              ...{ maritalStatus: maritalStatus, jobStatus: jobStatus },
            }),
            () => {
              this.setTemplate(this.state.selected);
            }
          );
        })
        .catch(er => {
          console.log(er);
        });
    }

    setTemplate(key) {
      const { t } = this.props;
      var template;
      SetLocalItem("SELECTED_TEMPLATE", key);
      this.setState({ loading: true });
      console.log(this.state);

      /*if (key === "template-blue") {
        template = <TemplateBlue t={t} data={this.state} />;
      } else if (key === "template-purple") {
        template = <TemplatePurple t={t} data={this.state} />;
      } else*/ if (
        key === "template-hg1"
      ) {
        template = <TemplateHG1 t={t} data={this.state} />;
      } else if (key === "template-hg2") {
        template = <TemplateHG2 t={t} data={this.state} />;
      } else if (key === "template-hg3") {
        template = <TemplateHG3 t={t} data={this.state} />;
      } else if (key === "template-hg4") {
        template = <TemplateHG4 t={t} data={this.state} />;
      } else if (key === "template-hg5") {
        template = <TemplateHG5 t={t} data={this.state} />;
      } else if (key === "template-hg6") {
        template = <TemplateHG6 t={t} data={this.state} />;
      } else {
        template = <TemplateHG2 t={t} data={this.state} />;
      }
      this.setState({ cvTemplate: template, selected: key }, () => {
        this.setState({ loading: false });
      });
    }

    render() {
      const { cvTemplate, loading, toogle } = this.state;
      const { t } = this.props;
      return (
        <div
          style={{
            boxSizing: "border-box",
            backgroundColor: "rgba(82,86,89)",
          }}>
          <ApplicantHeader menu="home" />
          <div
            style={{
              height: "100vh",
              boxSizing: "border-box",
              paddingTop: 80,
              display: "flex",
            }}>
            <div
              style={{
                width: "30%",
                height: "100%",
                // backgroundColor: "white",
                backgroundColor: "#333333",
                boxSizing: "border-box",
              }}>
              <div
                style={{
                  width: "100%",
                  height: 50,
                  backgroundColor: palette.primary,
                }}>
                <ButtonPrev
                  text={t("button.back")}
                  color="white"
                  onClick={() => {
                    AppHistory.goBack();
                  }}
                />
              </div>
              <Grid
                container
                style={{
                  margin: "8.5% 4% 4%",
                  height: "83%",
                  width: "26%",
                  top: 0,
                  position: "fixed",
                  overflowX: "auto",
                }}>
                <Grid item lg={2} md={2} sm={2}>
                  <Button
                    onClick={() => {
                      this.setState({ toogle: !toogle });
                      setTimeout(() => {
                        this.setTemplate(GetLocalItem("SELECTED_TEMPLATE"));
                      }, 100);
                    }}>
                    <img
                      src={toogle ? ImgToogleOn : ImgToogleOff}
                      style={{
                        width: 54,
                        height: 24,
                      }}
                      alt={"QR Toogle"}
                    />
                  </Button>
                </Grid>
                <Grid item lg={4} md={4} sm={4}>
                  <Typography
                    style={{
                      marginTop: 9,
                      fontSize: 14,
                      color: "white",
                      fontWeight: "lighter",
                    }}>
                    {t("biodata:cv_template.index.showQr")}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                  {/* JANGAN DIHAPUS */}
                  {/* <Tooltip
                    title={t("biodata:cv_template.index.tooltip")} 
                    arrow
                  >
                    <img 
                      src={IconTooltipCV}
                      style={{
                        marginTop: 10,
                        width: 16,
                        height: 16,
                      }}
                      alt={"Tooltip"}
                    />
                  </Tooltip> */}
                </Grid>
                {templates.map((v, k) => {
                  return (
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      key={k}
                      style={{
                        marginTop: "2%",
                      }}
                      onClick={() => {
                        this.setTemplate(v.key);
                      }}>
                      <div
                        style={{
                          display:
                            this.state.selected === v.key ? "flex" : "none",
                          width: 153,
                          height: 204,
                          position: "absolute",
                        }}>
                        <img
                          style={{
                            display:
                              this.state.selected === v.key ? "flex" : "none",
                            position: "absolute",
                            zIndex: 100,
                            width: 40,
                            height: 40,
                            top: "40%",
                            left: "40%",
                          }}
                          src={IconConfirmationCheck}
                          alt={"check"}
                        />
                        <div
                          style={{
                            display:
                              this.state.selected === v.key ? "flex" : "none",
                            width: 153,
                            height: 204,
                            backgroundColor: "black",
                            position: "absolute",
                            opacity: 0.3,
                          }}></div>
                      </div>
                      <img
                        src={v.image}
                        style={{ width: 153, height: 204 }}
                        alt="blue"
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            {!loading && (
              <PDFViewer
                style={{
                  width: "70%",
                  height: "100%",
                  border: "none",
                  backgroundColor: "white",
                }}>
                {cvTemplate}
              </PDFViewer>
            )}
          </div>
        </div>
      );
    }
  }
);
