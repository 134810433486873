import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Typography, Grid, Button } from "@material-ui/core";
import { IconEdit, IconVacancyBullet } from "assets/img";
import { currencyFormat, getItem, GetLocalItem } from "app/utils";
import data from "../data";

class VacancyDetailConfirmation extends React.Component {
  constructor(props) {
    super(props);
    if (GetLocalItem("vacancyGet")) {
      data.listVacancy = GetLocalItem("vacancyGet");
    }

    this.state = {
      listVacancy: data.listVacancy,
      other: "",
    }
  }

  componentDidMount() {
    var ot = this.state.listVacancy.jobFamilies.find(p => p.jobFamilyEng === "Other") ? this.state.listVacancy.jobFamilies.find(p => p.jobFamilyEng === "Other").id : "";
    this.setState({other: ot});
  }

  render() {
    const { listVacancy, other } = this.state;
    const { data, t } = this.props;
    var otherBenefitID = listVacancy.benefits.find(p => p.benefit === "Others").id;
    return(
      <Grid container style={{ padding: "4% 6% 8%" }}>
        <Grid
          item lg={9} md={9} sm={9}
        >
          <Typography
            style={{
              fontSize: "24px",
              color: palette.primary,
            }}
          >
            {t("vacancy:create.step", { returnObjects: true })[0]}
          </Typography>
        </Grid>

        <Grid
          item lg={3} md={3} sm={3}
        >
          <Button
            style={{
              border: "1px solid #515D6E",
              borderRadius: "4px",
              marginLeft: "30%"
            }}
            onClick={() => {
              this.props.onEdit()
            }}
          >
            <img
              style={{ padding: "10px 0px 12px 22px" }}
              src={IconEdit}
              alt={"Edit"}
              height="15vh"
            />
            <Typography
              style={{
                padding: "9px 22px 6px 11px",
                fontSize: "14px",
                color: "#515D6E",
                textTransform: "none",
              }}
            >
              Edit
            </Typography>
          </Button>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4%"}}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}
          >
            {t("vacancy:create.vacancyDetail.jobTitle.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.vacancy.jobTitle}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "3%"}}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.vacancyDetail.jobFamily.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {
              data.vacancy.jobFamily === other ? data.vacancy.otherValue :
              (
                listVacancy.jobFamilies.find(p => p.id === data.vacancy.jobFamily) ? 
                (
                  getItem("lang") === "en" ? 
                  listVacancy.jobFamilies.find(p => p.id === data.vacancy.jobFamily).jobFamilyEng :
                  listVacancy.jobFamilies.find(p => p.id === data.vacancy.jobFamily).jobFamilyIna
                ) : ""
              )
            }
          </Typography>
        </Grid>
        
        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "3%"}}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.vacancyDetail.subJobFamily.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {
              data.vacancy.jobFamily === other ? "" :
              (
                listVacancy.jobFamilies.find(p => p.id === data.vacancy.jobFamily) ? 
                (
                  listVacancy.jobFamilies.find(p => p.id === data.vacancy.jobFamily).jobFamilyDetails.find(p => p.id === data.vacancy.subJobFamily) ? 
                  (getItem("lang") === "en" ? 
                  listVacancy.jobFamilies.find(p => p.id === data.vacancy.jobFamily).jobFamilyDetails.find(p => p.id === data.vacancy.subJobFamily).jobFamilyDetailEng :
                  listVacancy.jobFamilies.find(p => p.id === data.vacancy.jobFamily).jobFamilyDetails.find(p => p.id === data.vacancy.subJobFamily).jobFamilyDetailIna) : ""
                ) : ""
              )
            }
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "3%"}}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.vacancyDetail.numberOfVacancy.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {data.vacancy.vacancyNumber}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "3%"}}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.vacancyDetail.workLocation.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {
              data.vacancyWorkLocation.length > 0 ? data.vacancyWorkLocation.map((v, i) => {
                var value = i === 0 ? 

                v === 9999 ?
                getItem("lang") === "en" ?listVacancy.locationCity.find(p => p.id === v).name : this.props.t("vacancy:create.vacancyDetail.dropdown")
                :
                listVacancy.locationCity.find(p => p.id === v).name 
                
                :  
                
                ", ".concat(listVacancy.locationCity.find(p => p.id === v).name);
                return(value);
              }) : null
            }
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "3%"}}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.vacancyDetail.jobDesc.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          {
            !(data.vacancyJobDesc.length === 1 && data.vacancyJobDesc[0] === "") ? data.vacancyJobDesc.map((v, i) => {
              return (
                <div style={{ width: "51%" }} key={i}>
                  <Grid container>
                    <Grid
                      item
                      lg={1}
                      md={1}
                      sm={1}
                      style={{ marginTop: 4 }}>
                      <img
                        style={{ paddingTop: 5, paddingLeft: 2 }}
                        src={IconVacancyBullet}
                        alt={i}
                        height="10vh"
                        align="center"
                      />
                    </Grid>
                    <Grid
                      item
                      lg={11}
                      md={11}
                      sm={11}
                      style={{ marginTop: 4 }}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          color: palette.primary,
                        }}>
                        {v}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            }) : null
          }
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "3%"}}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.vacancyDetail.salaryRange.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          <Typography
            style={{
              fontSize: "18px",
              color: palette.primary,
            }}>
            {"Rp " + currencyFormat(String(data.vacancy.salaryMin)) + " - Rp " + currencyFormat(String(data.vacancy.salaryMax))}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "3%"}}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "lighter",
              color: palette.primary,
            }}>
            {t("vacancy:create.vacancyDetail.benefit.label")}
          </Typography>
        </Grid>

        <Grid
          item lg={12} md={12} sm={12}
          style={{marginTop: "4px"}}
        >
          {
            data.vacancyBenefits.length > 0 ? data.vacancyBenefits.map((v, i) => {
              if(v === otherBenefitID) {
                return (
                  <div key={i}>
                    {data.otherBenefits.map((vOther, iOther) => {
                      return (
                        <div style={{ width: "51%" }} key={iOther}>
                          <Grid container>
                            <Grid item lg={1} md={1} sm={1} style={{ marginTop: 4 }}>
                              <img
                                style={{ paddingTop: 5, paddingLeft: 2 }}
                                src={IconVacancyBullet}
                                alt={iOther}
                                height="10vh"
                                align="center"
                              />
                            </Grid>
                            <Grid
                              item
                              lg={11}
                              md={11}
                              sm={11}
                              style={{ marginTop: 4 }}>
                              <Typography
                                style={{
                                  fontSize: "18px",
                                  color: palette.primary,
                                }}>
                                {vOther}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })}
                  </div>
                )
              } else {
                return (
                  <div style={{ width: "51%" }} key={i}>
                    <Grid container>
                      <Grid
                        item
                        lg={1}
                        md={1}
                        sm={1}
                        style={{ marginTop: 4 }}>
                        <img
                          style={{ paddingTop: 5, paddingLeft: 2 }}
                          src={IconVacancyBullet}
                          alt={i}
                          height="10vh"
                          align="center"
                        />
                      </Grid>
                      <Grid
                        item
                        lg={11}
                        md={11}
                        sm={11}
                        style={{ marginTop: 4 }}>
                        <Typography
                          style={{
                            fontSize: "18px",
                            color: palette.primary,
                          }}>
                          {
                            getItem("lang") === "en" ? (
                              listVacancy.benefits.find(p => p.id === v) ? listVacancy.benefits.find(p => p.id === v).benefit : v
                            ) : (
                              listVacancy.benefits.find(p => p.id === v) ? listVacancy.benefits.find(p => p.id === v).benefitIna : v
                            )
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              }
            }) : null
          }
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(VacancyDetailConfirmation);
