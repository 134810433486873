import React from "react";
import { Link } from "react-router-dom";
import { palette } from "assets/css/main";
import { loadImage, getItem } from "app/utils";
import { isMobile } from "react-device-detect";
export default class Logo extends React.Component {
  render() {
    const { style } = this.props;
    return (
      <div
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}>
        <Link to={getItem("access_token") ? "#" : "/"} className="centered">
          <img
            alt="Hiregrade-Logo"
            src={loadImage("HiregradeLogo.png")}
            width={isMobile ? 100 : 150}
          />
        </Link>
        <span
          style={{
            marginLeft: 10,
            padding: isMobile ? "5px 10px 2px" : "7px 15px 5px 15px",
            backgroundColor: palette.secondary,
            color: palette.primarydark,
            textTransform: "uppercase",
            fontSize: isMobile ? 14 : 16,
            borderRadius: 2,
          }}>
          beta
        </span>
      </div>
    );
  }
}
