import React from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { HeaderLogo } from "..";
import { palette } from "assets/css/main";
import { Link } from "react-router-dom";
import { getItem, RequestGet, RequestPut, setItem } from "app/utils";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { HtmlTooltip } from "app/components";
import { SwitchID, SwitchEN } from "assets/img";
import i18next from "i18n";
// import { IcnNotif } from "assets/img";
import DialogueConfirm from "../modal/confirm";
import alertData from "../alert/alert-data";
import { PushDataLayer } from "app/utils/general/data-layer";
import { HubConnectionBuilder } from "@microsoft/signalr";
// import { NotifBadge } from "../general/component";

const linkStyleActive = {
  color: palette.primary,
  fontSize: 16,
  borderBottom: "4px solid " + palette.primary,
  display: "flex",
  marginRight: 30,
  height: 76,
  alignItems: "center",
  cursor: "pointer",
};
const linkStyle = {
  color: palette.primary,
  marginRight: 30,
  fontSize: 16,
  display: "flex",
  borderBottom: "4px solid transparent",
  height: 76,
  alignItems: "center",
  cursor: "pointer",
};

class ApplicantAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifCount: 0,
      marketStatus: {},
      anchorEl: null,
      ...JSON.parse(getItem("user_info")),
      activeMenu: [],
      lang: getItem("lang"),
      openActiveMarketplace: false,
      biodata: {
        personalInfo: {
          firstName: "",
          middleName: "",
          lastName: "",
          gender: "",
          birthPlace: "",
          birthDate: "",
          maritalStatus: "",
          identityNumber: "",
          identityPhoto: "",
          email: "",
          phoneNumber: "",
          address: "",
          street: "",
        },
        listFormalEducation: [],
        listInformalEducation: [],
        listOrganization: [],
        listWorks: [],
      },
      signalRConnection: process.env.REACT_APP_SIGNALR
        ? new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_SIGNALR)
            .withAutomaticReconnect()
            .build()
        : {},
    };
  }
  handleClick = e => {
    this.setState({ anchorEl: e.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  closeLang() {
    var l = getItem("lang") === "id" ? "en" : "id";
    this.setState({ lang: l });
    i18next.changeLanguage(l);
    setItem("lang", l);
    this.setState({ lang: l });
    window.location.reload();
  }
  getMarketStatus() {
    RequestGet("marketstatus")
      .then(res => {
        this.setState({ marketStatus: res.data });
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  getNotification() {
    RequestGet("notification", { page: 1, limit: 1 })
      .then(res => {
        this.setState({
          notifCount: res.data.countUnread < 10 ? res.data.countUnread : "9+",
        });
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  generateTooltip() {
    const { t } = this.props;
    const activeMenu = [
      {
        id: "home",
        text: t("link.aboutYou"),
        url: "/user/home",
        tooltip: t("tooltip.aboutYou"),
      },
      {
        id: "profile",
        text: t("link.profile"),
        url: "/user/profile",
        tooltip: t("tooltip.profile"),
      },
      // take out feature in 01/11/2023
      // {
      //   id: "job-opening",
      //   text: t("link.jobOpening"),
      //   url: "/user/job-opening",
      //   tooltip: t("tooltip.jobOpening"),
      // },
    ];
    this.setState({ activeMenu: activeMenu });
  }
  DataLayer(action) {
    PushDataLayer({
      eventCategory: "All Pages",
      eventAction: action,
      eventLabel: "Click",
    });
  }

  tooglePopupActiveMarketplace(val) {
    this.setState({ openActiveMarketplace: val });
  }

  putMarketStatus() {
    var data = {
      isActive: true,
    };
    RequestPut("marketstatus", data)
      .then(() => {
        window.location.href = "/user/home";
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  getBiodata() {
    RequestGet("biodata", { userId: getItem("user_id") })
      .then(res => {
        res.data.listWorks.sort(function (a, b) {
          return b.stillWorking - a.stillWorking;
        });
        res.data.listFormalEducation.sort(function (a, b) {
          return b.yearGraduate - a.yearGraduate;
        });
        res.data.listInformalEducation.sort(function (a, b) {
          return b.year - a.year;
        });
        res.data.listOrganization.sort(function (a, b) {
          return b.year - a.year;
        });
        return res.data;
      })
      .then(data => {
        if(data.personalInfo.address !== null){
          let splitAddress = data.personalInfo.address.split(',');
          data.personalInfo.street = splitAddress[0];
        }
        
        this.setState(prev => ({ ...prev, ...data }));
        this.setState({ biodata: data });
      })
      .then();
  }

  componentDidMount() {
    this.generateTooltip();
    this.getNotification();
    this.getMarketStatus();
    this.getBiodata();

    // signalR
    if (this.state.signalRConnection.connectionStarted) {
      console.log(this.state.signalRConnection);

      this.state.signalRConnection.on("UserConnected", connectionId => {
        console.log("Connected!");
        console.log(connectionId);

        this.state.signalRConnection.invoke(
          "UpdateDB",
          getItem("user_id"),
          connectionId,
          "APPLICANT",
          "true",
          null
        );
      });

      this.state.signalRConnection
        .start()
        .then(() => {
          console.log("Started!");
        })
        .catch(e => {
          console.log("Stoped!");
          console.log("Connection failed: ", e);
        });
    }
  }

  render() {
    const { menu, hideMenu, t } = this.props;
    const {
      anchorEl,
      first_name,
      activeMenu,
      lang,
      marketStatus,
      // notifCount,
      openActiveMarketplace,
      signalRConnection,
      biodata
    } = this.state;
    return (
      <div>
        <AppBar
          position="absolute"
          color="default"
          style={{
            display: "flex",
            backgroundColor: "white",
            top: 0,
            height: 80,
            bottom: "auto",
            position: "fixed",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)",
            justifyContent: "center",
          }}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                paddingLeft: 76,
                paddingRight: 76,
              }}>
              <Grid item lg={2} md={2}>
                <HeaderLogo />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                style={{
                  paddingLeft: 60,
                }}>
                <Grid
                  container
                  justify="flex-start"
                  alignItems="center"
                  style={{
                    height: 76,
                  }}>
                  {!hideMenu && (
                    <>
                      {activeMenu.map((v, i) => {
                        return (
                          <HtmlTooltip
                            key={i}
                            title={
                              menu !== v.id ? (
                                <div style={{ maxWidth: 200, color: "white" }}>
                                  {v.tooltip}
                                </div>
                              ) : (
                                ""
                              )
                            }
                            arrow>
                            <Link
                              to={v.url}
                              onClick={this.DataLayer(v.text)}
                              style={
                                menu === v.id ? linkStyleActive : linkStyle
                              }>
                              {v.text}
                            </Link>
                          </HtmlTooltip>
                        );
                      })}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={4} md={4}>
                <Grid container justify="center" alignItems="center">
                  {!hideMenu && (
                    <>
                      {/* take out feature in 01/11/2023 */}
                      {/* <Link
                        onClick={() => {
                          this.DataLayer("Notification");
                        }}
                        to="/user/notification"
                        style={
                          menu === "notification" ? linkStyleActive : linkStyle
                        }>
                        <img src={IcnNotif} alt={"notif"} height={"36vh"} />
                        {notifCount !== 0 && (
                          <div
                            style={{
                              position: "absolute",
                              marginLeft: "20px",
                              width: "20px",
                              height: "20px",
                              backgroundColor: "#52C8F4",
                              borderRadius: "20px",
                              color: "white",
                              textAlign: "center",
                            }}>
                            <span style={{ fontSize: 12 }}>
                              {String(notifCount)}
                            </span>
                          </div>
                        )}
                        <span style={{ marginLeft: 10 }}>
                          {t("link.notification")}
                        </span>
                      </Link> */}
                      <Button
                        className="Menu-Button"
                        aria-haspopup="true"
                        style={{
                          textTransform: "capitalize",
                          color: palette.primary,
                          fontSize: 16,
                          padding: "0px 0px",
                        }}
                        onClick={this.handleClick}>
                        {biodata.personalInfo.firstName !== '' ? biodata.personalInfo.firstName : first_name}
                        {Boolean(anchorEl) ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </Button>
                    </>
                  )}

                  <Menu
                    className="RecruiterUserMenu"
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    style={{
                      fontWeight: "lighter",
                      marginTop: 50,
                      color: palette.primary,
                      maxWidth: "23%",
                    }}
                    onClose={this.handleClose}>
                    <MenuItem
                      style={{
                        width: 200,
                        fontWeight: "lighter",
                        color: palette.primary,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {}}>
                      {t("link.lang")}
                      <img
                        src={lang === "id" ? SwitchID : SwitchEN}
                        alt={"lang"}
                        height={24}
                        width={44}
                        onClick={() => {
                          this.closeLang();
                        }}
                      />
                    </MenuItem>
                    <MenuItem
                      style={{
                        fontWeight: "lighter",
                        color: palette.danger,
                      }}
                      onClick={() => {
                        if (this.state.signalRConnection.connectionStarted) {
                          signalRConnection
                            .invoke(
                              "UpdateDB",
                              getItem("user_id"),
                              null,
                              "APPLICANT",
                              "false",
                              "LOGOUT"
                            )
                            .then(() => {
                              signalRConnection
                                .stop()
                                .then(() => {
                                  console.log("Disconnected!");

                                  window.location.href = "/user/logout";
                                })
                                .catch(e => console.log(e));
                            });
                        } else {
                          window.location.href = "/user/logout";
                        }
                      }}>
                      {t("link.logout")}
                    </MenuItem>
                    {!marketStatus.isActive && (
                      <MenuItem
                        onClick={() => {
                          this.tooglePopupActiveMarketplace(
                            !openActiveMarketplace
                          );
                          this.handleClose();
                        }}>
                        <Grid container>
                          <Grid item lg={12} md={12}>
                            <Button
                              style={{
                                marginTop: "10px",
                                background: "#52C8F4",
                                borderRadius: "4px",
                                fontSize: "13px",
                                lineHeight: "17px",
                                color: "white",
                                padding: "7px 13px 4px",
                                textTransform: "none",
                              }}>
                              Active to HG Marketplace
                            </Button>
                          </Grid>
                          <Grid item lg={12} md={12}>
                            <Typography
                              style={{
                                marginTop: "8px",
                                fontSize: "11px",
                                lineHeight: "14px",
                                textAlign: "center",
                                color: "#B0B7BD",
                              }}>
                              Set your profile to be searchable and
                              <br />
                              viewable by recruiters
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    )}
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        {/* Active to HG Marketplace Popup */}
        <DialogueConfirm
          open={openActiveMarketplace}
          title={"Confirmation"}
          content={
            "This will set your profile to be searchable and viewable by Hiregrade recruiters"
          }
          btnYes={"Ok"}
          btnNo={"Cancel"}
          onOK={() => {
            // this.tooglePopupActiveMarketplace(!openActiveMarketplace)
            this.putMarketStatus();
          }}
          onClose={() =>
            this.tooglePopupActiveMarketplace(!openActiveMarketplace)
          }
        />
      </div>
    );
  }
}

export default withTranslation()(ApplicantAppBar);
