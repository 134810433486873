import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import tipeProps from "prop-types";

/**
 * @extends {React.Component<{onError:tipeProps.func}>}
 */
class VideoJSPlayer extends React.Component {
  currentsrc = null;
  static defaultProps = {
    onError: () => {},
  };
  static propTypes = {
    onError: tipeProps.func,
  };
  componentDidMount() {
    this.player = videojs(
      this.videoNode,
      this.props,
      function onPlayerReady() {}
    );
    this.player.on("error", () => {
      if (this.player.error().code === 4) {
        this.props.onError(false, 4);
      } else {
        this.props.onError(true, 2);
      }
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
    this.videoNode = undefined;
  }
  render() {
    return (
      <div>
        <div>
          <video
            ref={node => (this.videoNode = node)}
            className="video-js vjs-16-9"></video>
        </div>
      </div>
    );
  }
}
export default VideoJSPlayer;
