import React from "react";
import { withTranslation } from "react-i18next";
import SidePanel from "./side-panel";
import { MyHelmet, ApplicantHeader } from "app/components";
import CVPanel from "./cv-panel";

class EditCV extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
  }

  render() {
    return (
      <div>
        <MyHelmet title={"Home"} />
        <ApplicantHeader menu="home" />
        <div
          style={{
            width: "100%",
            display: "flex",
            paddingTop: "100px",
            flexDirection: "row",
            paddingBottom: 100,
          }}>
          <div
            style={{
              width: "15%",
              display: "flex",
              flexDirection: "column",
            }}></div>
          <div style={{ width: 300, display: "flex", flexDirection: "column"}}>
            <SidePanel
              edit ={true}
              onChange={v => {
                this.setState({ sideStep: v });
              }}
            />
          </div>
          <div
            style={{
              width: "1%",
              display: "flex",
              flexDirection: "column",
            }}></div>
          <div
            style={{ width: "54%", display: "flex", flexDirection: "column" }}>
            <CVPanel />
          </div>
          <div
            style={{
              width: "15%",
              display: "flex",
              flexDirection: "column",
            }}></div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(EditCV);
