import React from "react";
import { Dialog, Grid, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { IcnSpeakerConfirmation } from "assets/img";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { t } from "app/utils";

class Information extends React.Component {
  static defaultProps = {
    onFinish: () => {},
    title: "",
    name: "",
    noq: "",
    timer: 3,
    total: 0,
  };
  static propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    timer: PropTypes.number,
    total: PropTypes.number,
    noq: PropTypes.string,
    onFinish: PropTypes.func,
  };
  state = { timer: this.props.timer };
  init() {
    this.setState({ timer: this.props.timer });
  }
  counter() {
    var timer = this.state.timer;
    if (timer > 0) {
      setTimeout(() => {
        timer -= 1;
        this.setState({ timer: timer });
        this.counter();
      }, 1000);
    } else {
      if (this.props.onFinish !== undefined) {
        this.props.onFinish();
      } else {
        console.log("On finish not defined");
      }
    }
  }
  render() {
    console.log(t("dialogue.test.title"));
    const { open, title, name, total, noq } = this.props;
    const tt = title ? title : t("dialogue.test.title");
    const nm = name ? name : t("dialogue.test.testName");
    const no = noq ? noq : t("dialogue.test.noq");
    return (
      <Dialog
        className={"Custom-Dialog "}
        onEnter={() => this.init()}
        onEntered={() => this.counter()}
        aria-labelledby="customized-dialog-title"
        disableBackdropClick
        open={open}
        style={{ backgroundColor: "transparent" }}>
        <Grid
          container
          justify="center"
          alignItems="flex-end"
          style={{
            backgroundColor: "transparent",
            width: 500,
            paddingTop: 60,
          }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              width: 140,
              height: 140,
              borderRadius: "50%",
            }}>
            <img
              src={IcnSpeakerConfirmation}
              width={129}
              height={129}
              style={{ position: "relative", left: "5%" }}
              alt="Info"
            />
          </div>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              paddingTop: 100,
              backgroundColor: "white",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              fontWeight: "bold",
              fontSize: 24,
              color: palette.primary,
              width: "100%",
            }}>
            <div style={{ marginBottom: 20 }}>{t("dialogue.test.title")}</div>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          direction="row"
          alignItems="flex-start"
          style={{
            backgroundColor: "#ffff",
            padding: 20,
            width: 500,
            borderTopColor: palette.primary,
            borderTopWidth: 1,
            borderTopStyle: "solid",
          }}>
          <Grid item lg={4} sm={4}>
            <Typography
              style={{
                fontSize: 24,
                color: palette.primary,
              }}>
              {nm}
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2}>
            <Typography
              style={{
                fontSize: 24,

                color: palette.primary,
              }}>
              :
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6}>
            <Typography
              style={{
                fontSize: 24,
                color: palette.primary,
              }}>
              {tt}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={4}>
            <Typography
              style={{
                fontSize: 24,
                color: palette.primary,
              }}>
              {no}
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2}>
            <Typography
              style={{
                fontSize: 24,
                color: palette.primary,
              }}>
              :
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6}>
            <Typography
              style={{
                fontSize: 24,
                color: palette.primary,
              }}>
              {total}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{
            backgroundColor: palette.primary,
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            height: 120,
            width: "100%",
          }}>
          <Typography
            style={{ color: "white", fontWeight: "bold", marginTop: 15 }}>
            {t("dialogue.test.willStart")}:
          </Typography>
          <Typography
            style={{ color: "white", fontWeight: "bolder", fontSize: 38 }}>
            {this.state.timer}
          </Typography>
        </Grid>
      </Dialog>
    );
  }
}

export default withTranslation()(Information);
