import React from "react";
import { palette } from "assets/css/main";
class Numbering extends React.Component {
  render() {
    const { color, number, style, height, background } = this.props;
    return (
      <span
        style={{
          backgroundColor: background,
          color: color,
          borderRadius: "50%",
          padding: "7px 12.2px",
          height: height,
          ...style,
        }}>
        {number}
      </span>
    );
  }
}
Numbering.defaultProps = {
  color: "white",
  number: "n",
  style: {},
  height: 17,
  background: palette.darkGrey,
};
export default Numbering;
