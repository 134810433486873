import React from "react";
import { getItem, RequestPut, General, removeArrayWithIndex } from "app/utils";
import { palette } from "assets/css/main";
import { Add } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { PRIMARY_BUTTON } from "assets/css/main";
import { ConfirmFooter } from "./confirmation-footer";
import data from "app/pages/applicant/initialize/bio-data/data";
import DialogueConfirm from "app/components/modal/confirm";
import { IconEditCV, IconDeleteCV, IconFolder } from "assets/img";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DatePickerValidator from "app/components/general/DatePickerValidator";
import alertData from "app/components/alert/alert-data";
import { DialogueInfo } from "app/components";

class ConfirmationOrganizationExperience extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
    this.state = {
      biodata: props.data,
      ...data.organization,
      ...JSON.parse(getItem("user_info")),
      anchorEl: null,
      open: false,
      opendelete: false,
      role: "",
      isedit: false,
      tmpYear: null,
      openValidation: false,
    };
    General.visible = false;
    data.organization.userId = getItem("user_id");
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  componentDidMount() {}

  handleChange(obj) {
    this.setState(obj);
    data.setOrganization(obj);
    var listOrganizationexp = {
      listOrganizationexp: {
        ...this.state.biodata.listOrganization,
        ...data.listOrganization,
      },
    };
    this.setState({
      biodata: { ...this.state.biodata, ...listOrganizationexp },
    });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, opendelete: false });
  };

  handleClickOpen = (key = -1) => {
    if (key > -1) {
      this.setState(
        {
          open: true,
          ...this.state.biodata.listOrganization[key],
          isedit: true,
          k: key,
        },
        () => {
          this.setState({
            tmpYear: new Date(this.state.year + "-01-01"),
          });
        }
      );
    } else {
      this.setState({
        open: true,
        isedit: false,
        name: "",
        field: "",
        tmpYear: "",
        position: " ",
        function: "",
      });
    }
  };

  handleClickDelete = key => {
    this.setState({ opendelete: true, k: key });
  };

  addorganizationexperience() {
    const { t } = this.props;
    if (data.organization.position === " ") {
      alertData.show(t("biodata:validation.required.position"), "error");
      return false;
    }
    data.organization.year = this.state.tmpYear.getFullYear();
    data.listOrganization.push(data.organization);
    data.setOrganization({
      name: "",
      field: "",
      year: "",
      position: " ",
      function: "",
    });

    var objorganizationexp = {
      name: "",
      field: "",
      year: "",
      position: " ",
      function: "",
    };
    data.listOrganization.forEach(organizationexp => {
      objorganizationexp = {
        name: organizationexp.name,
        field: organizationexp.field,
        year: organizationexp.year,
        position: organizationexp.position,
        function: organizationexp.function,
      };
    });
    this.state.biodata.listOrganization.push(objorganizationexp);
    data.listOrganization = this.state.biodata.listOrganization;
    //alertData.show("Add Success");
    this.setState({ open: false });
  }

  editorganizationexperience() {
    const { t } = this.props;
    var key = this.state.k;
    var stateListData = this.state.biodata.listOrganization;
    stateListData[key].name = this.state.name;
    stateListData[key].field = this.state.field;
    stateListData[key].year = this.state.tmpYear.getFullYear();
    stateListData[key].position = this.state.position;
    stateListData[key].function = this.state.function;

    if (stateListData[key].position === " ") {
      alertData.show(t("biodata:validation.required.position"), "error");
      return false;
    }

    data.organization.name = this.state.name;
    data.organization.field = this.state.field;
    data.organization.year = this.state.tmpYear.getFullYear();
    data.organization.position = this.state.position;
    data.organization.function = this.state.function;

    data.listOrganization = stateListData;

    this.setState(
      { open: false, biodata: {...this.state.biodata, listOrganization: stateListData } },
      () => {
        alertData.show("Edit Success");
      }
    );
  }

  deleteorganizationexperience() {
    var key = this.state.k;
    //var lengthlistwork = this.state.biodata.listWorks.length;
    var listdataOrganization = removeArrayWithIndex(
      this.state.biodata.listOrganization,
      key
    );
    const tempArray = this.state.biodata;
    tempArray.listOrganization = listdataOrganization;
    this.props.updateData(tempArray)

    data.listOrganization = listdataOrganization;
    this.setState(
      {
        opendelete: false,
        biodata: {...this.state.biodata, listOrganization: listdataOrganization },
      },
      () => {
        alertData.show("Delete Success");
      }
    );
  }

  onSubmit(isEdit) {
    if (isEdit) {
      this.editorganizationexperience();
    } else {
      this.addorganizationexperience();
    }
  }

  submitdata() {
    var datasubmit = {};
    datasubmit.personalInfo = data.personalInfo;
    datasubmit.listFormalEducation = this.state.biodata.listFormalEducation.length > 0 ? this.state.biodata.listFormalEducation : data.listFormalEducation;
    datasubmit.listOrganization = this.state.biodata.listOrganization;
    datasubmit.listInformalEducation = this.state.biodata.listInformalEducation;
    datasubmit.listWorks = this.state.biodata.listWorks.length > 0 ? this.state.biodata.listWorks : data.listWorks;

    const {address,description,birthPlace,phoneNumber} = this.state.biodata.personalInfo;
    const {photo} = this.props.data.personalInfo;
    
    if (photo === null || address === null || description === null || birthPlace === null || phoneNumber === null) {
      this.setState({
        openValidation: true,
      });
    }else{
      RequestPut("biodata", datasubmit)
        .then(() => {
          window.location.reload();
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  render() {
    const {
      biodata: { listOrganization },
      open,
      isedit,
      opendelete,
      openValidation
    } = this.state;
    const { t } = this.props;
    const position = t("biodata:options.organizationPosition", {
      returnObjects: true,
    });
    var member = position[0].label;
    var committe = position[1].label;
    return (
      <div
        style={{
          fontWeight: "lighter",
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          padding: "0px 50px",
          color: palette.primary,
        }}>
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            flexDirection: "row",
            alignItems: "center",
            color: palette.primary,
            fontSize: 20,
            fontWeight: "bolder",
            justifyContent: "space-between",
          }}>
          <div>{t("biodata:CVorganizationalExperience")}</div>
          <div>
            <Button
              onClick={this.handleClickOpen}
              size="small"
              variant="contained"
              style={{
                ...PRIMARY_BUTTON,
                fontWeight: "bold",
                marginLeft: 46,
                backgroundColor: "white",
                color: palette.primary,
                fontSize: 11,
              }}>
              <Add style={{ width: "0.8em", height: "0.8em" }}></Add> &nbsp;{" "}
              {t("biodata:CVbtnAdd")}
            </Button>
          </div>
        </div>
        <Dialog
          fullScreen
          className={"Custom-Dialog-cv"}
          aria-labelledby="customized-dialog-title"
          disableBackdropClick
          open={open}
          style={{ backgroundColor: "transparent" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              paddingTop: "50px",
            }}>
            <div
              style={{
                width: 800,
                minHeight: 650,
                padding: 30,
                position: "relative",
              }}>
              <button
                onClick={() => {
                  this.handleClose();
                }}
                style={{
                  width: 28,
                  height: 28,
                  color: "#C4C4C4",
                  borderRadius: "50%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  border: "none",
                  cursor: "pointer",
                }}>
                X
              </button>
              <ValidatorForm
                style={{ backgroundColor: "white", borderRadius: 10 }}
                onSubmit={() => {
                  this.onSubmit(isedit);
                }}>
                <DialogTitle
                  id="form-dialog-title"
                  style={{
                    color: palette.primary,
                    borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                  }}>
                  {t("biodata:CVorganizationalExperience")}
                </DialogTitle>
                <DialogContent>
                  <div value={this.state.status}>
                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("biodata:form.field.organizationName")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <TextValidator
                      style={{ width: "97%", fontSize: 20 }}
                      validators={["required"]}
                      errorMessages={t(
                        "biodata:validation.required.organizationName"
                      )}
                      onChange={e => {
                        this.handleChange({
                          name: e.currentTarget.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t(
                        "biodata:form.placeholder.organizationName"
                      )}
                      className="Field-cfm-cv"
                      value={this.state.name}
                    />
                    <label
                      style={{
                        color: "#2E69B2",
                        fontSize: 14,
                        textTransform: "uppercase",
                        fontWeight: "lighter",
                      }}>
                      {t("biodata:form.field.organizationArea")}
                      <span
                        style={{ color: "#CD594D" }}
                        className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                        &thinsp;*
                      </span>
                    </label>
                    <TextValidator
                      style={{ width: "97%", fontSize: 20 }}
                      validators={["required"]}
                      errorMessages={t(
                        "biodata:validation.required.organizationArea"
                      )}
                      onChange={e => {
                        this.handleChange({
                          field: e.currentTarget.value,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t(
                        "biodata:form.placeholder.organizationArea"
                      )}
                      className="Field-cfm-cv"
                      value={this.state.field}
                    />
                    <div
                      value={this.state.tmpYear}
                      style={{
                        width: "97%",
                        fontSize: 20,
                      }}>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {t("biodata:form.field.year")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <DatePickerValidator
                        className="Profile-cfm-cv"
                        label={""}
                        format="yyyy"
                        maxDetail="decade"
                        style={{ width: 520 }}
                        value={this.state.tmpYear}
                        onChange={e => {
                          if (e) {
                            General.visible = true;
                            this.handleChange({
                              tmpYear: e,
                              year: e,
                            });
                          }
                        }}
                        yearPlaceholder={t("biodata:form.placeholder.year")}
                      />
                    </div>
                    <div>
                      <label
                        style={{
                          color: "#2E69B2",
                          fontSize: 14,
                          textTransform: "uppercase",
                          fontWeight: "lighter",
                        }}>
                        {t("biodata:form.field.organizationPosition")}
                        <span
                          style={{ color: "#CD594D" }}
                          className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                          &thinsp;*
                        </span>
                      </label>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: 8,
                        }}
                        className="radio">
                        <label
                          style={{
                            fontSize: 18,
                            color: palette.primary,
                            marginRight: 40,
                            marginBottom: 5,
                          }}>
                          <input
                            style={{
                              color: "#52C8F4",
                            }}
                            type="radio"
                            options={position}
                            value={this.state.position}
                            checked={this.state.position === "member"}
                            onChange={option => {
                              this.handleChange({ position: "member" });
                            }}
                          />
                          {member}
                        </label>

                        <label
                          style={{
                            fontSize: 18,
                            color: palette.primary,
                            marginRight: 40,
                            marginBottom: 10,
                          }}>
                          <input
                            style={{
                              color: "#52C8F4",
                            }}
                            type="radio"
                            options={position}
                            value={this.state.position}
                            checked={this.state.position === "Committee"}
                            onChange={option => {
                              this.handleChange({ position: "Committee" });
                            }}
                          />
                          {committe}
                        </label>
                      </div>
                      {this.state.position === "Committee" && (
                        <TextValidator
                          style={{
                            width: "94%",
                            fontSize: 20,
                            marginLeft: 20,
                          }}
                          onChange={e => {
                            this.handleChange({
                              function: e.currentTarget.value,
                            });
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={t("biodata:form.placeholder.function")}
                          className="Field-cfm-cv"
                          value={this.state.function}
                        />
                      )}
                    </div>
                  </div>
                </DialogContent>
                <DialogActions
                  style={{
                    backgroundColor: palette.primary,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                  }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        onClick={this.handleClose}
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {t("biodata:CVbtnCancel")}
                      </Button>
                    </div>
                    <div style={{ width: "76%" }}></div>
                    <div style={{ width: "12%", textAlign: "center" }}>
                      <Button
                        type="submit"
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: 18,
                          textDecoration: "underline",
                        }}>
                        {isedit
                          ? t("biodata:CVbtnEdit")
                          : t("biodata:CVbtnAdd")}
                      </Button>
                    </div>
                  </div>
                </DialogActions>
              </ValidatorForm>
            </div>
          </div>
        </Dialog>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "47%",
              marginTop: "4%",
              width: "100%",
            }}>
            <div
              style={{
                width: "100%",
              }}>
              {listOrganization.length === 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10%",
                  }}>
                  <div>
                    <img src={IconFolder} alt="Folder" />
                  </div>
                  <div>
                    <label style={{ color: palette.primary }}>
                      Click the Add button to add new <br />{" "}
                      {t("biodata:CVorganizationalExperience")}
                    </label>
                  </div>
                </div>
              )}
              {listOrganization.map((v, k) => {
                var posisi = v.position;
                if (v.position === "Committee") {
                  posisi = v.function;
                }
                return (
                  <div
                    key={k}
                    style={{
                      borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                    }}>
                    <div>
                      <br />
                    </div>
                    <div
                      style={{
                        color: palette.primary,
                        fontSize: 18,
                        fontWeight: "bolder",
                      }}>
                      {v.name}
                    </div>
                    <div
                      style={{
                        color: palette.primary,
                        fontSize: 18,
                        fontWeight: "bolder",
                      }}>
                      {posisi}
                    </div>
                    <div
                      style={{
                        color: palette.primary,
                        fontSize: 18,
                        fontWeight: "lighter",
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div
                        style={{
                          width: "100%",
                        }}>
                        {v.field}
                      </div>
                      <div>
                        <img
                          onClick={() => {
                            this.handleClickOpen(k);
                          }}
                          style={{ cursor: "pointer" }}
                          src={IconEditCV}
                          alt="Edit"
                        />
                      </div>
                      <div style={{ width: "4%" }}></div>
                      <div>
                        <img
                          onClick={() => {
                            this.handleClickDelete(k);
                          }}
                          style={{ cursor: "pointer" }}
                          src={IconDeleteCV}
                          alt="Edit"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: "85%",
                        color: palette.primary,
                        fontSize: 18,
                        fontWeight: "lighter",
                        marginTop: "-1.5%",
                      }}>
                      {v.year}
                    </div>
                    <div>
                      <br />
                    </div>
                    <DialogueConfirm
                      open={opendelete}
                      title={t("auth:CVdeleteConfirm.applicant.title")}
                      content={t("auth:CVdeleteConfirm.applicant.message")}
                      btnYes={t("auth:CVdeleteConfirm.applicant.btnOk")}
                      btnNo={t("auth:CVdeleteConfirm.applicant.btnCancel")}
                      onOK={() => this.deleteorganizationexperience()}
                      onClose={() => {
                        this.setState({ opendelete: false });
                      }}
                    />
                    
                  </div>
                );
              })}
            </div>
          </div>
          <DialogueInfo
            title={t("biodata:validation.dialog.title")}
            content={t("biodata:validation.dialog.content")}
            open={openValidation}
            onClose={() => {
              this.setState({ openValidation: false });
            }}
          />
          <ConfirmFooter
            text={t("button.save")}
            NextClick={() => {
              this.submitdata();
            }}
          />
        </div>
      </div>
    );
  }
}
export default withTranslation()(ConfirmationOrganizationExperience);
