import React from "react";
import { ListItem, Divider, ListItemText, List } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { HeaderLogo } from "..";
import { palette } from "assets/css/main";
import { Link } from "react-router-dom";
import { getItem, RequestGet, setItem } from "app/utils";
// import { IcnNotif } from "assets/img";
import i18next from "i18next";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import { t } from "app/utils";

//masalah loading terus( memori usage nambah )karena lu bikin class namanya "List" sedangkan di dalam rendernya ada manggil component "List" juga yg harusnya di import dari material/core
//yg manggil component ga error walaupun belom import, karena ngambil dari nama class.
class ListContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifCount: 0,
      anchorEl: false,
      ...JSON.parse(getItem("company_info")),
      lang: getItem("lang"),
    };
  }
  handleClickCompany = e => {
    this.setState({ anchorEl: !this.state.anchorEl });
  };
  render() {
    const { anchorEl, notifCount, companyNameBrand } = this.state;
    return (
      <div role="presentation">
        <List>
          {[
            {
              text: t("menuRecruiterAppbar.dashboard"),
              path: "/recruiter/home",
            },
            {
              text: t("menuRecruiterAppbar.candidates"),
              path: "/recruiter/candidates",
            },
          ].map(val => (
            <Link
              to={val.path}
              key={val.text}
              style={{ color: palette.primary }}>
              <ListItem button>
                <ListItemText primary={val.text} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
        <List>
          <Link
            to={"/recruiter/notification"}
            style={{ color: palette.primary }}>
            <ListItem button>
              <div
                style={{
                  position: "absolute",
                  width: "20px",
                  height: "20px",
                  left: 120,
                  backgroundColor: "#52C8F4",
                  borderRadius: "20px",
                  color: "white",
                  textAlign: "center",
                }}>
                <span style={{ fontSize: 12 }}>{String(notifCount)}</span>
              </div>
              <ListItemText primary={t("link.notification")} />
            </ListItem>
          </Link>
        </List>
        <List>
          <ListItem
            button
            onClick={this.handleClickCompany}
            style={{ fontSize: "1rem", color: palette.primary }}>
            {companyNameBrand}
            {Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </List>
      </div>
    );
  }
}

class RecruiterAppBarMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notifCount: 0,
      anchorEl: null,
      ...JSON.parse(getItem("company_info")),
      lang: getItem("lang"),
      top: false,
      left: false,
      bottom: false,
      right: false,
    };
  }

  handleClickMenu(e) {
    this.setState({ right: e });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  closeLang() {
    var l = getItem("lang") === "id" ? "en" : "id";
    this.setState({ lang: l });
    i18next.changeLanguage(l);
    setItem("lang", l);
    this.setState({ lang: l });
    window.location.reload();
  }

  getNotification() {
    RequestGet("notification", { loading: false })
      .then(res => {
        this.setState({
          notifCount: res.data.countUnread < 10 ? res.data.countUnread : "9+",
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getNotification();
  }

  render() {
    const { right } = this.state;
    console.log(right);
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          top: 0,
          padding: "20px",
          width: "100%",
          bottom: "auto",
          position: "fixed",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)",
          justifyContent: "space-between",
          boxSizing: "border-box",
          zIndex: 1201,
        }}>
        <HeaderLogo />
        {!right ? (
          <MenuIcon
            style={{ color: palette.primary }}
            onClick={() => {
              this.handleClickMenu(true);
            }}
          />
        ) : (
          <CloseIcon
            style={{ color: palette.primary }}
            onClick={() => {
              this.handleClickMenu(true);
            }}
          />
        )}
        <Drawer
          className={"HGMobile"}
          anchor={"right"}
          open={right}
          onClose={() => {
            this.handleClickMenu(false);
          }}>
          <ListContent />
        </Drawer>
      </div>
    );
  }
}
export default withTranslation()(RecruiterAppBarMobile);
