import React from "react";
import { Dialog, Grid, Typography, Button } from "@material-ui/core";
import { palette, PRIMARY_BUTTON } from "assets/css/main";
import propTypes from "prop-types";
import Moment from "react-moment";
import UploaderOfferingLetter from "app/pages/recruiter/dashboard/UploaderOfferingLetter";

class DialogueSendOfferingFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileoffering: "",
      part: "",
    };
  }

  render() {
    const { open, title, content, btnYes, btnNo, time } = this.props;
    const { fileoffering } = this.state;
    var isEnable = false;
    if (fileoffering !== "") {
      isEnable = true;
    }
    return (
      <Dialog
        className={""}
        aria-labelledby="customized-dialog-title"
        disableBackdropClick
        open={open}
        style={{ backgroundColor: "transparent" }}>
        <Grid
          container
          justify="center"
          alignItems="flex-end"
          style={{
            backgroundColor: "transparent",
            width: 600,
            //height: 354,
            //paddingTop: 60,
          }}>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              paddingTop: 40,
              paddingRight: 350,
              backgroundColor: "white",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              fontWeight: "bold",
              fontSize: 18,
              color: palette.primary,
              width: "100%",
            }}>
            <div style={{ marginBottom: 10 }}>{title}</div>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          direction="row"
          alignItems="flex-start"
          style={{
            backgroundColor: "#ffff",
            paddingRight: 75,
            width: 600,
          }}>
          <Typography
            style={{
              color: palette.primary,
              marginBottom: 10,
              marginLeft: 40,
              fontSize: 14,
            }}>
            {content}
            <span
              style={{
                color: palette.primary,
                marginBottom: 10,
                //maxWidth: 350,
                fontSize: 14,
                borderBottom: "1px solid #DFE5F4",
              }}></span>
          </Typography>
          <UploaderOfferingLetter
            isBlob={false}
            key={1}
            accept="application/pdf"
            label={""}
            style={{
              width: 532,
              height: 70,
              fontSize: 12,
              marginBottom: 40,
              marginLeft: 75,
            }}
            previewStyle={{
              width: fileoffering === "" ? 520 : 30,
            }}
            iconStyle={{ width: 30, height: 30, marginRight: 10 }}
            labelStyle={{
              fontSize: 12,
              textAlign: "center",
              fontWeight: "lighter",
            }}
            onDropFile={o => {
              this.setState({
                offeringletter: o.file ? "https://offeringletter.pdf" : "",
                fileoffering: o,
                part: "offering",
              });
            }}
            value={this.state.offeringletter}
          />
          {time && (
            <div
              style={{
                backgroundColor: palette.info,
                color: palette.primary,
                padding: 10,
                marginBottom: 30,
                textAlign: "center",
              }}>
              <Moment
                date={time}
                add={{ days: 3, hours: 12 }}
                format="dddd, DD MMMM yyyy hh:mm \WIB"
              />
            </div>
          )}
        </Grid>
        <Grid
          container
          justify="space-between"
          style={{
            //height: 70,
            backgroundColor: "white",
            paddingLeft: 270,
            paddingRight: 40,
            paddingBottom: 25,
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
          }}>
          <Button
            style={{
              color: palette.primary,
              backgroundColor: "white",
              textTransform: "capitalize",
              border: "1px solid #2E69B2",
              width: 128,
              height: 44,
            }}
            onClick={() => {
              this.props.onClose();
              this.setState({
                offeringletter: "",
                fileoffering: "",
              });
            }}>
            {btnNo}
          </Button>
          <Button
            disabled={!isEnable}
            style={{
              ...PRIMARY_BUTTON,
              textTransform: "capitalize",
              color: "white",
              width: 128,
              height: 44,
              opacity: isEnable ? 1 : 0.5,
            }}
            onClick={() => {
              this.props.onOK(this.state);
              this.setState({
                offeringletter: "",
                fileoffering: "",
              });
            }}>
            {btnYes}
          </Button>
        </Grid>
      </Dialog>
    );
  }
}

DialogueSendOfferingFile.propTypes = {
  title: propTypes.string,
  content: propTypes.string,
  open: propTypes.bool,
  onClose: propTypes.func,
  onOK: propTypes.func,
  btnYes: propTypes.string,
  btnNo: propTypes.string,
};

DialogueSendOfferingFile.defaultProps = {
  btnYes: "Yes",
  btnNo: "No",
  title: "Sample title",
  content: "Success",
  open: true,
  onOK: () => {
    return true;
  },
  onClose: () => {
    return false;
  },
};

export default DialogueSendOfferingFile;
