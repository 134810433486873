import React from "react";
import { Dialog, Grid, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
export default class PreviewPage extends React.Component {
  render() {
    const { open, image, title } = this.props;
    return (
      <Dialog
        className={"Custom-Dialog "}
        aria-labelledby="customized-dialog-title"
        disableBackdropClick
        open={open}
      >
        <Button
          onClick={() => {
            if (this.props.onClose) {
              this.props.onClose(false);
            }
          }}
          style={{
            padding: 0,
            backgroundColor: palette.grey,
            width: 30,
            height: 30,
            minWidth: 30,
            position: "absolute",
            right: 0,
            top: 0,
            borderRadius: "50%",
          }}
        >
          X
        </Button>
        <Grid
          container
          justify="center"
          alignItems="flex-end"
          style={{
            backgroundColor: "transparent",
            padding: 25,
          }}
        >
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            style={{
              backgroundColor: palette.info,
              borderRadius: 10,
            }}
          >
            <img
              src={image}
              style={{ marginTop: 20, cursor: "pointer" }}
              width={"100%"}
              alt="Preview"
            />
          </Grid>
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
            style={{
              backgroundColor: palette.info,
              borderRadius: 5,
              height: 80,
              marginTop: 10,
              fontWeight: "bold",
              color: palette.primary,
            }}
          >
            {title}
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
