import React from "react";
import { palette } from "assets/css/main";
import { getItem, findObject, removeObject } from "app/utils";
import { TextField } from "@material-ui/core";
import ModalDetail from "./modal-detail";
import { IconQuestion } from "assets/img";
import ModalDescription from "./modal-description";
import PropTypes from "prop-types";

/**
 * @extends {React.Component<{value:any, multiple:boolean, onChange:Function, disable:boolean}>}
 */
export default class OptionWorkAbout extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    isNumber: PropTypes.bool,
    options: PropTypes.array,
    value: PropTypes.any,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    disable: PropTypes.bool,
  };
  static defaultProps = {
    value: "",
    multiple: false,
    onChange: () => {},
    disable: false,
    label: "",
    isNumber: false,
    options: [],
  };

  state = {
    active: "",
    otherValue: "",
    idNoPref: null,
    idOther: "",
    isOpenDetail: false,
    showDesc: false,
    textDesc: "",
    answerDesc: "",
    dataDetail: [],
  };
  char = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  setActive(val, set = false) {
    const x = [
      {
        userAnswer: val.answerId,
        otherValue: !set ? "" : set,
        // otherId: val.hasValue === true ? val.answerId : "",
        // answerDetails: val.answerDetail,
        // answerTextEn: val.answerTextEn,
      },
    ];
    var responseAnswer = this.props.value ? this.props.value : [];

    if (val.hasValue === true) {
      this.setState({ otherValue: set });
    }

    if (set) {
      x[0].otherValue = set;
    } else {
      if (val.hasValue === true) {
        var obj = findObject(responseAnswer, "userAnswer", val.answerId);
        this.setState({ otherValue: obj ? obj.otherValue : "" });
      }
    }

    /*if (val.answerTextEn.indexOf("No Preference") === 0) {
      this.setState({ idNoPref: val.answerId, otherValue: "" });
      this.props.onChange(x);
    } else*/ if (
      !this.props.multiple
    ) {
      this.props.onChange(x);
    } else {
      if (findObject(responseAnswer, "userAnswer", val.answerId)) {
        if (responseAnswer.length > 1) {
          if (!set) {
            this.props.onChange(
              removeObject(responseAnswer, "userAnswer", val.answerId)
            );
          } else {
            responseAnswer = removeObject(
              responseAnswer,
              "userAnswer",
              val.answerId
            );
            this.props.onChange([...x, ...responseAnswer]);
          }
        } else {
          this.props.onChange(x);
        }
      } else {
        this.props.onChange([...x, ...responseAnswer]);
      }
    }
  }

  handleClick(v, i) {
    // if (v.answerDetails) {
    //   if (v.answerDetails.length) {
    //     var responseAnswer = this.props.value
    //       ? this.props.value
    //       : [];

    //     if (findObject(responseAnswer, "userAnswer", v.answerId)) {
    //       this.props.onChange(
    //         removeObject(responseAnswer, "userAnswer", v.answerId)
    //       );
    //     } else {
    //       this.openDetail(v, this.char.split("")[i]);
    //     }
    //   } else {
    //     this.setActive(v);
    //   }
    // } else {
    this.setActive(v);
    // }
  }

  openDetail(v, n) {
    this.setDisplay(false);
    this.setState({ isOpenDetail: true, number: n, dataDetail: v });
  }

  setDisplay(value = true) {
    try {
      document.getElementById("modal-parent").style.display = value
        ? ""
        : "none";
    } catch (e) {}
  }

  rendering(min, max) {
    const { options, label, value, isNumber } = this.props;
    const responseAnswer = value ? this.props.value : [];
    // const responseDetail = value.length > 0 ? value : [];
    const disabled = this.props.disabled ? this.props.disabled : false;
    return options.map((v, i) => {
      if (i >= min && i < max) {
        return (
          <div
            className={label}
            key={i}
            style={{
              color: palette.primary,
              fontWeight: "bold",
              marginBottom: 15,
              fontSize: 32,
              cursor: "pointer",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "row",
              display: "flex",
            }}>
            <div
              style={{
                padding: "8px 10px 6px",
                fontSize: 14,
                textAlign: "center",
                width: 10,
                color: findObject(responseAnswer, "userAnswer", v.answerId)
                  ? "white"
                  : findObject(responseAnswer, "otherValue", "") === false ||
                    findObject(responseAnswer, "otherValue", null) === false
                  ? "white"
                  : palette.primary,
                backgroundColor: findObject(
                  responseAnswer,
                  "userAnswer",
                  v.answerId
                )
                  ? palette.primary
                  : findObject(responseAnswer, "otherValue", "") === false ||
                    findObject(responseAnswer, "otherValue", null) === false
                  ? palette.primary
                  : "white",
                borderRadius: 5,
                border: "1px solid " + palette.primary,
                fontWeight: "normal",
                marginRight: 10,
              }}
              onClick={() => {
                this.handleClick(v, i);
              }}>
              {isNumber ? i + 1 : this.char.split("")[i]}
            </div>
            <div style={{ paddingTop: 8, fontSize: 18, fontWeight: "normal" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  maxWidth: 300,
                }}>
                <span
                  style={{ paddingRight: 25 }}
                  onClick={() => {
                    this.handleClick(v, i);
                  }}>
                  {getItem("lang") === "en" ? v.answerTextEn : v.answerTextId}
                  {getItem("lang") === "en"
                    ? v.answerDetailEng
                    : v.answerDetailIna}
                </span>
                {v.descEng && (
                  <>
                    <img
                      onClick={() => {
                        try {
                          document.getElementById(
                            "content-detail"
                          ).style.display = "none";
                        } catch (error) {}
                        this.setState({
                          showDesc: true,
                          textDesc:
                            getItem("lang") === "en" ? v.descEng : v.descIna,
                          answerDesc:
                            getItem("lang") === "en"
                              ? v.answerTextEn
                              : v.answerTextId,
                        });
                      }}
                      src={IconQuestion}
                      style={{
                        position: "absolute",
                        width: 18,
                        opacity: 0.5,
                        cursor: "pointer",
                        right: 0,
                      }}
                      alt="quest"
                    />
                  </>
                )}
              </div>
              <div>
                {v.hasValue === true && (
                  <>
                    {findObject(responseAnswer, "userAnswer", v.answerId) && (
                      <TextField
                        multiline
                        disabled={disabled}
                        key={v.answerId}
                        onChange={e => {
                          this.setActive(v, e.currentTarget.value);
                          this.setState({ otherValue: e.currentTarget.value });
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={true}
                        placeholder={
                          getItem("lang") === "en"
                            ? "Please Specify"
                            : "Harap dituliskan"
                        }
                        label={""}
                        defaultValue={
                          findObject(responseAnswer, "userAnswer", v.answerId)
                            ? findObject(
                                responseAnswer,
                                "userAnswer",
                                v.answerId
                              ).otherValue
                            : ""
                        }
                        className="Field work-ready"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        return "";
      }
    });
  }

  render() {
    const { style, options, label, required, isEdit } = this.props;
    const {
      isOpenDetail,
      number,
      dataDetail,
      showDesc,
      answerDesc,
      textDesc,
    } = this.state;
    const maxInRow = options.length > 8 ? Math.ceil(options.length / 2) : 4;
    const width = 400;
    return (
      <div className="option" style={{ ...style, marginBottom: 20 }}>
        <ModalDetail
          data={dataDetail}
          open={isOpenDetail}
          number={number}
          isEdit={isEdit}
          onClose={v => {
            this.setState({ isOpenDetail: false });
            this.setDisplay(true);
            if (v) {
              this.setActive(v);
            }
          }}
        />
        <ModalDescription
          isOpen={showDesc}
          data={{ title: answerDesc, desc: textDesc }}
          onClose={() => {
            try {
              document.getElementById("content-detail").style.display = "";
            } catch (error) {}
            this.setState({ showDesc: false });
          }}
        />

        <div
          className="MuiInputLabel-root"
          style={{
            textTransform: "none",
            marginBottom: 10,
            color: palette.primary,
            fontWeight: "lighter",
          }}>
          {label}
          {required && (
            <span className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
              &thinsp;*
            </span>
          )}
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div style={{ width: width }}>{this.rendering(0, maxInRow)}</div>
          <div style={{ width: width }}>
            {this.rendering(maxInRow, maxInRow * 2)}
          </div>
        </div>
      </div>
    );
  }
}
