import React from "react";
import { withTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { ImgTestCompleted } from "assets/img";
import { palette } from "assets/css/main";
import { Header, NextBtn } from "app/components";
import MyHelmet from "app/components/header/MyHelmet";
import DialogueConfirm from "app/components/modal/confirm";
import { /*setItem,*/ AppHistory, getItem, setItem } from "app/utils";

class FinishTest extends React.Component {
  state = {
    open: false,
  };
  constructor(props) {
    super(props);
    if (getItem("EXAM_COMPLETED") === "true") {
      //uncoment-to-dashboard
      this.props.history.push("/user/home");
    }
  }
  render() {
    const { t } = this.props;
    return (
      <div style={{ paddingTop: 120, boxSizing: "border-box" }}>
        <MyHelmet title="Finish Test" />
        <DialogueConfirm
          open={this.state.open}
          title={t("finish:confirm.title")}
          content={t("finish:confirm.message")}
          btnYes={t("finish:confirm.btnYes")}
          btnNo={t("finish:confirm.btnNo")}
          onOK={() => this.props.history.push("/user/logout")}
          onClose={() => {
            this.setState({ open: false });
          }}
        />
        {/* <ApplicantAppBar hideMenu={true} /> */}
        <Header shadow={true} linkgroup={false} stepper={true} activeStep={2} />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            color: palette.primary,
            padding: "100px 100px 0px 100px",
            flexDirection: "row",
            minHeight: 500,
            boxSizing: "border-box",
          }}>
          <div>
            <p
              style={{
                fontWeight: "bold",
                fontSize: 32,
                padding: 0,
                margin: 0,
                marginBottom: 30,
              }}>
              {t("finish:title")}
            </p>
            {/* <p
              style={{
                fontWeight: "bold",
                fontSize: 32,
                padding: 0,
                margin: 0,
              }}>
              {t("finish:subTitle")}
            </p> */}
            <p style={{ fontSize: 18, width: 600 }}>
              {t("finish:description")}
            </p>
            <p style={{ fontSize: 18, width: 600 }}>
              {t("finish:subDescription")}
            </p>
          </div>
          <div>
            <img
              src={ImgTestCompleted}
              height={279}
              width={626}
              alt={"Finish Hiregrade Test"}
            />
          </div>
        </div>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            height: 60,
            // display: "none",
            position: "fixed",
            bottom: 0,
            backgroundColor: palette.primary,
            paddingRight: 50,
          }}>
          <NextBtn
            color={"white"}
            onClick={() => {
              this.setState({ open: true });
              //uncoment-to-dashboard
              setItem("EXAM_COMPLETED", true);
              AppHistory.push("/user/home");
            }}
            text={t("button.next")}
          />
        </Grid>
      </div>
    );
  }
}
export default withTranslation()(FinishTest);
