import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  IcnAddress,
  IcnPhone,
  IcnContactMail,
  IcnBirthday,
  IcnGender,
  IcnMarital,
  IcnKTP,
  IcnNovice6,
  IcnIntermediate6,
  IcnAdvanced6,
} from "assets/img";
import moment from "moment";
import { getItem, loadImage } from "app/utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  sectionLeft: {
    height: "100%",
    width: "31%",
    opacity: 0.78,
    padding: "46pt 23pt 0pt 50pt",
    display: "flex",
    flexDirection: "column",
  },
  sectionRight: {
    height: "100%",
    width: "69%",
    padding: "32pt 32pt 0pt 23pt",
    display: "flex",
    flexDirection: "column",
  },
});

const WorkExperienceView = props => {
  return (
    <View
      style={{
        marginTop: 28,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        style={{
          fontFamily: "Avenir",
          fontSize: 14,
          fontWeight: "bold",
          color: "#000000",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.workExp")}
      </Text>
      {
        props.data.map((v,i) => {
          return (
            <View key={i}
              style={{
                marginTop: 9,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {v.name}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {v.role}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 10,
                  color: "#000000",
                }}
              >
                {(getItem("lang") === "en" ? moment(v.yearStart).lang("en").format("MMMM YYYY") : moment(v.yearStart).format("MMMM YYYY")) + " - " + (v.yearEnd ? v.yearEnd : props.t("biodata:cv_template.hg1.workYearEnd"))}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 10,
                  color: "#000000",
                }}
              >
                {
                  v.status === "1" ? props.t("biodata:cv_template.hg1.employeeStatus.1") : (
                    v.status === "2" ? props.t("biodata:cv_template.hg1.employeeStatus.2") : (
                      v.status === "3" ? props.t("biodata:cv_template.hg1.employeeStatus.3") : null
                    )
                  )
                }
              </Text>
            </View>
          )
        })
      }
    </View>
  )
}

const FormalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 28,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        style={{
          fontFamily: "Avenir",
          fontSize: 14,
          fontWeight: "bold",
          color: "#000000",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.formalEdu")}
      </Text>
      {
        props.data.map((v,i) => {
          return (
            <View key={i}
              style={{
                marginTop: 9,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {v.institution}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {v.degree + " | " + v.major}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 10,
                  color: "#000000",
                }}
              >
                {"GPA : " + v.grade}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 10,
                  color: "#000000",
                }}
              >
                {"Graduated : " + v.yearGraduate}
              </Text>
            </View>
          )
        })
      }
    </View>
  )
}

const InformalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 28,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        style={{
          fontFamily: "Avenir",
          fontSize: 14,
          fontWeight: "bold",
          color: "#000000",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.informalEdu")}
      </Text>
      {
        props.data.map((v,i) => {
          return (
            <View key={i}
              style={{
                marginTop: 9,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {v.institution}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {v.name}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 10,
                  color: "#000000",
                }}
              >
                {v.year}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 10,
                  color: "#000000",
                }}
              >
                {"Certificate : " + (v.isSertifikat ? props.t("biodata:cv_template.hg1.certificate.true") : props.t("biodata:cv_template.hg1.certificate.false"))}
              </Text>
            </View>
          )
        })
      }
    </View>
  )
}

const OrganizationalExperienceView = props => {
  return (
    <View
      style={{
        marginTop: 28,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        style={{
          fontFamily: "Avenir",
          fontSize: 14,
          fontWeight: "bold",
          color: "#000000",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.organizationalExp")}
      </Text>
      {
        props.data.map((v,i) => {
          return (
            <View key={i}
              style={{
                marginTop: 9,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {v.name}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#000000",
                  opacity: 0.78,
                }}
              >
                {
                  v.position === "1" ? props.t("biodata:cv_template.hg1.organizationalPosition.1") : (
                    v.position === "2" ? (
                      v.function ? v.function : props.t("biodata:cv_template.hg1.organizationalPosition.2")
                    ) : null
                  )
                }
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 10,
                  color: "#000000",
                }}
              >
                {v.field}
              </Text>
              <Text
                style={{
                  fontFamily: "Avenir",
                  marginTop: 2,
                  fontSize: 10,
                  color: "#000000",
                }}
              >
                {v.year}
              </Text>
            </View>
          )
        })
      }
    </View>
  )
}

const LanguageView = props => {
  return (
    <View
      style={{
        marginTop: 30,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text
        style={{
          fontFamily: "Avenir",
          fontSize: 14,
          fontWeight: "bold",
          color: "#000000",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.language")}
      </Text>
      {
        props.data.map((v,i) => {
          return (
            <View key={i}
              style={{
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#000000",
                }}
              >
                {getItem("lang") === "en" ? v.languageSkillEng : v.languageSkillIna}
              </Text>
              <Image
                src={
                  v.proficiency === 1 ? IcnNovice6 : (
                    v.proficiency === 2 ? IcnIntermediate6 : (
                      v.proficiency === 3 ? IcnAdvanced6 : null
                    )
                  )
                }
                style={{
                  marginTop: 6,
                  width: "100%",
                  height: 5,
                }}
              />
            </View>
          )
        })
      }
    </View>
  )
}

const TemplateHG6 = props => (
  <Document
    title={"CV - " + props.data.personalInfo.name}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <Page size="A4" style={styles.page}>
      <View style={styles.sectionLeft}>
        <Text
          style={{
            fontFamily: "Avenir",
            fontSize: 25,
            fontWeight: "bold",
            color: "#000000",
            opacity: 0.78,
            textTransform: "uppercase",
          }}
        >
          {props.data.personalInfo.name}
        </Text>
        <Text
          style={{
            fontFamily: "Avenir",
            marginTop: 7,
            fontSize: 14,
            fontWeight: "bold",
            color: "#000000",
            opacity: 0.78,
          }}
        >
          {props.data.listWorks.length > 0 ? props.data.listWorks[0].role : null}
        </Text>
        <Image 
          src={props.data.personalInfo.photo + "?t=" + new Date().getTime()}
          style={{
            marginTop: 30,
            width: 94,
            height: 94,
            borderRadius: 47,
          }}
        />
        <View
          style={{
            marginTop: 30,
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontFamily: "Avenir",
              fontSize: 14,
              fontWeight: "bold",
              color: "#000000",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.bio")}
          </Text>
          <View
            style={{
              marginTop: 12,
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnBirthday}
                style={{
                  width: 10,
                  height: 10,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#000000",
                }}
              >
                {props.data.personalInfo.birthPlace + ", " + (getItem("lang") === "en" ? moment(props.data.personalInfo.birthDate).lang("en").format("DD MMMM YYYY") : moment(props.data.personalInfo.birthDate).format("DD MMMM YYYY"))}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginTop: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnGender}
                style={{
                  width: 7,
                  height: 7,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#000000",
                }}
              >
                {
                  props.data.personalInfo.gender === "M" ? props.t("biodata:cv_template.hg1.gender.m") : (
                    props.data.personalInfo.gender === "F" ? props.t("biodata:cv_template.hg1.gender.f") : null
                  )
                }
              </Text>
            </View>
          </View>
          <View
            style={{
              marginTop: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnMarital}
                style={{
                  width: 8,
                  height: 8,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#000000",
                }}
              >
                {props.data.personalInfo.maritalStatus}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginTop: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnKTP}
                style={{
                  width: 9,
                  height: 7,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 8,
                  color: "#000000",
                }}
              >
                {props.data.personalInfo.identityNumber}
              </Text>
            </View>
          </View>
        </View>
        {props.data.personalInfo.listUserLanguage.length > 0 ?
          <LanguageView 
            data={props.data.personalInfo.listUserLanguage}
            t={props.t}
          /> : null
        }
        <View
          style={{
            width: "250%",
            height: 67,
            position: "absolute",
            bottom: 0,
            padding: "0pt 0pt 27pt 50pt",
          }}
        >
          {
            props.data.toogle &&
            <View
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Image 
                src={loadImage("qr-code.png")}
                style={{
                  width: 40,
                  height: 40,
                }}
              />
              <View
                style={{
                  marginLeft: 8,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Avenir",
                    fontWeight: "bold",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrTitle")}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrDesc")}
                </Text>
                <Text
                  style={{
                    fontFamily: "Avenir",
                    fontWeight: "bold",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrLink")}
                </Text>
              </View>
            </View>
          }
        </View>
      </View>
      <View style={styles.sectionRight}>
        <View
          style={{
            paddingTop: 14,
            borderTop: "1pt solid #aaaaaa",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            borderBottom: "1pt solid #aaaaaa",
            paddingBottom: 14,
            marginBottom: 28,
          }}
        >
          <View
            style={{
              width: "35%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnAddress}
                style={{
                  width: 12,
                  height: 9,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 9,
                  color: "#000000",
                }}
              >
                {props.data.personalInfo.address}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginLeft: "5%",
              width: "25%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnPhone}
                style={{
                  width: 9,
                  height: 9,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 9,
                  color: "#000000",
                }}
              >
                {"+62 " + props.data.personalInfo.phoneNumber}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginLeft: "5%",
              width: "30%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                width: "15%",
              }}
            >
              <Image 
                src={IcnContactMail}
                style={{
                  width: 9,
                  height: 9,
                }}
              />
            </View>
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Avenir",
                  fontSize: 9,
                  color: "#000000",
                }}
              >
                {props.data.email ? props.data.email : " - "}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontFamily: "Avenir",
              fontSize: 14,
              fontWeight: "bold",
              color: "#000000",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg5.profile")}
          </Text>
          <Text
            style={{
              fontFamily: "Avenir",
              marginTop: 9,
              fontSize: 10,
              color: "#000000",
            }}
          >
            {props.data.personalInfo.description}
          </Text>
        </View>
        {props.data.listWorks.length > 0 ? 
          <WorkExperienceView 
            data={props.data.listWorks}
            t={props.t}
          /> : null
        }
        {props.data.listFormalEducation.length > 0 ?
          <FormalEducationView 
            data={props.data.listFormalEducation}
            t={props.t}
          /> : null
        }
        {props.data.listInformalEducation.length > 0 ?
          <InformalEducationView 
            data={props.data.listInformalEducation}
            t={props.t}
          /> : null
        }
        {props.data.listOrganization.length > 0 ?
          <OrganizationalExperienceView 
            data={props.data.listOrganization}
            t={props.t}
          /> : null
        }
      </View>
    </Page>
  </Document>
);
export default TemplateHG6;
