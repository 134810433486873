import React from "react";
import { palette } from "assets/css/main";
import { FiberManualRecord } from "@material-ui/icons";
export const Item = props => {
  const data = props.items ? props.items : [];
  return (
    <div
      style={{
        color: props.color ? props.color : palette.primarydark,
        display: "flex",
        flexDirection: "column",
        fontSize: props.fontSize ? props.fontSize : 18,
      }}>
      {data.map((v, k) => {
        return (
          <div
            key={k}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
            }}>
            <FiberManualRecord
              style={{
                fontSize: props.fontSize ? props.fontSize - 10 : 12,
                paddingRight: 10,
              }}
            />
            {v}
          </div>
        );
      })}
    </div>
  );
};
