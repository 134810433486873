import React from "react";
import { withTranslation } from "react-i18next";
// import { palette } from "assets/css/main";
// import UserProfileSidePanel from "./side-panel";
import HeaderProfile from "./header";
import { ApplicantHeader, MyHelmet } from "app/components";
// import { Tab, Tabs } from "@material-ui/core";
import "./profile.css";
import { getItem } from "app/utils";
// import TableContent from "./tab-content";

export default withTranslation("user-profile")(
  class CandidatesInformation extends React.Component {
    state = {
      value: 0,
      tabContent: [""],
      tabs: [],
      lang: getItem("lang"),
      data: {
        personalInfo: { firstName: "", id: "" },
      },
    };

    componentDidMount() {
      window.scrollTo(0, 0);
      // this.getSoftSkill();
    }
    
    // take out feature in 01/11/2023
    // getSoftSkill() {
    //   RequestGet("userresult")
    //     .then(r => {
    //       const { data } = r;

    //       this.setState({
    //         lang: getItem("lang"),
    //         tabs: data.map(v => {
    //           return { eng: v.categoryNameEng, ina: v.categoryNameIna };
    //         }),
    //         tabContent: data.map((v, k) => {
    //           return <TableContent key={k} data={v.softSkillHeaders} />;
    //         }),
    //       });
    //     })
    //     .catch(e => {
    //       console.log("getSoftSkill()", e);
    //     });
    // }

    // handleChangeTab(x, n) {
    //   this.setState({ value: n });
    // }

    render() {
      // const { value, tabContent, tabs, lang } = this.state;
      // if (getItem("lang") !== lang) {
      //   this.getSoftSkill();
      // }
      return (
        <div style={{ height: "100%", boxSizing: "border-box" }}>
          <ApplicantHeader menu="profile" />
          <MyHelmet title="Candidate Information" />
          <div
            style={{ paddingTop: 80, height: "100%", boxSizing: "border-box" }}>
            <HeaderProfile
              onRender={data => {
                this.setState({ data: data });
              }}
            />
            {/* take out feature in 01/11/2023 */}
            {/* <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                minHeight: 650,
                boxSizing: "border-box",
              }}>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  backgroundColor: palette.lightGrey,
                  boxSizing: "border-box",
                  justifyContent: "flex-end",
                }}>
                <UserProfileSidePanel view="diagnostic" />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  height: "100%",
                  boxSizing: "border-box",
                  flexDirection: "column",
                  padding: "25px 40px",
                  color: palette.primary,
                }}>
                <Tabs
                  value={value}
                  onChange={(x, n) => this.handleChangeTab(x, n)}
                  indicatorColor="primary"
                  textColor="primary"
                  className="Tabs-User-Profile"
                  style={{
                    borderBottom: "1px solid " + palette.popacity(0.2),
                  }}>
                  {tabs.map((v, k) => {
                    return (
                      <Tab
                        key={k}
                        label={getItem("lang") === "en" ? v.eng : v.ina}
                        style={{
                          textTransform: "capitalize",
                          color: palette.primary,
                          opacity: value === k ? 1 : 0.5,
                        }}
                      />
                    );
                  })}
                </Tabs>
                <div style={{ padding: "30px 0px 0px" }}>
                  {tabContent[value]}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      );
    }
  }
);
