import React from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import {
  AppHistory,
  base64toBlob,
  getItem,
  RequestPost,
  setItem,
} from "app/utils";
import "./style.css";
import imageCompression from 'browser-image-compression';

export default class CandidTaken extends React.Component {
  taken = null;
  state = {
    isStart: false,
    hideCamera: false,
    camera: <div></div>,
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        hideCamera: false,
        camera: (
          <Camera
            style={{ width: 200 }}
            className="Ok"
            onCameraStart={() => {
              this.startCamera();
            }}
            isSilentMode={true}
            onTakePhoto={dataUri => {
              this.handleTakePhoto(dataUri);
            }}
            onCameraError={error => {
              this.handleCameraError(error);
            }}
          />
        ),
      });
    }, 18000);
  }

  startCamera() {
    const to = Math.floor(Math.random() * (20000 - 5000 + 1) + 5000);
    setTimeout(() => {
      if (this.props.id) {
        document.getElementById("outer-circle").click();
      }
    }, to);
  }

  async handleTakePhoto(dataUri) {
    var arr = dataUri.split(",");
    var file = base64toBlob(arr[1], "image/png");

    // compress image
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1600,
      useWebWorker: true
    }

    var data = new FormData();
    try {
      const compressedFile = await imageCompression(file, options);
      data.append("examAttemptsId", this.props.id);
      data.append("candidFile", compressedFile, this.props.id + ".png");
      data.append("loading", false);
      data.append("prevent_error", true);
    } catch(err) {
      console.log(err);
    }

    this.setState({ camera: <div></div>, hideCamera: true });
    RequestPost("ExamAttemptsPhoto", data)
    .then((res) => {
      console.log("Kirim . . .");
      console.log(res);
      this.setState({ camera: <div></div>, hideCamera: true });
    })
    .catch(err => {
      console.log("GAGAL");
      console.log(err);
    });
  }

  async getMedia() {
    await navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(() => {})
      .catch(() => {
        AppHistory.push("/initialize/pretest");
      });
  }

  handleCameraError(error) {
    console.log(error);
    if (getItem("Block_Camera")) {
      if (Boolean(getItem("Block_Camera") === "true")) {
        AppHistory.push("/initialize/pretest");
      } else {
        this.getMedia();
      }
    } else {
      setItem("Block_Camera", true);
      AppHistory.push("/initialize/pretest");
    }
  }

  render() {
    const { camera, hideCamera } = this.state;
    return (
      <div
        style={{
          position: "absolute",
          backgroundColor: "red",
          zIndex: -200,
        }}>
        {hideCamera ? (
          <></>
        ) : (
          <>
            {/* <div
              style={{
                height: 200,
                width: 200,
                backgroundColor: "white",
                position: "absolute",
                zIndex: -20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bottom: 70,
                right: 70,
              }}> */}
              {camera}
            {/* </div> */}
          </>
        )}
      </div>
    );
  }
}
