import React from "react";
import { palette } from "assets/css/main";

class BadgeCountInfo extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: 10,
          minWidth: 21,
          minHeight: 21,
          fontWeight: "normal",
          borderRadius: this.props.circle ? "50%" : 10,
          padding: this.props.circle ? "none" : "0px 8px",
          backgroundColor: palette.info,
        }}
      >
        {this.props.count ? this.props.count : 0}
      </div>
    );
  }
}
export { BadgeCountInfo };
