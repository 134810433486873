import React from "react";
import { withTranslation } from "react-i18next";
import { ApplicantHeader } from "app/components";
import { HGVideoRecorder } from "app/components";
import { AppHistory, removeItem, setItem } from "app/utils";
import { palette } from "assets/css/main";
import DialogueConfirm from "app/components/modal/confirm";

export default withTranslation()(
  class InterviewSamplePage extends React.Component {
    state = {
      openDialogue: false,
      lastQuestion: false,
      interviewQuestion: [{}],
      current: 1,
      isLoaded: true,
      answered: 0,
      openConfirm: false,
      retry: {},
      progressDialog: false,
      progress: 0,
      retryDialog: false,
      dialogConfirm: false,
    };
    componentDidMount() {
      setItem("TRIAL_VIDEO", true);
    }

    render() {
      const { isLoaded, dialogConfirm } = this.state;
      const { t } = this.props;
      return (
        <div style={{ paddingBottom: 80 }}>
          <ApplicantHeader hideMenu={true} />
          <DialogueConfirm
            open={dialogConfirm}
            title={t("cv:videoInterview.confirm.title")}
            content={t("cv:videoInterview.confirm.content")}
            onOK={() => {
              AppHistory.push("/user/interview");
              removeItem("TRIAL_VIDEO");
            }}
            btnNo={t("button.cancel")}
            btnYes={"OK"}
            onClose={() => this.setState({ dialogConfirm: false })}
          />
          <div
            style={{
              padding: "120px 100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 100,
            }}>
            {isLoaded && (
              <div
                style={{
                  width: 730,
                  height: 400,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: palette.primary,
                }}>
                <span
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                    marginBottom: 15,
                  }}>
                  {t("cv:videoInterview.sample.title")}
                </span>
                <div
                  style={{
                    marginBottom: 20,
                    width: 455,
                    backgroundColor: palette.info,
                    padding: "20px 25px",
                    borderRadius: 10,
                    textAlign: "center",
                  }}>
                  {t("cv:videoInterview.sample.question")}
                </div>
                <HGVideoRecorder
                  timeLimit={1}
                  countdownTime={1}
                  onSubmit={() => {
                    this.setState({ dialogConfirm: true });
                  }}
                  onRetake={() => {}}
                  lastQuestion={true}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  }
);
