import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { ImgRecruiter } from "assets/img";
import { Item } from "app/components/general/Items";
import { withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

class Employer extends React.Component {
  render() {
    const { t } = this.props;
    const recruiters = t("about:home.recruiters.items", {
      returnObjects: true,
    });
    const educationalInstitutions = t(
      "about:home.educationalInstitutions.items",
      { returnObjects: true }
    );
    const candidates = t("about:home.candidates.items", {
      returnObjects: true,
    });
    if (isMobile) {
      return (
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          direction="column"
          style={{
            backgroundColor: palette.secondary,
            minHeight: 100,
            padding: "40px 25px",
          }}>
          <Typography
            style={{
              color: palette.primarydark,
              fontSize: 24,
              fontWeight: "bold",
            }}>
            {t("about:home.title")}
          </Typography>
          <img
            src={ImgRecruiter}
            alt="Recruiter"
            style={{ width: 176, height: 219, padding: "35px 0px" }}
          />
          <Grid container direction="column">
            <Grid item lg={6} md={6} style={{ marginBottom: 30 }}>
              <Typography
                style={{
                  color: palette.primarydark,
                  fontSize: 18,
                  fontWeight: "bold",
                }}>
                {t("about:home.recruiters.title")}
              </Typography>
              <Item fontSize={16} items={recruiters} />
            </Grid>
            <Grid item lg={6} md={6} style={{ marginBottom: 30 }}>
              <Typography
                style={{
                  color: palette.primarydark,
                  fontSize: 18,
                  fontWeight: "bold",
                }}>
                {t("about:home.educationalInstitutions.title")}
              </Typography>
              <Item fontSize={16} items={educationalInstitutions} />
            </Grid>
            <Grid item lg={6} md={6} style={{ marginBottom: 30 }}>
              <Typography
                style={{
                  color: palette.primarydark,
                  fontSize: 18,
                  fontWeight: "bold",
                }}>
                {t("about:home.candidates.title")}
              </Typography>
              <Item fontSize={16} items={candidates} />
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid
          container
          style={{
            backgroundColor: palette.secondary,
          }}>
          <div
            style={{
              display: "flex",
              padding: "120px 0px",
              justifyContent: "space-around",
              width: "100%",
            }}>
            <div style={{}}>
              <img
                src={ImgRecruiter}
                alt="Recruiter"
                style={{ width: 360, height: 469 }}
              />
            </div>
            <div>
              <div
                style={{
                  position: "relative",
                  marginLeft: 150,
                }}>
                <Typography
                  style={{
                    marginBottom: 20,
                    color: palette.primarydark,
                    fontSize: 32,
                    fontWeight: "bold",
                  }}>
                  {t("about:home.title")}
                </Typography>
                <Grid container direction="column" style={{ width: 750 }}>
                  <Grid item lg={6} md={6} style={{ marginBottom: 30 }}>
                    <Typography
                      style={{
                        color: palette.primarydark,
                        fontSize: 24,
                        fontWeight: "bold",
                      }}>
                      {t("about:home.recruiters.title")}
                    </Typography>
                    <Item items={recruiters} />
                  </Grid>
                  <Grid item lg={6} md={6} style={{ marginBottom: 30 }}>
                    <Typography
                      style={{
                        color: palette.primarydark,
                        fontSize: 24,
                        fontWeight: "bold",
                      }}>
                      {t("about:home.educationalInstitutions.title")}
                    </Typography>
                    <Item items={educationalInstitutions} />
                  </Grid>
                  <Grid item lg={6} md={6} style={{ marginBottom: 30 }}>
                    <Typography
                      style={{
                        color: palette.primarydark,
                        fontSize: 24,
                        fontWeight: "bold",
                      }}>
                      {t("about:home.candidates.title")}
                    </Typography>
                    <Item items={candidates} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      );
    }
  }
}
export default withTranslation()(Employer);
