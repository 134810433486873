import React from "react";
import { palette } from "assets/css/main";
import { DialogueFull } from "app/components";
import { withTranslation } from "react-i18next";
import { Button, Tab, Tabs, Typography, Box } from "@material-ui/core";
//import { IcnOfferingPdf, IcnCalendarSmall } from "assets/img";
import { getItem, RequestGet } from "app/utils";
import alertData from "app/components/alert/alert-data";
import { pdf } from "@react-pdf/renderer";
import TemplateCandidateJobProximity, {
  saveDocument,
} from "./template-candidate-job-proximity";
import PropTypes from "prop-types";

const styles = {
  softSkillName: {
    color: palette.primary,
    fontSize: 14,
    width: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bolder",
  },
  softskillValue: {
    borderLeft: "1px solid #AFBFE5",
    boxSizing: "border-box",
    color: palette.primary,
    fontSize: 14,
    width: 200,
    height: 85,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  softskillValueElips: {
    borderLeft: "1px solid #AFBFE5",
    boxSizing: "border-box",
    color: palette.primary,
    fontSize: 14,
    width: 220,
    height: 85,
    display: "flex",
    justifyContent: "center",
  },
  elipseJob: {
    color: "white",
    fontSize: 10,
    height: 25,
    padding: "0 15",
    background: palette.primary,
    border: "none",
    borderRadius: 20,
    opacity: 1,
    fontWeight: "normal",
    marginLeft: 10,
  },
  elipseCandidate: {
    color: "white",
    fontSize: 10,
    height: 25,
    padding: "0 15",
    background: "#52C8F4",
    border: "none",
    borderRadius: 20,
    opacity: 1,
    fontWeight: "normal",
    marginLeft: 10,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class DialogueCandidateJobProximity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      jobProximityTemplate: null,
      candidate: {},
      value: 0,
      softSkillCategory: [],
      alreadyGet: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  getCandidateProximity() {
    this.setState({ isLoading: false, candidate: {} });
    const { candidateId, vacancyId, onClose, t } = this.props;
    RequestGet("Vacancy/Proximity", {
      loading: true,
      userId: candidateId,
      VacancyId: vacancyId,
    })
      .then(r => {
        this.setState(
          {
            candidate: r.data,
            isLoading: true,
            softSkillCategory: r.data.softSkillCategory,
            alreadyGet: true,
          },
          () => {
            this.setTemplate(r.data);
          }
        );
      })
      .catch(e => {
        console.log("getCandidateProximity()", e);
        alertData.show(t("messages.failedViewProximity"), "error");
        onClose(false);
      });
  }

  setTemplate(candidate) {
    const { t } = this.props;
    this.setState({ loading: true });
    var template = null;
    var filename = "";
    if (candidate.status !== "SEL") {
      filename = candidate.name;
    } else {
      filename = candidate.candidateId;
    }
    template = <TemplateCandidateJobProximity t={t} data={this.state} />;
    pdf(template)
      .toBlob()
      .then(x => {
        this.blob = x;
        this.blobURL = saveDocument(
          window.URL.createObjectURL(x),
          "Job Proximity - " + filename + ".pdf"
        );
        this.setState({ loading: false });
      });
    this.setState({ jobProximityTemplate: template });
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { open, onClose, t } = this.props;
    const {
      isLoading,
      candidate,
      value,
      softSkillCategory,
      alreadyGet,
    } = this.state;
    var name = "-";
    if (candidate.status !== "SEL") {
      name = candidate.name;
    }
    var proximity = 0;
    if (candidate.proximity !== null) {
      proximity = candidate.proximity;
    }

    return (
      <DialogueFull
        hideClose={true}
        open={open}
        onEnter={() => this.getCandidateProximity()}
        onClose={() => {
          onClose(false);
        }}>
        {isLoading ? (
          <div>
            <div
              style={{
                backgroundColor: "white",
                width: 900,
                boxSizing: "border-box",
                borderRadius: 4,
              }}>
              <div
                style={{
                  height: 20,
                  backgroundColor: palette.primary,
                  padding: "20px 20px",
                  color: "white",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                  fontSize: 18,
                }}>
                {t("candidates:CandidateJobProximityDialog.title")}
              </div>
              <div
                style={{
                  padding: "20px 50px",
                  height: "30%",
                  boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      width: "30%",
                      flexDirection: "column",
                    }}>
                    <div style={{}}>
                      <p
                        style={{
                          fontSize: 16,
                          margin: 0,
                          textTransform: "capitalize",
                          color: palette.primary,
                          fontWeight: "lighter",
                        }}>
                        {t("candidates:CandidateJobProximityDialog.name")}
                      </p>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <p
                        style={{
                          fontSize: 16,
                          margin: 0,
                          textTransform: "capitalize",
                          color: palette.primary,
                          fontWeight: "lighter",
                        }}>
                        {t(
                          "candidates:CandidateJobProximityDialog.candidateId"
                        )}
                      </p>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <p
                        style={{
                          fontSize: 16,
                          margin: 0,
                          textTransform: "capitalize",
                          color: palette.primary,
                          fontWeight: "lighter",
                        }}>
                        {t("candidates:CandidateJobProximityDialog.jobTitle")}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "59%",
                      flexDirection: "column",
                    }}>
                    <div style={{}}>
                      <p
                        style={{
                          fontSize: 16,
                          margin: 0,
                          textTransform: "capitalize",
                          color: palette.primary,
                          fontWeight: "bolder",
                        }}>
                        {name}
                      </p>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <p
                        style={{
                          fontSize: 16,
                          margin: 0,
                          textTransform: "capitalize",
                          color: palette.primary,
                          fontWeight: "bolder",
                        }}>
                        {candidate.candidateId}
                      </p>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <p
                        style={{
                          fontSize: 16,
                          margin: 0,
                          textTransform: "capitalize",
                          color: palette.primary,
                          fontWeight: "bolder",
                        }}>
                        {candidate.jobTitle}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "11%",
                      flexDirection: "column",
                      textAlign: "center",
                    }}>
                    <div style={{}}>
                      <p
                        style={{
                          fontSize: 13,
                          margin: 0,
                          textTransform: "capitalize",
                          color: palette.primary,
                          fontWeight: "lighter",
                        }}>
                        {t(
                          "candidates:CandidateJobProximityDialog.jobProximity"
                        )}
                      </p>
                    </div>
                    <div style={{ position: "relative" }}>
                      <Button
                        style={{
                          color: "white",
                          fontSize: 20,
                          height: 30,
                          padding: "0 15",
                          textTransform: "lowercase",
                          background: "#2F3168",
                          border: "none",
                          borderRadius: 20,
                          opacity: 1,
                          fontWeight: "normal",
                          width: 83,
                        }}>
                        {proximity} %
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: "20px 80px",
                  height: "70%",
                  boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                }}>
                <div
                  style={{
                    fontWeight: "lighter",
                    display: "flex",
                    backgroundColor: "white",
                    flexDirection: "column",
                    fontSize: 24,
                  }}>
                  <div
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                    }}>
                    <Tabs
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: palette.primary,
                        },
                      }}
                      value={value}
                      onChange={this.handleChange}
                      style={{
                        color: palette.primary,
                        fontWeight: "bolder",
                        width: "100%",
                        fontSize: 15,
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      {softSkillCategory.map((v, i) => {
                        return (
                          <Tab
                            key={i}
                            label={
                              getItem("lang") === "en"
                                ? v.categoryNameEng
                                : v.categoryNameIna
                            }
                            style={{
                              borderBottom:
                                "1px solid rgba(46, 105, 178, 0.24)",
                              textTransform: "none",
                              fontSize: 15,
                            }}
                          />
                        );
                      })}
                    </Tabs>
                    {alreadyGet ? (
                      <div style={{ height: 500, overflowX: "scroll" }}>
                        {" "}
                        {softSkillCategory.map((v, i) => {
                          return (
                            <TabPanel key={i} value={value} index={i}>
                              {v.softSkillHeaders.map(
                                (valsoftSkillHeaders, j) => {
                                  return (
                                    <div
                                      key={j}
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          border: "1px solid #AFBFE5",
                                          boxSizing: "border-box",
                                        }}>
                                        <div
                                          key={j}
                                          style={styles.softSkillName}>
                                          {getItem("lang") === "en"
                                            ? valsoftSkillHeaders.softskillNameEng
                                            : valsoftSkillHeaders.softskillNameIna}
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}>
                                          {valsoftSkillHeaders.softSkillDetails.map(
                                            (valsoftSkillDetails, k) => {
                                              return (
                                                <div
                                                  key={k}
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                  }}>
                                                  <div
                                                    style={{
                                                      ...styles.softskillValue,
                                                      borderBottom:
                                                        "1px solid #AFBFE5",
                                                    }}>
                                                    {getItem("lang") === "en"
                                                      ? valsoftSkillDetails.softskillValueEng
                                                      : valsoftSkillDetails.softskillValueIna}
                                                  </div>
                                                  <div
                                                    style={{
                                                      ...styles.softskillValueElips,
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      borderBottom:
                                                        "1px solid #AFBFE5",
                                                    }}>
                                                    <Button
                                                      style={{
                                                        ...styles.elipseJob,
                                                        width:
                                                          valsoftSkillDetails.jobValue ===
                                                          "LOW"
                                                            ? 50
                                                            : valsoftSkillDetails.jobValue ===
                                                              "MEDIUM"
                                                            ? 100
                                                            : valsoftSkillDetails.jobValue ===
                                                              "HIGH"
                                                            ? 150
                                                            : 0,
                                                      }}>
                                                      <span style={{}}>
                                                        {
                                                          valsoftSkillDetails.jobValue
                                                        }
                                                      </span>
                                                    </Button>
                                                    <span
                                                      style={{
                                                        height: 5,
                                                      }}></span>
                                                    <Button
                                                      style={{
                                                        ...styles.elipseCandidate,
                                                        width:
                                                          valsoftSkillDetails.candidateValue ===
                                                          "LOW"
                                                            ? 50
                                                            : valsoftSkillDetails.candidateValue ===
                                                              "MEDIUM"
                                                            ? 100
                                                            : valsoftSkillDetails.candidateValue ===
                                                              "HIGH"
                                                            ? 150
                                                            : 0,
                                                      }}>
                                                      <span style={{}}>
                                                        {
                                                          valsoftSkillDetails.candidateValue
                                                        }
                                                      </span>
                                                    </Button>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </TabPanel>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  bottom: 0,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  width: "100%",
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                  padding: "20px 50px",
                }}>
                <div
                  style={{
                    width: "65%",
                    height: 50,
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        fontSize: 20,
                        height: 19,
                        padding: "0 15",
                        background: palette.primary,
                        border: "none",
                        borderRadius: 20,
                        opacity: 1,
                        fontWeight: "normal",
                        width: 19,
                      }}></div>
                    <label
                      style={{
                        fontSize: 14,
                        fontWeight: "lighter",
                        color: palette.primary,
                        marginLeft: 10,
                      }}>
                      {t(
                        "candidates:CandidateJobProximityDialog.vacancyProfile"
                      )}
                    </label>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        fontSize: 20,
                        height: 19,
                        padding: "0 15",
                        background: "#52C8F4",
                        border: "none",
                        borderRadius: 20,
                        opacity: 1,
                        fontWeight: "normal",
                        width: 19,
                        marginTop: 10,
                      }}></div>
                    <label
                      style={{
                        fontSize: 14,
                        fontWeight: "lighter",
                        marginTop: 10,
                        color: palette.primary,
                        marginLeft: 10,
                      }}>
                      {t(
                        "candidates:CandidateJobProximityDialog.candidateProfile"
                      )}
                    </label>
                  </div>
                </div>
                <div style={{ width: "35%", textAlign: "right", height: 50 }}>
                  <Button
                    target="_blank"
                    disabled={!isLoading}
                    onClick={() => {
                      document.getElementById("download-proximity").click();
                    }}
                    style={{
                      backgroundColor: "white",
                      color: palette.primary,
                      padding: "8px 50px",
                      border: "1px solid" + palette.primary,
                      width: 128,
                      marginRight: 15,
                      opacity: !isLoading ? 0.5 : 1,
                    }}>
                    {t("candidates:CandidateJobProximityDialog.btnDownload")}
                  </Button>
                  <Button
                    onClick={() => {
                      onClose(false);
                    }}
                    style={{
                      backgroundColor: palette.primary,
                      color: "white",
                      padding: "8px 50px",
                      border: "1px solid" + palette.primary,
                      width: 128,
                    }}>
                    {t("candidates:CandidateJobProximityDialog.btnClose")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </DialogueFull>
    );
  }
}
export default withTranslation("candidates")(DialogueCandidateJobProximity);
