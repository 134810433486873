import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { findObject, RequestGet } from "app/utils";

export default withTranslation()(
  class CVPreviewFE extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        listUniversity: [],
        listMajor: [],
      };
    }

    componentDidMount() {
      this.getUniversity();
    }

    getUniversity() {
      RequestGet("University")
        .then(resUniversity => {
          this.setState({
            ...this.state,
            listUniversity: resUniversity.data,
          });
          this.getMajor();
        })
        .catch(err => {
          console.log(err);
        });
    }

    getMajor() {
      const {
        data: { listFormalEducation },
      } = this.props;
      listFormalEducation.forEach(v => {
        var university = v.institution;
        university = findObject(this.state.listUniversity, "id", v.institution);
        if (university !== null) {
          RequestGet("University/Major/" + university.id, {
            loading: true,
          })
            .then(resMajor => {
              this.setState({
                listMajor: resMajor.data,
              });
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    }

    render() {
      const {
        t,
        data: { listFormalEducation },
      } = this.props;
      const { listUniversity, listMajor } = this.state;
      return (
        <div style={{ color: palette.primary, padding: "0px 50px" }}>
          <div style={{ fontSize: 18 }}>{t("biodata:CVformalEducation")}</div>
          {listFormalEducation.map((v, k) => {
            var university = v.institution;
            university = findObject(listUniversity, "id", v.institution);
            if (university === null) {
              university = v.institution;
            } else {
              university = university.university;
            }

            var major = v.major;
            major = findObject(listMajor, "id", v.major);
            if (major === null) {
              major = v.major;
            } else {
              major = major.major;
            }

            return (
              <div
                key={k}
                style={{
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                }}>
                <div>
                  <div>
                    <br />
                  </div>
                  <div
                    style={{
                      color: palette.primary,
                      fontSize: 18,
                      fontWeight: "bolder",
                    }}>
                    {university}
                  </div>
                  <div
                    style={{
                      color: palette.primary,
                      fontSize: 18,
                      fontWeight: "bolder",
                    }}>
                    {v.degree} - {major}
                  </div>
                  <div
                    style={{
                      color: palette.primary,
                      fontSize: 18,
                      fontWeight: "lighter",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <div
                      style={{
                        width: "100%",
                      }}>
                      {t("biodata:form.field.gpa")} : {v.grade}
                    </div>
                  </div>
                  <div style={{ fontSize: 18, fontWeight: "lighter" }}>
                    {t("biodata:form.field.graduated")} {v.yearGraduate}
                  </div>
                  <div>
                    <br />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
);
