import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { IcnInfo, IcnZoom } from "assets/img";
import PreviewPage from "./preview";
import IntroInterest from "./intro";
import InterestInstructions from "./instructions";
import btd from "../data";
import { RequestPut, getItem, General, t } from "app/utils";
import { withTranslation } from "react-i18next";
import InterestFooter from "./footer-interest";
import Information from "../cognitive/Information";

class MatchingPage extends React.Component {
  container = [];
  state = {
    plus: false,
    current: 0,
    progress: 0,
    text_desc: null,
    container: [],
    selected: [],
    image: null,
    data: [],
    footer: false,
    preview: false,
    prevImage: null,
    intro: this.props.intro,
    maxStep: 0,
    total: 0,
    step: 0,
    open: this.props.intro ? false : true,
    timer: 3,
    itemPerStep: 6,
    isDropBack: false,
    dialogInfo: false,
    button: "Continue",
  };
  componentDidMount() {
    btd.ordering = [];
    this.reset();
  }
  countItemPerstep() {}
  reset() {
    this.setState({ footer: false });
    if (this.props.isLast) {
      btd.stepper.footer = false;
      btd.stepper.nextBtn = false;
    }
    var d = [];
    var data = this.props.data.sort(function (a, b) {
      return a.questionOrder - b.questionOrder;
    });
    var container = data.slice(
      this.state.step * this.state.itemPerStep,
      this.state.step * this.state.itemPerStep + this.state.itemPerStep
    );
    this.container = container;
    if (this.props.data) {
      this.validate();
      this.setState({
        data: container,
        container: container,
        maxStep: this.props.data.length,
        current: this.state.step,
        total: this.props.data.length / this.state.itemPerStep,
      });
      container.forEach(val => {
        d.push({ id: null, responseAnswer: "", image: "" });
      }, this.setState({ selected: d }));
    }
  }

  onDrop(k) {
    var d = this.state.selected;
    var dt = this.removeItem(this.state.data, this.state.id, false);
    var idx = 0;
    if (this.findData(d, this.state.id) === -1) {
      if (d[k].id) {
        dt.push({
          id: d[k].id,
          answer: d[k].image,
          text_desc: d[k].text_desc,
          answerDescEn: d[k].text_desc,
          answerDescId: d[k].text_desc,
        });
        d[k] = {
          id: this.state.id,
          image: this.state.image,
          text_desc: this.state.text_desc,
          responseAnswer: k + 1,
        };
      } else {
        d[k] = {
          id: this.state.id,
          image: this.state.image,
          text_desc: this.state.text_desc,
          responseAnswer: k + 1,
        };
      }
    } else {
      idx = this.findData(d, this.state.id);
      if (d[k]) {
        d[idx] = d[k];
        d[idx].responseAnswer = idx + 1;
      } else {
        d[idx] = null;
      }
      d[k] = {
        id: this.state.id,
        image: this.state.image,
        text_desc: this.state.text_desc,
        responseAnswer: k + 1,
      };
    }
    this.setState({
      selected: d,
      image: null,
      text_desc: null,
      id: null,
      data: dt,
    });
    this.validate();
  }

  findData(data, key) {
    return data.findIndex(x => x.id === key);
  }

  removeItem(array, key, allowNull = true) {
    var tmp = [];
    array.forEach(v => {
      if (v.id !== key) {
        tmp.push(v);
      } else {
        if (allowNull) {
          tmp.push({ id: null });
        }
      }
    });
    return tmp;
  }

  startDragBack(k) {
    this.setState({
      isDropBack: true,
      id: this.state.selected[k].id,
      image: this.state.selected[k].image,
      text_desc: this.state.selected[k].text_desc,
    });
  }

  onDropBack(e) {
    if (this.state.isDropBack) {
      var selected = this.removeItem(this.state.selected, this.state.id);
      var dt = this.state.data;
      dt.push({
        answer: this.state.image,
        answerEn: this.state.image,
        id: this.state.id,
        answerDescEn: this.state.text_desc,
        answerDescId: this.state.text_desc,
      });
      this.setState({
        selected: selected,
        image: null,
        data: dt,
        isDropBack: false,
      });
      this.validate();
    }
  }

  startDrag(ev, id, text) {
    this.setState({ image: ev, id: id, text_desc: text });
  }

  nextButtonClick() {
    btd.setOrdering(this.state.selected);
    if (
      this.props.data.length / this.state.itemPerStep ===
      this.state.step + 1
    ) {
      RequestPut("interestattempts", btd.ordering)
        .then(() => {
          this.props.history.push("/basic-test");
        })
        .catch(e => {
          console.log("Matching.js:nextButtonClick()->save", e);
          btd.ordering.splice(
            this.state.step * this.state.itemPerStep,
            this.state.step * this.state.itemPerStep + this.state.itemPerStep
          );
        });
    } else {
      this.setState({ step: this.state.step + 1 }, () => this.reset());
    }
  }
  validate() {
    const { t } = this.props;
    var c, p;
    setTimeout(() => {
      if (
        this.props.data.length / this.state.itemPerStep ===
        this.state.step + 1
      ) {
        this.setState({ button: t("button.saveandcontinue") });
      } else {
        this.setState({ button: t("button.continue") });
      }
      if (this.findData(this.state.selected, null) === -1) {
        if (this.props.isLast) {
          btd.stepper.footer = true;
          btd.stepper.nextBtn = true;
        } else {
          this.setState({ footer: true });
        }
        c = this.state.step + 1;
        p = (c / this.state.total) * 100;
        General.visible = true;
        this.setState({ current: c, progress: p, plus: true });
      } else {
        General.visible = false;
        c = this.state.step;
        p = (c / this.state.total) * 100;
        this.setState({ footer: false, current: c, progress: p, plus: false });
        if (this.props.isLast) {
          btd.stepper.footer = false;
          btd.stepper.nextBtn = false;
        }
      }
    }, 100);
  }
  showPreview(i, d) {
    this.setState({ preview: true, prevImage: i, desc: d });
  }
  render() {
    const { title, info } = this.props;
    const {
      intro,
      progress,
      current,
      total,
      step,
      open,
      dialogInfo,
    } = this.state;

    return (
      <div
        style={{
          display: this.props.current === this.props.index ? "" : "none",
        }}>
        {this.props.data.map((v, k) => {
          return (
            <img
              style={{ display: "none" }}
              key={"sample-" + k}
              src={getItem("lang") === "en" ? v.answerEn : v.answer}
              alt={v.answerId}
              onLoadStart={() => {
                General.loading = true;
              }}
              onLoad={() => {
                General.loading = false;
              }}
            />
          );
        })}
        <Information
          noq={t("basictest:interestTest.noq")}
          timer={3}
          open={dialogInfo}
          total={total}
          title={title}
          onFinish={() => {
            this.setState({ dialogInfo: false });
          }}
        />
        {intro && (
          <IntroInterest
            onStart={() => {
              this.setState({ intro: false, open: true });
              this.props.onStart();
            }}
          />
        )}
        {open ? (
          <InterestInstructions
            instruction={info}
            title={title}
            questionID={this.props.questionID}
            onFinish={() => {
              this.setState({ intro: false, open: false, dialogInfo: true });
            }}
          />
        ) : (
          ""
        )}
        {!intro && !open ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            style={{
              width: "100vw",
              paddingTop: 10,
              paddingLeft: 40,
              paddingRight: 40,
            }}>
            <PreviewPage
              open={this.state.preview}
              image={this.state.prevImage}
              title={this.state.desc}
              onClose={v => this.setState({ preview: v })}
            />
            <Typography
              // variant="h4"
              style={{
                color: palette.primary,
                fontWeight: "bold",
                fontSize: 24,
              }}>
              {this.state.container.length > 0 && title + " " + (step + 1)}
            </Typography>
            {info !== undefined && (
              <table style={{ width: 700, fontSize: 14 }}>
                <tbody>
                  <tr>
                    <td width={40}>
                      <img width={25} src={IcnInfo} alt="info" />
                    </td>
                    <td style={{ color: "#B0B7BD" }}>{info}</td>
                  </tr>
                </tbody>
              </table>
            )}
            {this.state.data !== undefined && (
              //start-place-drop
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ width: "100%", paddingTop: 10 }}>
                {
                  this.state.container.map((v, k) => {
                    return (
                      <Grid item lg={2} md={2} key={k}>
                        <Grid
                          container
                          justify="center"
                          direction="column"
                          alignItems="center">
                          <div
                            style={{
                              width: 28,
                              height: 23,
                              borderRadius: 16,
                              marginBottom: 5,
                              borderWidth: 1,
                              borderColor: palette.primary,
                              borderStyle: "solid",
                              color: palette.primary,
                              textAlign: "center",
                              paddingTop: 5,
                              alignItems: "center",
                            }}>
                            {k + 1}
                          </div>
                          <div
                            id={"target-" + k}
                            onDrop={e => {
                              e.preventDefault();
                              this.onDrop(k);
                            }}
                            onDragOver={e => {
                              e.preventDefault();
                            }}
                            style={{
                              padding: 5,
                              borderWidth: 1,
                              width: 175,
                              height: 225,
                              borderColor: palette.primary,
                              backgroundColor: palette.info,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              textAlign: "center",
                              borderStyle: "dashed",
                            }}>
                            {this.state.selected[k].id === null && (
                              <p
                                style={{
                                  width: 120,
                                  paddingTop: 60,
                                  color: palette.primary,
                                  textAlign: "center",
                                }}>
                                {t("basictest:interestTest.dragDrop")}
                              </p>
                            )}
                            {this.state.selected[k].id !== null && (
                              <>
                                <div className={"Topimg"}>
                                  <img
                                    className={"target"}
                                    id={"image-id-selected-" + k}
                                    draggable={true}
                                    onDragStart={ev => {
                                      this.startDragBack(k);
                                    }}
                                    src={this.state.selected[k].image}
                                    key={"image-id-selected-" + k}
                                    style={{
                                      cursor: "pointer",
                                      zIndex: 2,
                                      width: "100%",
                                    }}
                                    alt={"Data" + k}
                                  />
                                  <Button
                                    onClick={() =>
                                      this.showPreview(
                                        this.state.selected[k].image,
                                        this.state.selected[k].text_desc
                                      )
                                    }
                                    variant={"contained"}
                                    size={"small"}
                                    style={{
                                      minWidth: 10,
                                      padding: 5,
                                      backgroundColor: "white",
                                      position: "absolute",
                                      bottom: 8,
                                      right: 10,
                                      zIndex: 3,
                                    }}>
                                    <img src={IcnZoom} alt="zoom" />
                                  </Button>
                                </div>
                                <div
                                  className="text Footer Competence"
                                  style={{
                                    backgroundColor: "white",
                                    color: palette.primary,
                                    width: 165,
                                    minHeight: 40,
                                    marginTop: 0,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "5px 5px",
                                  }}>
                                  {this.state.selected[k].text_desc}
                                </div>
                              </>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    );
                  })
                  //end-place-drop
                }
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{
                    backgroundColor: palette.grey,
                    minHeight: 100,
                    marginTop: 10,
                    borderRadius: 4,
                    padding: 10,
                    // overflowX: "auto",
                  }}>
                  <div
                    style={{ width: "100vw", minHeight: 200 }}
                    onDrop={e => {
                      this.onDropBack(e);
                      e.preventDefault();
                    }}
                    onDragOver={e => e.preventDefault()}
                    className="Item-Image">
                    {this.state.data.map((val, k) => {
                      return (
                        <div
                          key={k}
                          onDragStart={ev => {
                            this.startDrag(
                              getItem("lang") === "en"
                                ? val.answerEn
                                : val.answer,
                              val.id,
                              getItem("lang") === "en"
                                ? val.answerDescEn
                                : val.answerDescId
                            );
                          }}
                          style={{
                            height: "12%",
                            width: "12%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          draggable={true}>
                          <p
                            style={{
                              color: "white",
                              width: 164,
                              zIndex: 3,
                              fontWeight: "bold",
                              position: "absolute",
                              top: 20,
                            }}>
                            {t("basictest:interestTest.dragProvide")}
                          </p>
                          <div className={"Itemimg"}>
                            <img
                              className="target"
                              id={"image-id-" + k}
                              src={
                                getItem("lang") === "en"
                                  ? val.answerEn
                                  : val.answer
                              }
                              key={k}
                              style={{
                                cursor: "pointer",
                                zIndex: 2,
                                width: "100%",
                                height: "100%",
                              }}
                              alt={"Data" + k}
                            />
                          </div>
                          <Button
                            onClick={() =>
                              this.showPreview(
                                getItem("lang") === "en"
                                  ? val.answerEn
                                  : val.answer,
                                getItem("lang") === "en"
                                  ? val.answerDescEn
                                  : val.answerDescId
                              )
                            }
                            variant={"contained"}
                            size={"small"}
                            style={{
                              minWidth: 10,
                              padding: 5,
                              backgroundColor: "white",
                              position: "absolute",
                              top: 130,
                              right: 15,
                              zIndex: 3,
                            }}>
                            <img src={IcnZoom} alt="zoom" />
                          </Button>
                          <div
                            className={"Footer Competence"}
                            style={{
                              backgroundColor: "white",
                              color: palette.primary,
                            }}>
                            {getItem("lang") === "en"
                              ? val.answerDescEn
                              : val.answerDescId}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            )}
            <InterestFooter
              validate={true}
              enable={!this.state.footer}
              current={current}
              total={total}
              progress={progress}
              text={this.state.button}
              onClick={() => this.nextButtonClick()}
            />
          </Grid>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default withTranslation()(MatchingPage);
