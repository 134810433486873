import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  StepConnector,
  withStyles,
} from "@material-ui/core";
import { palette } from "assets/css/main";
import clsx from "clsx";
import { Check } from "@material-ui/icons";
import "./style.css";
import { withTranslation } from "react-i18next";

const useCustomIconStylesAZ = makeStyles({
  root: {
    backgroundColor: "rgba(82, 200, 244, 0.3)",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    fontWeight: "bold",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: palette.secondary,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "rgba(82, 200, 244, 0.3)",
  },
});

const useCustomIconStyles19 = makeStyles({
  root: {
    backgroundColor: "rgba(46, 105, 178,0.3)",
    zIndex: 1,
    color: "#fff",
    width: 30,
    height: 30,
    fontWeight: "bold",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: palette.primary,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "rgba(46, 105, 178,0.3)",
  },
});

const CustomConnectorAZ = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: palette.secondary,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: palette.secondary,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#ccc",
    borderRadius: 1,
  },
})(StepConnector);

const CustomConnector19 = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: palette.primary,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: palette.primary,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#ccc",
    borderRadius: 1,
  },
})(StepConnector);

function CustomIconAZ(props) {
  const classes = useCustomIconStylesAZ();
  const { active, completed } = props;
  const icons = {
    1: "a",
    2: "b",
    3: "c",
    4: "d",
    5: "e",
    6: "f",
    7: "g",
    8: "h",
    9: "i",
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {completed ? <Check /> : icons[String(props.icon)]}
    </div>
  );
}
function CustomIcon19(props) {
  const classes = useCustomIconStyles19();
  const { active, completed } = props;
  const icons = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {completed ? <Check /> : icons[String(props.icon)]}
    </div>
  );
}
class StepHeader extends React.Component {
  render() {
    const {
      activeStep,
      data,
      t,
      stepWidth,
      contentWidth,
      style,
      isAZ,
    } = this.props;
    var d = [t("step.bio-data"), t("step.test")];
    if (data) {
      d = data;
    }
    return (
      <div
        style={{
          width: contentWidth ? "auto" : contentWidth,
          height: 80,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          boxSizing: "border-box",
          ...style,
        }}>
        <Stepper
          connector={isAZ ? <CustomConnectorAZ /> : <CustomConnector19 />}
          activeStep={activeStep}
          style={{
            width: stepWidth ? stepWidth : 230,
            backgroundColor: "transparent",
          }}>
          {d.map((v, k) => {
            return (
              <Step
                key={k}
                completed={activeStep > k ? true : false}
                xyz="aaabb">
                <StepLabel
                  xyz="aaabb"
                  className={isAZ ? "NavBar" : "NavBarHead"}
                  StepIconComponent={isAZ ? CustomIconAZ : CustomIcon19}>
                  {v}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }
}
export default withTranslation()(StepHeader);
