import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { palette, WHITE_BUTTON } from "assets/css/main";
import { IcnSpeakerConfirmation } from "assets/img";
import propTypes from "prop-types";
import Moment from "react-moment";
import { getItem } from "app/utils";
import DialogueFull from "./full";

/**
 * @extends {React.Component<{title:propTypes.string, content:propTypes.string, open:propTypes.bool, onClose:propTypes.func, onOK:propTypes.func, btnYes:propTypes.string, btnNo:propTypes.string,onEnter:propTypes.func,  contentDetail:propTypes.string}>}
 */
class DialogueConfirm extends React.Component {
  render() {
    const {
      open,
      title,
      content,
      contentDetail,
      contentWidth,
      btnYes,
      btnNo,
      time,
      onEnter,
      disclaimerTime,
    } = this.props;
    return (
      <DialogueFull
        onEnter={onEnter}
        style={{ width: 500 }}
        hideClose={true}
        open={open}>
        <Grid
          container
          justify="center"
          alignItems="flex-end"
          style={{
            backgroundColor: "transparent",
            width: 500,
            paddingTop: 35,
          }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              width: 140,
              height: 140,
              borderRadius: "50%",
            }}>
            <img
              src={IcnSpeakerConfirmation}
              width={129}
              height={129}
              style={{ position: "relative", left: "5%" }}
              alt="Info"
            />
          </div>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              paddingTop: 100,
              backgroundColor: "white",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              fontWeight: "bold",
              fontSize: 24,
              color: palette.primary,
              width: "100%",
            }}>
            <div style={{ marginBottom: 10 }}>{title}</div>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          direction="row"
          alignItems="flex-start"
          style={{
            backgroundColor: "#ffff",
            padding: 20,
            width: 500,
          }}>
          <Typography
            style={{
              color: palette.primary,
              marginBottom: contentDetail ? 20 : 40,
              textAlign: "center",
              width: contentWidth ? contentWidth : 350,
            }}>
            {content}
          </Typography>
          {contentDetail && (
            <Typography
              style={{
                color: palette.primary,
                marginBottom: 40,
                textAlign: "center",
                width: contentWidth ? contentWidth : 250,
              }}>
              {contentDetail}
            </Typography>
          )}
          {time && (
            <div>
              {disclaimerTime && (
                <p
                  style={{
                    margin: 0,
                    fontSize: 14,
                    fontWeight: "lighter",
                    fontStyle: "italic",
                    color: palette.primary,
                  }}>
                  {disclaimerTime}
                </p>
              )}
              <div
                style={{
                  backgroundColor: palette.info,
                  color: palette.primary,
                  padding: 10,
                  marginBottom: 30,
                  textAlign: "center",
                }}>
                <Moment
                  date={time}
                  format="dddd, DD MMMM yyyy hh:mm \WIB"
                  locale={getItem("lang") === "en" ? "en" : "id"}
                />
              </div>
            </div>
          )}
        </Grid>
        <Grid
          container
          justify="space-between"
          style={{
            height: 70,
            backgroundColor: palette.primary,
            paddingLeft: 20,
            paddingRight: 20,
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
          }}>
          <Button style={{ ...WHITE_BUTTON }} onClick={this.props.onClose}>
            {btnNo}
          </Button>
          <Button style={{ ...WHITE_BUTTON }} onClick={this.props.onOK}>
            {btnYes}
          </Button>
        </Grid>
      </DialogueFull>
    );
  }
}

DialogueConfirm.propTypes = {
  title: propTypes.string,
  content: propTypes.string,
  open: propTypes.bool,
  onClose: propTypes.func,
  onOK: propTypes.func,
  btnYes: propTypes.string,
  btnNo: propTypes.string,
  onEnter: propTypes.func,
  contentDetail: propTypes.string,
};

DialogueConfirm.defaultProps = {
  btnYes: "Yes",
  btnNo: "No",
  title: "Sample title",
  content: "Success",
  open: true,
  onOK: () => {
    return true;
  },
  onEnter: () => {
    return true;
  },
  onClose: () => {
    return false;
  },
};

export default DialogueConfirm;
