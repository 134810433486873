import React from "react";
import { withTranslation } from "react-i18next";
import ConfirmationSidePanel from "./side-panel";
import ConfirmationProfileRecruiter from "./profile-recruiter-confirmation";
import ConfirmationProfileCorporate from "./profile-corporate-confirmation";
import ConfirmationProfileAdditional from "./profile-additional-confirmation";
import MyHelmet from "app/components/header/MyHelmet";
import { Header } from "app/components";
import {
  RequestGet,
  RequestPut,
  AppHistory,
  getItem,
  setItem,
} from "app/utils";
import { ConfirmationFooter } from "./confirmation-footer";
import data from "app/pages/recruiter/data";
import alertData from "app/components/alert/alert-data";

class ConfirmationProfileSetup extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
    this.state = {
      companyProfile: {
        aboutUs: "",
        businessSubType: "",
        businessType: "",
        companyAddress: "",
        companyAsset: 0,
        companyCode: "",
        companyLogo: "",
        companyNameBrand: "",
        companyNameLegal: "",
        companyRevenue: 0,
        companyType: "",
        established: "",
        numberOfBranches: "",
        numberOfEmployee: "",
        picIdentityCard: "",
        projectYear: "",
        siup: "",
        specifyOthers: "",
        urgentHiring: "",
        website: "",
      },
      recruiterPersonalInfo: {
        email: "",
        firstName: "",
        lastName: "",
        middleName: "",
        phoneNumber: "",
        positionType: "",
        recruiterType: "",
      },
      alreadyGet: false,
      view: null,
    };
  }

  componentDidMount() {
    this.getRecruiterProfile();
  }

  getRecruiterProfile() {
    RequestGet("RecruiterProfile", { loading: false })
      .then(r => {
        this.setState(
          {
            recruiterPersonalInfo: r.data.recruiterPersonalInfo,
            companyProfile: r.data.companyProfile,
            additionalInfo: r.data.additionalInfo,
            alreadyGet: true,
          },
          () => {
            this.changeView("init");
            data.recruiterPersonalInfo = r.data.recruiterPersonalInfo;
            data.companyProfile = r.data.companyProfile;
            data.additionalInfo = r.data.additionalInfo;
            data.companyBusinessType = r.data.listCompanyBusinessType
              ? r.data.listCompanyBusinessType.map(v => {
                  return v.businessType;
                })
              : [];
          }
        );
        this.getBusinessType();
      })
      .catch(e => {
        console.log(e);
      });
  }

  changeView(key) {
    const {
      companyProfile,
      recruiterPersonalInfo,
      additionalInfo,
      listBusinessType,
    } = this.state;

    if (key === "profile") {
      this.setState({
        view: <ConfirmationProfileRecruiter data={recruiterPersonalInfo} />,
      });
    } else if (key === "company") {
      this.setState({
        view: (
          <ConfirmationProfileCorporate
            data={companyProfile}
            listbisnistype={listBusinessType}
          />
        ),
      });
    } else if (key === "additional") {
      this.setState({
        view: <ConfirmationProfileAdditional data={additionalInfo} />,
      });
    } else {
      this.setState({
        view: <ConfirmationProfileRecruiter data={recruiterPersonalInfo} />,
      });
    }
  }

  handleClick(e) {
    const { t } = this.props;
    document.getElementById("button-submit").click();
    var datasubmit = {};
    datasubmit.recruiterPersonalInfo = data.recruiterPersonalInfo;
    datasubmit.companyProfile = data.companyProfile;
    datasubmit.additionalInfo = data.additionalInfo;
    datasubmit.companyBusinessType = data.companyBusinessType;

    //Profile
    if (data.recruiterPersonalInfo.firstName === "") {
      alertData.show(t("validation.required.name"), "error");
      return false;
    }
    if (data.recruiterPersonalInfo.phoneNumber === "") {
      alertData.show(t("validation.required.phoneNumber"), "error");
      return false;
    }
    if (data.recruiterPersonalInfo.positionType === "") {
      alertData.show(t("validation.required.position"), "error");
      return false;
    }

    //Company
    if (data.companyProfile.companyNameBrand === "") {
      alertData.show(t("validation.required.brand"), "error");
      return false;
    }
    if (data.companyProfile.companyNameLegal === "") {
      alertData.show(t("validation.required.companyNameLegal"), "error");
      return false;
    }
    if (data.companyProfile.companyAddress === "") {
      alertData.show(t("validation.required.companyAddress"), "error");
      return false;
    }
    if (data.companyProfile.aboutUs === "") {
      alertData.show(t("validation.required.aboutCompany"), "error");
      return false;
    }

    RequestPut("RecruiterProfile", datasubmit)
      .then(() => {
        alertData.show("Confirmation Success");
        setItem("company_info", JSON.stringify(datasubmit.companyProfile));
        setTimeout(() => {
          AppHistory.push("/recruiter/home");
        }, 1500);
      })
      .catch(e => {
        console.log(e);
      });
  }

  getBusinessType() {
    RequestGet("Industry")
      .then(res => {
        this.setState({
          listBusinessType: res.data.map(obj => {
            return {
              value: obj.id,
              label:
                getItem("lang") === "en" ? obj.industryEng : obj.industryIna,
            };
          }),
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    const { companyProfile, alreadyGet } = this.state;

    const { t } = this.props;
    return (
      <div className="auth">
        <MyHelmet title="Confirmation profile" />
        <Header
          shadow={true}
          stepperData={t("recruiterSetup", { returnObjects: true })}
          stepper={true}
          stepWidth={730}
          activeStep={3}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            paddingTop: "100px",
            flexDirection: "row",
            padding: "100px 200px",
          }}>
          {alreadyGet ? (
            <div
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
              }}>
              <ConfirmationSidePanel
                onChange={v => {
                  this.changeView(v);
                }}
                data={{ companyProfile: companyProfile }}
              />
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              width: "1%",
              display: "flex",
              flexDirection: "column",
            }}></div>
          {alreadyGet ? (
            <div
              style={{
                width: "54%",
                display: "flex",
                flexDirection: "column",
              }}>
              {this.state.view}
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              width: "15%",
              display: "flex",
              flexDirection: "column",
            }}></div>
        </div>
        <ConfirmationFooter
          text={t("button.save")}
          NextClick={() => {
            this.handleClick();
          }}
        />
      </div>
    );
  }
}
export default withTranslation()(ConfirmationProfileSetup);
