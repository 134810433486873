import { Tooltip, withStyles } from "@material-ui/core";

export const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#2F3168",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #2F3168",
  },
  arrow: {
    color: "#2F3168",
  },
}))(Tooltip);
