import React from "react";
import { withTranslation } from "react-i18next";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette } from "assets/css/main";
import CandidateSidePanel from "./side-panel";
import CandidatePreview from "./candidate-preview";
import { findObject, getItem, RequestGet } from "app/utils";

export default withTranslation()(
  class CandidatesAbout extends React.Component {
    state = { data: [], isLoading: true, answers: [] };
    componentDidMount() {
      this.getAboutCandidates();
    }
    getAboutCandidates() {
      const {
        match: { params },
      } = this.props;
      this.setState({ isLoading: true });
      RequestGet("candidate/about/" + params.id)
        .then(r => {
          const { data } = r;

          this.setState({
            answers: data.map(v => {
              if (v.categoryQuestion === "Freetext") {
                return v.responseAnswers.map(x => {
                  return { answer: x.userAnswer };
                });
              } else {
                return v.responseAnswers.map(x => {
                  var a = findObject(v.aboutAnswers, "answerId", x.userAnswer);
                  var an = a
                    ? getItem("lang") === "en"
                      ? a.answerTextEn
                      : a.answerTextId
                    : "";
                  return { answer: an };
                });
              }
            }),
          });
          return data;
        })
        .then(d => {
          console.log(this.state.answers);
          this.setState({ data: d });
          this.setState({ isLoading: false });
        })
        .catch(e => {
          console.log("getAboutCandidates()", e);
          this.setState({ isLoading: false });
        });
    }
    render() {
      const {
        match: { params },
        t,
      } = this.props;
      const { data, isLoading, answers } = this.state;
      return (
        <div style={{ height: "100%", boxSizing: "border-box" }}>
          <RecruiterAppBar menu="dashboard" shadow={true} />
          <div
            style={{ paddingTop: 80, height: "100%", boxSizing: "border-box" }}>
            <CandidatePreview id={params.id} />
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                minHeight: 650,
                boxSizing: "border-box",
              }}>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  backgroundColor: palette.lightGrey,
                  boxSizing: "border-box",
                  justifyContent: "flex-end",
                }}>
                <CandidateSidePanel view="about" id={params.id} />
              </div>
              <div
                style={{
                  width: "80%",
                  padding: "25px 40px",
                  color: palette.primary,
                  marginBottom: 50,
                  boxSizing: "border-box",
                }}>
                <span style={{ fontWeight: "bold", fontSize: 24 }}>
                  {t("candidates:candidateInfo.ac")}
                </span>
                {data.length === 0 && !isLoading ? (
                  <div
                    style={{
                      padding: "20px 0px 0px 0px",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    {t("candidates:candidateInfo.empty")}
                  </div>
                ) : (
                  ""
                )}
                {data.map((v, i) => {
                  return (
                    <div
                      key={"abouts" + i}
                      style={{
                        padding: "20px 0px 0px 0px",
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div style={{ width: "2%" }}>
                        <span
                          style={{
                            backgroundColor: palette.darkGrey,
                            color: "white",
                            display: "flex",
                            width: 20,
                            height: 20,
                            fontSize: 12,
                            borderRadius: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}>
                          {i + 1}
                        </span>
                      </div>
                      <div style={{ padding: "1px 10px" }}>
                        <span>
                          {getItem("lang") === "en"
                            ? v.questionTextEn
                            : v.questionTextId}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontWeight: "bold",
                            marginTop: 5,
                          }}>
                          {answers[i].length
                            ? answers[i].map((x, y) => {
                                return (
                                  <p
                                    style={{
                                      margin: 0,
                                      overflowWrap: "anywhere",
                                    }}
                                    key={"ans-" + y}>
                                    {x.answer}
                                  </p>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div></div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
