import React from "react";
import { withTranslation } from "react-i18next";
import bdc from "./data";
import "./custom.css";
import { TextField, Grid, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { Options, Uploader, DatePicker, DialogueInfo } from "app/components";
import {
  getItem,
  nameRegex,
  numberRegex,
  RequestPost,
  General,
  RequestGet,
  findObject,
  removeObject,
} from "app/utils";
import WizardForm from "app/components/typeform/WizardForm";
import Slider from "@material-ui/core/Slider";
import SelectSearch from "react-select";
// import { logError } from "app/utils/general/rollbar";
const addComma = val => {
  if (val) {
    return ", " + val;
  } else {
    return "";
  }
};
class PersonalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...bdc.personal_data,
      currentField: 1,
      totalField: 6,
      listProvince: [],
      listCity: [],
      listDistrict: [],
      listSubDistrict: [],
      provinceId: null,
      cityId: null,
      districtId: null,
      descriptionCount: 0,
      isSelfDesc: false,
      showFailed: false,
      listCheckedForeignLang: [],
      listforeignLangProficiency: [],
    };
    General.visible = false;
    this.slideChange = this.slideChange.bind(this);
  }
  handleNext(isLast) {
    bdc.stepper.footer = isLast;
    bdc.stepper.nextBtn = isLast;
  }
  handleBack() {
    bdc.stepper.footer = false;
    bdc.stepper.nextBtn = false;
  }
  handleUploadImage(file, part = "ktp") {
    var form = new FormData();
    form.append(
      "file",
      file.file,
      getItem("user_id") + "-" + part + "." + file.extension
    );
    RequestPost("biodata/upload", form)
      .then(r => {
        const { imageUrl } = r.data;
        try {
          document.getElementById("preview-img").blur();
          document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
        } catch (error) {
          console.log(error);
        }
        if (part === "profile") {
          this.handleChange({ photo: imageUrl });
        } else {
          General.visible = true;
          this.handleChange({ identityPhoto: imageUrl });
        }
      })
      .catch(e => {
        console.log("handleUploadImage()", e);
      });
  }
  handleChange(obj, length, element) {
    if (element !== undefined) {
      var listchecklang = this.state.listCheckedForeignLang;
      obj = { listUserLanguage: listchecklang };
    }
    this.setState(obj);
    bdc.setPersonalData(obj);
    if (length > 0) {
      this.setState({
        descriptionCount: length,
      });
    } else {
      this.setState({
        descriptionCount: 0,
      });
    }

    if (element !== undefined) {
      var tmp = this.state.levellang;
      tmp[element.index] = !tmp[element.index];
      this.setState({
        levellang: tmp,
      });
    }
    var e = document.getElementById("error-messages");
    var el = document.getElementsByClassName("MuiInput-underline");
    var label = document.getElementsByClassName("MuiInputLabel-root");

    try {
      e.innerText = "";
      document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
      label[0].classList.remove("Mui-error");
      el[0].classList.remove("Mui-error");
    } catch (error) {}
  }

  submit() {
    var data = {};
    data.personalInfo = bdc.personal_data;
    var fn = bdc.personal_data.firstName,
      mn = bdc.personal_data.middleName,
      ln = bdc.personal_data.lastName;
    var full = fn + " " + mn + " " + ln;
    data.personalInfo.name = full.replace(/ +(?= )/g, "").trim();
    data.personalInfo.address =
      bdc.personal_data.street +
      addComma(bdc.personal_data.rt + "/" + bdc.personal_data.rw) +
      addComma(this.state.subDistrict.label) +
      addComma(this.state.district.label) +
      addComma(this.state.city.label) +
      addComma(this.state.province.label) +
      addComma(bdc.personal_data.zipcode);

    data.personalInfo.zipcode = parseInt(bdc.personal_data.zipcode);
    var listchecklang = this.state.listCheckedForeignLang;
    if (listchecklang.length === 0) {
      data.personalInfo.listUserLanguage = [];
    }

    data.listFormalEducation = [];
    data.listOrganization = [];
    data.listInformalEducation = [];
    data.listWorks = [];
    RequestPost("biodata", data)
      .then(() => {
        window.location.reload();
      })
      .catch(e => {
        // logError("personal-data :" + e);
        this.setState({ showFailed: true });
      });
  }

  handleRTRW(e) {
    var val = e.currentTarget.value;
    if (numberRegex.test(val)) {
      val = parseInt(e.currentTarget.value);
      if (val < 10) {
        return "00" + val;
      } else if (val < 100) {
        return "0" + val;
      } else if (val < 1000) {
        return val.toString();
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  componentDidMount() {
    try {
      var user = JSON.parse(getItem("user_info"));
      this.handleChange({
        firstName: user.first_name,
        middleName: user.mid_name ? user.mid_name : "",
        lastName: user.last_name ? user.last_name : "",
        email: user.email,
      });
      this.getLanguageSkill();
    } catch (error) {}
  }
  getLanguageSkill() {
    var char = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var list = [];
    RequestGet("Biodata/Language")
      .then(res => {
        var sliderlang = [];
        var strlevel = [];
        var listchek = this.state.listCheckedForeignLang;
        res.data.map((v, i) => {
          sliderlang.push(false);
          strlevel.push(1);
          return list.push({
            key: char[i],
            label:
              getItem("lang") === "en" ? v.languageSkill : v.languageSkillIna,
            value: v.id,
            proficiencybyId: strlevel[i],
          });
        });
        this.setState({
          ...this.state,
          listforeignLangProficiency: list,
          levellang: sliderlang,
          strLevelLang: strlevel,
          listCheckedForeignLang: listchek,
        });
        this.getProvince();
      })
      .catch(err => {
        console.log(err);
      });
  }
  getProvince() {
    RequestGet("location/province")
      .then(resProv => {
        var province = resProv.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        this.setState({
          ...this.state,
          listProvince: province,
        });
        var e = document.getElementById("error-messages");
        var el = document.getElementsByClassName("MuiInput-underline");
        var label = document.getElementsByClassName("MuiInputLabel-root");

        try {
          e.innerText = "";
          document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
          label[0].classList.remove("Mui-error");
          el[0].classList.remove("Mui-error");
        } catch (error) {}
      })
      .catch(err => {
        console.log(err);
      });
  }
  onChangeProvince(provinceId) {
    var listprovincebyId = findObject(
      this.state.listProvince,
      "value",
      provinceId.provinceId
    );
    RequestGet("location/city/" + provinceId.provinceId, {
      loading: true,
    })
      .then(resCity => {
        var city = resCity.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var obj = { province: provinceId.provinceId.toString() };
        bdc.setPersonalData(obj);
        this.setState({
          ...this.state,
          listCity: city,
          listDistrict: [],
          listSubDistrict: [],
          province: listprovincebyId,
          city: " ",
          district: " ",
          subDistrict: " ",
          zipcode: "",
          address: "",
          rt: "000",
          rw: "000",
        });
        var e = document.getElementById("error-messages");
        var el = document.getElementsByClassName("MuiInput-underline");
        var label = document.getElementsByClassName("MuiInputLabel-root");

        try {
          e.innerText = "";
          document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
          label[0].classList.remove("Mui-error");
          el[0].classList.remove("Mui-error");
        } catch (error) {}
      })
      .catch(err => {
        console.log(err);
      });
  }
  onChangeCity(cityId) {
    var listcitybyId = findObject(this.state.listCity, "value", cityId.cityId);
    RequestGet("location/district/" + cityId.cityId, {
      loading: true,
    })
      .then(resDistrict => {
        var district = resDistrict.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var obj = { city: cityId.cityId.toString() };
        bdc.setPersonalData(obj);
        this.setState({
          ...this.state,
          listDistrict: district,
          listSubDistrict: [],
          province: this.state.province,
          city: listcitybyId,
          district: " ",
          subDistrict: " ",
          zipcode: "",
          address: "",
          rt: "000",
          rw: "000",
        });
        var e = document.getElementById("error-messages");
        var el = document.getElementsByClassName("MuiInput-underline");
        var label = document.getElementsByClassName("MuiInputLabel-root");

        try {
          e.innerText = "";
          document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
          label[0].classList.remove("Mui-error");
          el[0].classList.remove("Mui-error");
        } catch (error) {}
      })
      .catch(err => {
        console.log(err);
      });
  }
  onChangeDistrict(districtId) {
    var listdistrictbyId = findObject(
      this.state.listDistrict,
      "value",
      districtId.districtId
    );
    RequestGet("location/subdistrict/" + districtId.districtId, {
      loading: true,
    })
      .then(resSubDistrict => {
        var subdistrict = resSubDistrict.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: v.name,
          };
        });
        var obj = { district: districtId.districtId.toString() };
        bdc.setPersonalData(obj);
        this.setState({
          ...this.state,
          listSubDistrict: subdistrict,
          province: this.state.province,
          city: this.state.city,
          district: listdistrictbyId,
          subDistrict: " ",
          zipcode: "",
          address: "",
          rt: "000",
          rw: "000",
        });
        var e = document.getElementById("error-messages");
        var el = document.getElementsByClassName("MuiInput-underline");
        var label = document.getElementsByClassName("MuiInputLabel-root");

        try {
          e.innerText = "";
          document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
          label[0].classList.remove("Mui-error");
          el[0].classList.remove("Mui-error");
        } catch (error) {}
      })
      .catch(err => {
        console.log(err);
      });
  }

  onChangeSubDistrict(subdistrictId) {
    var listsubdistrictbyId = findObject(
      this.state.listSubDistrict,
      "value",
      subdistrictId.subdistrictId
    );
    var obj = { subDistrict: subdistrictId.subdistrictId.toString() };
    bdc.setPersonalData(obj);
    this.setState({
      ...this.state,
      province: this.state.province,
      city: this.state.city,
      district: this.state.district,
      subDistrict: listsubdistrictbyId,
      zipcode: "",
      address: "",
      rt: "000",
      rw: "000",
    });
    var e = document.getElementById("error-messages");
    var el = document.getElementsByClassName("MuiInput-underline");
    var label = document.getElementsByClassName("MuiInputLabel-root");

    try {
      e.innerText = "";
      document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
      label[0].classList.remove("Mui-error");
      el[0].classList.remove("Mui-error");
    } catch (error) {}
  }

  slideChange = (val, langid, idx) => {
    var liststrlevel = this.state.strLevelLang;
    if (val === 1) {
      liststrlevel[idx] = 1;
    } else if (val === 2) {
      liststrlevel[idx] = 2;
    } else {
      liststrlevel[idx] = 3;
    }
    this.setState({ strLevelLang: liststrlevel });

    var listchecklang = this.state.listCheckedForeignLang;

    if (listchecklang.length > 0) {
      listchecklang.forEach(e => {
        if (e.languageId === langid) {
          e.proficiency = val;
        }
      });
    } else {
      listchecklang.push({ languageId: langid, proficiency: val });
    }

    var listcheked = this.state.listforeignLangProficiency;
    listcheked.forEach(e => {
      if (e.value === langid) {
        e.proficiencybyId = val;
      }
    });

    var obj = {};
    obj = { listUserLanguage: listchecklang };
    this.setState({
      listCheckedForeignLang: listchecklang,
      listforeignLangProficiency: listcheked,
    });
    this.setState(obj);
    bdc.setPersonalData(obj);
  };
  render() {
    const { t } = this.props;
    const {
      listProvince,
      listCity,
      listDistrict,
      listSubDistrict,
      descriptionCount,
      listCheckedForeignLang,
      strLevelLang,
      listforeignLangProficiency,
      levellang,
      showFailed,
    } = this.state;
    const maritalStatus = t("biodata:options.maritalStatus", {
      returnObjects: true,
    });
    const gender = t("biodata:options.gender", {
      returnObjects: true,
    });
    const levelSkillLang = t("biodata:levelSkillLang", { returnObjects: true });

    return (
      <Grid container style={{ paddingTop: 40, width: "100vw" }}>
        <div>
          <Typography
            style={{
              color: palette.primary,
              marginBottom: 10,
              padding: "0px 25%",
              fontWeight: "bold",
            }}>
            {t("biodata:step.personaldata")}
          </Typography>
          <DialogueInfo
            title={t("biodata:failed.title")}
            content={t("biodata:failed.content")}
            open={showFailed}
            onClose={() => {
              this.setState({ showFailed: false }, () => {
                this.submit(false);
              });
            }}
          />
          <WizardForm
            onSubmit={() => {
              this.submit();
            }}>
            <TextField
              autoFocus={true}
              onChange={e => {
                var val = e.currentTarget.value;
                nameRegex.test(val)
                  ? this.handleChange({ firstName: val })
                  : e.preventDefault();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              maxLength={50}
              required={true}
              placeholder={t("biodata:form.placeholder.firstName")}
              label={t("biodata:form.field.firstName")}
              value={this.state.firstName}
              className="Field"
            />
            <TextField
              autoFocus={true}
              onChange={e => {
                var val = e.currentTarget.value;
                nameRegex.test(val)
                  ? this.handleChange({ middleName: val })
                  : e.preventDefault();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              maxLength={50}
              placeholder={t("biodata:form.placeholder.middleName")}
              label={t("biodata:form.field.middleName")}
              value={this.state.middleName}
              className="Field"
            />
            <TextField
              autoFocus={true}
              onChange={e => {
                var val = e.currentTarget.value;
                nameRegex.test(val)
                  ? this.handleChange({ lastName: val })
                  : e.preventDefault();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              maxLength={50}
              placeholder={t("biodata:form.placeholder.lastName")}
              label={t("biodata:form.field.lastName")}
              value={this.state.lastName}
              className="Field"
            />
            <Options
              label={t("biodata:form.field.gender")}
              options={gender}
              onChange={option => {
                this.handleChange({ gender: option.value });
              }}
              required={true}
              value={this.state.gender}
            />
            <TextField
              autoFocus={true}
              onChange={e => {
                var val = e.currentTarget.value;
                nameRegex.test(val)
                  ? this.handleChange({ birthPlace: val })
                  : e.preventDefault();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              required={true}
              placeholder={t("biodata:form.placeholder.birthplace")}
              label={t("biodata:form.field.birthplace")}
              className="Field"
              value={this.state.birthPlace}
              maxLength={50}
            />
            <DatePicker
              required={true}
              maxDate={new Date()}
              autoFocus={true}
              value={this.state.birthDate}
              format="d/M/yyyy"
              label={t("biodata:form.field.birthdate")}
              onChange={e => {
                this.handleChange({ birthDate: e });
              }}
            />
            <Options
              label={t("biodata:form.field.marital")}
              options={maritalStatus}
              onChange={option => {
                this.handleChange({ maritalStatus: option.value });
              }}
              required={true}
              value={this.state.maritalStatus}
            />
            <TextField
              autoFocus={true}
              onChange={e => {
                var val = e.currentTarget.value;
                numberRegex.test(val)
                  ? this.handleChange({ identityNumber: val })
                  : e.preventDefault();
              }}
              InputLabelProps={{
                shrink: true,
              }}
              // minLength={16}
              // maxLength={16}
              // required={true}
              // requiredHelper={t("biodata:validation.required.required")}
              // helper={t("biodata:validation.nik")}
              placeholder={t("biodata:form.placeholder.nik")}
              label={t("biodata:form.field.nik")}
              value={this.state.identityNumber}
              className="Field"
            />
            <Grid
              container
              direction="column"
              justify="flex-start"
              value={this.state.province}
              required={true}>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 40,
                  textTransform: "uppercase",
                }}>
                {t("biodata:form.field.domocile")}
              </Typography>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 18,
                  fontWeight: "lighter",
                  marginBottom: 10,
                }}>
                {t("biodata:form.field.province")}
                <span
                  style={{ color: palette.primary }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <SelectSearch
                closeMenuOnSelect
                key={new Date().getTime()}
                className="Select-bio-domicile-location"
                classNamePrefix="select"
                placeholder={t("biodata:form.placeholder.province")}
                menuColor={palette.primary}
                name="province"
                options={listProvince}
                value={this.state.province ? this.state.province : " "}
                onChange={v => {
                  this.onChangeProvince({ provinceId: v.value });
                }}
                style={{ color: palette.primary, fontSize: 32 }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              value={this.state.city}
              required={true}>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 40,
                  textTransform: "uppercase",
                }}>
                {t("biodata:form.field.domocile")}
              </Typography>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 18,
                  fontWeight: "lighter",
                  marginBottom: 10,
                }}>
                {t("biodata:form.field.city")}
                <span
                  style={{ color: palette.primary }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <SelectSearch
                closeMenuOnSelect
                key={new Date().getTime()}
                className="Select-bio-domicile-location"
                classNamePrefix="select"
                placeholder={t("biodata:form.placeholder.city")}
                menuColor={palette.primary}
                name="city"
                options={listCity}
                value={this.state.city ? this.state.city : " "}
                onChange={v => {
                  this.onChangeCity({ cityId: v.value });
                }}
                style={{ color: palette.primary, fontSize: 32 }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              value={this.state.district}>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 40,
                  textTransform: "uppercase",
                }}>
                {t("biodata:form.field.domocile")}
              </Typography>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 18,
                  fontWeight: "lighter",
                  marginBottom: 10,
                }}>
                {t("biodata:form.field.district")}
                <span
                  style={{ color: palette.primary }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <SelectSearch
                closeMenuOnSelect
                key={new Date().getTime()}
                className="Select-bio-domicile-location"
                classNamePrefix="select"
                placeholder={t("biodata:form.placeholder.district")}
                menuColor={palette.primary}
                name="district"
                options={listDistrict}
                value={this.state.district ? this.state.district : " "}
                onChange={v => {
                  this.onChangeDistrict({ districtId: v.value });
                }}
                style={{ color: palette.primary, fontSize: 32 }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              value={this.state.subDistrict}>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 40,
                  textTransform: "uppercase",
                }}>
                {t("biodata:form.field.domocile")}
              </Typography>
              <label
                style={{
                  color: "#2E69B2",
                  fontSize: 18,
                  fontWeight: "lighter",
                  marginBottom: 10,
                }}>
                {t("biodata:form.field.subDistrict")}
                <span
                  style={{ color: palette.primary }}
                  className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                  &thinsp;*
                </span>
              </label>
              <SelectSearch
                closeMenuOnSelect
                key={new Date().getTime()}
                className="Select-bio-domicile-location"
                classNamePrefix="select"
                placeholder={t("biodata:form.placeholder.subDistrict")}
                menuColor={palette.primary}
                name="subdistrict"
                options={listSubDistrict}
                value={this.state.subDistrict ? this.state.subDistrict : " "}
                onChange={v => {
                  this.onChangeSubDistrict({ subdistrictId: v.value });
                }}
                style={{ color: palette.primary, fontSize: 32 }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              value={this.state.zipcode}
              maxLength={5}
              label={t("biodata:form.field.zip")}
              helper={t("biodata:validation.zip")}
              minLength={5}
              required={true}>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 40,
                  textTransform: "uppercase",
                }}>
                {t("biodata:form.field.domocile")}
              </Typography>
              <TextField
                autoFocus={true}
                onChange={e => {
                  var val = e.currentTarget.value;
                  numberRegex.test(val)
                    ? this.handleChange({ zipcode: val })
                    : e.preventDefault();
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.zipcode}
                required={true}
                placeholder={t("biodata:form.placeholder.zip")}
                label={t("biodata:form.field.zip")}
                className="Field"
              />
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              value={this.state.street}
              maxLength={150}
              label={t("biodata:form.field.street")}
              required={true}>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 40,
                  textTransform: "uppercase",
                }}>
                {t("biodata:form.field.domocile")}
              </Typography>
              <TextField
                autoFocus={true}
                onChange={e => {
                  this.handleChange({ street: e.currentTarget.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.state.street}
                required={true}
                placeholder={t("biodata:form.placeholder.street")}
                label={t("biodata:form.field.street")}
                className="Field"
              />
            </Grid>
            <Grid
              container
              direction="column"
              justify="flex-start"
              value={this.state.rtrw}>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 40,
                  textTransform: "uppercase",
                }}>
                {t("biodata:form.field.domocile")}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <TextField
                  autoFocus={true}
                  onChange={e => {
                    this.handleRTRW(e)
                      ? this.handleChange({ rt: this.handleRTRW(e) })
                      : e.preventDefault();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.rt}
                  placeholder={"000"}
                  label={"RT/RW"}
                  style={{ width: 80 }}
                  className="Field rtrw"
                />
                <span
                  style={{
                    fontSize: 24,
                    borderBottom: "2px solid " + palette.primary,
                    color: palette.primary,
                    padding: "9px 15px 9px 0px",
                  }}>
                  /
                </span>
                <TextField
                  onChange={e => {
                    this.handleRTRW(e)
                      ? this.handleChange({ rw: this.handleRTRW(e) })
                      : e.preventDefault();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.rw}
                  placeholder={"000"}
                  label={""}
                  style={{ width: 80 }}
                  className="Field rtrw"
                />
              </div>
            </Grid>
            <Grid
              required={true}
              value={this.state.phoneNumber}
              minLength={7}
              label={t("biodata:form.field.contact")}
              maxLength={14}>
              <Typography
                style={{
                  color: palette.primary,
                  marginBottom: 20,
                  textTransform: "uppercase",
                }}>
                {t("biodata:form.field.contact")}
              </Typography>
              <Grid style={{ display: "flex", flexDirection: "row" }}>
                <span
                  className="Field"
                  style={{
                    width: 62,
                    fontSsize: 32,
                    fontWeight: "bold",
                    padding: "25px 0px 3px",
                    color: palette.primary,
                    borderBottom: "2px solid " + palette.primary,
                  }}>
                  +62
                </span>
                <TextField
                  autoFocus={true}
                  placeholder={t("biodata:form.placeholder.contact")}
                  onChange={e => {
                    var char = e.currentTarget.value;
                    if (char.split("")[0] === "0") {
                      char = char.slice(1);
                    }
                    numberRegex.test(char)
                      ? this.handleChange({ phoneNumber: char })
                      : e.preventDefault();
                  }}
                  value={this.state.phoneNumber}
                  className="Field"
                  style={{ width: 400, marginLeft: 10 }}
                />
              </Grid>
            </Grid>
            <TextField
              autoFocus={true}
              onChange={e => {
                this.handleChange({ email: e.currentTarget.value });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
              type="email"
              value={this.state.email}
              required={true}
              placeholder={t("biodata:form.placeholder.email")}
              label={t("biodata:form.field.email")}
              className="Field"
            />
            <Grid
              container
              direction="column"
              justify="flex-start"
              required={true}
              value={this.state.description}
              label={t("biodata:form.field.description")}
              maxLength={250}>
              <TextField
                onChange={e => {
                  var val = e.currentTarget.value;
                  this.handleChange(
                    {
                      description: e.currentTarget.value,
                    },
                    val.length
                  );
                }}
                multiline
                rows={5}
                InputLabelProps={{
                  shrink: true,
                }}
                required={true}
                value={this.state.description}
                placeholder={t("biodata:form.placeholder.description")}
                label={t("biodata:form.field.description")}
                className="Field"
              />
              <Typography
                style={{
                  color: palette.primary,
                  fontSize: 14,
                  fontWeight: "lighter",
                  textAlign: "right",
                }}>
                {descriptionCount}/250
              </Typography>
            </Grid>
            <Grid
              container
              style={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <Grid
                container
                style={{ width: "35%" }}
                direction="row"
                justify="flex-start"
                value={this.state.listCheckedForeignLang}>
                <Options
                  label={t("biodata:form.field.foreignLangProficiency")}
                  options={listforeignLangProficiency}
                  multiple={true}
                  onChange={option => {
                    if (
                      findObject(
                        listCheckedForeignLang,
                        "languageId",
                        option.value
                      )
                    ) {
                      const newList = removeObject(
                        listCheckedForeignLang,
                        "languageId",
                        option.value
                      );
                      this.setState({ listCheckedForeignLang: newList });
                    } else {
                      listCheckedForeignLang.push({
                        languageId: option.value,
                        proficiency: 1,
                      });
                    }
                    this.handleChange(
                      {
                        listUserLanguage: [
                          {
                            languageId: option.value,
                            proficiency: 1,
                          },
                        ],
                      },
                      null,
                      option
                    );
                  }}
                  value={this.state.listCheckedForeignLang}
                />
              </Grid>
              <Grid
                container
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "35px 0px 0px 0px",
                }}>
                {this.state.listforeignLangProficiency.map((v, i) => {
                  return (
                    <Grid
                      key={i}
                      id="GridSlide"
                      container
                      style={{
                        flexDirection: "column",
                        marginBottom: 3,
                        minHeight: 60,
                      }}>
                      <div
                        style={{
                          display: levellang[i] ? "flex" : "none",
                          flexDirection: "column",
                        }}>
                        <Typography
                          id="level-lang"
                          gutterBottom
                          style={{
                            color: palette.primary,
                            fontWeight: "lighter",
                          }}>
                          Level -{" "}
                          {strLevelLang[i] === 1
                            ? levelSkillLang[0]
                            : strLevelLang[i] === 2
                            ? levelSkillLang[1]
                            : levelSkillLang[2]}
                        </Typography>
                        <Slider
                          key={`slider-${v.proficiencybyId}`}
                          onChange={(event, val) =>
                            this.slideChange(val, v.value, i)
                          }
                          defaultValue={v.proficiencybyId}
                          aria-labelledby="discrete-slider"
                          step={1}
                          marks
                          min={1}
                          max={3}
                          style={{ width: "50%", color: "#52C8F4" }}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Uploader
              previewStyle={{ width: 620, height: 300 }}
              isBlob={false}
              key={1}
              accept="image/png,image/jpg,image/jpeg"
              label={t("biodata:form.field.photo")}
              onDropFile={o => {
                this.handleUploadImage(o, "profile");
              }}
              required="true"
              value={this.state.photo}
            />
            <Uploader
              previewStyle={{ width: 620, height: 300 }}
              isBlob={false}
              key={1}
              accept="image/png,image/jpg,image/jpeg"
              label={t("biodata:form.field.idcard")}
              onDropFile={o => {
                this.handleUploadImage(o, "identityPhoto");
              }}
              // required="true"
              value={this.state.identityPhoto}
            />
          </WizardForm>
        </div>
      </Grid>
    );
  }
}
export default withTranslation()(PersonalData);
