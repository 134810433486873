import React from "react";
import { withTranslation } from "react-i18next";
import { Header, NextBtn } from "app/components";
import MyHelmet from "app/components/header/MyHelmet";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import { Grid, Typography, MenuItem, TextField } from "@material-ui/core";
import { palette } from "assets/css/main";
import { getItem, RequestPost, nameRegex, AppHistory } from "app/utils";
const recruiter_type = [
  { code: "HR", label: "Human Resources" },
  { code: "HH", label: "Headhunter" },
];
export const StarRequired = props => {
  return (
    <label>
      {props.label}
      <span
        className="MuiFormLabel-asterisk MuiInputLabel-asterisk"
        style={{ color: "#CD594D" }}>
        *
      </span>
    </label>
  );
};

class RecruiterProfileSetup extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      type: 0,
      position: "",
      phoneNumber: "",
      ...JSON.parse(getItem("user_info")),
    };
  }
  submitForm(e) {
    e.preventDefault();
    const {
      first_name,
      middle_name,
      last_name,
      email,
      phoneNumber,
      position,
      type,
    } = this.state;
    var data = {
      recruiterPersonalInfo: {
        firstName: first_name,
        middleName: middle_name,
        lastName: last_name,
        email: email,
        phoneNumber: phoneNumber,
        recruiterType: type,
        positionType: position,
      },
    };
    RequestPost("biodatarecruiter", data)
      .then(res => {
        if (res) {
          AppHistory.push("/recruiter/profile-company");
        }
      })
      .catch(err => {
        console.log("RecruiterProfileSetup", err);
      });
  }
  handleClick(e) {
    document.getElementById("button-submit").click();
  }
  componentDidMount() {
    ValidatorForm.addValidationRule("selectRequired", value => {
      if (value === 0 || value === " ") {
        return false;
      }
      return true;
    });
  }
  componentWillUnmount() {
    ValidatorForm.removeValidationRule("selectRequired");
  }

  render() {
    const { t } = this.props;
    return (
      <div className="auth">
        <MyHelmet title="Update profile" />
        <Header
          shadow={true}
          stepperData={t("recruiterSetup", { returnObjects: true })}
          stepper={true}
          stepWidth={730}
          activeStep={0}
        />
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          direction="column"
          style={{ marginTop: 130, marginBottom: 120 }}>
          <div style={{ width: 520, color: palette.primary }}>
            <Typography style={{ fontSize: 24 }}>
              {t("profilesetup:welcome")}
            </Typography>
            <Typography>{t("profilesetup:info")}</Typography>
            <label
              style={{ fontWeight: "lighter", color: "#CD594D", fontSize: 12 }}>
              *required
            </label>
            <ValidatorForm onSubmit={this.submitForm}>
              <div style={{ marginTop: "5%" }}>
                <label
                  style={{
                    color: palette.primary,
                    fontSize: 14,
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                  }}>
                  {t("profilesetup:form.field.email")}
                </label>
                <br />
                <label
                  style={{
                    color: palette.primary,
                    fontWeight: "bolder",
                    fontSize: 24,
                  }}>
                  {this.state.email}
                </label>
              </div>
              <div style={{ marginTop: "5%" }}>
                <label
                  style={{
                    color: "#2E69B2",
                    fontSize: 14,
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                  }}>
                  {t("profilesetup:form.field.firstName")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <TextValidator
                  style={{
                    width: "90%",
                  }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.name")]}
                  onChange={e => {
                    var val = e.currentTarget.value;
                    console.log(val);
                    nameRegex.test(val)
                      ? this.setState({ first_name: val })
                      : e.preventDefault();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("profilesetup:form.placeholder.firstName")}
                  value={this.state.first_name}
                  className="Field"
                />
              </div>
              <div>
                <label
                  style={{
                    color: "#2E69B2",
                    fontSize: 14,
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                  }}>
                  {t("profilesetup:form.field.middleName")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk"></span>
                </label>
                <TextField
                  style={{
                    width: "90%",
                  }}
                  onChange={e => {
                    var val = e.currentTarget.value;
                    nameRegex.test(val)
                      ? this.setState({ middle_name: val })
                      : e.preventDefault();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("profilesetup:form.placeholder.middleName")}
                  value={this.state.middle_name}
                  className="Field"
                />
              </div>
              <div>
                <label
                  style={{
                    color: "#2E69B2",
                    fontSize: 14,
                    textTransform: "uppercase",
                    fontWeight: "lighter",
                  }}>
                  {t("profilesetup:form.field.lastName")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk"></span>
                </label>
                <TextField
                  style={{
                    width: "90%",
                  }}
                  onChange={e => {
                    var val = e.currentTarget.value;
                    nameRegex.test(val)
                      ? this.setState({ last_name: val })
                      : e.preventDefault();
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={t("profilesetup:form.placeholder.lastName")}
                  value={this.state.last_name}
                  className="Field"
                />
              </div>
              <Grid
                required={true}
                value={this.state.phoneNumber}
                style={{ marginTop: "5%" }}>
                <Typography
                  style={{
                    color: palette.primary,
                    fontWeight: "lighter",
                    textTransform: "uppercase",
                  }}>
                  {t("biodata:form.field.contact")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </Typography>
                <Grid container direction="row" className="group">
                  <TextField
                    value={"+62"}
                    className="Field"
                    contentEditable={false}
                    style={{ width: 62, marginTop: 0 }}
                  />
                  <TextValidator
                    placeholder={t("biodata:form.placeholder.contact")}
                    validators={[
                      "required",
                      "isNumber",
                      "minStringLength:8",
                      "maxStringLength:14",
                    ]}
                    errorMessages={[
                      t("validation.required.phoneNumber"),
                      t("validation.valid.phoneNumber"),
                      t("validation.minLength.phoneNumber"),
                    ]}
                    onChange={e => {
                      var char = e.currentTarget.value;
                      if (char.split("")[0] === "0") {
                        char = char.slice(1);
                      }
                      this.setState({ phoneNumber: char });
                    }}
                    value={this.state.phoneNumber}
                    className="Field"
                    style={{ width: 400, marginLeft: 10, marginTop: 0 }}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: "5%" }}>
                <label
                  style={{
                    fontSize: 15,
                    fontWeight: "lighter",
                    textTransform: "uppercase",
                  }}>
                  {t("profilesetup:form.field.type")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <SelectValidator
                  className="Field select-with-placeholder"
                  style={{ width: "91%" }}
                  value={this.state.type}
                  defaultValue={0}
                  onChange={e => this.setState({ type: e.target.value })}
                  validators={["selectRequired"]}
                  errorMessages={[t("validation.required.type")]}>
                  <MenuItem
                    style={{ color: palette.popacity(0.5) }}
                    value={0}
                    disabled>
                    <span>{t("profilesetup:form.placeholder.type")}</span>
                  </MenuItem>
                  {recruiter_type.map((v, k) => {
                    return (
                      <MenuItem
                        key={k}
                        style={{
                          fontSize: 24,
                          color: palette.primary,
                          fontWeight: "bold",
                        }}
                        value={v.code}>
                        {v.label}
                      </MenuItem>
                    );
                  })}
                </SelectValidator>
              </div>
              <div style={{ marginTop: "5%" }}>
                <label
                  style={{
                    fontSize: 15,
                    fontWeight: "lighter",
                    textTransform: "uppercase",
                  }}>
                  {t("profilesetup:form.field.position")}
                  <span
                    style={{ color: "#CD594D" }}
                    className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                    &thinsp;*
                  </span>
                </label>
                <TextValidator
                  onChange={e => {
                    this.setState({ position: e.target.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="Field"
                  placeholder={t("profilesetup:form.placeholder.position")}
                  name="position"
                  value={this.state.position}
                  style={{ width: "91%" }}
                  validators={["required"]}
                  errorMessages={[t("validation.required.position")]}
                />
              </div>
              <button type="submit" id="button-submit" className="Submit-Btn">
                submit
              </button>
            </ValidatorForm>
          </div>
        </Grid>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          style={{
            height: 60,
            position: "fixed",
            bottom: 0,
            backgroundColor: palette.primary,
            paddingRight: 50,
          }}>
          <NextBtn
            color="white"
            text={t("button.continue")}
            onClick={() => this.handleClick()}
          />
        </Grid>
      </div>
    );
  }
}
export default withTranslation()(RecruiterProfileSetup);
