import i18n from "i18next";

import Backend from "i18next-xhr-backend";
import { getItem, setItem } from "app/utils";
const backendOpts = {
  loadPath: `/locales/{{lng}}/{{ns}}.json`,
};
i18n
  .use(Backend)
  // .use(LanguageDetector)
  .init({
    debug: false,
    fallbackLng: initLang(),
    backend: backendOpts,
    ns: [
      "general",
      "auth",
      "welcome",
      "biodata",
      "about",
      "contact",
      "pretest",
      "finish",
      "basictest",
      "profilesetup",
      "rec-dashboard",
      "vacancy",
      "cv",
      "candidates",
      "job-opening",
      "interview-schedule",
      "calendar-schedule",
      "rec-user-dashboard",
      "rec-add-user",
      "user-profile",
      "notification",
    ],
    defaultNS: "general",
  });
function initLang() {
  if (getItem("lang")) {
    return getItem("lang");
  } else {
    setItem("lang", "id");
    return "id";
  }
}
export default i18n;
