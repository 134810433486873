import { AUTH_API, RequestPost } from "./HttpClient";

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export function getItem(key) {
  try {
    var name = key + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        var val = c.substring(name.length, c.length);
        return val;
      }
    }
    return null;
  } catch {
    return null;
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function setItem(key, value, remove) {
  try {
    var days = process.env.REACT_APP_DAYEXPIRED
      ? process.env.REACT_APP_DAYEXPIRED
      : 7;
    var d = new Date();
    d.setDate(d.getDate() + days);
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    var expires = "expires=" + d.toGMTString();
    var tag;
    if (remove) {
      tag =
        key + "=" + value + ";expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    } else {
      tag = key + "=" + value + ";" + expires + ";path=/";
    }

    // if (process.env.NODE_ENV === "production") {
    //   document.cookie = tag + ";Secure";
    // } else {
    document.cookie = tag;
    // }
    return true;
  } catch {
    return false;
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export function removeItem(key) {
  try {
    setItem(key, "", true);
    return true;
  } catch (er) {
    console.error(er);
    return false;
  }
}

/**
 * Burn it all to the ground.
 */
export function clearAll() {
  if (getItem("user_id")) {
    RequestPost(AUTH_API + "connect/logout", {
      loading: true,
      userId: getItem("user_id"),
    })
      .then(() => {
        console.log("Logout success");
        var template = GetLocalItem("SELECTED_TEMPLATE");
        localStorage.clear();
        SetLocalItem("SELECTED_TEMPLATE", template);
      })
      .catch(le => {
        setTimeout(() => {
          clearAll();
        }, 500);
        console.log("logout error", le);
      });
    try {
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c) {
          var name = ca[i].split("=")[0].trim();
          if (name === "lang") {
            setItem("lang", getItem("lang"));
          } else {
            setItem(name, "", true);
          }
        }
      }
      return true;
    } catch {
      return false;
    }
  }
}

export function SetLocalItem(key, value, min = 0, hour = 0, day = 0) {
  if (!value) {
    localStorage.removeItem(key);
    return true;
  }
  try {
    var days =
      day <= 0
        ? process.env.REACT_APP_DAYEXPIRED
          ? process.env.REACT_APP_DAYEXPIRED
          : 7
        : day;
    var d = new Date();
    d.setDate(d.getDate() + days);
    d.setHours(hour);
    d.setMinutes(min);
    d.setSeconds(0);
    var item = {
      value: value,
      expires: d.getTime(),
    };
    localStorage.setItem(key, JSON.stringify(item));
    return true;
  } catch (error) {
    return false;
  }
}

export function GetLocalItem(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expires) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}
