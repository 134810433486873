import React from "react";
import { Text, View, Page } from "@react-pdf/renderer";
import { CIStyles, HeaderLogoPDF, WaterMark } from "./ci-styles";
import { palette } from "assets/css/main";
import { findObject } from "app/utils";
const TableItemSD = props => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        fontSize: 12,
        width: "100%",
        borderTop: "1pt solid " + palette.cv.borderColor,
        borderBottom: "1pt solid " + palette.cv.borderColor,
        marginTop: -1,
      }}>
      <View
        style={{
          color: palette.cv.textGrey,
          textAlign: "center",
          borderRight: props.head
            ? "none"
            : "1pt solid " + palette.cv.borderColor,
          width: "10%",
          padding: "5pt 5pt",
          fontWeight: "light",
        }}>
        <Text>{props.num}</Text>
      </View>
      <View
        style={{
          color: palette.cv.textGrey,
          textAlign: props.head ? "center" : "left",
          borderRight: props.head
            ? "none"
            : "1pt solid " + palette.cv.borderColor,
          width: "30%",
          padding: "5pt 5pt",
          fontWeight: "light",
        }}>
        <Text>{props.title}</Text>
      </View>
      <View
        style={{
          color: props.head ? palette.cv.textGrey : palette.cv.textColor,
          textAlign: props.head ? "center" : "left",
          width: "55%",
          padding: "5pt 5pt",
          fontWeight: props.head ? "light" : "normal",
        }}>
        {typeof props.value !== "object" ? (
          <Text style={{}}>{props.value}</Text>
        ) : (
          props.value
        )}
      </View>
    </View>
  );
};

const CIPageSelfDescription = props => {
  const listDataSD = props.data ? props.data : [];

  return (
    <Page style={CIStyles.page}>
      <WaterMark />
      <HeaderLogoPDF />
      <View style={{ position: "fixed" }} fixed>
        <Text style={{ color: palette.primary, fontWeight: "bold" }}>
          II. Self Description
        </Text>
      </View>
      {/* Questions */}
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 20,
        }}>
        <View style={CIStyles.subtitleLine} />
        <Text style={{ fontSize: 14 }}>Questions</Text>
      </View>
      <View style={{ marginTop: 20 }}>
        <TableItemSD
          head={true}
          num={"No"}
          title={"Questions"}
          value={"Answers"}
        />
        {listDataSD.map((v, i) => {
          const number = i < 9 ? "0" + (i + 1) : i + 1;
          let answer = <Text></Text>;
          if (v.categoryQuestion === "Freetext") {
            answer = v.responseAnswers.map((x, y) => {
              return <Text key={"as" + y}>{x.userAnswer}</Text>;
            });
          } else {
            const option = v.aboutAnswers;
            answer = v.responseAnswers.map((x, y) => {
              const text = x.otherValue
                ? x.otherValue
                : findObject(option, "answerId", x.userAnswer, false)
                    .answerTextEn;
              return <Text key={"as" + y}>{text}</Text>;
            });
          }
          return (
            <TableItemSD
              break
              key={"ab-" + i}
              num={number}
              title={v.questionTextId}
              value={<View>{answer}</View>}
            />
          );
        })}
      </View>
      {/* Questions */}
    </Page>
  );
};
export default CIPageSelfDescription;
