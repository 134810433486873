import React from "react";
import { withTranslation } from "react-i18next";
import { MyHelmet, ApplicantHeader } from "app/components";
import { AppHistory, getItem, GetLocalItem, RequestGet } from "app/utils";
import data from "./data";
import { Button, Grid, Typography } from "@material-ui/core";
import { ImgCompanyLogo, IconBackJob } from "assets/img";
import alertData from "app/components/alert/alert-data";
import SideStepDetail from "./SideStepDetail";
import JobDetails from "./JobDetails";
import JobRequirement from "./JobRequirement";
import CompanyProfile from "./CompanyProfile";
import InterviewInvitation from "./InterviewInvitation";
import "./custom.css";

class JobOpeningDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobDetail: data.jobDetail,
      step: "JobDetails",
      loaded: false,
    };
  }

  getJobDetail(id) {
    RequestGet("JobOpen/Detail/" + id)
      .then(res => {
        this.setState({ jobDetail: res.data });
        data.jobDetail = res.data;

        this.setState({ loaded: true });
      })
      .catch(err => {
        alertData.show(String(err.status), "error");
      });
  }

  componentDidMount() {
    const { invitation } = AppHistory.location.state
      ? AppHistory.location.state
      : {};
    if (invitation) {
      this.setState({ step: "InterviewInvitation" });
    }
    this.getJobDetail(GetLocalItem("InterviewID"));
  }

  render() {
    const { t } = this.props;
    const { jobDetail, step, loaded } = this.state;
    return (
      <div>
        <MyHelmet title={"Home"} />
        <ApplicantHeader menu="job-opening" />
        <Grid
          position="absolute"
          container
          style={{
            zIndex: 100,
            display: "flex",
            position: "fixed",
            top: 80,
            backgroundColor: "#52C8F4",
            padding: "23px 143px",
          }}>
          <Grid item lg={3} md={3} sm={3}>
            <div
              style={{
                height: "135px",
                width: "135px",
                borderRadius: "50%",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
              }}>
              <img
                src={
                  jobDetail.companyLogo ? jobDetail.companyLogo : ImgCompanyLogo
                }
                alt={"company"}
              />
            </div>
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12}>
                <Button
                  onClick={() => {
                    AppHistory.push("/user/job-opening");
                  }}
                  style={{
                    color: "white",
                    fontSize: "14px",
                    textTransform: "none",
                    textDecoration: "underline",
                  }}>
                  <img
                    src={IconBackJob}
                    alt={"company"}
                    height="8vh"
                    align="left"
                    style={{
                      marginRight: "4px",
                    }}
                  />
                  {t("job-opening:jobOpeningDetail.back")}
                </Button>
              </Grid>

              <Grid
                item
                lg={4}
                md={4}
                sm={4}
                style={{
                  marginTop: "9px",
                }}>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "24px",
                  }}>
                  {jobDetail.jobTitle ? jobDetail.jobTitle : ""}
                </Typography>
              </Grid>

              <Grid
                item
                lg={8}
                md={8}
                sm={8}
                style={{
                  marginTop: "9px",
                }}>
                <Typography
                  style={{
                    backgroundColor: "#FCE7C5",
                    borderRadius: "20px",
                    padding: "6px 13px 3px",
                    color: "#CD594D",
                    fontSize: "12px",
                    maxWidth: "max-content",
                  }}>
                  {getItem("lang") === "en"
                    ? jobDetail.statusEng
                      ? jobDetail.statusEng
                      : ""
                    : jobDetail.statusIna
                    ? jobDetail.statusIna
                    : ""}
                </Typography>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                style={{
                  marginTop: "2px",
                }}>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "18px",
                  }}>
                  {jobDetail.company ? jobDetail.company : ""}
                </Typography>
              </Grid>

              <Grid
                item
                lg={3}
                md={3}
                sm={3}
                style={{
                  marginTop: "4px",
                }}>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "14px",
                  }}>
                  {t("job-opening:jobOpeningDetail.location")}
                </Typography>
              </Grid>

              {loaded && (
                <Grid
                  item
                  lg={9}
                  md={9}
                  sm={9}
                  style={{
                    marginTop: "4px",
                  }}>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}>
                    {jobDetail.jobDetail.workLocations
                      ? jobDetail.jobDetail.workLocations.join(", ")
                      : null}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            paddingBottom: 100,
          }}>
          <Grid container>
            <Grid item lg={3} md={3} sm={3}>
              <SideStepDetail
                head={step}
                onChange={v => {
                  this.setState({ step: v });
                }}
              />
            </Grid>

            {loaded && (
              <Grid item lg={9} md={9} sm={9}>
                {step === "JobDetails" ? (
                  <JobDetails data={jobDetail} />
                ) : step === "JobRequirement" ? (
                  <JobRequirement data={jobDetail} />
                ) : step === "CompanyProfile" ? (
                  <CompanyProfile data={jobDetail} />
                ) : (
                  <InterviewInvitation data={jobDetail} />
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}
export default withTranslation()(JobOpeningDetail);
