import React from "react";
import { palette } from "assets/css/main";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import data from "./data";
import { withTranslation } from "react-i18next";
import { AppHistory, findObject, getItem, RequestPut } from "app/utils";
import { CVFooter } from "../footer-cv";
import alertData from "app/components/alert/alert-data";
import { IconEditWhite, IconVacancyBullet } from "assets/img";
import "./component/custom.css";
import OptionWorkAbout from "./option-work-about";

class TellUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...data.tell_us,
      edit: false,
    };
  }

  handleChange(obj, index) {
    var newTellUs = this.state.tellUsApi;
    var newAnswer = {
      userAnswer: "",
      otherValue: "",
    };

    if (this.state.tellUsApi[index].categoryQuestion === "Freetext") {
      if (obj.answer === "" || obj.answer === null) {
        newTellUs[index].responseAnswers = [];
      } else {
        if (this.state.tellUsApi[index].responseAnswers.length === 0) {
          newAnswer.userAnswer = obj.answer;
          newTellUs[index].responseAnswers.push(newAnswer);
        } else {
          newTellUs[index].responseAnswers[0].userAnswer = obj.answer;
        }
      }
    } else if (this.state.tellUsApi[index].categoryQuestion === "Single") {
      newTellUs[index].responseAnswers = obj.answer;
    } else {
      newTellUs[index].responseAnswers = obj.answer;
    }

    data.setTellUs({ tellUsApi: newTellUs });
    this.setState({ tellUsApi: newTellUs });
  }

  putAboutQuestion(obj) {
    RequestPut("aboutquestion", obj)
      .then(res => {
        console.log(res);
        alertData.show("Success");

        setTimeout(() => {
          this.setState({ edit: false });
          AppHistory.push("/user/about-you");
        }, 1000);
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err), "error");
      });
  }

  render() {
    const { t } = this.props;
    const { edit } = this.state;
    return (
      <Grid
        container
        className="about-you"
        style={{
          paddingBottom: "10%",
          boxShadow: "rgba(0, 0, 0, 0.09) 0px 0px 13px",
        }}>
        <div
          style={{
            width: "100%",
            height: 28,
            padding: "0 3% 6% 3%",
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "#2E69B2",
            flexDirection: "column",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
          }}>
          <Grid container>
            <Grid
              item
              lg={10}
              md={10}
              sm={10}
              style={{
                marginTop: "3%",
              }}>
              <Typography
                style={{
                  color: "white",
                  fontSize: 24,
                  textTransform: "none",
                }}>
                {t("about:tellUs.title")}
              </Typography>
            </Grid>
            {!edit && (
              <Grid
                item
                lg={2}
                md={2}
                sm={2}
                style={{
                  marginTop: "2%",
                }}>
                <Button
                  onClick={() => this.setState({ edit: true })}
                  style={{
                    border: "1px solid #FFFFFF",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                    color: "white",
                    fontSize: 14,
                    textTransform: "none",
                    padding: "8px 22px",
                  }}>
                  <img src={IconEditWhite} alt={"Edit"} height="14vh" />
                  &nbsp; Edit
                </Button>
              </Grid>
            )}
          </Grid>
        </div>

        {data.tell_us.tellUsApi.map((v, i) => {
          var number = i + 1;
          const answered = v.responseAnswers[0] ? v.responseAnswers[0] : [{}];
          return (
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              style={{ marginTop: "26px", paddingRight: "2%" }}
              key={i}>
              <Grid container>
                <Grid
                  item
                  lg={1}
                  md={1}
                  sm={1}
                  style={{
                    paddingLeft: "2%",
                  }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#B0B7BD",
                      borderRadius: "20px",
                      color: "white",
                      textAlign: "center",
                    }}>
                    {number}
                  </div>
                </Grid>
                <Grid item lg={11} md={11} sm={11}>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "lighter",
                          color: palette.primary,
                        }}>
                        {getItem("lang") === "en"
                          ? v.questionTextEn
                          : v.questionTextId}
                      </Typography>
                    </Grid>

                    {answered ? (
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ marginTop: "12px" }}>
                        {edit === true ? (
                          v.categoryQuestion === "Single" ? (
                            <OptionWorkAbout
                              onChange={val => {
                                this.handleChange({ answer: val }, i);
                              }}
                              value={v.responseAnswers}
                              options={v.aboutAnswers}
                            />
                          ) : v.categoryQuestion === "Multiple" ? (
                            <OptionWorkAbout
                              onChange={val => {
                                this.handleChange({ answer: val }, i);
                              }}
                              multiple={true}
                              value={v.responseAnswers}
                              options={v.aboutAnswers}
                            />
                          ) : (
                            <TextField
                              multiline
                              style={{ width: "90%" }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder={t(
                                "about:tellUsFirstTime.popup.placeholder"
                              )}
                              className="Field"
                              onChange={val =>
                                this.handleChange(
                                  { answer: val.target.value },
                                  i
                                )
                              }
                              value={
                                v.responseAnswers.length > 0
                                  ? answered.userAnswer
                                  : ""
                              }
                            />
                          )
                        ) : v.categoryQuestion === "Multiple" ? (
                          v.responseAnswers.length > 0 ? (
                            v.responseAnswers.map((val, idx) => {
                              return (
                                <div style={{ width: "100%" }} key={idx}>
                                  <Grid container>
                                    <Grid
                                      item
                                      lg={1}
                                      md={1}
                                      sm={1}
                                      style={{ marginTop: 4 }}>
                                      <img
                                        style={{
                                          paddingTop: 5,
                                          paddingLeft: 2,
                                        }}
                                        src={IconVacancyBullet}
                                        alt={idx}
                                        height="10vh"
                                        align="center"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      lg={11}
                                      md={11}
                                      sm={11}
                                      style={{ marginTop: 4, marginLeft: -30 }}>
                                      <Typography
                                        style={{
                                          wordWrap: "break-word",
                                          fontSize: "18px",
                                          color: palette.primary,
                                        }}>
                                        {val.otherValue === ""
                                          ? getItem("lang") === "en"
                                            ? findObject(
                                                v.aboutAnswers,
                                                "answerId",
                                                val.userAnswer
                                              ).answerTextEn
                                            : findObject(
                                                v.aboutAnswers,
                                                "answerId",
                                                val.userAnswer
                                              ).answerTextId
                                          : getItem("lang") === "en"
                                          ? findObject(
                                              v.aboutAnswers,
                                              "answerId",
                                              val.userAnswer
                                            ).answerTextEn
                                          : findObject(
                                              v.aboutAnswers,
                                              "answerId",
                                              val.userAnswer
                                            ).answerTextId +
                                            ", " +
                                            val.otherValue}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                              );
                            })
                          ) : null
                        ) : (
                          <Typography
                            style={{
                              wordWrap: "break-word",
                              color: palette.primary,
                              fontSize: "18px",
                            }}>
                            {v.categoryQuestion === "Single"
                              ? answered.otherValue === ""
                                ? getItem("lang") === "en"
                                  ? findObject(
                                      v.aboutAnswers,
                                      "answerId",
                                      answered.userAnswer
                                    ).answerTextEn
                                  : findObject(
                                      v.aboutAnswers,
                                      "answerId",
                                      answered.userAnswer
                                    ).answerTextId
                                : getItem("lang") === "en"
                                ? findObject(
                                    v.aboutAnswers,
                                    "answerId",
                                    answered.userAnswer
                                  ).answerTextEn
                                : findObject(
                                    v.aboutAnswers,
                                    "answerId",
                                    answered.userAnswer
                                  ).answerTextId +
                                  ", " +
                                  answered.otherValue
                              : answered.userAnswer}
                          </Typography>
                        )}
                      </Grid>
                    ) : (
                      "null"
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}

        {edit && (
          <CVFooter
            text="save"
            textBack="Cancel"
            BackClick={() => {
              this.setState({ edit: false });
            }}
            NextClick={() => {
              console.log(this.state.tellUsApi);
              this.putAboutQuestion(this.state.tellUsApi);
            }}
          />
        )}
      </Grid>
    );
  }
}
export default withTranslation()(TellUs);
