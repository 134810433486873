import React from "react";
import propTypes from "prop-types";
import { getItem, t } from "app/utils";
import { HtmlTooltip } from "app/components";
import { palette } from "assets/css/main";
import { Avatar, Chip } from "@material-ui/core";

class TextContent extends React.Component {
  static defaultProps = {
    text: "",
  };
  maxLength = 100;
  state = {
    content: this.props.text,
    minText: this.props.text.slice(0, this.maxLength),
    displayText: "",
    readMore: false,
    longText: false,
  };
  componentDidMount() {
    if (this.state.content.length > this.maxLength) {
      console.log("object");
      this.setState({
        displayText: this.state.minText,
        readMore: false,
        longText: true,
      });
    }
  }
  handleClick(x) {
    x.preventDefault();
    this.setState({
      readMore: !this.state.readMore,
      displayText: this.state.readMore
        ? this.state.minText
        : this.state.content,
    });
  }
  render() {
    const { displayText, readMore, longText } = this.state;
    return (
      <p style={{ fontWeight: "lighter" }}>
        {displayText}
        {longText && readMore ? " " : "...  "}
        {longText ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            style={{
              fontWeight: "bold",
              color: palette.primary,
              cursor: "pointer",
            }}
            onClick={e => this.handleClick(e)}>
            {readMore ? t("button.readLess") : t("button.readMore")}
          </a>
        ) : (
          ""
        )}
      </p>
    );
  }
}
/**
 * @extends {React.Component<{data:propTypes.array}>}
 */
export default class TabContent extends React.Component {
  static propTypes = {
    data: propTypes.array,
  };
  static defaultProps = {
    data: [],
  };
  render() {
    const { data } = this.props;
    return (
      <div className="Tabs-User-Profile">
        {data.map((v, k) => {
          if (v.softSkillDetails.length) {
            const detail = v.softSkillDetails;
            return (
              <table key={"categoryDetail" + k}>
                <thead>
                  <tr>
                    <th colSpan={3}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}>
                        <p style={{ margin: "0px 25px 0px 0px" }}>
                          {getItem("lang") === "en"
                            ? v.softskillNameEng
                            : v.softskillNameIna}
                        </p>

                        <HtmlTooltip
                          style={{ color: "white" }}
                          title={
                            <div style={{ color: "white" }}>
                              {getItem("lang") === "en"
                                ? v.softskillDescEng
                                : v.softskillDescIna}
                            </div>
                          }
                          arrow>
                          <Avatar
                            style={{
                              backgroundColor: palette.primarydark,
                              width: 18,
                              height: 18,
                              fontSize: 12,
                            }}>
                            ?
                          </Avatar>
                        </HtmlTooltip>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detail.map((vd, kd) => {
                    var tooltip =
                      vd.attributeEng && vd.attributeIna
                        ? getItem("lang") === "en"
                          ? vd.attributeEng.replace(/\n/g, "").split("-")
                          : vd.attributeIna.replace(/\n/g, "").split("-")
                        : [];
                    tooltip.shift();
                    return (
                      <tr key={"detail" + kd}>
                        <td style={{ width: "25%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}>
                            <p style={{ margin: "0px 25px 0px 0px" }}>
                              {getItem("lang") === "en"
                                ? vd.softskillValueEng
                                : vd.softskillValueIna}
                            </p>
                            {vd.attributeEng && (
                              <HtmlTooltip
                                style={{ color: "white" }}
                                title={tooltip.map((vt, kt) => {
                                  if (k === 0) {
                                    return (
                                      <div
                                        key={"tooltip-" + kd + "-" + kt}
                                        style={{ color: "white" }}>
                                        - {vt}
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        key={"tooltip-" + kd + "-" + kt}
                                        style={{ color: "white" }}>
                                        - {vt}
                                      </div>
                                    );
                                  }
                                })}
                                arrow>
                                <Avatar
                                  style={{
                                    backgroundColor: palette.primarydark,
                                    width: 18,
                                    height: 18,
                                    fontSize: 12,
                                  }}>
                                  ?
                                </Avatar>
                              </HtmlTooltip>
                            )}
                          </div>
                        </td>
                        <td style={{ width: "25%" }}>
                          <Chip
                            label={vd.value}
                            style={{
                              color: "white",
                              minWidth: 100,
                              width:
                                vd.value === "LOW"
                                  ? "25%"
                                  : vd.value === "MEDIUM"
                                  ? "50%"
                                  : "100%",
                              backgroundColor:
                                vd.value === "LOW"
                                  ? palette.sopacity(0.5)
                                  : vd.value === "MEDIUM"
                                  ? palette.secondary
                                  : palette.primary,
                            }}
                          />
                        </td>
                        <td>
                          <TextContent
                            text={
                              getItem("lang") === "en"
                                ? vd.descriptionEng
                                : vd.descriptionIna
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          } else {
            return (
              <p key={"p" + k}>
                {getItem("lang") === "en"
                  ? v.softskillDescEng
                  : v.softskillDescIna}
              </p>
            );
          }
        })}
      </div>
    );
  }
}
