import { Button } from "@material-ui/core";
import { DialogueFull } from "app/components";
import { palette } from "assets/css/main";
import PropTypes from "prop-types";
import React from "react";
import i18next from "i18next";

class ModalDescription extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    data: PropTypes.any,
  };
  static defaultProps = {
    onClose: () => {},
    isOpen: false,
  };
  render() {
    const { isOpen, data, onClose } = this.props;
    return (
      <DialogueFull hideClose={true} open={isOpen}>
        <div
          style={{
            backgroundColor: "white",
            width: 900,
            boxSizing: "border-box",
            borderRadius: 4,
          }}>
          <div
            style={{
              padding: "10px 40px 20px",
              height: "100%",
              minHeight: 200,
              color: palette.primary,
            }}>
            <p style={{ fontSize: 18, fontWeight: "bold" }}>{data.title}</p>
            <p>{data.desc}</p>
          </div>
          <div
            style={{
              display: "flex",
              bottom: 0,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "white",
              boxSizing: "border-box",
              width: "100%",
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
              padding: "20px 40px",
            }}>
            <Button
              onClick={() => {
                onClose();
              }}
              style={{
                backgroundColor: palette.primary,
                color: "white",
                textTransform: "capitalize",
              }}>
              {i18next.t("button.back")}
            </Button>
          </div>
        </div>
      </DialogueFull>
    );
  }
}

export default ModalDescription;
