import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { AppHistory, loadImage, setItem } from "app/utils";
import { PRIMARY_BUTTON } from "assets/css/main";
import { withTranslation } from "react-i18next";
import { applicant, recruiter } from "app/routes";

class NotFound extends React.Component {
  state = { show: false };
  componentDidMount() {
    const {
      location: { pathname },
    } = AppHistory;
    this.setState({ show: false });

    this.validateUrl(recruiter, "recruiter", pathname).then(() => {
      this.validateUrl(applicant, "applicant", pathname).then(() => {
        setTimeout(() => {
          this.setState({ show: true });
        }, 2000);
      });
    });
  }

  async validateUrl(route, role, p) {
    var count = p.split("/");
    route.map(v => {
      var c = v.path.split("/");
      if (v.path.indexOf(":") > 0) {
        var tmp = v.path.split(":")[0];
        if (p.indexOf(tmp) >= 0) {
          if (c.length === count.length) {
            return (window.location.href =
              "/user/login/" + role + "?redir=" + p);
          }
        }
      } else {
        if (p.indexOf(v.path) >= 0) {
          if (c.length === count.length) {
            // return (window.location.href =
            //   "/user/login/" + role + "?redir=" + p);
          }
        }
      }
      return true;
    });
    return true;
  }

  render() {
    const { t, history } = this.props;
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        style={{ paddingTop: 50, display: this.state.show ? "flex" : "none" }}>
        <img src={loadImage("logo.png")} alt="Hiregrade" width={200} />
        <Typography variant="h3" style={{ marginBottom: 20 }}>
          {t("error.notfound.title")}
        </Typography>
        <Typography variant="h5" style={{ marginBottom: 50 }}>
          {t("error.notfound.description")}
        </Typography>
        <Button
          variant="contained"
          style={{ ...PRIMARY_BUTTON }}
          onClick={() => {
            if (history.action === "PUSH") {
              history.goBack();
            } else {
              history.push("/");
              setItem("FL", null, true);
              window.location.reload();
            }
          }}>
          {history.action === "PUSH"
            ? t("error.button.back")
            : t("error.button.home")}
        </Button>
      </Grid>
    );
  }
}
export default withTranslation()(NotFound);
