import { HeaderNologin } from "app/components";
import { t } from "app/utils";
import { palette } from "assets/css/main";
import { ImgUnderConstruction } from "assets/img";
import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class NotSupported extends Component {
  render() {
    return (
      <div>
        <HeaderNologin button={false} />
        <div
          style={{
            padding: "80px 25px",
            textAlign: "center",
            color: palette.primary,
          }}>
          <img src={ImgUnderConstruction} width="100%" alt="Unsupported" />
          <h3>{t("notAvailable.title")}</h3>
          <p>{t("notAvailable.content")}</p>

          <Link
            to="/user/logout"
            style={{
              color: palette.primary,
              textDecoration: "underline",
              fontWeight: "bold",
            }}>
            {t("link.logout")}
          </Link>
        </div>
      </div>
    );
  }
}
