import React from "react";
import { observer } from "mobx-react";
import { Grid, LinearProgress } from "@material-ui/core";
import { palette } from "assets/css/main";
import { NextBtn } from "app/components";

export const FooterInterview = observer(props => {
  const { text, progress, textProgress, valueProgress, totalProgress } = props;
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      style={{
        minHeight: 60,
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: progress ? palette.grey : palette.primary,
        padding: "0px 50px",
        zIndex: 100,
        display: "flex",
        justifyContent: progress ? "center" : "flex-end",
      }}>
      {!progress && (
        <NextBtn
          color={"white"}
          onClick={() => props.NextClick()}
          text={text}
        />
      )}
      {progress && (
        <>
          <div style={{ color: palette.darkGrey, textAlign: "center" }}>
            <LinearProgress
              className={"Biodata freetext-progress"}
              style={{ width: 200, height: 5, marginTop: 15 }}
              variant="determinate"
              value={(valueProgress / totalProgress) * 100}
            />
            <p style={{ margin: "10px 0px" }}>
              {valueProgress + " / " + totalProgress + " " + textProgress}
            </p>
          </div>
        </>
      )}
    </Grid>
  );
});
