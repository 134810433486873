import React from "react";
import {
  Dialog,
  Grid,
  Typography,
  Button,
  TextField,
  LinearProgress,
} from "@material-ui/core";
import { palette } from "assets/css/main";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import data from "app/pages/applicant/initialize/bio-data/data";
import "../user-home.css";
import { IcnX } from "assets/img";
import { getItem, RequestGet } from "app/utils";
import alertData from "app/components/alert/alert-data";
import OptionWorkAbout from "./about-you/option-work-about";

class DialogueAdditionalQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      biodata_additionalQuestion: data.biodata_additionalQuestion,
      progressStep: 0,
      step: 0,
      loaded: false,
    };
  }

  handleChange(obj) {
    var btn = document.getElementById("BtnNext");
    var newTellUs = this.state.biodata_additionalQuestion;
    var newProgress = this.state.progressStep;
    var newAnswer = {
      userAnswer: "",
      otherValue: "",
    };

    if (
      this.state.biodata_additionalQuestion[this.state.step]
        .categoryQuestion === "Freetext"
    ) {
      if (obj.answer === "" || obj.answer === null) {
        if (
          this.state.biodata_additionalQuestion[this.state.step].responseAnswers
            .length > 0
        ) {
          // revert
          btn.disabled = true;
          btn.classList.add("Mui-disabled");

          this.setState({ progressStep: newProgress - 1 });
        }
        newTellUs[this.state.step].responseAnswers = [];
      } else {
        if (
          this.state.biodata_additionalQuestion[this.state.step].responseAnswers
            .length === 0
        ) {
          // enable button
          btn.disabled = false;
          btn.classList.remove("Mui-disabled");
          this.setState({ progressStep: newProgress + 1 });

          newAnswer.userAnswer = obj.answer;
          newTellUs[this.state.step].responseAnswers.push(newAnswer);
        } else {
          newTellUs[this.state.step].responseAnswers[0].userAnswer = obj.answer;
        }
      }
    } else if (
      this.state.biodata_additionalQuestion[this.state.step]
        .categoryQuestion === "Single"
    ) {
      // enable button
      btn.disabled = false;
      btn.classList.remove("Mui-disabled");

      if (
        this.state.biodata_additionalQuestion[this.state.step].responseAnswers
          .length === 0
      ) {
        this.setState({ progressStep: newProgress + 1 });
      }

      newTellUs[this.state.step].responseAnswers = obj.answer;
    } else {
      // multiple
      btn.disabled = false;
      btn.classList.remove("Mui-disabled");
      if (
        this.state.biodata_additionalQuestion[this.state.step].responseAnswers
          .length === 0
      ) {
        this.setState({ progressStep: newProgress + 1 });
      } else {
        // remove progress
        if (obj.answer.length === 0) {
          this.setState({ progressStep: newProgress - 1 });
        }
      }

      newTellUs[this.state.step].responseAnswers = obj.answer;
    }

    this.setState({ biodata_additionalQuestion: newTellUs });
    data.biodata_additionalQuestion = newTellUs;
  }

  getAdditionalQuestion() {
    RequestGet("aboutquestion/additional")
      .then(res => {
        this.setState({ biodata_additionalQuestion: res.data });
        data.biodata_additionalQuestion = res.data;
        this.setState({ loaded: true });
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  componentDidMount() {
    this.getAdditionalQuestion();
    // this.disabledBtn();
  }

  disabledBtn() {
    setTimeout(() => {
      var btn = document.getElementById("BtnNext");
      if (btn !== null) {
        btn.disabled = true;
        btn.classList.add("Mui-disabled");
      }
    }, 100);
  }

  handleBack() {
    var btn = document.getElementById("BtnNext");
    btn.disabled = false;
    btn.classList.remove("Mui-disabled");

    var newStep = this.state.step - 1;
    this.setState({ step: newStep });
  }

  render() {
    const {
      biodata_additionalQuestion,
      progressStep,
      step,
      loaded,
    } = this.state;
    const { open, t } = this.props;
    return (
      <Dialog
        className={"dialog-additional"}
        aria-labelledby="customized-dialog-title"
        disableBackdropClick
        open={open}
        scroll={"body"}
        style={{ backgroundColor: "transparent" }}>
        {loaded && (
          <>
            {/* handle disabled button when componentDidMount() doesn't work */}
            {biodata_additionalQuestion[step].responseAnswers.length === 0 &&
            step === 0
              ? this.disabledBtn()
              : null}

            <Grid
              container
              justify="center"
              alignItems="flex-end"
              style={{
                backgroundColor: "transparent",
                width: 550,
                paddingTop: 60,
              }}>
              <div
                style={{
                  position: "absolute",
                  top: 40,
                  marginLeft: 490,
                  width: 30,
                  height: 30,
                  borderRadius: "50%",
                  zIndex: "100",
                }}>
                <Button onClick={() => this.props.onClose()}>
                  <img
                    src={IcnX}
                    width={24}
                    height={24}
                    style={{ position: "relative", left: "5%" }}
                    alt="Close"
                  />
                </Button>
              </div>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                  padding: "32px",
                  backgroundColor: palette.primary,
                  width: "100%",
                  color: "white",
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                }}>
                <Grid item lg={12} md={12} sm={12}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      lineHeight: "23px",
                      textTransform: "none",
                    }}>
                    {t("about:tellUsFirstTime.popup.title")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                  padding: "32px",
                  backgroundColor: "white",
                  width: "100%",
                  color: palette.primary,
                }}>
                <Grid item lg={1} md={1} sm={1}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#B0B7BD",
                      borderRadius: "20px",
                      color: "white",
                      textAlign: "center",
                    }}>
                    {step + 1}
                  </div>
                </Grid>
                <Grid
                  item
                  lg={11}
                  md={11}
                  sm={11}
                  style={{ maxHeight: "150px", overflow: "scroll" }}>
                  {biodata_additionalQuestion[step].categoryQuestion ===
                  "Single" ? (
                    <OptionWorkAbout
                      label={
                        getItem("lang") === "en"
                          ? biodata_additionalQuestion[step].questionTextEn
                          : biodata_additionalQuestion[step].questionTextId
                      }
                      onChange={v => {
                        this.handleChange({ answer: v });
                      }}
                      value={biodata_additionalQuestion[step].responseAnswers}
                      options={biodata_additionalQuestion[step].aboutAnswers}
                    />
                  ) : biodata_additionalQuestion[step].categoryQuestion ===
                    "Multiple" ? (
                    <OptionWorkAbout
                      label={
                        getItem("lang") === "en"
                          ? biodata_additionalQuestion[step].questionTextEn
                          : biodata_additionalQuestion[step].questionTextId
                      }
                      onChange={v => {
                        this.handleChange({ answer: v });
                      }}
                      multiple={true}
                      value={biodata_additionalQuestion[step].responseAnswers}
                      options={biodata_additionalQuestion[step].aboutAnswers}
                    />
                  ) : (
                    <div style={{ width: "100%" }}>
                      <Grid container>
                        <Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: "lighter",
                            color: palette.primary,
                          }}>
                          {getItem("lang") === "en"
                            ? biodata_additionalQuestion[step].questionTextEn
                            : biodata_additionalQuestion[step].questionTextId}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <TextField
                          multiline
                          style={{ width: "90%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={t(
                            "about:tellUsFirstTime.popup.placeholder"
                          )}
                          className="Field"
                          onChange={v =>
                            this.handleChange({ answer: v.target.value })
                          }
                          value={
                            biodata_additionalQuestion[step].responseAnswers
                              .length > 0
                              ? biodata_additionalQuestion[step]
                                  .responseAnswers[0].userAnswer
                              : ""
                          }
                        />
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-between"
              style={{
                height: 70,
                backgroundColor: "white",
                paddingLeft: 20,
                paddingRight: 20,
                borderTop: "1px solid #DFE5F4",
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
              }}>
              <div
                style={{
                  width: "40%",
                }}>
                <LinearProgress
                  style={{
                    marginTop: "17px",
                    width: "100%",
                  }}
                  className={"freetext-progress"}
                  variant="determinate"
                  value={
                    (progressStep / biodata_additionalQuestion.length) * 100
                  }
                />
                <Typography
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    color: "#B0B7BD",
                  }}>
                  {progressStep + "/" + biodata_additionalQuestion.length + " "}
                  {t("about:tellUsFirstTime.popup.progress")}
                </Typography>
              </div>
              {step > 0 && (
                <Button
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #E5E5E5",
                    borderRadius: "4px",
                    fontSize: "14px",
                    color: palette.primary,
                    textTransform: "none",
                    padding: "11px 32px 6px",
                    margin: "9px 0 17px 20px",
                  }}
                  onClick={() => this.handleBack()}>
                  {t("about:tellUsFirstTime.popup.backButton")}
                </Button>
              )}
              <Button
                id={"BtnNext"}
                style={
                  biodata_additionalQuestion[step].responseAnswers.length === 0
                    ? {
                        backgroundColor: "#E5E5E5",
                        border: "1px solid #E5E5E5",
                        borderRadius: "4px",
                        fontSize: "14px",
                        color: "white",
                        textTransform: "none",
                        padding: "11px 32px 6px",
                        marginTop: "9px",
                        marginBottom: "17px",
                      }
                    : {
                        backgroundColor: palette.primary,
                        border: "1px solid #2E69B2",
                        borderRadius: "4px",
                        fontSize: "14px",
                        color: "white",
                        textTransform: "none",
                        padding: "11px 32px 6px",
                        marginTop: "9px",
                        marginBottom: "17px",
                      }
                }
                onClick={
                  step === biodata_additionalQuestion.length - 1
                    ? () => {
                        data.setAdditionalQuestion({
                          additionalQuestions: biodata_additionalQuestion.map(
                            obj => {
                              return {
                                questionId: obj.questionId,
                                responseAnswers: obj.responseAnswers,
                              };
                            }
                          ),
                        });
                        this.props.onOK();
                      }
                    : () => {
                        if (
                          biodata_additionalQuestion[step + 1].responseAnswers
                            .length === 0
                        ) {
                          this.disabledBtn();
                        }
                        var newStep = step;
                        this.setState({ step: newStep + 1 });
                      }
                }>
                {step === biodata_additionalQuestion.length - 1
                  ? t("about:tellUsFirstTime.popup.saveButton")
                  : t("about:tellUsFirstTime.popup.nextButton")}
              </Button>
            </Grid>
          </>
        )}
      </Dialog>
    );
  }
}

DialogueAdditionalQuestion.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  onOK: propTypes.func,
};

DialogueAdditionalQuestion.defaultProps = {
  open: true,
  onOK: () => {
    return true;
  },
  onClose: () => {
    return false;
  },
};

export default withTranslation()(DialogueAdditionalQuestion);
