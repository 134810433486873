import React from "react";
import { withTranslation } from "react-i18next";
import { Typography, Grid, Button } from "@material-ui/core";
import { palette } from "assets/css/main";
import { ArrowForward } from "@material-ui/icons";
import {
  UserSession,
  RequestPost,
  RequestGet,
  setItem,
  clearAll,
  getItem,
  t,
  AUTH_API,
  AppHistory,
} from "app/utils";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  IcnFacebook,
  IcnLinkedin,
  IcnGoogle,
  ImgLoginApplicant,
  IconEyeClose,
  IconEyeOpen,
} from "assets/img";
import LinkedIn from "linkedin-login-for-react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { DialogueInfo, Header, HeaderNologin, Footer } from "app/components";
import MyHelmet from "app/components/header/MyHelmet";
import history from "app/utils/general/history";
import { PushDataLayer } from "app/utils/general/data-layer";
import { isMobile } from "react-device-detect";
class ApplicantLogin extends React.Component {
  params = null;
  constructor(props) {
    super(props);
    this.state = {
      email: process.env.REACT_APP_USER_APPLICANT
        ? process.env.REACT_APP_USER_APPLICANT
        : "",
      password: process.env.REACT_APP_PASS_APPLICANT
        ? process.env.REACT_APP_PASS_APPLICANT
        : "",
      tnc: false,
      accept_tnc: false,
      showPassword: false,
      error: "",
      showForceLogout: false,
      open: false,
      inactiveOpen: false,
      isNotVerified: false,
    };
    this.submitForm = this.submitForm.bind(this);
    this.verifyEmail = this.verifyEmail.bind(this);
    this.callbackLinkedIn = this.callbackLinkedIn.bind(this);
    this.params = new URLSearchParams(this.props.location.search);
  }

  submitForm = ev => {
    ev.preventDefault();
    var form = this.state;
    var data = new URLSearchParams();
    data.append("client_id", process.env.REACT_APP_CLIENT);
    data.append("client_secret", process.env.REACT_APP_SECRET);
    data.append("grant_type", "password");
    data.append("username", form.email);
    data.append("password", form.password);
    if (localStorage.getItem("session_token_")) {
      data.append("session_token", localStorage.getItem("session_token_"));
    }

    RequestPost(AUTH_API + "connect/token", data)
      .then(resLogin => {
        UserSession.setData(resLogin.data);
        localStorage.setItem("session_token_", resLogin.data.session_token);
        RequestGet(AUTH_API + "connect/userinfo")
          .then(res => {
            setItem("HG_CLIENT", process.env.REACT_APP_CLIENT);
            setItem("user_info", JSON.stringify(res.data));
            setItem("GR", res.data.role);
            setItem("user_id", res.data.sub);
            setItem("name", res.data.fullname);
            var dataLayer = {
              eventCategory: "Home Page",
              eventAction: "Login Action",
              eventLabel: "Click",
              loginData: JSON.stringify(),
              userId: res.data.sub,
            };
            if (res.data.role === "Applicant") {
              this.getStepPosition();
              dataLayer.eventAction = "Login Applicant Successfull";
              PushDataLayer(dataLayer);
            } else {
              this.setState({ open: true });
              dataLayer.eventAction = "Login Applicant Failed";
              PushDataLayer(dataLayer);
              clearAll();
            }
          })
          .catch(err => {
            this.setState({ error: err.data.error_description });
          });
      })
      .catch(e => {
        if (e.data.error_description) {
          const error = e.data.error_description;
          if (error.indexOf("Already login in another device") > -1) {
            this.setState({ showForceLogout: true, error: error });
          } else if (error.indexOf("Account is Inactive") > -1) {
            this.setState({ inactiveOpen: true, error: error });
          } else if(error.indexOf("Invalid login attempt. You must have a confirmed email account") > -1){
            this.setState({isNotVerified: true, error: error});
          } else {
            this.setState({
              error: error,
            });
          }
        } else {
          this.setState({
            error: t("error.exception.description"),
          });
        }
      });
  };

  verifyEmail(){
    var form = this.state;
    this.props.history.push("/user/register/verify", form.email);
    setItem("email-register", form.email);
  }

  getStepPosition() {
    if (isMobile) {
      AppHistory.push("/not-supported");
    } else {
      RequestGet("examattempts", {
        userId: getItem("user_id"),
      }).then(r => {
        if (r.data.examCompleted) {
          setItem("EXAM_COMPLETED", true);
          var redir = this.params.get("redir")
            ? this.params.get("redir")
            : "/user/home";
          window.location.href = redir;
        } else {
          setItem("EXAM_COMPLETED", false);
          if (
            r.data.examCategoryid === "df9b82fa-50c2-46cd-8639-2ffa73125200"
          ) {
            this.getBiodata();
          } else {
            this.getBiodata(false);
          }
        }
      });
    }
  }

  getBiodata(first = true) {
    RequestGet("biodata")
      .then(res => {
        var data = res.data;
        if (data.personalInfo) {
          setItem("gender", data.personalInfo.gender);
        }
        this.getBiodataStatus(first);
      })
      .catch(e => {
        console.log("getBiodata()", e);
      });
  }

  getBiodataStatus(first) {
    RequestGet("biodata/status")
      .then(r => {
        const { biodataStatus } = r.data;
        if (biodataStatus === "POA") {
          if (first) {
            window.location.href = "/initialize/pretest";
          } else {
            window.location.href = "/basic-test";
          }
        } else {
          window.location.href = "/welcome";
        }
      })
      .catch(e => {
        console.log("getBiodataStatus()", e);
      });
  }

  callbackLinkedIn(error, code) {
    if (!error) {
      this.socialSuccess(code, "linkedin", "LinkedInAuth");
    }
  }
  socialSuccess(data, clientId, grantType) {
    var d = new URLSearchParams();
    var id_token =
      clientId === "google"
        ? data.tokenId
        : clientId === "facebook"
        ? data.accessToken
        : data;
    d.append("client_id", clientId);
    d.append("client_secret", process.env.REACT_APP_SECRET);
    d.append("grant_type", grantType);
    d.append("id_token", id_token);
    if (clientId === "linkedin") {
      d.append("redirect_url", window.location.href);
    }
    if (localStorage.getItem("session_token_")) {
      d.append("session_token", localStorage.getItem("session_token_"));
    }
    RequestPost(AUTH_API + "connect/token", d)
      .then(resLogin => {
        UserSession.setData(resLogin.data);
        localStorage.setItem("session_token_", resLogin.data.session_token);
        RequestGet(AUTH_API + "connect/userinfo")
          .then(res => {
            setItem("HG_CLIENT", clientId);
            setItem("GR", res.data.role);
            setItem("user_info", JSON.stringify(res.data));
            setItem("user_id", res.data.sub);
            setItem("name", res.data.fullname);

            if (res.data.role === "Applicant") {
              this.getStepPosition();
            } else {
              this.setState({ open: true });
              clearAll();
            }
          })
          .catch(err => {});
      })
      .catch(e => {
        if (e.data.error_description) {
          const error = e.data.error_description;
          if (error.indexOf("Already login in another device") > -1) {
            this.setState({ showForceLogout: true, error: error });
          } else if (error.indexOf("Account is Inactive") > -1) {
            this.setState({ inactiveOpen: true });
          } else {
            this.setState({
              error: error,
            });
          }
        } else {
          this.setState({
            error: t("error.exception.description"),
          });
        }
      });
  }

  socialFailure(data, client) {
    console.log(data, client);
  }

  render() {
    const { t } = this.props;
    const { open, showPassword, showForceLogout, error, inactiveOpen,isNotVerified } =
      this.state;
    if (isMobile) {
      return (
        <div>
          <MyHelmet
            title={t("link.login")}
            description="Hiregrade - Login Candidate"
          />
          <HeaderNologin />
          <DialogueInfo
            width={590}
            onClose={() => this.setState({ inactiveOpen: false })}
            title={t("auth:inactive.applicant.title")}
            content={
              <div
                className="BodyPopup"
                style={{
                  color: palette.primary,
                  width: 280,
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("auth:inactive.applicant.content"),
                }}></div>
            }
            open={inactiveOpen}
          />
          <Grid style={{ minHeight: "100vh" }}>
            <Grid item lg={6} md={6} sm={6}>
              <div
                className="auth-mobile"
                style={{ marginTop: 20, padding: "80px 30px" }}>
                <div style={{ marginBottom: 20, display: "flex" }}>
                  <Button
                    disabled={true}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: "white",
                      marginRight: 10,
                      backgroundColor: palette.primary,
                    }}>
                    {t("link.login")}
                  </Button>
                  <Button
                    onClick={() => {
                      window.location.href = "/user/register/applicant";
                    }}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.signin")}
                  </Button>
                </div>
                <DialogueInfo
                  contentWidth={278}
                  title={t("auth:errorLogin.recruiter.title")}
                  content={t("auth:errorLogin.recruiter.message")}
                  open={open}
                  onClose={() => this.setState({ open: false })}
                />
                <Typography
                  style={{
                    fontWeight: "bolder",
                    color: palette.primary,
                    marginBottom: 40,
                    fontSize: 24,
                  }}>
                  {t("link.login") + " " + t("auth:candidate")}
                </Typography>
                <ValidatorForm onSubmit={this.submitForm}>
                  <TextValidator
                    label={t("auth:email")}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={
                      getItem("lang") === "en"
                        ? t("auth:placeholder.email")
                        : t("auth:placeholder.email_ap")
                    }
                    name="email"
                    value={this.state.email}
                    style={{ width: "100%", marginTop: 20 }}
                    autoComplete="username"
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      t("auth:validation.required"),
                      t("auth:validation.email"),
                    ]}
                    className={"Field"}
                  />
                  <div style={{ position: "relative" }}>
                    <TextValidator
                      label={t("auth:password")}
                      onChange={e => {
                        this.setState({ password: e.target.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={
                        getItem("lang") === "en"
                          ? t("auth:placeholder.password")
                          : t("auth:placeholder.password_ap")
                      }
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className={"Field passwordhint"}
                      value={this.state.password}
                      style={{ width: "100%", marginTop: 20 }}
                      autoComplete={"current-password"}
                      validators={["required"]}
                      errorMessages={[t("auth:validation.required")]}
                    />
                    <img
                      src={showPassword ? IconEyeOpen : IconEyeClose}
                      alt="show hide"
                      onClick={() => {
                        this.setState({ showPassword: !showPassword });
                      }}
                      style={{
                        padding: 5,
                        cursor: "pointer",
                        width: 24.04,
                        position: "absolute",
                        bottom: 22,
                        right: 0,
                      }}
                    />
                  </div>

                  <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    style={{ marginTop: 20 }}>
                    <Button
                      variant="text"
                      style={{ padding: 0 }}
                      onClick={() => {
                        setItem("idRole", "a");
                        this.props.history.push("/user/forgot");
                      }}>
                      <span
                        style={{
                          fontSize: 16,
                          color: palette.primary,
                          textTransform: "capitalize",
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontWeight: "600",
                        }}>
                        {t("auth:forgotPassword")}
                      </span>
                    </Button>
                  </Grid>
                  <Typography
                    style={{ width: "100%", marginTop: 20, color: "red" }}>
                    {error}
                  </Typography>
                  {isNotVerified && (
                    <Typography style={{ width: "100%", marginTop: 20, cursor: "pointer" }} onClick={this.verifyEmail}>Resend Verify Email</Typography>
                  )}
                  {showForceLogout && (
                    <>
                      <Button
                        onClick={() => {
                          history.push("/user/request-logout");
                        }}
                        style={{
                          paddingLeft: 0,
                          marginTop: 20,
                          marginBottom: 24,
                        }}>
                        <span
                          style={{
                            fontSize: 18,
                            fontWeight: "bolder",
                            color: palette.primary,
                            textTransform: "capitalize",
                            marginRight: 5,
                            textDecoration: "underline",
                          }}>
                          {t("auth:forceLogout.link")}
                        </span>
                      </Button>
                      <br />
                    </>
                  )}
                  <Button
                    type="submit"
                    style={{ paddingLeft: 0, marginTop: 20, marginBottom: 24 }}>
                    <span
                      style={{
                        fontSize: 24,
                        fontWeight: "900",
                        color: palette.primary,
                        textTransform: "capitalize",
                        marginRight: 5,
                        textDecoration: "underline",
                      }}>
                      {t("auth:login")}
                    </span>
                    <ArrowForward style={{ color: palette.primary }} />
                  </Button>
                </ValidatorForm>

                <div style={{ alignItems: "center", textAlign: "center" }}>
                  <span
                    style={{
                      fontSize: 14,
                      color: palette.primary,
                      textTransform: "uppercase",
                      fontWeight: "lighter",
                    }}>
                    {t("auth:login-with")} :
                  </span>
                  <div
                    style={{
                      alignItems: "center",
                      textAlign: "center",
                      paddingTop: 10,
                      paddingRight: 15,
                    }}>
                    <LinkedIn
                      className="ButtonLinkedin"
                      clientId={process.env.REACT_APP_LINKEDIN_ID}
                      callback={this.callbackLinkedIn}
                      scope={[
                        "r_emailaddress",
                        "r_liteprofile",
                        "w_member_social",
                      ]}
                      text={
                        <img
                          src={IcnLinkedin}
                          alt="LinkedIn"
                          height={30}
                          style={{ marginLeft: 20 }}
                        />
                      }
                    />

                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_ID}
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={d =>
                        this.socialSuccess(d, "facebook", "FacebookAuth")
                      }
                      render={renderProps => (
                        <img
                          onClick={renderProps.onClick}
                          src={IcnFacebook}
                          alt="Facebook"
                          height={26}
                          style={{ marginLeft: 20, cursor: "pointer" }}
                        />
                      )}
                    />
                    <GoogleLogin
                      className="ButtonGoogle"
                      buttonText={""}
                      icon={false}
                      SameSite={"None"}
                      isSignedIn={false}
                      clientId={process.env.REACT_APP_GOOGLE_KEY}
                      onSuccess={s =>
                        this.socialSuccess(s, "google", "GoogleAuth")
                      }
                      onFailure={x => this.socialFailure(x, "google")}
                      cookiePolicy={""}>
                      <img src={IcnGoogle} alt="Google" width={26} />
                    </GoogleLogin>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid style={{ width: "100%" }}>
              <div style={{ position: "relative", bottom: 0 }}>
                <Footer />
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <MyHelmet
            title={t("link.login")}
            description="Hiregrade - Login Candidate"
          />
          <Header shadow={true} linkgroup={true} />
          <DialogueInfo
            width={590}
            onClose={() => this.setState({ inactiveOpen: false })}
            title={t("auth:inactive.applicant.title")}
            content={
              <div
                className="BodyPopup"
                style={{
                  color: palette.primary,
                  width: 480,
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("auth:inactive.applicant.content"),
                }}></div>
            }
            open={inactiveOpen}
          />
          <Grid container style={{ minHeight: "100vh" }}>
            <Grid item lg={6} md={6} sm={6}>
              <div
                className={"auth"}
                style={{ marginTop: 40, padding: "100px 120px" }}>
                <div style={{ marginBottom: 40 }}>
                  <Button
                    disabled={true}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: "white",
                      marginRight: 10,
                      backgroundColor: palette.primary,
                    }}>
                    {t("link.login")}
                  </Button>
                  <Button
                    onClick={() => {
                      window.location.href = "/user/register/applicant";
                    }}
                    style={{
                      padding: "10px 30px",
                      textTransform: "capitalize",
                      color: palette.primary,
                      marginRight: 10,
                    }}>
                    {t("link.signin")}
                  </Button>
                </div>
                <DialogueInfo
                  contentWidth={378}
                  title={t("auth:errorLogin.recruiter.title")}
                  content={t("auth:errorLogin.recruiter.message")}
                  open={open}
                  onClose={() => this.setState({ open: false })}
                />
                <Typography
                  style={{
                    fontWeight: "bolder",
                    color: palette.primary,
                    fontSize: 32,
                    marginBottom: 40,
                  }}>
                  {t("link.login") + " " + t("auth:candidate")}
                </Typography>
                <ValidatorForm onSubmit={this.submitForm}>
                  <TextValidator
                    label={t("auth:email")}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={
                      getItem("lang") === "en"
                        ? t("auth:placeholder.email")
                        : t("auth:placeholder.email_ap")
                    }
                    name="email"
                    value={this.state.email}
                    style={{ width: "100%", marginTop: 20 }}
                    autoComplete="username"
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      t("auth:validation.required"),
                      t("auth:validation.email"),
                    ]}
                    className={"Field"}
                  />
                  <div style={{ position: "relative" }}>
                    <TextValidator
                      label={t("auth:password")}
                      onChange={e => {
                        this.setState({ password: e.target.value });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={
                        getItem("lang") === "en"
                          ? t("auth:placeholder.password")
                          : t("auth:placeholder.password_ap")
                      }
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className={"Field passwordhint"}
                      value={this.state.password}
                      style={{ width: "100%", marginTop: 20 }}
                      autoComplete={"current-password"}
                      validators={["required"]}
                      errorMessages={[t("auth:validation.required")]}
                    />
                    <img
                      src={showPassword ? IconEyeOpen : IconEyeClose}
                      alt="show hide"
                      onClick={() => {
                        this.setState({ showPassword: !showPassword });
                      }}
                      style={{
                        padding: 10,
                        cursor: "pointer",
                        width: 24.04,
                        position: "absolute",
                        bottom: 22,
                        right: 0,
                      }}
                    />
                  </div>

                  <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    style={{ marginTop: 20 }}>
                    <Button
                      variant="text"
                      style={{ padding: 0 }}
                      onClick={() => {
                        setItem("idRole", "a");
                        this.props.history.push("/user/forgot");
                      }}>
                      <span
                        style={{
                          fontSize: 18,
                          color: palette.primary,
                          textTransform: "capitalize",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}>
                        {t("auth:forgotPassword")}
                      </span>
                    </Button>
                  </Grid>
                  <Typography
                    style={{ width: "100%", marginTop: 20, color: "red" }}>
                    {error}
                  </Typography>
                  {isNotVerified && (
                    <Typography style={{ width: "100%", marginTop: 20, cursor: "pointer" }} onClick={this.verifyEmail}>Resend Verify Email</Typography>
                  )}
                  {showForceLogout && (
                    <>
                      <Button
                        onClick={() => {
                          history.push("/user/request-logout");
                        }}
                        style={{
                          paddingLeft: 0,
                          marginTop: 20,
                          marginBottom: 24,
                        }}>
                        <span
                          style={{
                            fontSize: 18,
                            fontWeight: "bolder",
                            color: palette.primary,
                            textTransform: "capitalize",
                            marginRight: 5,
                            textDecoration: "underline",
                          }}>
                          {t("auth:forceLogout.link")}
                        </span>
                      </Button>
                      <br />
                    </>
                  )}
                  <Button
                    type="submit"
                    style={{ paddingLeft: 0, marginTop: 20, marginBottom: 24 }}>
                    <span
                      style={{
                        fontSize: 24,
                        fontWeight: "bolder",
                        color: palette.primary,
                        textTransform: "capitalize",
                        marginRight: 5,
                        textDecoration: "underline",
                      }}>
                      {t("auth:login")}
                    </span>
                    <ArrowForward style={{ color: palette.primary }} />
                  </Button>
                </ValidatorForm>

                <Grid container alignItems="center">
                  <span
                    style={{
                      fontSize: 18,
                      color: palette.primary,
                      textTransform: "uppercase",
                    }}>
                    {t("auth:login-with")} :
                  </span>
                  <LinkedIn
                    className="ButtonLinkedin"
                    clientId={process.env.REACT_APP_LINKEDIN_ID}
                    callback={this.callbackLinkedIn}
                    scope={[
                      "r_emailaddress",
                      "r_liteprofile",
                      "w_member_social",
                    ]}
                    text={
                      <img
                        src={IcnLinkedin}
                        alt="LinkedIn"
                        height={30}
                        style={{ marginLeft: 20 }}
                      />
                    }
                  />

                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={d =>
                      this.socialSuccess(d, "facebook", "FacebookAuth")
                    }
                    render={renderProps => (
                      <img
                        onClick={renderProps.onClick}
                        src={IcnFacebook}
                        alt="Facebook"
                        height={26}
                        style={{ marginLeft: 20, cursor: "pointer" }}
                      />
                    )}
                  />
                  <GoogleLogin
                    className="ButtonGoogle"
                    buttonText={""}
                    icon={false}
                    SameSite={"None"}
                    isSignedIn={false}
                    clientId={process.env.REACT_APP_GOOGLE_KEY}
                    onSuccess={s =>
                      this.socialSuccess(s, "google", "GoogleAuth")
                    }
                    onFailure={x => this.socialFailure(x, "google")}
                    cookiePolicy={""}>
                    <img src={IcnGoogle} alt="Google" width={26} />
                  </GoogleLogin>
                </Grid>
              </div>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              style={{
                backgroundColor: palette.bg,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                src={ImgLoginApplicant}
                alt="Candidate"
                width={572}
                height={382.78}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation()(ApplicantLogin);
