import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Typography, TextField, Checkbox } from "@material-ui/core";
import { palette } from "assets/css/main";
import data from "./data";
import WizardForm from "app/components/typeform/WizardForm";
import { Options, DatePicker, DialogueInfo } from "app/components";
import "./custom.css";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import SelectSearch from "react-select";

import {
  getItem,
  RequestPost,
  General,
  AppHistory,
  RequestGet,
  findObject,
  findDuplicate,
} from "app/utils";
// import { logError } from "app/utils/general/rollbar";
class WorkExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...data.work_experience,
      industries: [],
      showDuplicate: false,
      showFailed: false,
    };
    data.stepper.footer = false;
    data.work_experience.userId = getItem("user_id");
    General.visible = false;
  }

  handleNext(isLast) {
    data.stepper.footer = isLast;
    data.stepper.nextBtn = isLast;
  }

  handleBack() {
    data.stepper.footer = false;
    data.stepper.nextBtn = false;
  }
  getIndustry() {
    RequestGet("industry", { loading: false })
      .then(r => {
        return r.data.map(v => {
          return {
            key: v.id,
            value: v.id,
            label: getItem("lang") === "en" ? v.industryEng : v.industryIna,
          };
        });
      })
      .then(i => {
        this.setState({ industries: i });
      })
      .catch(e => {
        console.log(e);
      });
  }
  handleChange(obj) {
    this.setState(obj);
    data.setWorkingExperience(obj);
    var e = document.getElementById("error-messages");
    var el = document.getElementsByClassName("MuiInput-underline");
    var label = document.getElementsByClassName("MuiInputLabel-root");
    try {
      e.innerText = "";
      document.getElementById("TFNextBtn").classList.remove("Submit-Btn");
      label[0].classList.remove("Mui-error");
      el[0].classList.remove("Mui-error");
    } catch (error) {}
  }

  handleStartDate() {
    try {
      var date = this.state.start.split("/");
      var d = new Date(date[2] + "/" + date[1] + "/" + date[0]);
      if (d instanceof Date && !isNaN(d)) {
        this.handleChange({ yearStart: d });
      } else {
        this.handleChange({ start: null, yearStart: null });
      }
    } catch (error) {
      this.handleChange({ start: null, yearStart: null });
    }
  }

  handleEndate() {
    try {
      var date = this.state.end.split("/");
      var d = new Date(date[2] + "/" + date[1] + "/" + date[0]);
      if (d instanceof Date && !isNaN(d)) {
        this.handleChange({ yearEnd: d });
      } else {
        this.handleChange({ end: null, yearEnd: null });
      }
    } catch (error) {
      this.handleChange({ end: null, yearEnd: null });
    }
  }

  submit(isSkip = false, init = true) {
    data.personalInfo = null;
    data.listFormalEducation = [];
    data.listFormalEducation = [];
    data.listOrganization = [];
    data.listInformalEducation = [];

    if (init) {
      data.listWorks.push(data.work_experience);
    }

    if (findDuplicate(JSON.parse(JSON.stringify(data.listOrganization)))) {
      this.setState({ showDuplicate: true });
    } else {
      if (isSkip) {
        data.listWorks = null;
      }
      RequestPost("biodata", data)
        .then(() => {
          data.listOrganization = [];
          window.location.reload();
        })
        .catch(e => {
          // logError("work-experience :" + e);
          this.setState({ showFailed: true });
        });
    }
  }

  componentDidMount() {
    this.TFNextBtn = document.getElementById("TFNextBtn");
    this.TFNextBtn.classList.add("Submit-Btn");
    this.getIndustry();
  }

  addWorkingExperience() {
    data.listWorks.push(data.work_experience);
    data.setWorkingExperience({
      status: "",
      name: "",
      field: "",
      role: "",
      yearStart: "",
      yearEnd: null,
      stillWorking: false,
      start: "",
      end: "",
    });
    AppHistory.push("/initialize/biodata/addwork" + data.listWorks.length);
  }

  render() {
    const { t } = this.props;
    const { industries, showDuplicate, showFailed } = this.state;
    const js = t("biodata:options.jobStatus", { returnObjects: "true" });
    const jobStatus = data.listWorks.length > 0 ? js.slice(0, 3) : js;

    return (
      <Grid container style={{ paddingTop: 40, width: "100vw" }}>
        <DialogueInfo
          title={t("biodata:duplicate.title")}
          content={t("biodata:duplicate.content")}
          open={showDuplicate}
          onClose={() => {
            this.setState({ showDuplicate: false }, () => {
              window.location.reload();
            });
          }}
        />
        <DialogueInfo
          title={t("biodata:failed.title")}
          content={t("biodata:failed.content")}
          open={showFailed}
          onClose={() => {
            this.setState({ showFailed: false }, () => {
              this.submit(false, false);
            });
          }}
        />
        <Typography
          style={{
            color: palette.primary,
            padding: "0px 25%",
            marginBottom: 10,
            fontWeight: "bold",
            width: 400,
          }}>
          {t("biodata:step.workexperience")}
        </Typography>
        <WizardForm onSubmit={isSkip => this.submit(isSkip)}>
          <Options
            skipAble={true}
            conditionValue={"4"}
            required={true}
            label={t("biodata:form.field.employmentstatus")}
            onChange={e => {
              this.handleChange({ status: e.value });
            }}
            options={jobStatus}
            value={this.state.status}
          />

          <TextField
            maxLength={150}
            autoFocus={true}
            onChange={e => {
              this.handleChange({ name: e.currentTarget.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={this.state.name}
            required={true}
            placeholder={t("biodata:form.placeholder.companyname")}
            label={t("biodata:form.field.companyname")}
            className="Field"
          />
          <div required={true} value={this.state.field}>
            <label
              style={{
                color: "#2E69B2",
                fontSize: 18,
                fontWeight: "lighter",
                marginBottom: 10,
              }}>
              {t("biodata:form.field.businessarea")}
              <span
                style={{ color: palette.primary }}
                className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                &thinsp;*
              </span>
            </label>
            <SelectSearch
              closeMenuOnSelect
              key={new Date().getTime()}
              className="Select-bio-education"
              classNamePrefix="select"
              placeholder={t("biodata:form.placeholder.businessarea")}
              menuColor={palette.primary}
              name="industries"
              options={industries}
              value={
                this.state.field
                  ? findObject(industries, "value", this.state.field)
                  : ""
              }
              onChange={v => {
                this.handleChange({ field: v.value });
              }}
              style={{ color: palette.primary, fontSize: 32 }}
            />
          </div>
          <TextField
            autoFocus={true}
            onChange={e => {
              this.handleChange({ role: e.currentTarget.value });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={this.state.role}
            maxLength={50}
            required={true}
            placeholder={t("biodata:form.placeholder.position")}
            label={t("biodata:form.field.position")}
            className="Field"
          />
          <DatePicker
            autoFocus={true}
            onChange={e => {
              this.handleChange({ yearStart: e });
            }}
            value={this.state.yearStart}
            maxDate={new Date()}
            label={t("biodata:form.field.startdate")}
            format="dd/M/yyyy"
            required={true}
          />
          <div required={!this.state.stillWorking} value={this.state.yearEnd}>
            <DatePicker
              required={!this.state.stillWorking}
              onChange={e => {
                this.handleChange({ yearEnd: e, stillWorking: false });
              }}
              value={this.state.yearEnd}
              maxDate={new Date()}
              minDate={this.state.yearStart}
              label={t("biodata:form.field.enddate")}
              format={"dd/M/yyyy"}
            />
            <div
              id="custom-error"
              style={{ color: palette.danger, paddingBottom: 20 }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <Checkbox
                className="show-button"
                color="primary"
                checked={this.state.stillWorking}
                icon={<CheckBoxOutlineBlank fontSize="large" color="primary" />}
                checkedIcon={<CheckBox fontSize="large" />}
                name="checkedI"
                onClick={() => {
                  this.handleChange({
                    stillWorking: !this.state.stillWorking,
                  });

                  if (!this.state.stillWorking) {
                    this.handleChange({ yearEnd: null });
                  }
                }}
              />
              <Typography
                style={{
                  color: palette.primary,
                  textTransform: "uppercase",
                  marginLeft: 10,
                }}>
                Current
              </Typography>
            </div>
            <div
              onClick={() => this.addWorkingExperience()}
              style={{
                color: palette.primary,
                textDecoration: "underline",
                textTransform: "uppercase",
                marginTop: 150,
                position: "absolute",
                cursor: "pointer",
              }}>
              {t("biodata:addWorkExperience")}
            </div>
          </div>
        </WizardForm>
      </Grid>
    );
  }
}

export default withTranslation()(WorkExperience);
