import React from "react";
import { palette, COLOR } from "assets/css/main";
import { getItem, RequestPost } from "app/utils";
import { withTranslation } from "react-i18next";
import { IcnInfoFull } from "assets/img";
import { ProfilePicture } from "app/components";

import data from "app/pages/applicant/initialize/bio-data/data";

export default withTranslation()(
  class ConfirmationSidePanel extends React.Component {
    state = {
      ...JSON.parse(getItem("user_info")),
      anchorEl: null,
      percentage: 0,
    };
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }

    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    handleUploadFile(file) {
      const {personalInfo} = this.props.data;

      var form = new FormData();
      form.append(
        "file",
        file.file,
        getItem("user_id") + "-profile." + file.extension
      );
      RequestPost("biodata/upload", form)
        .then(r => {
          const { imageUrl } = r.data;
          var personal = personalInfo;
          personal.photo = imageUrl;
          data.personal_data.photo = imageUrl;

          const tempArray = this.props.data;
          tempArray.personalInfo = personal;
          this.props.updateData(tempArray)
        })
        .catch(e => {
          console.log("handleUploadImage()", e);
        });
    }

    render() {
      const {personalInfo} = this.props.data;

      const { t } = this.props;
      return (
        <div
          style={{
            padding: 20,
            fontWeight: "lighter",
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 0px 13px rgba(0, 0, 0, 0.09)",
          }}>
          <div
            style={{display: "flex", flexDirection: "row"}}
          >
            <div
              style={{
                backgroundColor: "transparent",
                width: 165,
                height: 165,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                border: "1px solid " + palette.primary,
              }}>
              <ProfilePicture
                edit={true}
                value={personalInfo.photo}
                onSelectFile={file => {
                  this.handleUploadFile(file);
                }}
              />
            </div>
            <p
              style={{
                textAlign: "right",
                fontSize: 24,
                color: COLOR.red,
                fontWeight: "bolder",
              }}>
              *
            </p>
          </div>
          
          <div
            style={{
              display: personalInfo.name ? "" : "none",
              borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
              width: "100%",
            }}>
            <p
              style={{
                textAlign: "center",
                fontSize: 24,
                marginBottom: 15,
                color: palette.primary,
                fontWeight: "bolder",
              }}>
              {personalInfo.name}
              <br />
            </p>
          </div>
          <div style={{ marginTop: "8%", width: "100%", display: "flex" }}>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  position: "relative",
                  height: 70,
                  color: palette.primary,
                  fontSize: 14,
                }}>
                <img
                  src={IcnInfoFull}
                  alt="info"
                  style={{ marginRight: 10, width: 24 }}
                />
                {t("biodata:confirmation.reconfirm")}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
