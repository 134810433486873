import React from "react";
import DatePicker from "react-date-picker";
import "./custom.css";
import propTypes from "prop-types";

class DatePickers extends React.Component {
  render() {
    const {
      label,
      value,
      format,
      dayPlaceholder,
      monthPlaceholder,
      yearPlaceholder,
      maxDate,
      minDate,
      maxDetail,
      minDetail,
      autoFocus,
      style,
      className,
      required,
    } = this.props;
    return (
      <div className={className + " custom-date"} style={style}>
        <label style={{}}>{label}</label>
        <DatePicker
          required={required ? required : false}
          autoFocus={autoFocus}
          maxDate={maxDate}
          minDate={minDate}
          maxDetail={maxDetail}
          minDetail={minDetail}
          dayPlaceholder={dayPlaceholder}
          monthPlaceholder={monthPlaceholder}
          yearPlaceholder={yearPlaceholder}
          format={format}
          onChange={val => {
            this.props.onChange(val);
          }}
          value={
            value instanceof Date
              ? value
              : value.indexOf("T") > 0 && value.indexOf("Z") < 0
              ? new Date(value + ".000Z")
              : ""
          }
          className={className + " Field"}
          label="s"
          clearIcon={null}
          calendarIcon={null}
        />
      </div>
    );
  }
}
DatePickers.propTypes = {
  value: propTypes.any,
  label: propTypes.any,
  format: propTypes.string,
  minDetail: propTypes.string,
  maxDetail: propTypes.string,
  dayPlaceholder: propTypes.string,
  yearPlaceholder: propTypes.string,
  monthPlaceholder: propTypes.string,
  onChange: propTypes.func,
};
DatePickers.defaultProps = {
  autoFocus: false,
  maxDate: null,
  minDate: null,
  minDetail: "century",
  maxDetail: "month",
  label: "Date",
  format: "yyyy/MM/dd",
  dayPlaceholder: null,
  yearPlaceholder: null,
  monthPlaceholder: null,
  onChange: () => {},
};
export default DatePickers;
