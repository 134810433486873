import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import {
  AppHistory,
  findObject,
  getItem,
  GetLocalItem,
  RequestGet,
  SetLocalItem,
} from "app/utils";
import alertData from "app/components/alert/alert-data";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ButtonFilterStatus } from "app/components";
import moment from "moment";
class CandidatePreview extends React.Component {
  state = {
    data: {
      personalInfo: { firstName: "", id: "" },
      listWorks: [],
      listFormalEducation: [],
      listCandidateStatus: [],
    },
  };

  componentDidMount() {
    var data = GetLocalItem("BIODATA_CANDIDATE");
    if (data) {
      if (data.id !== this.props.id) {
        this.getBiodataCandidate();
      } else {
        this.setState({ data: data.data });
        this.props.onRender(data.data);
      }
    } else {
      this.getBiodataCandidate();
    }
  }
  getBiodataCandidate() {
    const { t } = this.props;
    RequestGet("candidate/" + this.props.id)
      .then(res => {
        res.data.listWorks.sort(function (a, b) {
          return b.stillWorking - a.stillWorking;
        });
        res.data.listFormalEducation.sort(function (a, b) {
          return b.yearGraduate - a.yearGraduate;
        });
        return res.data;
      })
      .then(data => {
        if (data.personalInfo) {
          var tmp = { id: this.props.id, data: data };
          SetLocalItem("BIODATA_CANDIDATE", tmp, 1);
          this.setState({ data: data });
          this.props.onRender(data);
        } else {
          alertData.show(t("error.exception.description"), "error");
          AppHistory.goBack();
        }
      })
      .catch(e => {
        console.log("getBiodataCandidate()", e);
      });
  }
  render() {
    const {
      data: { personalInfo, listFormalEducation, listCandidateStatus },
    } = this.state;
    const { t } = this.props;
    const maritalStatus = t("biodata:options.maritalStatus", {
      returnObjects: true,
    });
    return (
      <>
        <div
          style={{
            backgroundColor: palette.secondary,
            display: "flex",
            flexDirection: "row",
            width: "100%",
            minHeight: 150,
            paddingTop: 20,
          }}>
          <div
            style={{
              width: "20%",
              paddingRight: 50,
              boxSizing: "border-box",
            }}>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <div
                style={{
                  width: 122,
                  height: 122,
                  borderRadius: 61,
                  backgroundColor: palette.lightGrey,
                  backgroundImage: "url(" + personalInfo.photo + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}></div>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  backgroundColor: palette.primary,
                  color: "white",
                  width: 41,
                  height: 41,
                  borderRadius: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                  bottom: 0,
                }}>
                10%
              </div>
            </div>
          </div>
          <div
            style={{
              width: "60%",
              boxSizing: "border-box",
              padding: "10px 20px",
              color: "white",
              display: "flex",
              flexDirection: "column",
            }}>
            <span>
              <Button
                onClick={() => {
                  if (!this.props.candidate) {
                    AppHistory.push(
                      "/recruiter/viewcandidatelist/" +
                        GetLocalItem("VacancyId"),
                      {
                        status: AppHistory.location.state
                          ? AppHistory.location.state.status
                          : "LIS",
                      }
                    );
                  } else {
                    AppHistory.push("/recruiter/candidates", {
                      status: AppHistory.location.state
                        ? AppHistory.location.state.status
                        : "ALL",
                    });
                  }
                }}
                style={{
                  fontSize: 12,
                  color: "white",
                  padding: 0,
                  textDecoration: "underline",
                  textTransform: "none",
                }}>
                <ArrowBackIcon
                  style={{ fontSize: 12, textDecoration: "underline" }}
                />
                {t("candidates:candidateInfo.btnBack")}
              </Button>
            </span>
            <span style={{ fontWeight: "bold", fontSize: 24, paddingTop: 5 }}>
              {personalInfo.firstName.split("")[0]
                ? personalInfo.firstName.split("")[0] +
                  personalInfo.id.split("-")[0].toUpperCase()
                : "..."}
            </span>
            {listFormalEducation.length > 0 && (
              <>
                <span style={{ fontSize: 18, paddingTop: 5 }}>
                  {listFormalEducation[0].degree +
                    " " +
                    listFormalEducation[0].major +
                    " | " +
                    listFormalEducation[0].institution}
                </span>
                <span style={{ fontSize: 14, paddingTop: 5 }}>
                  {t("candidates:candidateInfo.personal") +
                    " : " +
                    personalInfo.birthPlace +
                    ", " +
                    moment(personalInfo.birthDate).format("DD MMMM YYYY") +
                    " / " +
                    findObject(
                      maritalStatus,
                      "value",
                      personalInfo.maritalStatus,
                      false
                    ).label}
                </span>
                <span style={{ fontSize: 14, paddingTop: 5 }}>
                  {t("candidates:candidateInfo.domicileCity") +
                    " : " +
                    personalInfo.cityName}
                </span>
                <span
                  style={{
                    fontSize: 14,
                    paddingTop: 5,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  {t("candidates:candidateInfo.status") + " : "}
                  {listCandidateStatus.map((r, i) => {
                    if (r.count > 0) {
                      return (
                        <div key={i}>
                          <ButtonFilterStatus
                            className={"Status"}
                            disabled={true}
                            onClick={() => {}}
                            style={{
                              height: "auto",
                              padding: "2px 15px",
                              color: "#FFB031",
                              border: "none",
                              backgroundColor: "#fee7c0",
                              fontWeight: "normal",
                              textTransform: "lowercase",
                              fontSize: 12,
                              opacity: 3,
                              margin: 5,
                            }}
                            index={1}
                            active={1}
                            text={
                              getItem("lang") === "en"
                                ? r.count + " " + r.textEng
                                : r.count + " " + r.textIna
                            }
                          />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </span>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
CandidatePreview.defaultProps = {
  onRender: () => {},
};
export default withTranslation()(CandidatePreview);
