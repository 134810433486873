import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { palette } from "assets/css/main";
import { IcnSpeakerConfirmation } from "assets/img";
import propTypes from "prop-types";
import { NextBtn } from "../general/ButtonNext";
import DialogueFull from "./full";

/**
 * @extends {React.Component<{title:propTypes.string, content:propTypes.string,contentDetail:ropTypes.string, open:propTypes.bool, onClose:propTypes.func, btnText:propTypes.string}>}
 */
class DialogueInfo extends React.Component {
  render() {
    const {
      open,
      title,
      content,
      btnText,
      contentWidth,
      contentDetail,
      width,
    } = this.props;
    return (
      <DialogueFull
        open={open}
        hideClose={true}
        style={{ width: width ? width : 500 }}>
        <Grid
          container
          justify="center"
          alignItems="flex-end"
          style={{
            backgroundColor: "transparent",
            width: width ? width : 500,
            paddingTop: 35,
          }}>
          <div
            style={{
              position: "absolute",
              top: 0,
              width: 140,
              height: 140,
              borderRadius: "50%",
            }}>
            <img
              src={IcnSpeakerConfirmation}
              width={129}
              height={129}
              style={{ position: "relative", left: "5%" }}
              alt="Info"
            />
          </div>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              paddingTop: 100,
              backgroundColor: "white",
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
              fontWeight: "bold",
              fontSize: 24,
              color: palette.primary,
              width: "100%",
            }}>
            <div style={{ marginBottom: 10 }}>{title}</div>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          direction="row"
          alignItems="flex-start"
          style={{
            backgroundColor: "#ffff",
            padding: 20,
            width: width ? width : 500,
          }}>
          {typeof content === "string" ? (
            <Typography
              style={{
                color: palette.primary,
                marginBottom: contentDetail ? 20 : 40,
                textAlign: "center",
                width: contentWidth ? contentWidth : 250,
              }}>
              {content}
            </Typography>
          ) : (
            content
          )}
          {contentDetail && (
            <Typography
              style={{
                color: palette.primary,
                marginBottom: 40,
                textAlign: "center",
                width: contentWidth ? contentWidth : 250,
              }}>
              {contentDetail}
            </Typography>
          )}
        </Grid>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{
            backgroundColor: palette.primary,
            borderBottomLeftRadius: 15,
            borderBottomRightRadius: 15,
            height: 100,
          }}>
          <NextBtn
            color="white"
            text={btnText}
            onClick={() => this.props.onClose()}
          />
        </Grid>
      </DialogueFull>
    );
  }
}

DialogueInfo.propTypes = {
  title: propTypes.string,
  content: propTypes.any,
  open: propTypes.bool,
  onClose: propTypes.func,
  btnText: propTypes.string,
};

DialogueInfo.defaultProps = {
  btnText: "OK",
  title: "Sample title",
  content: "Success",
  open: true,
  onClose: () => {
    return false;
  },
};

export default DialogueInfo;
