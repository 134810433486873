import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import RecruiterFooter from "app/components/footer/RecruiterFooter";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { Grid } from "@material-ui/core";
import data from "./data";
import { ConfirmationStep } from "app/components";
import VacancyDetailEdit from "./edit/vacancy-detail-edit";
import JobRequirementEdit from "./edit/job-requirement-edit";
import SoftSkillEdit from "./edit/soft-skill-edit";
import {
  RequestGet,
  RequestPut,
  getItem,
  AppHistory,
  GetLocalItem,
  findObject,
} from "app/utils";

class EditVacancy extends React.Component {
  constructor(props) {
    super(props);
    if (GetLocalItem("vacancyGet")) {
      data.listVacancy = GetLocalItem("vacancyGet");
    }

    this.state = {
      ...data.listVacancy,
      ...data.vacancyPost,
      thinkingSkill: [],
      workingAttitude: [],
      relationshipSkill: [],
      confirmationStep: "VacancyDetail",
      loaded: false,
      vacancyId: null,
      maxError: false,
    };
    this.translate = this.props.t;
  }

  putVacancy(obj, nextPage, vacancyId) {
    var url = "vacancy/" + vacancyId;
    // console.log(vacancyId);
    //console.log(JSON.stringify(obj));
    RequestPut(url, obj)
      .then(res => {
        AppHistory.push(nextPage);
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleVacancy(obj) {
    var newVacancy = data.vacancy_post.vacancy;
    newVacancy = { ...newVacancy, ...obj };
    this.setState({ vacancy: newVacancy });
    data.setVacancyPost({ vacancy: newVacancy });
  }

  handleRequirement(obj) {
    var newReq = data.vacancy_post.vacancyRequirement;
    newReq = { ...newReq, ...obj };
    this.setState({ vacancyRequirement: newReq });
    data.setVacancyPost({ vacancyRequirement: newReq });
  }

  getSoftSkill() {
    RequestGet("softskill")
      .then(res => {
        this.setState({ soft_skill_api: res.data });
        data.soft_skill_api = res.data;
      })
      .catch(er => {
        console.log(er);
      });
  }

  getVacancy(vacancyId) {
    RequestGet("vacancy/" + vacancyId)
      .then(res => {
        var ot = res.data.jobFamilies.find(p => p.jobFamilyEng === "Other").id;
        var otWorkEnv = res.data.environmentWork.find(
          p => p.workEnvironmentEng === "Others"
        ).id;
        var otPerform = res.data.jobPerform.find(
          p => p.jobPerformEng === "Others"
        ).id;
        var otBenefit = res.data.benefits.find(p => p.benefit === "Others").id;
        this.setState({ other: ot });
        this.setState({ listVacancy: res.data });
        data.listVacancy = res.data;
        this.handleVacancy({
          companyProfileId: JSON.parse(getItem("company_info")).id,
        });

        // add current vacancies
        this.handleVacancy({ 
          vacancyType: res.data.vacancy.vacancyType,
          jobFamily: res.data.vacancy.jobFamily,
          subJobFamily: res.data.vacancy.subJobFamily,
          jobTitle: res.data.vacancy.jobTitle,
          vacancyNumber: res.data.vacancy.vacancyNumber,
          salaryMin: res.data.vacancy.salaryMin,
          salaryMax: res.data.vacancy.salaryMax,
          otherValue: res.data.vacancy.otherValue,
          minEducation: res.data.vacancy.minEducation,
          minGpa: res.data.vacancy.minGpa,
        });

        this.handleRequirement({
          languageSkill: res.data.listVacancyRequirement.languageSkill,
          certificate: res.data.listVacancyRequirement.certificate,
          hasCertificate: res.data.listVacancyRequirement.hasCertificate,
        });

        var newUniv = 
          res.data.listVacancyUniversity.length > 0
            ? res.data.listVacancyUniversity.map(v => {
              return v.universityId
            })
            : [];
        this.setState({ vacancyUniversitys: newUniv });
        data.setVacancyPost({ vacancyUniversitys: newUniv });

        var newMajor = 
            res.data.listVacancyMajorAlias.length > 0
              ? res.data.listVacancyMajorAlias.map(v => {
                return v.aliasId
              })
              : [];
        this.setState({ vacancyMajorAliases: newMajor });
        data.setVacancyPost({ vacancyMajorAliases: newMajor });

        var newBenefits =
          res.data.listVacancyBenefit.length > 0
            ? res.data.listVacancyBenefit.map(v => {
                return v.benefit;
              })
            : [];
        this.setState({ vacancyBenefits: newBenefits });
        data.setVacancyPost({ vacancyBenefits: newBenefits });

        if (findObject(res.data.listVacancyBenefit, "benefit", otBenefit)) {
          var otherBenefit = res.data.listVacancyBenefit.find(
            p => p.benefit === otBenefit
          );
          var newOtherBenefits = otherBenefit.otherBenefit.map(v => {
            return v;
          });
          this.setState({ otherBenefits: newOtherBenefits });
          data.setVacancyPost({ otherBenefits: newOtherBenefits });
        }

        var newWorkLocations =
          res.data.listVacancyWorkLocation.length > 0
            ? res.data.listVacancyWorkLocation.map(v => {
                return v.workLocation;
              })
            : [];
        this.setState({ vacancyWorkLocation: newWorkLocations });
        data.setVacancyPost({ vacancyWorkLocation: newWorkLocations });

        var newJobDesc =
          res.data.listVacancyJobDesc.length > 0
            ? res.data.listVacancyJobDesc.map(v => {
                return v.jobDescription;
              })
            : [""];
        this.setState({ vacancyJobDesc: newJobDesc });
        data.setVacancyPost({ vacancyJobDesc: newJobDesc });

        var newCertificates =
          res.data.listVacancyRequirementCertificates.length > 0
            ? res.data.listVacancyRequirementCertificates.map(v => {
                return v.specificCertificate;
              })
            : [""];
        this.setState({ vacancyRequirementCertificate: newCertificates });
        data.setVacancyPost({ vacancyRequirementCertificate: newCertificates });

        var newWorkEnvs =
          res.data.listVacancyRequirementEnvironmentWork.length > 0
            ? res.data.listVacancyRequirementEnvironmentWork.map(v => {
                return v.environmentWorkId;
              })
            : [];
        this.setState({ vacancyRequirementEnvironmentWork: newWorkEnvs });
        data.setVacancyPost({ vacancyRequirementEnvironmentWork: newWorkEnvs });

        if (
          findObject(
            res.data.listVacancyRequirementEnvironmentWork,
            "environmentWorkId",
            otWorkEnv
          )
        ) {
          var otherWorkEnv = res.data.listVacancyRequirementEnvironmentWork.find(
            p => p.environmentWorkId === otWorkEnv
          );
          var newOtherWorkEnvs = otherWorkEnv.otherEnvironment.map(v => {
            return v;
          });
          this.setState({ otherEnvironments: newOtherWorkEnvs });
          data.setVacancyPost({ otherEnvironments: newOtherWorkEnvs });
        }

        var newJobPerforms =
          res.data.listVacancyRequirementJobPerform.length > 0
            ? res.data.listVacancyRequirementJobPerform.map(v => {
                return v.jobPerformId;
              })
            : [];
        this.setState({ vacancyRequirementJobPerform: newJobPerforms });
        data.setVacancyPost({ vacancyRequirementJobPerform: newJobPerforms });

        if (
          findObject(
            res.data.listVacancyRequirementJobPerform,
            "jobPerformId",
            otPerform
          )
        ) {
          var otherPerform = res.data.listVacancyRequirementJobPerform.find(
            p => p.jobPerformId === otPerform
          );
          var newOtherPerforms = otherPerform.otherJobPerform.map(v => {
            return v;
          });
          this.setState({ otherJobPerform: newOtherPerforms });
          data.setVacancyPost({ otherJobPerform: newOtherPerforms });
        }

        var newSoftSkills =
          res.data.listVacancySoftskill.length > 0
            ? res.data.listVacancySoftskill.map(v => {
                return {
                  softskillHeaderId: v.softskillHeaderId,
                  softskillDetailId: v.softskillDetailId,
                };
              })
            : [
                {
                  softskillHeaderId: "",
                  softskillDetailId: "",
                },
              ];
        this.setState({ vacancySoftskills: newSoftSkills });
        data.setVacancyPost({ vacancySoftskills: newSoftSkills });
      })
      .then(() => {
        this.setState({ loaded: true });
      })
      .catch(err => {
        console.log("Gagal GET");
        console.log(err);
      });
  }

  componentDidMount() {
    // lock vacancyId
    this.setState({ vacancyId: this.state.vacancy.id });

    this.getSoftSkill();
    this.getVacancy(this.state.vacancy.id);
  }
  render() {
    const { t } = this.props;
    const { confirmationStep, loaded, vacancyId, maxError } = this.state;
    return (
      <div
        className="RecruiterVacancy font-hurme"
        style={{
          position: "relative",
        }}>
        <MyHelmet title="Dashboard" />
        <RecruiterAppBar
          shadow={true}
          menu="dashboard"
          history={this.props.history}
        />
        <Grid container style={{ minHeight: "100vh" }}>
          <Grid item lg={3} md={3} sm={3}>
            <ConfirmationStep
              edit={true}
              data={confirmationStep}
              onChange={v => {
                this.setState({ confirmationStep: v });
              }}
            />
          </Grid>
          {loaded === true ? (
            <Grid item lg={9} md={9} sm={9}>
              {confirmationStep === "VacancyDetail" ? (
                <VacancyDetailEdit
                  validation={maxError}
                  onValidation={v => {
                    this.setState({ maxError: v });
                  }}
                />
              ) : confirmationStep === "JobRequirement" ? (
                <JobRequirementEdit />
              ) : (
                <SoftSkillEdit />
              )}
            </Grid>
          ) : null}
          <RecruiterFooter
            style={{ width: "100%" }}
            history={this.props.history}
            // nextTo="/recruiter/home"
            backTo="/recruiter/home"
            backLabel={t("vacancy:edit.footer.back")}
            nextLabel={t("vacancy:edit.footer.save")}
            nextHref={true}
            action={true}
            onFinish={() => {
              if (maxError === false) {
                this.putVacancy(
                  data.vacancy_post,
                  "/recruiter/home",
                  vacancyId
                );
              }
            }}
          />
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(EditVacancy);
