import React from "react";
import { Button } from "@material-ui/core";
import { palette } from "assets/css/main";

const filterButton = {
  color: palette.primary,
  fontSize: 14,
  height: 33,
  padding: "0px 15px",
  textTransform: "capitalize",
  background: "rgba(46, 105, 178, 0.2)",
  border: "1px solid #2E69B2",
  borderRadius: 20,
  marginRight: 15,
};
const background = (state, status) => {
  return state === status ? "rgba(46, 105, 178, 0.2)" : "";
};
const opacity = (state, status) => {
  return state === status ? 1 : 0.5;
};

export default class ButtonFilterStatus extends React.Component {
  state = {
    status: this.props.index === 1 ? 1 : 0,
    anchorAction: null,
  };

  render() {
    const {
      active,
      index,
      text,
      onClick,
      style,
      disabled,
      className,
      status,
    } = this.props;
    return (
      <Button
        className={className}
        disabled={disabled}
        onClick={() => {
          onClick(index);
          this.setState({ status: index });
        }}
        style={{
          ...filterButton,
          opacity: opacity(status, active),
          backgroundColor: background(status, active),
          ...style,
        }}>
        {text}
      </Button>
    );
  }
}
