import React from "react";
import Catch from "./errorTracking";
import { loadImage } from "app/utils";
import { Grid, Typography, Button } from "@material-ui/core";
import { PRIMARY_BUTTON } from "assets/css/main";
import { withTranslation } from "react-i18next";
export const ErrorBoundary = Catch((props, error) => {
  const { t } = props;
  const node = error ? (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      style={{ paddingTop: 50 }}>
      <img src={loadImage("logo.png")} alt="Hiregrade" width={200} />
      <Typography variant="h3" style={{ marginBottom: 20 }}>
        {t("error.exception.title")}
      </Typography>
      <Typography variant="h5" style={{ marginBottom: 50 }}>
        {t("error.exception.description")}
      </Typography>
      <Button
        variant="contained"
        style={{ ...PRIMARY_BUTTON }}
        onClick={() => {
          window.location.href = "/";
        }}>
        {t("error.button.home")}
      </Button>
    </Grid>
  ) : (
    <>{props.children}</>
  );
  return node;
});
ErrorBoundary.propsTypes = {
  children: React.ReactNode,
};
export default withTranslation()(ErrorBoundary);
