import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

class CustomDatePicker extends React.Component {
  valid(current) {
    return current.day() !== 0 && current.day() !== 6;
  }
  render() {
    return (
      <Datetime
        placholder={"sss"}
        className="custom-time"
        dateFormat={"ddd, MM-yyyy"}
        timeFormat={false}
        isValidDate={e => this.valid(e)}
        onChange={x => {
          console.log(x);
        }}
      />
    );
  }
}
class CustomTimePicker extends React.Component {
  valid(current) {
    return current.day() !== 0 && current.day() !== 6;
  }
  render() {
    const { value, onChange, disabled } = this.props;
    return (
      <>
        {disabled ? (
          <Datetime
            open={false}
            value={value}
            placholder={"sss"}
            className="custom-time"
            dateFormat={false}
            timeFormat={"HH:mm"}
            isValidDate={e => this.valid(e)}
            onChange={x => {
              onChange(x);
            }}
          />
        ) : (
          <Datetime
            value={value}
            placholder={"sss"}
            className="custom-time"
            dateFormat={false}
            timeFormat={"HH:mm"}
            isValidDate={e => this.valid(e)}
            onChange={x => {
              onChange(x);
            }}
          />
        )}
      </>
    );
  }
}

export { CustomDatePicker, CustomTimePicker };
