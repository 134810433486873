import { Grid } from "@material-ui/core";
import { palette } from "assets/css/main";
import propTypes from "prop-types";

import React from "react";
import { NextBtn } from "../general/ButtonNext";
import ButtonPrev from "../general/ButtonPrev";
class FormFooter extends React.Component {
  render() {
    const {
      nextText,
      prevText,
      OnClickNext,
      OnClickBack,
      nextArrow,
      backArrow,
      fontSize,
    } = this.props;
    return (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{
          zIndex: 100,
          height: 60,
          position: "fixed",
          bottom: 0,
          backgroundColor: palette.primary,
          padding: "0px 50px",
        }}>
        {prevText ? (
          <>
            {backArrow ? (
              <ButtonPrev
                fontSize={fontSize}
                color="white"
                text={prevText}
                onClick={() => OnClickBack()}
              />
            ) : (
              <div
                onClick={() => {
                  OnClickBack();
                }}
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: fontSize,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}>
                {prevText}
              </div>
            )}
          </>
        ) : (
          ""
        )}
        {nextArrow ? (
          <NextBtn
            color="white"
            text={nextText}
            onClick={() => OnClickNext()}
          />
        ) : (
          <div
            onClick={() => {
              OnClickNext();
            }}
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: fontSize,
              cursor: "pointer",
              textDecoration: "underline",
            }}>
            {nextText}
          </div>
        )}
      </Grid>
    );
  }
}
FormFooter.propTypes = {
  prevText: propTypes.string,
  nextText: propTypes.string,
  OnClickNext: propTypes.func,
  OnClickBack: propTypes.func,
  nextArrow: propTypes.bool,
  backArrow: propTypes.bool,
  fontSize: propTypes.number,
};
FormFooter.defaultProps = {
  prevText: null,
  nextText: "Next",
  OnClickNext: () => {
    console.log("props OnClickNext");
  },
  OnClickBack: () => {
    console.log("props OnClickBack");
  },
  nextArrow: true,
  backArrow: true,
  fontSize: 24,
};
export default FormFooter;
