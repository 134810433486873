import { getItem, clearAll, setItem } from "./Storage";
import { AppHistory, General } from "..";
import i18next from "i18next";
import alertData from "app/components/alert/alert-data";
// import { logInfo } from "./rollbar";
const axios = require("axios").default;
export const MAIN_API = process.env.REACT_APP_API;
export const AUTH_API = process.env.REACT_APP_AUTHAPI;
// setItem("GR", "Recruiter");
axios.interceptors.request.use(
  function (config) {
    var isLoading = true;
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
      isLoading =
        config.data.get("loading") !== undefined
          ? config.data.get("loading") === "true"
          : true;
    } else if (config.data instanceof URLSearchParams) {
      isLoading = config.data.get("loading") != null ? false : true;
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    } else {
      if (config.data) {
        isLoading =
          config.data.loading === "false" || config.data.loading === false
            ? false
            : true;
      }
      config.headers["Content-Type"] = "application/json";
    }
    if (getItem("access_token")) {
      config.headers.Authorization = "Bearer " + getItem("access_token");
    }
    if (isLoading) {
      General.loading = true;
    }
    return config;
  },
  function (error) {
    General.loading = false;
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (res) {
    General.loading = false;
    return res;
  },
  function (er) {
    var dataRes;
    General.loading = false;
    const originalRequest = er.config;
    var code = er.response ? er.response.status : 500;
    if (code === 401 && getItem("refresh_token")) {
      var data = new URLSearchParams();
      var user = JSON.parse(getItem("user_info"));

      data.append("client_id", getItem("HG_CLIENT"));
      data.append("client_secret", process.env.REACT_APP_SECRET);
      data.append("grant_type", "refresh_token");
      data.append("refresh_token", getItem("refresh_token"));
      if (getItem("GR") === "Applicant") {
        data.append("session_token", localStorage.getItem("session_token_"));
      } else {
        data.append("session_token", localStorage.getItem("session_token__"));
      }
      data.append("username", user.email);

      return axios
        .post(AUTH_API + "connect/token", data)
        .then(res => {
          setItem("access_token", res.data.access_token);
          setItem("refresh_token", res.data.refresh_token);
          localStorage.setItem("session_token", res.data.session_token);
          return axios(originalRequest);
        })
        .catch(() => {
          var gr = getItem("GR").toLocaleLowerCase();

          // logInfo("Redirect Login", {
          //   code: 401,
          //   type: "refresh_token",
          //   role: gr,
          //   request: originalRequest,
          // });
          // setTimeout(() => {
          //   clearAll();
          //   return (window.location.href =
          //     "/user/login/" + gr + "?redir=" + AppHistory.location.pathname);
          // }, 2000);
        });
    }
    if (code === 401 && !getItem("access_token")) {
      var gr = getItem("GR").toLocaleLowerCase();

      // logInfo("Redirect Login", {
      //   code: 401,
      //   type: "no_token",
      //   role: gr,
      //   request: originalRequest,
      // });
      setTimeout(() => {
        clearAll();
        return (window.location.href =
          "/user/login/" + gr + "?redir=" + AppHistory.location.pathname);
      }, 2000);
    }
    if (code !== 401) {
      if (!er.response) {
        var d = originalRequest.data;
        var pe = null;
        try {
          pe = d.get("prevent_error");
        } catch (error) {}
        alertData.setOpen(true);
        var e = er.toString();
        var messages = e.split(":")[1];
        if (!pe) {
          if (messages.indexOf("Network Error") > -1) {
            if (getItem("TEST_ATTEMPT") === "true") {
              alertData.setReconnecting(true);
            }
            alertData.setMessage(i18next.t("error.network.disconnected"));
          } else {
            alertData.setMessage(
              i18next.t("error.exception.title") +
                ", " +
                i18next.t("error.exception.description")
            );
          }
        }
      }
    }
    dataRes = er.response ? er.response : { data: { error_description: "" } };
    return Promise.reject(dataRes);
  }
);
const decode = obj => {
  try {
    return new URLSearchParams(obj).toString();
  } catch (error) {
    return false;
  }
};

/**
 * Axios with Method GET
 * @param uri is endpoint from URL
 * @param query is parameter query for get data
 */
export async function RequestGet(uri, query, config) {
  var loading = undefined;
  if (query) {
    loading =
      query.loading === "false" || query.loading === false ? false : true;
    query = "?" + decode(query);
  }
  var url =
    uri.indexOf("https://") === 0 || uri.indexOf("http://") === 0
      ? uri
      : MAIN_API + uri;
  return await axios.get(
    url + (query || ""),
    loading !== undefined ? { data: { loading: loading } } : undefined,
    config
  );
}

/**
 * Axios with method POST
 * @param uri is endpoint from URL
 * @param data is Object or Form Data
 */
export async function RequestPost(uri, data, config) {
  var url =
    uri.indexOf("https://") === 0 || uri.indexOf("http://") === 0
      ? uri
      : MAIN_API + uri;
  return await axios.post(url, data, config);
}

/**
 * Axios with method PUT
 * @param uri is endpoint from URL
 * @param data is Object or Form Data
 */
export async function RequestPut(uri, data, config) {
  var url =
    uri.indexOf("https://") === 0 || uri.indexOf("http://") === 0
      ? uri
      : MAIN_API + uri;
  return await axios.put(url, data, config);
}

/**
 * Axios with method DELETE
 * @param uri is endpoint from URL
 * @param data is Object or Form Data
 */
export async function RequestDelete(uri) {
  return await axios.delete(MAIN_API + uri);
}
