import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import { HeaderLogo } from "..";
import { palette } from "assets/css/main";
import { getItem, setItem, t } from "app/utils";
import { Link } from "react-router-dom";
import { IcnEN, IcnID, IcnCheckFull } from "assets/img";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";
import i18next from "i18next";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "none",
  },
  drawerPaper: {
    width: drawerWidth,
    top: 65,
  },
  drawerContainer: {
    overflow: "auto",
  },
  list: {
    width: 250,
    color: palette.primary,
    marginBottom: 65,
  },
  fullList: {
    width: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function HeaderNologin(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    openLanguage: false,
  });

  const closeLang = l => {
    i18next.changeLanguage(l);
    setState("right", false);
    setItem("lang", l);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor, va) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation">
      <List>
        {[
          { text: t("link.home"), path: "/home" },
          { text: t("link.about"), path: "/home/about" },
          { text: t("link.contact"), path: "/home/contact" },
        ].map(val => (
          <Link to={val.path} key={val.text} style={{ color: palette.primary }}>
            <ListItem button>
              <ListItemText primary={val.text} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={toggleDrawer("openLanguage", !state["openLanguage"])}>
          <ListItemText primary={t("link.lang")} />
          {state["openLanguage"] ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={state["openLanguage"]} timeout="auto" unmountOnExit>
          <List component="div">
            {[
              {
                img: (
                  <img
                    src={IcnEN}
                    height={16}
                    width={24}
                    alt="langID"
                    style={{ marginRight: 5 }}
                  />
                ),
                text: "English",
                lang: "en",
              },
              {
                img: (
                  <img
                    src={IcnID}
                    height={16}
                    width={24}
                    alt="langID"
                    style={{ marginRight: 5 }}
                  />
                ),
                text: "Bahasa Indonesia",
                lang: "id",
              },
            ].map(val => (
              <ListItem
                button
                key={val.text}
                onClick={() => {
                  closeLang(val.lang);
                }}>
                <ListItemIcon style={{ minWidth: 20 }}>{val.img}</ListItemIcon>
                <ListItemText
                  className="ListItemMenu"
                  primary={val.text}
                  style={{ fontWeight: "lighter" }}
                />
                {getItem("lang") === val.lang ? (
                  <img
                    src={IcnCheckFull}
                    alt="checked"
                    style={{ marginRight: 10 }}
                  />
                ) : null}
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "white",
        top: 0,
        padding: "20px",
        width: "100%",
        bottom: "auto",
        position: "fixed",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)",
        justifyContent: "space-between",
        boxSizing: "border-box",
        zIndex: 1201,
      }}>
      <HeaderLogo />
      {(props.button === undefined || props.button === true) && (
        <>
          {!state["right"] ? (
            <MenuIcon
              style={{ color: palette.primary }}
              onClick={toggleDrawer("right", true)}
            />
          ) : (
            <CloseIcon
              style={{ color: palette.primary }}
              onClick={toggleDrawer("right", true)}
            />
          )}
        </>
      )}

      <Drawer
        className={classes.drawer + " HGMobile"}
        classes={{
          paper: classes.drawerPaper,
        }}
        style={{ top: 80 }}
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}>
        {list("right")}
      </Drawer>
    </div>
  );
}
