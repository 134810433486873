import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { palette } from "assets/css/main";
import {
  IconUserEnvelope,
  IconUserAddress,
  IconUserTelephone,
  IconUserEgg,
  IconUserStatus,
} from "assets/img";
import { findWithAttr, getItem } from "app/utils";
// Create styles
import moment from "moment";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  sectionLeft: {
    height: "100%",
    width: "35%",
    padding: "10pt 10pt",
    fontWeight: "lighter",
    display: "flex",
    backgroundColor: palette.info,
    flexDirection: "column",
    alignItems: "center",
  },
  listContent: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "-2pt 10pt 10pt 10pt",
    borderLeft: "2pt solid " + palette.primary,
  },
  titleItem: { fontSize: 14, fontWeight: "bold" },
  titleContent: { fontSize: 18, textTransform: "uppercase" },
  item: { fontSize: 12, padding: "5pt 0pt 0pt 0pt" },
  timelineDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    position: "absolute",
    left: -6,
    top: 4,
    backgroundColor: palette.primary,
  },
});

const PersonalInfoItem = props => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,
        width: "100%",
        fontSize: 12,
        padding: "0pt 10pt",
      }}>
      <Image
        src={props.image + "?t=" + new Date().getTime()}
        style={{ height: 20, width: 20 }}
        alt={props.alt}
      />
      <Text
        style={{
          textAlign: "center",
          width: "100%",
          color: palette.primary,
          padding: "5pt 0pt",
        }}>
        {props.text}
        {props.date ? ", " + moment(props.date).format("DD/MM/yyyy") : ""}
      </Text>

      <View
        style={{
          display: props.isLast ? "none" : "",
          width: 100,
          borderBottom: "1pt solid " + palette.primary,
          borderBottomColor: palette.primary,
        }}
      />
    </View>
  );
};

const WorkExperienceView = props => {
  return (
    <View
      className="user"
      style={{
        display: props.data.length > 0 ? "" : "none",
        marginTop: 60,
      }}>
      <View style={styles.titleContent}>
        <Text>{props.t("biodata:step.workexperience")}</Text>
      </View>
      <View style={{ padding: "10pt 0pt" }}>
        {props.data.map((val, i) => {
          return (
            <View style={styles.listContent} key={"we" + i}>
              <View style={styles.timelineDot} />
              <View>
                <Text style={styles.titleItem}>{val.name}</Text>
                <Text style={styles.item}>{val.role}</Text>
                <Text style={styles.item}>
                  {findWithAttr(props.jobStatus, "value", val.status, "label")}
                </Text>
              </View>
              <View style={{ padding: "3pt 0pt" }}>
                <Text style={styles.item}>
                  {val.yearStart.split("-")[0]} -
                  {val.stillWorking
                    ? " " + props.t("biodata:form.field.stillWorking")
                    : " " + val.yearEnd.split("-")[0]}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const FormalEducationView = props => {
  return (
    <View
      className="user"
      style={{
        display: props.data.length > 0 ? "" : "none",
        marginTop: 60,
      }}>
      <View style={styles.titleContent}>
        <Text>{props.t("biodata:step.formaleducation")}</Text>
      </View>
      <View style={{ padding: "10pt 0pt" }}>
        {props.data.map((val, i) => {
          return (
            <View key={"fe" + i} style={styles.listContent}>
              <View style={styles.timelineDot} />
              <View>
                <Text style={styles.titleItem}>{val.institution}</Text>
                <Text style={styles.titleItem}>
                  {val.degree} - {val.major}
                </Text>
                <Text style={styles.item}>
                  {props.t("biodata:form.field.gpa") + " : "}
                  {val.grade}
                </Text>
              </View>
              <View style={{ padding: "3pt 0pt" }}>
                <Text style={styles.item}>
                  {props.t("biodata:form.field.graduated") + " "}
                  {val.yearGraduate}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const TemplateBlue = props => (
  <Document
    title={"CV - " + props.data.personalInfo.name}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <Page size="A4" style={styles.page}>
      <View style={styles.sectionLeft}>
        <View
          style={{
            backgroundColor: "transparent",
            width: 165,
            height: 165,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            borderTopLeftRadius: 82.5,
            borderTopRightRadius: 82.5,
            borderBottomRightRadius: 82.5,
            borderBottomLeftRadius: 82.5,
            border: "1pt solid " + palette.primary,
            padding: 10,
          }}>
          <Image
            src={props.data.personalInfo.photo + "?t=" + new Date().getTime()}
            style={{
              backgroundColor: palette.darkGrey,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: 160,
              height: 158,
              borderTopLeftRadius: 82.5,
              borderTopRightRadius: 82.5,
              borderBottomRightRadius: 82.5,
              borderBottomLeftRadius: 82.5,
            }}
            allowDangerousPaths
          />
        </View>
        <PersonalInfoItem
          text={props.data.personalInfo.address}
          image={IconUserAddress}
          alt="test"
        />
        <PersonalInfoItem
          text={
            props.data.personalInfo.phoneNumber
              ? "0" + props.data.personalInfo.phoneNumber
              : ""
          }
          image={IconUserTelephone}
          alt="aa"
        />
        <PersonalInfoItem
          text={props.data.email}
          image={IconUserEnvelope}
          alt="email"
        />
        <PersonalInfoItem
          text={props.data.personalInfo.birthPlace}
          date={props.data.personalInfo.birthDate}
          image={IconUserEgg}
          alt="birthdate"
        />
        <PersonalInfoItem
          text={findWithAttr(
            props.data.maritalStatus,
            "value",
            props.data.personalInfo.maritalStatus,
            "label"
          )}
          isLast={true}
          image={IconUserStatus}
          alt="maritalStatus"
        />
      </View>
      <View
        style={{
          color: palette.primary,
          padding: "20pt 20pt 20pt 20pt",
          width: "65%",
        }}>
        <Text
          style={{
            fontSize: 24,
            textTransform: "uppercase",
            marginBottom: 15,
          }}>
          {props.data.personalInfo.name}
        </Text>
        <Text
          style={{
            fontSize: 18,
            margin: 0,
            textTransform: "uppercase",
            marginBottom: 15,
          }}>
          ID : {getItem("user_id") ? getItem("user_id").split("-")[0] : ""}
        </Text>
        <View
          style={{
            fontSize: 12,
            textTransform: "uppercase",
            marginBottom: 0,
            textAlign: "center",
            padding: 5,
            width: 120,
            backgroundColor: palette.info,
            borderRadius: 20,
          }}>
          <Text>
            {props.t("hgkode")} :{" "}
            {props.data.personalInfo.id ? getItem("user_id").split("-")[1] : ""}
          </Text>
        </View>
        <WorkExperienceView
          data={props.data.listWorks}
          t={props.t}
          jobStatus={props.data.jobStatus}
        />
        <FormalEducationView
          data={props.data.listFormalEducation}
          t={props.t}
        />
      </View>
    </Page>
  </Document>
);
export default TemplateBlue;
