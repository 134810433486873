import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { IcnWork, IcnFormal, IcnInformal, IcnOrganizational, IcnBirthday, IcnGender, IcnMarital, IcnKTP, IcnAddress, IcnPhone, IcnContactMail, IcnNovice3, IcnIntermediate3, IcnAdvanced3 } from "assets/img";
import moment from "moment";
import { getItem, loadImage } from "app/utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  sectionLeft: {
    height: "100%",
    width: "25%",
    display: "flex",
    backgroundColor: "#eaeaea",
    flexDirection: "column",
  },
  sectionRight: {
    height: "100%",
    width: "75%",
    padding: "33pt 26pt 0pt 20pt",
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
  },
});

const WorkExperienceView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "10%",
          }}
        >
          <Image 
            src={IcnWork}
            style={{
              width: 25,
              height: 25,
            }}
          />
        </View>
        <View
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              color: "#a98753",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.workExp")}
          </Text>
          <View 
            style={{
              marginTop: 7,
              width: 31,
              height: 4,
              backgroundColor: "#a98753",
            }}
          />
        </View>
      </View>
      {
        props.data.map((v,i) => {
          return(
            <View key={i}
              style={{
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {v.name}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {v.role}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 10,
                  color: "#333333",
                }}
              >
                {(getItem("lang") === "en" ? moment(v.yearStart).lang("en").format("MMMM YYYY") : moment(v.yearStart).format("MMMM YYYY")) + " - " + (v.yearEnd ? v.yearEnd : props.t("biodata:cv_template.hg1.workYearEnd"))}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 10,
                  color: "#333333",
                }}
              >
                {
                  v.status === "1" ? props.t("biodata:cv_template.hg1.employeeStatus.1") : (
                    v.status === "2" ? props.t("biodata:cv_template.hg1.employeeStatus.2") : (
                      v.status === "3" ? props.t("biodata:cv_template.hg1.employeeStatus.3") : null
                    )
                  )
                }
              </Text>
            </View>
          )
        })
      }
    </View>
  )
}

const FormalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "10%",
          }}
        >
          <Image 
            src={IcnFormal}
            style={{
              width: 25,
              height: 25,
            }}
          />
        </View>
        <View
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              color: "#a98753",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.formalEdu")}
          </Text>
          <View 
            style={{
              marginTop: 7,
              width: 31,
              height: 4,
              backgroundColor: "#a98753",
            }}
          />
        </View>
      </View>
      {
        props.data.map((v,i) => {
          return(
            <View key={i}
              style={{
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {v.institution}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {v.degree + " | " + v.major}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 10,
                  color: "#333333",
                }}
              >
                {"GPA : " + v.grade}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 10,
                  color: "#333333",
                }}
              >
                {"Graduated : " + v.yearGraduate}
              </Text>
            </View>
          )
        })
      }
    </View>
  )
}

const InformalEducationView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "10%",
          }}
        >
          <Image 
            src={IcnInformal}
            style={{
              width: 25,
              height: 25,
            }}
          />
        </View>
        <View
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "#a98753",
            }}
          >
            {props.t("biodata:cv_template.hg1.informalEdu")}
          </Text>
          <View 
            style={{
              marginTop: 7,
              width: 31,
              height: 4,
              backgroundColor: "#a98753",
            }}
          />
        </View>
      </View>
      {
        props.data.map((v,i) => {
          return(
            <View key={i}
              style={{
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {v.institution}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {v.name}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 10,
                  color: "#333333",
                }}
              >
                {v.year}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 10,
                  color: "#333333",
                }}
              >
                {"Certificate : " + (v.isSertifikat ? props.t("biodata:cv_template.hg1.certificate.true") : props.t("biodata:cv_template.hg1.certificate.false"))}
              </Text>
            </View>
          )
        })
      }
    </View>
  )
}

const OrganizationalExperienceView = props => {
  return (
    <View
      style={{
        marginTop: 24,
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <View
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "10%",
          }}
        >
          <Image 
            src={IcnOrganizational}
            style={{
              width: 25,
              height: 25,
            }}
          />
        </View>
        <View
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 16,
              fontWeight: "bold",
              color: "#a98753",
              textTransform: "uppercase",
            }}
          >
            {props.t("biodata:cv_template.hg1.organizationalExp")}
          </Text>
          <View 
            style={{
              marginTop: 7,
              width: 31,
              height: 4,
              backgroundColor: "#a98753",
            }}
          />
        </View>
      </View>
      {
        props.data.map((v,i) => {
          return(
            <View key={i}
              style={{
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {v.name}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#333333",
                }}
              >
                {
                  v.position === "1" ? props.t("biodata:cv_template.hg1.organizationalPosition.1") : (
                    v.position === "2" ? (
                      v.function ? v.function : props.t("biodata:cv_template.hg1.organizationalPosition.2")
                    ) : null
                  )
                }
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 10,
                  color: "#333333",
                }}
              >
                {v.field}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  marginTop: 4,
                  fontSize: 10,
                  color: "#333333",
                }}
              >
                {v.year}
              </Text>
            </View>
          )
        })
      }
    </View>
  )
}

const LanguageView = props => {
  return (
    <View
      style={{
        width: "100%",
        marginTop: 38,
      }}
    >
      <Text
        style={{
          fontFamily: "AdobeClean",
          fontSize: 16,
          fontWeight: "bold",
          color: "#a98753",
          textTransform: "uppercase",
        }}
      >
        {props.t("biodata:cv_template.hg1.language")}
      </Text>
      <View 
        style={{
          marginTop: 10,
          width: 25,
          height: 4,
          backgroundColor: "#a98753",
        }}
      />
      {
        props.data.map((v,i) => {
          return(
            <View key={i}
              style={i === 0 ? {
                marginTop: 15,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              } : {
                marginTop: 12,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 8,
                  fontWeight: "bold",
                  color: "#333333",
                  textTransform: "uppercase",
                }}
              >
                {getItem("lang") === "en" ? v.languageSkillEng : v.languageSkillIna}
              </Text>
              <Image 
                src={
                  v.proficiency === 1 ? IcnNovice3 : (
                    v.proficiency === 2 ? IcnIntermediate3 : (
                      v.proficiency === 3 ? IcnAdvanced3 : null
                    )
                  )
                }
                style={{
                  marginTop: 2,
                  width: 67,
                  height: 7
                }}
              />
            </View>
          )
        })
      }
    </View>
  )
}

const TemplateHG3 = props => (
  <Document
    title={"CV - " + props.data.personalInfo.name}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <Page size="A4" style={styles.page}>
      <View style={styles.sectionLeft}>
        <Image 
          src={props.data.personalInfo.photo + "?t=" + new Date().getTime()}
          style={{
            width: "100%",
          }}
        />
        <View
          style={{
            width: "100%",
            margin: "39pt 17pt 0pt 17pt",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <View
            style={{
              width: "100%",
            }}
          >
            <Text
              style={{
                fontFamily: "AdobeClean",
                fontSize: 16,
                fontWeight: "bold",
                color: "#a98753",
                textTransform: "uppercase",
              }}
            >
              {props.t("biodata:cv_template.hg1.bio")}
            </Text>
            <View 
              style={{
                marginTop: 10,
                width: 25,
                height: 4,
                backgroundColor: "#a98753",
              }}
            />
            <View
              style={{
                marginTop: 13,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnBirthday}
                  style={{
                    width: 10,
                    height: 10,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 1,
                    fontSize: 8,
                    color: "#333333",
                  }}
                >
                  {props.data.personalInfo.birthPlace + ", " + (getItem("lang") === "en" ? moment(props.data.personalInfo.birthDate).lang("en").format("DD MMMM YYYY") : moment(props.data.personalInfo.birthDate).format("DD MMMM YYYY"))}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnGender}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 8,
                    color: "#333333",
                  }}
                >
                  {
                    props.data.personalInfo.gender === "M" ? props.t("biodata:cv_template.hg1.gender.m") : (
                      props.data.personalInfo.gender === "F" ? props.t("biodata:cv_template.hg1.gender.f") : null
                    )
                  }
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnMarital}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 8,
                    color: "#333333",
                    textTransform: "capitalize",
                  }}
                >
                  {props.data.personalInfo.maritalStatus}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnKTP}
                  style={{
                    width: 10,
                    height: 8,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 8,
                    color: "#333333",
                  }}
                >
                  {props.data.personalInfo.identityNumber}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              marginTop: 38,
            }}
          >
            <Text
              style={{
                fontFamily: "AdobeClean",
                fontSize: 16,
                fontWeight: "bold",
                color: "#a98753",
                textTransform: "uppercase",
              }}
            >
              {props.t("biodata:cv_template.hg1.contact")}
            </Text>
            <View 
              style={{
                marginTop: 10,
                width: 25,
                height: 4,
                backgroundColor: "#a98753",
              }}
            />
            <View
              style={{
                marginTop: 13,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnAddress}
                  style={{
                    width: 13,
                    height: 10,
                  }}
                />
              </View>
              <View
                style={{
                  width: "70%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    fontSize: 8,
                    color: "#333333",
                  }}
                >
                  {props.data.personalInfo.address}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnPhone}
                  style={{
                    width: 10,
                    height: 10,
                  }}
                />
              </View>
              <View
                style={{
                  width: "90%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 1,
                    fontSize: 8,
                    color: "#333333",
                  }}
                >
                  {"+62 " + props.data.personalInfo.phoneNumber}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: 11,
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "10%",
                }}
              >
                <Image 
                  src={IcnContactMail}
                  style={{
                    width: 10,
                    height: 10,
                  }}
                />
              </View>
              <View
                style={{
                  width: "80%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "AdobeClean",
                    marginTop: 1,
                    fontSize: 8,
                    color: "#333333",
                  }}
                >
                  {props.data.email ? props.data.email : " - "}
                </Text>
              </View>
            </View>
          </View>
          {props.data.personalInfo.listUserLanguage.length > 0 ?
            <LanguageView 
              data={props.data.personalInfo.listUserLanguage}
              t={props.t}
            /> : null
          }
        </View>
        <View
          style={{
            width: "100%",
            height: 143,
            position: "absolute",
            bottom: 0,
            padding: "0pt 17pt 36pt 17pt",
          }}
        >
          {
            props.data.toogle &&
            <View
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Image 
                src={loadImage("qr-code.png")}
                style={{
                  width: 48,
                  height: 48,
                }}
              />
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontWeight: "bold",
                  marginTop: 12,
                  fontSize: 7,
                  color: "#3f4550",
                }}
              >
                {props.t("biodata:cv_template.hg1.qrTitle")}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontSize: 7,
                  color: "#3f4550",
                }}
              >
                {props.t("biodata:cv_template.hg1.qrDesc")}
              </Text>
              <Text
                style={{
                  fontFamily: "AdobeClean",
                  fontWeight: "bold",
                  fontSize: 7,
                  color: "#3f4550",
                }}
              >
                {props.t("biodata:cv_template.hg1.qrLink")}
              </Text>
            </View>
          }
        </View>
      </View>
      <View style={styles.sectionRight}>
        <View
          style={{
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 30,
              fontWeight: "bold",
              color: "#a98753",
              textTransform: "uppercase",
            }}
          >
            {props.data.personalInfo.name}
          </Text>
        </View>
        <View
          style={{
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              marginTop: 3,
              fontSize: 14,
              fontWeight: "bold",
              color: "#333333",
              textTransform: "uppercase",
            }}
          >
            {props.data.listWorks.length > 0 ? props.data.listWorks[0].role : null}
          </Text>
        </View>
        <View
          style={{
            marginTop: 18,
            paddingTop: 12,
            borderTop: "4pt solid #a98753",
          }}
        >
          <Text
            style={{
              fontFamily: "AdobeClean",
              fontSize: 10,
              color: "#333333",
            }}
          >
            {props.data.personalInfo.description}
          </Text>
        </View>
        {props.data.listWorks.length > 0 ?
          <WorkExperienceView 
            data={props.data.listWorks}
            t={props.t}
          /> : null
        }
        {props.data.listFormalEducation.length > 0 ?
          <FormalEducationView 
            data={props.data.listFormalEducation}
            t={props.t}
          /> : null
        }
        {props.data.listInformalEducation.length > 0 ?
          <InformalEducationView 
            data={props.data.listInformalEducation}
            t={props.t}
          /> : null
        }
        {props.data.listOrganization.length > 0 ?
          <OrganizationalExperienceView 
            data={props.data.listOrganization}
            t={props.t}
          /> : null
        }
      </View>
    </Page>
  </Document>
);
export default TemplateHG3;
