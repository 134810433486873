import React from "react";
import { Link } from "react-router-dom";
import { PRIMARY_BOLD, PRIMARY_BUTTON, palette } from "assets/css/main";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { setItem, getItem } from "app/utils";
import { ArrowDropDown } from "@material-ui/icons";
import i18next from "i18n";
import { withTranslation } from "react-i18next";
import { GoogleLogout } from "react-google-login";
import { IcnEN, IcnID } from "assets/img";

class LinkGroup extends React.Component {
  anchorEl = null;
  anchorCandidate = null;
  anchorRecruiter = null;

  constructor(props) {
    super(props);
    this.state = {
      lang: getItem("lang") ? getItem("lang") : "id",
      openLang: false,
      openCandidate: false,
      openRecruiter: false,
    };
    this.clickLang = this.clickLang.bind(this);
    this.clickCandidate = this.clickCandidate.bind(this);
    this.clickRecruiter = this.clickRecruiter.bind(this);
  }
  propsLogout() {
    window.location.href = "/user/logout";
  }
  clickLang = e => {
    this.anchorEl = e.currentTarget;
    this.setState({ openLang: true });
  };
  clickCandidate = e => {
    this.anchorCandidate = e.currentTarget;
    this.setState({ openCandidate: true });
  };
  clickRecruiter = e => {
    this.anchorRecruiter = e.currentTarget;
    this.setState({ openRecruiter: true });
  };
  closeLang = l => {
    i18next.changeLanguage(l);
    setItem("lang", l);
    this.setState({ lang: l, openLang: false });
    this.anchorEl = null;
  };
  handleClose = () => {
    this.setState({
      openLang: false,
      openCandidate: false,
      openRecruiter: false,
    });
    this.anchorEl = null;
    this.anchorCandidate = null;
    this.anchorRecruiter = null;
  };
  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          alignItems: "center",
          width: "100%",
          display: "flex",
          paddingLeft: 100,
        }}>
        <div style={{ width: "50%" }}>
          <Link
            to="/home"
            style={{
              ...PRIMARY_BOLD,
              marginLeft: 20,
              marginRight: 20,
            }}>
            {t("link.home")}
          </Link>
          <Link
            to="/home/about"
            style={{
              ...PRIMARY_BOLD,
              marginLeft: 20,
              marginRight: 20,
            }}>
            {t("link.about")}
          </Link>
          <Link
            to="/home/contact"
            style={{
              ...PRIMARY_BOLD,
              marginLeft: 20,
            }}>
            {t("link.contact")}
          </Link>
        </div>
        <div style={{ width: "50%", textAlign: "right" }}>
          <Button onClick={this.clickLang}>
            <img
              height={21}
              width={32}
              src={this.state.lang === "en" ? IcnEN : IcnID}
              alt="lang"
            />
            <ArrowDropDown style={{ marginLeft: 5, color: palette.primary }} />
          </Button>
          <Menu
            id="simple-menu"
            style={{ marginTop: 40, fontWeight: 100 }}
            anchorEl={this.anchorEl}
            open={this.state.openLang}
            onClose={this.handleClose}>
            <MenuItem
              onClick={() => this.closeLang("id")}
              style={{ fontWeight: 100, color: palette.primary }}>
              <img
                src={IcnID}
                height={16}
                width={24}
                alt="langID"
                style={{ marginRight: 5 }}
              />
              Bahasa Indonesia
            </MenuItem>
            <MenuItem
              onClick={() => this.closeLang("en")}
              style={{ fontWeight: 100, color: palette.primary }}>
              <img
                src={IcnEN}
                height={16}
                width={24}
                alt="langID"
                style={{ marginRight: 5 }}
              />
              English
            </MenuItem>
          </Menu>
          {!getItem("access_token") && (
            <>
              <Button
                onClick={this.clickCandidate}
                size="small"
                variant="contained"
                style={{
                  ...PRIMARY_BUTTON,
                  fontWeight: "bold",
                  marginLeft: 20,
                  padding: "0px 10px 0px 20px",
                  height: 38,
                }}>
                {t("link.candidate")}
                <ArrowDropDown style={{ color: "white" }} />
              </Button>
              {Boolean(process.env.REACT_APP_IS_RECRUITER_ACTIVE === "true") ? (
                <Button
                  onClick={this.clickRecruiter}
                  size="small"
                  variant="contained"
                  style={{
                    ...PRIMARY_BUTTON,
                    fontWeight: "bold",
                    marginLeft: 10,
                    marginRight: 50,
                    backgroundColor: "white",
                    color: palette.primary,
                    border: "1px solid " + palette.primary,
                    padding: "0px 10px 0px 20px",
                    height: 38,
                  }}>
                  {t("link.recruiter")}
                  <ArrowDropDown style={{ color: palette.primary }} />
                </Button>
              ) : (
                ""
              )}

              <Menu
                id="kandidat-menu"
                style={{ marginTop: 50, minHeight: 500 }}
                anchorEl={this.anchorCandidate}
                open={this.state.openCandidate}
                onClose={this.handleClose}>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    window.location.href = "/user/login/applicant";
                  }}
                  style={{ color: palette.primary }}>
                  {t("link.login")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                    window.location.href = "/user/register/applicant";
                  }}
                  style={{ color: palette.primary, paddingRight: 50 }}>
                  {t("link.signin")}
                </MenuItem>
              </Menu>
              <Menu
                className="link-menu"
                id="recruiter-menu"
                style={{ marginTop: 50, minHeight: 500 }}
                anchorEl={this.anchorRecruiter}
                open={this.state.openRecruiter}
                onClose={this.handleClose}>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                  }}
                  style={{ color: palette.primary, margin: 0, padding: 0 }}>
                  <Link
                    to="/user/login/recruiter"
                    style={{
                      color: palette.primary,
                      padding: "5px 50px 5px 15px",
                    }}>
                    {t("link.login")}
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    this.handleClose();
                  }}
                  style={{ color: palette.primary, margin: 0, padding: 0 }}>
                  <Link
                    to="/user/register/recruiter"
                    style={{
                      color: palette.primary,
                      padding: "5px 50px 5px 15px",
                    }}>
                    {t("link.signin")}
                  </Link>
                </MenuItem>
              </Menu>
            </>
          )}
          {getItem("access_token") && (
            <GoogleLogout
              className="Google-logout"
              onLogoutSuccess={() => this.propsLogout()}
              onFailure={() => this.propsLogout()}
              clientId={process.env.REACT_APP_GOOGLE_KEY}
              render={renderProps => (
                <Button
                  onClick={() => {
                    renderProps.onClick();
                    this.propsLogout();
                  }}
                  size="small"
                  variant="contained"
                  style={{
                    ...PRIMARY_BUTTON,
                    fontWeight: "bold",
                    marginLeft: 10,
                    marginRight: 50,
                    backgroundColor: "white",
                    color: palette.primary,
                    border: "1px solid " + palette.primary,
                    padding: "0px 18px 0px 20px",
                    height: 38,
                  }}>
                  {t("link.logout")}
                </Button>
              )}
            />
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation()(LinkGroup);
