import { Button, InputBase, Menu } from "@material-ui/core";
import { t } from "app/utils";
import { palette, PRIMARY_BUTTON } from "assets/css/main";
import React from "react";
import propTypes from "prop-types";

/**
 * @extends {React.Component<{onChange:propTypes.func, anchorSelectFilter:propTypes.any, ApplyFilter:propTypes.func}>}
 */

export default class SelectionFilter extends React.Component {
  static defaultProps = {
    onChange: () => {},
    anchorSelectFilter: null,
    ApplyFilter: () => {},
    state: {},
  };
  static propTypes = {
    onChange: propTypes.func,
    anchorSelectFilter: propTypes.any,
    ApplyFilter: propTypes.func,
    state: propTypes.any,
  };
  constructor(props) {
    super(props);
    this.state = this.props.state;
  }

  DoApplyFilter = () => {
    const {
      proxmin,
      proxmax,
      selected,
      inprocess,
      proposed,
      gpamin,
      gpamax,
      gender,
    } = this.state;
    if (
      proxmin ||
      proxmax ||
      selected ||
      inprocess ||
      proposed ||
      gpamin ||
      gpamax ||
      gender
    ) {
      this.changeLocal({ selectFilter: true });
    }
    this.props.ApplyFilter();
  };

  changeLocal = v => {
    this.setState(v);
    this.props.onChange(v);
  };

  render() {
    const { anchorSelectFilter } = this.props;
    const gender = t("biodata:options.gender", {
      returnObjects: true,
    });

    return (
      <Menu
        onEnter={() => {
          this.setState(this.props.state);
        }}
        className="select-filter"
        id="simple-menu-filter"
        anchorEl={anchorSelectFilter}
        //keepMounted
        open={Boolean(anchorSelectFilter)}
        style={{
          fontWeight: "lighter",
          marginTop: 50,
          color: palette.primary,
          width: 900,
        }}
        onClose={() => {
          this.changeLocal({ anchorSelectFilter: null });
        }}>
        <div>
          <label
            style={{
              color: palette.primary,
              fontSize: 18,
              textTransform: "none",
              fontWeight: "bolder",
              padding: "10px 15px 8px",
            }}>
            {"Filter"}
          </label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}>
          <div style={{ paddingTop: 10, paddingLeft: 10, width: "270px" }}>
            <label
              style={{
                color: palette.primary,
                fontSize: 14,
                textTransform: "none",
                fontWeight: "lighter",
                paddingLeft: 5,
              }}>
              {t("candidates:filter.gender")}
            </label>
            <div className="radio" style={{ padding: "11px 5px 5px" }}>
              <label
                style={{
                  fontSize: 18,
                  color: palette.primary,
                  marginRight: 20,
                }}>
                <input
                  style={{
                    color: palette.primary,
                  }}
                  type="radio"
                  options={gender}
                  value={this.state.gender}
                  checked={this.state.gender === "M"}
                  onChange={option => {
                    this.changeLocal({ gender: "M" });
                  }}
                />
                {t("candidates:genderlist.male")}
              </label>

              <label
                style={{
                  fontSize: 18,
                  color: palette.primary,
                }}>
                <input
                  style={{
                    color: palette.primary,
                  }}
                  type="radio"
                  options={gender}
                  value={this.state.gender}
                  checked={this.state.gender === "F"}
                  onChange={option => {
                    this.changeLocal({ gender: "F" });
                  }}
                />
                {t("candidates:genderlist.female")}
              </label>
            </div>
          </div>
          <div
            style={{
              paddingTop: 10,
              paddingLeft: 40,
            }}>
            <label
              style={{
                color: palette.primary,
                fontSize: 14,
                textTransform: "none",
                fontWeight: "lighter",
                paddingLeft: 5,
              }}>
              {t("candidates:filter.proximity")}
            </label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="Min"
                style={{
                  padding: 5,
                  paddingLeft: 10,
                  borderRadius: 4,
                  display: "flex",
                  border: "0.5px solid rgba(0,0,0,0.2)",
                  alignItems: "center",
                  width: 75,
                }}>
                <InputBase
                  type="number"
                  value={this.state.proxmin}
                  onChange={e => {
                    this.changeLocal({
                      proxmin: e.target.value,
                      color: palette.primary,
                    });
                  }}
                  placeholder={"Min. 0"}
                  style={{ width: 110, color: palette.primary }}
                />
              </div>
              <label
                style={{
                  color: palette.primary,
                  fontSize: 22,
                  textTransform: "none",
                  fontWeight: "lighter",
                  padding: "7px 5px 5px",
                }}>
                {"-"}
              </label>
              <div
                className="Max"
                style={{
                  padding: 5,
                  paddingLeft: 10,
                  borderRadius: 4,
                  display: "flex",
                  border: "0.5px solid rgba(0,0,0,0.2)",
                  alignItems: "center",
                  width: 75,
                }}>
                <InputBase
                  type="number"
                  value={this.state.proxmax}
                  onChange={e => {
                    this.changeLocal({ proxmax: e.target.value });
                  }}
                  placeholder={"Max. 100"}
                  style={{ width: 110, color: palette.primary }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              paddingTop: 10,
              paddingLeft: 10,
            }}></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}>
          <div style={{ paddingTop: 10, paddingLeft: 10 }}>
            <label
              style={{
                color: palette.primary,
                fontSize: 14,
                textTransform: "none",
                fontWeight: "lighter",
                paddingLeft: 5,
              }}>
              {t("candidates:filter.statusMarketplace")}
            </label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="checkbox"
                style={{
                  padding: "11px 5px 5px",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <label
                  style={{
                    fontSize: 18,
                    color: palette.primary,
                    marginRight: 40,
                  }}>
                  <input
                    style={{
                      color: palette.primary,
                    }}
                    type="checkbox"
                    checked={this.state.selected}
                    value={this.state.selected}
                    onChange={e => {
                      if (e.target.checked) {
                        this.changeLocal({ selected: true });
                      } else {
                        this.changeLocal({ selected: false });
                      }
                    }}
                  />
                  Selected
                </label>

                <label
                  style={{
                    fontSize: 18,
                    color: palette.primary,
                    marginRight: 40,
                  }}>
                  <input
                    style={{
                      color: palette.primary,
                    }}
                    type="checkbox"
                    value={this.state.proposed}
                    checked={this.state.proposed}
                    onChange={e => {
                      if (e.target.checked) {
                        this.changeLocal({ proposed: true });
                      } else {
                        this.changeLocal({ proposed: false });
                      }
                    }}
                  />
                  Proposed
                </label>
              </div>
              <div
                className="checkbox"
                style={{
                  padding: "11px 5px 5px",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <label
                  style={{
                    fontSize: 18,
                    color: palette.primary,
                    marginRight: 40,
                  }}>
                  <input
                    style={{
                      color: palette.primary,
                    }}
                    type="checkbox"
                    value={this.state.inprocess}
                    checked={this.state.inprocess}
                    onChange={e => {
                      if (e.target.checked) {
                        this.changeLocal({ inprocess: true });
                      } else {
                        this.changeLocal({ inprocess: false });
                      }
                    }}
                  />
                  In Process
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              paddingTop: 10,
              paddingLeft: 10,
            }}>
            <label
              style={{
                color: palette.primary,
                fontSize: 14,
                textTransform: "none",
                fontWeight: "lighter",
                paddingLeft: 5,
              }}>
              {t("candidates:filter.GPA")}
            </label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="Min"
                style={{
                  padding: 5,
                  paddingLeft: 10,
                  borderRadius: 4,
                  display: "flex",
                  border: "0.5px solid rgba(0,0,0,0.2)",
                  alignItems: "center",
                  width: 75,
                }}>
                <InputBase
                  type="number"
                  value={this.state.gpamin}
                  onChange={e => {
                    this.changeLocal({ gpamin: e.target.value });
                  }}
                  placeholder={"Min. 0.00"}
                  style={{ width: 110, color: palette.primary }}
                />
              </div>
              <label
                style={{
                  color: palette.primary,
                  fontSize: 22,
                  textTransform: "none",
                  fontWeight: "lighter",
                  padding: "7px 5px 5px",
                }}>
                {"-"}
              </label>
              <div
                className="Max"
                style={{
                  padding: 5,
                  paddingLeft: 10,
                  borderRadius: 4,
                  display: "flex",
                  border: "0.5px solid rgba(0,0,0,0.2)",
                  alignItems: "center",
                  width: 75,
                }}>
                <InputBase
                  type="number"
                  value={this.state.gpamax}
                  onChange={e => {
                    this.changeLocal({ gpamax: e.target.value });
                  }}
                  placeholder={"Max. 4.00"}
                  style={{ width: 110, color: palette.primary }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              paddingTop: 10,
              paddingLeft: 10,
            }}></div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 169,
            paddingBottom: 10,
            paddingTop: 10,
          }}>
          <Button
            onClick={e => {
              this.changeLocal({
                selectFilter: false,
                gender: "",
                proxmin: "",
                proxmax: "",
                GPAmin: "",
                GPAmax: "",
                selected: false,
                proposed: false,
                inprocess: false,
              });
            }}
            size="small"
            variant="contained"
            style={{
              ...PRIMARY_BUTTON,
              fontWeight: "bold",
              marginLeft: 46,
              backgroundColor: "white",
              color: palette.primary,
              height: 35,
              fontSize: 11,
              width: 130,
            }}>
            {t("candidates:actionlist.cancel")}
          </Button>
          <Button
            onClick={this.DoApplyFilter}
            size="small"
            variant="contained"
            style={{
              ...PRIMARY_BUTTON,
              fontWeight: "bold",
              marginLeft: 46,
              backgroundColor: palette.primary,
              color: "white",
              height: 35,
              fontSize: 11,
              width: 130,
            }}>
            {t("candidates:actionlist.apply")}
          </Button>
        </div>
      </Menu>
    );
  }
}
