import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { palette } from "assets/css/main";
import { NextBtn } from "app/components";

export const EditFooter = observer(props => {
  const { text, textBack } = props;
  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      style={{
        height: 60,
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: palette.primary,
        padding: "0px 50px",
        zIndex: 100,
        display: "flex",
        justifyContent: "space-between",
      }}>
      <div
        onClick={() => {
          props.BackClick();
        }}
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: 24,
          cursor: "pointer",
          textDecoration: "underline",
        }}>
        {textBack ? textBack : "Back"}
      </div>
      <NextBtn color={"white"} onClick={() => props.NextClick()} text={text} />
    </Grid>
  );
});
