import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette, PRIMARY_BUTTON, REC_STYLE } from "assets/css/main";
import {
  Button,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { MoreHoriz, Search } from "@material-ui/icons";
import { BadgeCountInfo } from "app/components/header/BadgeCountInfo";
import { AppHistory, RequestGet, RequestPost, RequestPut } from "app/utils";
import DialogueConfirm from "app/components/modal/confirm";
import { DialogueInfo } from "app/components";
import { IconFolder } from "assets/img";

class UserDashboard extends React.Component {
  anchorEl = null;

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      value: 0,
      button: "",
      showhide: {},
      anchorMoreActAction: null,
      anchorMoreInactAction: null,
      openMore: false,
      openMoreArc: false,
      userActives: [],
      userInactives: [],
      isLoading: true,
      openconfirmMovetoAct: false,
      openconfirmMovetoInact: false,
      openconfirmSwitchAdmin: false,
      openconfirmSendEmailInvitation: false,
      openEmailInvitationsent: false,
      verified: false,
      isAdmin: null,

      textSearch: "",
    };
    this.translate = this.props.t;
  }

  componentDidMount() {
    this.getListUser();
  }

  getListUser(search = null) {
    const { t } = this.props;
    this.setState({ isLoading: true });
    RequestGet("UserCompany")
      .then(res => {
        if (search !== null) {
          this.setState({
            isLoading: false,
            isAdmin: res.data.isAdmin,

            userActives: res.data.userActives.filter(v => {
              return v.name.toLowerCase().includes(search.toLowerCase());
            }),
            userInactives: res.data.userInactives.filter(v => {
              return v.name.toLowerCase().includes(search.toLowerCase());
            }),
            button: (
              <Button
                onClick={() => {
                  AppHistory.push("/recruiter/add-user");
                }}
                disabled={!res.data.isAdmin}
                style={{
                  ...PRIMARY_BUTTON,
                  marginBottom: 8,
                  height: 44,
                  marginLeft: 15,
                  padding: "0px 13px",
                  width: 158,
                  opacity: res.data.isAdmin ? 1 : 0.5,
                }}>
                {t("rec-user-dashboard:button.addUser")}
              </Button>
            ),
          });
        } else {
          this.setState({
            isLoading: false,
            isAdmin: res.data.isAdmin,
            userActives: res.data.userActives,
            userInactives: res.data.userInactives,
            button: (
              <Button
                onClick={() => {
                  AppHistory.push("/recruiter/add-user");
                }}
                disabled={!res.data.isAdmin}
                style={{
                  ...PRIMARY_BUTTON,
                  marginBottom: 8,
                  height: 44,
                  marginLeft: 15,
                  padding: "0px 13px",
                  width: 158,
                  opacity: res.data.isAdmin ? 1 : 0.5,
                }}>
                {t("rec-user-dashboard:button.addUser")}
              </Button>
            ),
          });
        }
      })
      .catch(er => {
        this.setState({ isLoading: false });
      });
  }

  handleChange(newValue) {
    this.setState({ value: newValue });
  }

  clickMore = (e, userId, isVerified) => {
    this.setState({
      anchorMoreActAction: e,
      userId: userId,
      verified: isVerified,
    });
  };

  clickMoreInact = (e, userId, isVerified) => {
    this.setState({
      anchorMoreInactAction: e,
      userId: userId,
      verified: isVerified,
    });
  };

  movetoInActive(uId) {
    var userId = uId;
    var active = false;
    RequestPut("usercompany/status", {
      userId,
      active,
      loading: true,
    })
      .then(r => {
        this.setState({
          openconfirmMovetoInact: false,
        });
        this.getListUser();
      })
      .catch(e => {
        console.log(e);
      });
  }

  movetoAct(uId) {
    var userId = uId;
    var active = true;
    RequestPut("usercompany/status", {
      userId,
      active,
      loading: true,
    })
      .then(r => {
        this.setState({
          openconfirmMovetoAct: false,
        });
        this.getListUser();
      })
      .catch(e => {
        console.log(e);
      });
  }

  sendEmailInvitation(uId) {
    var userId = uId;
    RequestPost("UserCompany/Resend", {
      userId,
      loading: true,
    })
      .then(res => {
        if (res) {
          this.setState({
            openconfirmSendEmailInvitation: false,
            openEmailInvitationsent: true,
          });
        }
      })
      .catch(err => {
        console.log("RecruiterAddUser", err);
      });
  }

  clikOkEmailInvitationsent() {
    this.setState({
      openEmailInvitationsent: false,
    });
    this.getListUser();
  }

  switchAdmin(uId) {
    var userId = uId;
    RequestPut("usercompany", {
      userId,
      loading: true,
    })
      .then(r => {
        this.setState({
          openconfirmSwitchAdmin: false,
        });
        this.getListUser();
      })
      .catch(e => {
        console.log(e);
      });
  }

  handleChangeInputSearch = event => {
    const search = event.target.value;

    this.setState({ textSearch: event.target.value });

    this.getListUser(search.toLowerCase());
  };

  render() {
    const {
      value,
      userActives,
      userInactives,
      isLoading,
      anchorMoreActAction,
      anchorMoreInactAction,
      openconfirmMovetoAct,
      openconfirmMovetoInact,
      userId,
      openconfirmSwitchAdmin,
      openconfirmSendEmailInvitation,
      openEmailInvitationsent,
      verified,
      isAdmin,
      textSearch,
    } = this.state;
    const { t } = this.props;
    return (
      <div
        style={{
          position: "relative",
        }}>
        <MyHelmet title="User Dashboard" />
        <RecruiterAppBar shadow={true} menu="" history={this.props.history} />

        {/* Move Active User Dialog */}
        <DialogueConfirm
          open={openconfirmMovetoAct}
          title={t("rec-user-dashboard:MovetoActconfirmation.title")}
          content={t("rec-user-dashboard:MovetoActconfirmation.message")}
          btnYes={t("rec-user-dashboard:MovetoActconfirmation.btnOk")}
          btnNo={t("rec-user-dashboard:MovetoActconfirmation.btnCancel")}
          onOK={() => this.movetoAct(userId)}
          onClose={() => {
            this.setState({ openconfirmMovetoAct: false });
          }}
        />

        {/* Move Inactive User Dialog */}
        <DialogueConfirm
          open={openconfirmMovetoInact}
          title={t("rec-user-dashboard:MovetoInactconfirmation.title")}
          content={t("rec-user-dashboard:MovetoInactconfirmation.message")}
          btnYes={t("rec-user-dashboard:MovetoInactconfirmation.btnOk")}
          btnNo={t("rec-user-dashboard:MovetoInactconfirmation.btnCancel")}
          onOK={() => this.movetoInActive(userId)}
          onClose={() => {
            this.setState({ openconfirmMovetoInact: false });
          }}
        />

        {/* Switch Admin Dialog */}
        <DialogueConfirm
          open={openconfirmSwitchAdmin}
          title={t("rec-user-dashboard:SwitchAdminconfirmation.title")}
          content={t("rec-user-dashboard:SwitchAdminconfirmation.message")}
          btnYes={t("rec-user-dashboard:SwitchAdminconfirmation.btnOk")}
          btnNo={t("rec-user-dashboard:SwitchAdminconfirmation.btnCancel")}
          onOK={() => this.switchAdmin(userId)}
          onClose={() => {
            this.setState({ openconfirmSwitchAdmin: false });
          }}
        />

        {/* Send Email Invitation Dialog */}
        <DialogueConfirm
          open={openconfirmSendEmailInvitation}
          title={t("rec-user-dashboard:SendEmailInvitationconfirmation.title")}
          content={t(
            "rec-user-dashboard:SendEmailInvitationconfirmation.message"
          )}
          btnYes={t("rec-user-dashboard:SendEmailInvitationconfirmation.btnOk")}
          btnNo={t(
            "rec-user-dashboard:SendEmailInvitationconfirmation.btnCancel"
          )}
          onOK={() => this.sendEmailInvitation(userId)}
          onClose={() => {
            this.setState({ openconfirmSendEmailInvitation: false });
          }}
        />

        {/* Email Invitation successfully sent Dialog */}
        <DialogueInfo
          title={t("rec-user-dashboard:EmailInvitationsent.title")}
          content={t("rec-user-dashboard:EmailInvitationsent.message")}
          open={openEmailInvitationsent}
          onClose={() => {
            this.clikOkEmailInvitationsent();
          }}
        />

        {/* More Action Active User */}
        <Menu
          className="more-action"
          id="more-menu"
          style={{
            marginTop: 35,
            minHeight: 500,
          }}
          anchorEl={anchorMoreActAction}
          keepMounted
          open={Boolean(anchorMoreActAction)}
          onClose={() => {
            this.setState({
              anchorMoreActAction: null,
            });
          }}>
          {!verified && (
            <MenuItem
              onClick={e => {
                this.setState({
                  openconfirmSendEmailInvitation: true,
                  anchorMoreActAction: null,
                });
              }}
              style={{
                color: palette.primary,
                margin: 0,
                fontWeight: "lighter",
                padding: "5px 50px 5px 15px",
              }}>
              {t("rec-user-dashboard:moreAction.sendEmailInv")}
            </MenuItem>
          )}
          {verified && (
            <MenuItem
              onClick={e => {
                this.setState({
                  openconfirmSwitchAdmin: true,
                  anchorMoreActAction: null,
                });
              }}
              style={{
                color: palette.primary,
                margin: 0,
                fontWeight: "lighter",
                padding: "5px 50px 5px 15px",
              }}>
              {t("rec-user-dashboard:moreAction.switchtoAdmin")}
            </MenuItem>
          )}
          <MenuItem
            onClick={e => {
              this.setState({
                openconfirmMovetoInact: true,
                anchorMoreActAction: null,
              });
            }}
            style={{
              color: palette.primary,
              margin: 0,
              fontWeight: "lighter",
              padding: "5px 50px 5px 15px",
            }}>
            {t("rec-user-dashboard:moreAction.movetoInact")}
          </MenuItem>
        </Menu>

        {/* More Action Inactive User */}
        <Menu
          className="link-menu"
          id="more-menu-arc"
          style={{
            marginTop: 35,
            minHeight: 500,
          }}
          anchorEl={anchorMoreInactAction}
          keepMounted
          open={Boolean(anchorMoreInactAction)}
          onClose={() => {
            this.setState({
              anchorMoreInactAction: null,
            });
          }}>
          <MenuItem
            onClick={e => {
              this.setState({
                openconfirmMovetoAct: true,
                anchorMoreInactAction: null,
              });
            }}
            style={{
              color: palette.primary,
              margin: 0,
              fontWeight: "lighter",
              padding: "5px 50px 5px 15px",
            }}>
            {t("rec-user-dashboard:moreAction.movetoAct")}
          </MenuItem>
        </Menu>

        <div style={REC_STYLE.BODY_STYLE}>
          {/* sub header */}
          <div
            style={{
              ...REC_STYLE.SUB_HEADER,
              display: "flex",
              flexDirection: "column",
              paddingTop: 30,
            }}>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}>
              <Typography
                style={{
                  fontSize: 24,
                  color: palette.primary,
                  textTransform: "uppercase",
                }}>
                User Dashboard
              </Typography>
            </div>
            <div
              style={{
                width: "100%",
                position: "relative",
                display: "flex",
                paddingTop: 25,
              }}>
              <div
                style={{
                  width: "71%",
                  position: "relative",
                }}>
                <Button
                  onClick={() => {
                    this.handleChange(0);
                  }}
                  style={{
                    borderRadius: 0,
                    paddingBottom: 18,
                    color: palette.primary,
                    opacity: value === 0 ? 1 : 0.5,
                    fontWeight: "bold",
                    borderBottom:
                      value === 0
                        ? "2px solid " + palette.primary
                        : "2px solid transparent",
                  }}>
                  {t("rec-user-dashboard:button.activeUser")}
                  <BadgeCountInfo circle={true} count={userActives.length} />
                </Button>
                <Button
                  onClick={() => {
                    this.handleChange(1);
                  }}
                  style={{
                    borderRadius: 0,
                    paddingBottom: 18,
                    color: palette.primary,
                    fontWeight: "bold",
                    opacity: value === 1 ? 1 : 0.5,
                    borderBottom:
                      value === 1
                        ? "2px solid " + palette.primary
                        : "2px solid transparent",
                  }}>
                  {t("rec-user-dashboard:button.inactiveUser")}
                  <BadgeCountInfo circle={true} count={userInactives.length} />
                </Button>
              </div>
              <div
                style={{
                  width: "36%",
                  display: "flex",
                  alignItems: "center",
                }}>
                <div
                  style={{
                    marginBottom: 8,
                    padding: 5,
                    paddingLeft: 10,
                    borderRadius: 4,
                    display: "flex",
                    border: "0.5px solid rgba(0,0,0,0.3)",
                    alignItems: "center",
                    width: 320,
                  }}>
                  <Search
                    style={{
                      color: "rgba(0,0,0,0.3)",
                      paddingBottom: 2,
                      paddingRight: 15,
                    }}
                  />
                  <InputBase
                    placeholder={t("rec-user-dashboard:placeholder.search")}
                    style={{
                      width: 250,
                    }}
                    onChange={this.handleChangeInputSearch}
                  />
                </div>
                {this.state.button}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                position: "relative",
              }}>
              <div
                className="list-title"
                style={{
                  width: "100%",
                }}>
                <table
                  style={{
                    width: "100%",
                    borderTop: "1px solid " + palette.primary,
                    paddingTop: 20,
                    paddingBottom: 20,
                    color: "rgba(0, 0, 0, 0.4)",
                    height: 74,
                  }}>
                  <tbody>
                    <tr>
                      <th width="15%" align="center">
                        {t("rec-user-dashboard:listTable.Name")}
                      </th>
                      <th width="13%" align="center">
                        {t("rec-user-dashboard:listTable.Email")}
                      </th>
                      <th width="7%" align="center">
                        {t("rec-user-dashboard:listTable.Status")}
                      </th>
                      <th width="15%" align="center">
                        {t("rec-user-dashboard:listTable.Phonenumber")}
                      </th>
                      <th width="10%" align="center">
                        {t("rec-user-dashboard:listTable.Position")}
                      </th>
                      <th width="15%" align="center">
                        {t("rec-user-dashboard:listTable.Role")}
                      </th>
                      <th width="7%" align="center">
                        {t("rec-user-dashboard:listTable.Action")}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {value === 0 && (
            <div
              style={{
                padding: "100px",
                paddingTop: 225,
              }}>
              <div
                className="list-data"
                style={{
                  width: "100%",
                }}>
                {userActives.length === 0 && !isLoading ? (
                  <div>
                    <Paper
                      style={{
                        display: !this.state.data ? "" : "none",
                        boxShadow: "none",
                        border: "1px solid #E5E5E5",
                        padding: "50px 20px",
                        color: palette.primary,
                      }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          flexDirection: "column",
                        }}>
                        <div
                          style={{
                            width: 500,
                            textAlign: "center",
                          }}>
                          <div>
                            <img src={IconFolder} alt="Folder" />
                          </div>
                          <div
                            style={{ marginTop: 15, fontWeight: "lighter" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                textSearch === ""
                                  ? t("rec-user-dashboard:text.emptyActive")
                                  : t("rec-user-dashboard:text.emptySearch"),
                            }}
                          />
                        </div>
                      </div>
                    </Paper>
                  </div>
                ) : null}
                {userActives.map((v, k) => {
                  return (
                    <table
                      key={"list-userActives-" + k}
                      className="item"
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                        borderRadius: 4,
                        marginBottom: 15,
                        height: 74,
                      }}>
                      <tbody>
                        <tr
                          style={{
                            width: "100%",
                          }}>
                          <td width="15%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.name}
                            </div>
                          </td>
                          <td width="13%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.email}
                            </div>
                          </td>
                          <td width="7%" align="center" valign="middle">
                            <div style={{ position: "relative" }}>
                              <Button
                                className={"Proximity"}
                                disabled={true}
                                onClick={() => {}}
                                style={{
                                  color: v.isVerified
                                    ? "rgb(46, 105, 178)"
                                    : "#515D6E",
                                  fontSize: 14,
                                  width: 110,
                                  height: 26,
                                  padding: "0 15",
                                  textTransform: "none",
                                  background: v.isVerified
                                    ? "rgb(46, 105, 178, 0.3)"
                                    : "#DBDFE8",
                                  border: "none",
                                  borderRadius: 20,
                                  opacity: 1,
                                  fontWeight: "normal",
                                }}>
                                {v.isVerified ? "Verified" : "Not Verified"}
                              </Button>
                            </div>
                          </td>
                          <td width="15%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.phoneNumber}
                            </div>
                          </td>
                          <td width="10%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.positionType}
                            </div>
                          </td>
                          <td width="15%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.isAdmin ? "Admin Company" : "User"}
                            </div>
                          </td>
                          <td width="7%" align="center" valign="middle">
                            {isAdmin && (
                              <div>
                                {!v.isAdmin && (
                                  <Button
                                    onClick={e => {
                                      this.clickMore(
                                        e.currentTarget,
                                        v.userId,
                                        v.isVerified
                                      );
                                    }}
                                    style={{
                                      padding: 0,
                                    }}>
                                    <MoreHoriz style={{ color: "grey" }} />
                                  </Button>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </div>
          )}
          {value === 1 && (
            <div
              style={{
                padding: "100px",
                paddingTop: 225,
              }}>
              <div
                className="list-data"
                style={{
                  width: "100%",
                }}>
                {userInactives.length === 0 && !isLoading ? (
                  <div>
                    <Paper
                      style={{
                        display: !this.state.data ? "" : "none",
                        boxShadow: "none",
                        border: "1px solid #E5E5E5",
                        padding: "50px 20px",
                        color: palette.primary,
                      }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          flexDirection: "column",
                        }}>
                        <div
                          style={{
                            width: 500,
                            textAlign: "center",
                          }}>
                          <div>
                            <img src={IconFolder} alt="Folder" />
                          </div>
                          <div
                            style={{ marginTop: 15, fontWeight: "lighter" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                textSearch === ""
                                  ? t("rec-user-dashboard:text.emptyInactive")
                                  : t("rec-user-dashboard:text.emptySearch"),
                            }}
                          />
                        </div>
                      </div>
                    </Paper>
                  </div>
                ) : null}
                {userInactives.map((v, k) => {
                  return (
                    <table
                      key={"list-userActives-" + k}
                      className="item"
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                        borderRadius: 4,
                        marginBottom: 15,
                        height: 74,
                      }}>
                      <tbody>
                        <tr
                          style={{
                            width: "100%",
                          }}>
                          <td width="15%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.name}
                            </div>
                          </td>
                          <td width="13%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.email}
                            </div>
                          </td>
                          <td width="7%" align="center" valign="middle">
                            <div style={{ position: "relative" }}>
                              <Button
                                className={"Proximity"}
                                disabled={true}
                                onClick={() => {}}
                                style={{
                                  color: v.isVerified
                                    ? "rgb(46, 105, 178)"
                                    : "#515D6E",
                                  fontSize: 14,
                                  width: 110,
                                  height: 26,
                                  padding: "0 15",
                                  textTransform: "none",
                                  background: v.isVerified
                                    ? "rgb(46, 105, 178, 0.3)"
                                    : "#DBDFE8",
                                  border: "none",
                                  borderRadius: 20,
                                  opacity: 1,
                                  fontWeight: "normal",
                                }}>
                                {v.isVerified ? "Verified" : "Not Verified"}
                              </Button>
                            </div>
                          </td>
                          <td width="15%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.phoneNumber}
                            </div>
                          </td>
                          <td width="10%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.positionType}
                            </div>
                          </td>
                          <td width="15%" align="center" valign="middle">
                            <div
                              style={{
                                color: "rgb(46, 105, 178)",
                                fontSize: 16,
                              }}>
                              {v.isAdmin ? "Admin Company" : "User"}
                            </div>
                          </td>
                          <td width="7%" align="center" valign="middle">
                            {isAdmin && (
                              <div>
                                {!v.isAdmin && (
                                  <Button
                                    onClick={e => {
                                      this.clickMoreInact(
                                        e.currentTarget,
                                        v.userId,
                                        v.isVerified
                                      );
                                    }}
                                    style={{
                                      padding: 0,
                                    }}>
                                    <MoreHoriz style={{ color: "grey" }} />
                                  </Button>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation()(UserDashboard);
