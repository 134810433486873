import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import UserProfileSidePanel from "./side-panel";
import HeaderProfile from "./header";
import { ApplicantHeader } from "app/components";
import { RequestGet } from "app/utils";

export default withTranslation("user-profile")(
  class UserJobSpectrumProfile extends React.Component {
    state = { data: [] };
    componentDidMount() {
      this.getJobspectrum();
    }

    getJobspectrum() {
      RequestGet("userresult/jobspectrum")
        .then(r => {
          this.setState({ data: r.data });
        })
        .catch(e => {
          console.log("getJobspectrum()=>", e);
        });
    }
    render() {
      const {
        t,
        match: { params },
      } = this.props;
      const { data } = this.state;
      const level = t("jobSpectrum.level", { returnObjects: true });
      return (
        <div style={{ height: "100%", boxSizing: "border-box" }}>
          <ApplicantHeader menu="profile" shadow={true} />
          <div
            style={{ paddingTop: 80, height: "100%", boxSizing: "border-box" }}>
            <HeaderProfile id={params.id} />
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                minHeight: 650,
              }}>
              <div
                style={{
                  display: "flex",
                  width: "20%",
                  backgroundColor: palette.lightGrey,
                  boxSizing: "border-box",
                  justifyContent: "flex-end",
                }}>
                <UserProfileSidePanel view="spectrum" id={params.id} />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  height: "100%",
                  boxSizing: "border-box",
                  flexDirection: "column",
                  padding: "25px 40px",
                  color: palette.primary,
                }}>
                <p style={{ margin: 0, fontSize: 18, marginBottom: 20 }}>
                  {t("jobSpectrum.title")}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}>
                  {level.map((a, b) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginRight: 25,
                        }}
                        key={"level" + b}>
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            borderRadius: "50%",
                            marginRight: 10,
                            backgroundColor:
                              b === 0
                                ? palette.primary
                                : palette.sopacity(1 / b),
                          }}></div>
                        <p style={{ fontWeight: "lighter", fontSize: 13 }}>
                          {a}
                        </p>
                      </div>
                    );
                  })}
                </div>
                {data.map((v, k) => {
                  return (
                    <div
                      key={"jobs" + k}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                        margin: "20px 0px",
                      }}>
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: "50%",
                          marginRight: 10,
                          backgroundColor:
                            v.compatibility === "HIGH"
                              ? palette.primary
                              : v.compatibility === "MEDIUM"
                              ? palette.sopacity(1)
                              : palette.sopacity(0.5),
                        }}></div>
                      <div style={{ width: "100%" }}>
                        <p style={{ margin: "5px 10px" }}>{v.job}</p>
                        <p
                          style={{
                            margin: "5px 10px",
                            fontWeight: "lighter",
                            width: "80%",
                          }}>
                          {v.description}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
