import React from "react";
import { RequestGet, getItem, RequestPost, setItem } from "app/utils";
import btd from "../data";
import IntroCognitive from "./intro";
import Freetext from "./Freetext";
import Choices from "./Choices";
import CandidTaken from "app/components/camera/HGCandid";

export default class CognitivePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: <div></div>,
      intro: btd.cognitiveIntro,
      examAttemptId: null,
      title:
        getItem("lang") === "en"
          ? this.props.data.infoEn
          : this.props.data.infoId,
      open: btd.cognitiveIntro ? false : true,
      timer: 1,
      total: this.props.data.totalQuestion,
    };
  }

  componentDidMount() {
    if (!this.state.intro) {
      this.getExamAttemptID();
    } else {
      this.getExamAttemptID();
    }
  }

  getExamAttemptID() {
    const { id } = this.props.data;
    var data = {
      examCategoryId: id,
      userId: getItem("user_id"),
    };
    RequestPost("examattempts", data)
      .then(r => {
        if (!getItem(r.data.id)) {
          setItem(r.data.id + "-tl", r.data.timeLimit);
        }
        this.getExamQuestion(r.data.id);
        this.setState({ examAttemptId: r.data.id });
      })
      .catch(e => {
        console.log("cognitive:index.js > getExamAttemptID() error", e);
      });
  }
  getExamQuestion(eid) {
    const data = {
      examAttemptId: eid,
      isTraining: false,
    };
    RequestGet("questionattempts", data)
      .then(r => {
        var data = r.data;
        var questionAttempts = data.questionAttempts.sort(function (a, b) {
          return a.questionOrder - b.questionOrder;
        });
        data.questionAttempts = questionAttempts;
        return data;
      })
      .then(reorder => {
        if (reorder.type === "Choice") {
          this.setState({
            content: (
              <Choices
                totalQuestion={this.props.data.totalQuestion}
                id={eid}
                history={this.props.history}
                data={reorder}
                root={this.props.data}
                title={
                  getItem("lang") === "en"
                    ? this.props.data.infoEn
                    : this.props.data.infoId
                }
              />
            ),
          });
        } else if (reorder.type === "Freetext") {
          this.setState({
            content: (
              <Freetext
                totalQuestion={this.props.data.totalQuestion}
                id={eid}
                root={this.props.data}
                history={this.props.history}
                data={reorder}
                title={
                  getItem("lang") === "en"
                    ? this.props.data.infoEn
                    : this.props.data.infoId
                }
              />
            ),
          });
        } else {
          this.setState({ content: <div>Unsupported Type</div> });
        }
      })
      .catch(e => {
        console.log("cognitive:index.js > getExamQuestion()", e);
      });
  }

  render() {
    const { intro, examAttemptId } = this.state;
    return (
      <div>
        <CandidTaken id={examAttemptId} />
        {intro && (
          <IntroCognitive
            onStart={() => {
              this.getExamAttemptID();
              this.setState({ intro: false, open: true });
              btd.cognitiveIntro = false;
            }}
          />
        )}
        <div style={{ backgroundColor: "white" }}>{!intro && this.state.content}</div>
      </div>
    );
  }
}
