import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  // Font,
} from "@react-pdf/renderer";
import { palette } from "assets/css/main";
import moment from "moment";
import { getItem, loadImage } from "app/utils";
import { IcnAddress, IcnBirthday, IcnContactMail, IcnGender, IcnMarital, IcnPhone, IcnKTP, IcnNovice, IcnIntermediate, IcnAdvanced } from "assets/img";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    display: "flex",
    width: "100%",
    height: "100%",
    paddingRight: "8%",
    paddingLeft: "8%",
  },
  sectionTop: {
    width: "100%",
    height: "12%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  sectionBottom: {
    width: "100%",
    height: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "2pt solid #d4d4d6",
    borderTop: "2pt solid #d4d4d6",
  },
  sectionMiddle: {
    width: "100%",
    height: "18%",
    display: "flex",
    flexDirection: "column",
  },
  sectionFooter: {
    width: "100%",
    height: "10%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  fontBebasNeue: {
    // fontFamily: "BebasNeue",
    fontSize: "60pt",
    color: "#9a9a9f",
  }
});

const InformalEducationView = props => {
  return (
    <View
      style={{
        width: "100%",
        height: "25%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "right",
        }}
      >
        <Text
          style={{
            fontFamily: "BebasNeue",
            marginTop: "10pt",
            fontSize: 16,
            color: "#333333",
            textTransform: "uppercase",
            letterSpacing: "3.2pt",
          }}
        >
          {props.t("biodata:cv_template.hg1.informalEdu")}
        </Text>
        {
          props.data.map((v,i) => {
            return(
              <View key={i}
                style={{
                  width: "100%",
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#333333",
                    textAlign: "right",
                    marginBottom: 2,
                  }}
                >
                  {v.institution}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#333333",
                    textAlign: "right",
                    marginBottom: 2,
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 9,
                    color: "#333333",
                    textAlign: "right",
                    marginBottom: 2,
                  }}
                >
                  {v.year}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 9,
                    fontStyle: "italic",
                    color: "#333333",
                    textAlign: "right",
                  }}
                >
                  {"Certificate : " + (v.isSertifikat ? props.t("biodata:cv_template.hg1.certificate.true") : props.t("biodata:cv_template.hg1.certificate.false"))}
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  );
};

const FormalEducationView = props => {
  return (
    <View
      style={{
        width: "100%",
        height: "25%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "2pt solid #d4d4d6",
      }}
    >
      <View
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "right",
        }}
      >
        <Text
          style={{
            fontFamily: "BebasNeue",
            marginTop: "10pt",
            fontSize: 16,
            color: "#333333",
            textTransform: "uppercase",
            letterSpacing: "3.2pt",
          }}
        >
          {props.t("biodata:cv_template.hg1.formalEdu")}
        </Text>
        {
          props.data.map((v,i) => {
            return(
              <View key={i}
                style={{
                  width: "100%",
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#333333",
                    textAlign: "right",
                    marginBottom: 2,
                  }}
                >
                  {v.institution}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#333333",
                    textAlign: "right",
                    marginBottom: 2,
                  }}
                >
                  {v.degree + " | " + v.major}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 9,
                    color: "#333333",
                    textAlign: "right",
                    marginBottom: 2,
                  }}
                >
                  {"GPA : " + v.grade}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 9,
                    fontStyle: "italic",
                    color: "#333333",
                    textAlign: "right",
                  }}
                >
                  {"Graduated : " + v.yearGraduate}
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  );
};

const LanguageView = props => {
  return (
    <View
      style={{
        width: "100%",
        height: "25%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "2pt solid #d4d4d6",
      }}
    >
      <View
        style={{
          //width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "right",
        }}
      >
        <Text
          style={{
            fontFamily: "BebasNeue",
            marginTop: 10,
            fontSize: 16,
            color: "#333333",
            textTransform: "uppercase",
            letterSpacing: "3.2pt",
            marginBottom: 5
          }}
        >
          {props.t("biodata:cv_template.hg1.language")}
        </Text>
        {
          props.data.map((v,i) => {
            return(
              <View key={i}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 2,
                }}
              >
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      width: "60%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Image 
                      src={
                        v.proficiency === 1 ? IcnNovice : (
                          v.proficiency === 2 ? IcnIntermediate : (
                            v.proficiency === 3 ? IcnAdvanced : null
                          )
                        )
                      }
                      style={{
                        width: 67,
                        height: 7,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      width: "40%"
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 9,
                        textAlign: "right",
                        color: "#333333",
                      }}
                    >
                      {getItem("lang") === "en" ? v.languageSkillEng : v.languageSkillIna}
                    </Text>
                  </View>
                </View>
              </View>
            )
          })
        }
      </View>
    </View>
  );
};

const WorkExperience = props => {
  return (
    <View
      style={{
        width: "100%",
        height: "25%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "2pt solid #d4d4d6",
      }}
    >
      <View
        style={{
          //width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <Text
          style={{
            fontFamily: "BebasNeue",
            marginTop: "10pt",
            fontSize: 16,
            color: "#333333",
            textTransform: "uppercase",
            letterSpacing: "3.2pt",
          }}
        >
          {props.t("biodata:cv_template.hg1.workExp")}
        </Text>
        {
          props.data.map((v,i) => {
            return(
              <View key={i}
                style={{
                  width: "100%",
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#333333",
                    textAlign: "left",
                    marginBottom: 2,
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#333333",
                    textAlign: "left",
                    marginBottom: 2,
                  }}
                >
                  {v.role}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 9,
                    color: "#333333",
                    textAlign: "left",
                    marginBottom: 2,
                  }}
                >
                  {(getItem("lang") === "en" ? moment(v.yearStart).lang("en").format("MMMM YYYY") : moment(v.yearStart).format("MMMM YYYY")) + " - " + (v.yearEnd ? v.yearEnd : props.t("biodata:cv_template.hg1.workYearEnd"))}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 9,
                    fontStyle: "italic",
                    color: "#333333",
                    textAlign: "left",
                  }}
                >
                  {
                    v.status === "1" ? props.t("biodata:cv_template.hg1.employeeStatus.1") : (
                      v.status === "2" ? props.t("biodata:cv_template.hg1.employeeStatus.2") : (
                        v.status === "3" ? props.t("biodata:cv_template.hg1.employeeStatus.3") : null
                      )
                    )
                  }
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  );
};

const OrganizationalExperience = props => {
  return (
    <View
      style={{
        width: "100%",
        height: "25%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <View
        style={{
          //width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
        }}
      >
        <Text
          style={{
            fontFamily: "BebasNeue",
            marginTop: "10pt",
            fontSize: 16,
            color: "#333333",
            textTransform: "uppercase",
            letterSpacing: "3.2pt",
          }}
        >
          {props.t("biodata:cv_template.hg1.organizationalExp")}
        </Text>
        {
          props.data.map((v,i) => {
            return(
              <View key={i}
                style={{
                  width: "100%",
                  marginTop: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    fontWeight: "bold",
                    color: "#333333",
                    textAlign: "left",
                    marginBottom: 2,
                  }}
                >
                  {v.name}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 10,
                    fontWeight: "bold",
                    color: "#333333",
                    textAlign: "left",
                    marginBottom: 2,
                  }}
                >
                  {
                    v.position === "1" ? props.t("biodata:cv_template.hg1.organizationalPosition.1") : (
                      v.position === "2" ? (
                        v.function ? v.function : props.t("biodata:cv_template.hg1.organizationalPosition.2")
                      ) : null
                    )
                  }
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 9,
                    color: "#333333",
                    textAlign: "left",
                    marginBottom: 2,
                  }}
                >
                  {v.field}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 9,
                    fontStyle: "italic",
                    color: "#333333",
                    textAlign: "left",
                  }}
                >
                  {v.year}
                </Text>
              </View>
            )
          })
        }
      </View>
    </View>
  );
};

const TemplateHG1 = props => (
  <Document
    title={"CV - " + props.data.personalInfo.name}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <Page size="A4" style={styles.page}>
      <View style={styles.sectionTop}>
        <View
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#eaeaea",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: 102,
              height: 102,
              backgroundColor: "white",
              padding: 10,
              display: "absoulte",
              marginTop: 51,
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: 51,
              borderTopRightRadius: 51,
              borderBottomRightRadius: 51,
              borderBottomLeftRadius: 51,
            }}
          >
            <Image
              src={props.data.personalInfo.photo + "?t=" + new Date().getTime()}
              style={{
                backgroundColor: palette.darkGrey,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: 84,
                height: 84,
                borderTopLeftRadius: 42,
                borderTopRightRadius: 42,
                borderBottomRightRadius: 42,
                borderBottomLeftRadius: 42,
              }}
              allowDangerousPaths
            />
          </View>
        </View>
      </View>
      <View style={styles.sectionMiddle}>
        <View
          style={{
            marginTop: 72,
          }}
        >
          <Text
            style={{
              fontFamily: "BebasNeue",
              fontSize: 30,
              textAlign: "center",
              color: "#9a9a9f",
              textTransform: "uppercase",
              letterSpacing: "6pt",
            }}
          >
            {props.data.personalInfo.name}
          </Text>
        </View>
        <View
          style={{
            marginTop: 10,
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto",
              fontSize: 12,
              color: "#333333",
              textTransform: "uppercase",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {props.data.listWorks.length > 0 ? props.data.listWorks[0].role : null}
          </Text>
        </View>
      </View>
      <View style={styles.sectionBottom}>
        <View
          style={{
            width: "49%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRight: "2pt solid #d4d4d6",
            paddingRight: "15pt",
          }}
        >
          <View
            style={{
              width: "100%",
              height: "25%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderBottom: "2pt solid #d4d4d6",
            }}
          >
            <View
              style={{
                //width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontFamily: "BebasNeue",
                  marginTop: "10pt",
                  fontSize: 16,
                  color: "#333333",
                  textTransform: "uppercase",
                  letterSpacing: "3.2pt",
                  textAlign: "right",
                }}
              >
                {props.t("biodata:cv_template.hg1.profile")}
              </Text>
              <Text
                style={{
                  fontFamily: "Roboto",
                  marginTop: "5pt",
                  fontSize: 9,
                  color: "#333333",
                  textAlign: "right",
                }}
              >
                {props.data.personalInfo.description ? props.data.personalInfo.description : null}
              </Text>
            </View>
          </View>
          <LanguageView 
            data={props.data.personalInfo.listUserLanguage}
            t={props.t}
          />
          <FormalEducationView 
            data={props.data.listFormalEducation}
            t={props.t}
          />
          <InformalEducationView 
            data={props.data.listInformalEducation}
            t={props.t}
          />
        </View>
        <View
          style={{
            width: "51%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: "15pt",
          }}
        >
          <View
            style={{
              width: "100%",
              height: "25%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderBottom: "2pt solid #d4d4d6",
            }}
          >
            <View
              style={{
                //width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Text
                style={{
                  fontFamily: "BebasNeue",
                  marginTop: 10,
                  fontSize: 16,
                  color: "#333333",
                  textTransform: "uppercase",
                  letterSpacing: "3.2pt",
                }}
              >
                {props.t("biodata:cv_template.hg1.contact")}
              </Text>
              <View
                style={{
                  marginTop: 5,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "10%",
                  }}
                >
                  <Image
                    src={IcnAddress}
                    style={{ height: 9, width: 12 }}
                    alt={"Address"}
                  />
                </View>
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 9,
                      color: "#333333",
                    }}
                  >
                    {props.data.personalInfo.address}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginTop: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "10%",
                  }}
                >
                  <Image
                    src={IcnPhone}
                    style={{ height: 9, width: 9 }}
                    alt={"Phone"}
                  />
                </View>
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 9,
                      color: "#333333",
                    }}
                  >
                    {"+62 " + props.data.personalInfo.phoneNumber}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginTop: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "10%",
                  }}
                >
                  <Image
                    src={IcnContactMail}
                    style={{ height: 9, width: 9 }}
                    alt={"Mail"}
                  />
                </View>
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 9,
                      color: "#333333",
                    }}
                  >
                    {props.data.email ? props.data.email : " - "}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              height: "25%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderBottom: "2pt solid #d4d4d6",
            }}
          >
            <View
              style={{
                //width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Text
                style={{
                  fontFamily: "BebasNeue",
                  marginTop: "10pt",
                  fontSize: 16,
                  color: "#333333",
                  textTransform: "uppercase",
                  letterSpacing: "3.2pt",
                }}
              >
                {props.t("biodata:cv_template.hg1.bio")}
              </Text>
              <View
                style={{
                  marginTop: 5,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "10%",
                  }}
                >
                  <Image
                    src={IcnBirthday}
                    style={{ height: 10, width: 10 }}
                    alt={"Birthday"}
                  />
                </View>
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 9,
                      color: "#333333",
                    }}
                  >
                    {props.data.personalInfo.birthPlace + ", " + (getItem("lang") === "en" ? moment(props.data.personalInfo.birthDate).lang("en").format("DD MMMM YYYY") : moment(props.data.personalInfo.birthDate).format("DD MMMM YYYY"))}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginTop: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "10%",
                  }}
                >
                  <Image
                    src={IcnGender}
                    style={{ height: 7, width: 7 }}
                    alt={"Gender"}
                  />
                </View>
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 9,
                      color: "#333333",
                    }}
                  >
                    {
                      props.data.personalInfo.gender === "M" ? props.t("biodata:cv_template.hg1.gender.m") : (
                        props.data.personalInfo.gender === "F" ? props.t("biodata:cv_template.hg1.gender.f") : null
                      )
                    }
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginTop: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "10%",
                  }}
                >
                  <Image
                    src={IcnMarital}
                    style={{ height: 8, width: 8 }}
                    alt={"Marital"}
                  />
                </View>
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 9,
                      color: "#333333",
                      textTransform: "capitalize",
                    }}
                  >
                    {props.data.personalInfo.maritalStatus}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginTop: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    width: "10%",
                  }}
                >
                  <Image
                    src={IcnKTP}
                    style={{ height: 7, width: 9 }}
                    alt={"KTP"}
                  />
                </View>
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 9,
                      color: "#333333",
                    }}
                  >
                    {props.data.personalInfo.identityNumber}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <WorkExperience 
            data={props.data.listWorks}
            t={props.t}
          />
          <OrganizationalExperience 
            data={props.data.listOrganization}
            t={props.t}
          />
        </View>
      </View>
      <View style={styles.sectionFooter}>
        <View
          style={{
            marginTop: 11,
            padding: "10pt 16pt",
            width: "100%",
            height: "90%",
            position: "absolute",
            backgroundColor: "#eaeaea",
          }}
        >
          {
            props.data.toogle &&
            <View
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 48,
                  height: "100%",
                }}
              >
                <Image 
                  src={loadImage("qr-code.png")}
                  style={{
                    width: 40,
                    height: 40,
                  }}
                />
              </View>
              <View
                style={{
                  width: 165,
                  height: "100%",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrTitle")}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrDesc")}
                </Text>
                <Text
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: 7,
                    color: "#3f4550",
                  }}
                >
                  {props.t("biodata:cv_template.hg1.qrLink")}
                </Text>
              </View>
            </View>
          }
        </View>
      </View>
    </Page>
  </Document>
);
export default TemplateHG1;
