import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { findWithAttr } from "app/utils";
import { IconFolder } from "assets/img";
export default withTranslation()(
  class CVPreviewWE extends React.Component {
    render() {
      const {
        t,
        data: { listWorks },
      } = this.props;
      const jobStatus = t("biodata:options.jobStatus", {
        returnObjects: true,
      });
      return (
        <div style={{ color: palette.primary, padding: "0px 50px" }}>
          <div style={{ fontSize: 18 }}>{t("biodata:CVworkExperience")}</div>
          {listWorks.length === 0 && (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "10%",
                  }}>
                  <div>
                    <img src={IconFolder} alt="Folder" />
                  </div>
                  <div>
                    <label style={{ color: palette.primary }}>
                      {t("biodata:CVlistnull")}
                    </label>
                  </div>
                </div>
              )}

          {listWorks.map((v, k) => {
            var datestart = new Date(v.yearStart);
            var yearstart = datestart.getFullYear();
            var dateend = new Date(v.yearEnd);
            var yearend = dateend.getFullYear();
            var stillw = "";
            if (v.stillWorking === true) {
              stillw = t("biodata:form.field.stillWorking");
            } else {
              stillw = yearend.toString();
            }
            return (
              <div
                key={k}
                style={{
                  borderBottom: "1px solid rgba(46, 105, 178, 0.24)",
                }}>
                <div>
                  <br />
                </div>
                <div
                  style={{
                    color: palette.primary,
                    fontSize: 18,
                    fontWeight: "bolder",
                  }}>
                  {v.name}
                </div>
                <div
                  style={{
                    color: palette.primary,
                    fontSize: 18,
                    fontWeight: "bolder",
                  }}>
                  {v.role}
                </div>
                <div
                  style={{
                    color: palette.primary,
                    fontSize: 18,
                    fontWeight: "lighter",
                    display: "flex",
                    flexDirection: "row",
                  }}>
                  <div
                    style={{
                      width: "100%",
                    }}>
                    {findWithAttr(jobStatus, "value", v.status, "label")}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "lighter",
                  }}>
                  {yearstart} - {stillw}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
);
