import { decorate } from "mobx";

class Notification {
  notificationList = [];
}

const notification = decorate(Notification, {
  // action here
});

export default new notification();