import React from "react";
import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { Typography, Grid, Button } from "@material-ui/core";
import {
  IconEdit,
  IconConfirmationCheck,
  IconQuestion,
  ImgQuestionBackgroundTop,
} from "assets/img";
import data from "../data";
import { getItem, GetLocalItem, RequestGet, SetLocalItem } from "app/utils";

const tableStyle = {
  width: "100%",
  border: "1px solid #B0B7BD",
  borderCollapse: "collapse",
  textAlign: "left",
};

const linkStyleActive = {
  color: palette.primary,
  fontSize: 18,
  borderBottom: "2px solid " + palette.primary,
  display: "flex",
  marginRight: 40,
  height: 40,
  alignItems: "center",
  cursor: "pointer",
};
const linkStyle = {
  color: palette.primary,
  marginRight: 40,
  fontSize: 18,
  display: "flex",
  borderBottom: "2px solid transparent",
  height: 40,
  alignItems: "center",
  cursor: "pointer",
};

const thStyle = {
  width: "100%",
  border: "1px solid #B0B7BD",
  fontWeight: "normal",
  fontSize: "18px",
  color: palette.primary,
  padding: "26px",
};

const tdStyle = {
  width: "100%",
  border: "1px solid #B0B7BD",
  fontWeight: "lighter",
  fontSize: "14px",
  color: palette.primary,
  padding: "18px 16px",
};

class SoftSkillConfirmation extends React.Component {
  constructor(props) {
    super(props);
    if (GetLocalItem("softSkillGet")) {
      data.soft_skill_api = GetLocalItem("softSkillGet");
    }

    this.state = {
      menu: "ts",
      thinkingSkill: [],
      workingAttitude: [],
      relationshipSkill: [],
      softSkillHeaders: [],
      soft_skill_api: data.soft_skill_api,
    };
  }

  componentDidMount() {
    if (this.state.soft_skill_api.length === 0) {
      this.getSoftSkill();
    } else {
      this.renderSoftSkill();
    }
  }

  renderSoftSkill() {
    this.setState({
      thinkingSkill: this.renderThinkingSkill(),
      workingAttitude: this.renderWorkingAttitude(),
      relationshipSkill: this.renderRelationshipSkill(),
    });
  }

  setup(list) {
    this.setState({ soft_skill_api: list });
    data.soft_skill_api = list;

    this.setState({ menu: list[0].id });
    var objHeaders = this.state.softSkillHeaders;
    list.forEach(v => {
      v.softSkillHeaders.forEach(val => {
        objHeaders.push(val);
      });
    });
    this.setState({ softSkillHeaders: objHeaders }, () => {
      this.setState({ loaded: true });
    });

    this.renderSoftSkill();
  }

  getSoftSkill() {
    if (GetLocalItem("softSkillGet")) {
      this.setup(GetLocalItem("softSkillGet"));
    } else {
      RequestGet("softskill")
        .then(res => {
          SetLocalItem("softSkillGet", res.data);
          this.setup(res.data);
        })
        .catch(er => {
          console.log(er);
        });
    }
  }

  renderThinkingSkill() {
    return this.state.soft_skill_api[0]
      ? this.state.soft_skill_api[0].softSkillHeaders.map((val, index) => {
          return (
            <tbody style={{ width: "100%" }} key={"thinkingSkill-" + index}>
              {val.softSkillDetails
                ? val.softSkillDetails.map((v, i) => {
                    return (
                      <tr style={{ width: "100%" }} key={"ts-" + i}>
                        {i === 0 ? (
                          <th
                            style={{ ...thStyle, width: "33%" }}
                            rowSpan={val.softSkillDetails.length}>
                            <Grid container>
                              <Grid item lg={8} md={8} sm={8}>
                                {getItem("lang") === "en"
                                  ? val.softskillNameEng
                                  : val.softskillNameIna}
                              </Grid>
                              <Grid item lg={4} md={4} sm={4}>
                                <img
                                  className="question-tag"
                                  style={{ paddingTop: "12px" }}
                                  src={IconQuestion}
                                  alt={"question" + i}
                                  height="15vh"
                                  align="right"
                                />
                                <div
                                  className="question"
                                  style={{
                                    position: "absolute",
                                    marginLeft: -63,
                                    marginTop: -89,
                                  }}>
                                  <img
                                    src={ImgQuestionBackgroundTop}
                                    height={"100vh"}
                                    alt="question"
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      color: "white",
                                      fontSize: "12px",
                                      textTransform: "none",
                                      top: "5%",
                                      left: "2%",
                                      right: "2%",
                                      paddingBottom: "10px",

                                      textAlign: "justify",
                                    }}>
                                    {getItem("lang") === "en"
                                      ? val.softskillDescEng
                                      : val.softskillDescIna}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </th>
                        ) : null}
                        <td
                          style={{
                            ...thStyle,
                            width: "57%",
                          }}>
                          <Grid container>
                            <Grid item lg={2} md={2} sm={2}>
                              <img
                                src={v.imageUrlEng}
                                alt={"answer" + i}
                                height="30vh"
                                align="left"
                              />
                            </Grid>
                            <Grid
                              item
                              lg={8}
                              md={8}
                              sm={8}
                              style={{ paddingTop: "6px" }}>
                              {v.softskillValueEng}
                            </Grid>
                            <Grid item lg={2} md={2} sm={2}>
                              <img
                                className="question-tag"
                                style={{ paddingTop: "8px" }}
                                src={IconQuestion}
                                alt={"question" + i}
                                height="15vh"
                                align="right"
                              />
                              <div
                                className="question"
                                style={{
                                  position: "absolute",
                                  marginLeft: -133,
                                  marginTop: -145,
                                }}>
                                <img
                                  src={ImgQuestionBackgroundTop}
                                  height={"152vh"}
                                  alt="question"
                                />
                                <div
                                  style={{
                                    position: "absolute",
                                    color: "white",
                                    fontSize: "12px",
                                    textTransform: "none",
                                    top: "4%",
                                    left: "2%",
                                    right: "2%",
                                    textAlign: "justify",
                                  }}>
                                  {getItem("lang") === "en"
                                    ? v.attributeEng.split("\r\n").map(val => {
                                        return (
                                          <div key={val}>
                                            {val}
                                            <br />
                                          </div>
                                        );
                                      })
                                    : v.attributeIna.split("\r\n").map(val => {
                                        return (
                                          <div key={val}>
                                            {val}
                                            <br />
                                          </div>
                                        );
                                      })}
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </td>
                        <td style={{ ...tdStyle, width: "10%" }}>
                          {data.vacancy_post.vacancySoftskills.find(
                            p => p.softskillHeaderId === val.id
                          ) ? (
                            data.vacancy_post.vacancySoftskills.find(
                              p => p.softskillDetailId === v.id
                            ) ? (
                              <img
                                src={IconConfirmationCheck}
                                alt={"check" + i}
                                height="24px"
                                align="center"
                              />
                            ) : null
                          ) : null}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          );
        })
      : null;
  }

  renderWorkingAttitude() {
    return this.state.soft_skill_api[1]
      ? this.state.soft_skill_api[1].softSkillHeaders.map((val, index) => {
          return (
            <tbody style={{ width: "100%" }} key={"workingAttitude-" + index}>
              {val.softSkillDetails.map((v, i) => {
                return (
                  <tr style={{ width: "100%" }} key={"wa-" + i}>
                    {i === 0 ? (
                      <th
                        style={{ ...thStyle, width: "33%" }}
                        rowSpan={val.softSkillDetails.length}>
                        <Grid container>
                          <Grid item lg={8} md={8} sm={8}>
                            {getItem("lang") === "en"
                              ? val.softskillNameEng
                              : val.softskillNameIna}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4}>
                            <img
                              className="question-tag"
                              style={{ paddingTop: "12px" }}
                              src={IconQuestion}
                              alt={"question" + i}
                              height="15vh"
                              align="right"
                            />
                            <div
                              className="question"
                              style={{
                                position: "absolute",
                                marginLeft: -60,
                                marginTop: -87,
                              }}>
                              <img
                                src={ImgQuestionBackgroundTop}
                                height={"98vh"}
                                alt="question"
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  color: "white",
                                  fontSize: 11,
                                  textTransform: "none",
                                  top: "10%",
                                  left: "2%",
                                  right: "2%",
                                  textAlign: "justify",
                                }}>
                                {getItem("lang") === "en"
                                  ? val.softskillDescEng
                                  : val.softskillDescIna}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </th>
                    ) : null}
                    <td
                      style={{
                        ...thStyle,
                        width: "57%",
                      }}>
                      <Grid container>
                        <Grid item lg={2} md={2} sm={2}>
                          <img
                            src={v.imageUrlEng}
                            alt={"answer" + i}
                            height="30vh"
                            align="left"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          md={8}
                          sm={8}
                          style={{ paddingTop: "6px" }}>
                          {v.softskillValueEng}
                        </Grid>
                        <Grid item lg={2} md={2} sm={2}>
                          <img
                            className={"question-tag"}
                            style={{ paddingTop: "8px" }}
                            src={IconQuestion}
                            alt={"question" + i}
                            height="15vh"
                            align="right"
                          />
                          <div
                            className="question"
                            style={{
                              position: "absolute",
                              marginLeft: -92,
                              marginTop: -115,
                            }}>
                            <img
                              src={ImgQuestionBackgroundTop}
                              height={"120vh"}
                              alt="question"
                            />
                            <div
                              style={{
                                position: "absolute",
                                color: "white",
                                fontSize: 11,
                                textTransform: "none",
                                top: "5%",
                                left: "2%",
                                right: "2%",
                                textAlign: "justify",
                              }}>
                              {getItem("lang") === "en"
                                ? v.attributeEng.split("\r\n").map(val => {
                                    return (
                                      <div key={val}>
                                        {val}
                                        <br />
                                      </div>
                                    );
                                  })
                                : v.attributeIna.split("\r\n").map(val => {
                                    return (
                                      <div key={val}>
                                        {val}
                                        <br />
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </td>
                    <td style={{ ...tdStyle, width: "10%" }}>
                      {data.vacancy_post.vacancySoftskills.find(
                        p => p.softskillHeaderId === val.id
                      ) ? (
                        data.vacancy_post.vacancySoftskills.find(
                          p => p.softskillDetailId === v.id
                        ) ? (
                          <img
                            src={IconConfirmationCheck}
                            alt={"check" + i}
                            height="24px"
                            align="center"
                          />
                        ) : null
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          );
        })
      : null;
  }

  renderRelationshipSkill() {
    return this.state.soft_skill_api[2]
      ? this.state.soft_skill_api[2].softSkillHeaders.map((val, index) => {
          return (
            <tbody style={{ width: "100%" }} key={"relationshipSkill-" + index}>
              {val.softSkillDetails.map((v, i) => {
                return (
                  <tr style={{ width: "100%" }} key={"rs-" + i}>
                    {i === 0 ? (
                      <th
                        style={{ ...thStyle, width: "33%" }}
                        rowSpan={val.softSkillDetails.length}>
                        <Grid container>
                          <Grid item lg={8} md={8} sm={8}>
                            {getItem("lang") === "en"
                              ? val.softskillNameEng
                              : val.softskillNameIna}
                          </Grid>
                          <Grid item lg={4} md={4} sm={4}>
                            <img
                              className="question-tag"
                              style={{ paddingTop: "12px" }}
                              src={IconQuestion}
                              alt={"question" + i}
                              height="15vh"
                              align="right"
                            />
                            <div
                              className="question"
                              style={{
                                position: "absolute",
                                marginLeft: -78,
                                marginTop: -90,
                              }}>
                              <img
                                src={ImgQuestionBackgroundTop}
                                height={"85vh"}
                                alt="question"
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  color: "white",
                                  fontSize: 12,
                                  textTransform: "none",
                                  top: "10%",
                                  left: "2%",
                                  right: "2%",
                                  textAlign: "justify",
                                }}>
                                {getItem("lang") === "en"
                                  ? val.softskillDescEng
                                  : val.softskillDescIna}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </th>
                    ) : null}
                    <td
                      style={{
                        ...thStyle,
                        width: "57%",
                      }}>
                      <Grid container>
                        <Grid item lg={2} md={2} sm={2}>
                          <img
                            src={v.imageUrlEng}
                            alt={"answer" + i}
                            height="30vh"
                            align="left"
                          />
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          md={8}
                          sm={8}
                          style={{ paddingTop: "6px" }}>
                          {v.softskillValueEng}
                        </Grid>
                        <Grid item lg={2} md={2} sm={2}>
                          <img
                            className={"question-tag"}
                            style={{ paddingTop: "8px" }}
                            src={IconQuestion}
                            alt={"question" + i}
                            height="15vh"
                            align="right"
                          />
                          <div
                            className="question"
                            style={{
                              position: "absolute",
                              marginLeft: -99,
                              marginTop: -120,
                            }}>
                            <img
                              src={ImgQuestionBackgroundTop}
                              height={"125vh"}
                              alt="question"
                            />
                            <div
                              style={{
                                position: "absolute",
                                color: "white",
                                fontSize: 12,
                                textTransform: "none",
                                top: "5%",
                                left: "2%",
                                right: "2%",
                                textAlign: "justify",
                              }}>
                              {getItem("lang") === "en"
                                ? v.attributeEng.split("\r\n").map(val => {
                                    return (
                                      <div key={val}>
                                        {val}
                                        <br />
                                      </div>
                                    );
                                  })
                                : v.attributeIna.split("\r\n").map(val => {
                                    return (
                                      <div key={val}>
                                        {val}
                                        <br />
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </td>
                    <td style={{ ...tdStyle, width: "10%" }}>
                      {data.vacancy_post.vacancySoftskills.find(
                        p => p.softskillHeaderId === val.id
                      ) ? (
                        data.vacancy_post.vacancySoftskills.find(
                          p => p.softskillDetailId === v.id
                        ) ? (
                          <img
                            src={IconConfirmationCheck}
                            alt={"check" + i}
                            height="24px"
                            align="center"
                          />
                        ) : null
                      ) : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          );
        })
      : null;
  }

  render() {
    const {
      menu,
      thinkingSkill,
      workingAttitude,
      relationshipSkill,
    } = this.state;
    return (
      <Grid container style={{ padding: "4% 6% 8%" }} className={"soft-skill"}>
        <Grid item lg={9} md={9} sm={9}>
          <Typography
            style={{
              fontSize: "24px",
              color: palette.primary,
            }}>
            Soft Skill
          </Typography>
        </Grid>

        <Grid item lg={3} md={3} sm={3}>
          <Button
            style={{
              border: "1px solid #515D6E",
              borderRadius: "4px",
              marginLeft: "30%",
            }}
            onClick={() => {
              this.props.onEdit();
            }}>
            <img
              style={{ padding: "10px 0px 12px 22px" }}
              src={IconEdit}
              alt={"Edit"}
              height="15vh"
            />
            <Typography
              style={{
                padding: "9px 22px 6px 11px",
                fontSize: "14px",
                color: "#515D6E",
                textTransform: "none",
              }}>
              Edit
            </Typography>
          </Button>
        </Grid>

        <Grid item lg={8} md={8} sm={8} style={{ marginTop: "4%" }}>
          <Grid
            container
            style={{
              borderBottom: "1px solid #DFE5F4",
            }}>
            <Grid item lg={4} md={4} sm={4}>
              <div
                style={menu === "ts" ? linkStyleActive : linkStyle}
                onClick={() => {
                  this.setState({ menu: "ts" });
                }}>
                Thinking Skill
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={4}>
              <div
                style={menu === "wa" ? linkStyleActive : linkStyle}
                onClick={() => {
                  this.setState({ menu: "wa" });
                }}>
                Working Attitude
              </div>
            </Grid>
            <Grid item lg={4} md={4} sm={4}>
              <div
                style={menu === "rs" ? linkStyleActive : linkStyle}
                onClick={() => {
                  this.setState({ menu: "rs" });
                }}>
                Relationship Skill
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={8} md={8} sm={8} style={{ marginTop: "3%" }}>
          <table style={tableStyle}>
            {menu === "ts"
              ? thinkingSkill
              : menu === "wa"
              ? workingAttitude
              : relationshipSkill}
          </table>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(SoftSkillConfirmation);
