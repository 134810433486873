import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
// import { withTranslation } from "react-i18next";
import { palette } from "assets/css/main";
import { NextBtn } from "../general/ButtonNext";
export default class RecruiterFooter extends React.Component {
  constructor(props) {
    super(props);
    this.translate = this.props.t;
  }

  nextPage(next) {
    // console.log(next);
    // if (this.props.nextHref === true) {
    //   window.location.href = next;
    // } else {
    //   this.props.history.push(next);
    // }

    this.props.history.push(next);
  }

  nextClick(next, value, maxStep) {
    // action to api
    if (this.props.action === true) {
      this.props.onFinish();
    } else {
      if (typeof value !== 'undefined') {
        var newValue = value + 1;
  
        // check if it's the last page
        if (newValue === maxStep) {
          this.props.onFinish();
        } else {
          this.props.stepperData(newValue);
        }
      } else {
        this.nextPage(next);
      }
    }
  }

  backClick(back, value) {
    if (typeof value !== 'undefined') {
      var newValue = value - 1;

      // check if it's the first page
      if (value === 0) {
        this.props.history.push(back);
      } else {
        this.props.stepperData(newValue);
      }
    } else {
      this.props.history.push(back);
    }
  }

  render() {
    //const { t, style, backLabel, backTo, nextTo, stepperValue } = this.props;
    return (
      <footer style={{ ...this.props.style, 
        backgroundColor: palette.primary,
        paddingLeft: "100px",
        paddingRight: "120px",
        height: 60,
        position: "fixed",
        bottom: "0",
      }}>
        <Grid
          container
          alignItems="center"
        >
          <Grid item lg={6} md={6} sm={6}>
            <Link
              to="#"
              style={{ color: "white", fontSize: "24px", textDecorationLine: "underline", fontWeight: "bolder" }}
              onClick={() => {
                this.backClick(this.props.backTo, this.props.stepperValue);
              }}
            >
              {this.props.backLabel}
            </Link>
          </Grid>
          <Grid item lg={6} md={6} sm={6} >
            <NextBtn 
              style={{float: "right", paddingRight: 170}}
              color="white"
              text={this.props.nextLabel ? this.props.nextLabel : "Next"}
              onClick={() => {
                this.nextClick(this.props.nextTo, this.props.stepperValue, this.props.maxStep)
              }}
            />
          </Grid>
        </Grid>
      </footer>
    );
  }
}