import React from "react";
import { AppBar, Toolbar, Grid } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import LinkGroup from "./LinkGroup";
import Step from "./StepHeader";
import { HeaderLogo } from "..";
class PrimaryAppBar extends React.Component {
  render() {
    const {
      shadow,
      linkgroup,
      stepper,
      activeStep,
      dashboardRecruiter,
      stepperData,
      stepWidth,
    } = this.props;
    return (
      <div>
        <AppBar
          position="absolute"
          color="default"
          style={{
            display: "flex",
            backgroundColor: "white",
            top: 0,
            height: 80,
            bottom: "auto",
            position: "fixed",
            boxShadow: !shadow ? "none" : "0px 2px 2px rgba(0, 0, 0, 0.09)",
            justifyContent: "center",
          }}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}>
              <Grid item lg={2} md={2}>
                <HeaderLogo style={{ marginLeft: 100 }} />
              </Grid>
              <Grid item lg={10} md={10}>
                <Grid container justify="flex-end" alignItems="center">
                  {linkgroup && <LinkGroup />}
                  {stepper && (
                    <Step
                      data={stepperData}
                      activeStep={activeStep}
                      stepWidth={stepWidth}
                    />
                  )}
                  {dashboardRecruiter && <LinkGroup />}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
export default withTranslation()(PrimaryAppBar);
