import React from "react";
import { withTranslation } from "react-i18next";
import MyHelmet from "app/components/header/MyHelmet";
import RecruiterFooter from "app/components/footer/RecruiterFooter";
import RecruiterAppBar from "app/components/header/RecruiterAppBar";
import { palette } from "assets/css/main";
import { Grid } from "@material-ui/core";
import data from "./data";
import StepHeader from "app/components/header/StepHeader";
import { ConfirmationStep } from "app/components";
import VacancyDetailConfirmation from "./confirmation/vacancy-detail-confirmation";
import VacancyDetailEdit from "./confirmation/vacancy-detail-edit";
import JobRequirementConfirmation from "./confirmation/job-requirement-confirmation";
import JobRequirementEdit from "./confirmation/job-requirement-edit";
import SoftSkillConfirmation from "./confirmation/soft-skill-confirmation";
import SoftSkillEdit from "./confirmation/soft-skill-edit";
import { GetLocalItem, RequestPost, SetLocalItem } from "app/utils";
import alertData from "app/components/alert/alert-data";

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    if (GetLocalItem("vacancyPost")) {
      data.vacancy_post = GetLocalItem("vacancyPost");
    }

    this.state = {
      vacancyPost: data.vacancy_post,
      thinkingSkill: [],
      workingAttitude: [],
      relationshipSkill: [],
      confirmationStep: "VacancyDetail",
      edit: false,
    };
    this.translate = this.props.t;
  }

  postVacancy(obj, nextPage) {
    // console.log(obj);
    RequestPost("vacancy", obj)
      .then(res => {
        // AppHistory.push(nextPage);
        window.location.href = nextPage;
      })
      .catch(err => {
        console.log(err);
        alertData.show(String(err.status), "error");
      });
  }

  componentDidMount() {}

  render() {
    const { t } = this.props;
    const { edit, confirmationStep, vacancyPost } = this.state;
    const stepData = t("vacancy:create.step", { returnObjects: true });

    return (
      <div
        key="ss-1"
        className="RecruiterVacancy font-hurme"
        style={{
          position: "relative",
        }}>
        <MyHelmet title="Dashboard" />
        <RecruiterAppBar
          shadow={true}
          menu="dashboard"
          history={this.props.history}
        />
        <StepHeader
          data={stepData}
          stepWidth={650}
          activeStep={3}
          isAZ={true}
          style={{
            padding: "0px 100px",
            marginTop: 80,
            backgroundColor: palette.info,
          }}
        />
        <Grid container style={{ minHeight: "100vh" }}>
          <Grid item lg={3} md={3} sm={3}>
            <ConfirmationStep
              data={confirmationStep}
              onChange={v => {
                this.setState({ confirmationStep: v });
              }}
            />
          </Grid>
          <Grid item lg={9} md={9} sm={9}>
            {edit === true ? (
              <>
                {confirmationStep === "VacancyDetail" ? (
                  <VacancyDetailEdit
                    data={vacancyPost}
                    onCancel={() => {
                      this.setState({ edit: false });
                    }}
                    onSave={obj => {
                      SetLocalItem("vacancyPost", obj);
                      this.setState({ vacancyPost: obj });
                      data.vacancy_post = obj;
                      this.setState({ edit: false });
                    }}
                  />
                ) : confirmationStep === "JobRequirement" ? (
                  <JobRequirementEdit
                    data={vacancyPost}
                    onCancel={() => {
                      this.setState({ edit: false });
                    }}
                    onSave={obj => {
                      SetLocalItem("vacancyPost", obj);
                      this.setState({ vacancyPost: obj });
                      data.vacancy_post = obj;
                      this.setState({ edit: false });
                    }}
                  />
                ) : (
                  <SoftSkillEdit
                    data={vacancyPost}
                    onCancel={() => {
                      this.setState({ edit: false });
                    }}
                    onSave={obj => {
                      SetLocalItem("vacancyPost", obj);
                      this.setState({ vacancyPost: obj });
                      data.vacancy_post = obj;
                      this.setState({ edit: false });
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {confirmationStep === "VacancyDetail" ? (
                  <VacancyDetailConfirmation
                    data={vacancyPost}
                    onEdit={() => {
                      this.setState({ edit: true });
                    }}
                  />
                ) : confirmationStep === "JobRequirement" ? (
                  <JobRequirementConfirmation
                    data={vacancyPost}
                    onEdit={() => {
                      this.setState({ edit: true });
                    }}
                  />
                ) : (
                  <SoftSkillConfirmation
                    onEdit={() => {
                      this.setState({ edit: true });
                    }}
                  />
                )}
              </>
            )}
            <RecruiterFooter
              style={{ width: "75%" }}
              history={this.props.history}
              nextTo="/recruiter/home"
              backTo={
                data.vacancy_post.vacancy.vacancyType === "Copy Existing"
                  ? "/recruiter/vacancy-detail"
                  : "/recruiter/soft-skill"
              }
              backLabel={t("vacancy:create.footer.back")}
              nextLabel="Submit"
              nextHref={true}
              action={true}
              onFinish={() => this.postVacancy(vacancyPost, "/recruiter/home")}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(Confirmation);
