import { observable, decorate, action } from "mobx";
class CompanyProfileContainer {
  companyProfile = {
    companyCode: "",
    companyNameBrand: "",
    companyNameLegal: "",
    companyAddress: "",
    website: "",
    established: "",
    companyType: "",
    businessType: "",
    businessSubType: "",
    aboutUs: "",
    companyLogo: "",
    picIdentityCard: null,
    siup: null,
    specifyOthers: "",
  };
  additionalInfo = {
    numberOfEmployee: "",
    numberOfBranches: null,
    companyAsset: null,
    companyRevenue: null,
    urgentHiring: "",
    projectYear: "",
  };
  companyBusinessType = [];
  recruiterPersonalInfo = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    recruiterType: "",
    positionType: "",
  };

  setCompanyProfile(new_data) {
    this.companyProfile = { ...this.companyProfile, ...new_data };
  }
  setadditionalinfo(new_data) {
    this.additionalInfo = { ...this.additionalInfo, ...new_data };
  }
  setrecruiterPersonalInfo(new_data) {
    this.recruiterPersonalInfo = { ...this.recruiterPersonalInfo, ...new_data };
  }
}

const companyProfileContainer = decorate(CompanyProfileContainer, {
  companyProfile: observable,
  additionalInfo: observable,
  recruiterPersonalInfo: observable,
  setCompanyProfile: action,
  setadditionalinfo: action,
  setrecruiterPersonalInfo: action,
});

export default new companyProfileContainer();
