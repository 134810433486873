import React from "react";
import prop from "prop-types";
/**
 * @extends {React.Component<{size:prop.number, url:prop.string}>}
 */
class ImageCircle extends React.Component {
  static defaultProps = {
    size: 100,
    url: "",
  };
  static propTypes = {
    size: prop.number,
    url: prop.string,
  };

  render() {
    const { size, url } = this.props;
    return (
      <div
        style={{
          height: size,
          width: size,
          borderRadius: "50%",
          backgroundImage: "url(" + url + ")",
          backgroundSize: "cover",
          backgroundRepeat: "none",
        }}></div>
    );
  }
}

export default ImageCircle;
