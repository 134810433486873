import React from "react";
import { palette } from "assets/css/main";
import { DatePicker, DialogueFull } from "app/components";
import { withTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { IcnOfferingPdf, IcnCalendarSmall } from "assets/img";
import { RequestPut } from "app/utils";
import alertData from "app/components/alert/alert-data";

class DialogueCandidateJoinDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      statelistcandidates: this.props.listcandidate,
      stateopen: this.props.open,
      isEnable: true,
      listJoinDate: [],
    };
  }

  handleChange(obj, index) {
    var joindate = obj;
    var list = this.state.statelistcandidates;

    list[index].joindate = joindate;

    for (var i = 0; i < list.length; i += 1) {
      if (list[i].joindate === "") {
        this.setState({
          isEnable: true,
        });
      } else {
        this.setState({
          isEnable: false,
        });
      }
    }

    this.setState({
      statelistcandidates: list,
    });
  }

  submitJoinDate(vacancyId) {
    const { onClose, t } = this.props;
    var status = "HIR";
    var listUser = [];
    for (var i = 0; i < this.state.statelistcandidates.length; i += 1) {
      listUser.push({
        candidateId: this.state.statelistcandidates[i].userId,
        joinDate: this.state.statelistcandidates[i].joindate,
      });
    }

    RequestPut("vacancy/candidate", {
      vacancyId,
      status,
      listUser,
      loading: true,
    })
      .then(() => {
        alertData.show(t("messages.successMoved"), "success");
        onClose(true);
      })
      .catch(e => {
        alertData.show(t("messages.successMoved"), "error");
        console.log("submitJoinDate()", e);
      });
  }

  render() {
    const { open, onClose, vacancyId, t } = this.props;
    const { isLoading, statelistcandidates, isEnable } = this.state;

    return (
      <DialogueFull
        open={open}
        onClose={() => {
          onClose(false);
        }}>
        {isLoading ? (
          <div>
            <div
              style={{
                backgroundColor: "white",
                width: 900,
                boxSizing: "border-box",
                borderRadius: 4,
              }}>
              <div
                style={{
                  height: 20,
                  backgroundColor: palette.primary,
                  padding: "20px 20px",
                  color: "white",
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                  fontSize: 18,
                }}>
                {t("candidates:CandidateJoinDateDialog.title")}
              </div>
              <div
                style={{
                  padding: "20px 50px",
                  height: "100%",
                }}>
                <div style={{ marginBottom: 30 }}>
                  <p
                    style={{
                      marginBottom: 5,
                      fontSize: 18,
                      color: palette.primary,
                    }}>
                    {t("candidates:CandidateJoinDateDialog.content")}
                  </p>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "50%" }}>
                    <p
                      style={{
                        fontSize: 18,
                        margin: 0,
                        textTransform: "capitalize",
                        color: "#B0B7BD",
                        fontWeight: "lighter",
                      }}>
                      {t("candidates:CandidateJoinDateDialog.candidate")}
                    </p>
                  </div>
                  <div style={{ width: "45%" }}>
                    <p
                      style={{
                        fontSize: 18,
                        margin: 0,
                        textTransform: "capitalize",
                        color: "#B0B7BD",
                        fontWeight: "lighter",
                      }}>
                      {t("candidates:CandidateJoinDateDialog.joindate")}
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    className="list-data"
                    style={{
                      width: "100%",
                      marginTop: "1%",
                    }}>
                    {statelistcandidates.map((v, k) => {
                      var filenameoffering = "";
                      if (v.offeringFile === null) {
                        filenameoffering = "";
                      } else {
                        var filename = v.offeringFile.split("/");
                        filenameoffering = filename[6];
                      }
                      return (
                        <table
                          key={"list-candidate-" + k}
                          className="item"
                          style={{
                            padding: "0px 0px 8px 0px",
                            width: "100%",
                            backgroundColor: "white",
                            boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
                            borderRadius: 4,
                            marginTop: 5,
                            display: "flex",
                            flexDirection: "row",
                          }}>
                          <tbody
                            style={{
                              backgroundColor: "#F8F8F8",
                              width: "45%",
                            }}>
                            <tr>
                              <td>
                                <div
                                  style={{
                                    position: "relative",
                                    paddingLeft: 7,
                                    paddingTop: 8,
                                    display: "flex",
                                  }}>
                                  <div
                                    style={{
                                      left: 10,
                                      zIndex: 1,
                                      width: 74,
                                      height: 74,
                                      borderRadius: "50%",
                                      backgroundColor: "grey",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      backgroundImage: v.photo
                                        ? "url(" + v.photo + ")"
                                        : "",
                                    }}></div>
                                  <p
                                    style={{
                                      margin: 0,
                                      paddingTop: 10,
                                      color: palette.primary,
                                      paddingLeft: 20,
                                    }}>
                                    {v.name}
                                    <span
                                      style={{ textTransform: "capitalize" }}>
                                      <img
                                        style={{
                                          marginRight: 5,
                                          marginBottom: -3,
                                          display:
                                            v.offeringFile !== null ||
                                            v.offeringFile === ""
                                              ? "unset"
                                              : "none",
                                        }}
                                        src={IcnOfferingPdf}
                                        alt="Offering Icon"
                                      />
                                      {filenameoffering}
                                    </span>
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody
                            style={{
                              backgroundColor: "white",
                              width: "5%",
                            }}></tbody>
                          <tbody
                            style={{
                              backgroundColor: "#F8F8F8",
                              width: "50%",
                            }}>
                            <tr>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: 90,
                                  }}>
                                  <DatePicker
                                    className="JoinDate"
                                    label={""}
                                    format="dd/MM/yyyy"
                                    style={{ width: 354 }}
                                    value={v.joindate}
                                    minDate={new Date()}
                                    onChange={e => {
                                      this.handleChange(e, k);
                                    }}
                                  />
                                  <img
                                    style={{
                                      marginRight: 5,
                                      marginBottom: -3,
                                      display: "unset",
                                    }}
                                    src={IcnCalendarSmall}
                                    alt="Calendar Icon"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  bottom: 0,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  backgroundColor: "white",
                  boxSizing: "border-box",
                  width: "100%",
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
                  padding: "20px 50px",
                }}>
                <Button
                  onClick={() => {
                    this.submitJoinDate(vacancyId);
                  }}
                  disabled={isEnable}
                  style={{
                    backgroundColor: palette.primary,
                    opacity: !isEnable ? 1 : 0.5,
                    color: "white",
                    padding: "8px 50px",
                  }}>
                  OK
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </DialogueFull>
    );
  }
}
export default withTranslation("candidates")(DialogueCandidateJoinDate);
