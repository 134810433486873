import React from "react";
import { withTranslation } from "react-i18next";
import { AppBar, Toolbar, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  loadImage,
  getItem,
  RequestGet,
  RequestPost,
  AUTH_API,
} from "app/utils";
import { IconConfirmationCheck, IcnFailedFilledNew } from "assets/img";
import { palette } from "assets/css/main";
import { NextBtn, HeaderNologin } from "app/components";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { isMobile } from "react-device-detect";

class ForceLogout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "/user/login/applicant",
      status: this.props.location.search ? true : false,
      successContent: "",
      failedContent: "",
      emailPlaceholder: "",
      load: false,
    };
    this.requestLogout = this.requestLogout.bind(this);
  }
  componentDidMount() {
    this.getKey();
  }
  getKey() {
    if (this.props.location.search) {
      const { t } = this.props;
      var params = new URLSearchParams(this.props.location.search);
      var successContent = t("auth:forceLogout.successContent");
      var failedContent = t("auth:forceLogout.successContent");
      var emailPlaceholder = t("auth:placeholder.email");
      if (params.get("role") === "Applicant") {
        this.setState({
          link: "/user/login/applicant",
          successContent: successContent,
          failedContent: failedContent,
        });
      } else {
        successContent =
          getItem("lang") === "en"
            ? t("auth:forceLogout.successContent")
            : t("auth:forceLogout.successContent_ap");
        failedContent =
          getItem("lang") === "en"
            ? t("auth:forceLogout.failedContent")
            : t("auth:forceLogout.failedContent_ap");
        emailPlaceholder =
          getItem("idRole") === "r"
            ? t("auth:placeholder.email")
            : getItem("lang") === "en"
            ? t("auth:placeholder.email")
            : t("auth:placeholder.email_ap");
        this.setState({
          link: "/user/login/applicant",
          successContent: successContent,
          failedContent: failedContent,
          emailPlaceholder: emailPlaceholder,
        });
      }
      //check params
      if (params.get("userId") && params.get("code")) {
        //validating
        this.check(params.get("userId"), params.get("code"));
      } else {
        this.setState({ load: true, status: false });
      }
    } else {
      this.setState({ load: true, status: false });
    }
  }
  check(userId, code) {
    const data = { userId: userId, code: code };
    RequestPost(AUTH_API + "connect/forcelogout", data)
      .then(() => {
        this.setState({ load: true, status: true });
      })
      .catch(e => {
        this.setState({ load: true, status: false });

        console.log("applicant-force-logout.js > check()", e);
      });
  }
  requestLogout(event) {
    event.preventDefault();
    RequestGet(AUTH_API + "connect/sendforcelogout?email=" + this.state.email)
      .then(res => {
        this.setState({ open: true });
      })
      .catch(e => {
        if (e) {
          this.setState({ error: e.data.messages[0] });
        } else {
          this.setState({ error: "error" });
        }
      });
  }
  render() {
    const { t } = this.props;
    const {
      load,
      status,
      successContent,
      failedContent,
      emailPlaceholder,
      link,
    } = this.state;
    if (isMobile) {
      return (
        <div>
          <HeaderNologin />
          <main style={{ paddingTop: 90 }} className="validate-mobile">
            {load && status && (
              <Grid style={{ textAlign: "center", padding: "0px 20px" }}>
                <img
                  src={IconConfirmationCheck}
                  alt="Success"
                  width={90}
                  height={90}
                />
                <Typography
                  style={{
                    fontSize: 24,
                    marginBottom: 24,
                    paddingTop: 56,
                    fontWeight: "bold",
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {t("auth:forceLogout.successTitle")}
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    marginBottom: 24,
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {successContent}
                </Typography>
                <NextBtn
                  text={t("auth:forceLogout.linkLogin")}
                  onClick={() => {
                    window.location.href = link;
                  }}
                />
              </Grid>
            )}

            {load && !status && (
              <Grid style={{ textAlign: "center", padding: "0px 20px" }}>
                <img
                  src={IcnFailedFilledNew}
                  alt="Failed"
                  width={90}
                  height={90}
                />
                <Typography
                  style={{
                    fontSize: 24,
                    marginBottom: 24,
                    paddingTop: 56,
                    fontWeight: "bold",
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {t("auth:forceLogout.failedTitle")}
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    marginBottom: 20,
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {failedContent}
                </Typography>
                <ValidatorForm onSubmit={this.requestLogout}>
                  <TextValidator
                    autoFocus={true}
                    onChange={e => {
                      this.setState({ email: e.target.value, error: "" });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Email"
                    className="Field"
                    placeholder={emailPlaceholder}
                    name="email"
                    value={this.state.email}
                    style={{ marginTop: 20, width: "70%" }}
                    autoComplete="username"
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      t("auth:validation.required"),
                      t("auth:validation.email"),
                    ]}
                  />
                  <p style={{ color: "red" }}>{this.state.error}</p>
                  <NextBtn
                    type="submit"
                    text={t("auth:requestLogout.btn")}
                    style={{ width: "100%", display: "inherit" }}
                  />
                </ValidatorForm>
              </Grid>
            )}
          </main>
        </div>
      );
    } else {
      return (
        <div>
          <AppBar
            position="absolute"
            color="default"
            style={{
              backgroundColor: "white",
              top: 0,
              height: 80,
              bottom: "auto",
              position: "fixed",
            }}>
            <Toolbar>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ height: 80 }}>
                <Grid item lg={2}>
                  <Link to="/" className="centered">
                    <img
                      alt="Hiregrade-Logo"
                      src={loadImage("HiregradeLogo.png")}
                      width={150}
                      style={{ marginLeft: 87 }}
                    />
                  </Link>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <main style={{ paddingTop: 210 }} className="validate">
            {load && status && (
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column">
                <img
                  src={IconConfirmationCheck}
                  alt="Success"
                  width={71}
                  height={71}
                />
                <Typography
                  style={{
                    fontSize: 24,
                    width: 450,
                    marginBottom: 24,
                    paddingTop: 56,
                    fontWeight: "bold",
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {t("auth:forceLogout.successTitle")}
                </Typography>
                <Typography
                  style={{
                    fontSize: 18,
                    width: 400,
                    marginBottom: 180,
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {successContent}
                </Typography>
                <NextBtn
                  text={t("auth:forceLogout.linkLogin")}
                  onClick={() => {
                    window.location.href = link;
                  }}
                />
              </Grid>
            )}

            {load && !status && (
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="column">
                <img
                  src={IcnFailedFilledNew}
                  alt="Failed"
                  width={71}
                  height={71}
                />
                <Typography
                  style={{
                    fontSize: 24,
                    width: 450,
                    marginBottom: 24,
                    paddingTop: 56,
                    fontWeight: "bold",
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {t("auth:forceLogout.failedTitle")}
                </Typography>
                <Typography
                  style={{
                    fontSize: 18,
                    width: 400,
                    marginBottom: 100,
                    color: palette.primary,
                    textAlign: "center",
                  }}>
                  {failedContent}
                </Typography>
                <ValidatorForm onSubmit={this.requestLogout}>
                  <TextValidator
                    autoFocus={true}
                    onChange={e => {
                      this.setState({ email: e.target.value, error: "" });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Email"
                    className="Field"
                    placeholder={emailPlaceholder}
                    name="email"
                    value={this.state.email}
                    style={{ width: 500, marginTop: 20 }}
                    autoComplete="username"
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      t("auth:validation.required"),
                      t("auth:validation.email"),
                    ]}
                  />
                  <p style={{ color: "red" }}>{this.state.error}</p>
                  <NextBtn
                    type="submit"
                    text={t("auth:requestLogout.btn")}
                    style={{ width: "100%", display: "inherit" }}
                  />
                </ValidatorForm>
              </Grid>
            )}
          </main>
        </div>
      );
    }
  }
}
export default withTranslation()(ForceLogout);
