import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { palette } from "assets/css/main";
import { getItem } from "app/utils";
import {
  WaterMark,
  HeaderLogoPDF,
} from "app/pages/recruiter/candidates/download-info/ci-styles";
const styles = StyleSheet.create({
  page: {
    width: "100%",
    height: "100%",
    paddingBottom: 50,
    paddingTop: 105,
  },
  sectionLeft: {
    height: "100%",
    width: "35%",
    padding: "10pt 10pt",
    fontWeight: "lighter",
    display: "flex",
    backgroundColor: palette.info,
    flexDirection: "column",
    alignItems: "center",
  },
  listContent: {
    width: "84%",
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "-2pt 30pt 10pt 30pt",
    backgroundColor: "#f2f2f2",
    left: 50,
  },
  listSoftSkill: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
    left: 50,
  },
  headerItem: {
    fontSize: 16,
    fontWeight: "bolder",
    color: palette.primary,
    padding: "0pt 50pt",
  },
  categoryName: {
    color: "#000000",
    fontSize: 10,
    fontWeight: "bold",
    top: 5,
    left: -16,
  },
  softSkillName: {
    width: "65%",
    color: "#000000",
    fontSize: 10,
    fontWeight: "bold",
    left: 16,
    marginTop: "5pt",
    marginBottom: "5pt",
  },
  softSkillDesc: {
    color: "#000000",
    opacity: "0.5",
    fontSize: 8,
    fontWeight: "bold",
    left: 16,
    marginBottom: "5pt",
  },
  softskillValue: {
    color: "#000000",
    fontSize: 10,
    fontWeight: "bold",
    left: 16,
    marginTop: "5pt",
    marginBottom: "5pt",
  },
  softskillValueAttribute: {
    color: "#000000",
    opacity: "0.5",
    fontSize: 8,
    fontWeight: "bold",
    left: 16,
    marginBottom: "5pt",
  },
  titleItem: { fontSize: 20, fontWeight: "bold" },
  titleContent: { fontSize: 18, textTransform: "uppercase" },
  item: { fontSize: 12, padding: "5pt 0pt 0pt 0pt" },
  vacancyDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    position: "absolute",
    left: -6,
    top: 4,
    backgroundColor: palette.primary,
  },
  candidateDot: {
    width: 10,
    height: 10,
    borderRadius: 5,
    position: "absolute",
    left: -6,
    top: 4,
    backgroundColor: "#52C8F4",
  },
  elipseJob: {
    height: 20,
    borderRadius: 10,
    margin: "0pt 0pt 10pt",
    backgroundColor: palette.primary,
  },
  elipseCandidate: {
    height: 20,
    borderRadius: 10,
    margin: "0pt 0pt 10pt",
    backgroundColor: "#52C8F4",
  },
  elipseProximity: {
    width: 50,
    height: 20,
    borderRadius: 10,
    position: "absolute",
    left: -2,
    top: 92,
    backgroundColor: "#2F3168",
    textAlign: "center",
  },
  test: {
    borderBottomColor: "#E1E7EE",
    borderBottomWidth: 10,
  },
});

export const onRenderDocument = ({ blob }, filename) => {
  var blobUrl = URL.createObjectURL(blob);
  saveDocument(blobUrl, filename);
};

export const saveDocument = (function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (blob, fileName) {
    a.href = blob;
    a.id = "download-proximity";
    a.download = fileName;
  };
})();

const SoftSkillCategoryView = props => {
  return (
    <View
      className="softSkillCategory"
      style={{
        display: props.data.softSkillCategory.length > 0 ? "" : "none",
        width: "100%",
      }}>
      <View style={{ padding: "10pt 0pt" }}>
        {props.data.softSkillCategory.map((val, i) => {
          var numberCategory = 1;
          return (
            <View key={i} style={{ width: "100%" }}>
              <View
                style={{
                  width: "100%",
                  padding: "5pt 55pt",
                  display: "flex",
                  flexDirection: "row",
                }}
                break>
                <View
                  style={{
                    width: "23%",
                  }}>
                  <View style={styles.vacancyDot} />
                  <Text
                    style={{
                      fontSize: 9,
                      padding: "4pt 10pt",
                    }}>
                    {props.t(
                      "candidates:CandidateJobProximityDialog.vacancyProfile"
                    )}
                  </Text>
                </View>
                <View
                  style={{
                    width: "23%",
                  }}>
                  <View style={styles.candidateDot} />
                  <Text
                    style={{
                      fontSize: 9,
                      padding: "4pt 10pt",
                    }}>
                    {props.t(
                      "candidates:CandidateJobProximityDialog.candidateProfile"
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.listContent}>
                <Text style={styles.categoryName}>
                  {numberCategory + i}
                  {". "}
                  {getItem("lang") === "en"
                    ? val.categoryNameEng
                    : val.categoryNameIna}
                </Text>
              </View>
              <View
                style={{
                  display: val.softSkillHeaders.length > 0 ? "" : "none",
                }}>
                <View style={{}}>
                  {val.softSkillHeaders.map((valsoftSkillHeaders, j) => {
                    return (
                      <View key={j} style={styles.listSoftSkill} break>
                        <View
                          style={{
                            width: "63%",
                            borderBottom: "1pt solid #E1E7EE",
                            borderRight: "1pt solid #E1E7EE",
                          }}>
                          <Text style={styles.softSkillName}>
                            {getItem("lang") === "en"
                              ? valsoftSkillHeaders.softskillNameEng
                              : valsoftSkillHeaders.softskillNameIna}
                          </Text>
                          <View style={{ width: "80%" }}>
                            <Text style={styles.softSkillDesc}>
                              {getItem("lang") === "en"
                                ? valsoftSkillHeaders.softskillDescEng
                                : valsoftSkillHeaders.softskillDescIna}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}>
                          {valsoftSkillHeaders.softSkillDetails.map(
                            (valsoftSkillDetails, k) => {
                              return (
                                <View
                                  key={k}
                                  style={{
                                    width: "90%",
                                    display: "flex",
                                    flexDirection: "row",
                                    borderRight: "1pt solid #E1E7EE",
                                  }}>
                                  <View
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: "100%",
                                      borderBottom: "1pt solid #E1E7EE",
                                    }}>
                                    <View
                                      style={{
                                        width: "90%",
                                      }}>
                                      <Text style={styles.softskillValue}>
                                        {getItem("lang") === "en"
                                          ? valsoftSkillDetails.softskillValueEng
                                          : valsoftSkillDetails.softskillValueIna}
                                      </Text>
                                      <View style={{ width: "78%" }}>
                                        <Text
                                          style={
                                            styles.softskillValueAttribute
                                          }>
                                          {getItem("lang") === "en"
                                            ? valsoftSkillDetails.attributeEng
                                            : valsoftSkillDetails.attributeIna}
                                        </Text>
                                      </View>
                                    </View>
                                    <View
                                      style={{
                                        width: "65%",
                                        display: "flex",
                                        padding: "10pt 0pt 10pt 30pt",
                                        borderBottom: "1pt solid #E1E7EE",
                                      }}>
                                      <View
                                        style={{
                                          ...styles.elipseJob,
                                          width:
                                            valsoftSkillDetails.jobValue ===
                                            "LOW"
                                              ? 40
                                              : valsoftSkillDetails.jobValue ===
                                                "MEDIUM"
                                              ? 60
                                              : valsoftSkillDetails.jobValue ===
                                                "HIGH"
                                              ? 100
                                              : 0,
                                          textAlign:
                                            valsoftSkillDetails.jobValue ===
                                            "LOW"
                                              ? "center"
                                              : "left",
                                        }}>
                                        <Text
                                          style={{
                                            color: "white",
                                            fontSize: 8,
                                            top: 5,
                                            left:
                                              valsoftSkillDetails.jobValue !==
                                              "LOW"
                                                ? 13
                                                : 0,
                                          }}>
                                          {valsoftSkillDetails.jobValue}
                                        </Text>
                                      </View>
                                      <View
                                        style={{
                                          ...styles.elipseCandidate,
                                          width:
                                            valsoftSkillDetails.candidateValue ===
                                            "LOW"
                                              ? 40
                                              : valsoftSkillDetails.candidateValue ===
                                                "MEDIUM"
                                              ? 60
                                              : valsoftSkillDetails.candidateValue ===
                                                "HIGH"
                                              ? 100
                                              : 0,
                                          textAlign:
                                            valsoftSkillDetails.candidateValue ===
                                            "LOW"
                                              ? "center"
                                              : "left",
                                        }}>
                                        <Text
                                          style={{
                                            color: "white",
                                            fontSize: 8,
                                            top: 5,
                                            left:
                                              valsoftSkillDetails.candidateValue !==
                                              "LOW"
                                                ? 13
                                                : 0,
                                          }}>
                                          {valsoftSkillDetails.candidateValue}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </View>
                              );
                            }
                          )}
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
              <View break />
            </View>
          );
        })}
      </View>
    </View>
  );
};

const TemplateCandidateJobProximity = props => (
  <Document
    title={"Job Proximity - " + props.data.candidate.name}
    author="hiregrade"
    creator="hiregrade"
    producer="hiregrade.id">
    <Page size="A4" style={styles.page} wrap>
      <WaterMark />
      <HeaderLogoPDF />
      <View>
        <View
          style={{
            width: "100%",
            padding: "5pt 50pt",
          }}>
          <Text style={styles.titleItem}>{props.t("Job Proximity")}</Text>
          <Text
            style={{
              fontSize: 10,
              padding: "10pt 0pt",
              color: "#000000",
              opacity: "0.5",
            }}>
            {props.t("candidates:tooltipmessages.proximity")}
          </Text>
        </View>
        <View
          style={{
            width: "100%",
            padding: "5pt 50pt",
            display: "flex",
            flexDirection: "row",
          }}>
          <View
            style={{
              width: "28%",
            }}>
            <Image
              src={props.data.candidate.photo + "?t=" + new Date().getTime()}
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: 120,
                height: 118,
                padding: "10pt 0pt",
              }}
              allowDangerousPaths
            />
          </View>
          <View
            style={{
              width: "72%",
              display: "flex",
              flexDirection: "row",
            }}>
            <View
              style={{
                width: "30%",
              }}>
              <Text
                style={{
                  fontSize: 10,
                  padding: "10pt 0pt",
                }}>
                {props.t("candidates:CandidateJobProximityDialog.name")}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                }}>
                {props.t("candidates:CandidateJobProximityDialog.candidateId")}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  padding: "10pt 0pt",
                }}>
                {props.t("candidates:CandidateJobProximityDialog.status")}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                }}>
                {props.t("candidates:CandidateJobProximityDialog.jobTitle")}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  padding: "10pt 0pt",
                }}>
                {props.t("candidates:CandidateJobProximityDialog.jobProximity")}
              </Text>
            </View>
            <View>
              {props.data.candidate.status === "SEL" && (
                <Text
                  style={{
                    fontSize: 10,
                    padding: "10pt 0pt",
                  }}>
                  {"-"}
                </Text>
              )}
              {props.data.candidate.status !== "SEL" && (
                <Text
                  style={{
                    fontSize: 10,
                    padding: "10pt 0pt",
                  }}>
                  {props.data.candidate.name}
                </Text>
              )}
              <Text
                style={{
                  fontSize: 10,
                }}>
                {props.data.candidate.candidateId}
              </Text>
              {props.data.candidate.status === "SEL" && (
                <Text
                  style={{
                    fontSize: 10,
                    padding: "10pt 0pt",
                  }}>
                  {"Selected"}
                </Text>
              )}
              {props.data.candidate.status === "INP" && (
                <Text
                  style={{
                    fontSize: 10,
                    padding: "10pt 0pt",
                  }}>
                  {"In Process"}
                </Text>
              )}
              {props.data.candidate.status === "PRO" && (
                <Text
                  style={{
                    fontSize: 10,
                    padding: "10pt 0pt",
                  }}>
                  {"Proposed"}
                </Text>
              )}
              {props.data.candidate.status === "HIR" && (
                <Text
                  style={{
                    fontSize: 10,
                    padding: "10pt 0pt",
                  }}>
                  {"Hired"}
                </Text>
              )}
              <Text
                style={{
                  fontSize: 10,
                }}>
                {props.data.candidate.jobTitle}
              </Text>
              <View style={styles.elipseProximity}>
                {props.data.candidate.proximity === null && (
                  <Text
                    style={{
                      color: "white",
                      fontSize: 10,
                      top: 5,
                    }}>
                    {"0"} %
                  </Text>
                )}
                {props.data.candidate.proximity !== null && (
                  <Text
                    style={{
                      color: "white",
                      fontSize: 10,
                      top: 5,
                    }}>
                    {props.data.candidate.proximity} %
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
      <View>
        <SoftSkillCategoryView data={props.data.candidate} t={props.t} />
      </View>
    </Page>
  </Document>
);
export default TemplateCandidateJobProximity;
