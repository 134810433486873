import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Tooltip, withStyles } from "@material-ui/core";
import { palette } from "assets/css/main";

class NotifBadge extends React.Component {
  render() {
    const { style } = this.props;
    return (
      <span
        style={{
          position: "absolute",
          top: 3,
          right: -3,
          backgroundColor: "#F34A38",
          width: 8,
          height: 8,
          borderRadius: "50%",
          ...style,
        }}></span>
    );
  }
}
class LoadingContentCandidate extends React.Component {
  render() {
    return (
      <table
        className="item"
        style={{
          padding: "20px 10px 30px 10px",
          width: "100%",
          backgroundColor: "white",
          boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.09)",
          borderRadius: 4,
        }}>
        <tbody>
          <tr>
            <th width="300" align="center">
              <div style={{ position: "relative", paddingLeft: 110 }}>
                <div
                  style={{
                    left: 10,
                    zIndex: 1,
                    position: "absolute",
                    width: 74,
                    height: 74,
                  }}>
                  <Skeleton variant="circle" width={74} height={54} />
                </div>
                <p style={{ margin: 0, paddingTop: 10 }}>
                  <Skeleton variant="text" />
                </p>
              </div>
            </th>
            <th width="150" align="center">
              <Skeleton variant="text" width={120} />
              <span>
                <Skeleton variant="text" width={100} />
              </span>
            </th>
            <th width="300" align="left">
              <Skeleton variant="text" width={200} />
              <span>
                <Skeleton variant="text" />
              </span>
            </th>
            <th width="100" align="left">
              <Skeleton variant="text" />
              <span>
                <Skeleton variant="text" />
              </span>
            </th>
            <th width="130" align="left">
              <Skeleton variant="text" />
              <span>
                <Skeleton variant="text" />
              </span>
            </th>
            <td width="120" align="left" valign="middle">
              <Skeleton variant="text" width={100} />
            </td>
            <td width="150" align="left" valign="middle">
              <Skeleton variant="text" width={120} />
            </td>
            <td width="100" align="center" valign="middle">
              <Skeleton variant="text" width={50} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: palette.primarydark,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  arrow: {
    color: palette.primarydark,
  },
}))(Tooltip);
export { NotifBadge, LoadingContentCandidate, HtmlTooltip };
