import React from "react";
import { palette } from "assets/css/main";
import { NextBtn } from "app/components";
import { LinearProgress } from "@material-ui/core";
import { withTranslation } from "react-i18next";

class InterestFooter extends React.Component {
  render() {
    const { text, current, total, progress, enable, t } = this.props;
    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          zIndex: 10,
          height: 60,
          padding: "0px 100px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          boxSizing: "border-box",
          width: "100vw",
          boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
        }}>
        <div
          style={{
            display: "flex",
            boxSizing: "border-box",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <LinearProgress
            style={{ width: 230, margin: "0px 10px" }}
            className={"Biodata freetext-progress"}
            variant="determinate"
            value={progress}
          />
        </div>
        <span style={{ color: "rgb(212, 213, 220)", paddingTop: 10 }}>
          {current}/{total} {t("progress.taskCompleted")}
        </span>
        <NextBtn
          validate={true}
          onClick={() => {
            this.props.onClick();
          }}
          fontSize={18}
          disabled={enable}
          underline={false}
          text={text}
          color="white"
          style={{
            backgroundColor: palette.primary,
            position: "absolute",
            padding: "7px 20px",
            right: 50,
          }}
        />
      </div>
    );
  }
}
InterestFooter.defaultProps = {
  onClick: () => {
    console.log("default click");
  },
};
export default withTranslation()(InterestFooter);
