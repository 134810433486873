import React from "react";
import { Button, LinearProgress } from "@material-ui/core";
import { palette } from "assets/css/main";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { NextBtn } from "../general/ButtonNext";
import { General, getItem, t } from "app/utils";
import PropTypes from "prop-types";

/**
 * @extends {React.Component<{onSubmit:Function, key:any, isLast:Function, reset:boolean, onReset:Function}>}
 */
class WizardForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    key: PropTypes.any,
    isLast: PropTypes.func,
    reset: PropTypes.bool,
    onReset: PropTypes.func,
  };
  static defaultProps = {
    onSubmit: () => {
      console.log("default submit");
    },
    key: new Date().getTime(),
    isLast: e => {
      return e;
    },
    reset: false,
    onReset: () => {
      return false;
    },
  };
  state = {
    buttonDisabled: true,
    current: 1,
    total: 1,
    progress: 0,
    error: false,
    custom_error: null,
  };
  backstep = false;

  constructor(props) {
    super(props);
    this.keyDown = this.keyDown.bind(this);
    this.keyUp = this.keyUp.bind(this);
  }
  TFNextBtn = undefined;
  getProgress() {
    this.TFNextBtn = document.getElementById("TFNextBtn");
    if (this.state.current === 1) {
      // this.TFNextBtn.classList.remove("Submit-Btn");
    } else {
      this.TFNextBtn.classList.add("Submit-Btn");
    }
    const { children } = this.props;
    const progress = (this.state.current / children.length) * 100;
    this.setState({ progress: progress });
  }

  setFocus() {
    setTimeout(() => {
      try {
        var show = document.getElementsByClassName("show-button");
        var el = document.getElementsByTagName("input");
        var ta = document.getElementsByTagName("textarea");
        var input = ta[0] ? ta[0] : el[0];
        if (show.length) {
          this.TFNextBtn.classList.remove("Submit-Btn");
        } else {
          if (input.name === "year" || input.readOnly) {
            var onlyear = document.getElementsByClassName("one");
            try {
              if (onlyear.length > 0) {
                this.backstep = false;
              } else {
                this.backstep = true;
              }
            } catch (er) {
              this.backstep = true;
            }
            this.backstep = true;

            var ex = el[0];
            ex.focus();
            if (ex.value) {
              this.TFNextBtn.classList.remove("Submit-Btn");
            }
          } else {
            this.backstep = false;
            input.focus();
            var val = input.value;
            input.value = "";
            input.value = val;
            if (input.value) {
              if (input.value.trim()) {
                this.TFNextBtn.classList.remove("Submit-Btn");
              } else {
                this.TFNextBtn.classList.add("Submit-Btn");
              }
            }
          }
        }
      } catch (error) {
        this.backstep = true;
      }
    }, 200);
  }

  nextNumber() {
    const { current } = this.state;
    const { children } = this.props;
    this.setState({ error: false });
    const content = children[current - 1];
    const error = this.validate(content);
    var e = document.getElementById("error-messages");
    var el = document.getElementsByClassName("MuiInput-underline");
    var label = document.getElementsByClassName("MuiInputLabel-root");
    const { skipAble, conditionValue, value } = content.props;
    var ce = document.getElementById("custom-error");

    if (skipAble) {
      if (conditionValue === value) {
        this.props.onSubmit(true);
        return;
      }
    }
    try {
      label[0].classList.remove("Mui-error");
      el[0].classList.remove("Mui-error");
      //skipable
    } catch (error) {
      // console.log(error);
    }

    if (error) {
      try {
        if (ce) {
          ce.innerText = error;
        } else {
          e.innerText = error;
        }
      } catch (error) {}

      try {
        label[0].classList.add("Mui-error");
        el[0].classList.add("Mui-error");
      } catch (error) {}
    } else if (current < children.length) {
      try {
        e.innerText = "";
      } catch (error) {}

      if (current === children.length - 1) {
        this.props.isLast(true);
        this.setState({ buttonDisabled: false });
      } else {
        this.props.isLast(false);
        this.setState({ buttonDisabled: true });
      }

      this.setState({ current: current + 1 }, () => {
        this.getProgress();
      });
    } else {
      if (!error) {
        this.props.onSubmit();
      }
    }

    this.setFocus();
  }

  prevNumber() {
    const { current } = this.state;
    var e = document.getElementById("error-messages");
    var ce = document.getElementById("custom-error");

    try {
      e.innerText = "";
      ce.innerText = "";
    } catch (error) {}
    this.props.isLast(false);
    if (current > 1) {
      this.setState({ current: current - 1, buttonDisabled: true }, () => {
        this.getProgress();
      });
    }
    this.getProgress();
    this.setFocus();
  }

  validate(object) {
    var result = null;
    if (object.props.type === "number") {
      if (Number.isNaN(object.props.value)) {
        result = object.props.helper
          ? object.props.helper
          : "Field must be number";
      }
      var help = object.props.helper ? object.props.helper : "Value not number";

      help = help.replace("MIN", object.props.minvalue);
      help = help.replace("MAX", object.props.maxvalue);

      if (
        object.props.minvalue !== undefined &&
        object.props.maxvalue !== undefined
      ) {
        result =
          object.props.minvalue <= parseFloat(object.props.value) &&
          object.props.maxvalue >= parseFloat(object.props.value)
            ? null
            : help;
      }
    }
    if (object.props.type === "email" && !result) {
      if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(object.props.value)
      ) {
        result = object.props.helper
          ? object.props.helper
          : "Email is not valid";
      }
    }
    if (object.props.required && !result) {
      if (object.props.value) {
        try {
          result = !object.props.value.trim()
            ? object.props.requiredHelper
              ? object.props.requiredHelper
              : "Field is required"
            : null;
        } catch (error) {
          result = null;
        }
      } else if (!object.props.value) {
        result = object.props.requiredHelper
          ? object.props.requiredHelper
          : "Field is required";
      } else if (!object.props.value.length) {
        result = object.props.requiredHelper
          ? object.props.requiredHelper
          : "Field is required";
      }
    }
    if (object.props.minLength && !result) {
      const en =
        object.props.label +
        " must have at least " +
        object.props.minLength +
        " characters";
      const id =
        object.props.label +
        " minimal terdiri dari" +
        object.props.minLength +
        " karakter";
      const w = getItem("lang") === "en" ? en : id;
      if (!object.props.value) {
        result = object.props.helper ? object.props.helper : w;
      } else if (object.props.value.length < object.props.minLength) {
        result = object.props.helper ? object.props.helper : w;
      }
    }
    if (object.props.maxLength && !result) {
      const en =
        object.props.label +
        " maximal " +
        object.props.maxLength +
        " characters";
      const id =
        object.props.label +
        " maksimal terdiri dari" +
        object.props.maxLength +
        " karakter";
      const w = getItem("lang") === "en" ? en : id;
      if (object.props.value) {
        if (object.props.value.length > object.props.maxLength) {
          result = object.props.helper ? object.props.helper : w;
        }
      }
    }
    return result;
  }

  componentDidMount() {
    General.visible = true;
    const { children } = this.props;
    this.getProgress();
    this.setState({
      total: children.length,
      content: children[this.state.current - 1],
    });
    document.addEventListener("keydown", this.keyDown);
    document.addEventListener("keyup", this.keyUp);
    this.setFocus();
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown);
    document.removeEventListener("keyup", this.keyUp);
  }
  keyUp(e) {
    if (e.keyCode === 8) {
      if (this.backstep) {
        this.prevNumber();
        this.backstep = false;
      }
    }
  }
  keyDown(e) {
    if (e.keyCode === 13) {
      if (this.props.reset) {
        this.setState({ current: 1 }, () => {
          this.nextNumber();
          this.props.onReset(false);
        });
      } else {
        this.nextNumber();
      }
    }
    if (e.keyCode === 8) {
      try {
        var input = document.getElementsByTagName("input")[0]
          ? document.getElementsByTagName("input")[0]
          : document.getElementsByTagName("textarea")[0];
        if (input.value === "") {
          this.backstep = true;
        }
      } catch (error) {}
    }
  }

  render() {
    const { children } = this.props;
    const {
      current,
      total,
      progress,
      buttonDisabled,
      error,
      custom_error,
    } = this.state;
    return (
      <div className="wizard-form" key={this.props.key}>
        <div
          style={{
            bottom: 0,
            zIndex: 1,
            minHeight: "20vh",
            width: "100vw",
            boxSizing: "border-box",
            padding: "20px 25%",
          }}>
          {this.props.children[current - 1]}
          {!custom_error && (
            <div
              id="error-messages"
              style={{ color: palette.danger, paddingBottom: 20 }}>
              {error}
            </div>
          )}
          {!error && (
            <div
              id="TFNextBtn"
              className="Submit-Btn"
              style={{ marginTop: 20 }}>
              <Button
                onClick={() => this.nextNumber()}
                variant="contained"
                style={{
                  backgroundColor: palette.primary,
                  color: "white",
                  marginRight: 20,
                }}>
                OK
              </Button>
              {t("text.press")} <strong>{t("text.enter")}</strong>
            </div>
          )}
        </div>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 10,
            height: 80,
            padding: "0px 100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            boxSizing: "border-box",
            width: "100vw",
            boxShadow: "2px -2px 2px rgba(0, 0, 0, 0.09)",
          }}>
          <div
            style={{
              display: "flex",
              boxSizing: "border-box",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Button
              disabled={current === 1 ? true : false}
              onClick={() => {
                this.prevNumber();
              }}
              style={{
                opacity: current === 1 ? 0.4 : 1,
                minWidth: 0,
                padding: 5,
                borderRadius: "50%",
                border: "1px solid " + palette.primary,
              }}>
              <ChevronLeft style={{ color: palette.primary }} />
            </Button>
            <LinearProgress
              style={{ width: 230, margin: "0px 10px" }}
              className={"Biodata freetext-progress"}
              variant="determinate"
              value={progress}
            />
            <Button
              disabled={current === children.length ? true : false}
              id="next-footer"
              onClick={e => {
                this.nextNumber();
              }}
              style={{
                opacity: current === children.length ? 0.4 : 1,
                minWidth: 0,
                padding: 5,
                borderRadius: "50%",
                border: "1px solid " + palette.primary,
              }}>
              <ChevronRight style={{ color: palette.primary }} />
            </Button>
          </div>
          <span style={{ color: "rgb(212, 213, 220)" }}>
            {current}/{total} Data
          </span>

          <NextBtn
            validate={true}
            onClick={() => {
              this.nextNumber();
            }}
            disabled={buttonDisabled}
            underline={false}
            text={t("button.save")}
            color="white"
            style={{
              backgroundColor: palette.primary,
              position: "absolute",
              padding: "10px 25px",
              right: 100,
            }}
          />
        </div>
      </div>
    );
  }
}

export default WizardForm;
// export default withTranslation()(WizardForm);
